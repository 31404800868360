import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions, authActions } from "../../store/actions";
import { isValidEmail, logAnalyticEvent } from "../../utils";
import { view } from "./LoginView";
import { getUrlParams } from "../../utils";

export const Login = () => {
  const dispatch = useDispatch();

  const [queryParams] = useState(getUrlParams(location.search));
  const [userName, setUserName] = useState("");
  const [error, setError] = useState("");
  const [notFound, setNotFound] = useState(false);
  const [pressedResend, setPressedResend] = useState(false);

  const emailSent = useSelector((state) => state.user.email);

  const cookies = useSelector((state) => state.settings.cookies);

  const submit = (e) => {
    e.preventDefault();
    if (isValidEmail(userName)) {
      dispatch(userActions.sendLoginEmail({ email: userName }));
    } else {
      setError("Invalid email");
    }
  };

  const handleUserName = (text) => {
    setUserName(text);
    resetError();
  };

  const handleSignUpClick = () => {
    // call common helper function
    logAnalyticEvent(
      "Sign Up",
      {
        page: "Sign In",
      },
      queryParams?.u || null,
      queryParams?.n || null,
      queryParams?.e || null,
      null,
      queryParams?.t || null,
    );
  };

  const resetError = () => {
    setError("");
    setNotFound(false);
  };

  useEffect(() => {
    // clear previous user data, just in case
    // dispatch(authActions.delete());

    // call common helper function
    logAnalyticEvent(
      "Login",
      {
        page: "Sign In",
      },
      queryParams?.u || null,
      queryParams?.n || null,
      queryParams?.e || null,
      null,
      queryParams?.t || null,
    );
    return () => {
      dispatch(userActions.sendEmailClear());
    };
  }, []);

  useEffect(() => {
    if (emailSent.error?.data?.type === "NotFound") {
      setNotFound(true);
    } else if (emailSent.error?.data?.message) {
      setError(emailSent.error?.data?.message);
    }
  }, [emailSent.error]);

  return view({
    userName,
    handleUserName,
    submit,
    error,
    notFound,
    loading: emailSent.fetching,
    emailSent: emailSent.data,
    handleSignUpClick,
    pressedResend,
    setPressedResend,
  });
};
