import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

import {
  Column,
  Row,
  H2,
  H4,
  P,
  UL,
  LI,
  Anchor,
  MainWrapper,
  Content,
  Metadata,
} from "../../components";

export const Terms = () => {
  return (
    <MainWrapper noHeader>
      <Metadata />
      <Content>
        <Column center>
          <H4 bold color="green">
            <Link to="/">Medical.watch</Link>
          </H4>
          <Column>
            <Row marginY>
              <H2 bold>Terms of Service</H2>
            </Row>
            <Row marginBottom="10">
              <P>Effective Date: January 7, 2023</P>
            </Row>
            <Row marginY="10">
              <H4 bold>1. Acceptance of Our Terms</H4>
            </Row>
            <P>
              1.1 These Terms of Service ("Terms") constitute a binding contract
              between you and Medical.watch, Inc. ("us" or "we" or
              "Medical.watch") governing the use of and access to the products
              we offer in connection with a free, trial or paid subscription
              ("Products") to you and any authorized individuals engaged by you
              to use the Products on your behalf (each, a "User," and
              collectively, "Users"). By using or accessing the Products or
              authorizing or permitting any User to use or access the Products,
              you agree to be bound by these Terms.
            </P>
            <P>
              1.2 If you are entering into these Terms on behalf of a company,
              organization, or other legal entity ("Entity"), you agree to these
              Terms for that Entity and represent to Medical.watch that you have
              the authority to bind such Entity and its Affiliates to these
              Terms. "Affiliates" shall mean any entity that controls, is
              controlled by or is under common control with a party, where
              "control" means ownership of 50% or more of the shares, equity
              interest or other securities entitled to vote for election of
              directors or other managing authority. In such case, "you" or
              "Customer" shall refer to such Entity and its Affiliates. If you
              are a Customer’s User, then these Terms will apply to you to the
              extent they are applicable to Users. If Customer is an agency,
              then a User may also be an agency Client as defined in Section 10
              (Agencies). If you do not have the authority to bind the Entity to
              these Terms or do not agree to these Terms, do not accept these
              Terms or use or access the Products.
            </P>
            <P>
              1.3 You represent and warrant that the information you provide in
              registering for the Products is accurate, complete, and rightfully
              yours to use.
            </P>
            <Row marginY="10">
              <H4 bold>2. Our Products</H4>
            </Row>
            <P>
              2.1 We deliver our Products through our web application
              ("Application") available at{" "}
              <Anchor
                inline
                bold
                line
                label="https://medical.watch"
                blank
                to="https://medical.watch"
              />{" "}
              and emails. The features and services available to you will be
              based on your subscription plan ("Plan"). If you signed a service
              order, the details of your Plan will be provided on your service
              order. If you purchase a Product within the Application, the
              details of your Plan will be set forth on the Billing Page (as
              defined in Section 6.1). The Products specifically exclude any
              third-party database or Third Party Services (as defined in
              Section 5).
            </P>
            <P>
              2.2 We reserve the right to modify features and functionality of
              our Products from time to time in our sole discretion. We will
              determine in our sole discretion whether any new features require
              additional fees. We may decide to add new features to the Products
              and make them generally available at no cost to Customers. Access
              to certain new features or functionality may require acceptance of
              additional terms presented within the Application. We may or may
              not provide notice to you of changes to the Products. We will not
              be liable to you or to any third party for any modifications,
              price increases, or discontinuations of our Products.
            </P>
            <P>
              2.3 Any additional Product add-ons, including but not limited to
              listening, analytics, bots, or customer service, or the addition
              of users, profiles, groups, brand keywords, and other features or
              functionality that you later add to an existing Plan (the
              "Add-Ons") during your subscription term shall be subject to these
              Terms. Any services provided to you, such as implementation,
              training, or other consulting services, related to your use of the
              Products (the "Professional Services") shall also be subject to
              these Terms and any applicable statements of work.
            </P>
            <Row marginY="10">
              <H4 bold>3. Registering Users on Our Application</H4>
            </Row>
            <P>
              3.1 As a User, you agree that you will only access our
              Applications for your internal business purposes and subject to
              these Terms. After any free trial of our Products, you will be
              required to register for our Applications and pay a subscription
              fee for the use of our Products. You must pay such subscription
              fees on the first day of your subscription term unless otherwise
              specified on your service order.
            </P>
            <P>
              3.2 If you, as a Customer, add Users to your account, you must
              bind each User to these Terms. You acknowledge and agree that a
              login may only be used by one (1) User, and that you will not
              share a single login among multiple people. You are responsible
              for maintaining the confidentiality of your login and account, and
              are fully responsible for any and all activities that occur under
              or in connection with your login or account. Except as provided in
              Section 10 (Agencies), you agree that you will not trade,
              transfer, or sell access to your login or account to another party
              unless otherwise agreed to in writing by Medical.watch.
            </P>
            <P>
              3.3 As a User, you represent and warrant that you are: (i) 18
              years old or older, (ii) not prohibited or restricted from having
              a Medical.watch account, and (iii) not a competitor of
              Medical.watch or using the Products for purposes that are
              competitive with Medical.watch.
            </P>
            <P>
              3.4 You agree to use reasonable efforts to prevent unauthorized
              use of the Products and notify us immediately if you discover any
              unauthorized use through your account. Immediately upon discovery
              of unauthorized use, you will take all necessary steps to
              terminate the unauthorized use and agree to cooperate with us in
              preventing or terminating such unauthorized use of the Products.
            </P>
            <Row marginY="10">
              <H4 bold>4. Availability of Service</H4>
            </Row>
            <P>
              4.1 While we will use commercially reasonable efforts to keep our
              Applications available and accessible, the Applications may be
              unavailable from time to time for repairs, upgrades, routine and
              emergency maintenance, or other interruptions that may be out of
              our reasonable control, including any outages of Third Party
              Services (as defined in Section 5) or any related application
              programming interface ("APIs") and integrations. Interruptions to
              your use of our Applications shall not serve as a basis to
              terminate your subscription or demand any full or partial refunds
              or credits of prepaid and unused subscription fees.
            </P>
            <Row marginY="10">
              <H4 bold>5. Our Use of Third Party Integrations and Services</H4>
            </Row>
            <P>
              5.1 Our Applications may contain links to or allow you to connect
              and use certain external third-party products, services, or
              software in conjunction with your use of our Applications and
              Products ("Third Party Services," and each, a "Third Party
              Service"), including certain social media networks and other
              integration partners. To take advantage of these features, you may
              be required to sign up or log into such Third Party Services on
              their respective websites or applications. By enabling the
              Applications to access such Third Party Service, you are
              authorizing a secure authentication token to pass from the Third
              Party Service to the Products for this express purpose. You
              acknowledge that your use of such Third Party Service is governed
              solely by the applicable terms and conditions, acceptable use
              policy, privacy policy or any other similar policy or terms of
              such Third Party Service (including, but not limited to, the
              Twitter Terms of Service located at{" "}
              <Anchor
                inline
                bold
                line
                label="https://www.twitter.com/tos"
                blank
                to="https://www.twitter.com/tos"
              />
              , the YouTube Terms of Service located at{" "}
              <Anchor
                inline
                bold
                line
                label="https://www.youtube.com/t/terms"
                blank
                to="https://www.youtube.com/t/terms"
              />
              , and the Google Privacy Policy located at{" "}
              <Anchor
                inline
                bold
                line
                label="https://policies.google.com/privacy"
                blank
                to="https://policies.google.com/privacy"
              />
              ) (collectively, "Third Party Service Terms"), and that
              Medical.watch does not endorse, is not liable for, and makes no
              representations as to the Third Party Service, its content, or the
              manner in which such Third Party Service uses, stores, or
              processes your data. We are not liable for any damage or loss
              arising from or in connection with your enablement of such Third
              Party Service and your reliance on the policies, privacy
              practices, and data security processes of such Third Party
              Service. We are not responsible or liable for any changes to, or
              deletion of, your data by the Third Party Services. Certain
              features of our Products may depend on the availability of these
              Third Party Services and the features and functionality they make
              available to us. We do not control Third Party Service features
              and functionality, and they may change without any notice to us.
              If any Third Party Service stops providing access to some or all
              of the features or functionality currently or historically
              available to us, or stops providing access to such features and
              functionality on reasonable terms, as determined by Medical.watch
              in our sole discretion, we may stop providing access to certain
              features and functionality of our Products. We will not be liable
              to you for any refunds or any damage or loss arising from, or in
              connection with, any such change made by the Third Party Service
              or any resulting change to our Products. You irrevocably waive any
              claim against Medical.watch with respect to such Third Party
              Services.
            </P>
            <P>
              5.2 In providing you with our Applications, you acknowledge and
              agree that: (i) you act as a data controller and Medical.watch
              acts as a data processor with respect to any content that
              Medical.watch collects on your behalf from Third Party Services
              ("Third Party Content"); (ii) you authorize and instruct us to
              enable integrations with Third Party Services, to enter into Third
              Party Service Terms for the purpose of enabling such integrations
              and to process any personal data accessed via such integrations on
              your behalf; and (iii) you are responsible for complying with any
              applicable Third Party Service Terms (including, but not limited
              to, any applicable developer policies) and any applicable privacy
              laws in the creation or use of Third Party Content.
            </P>
            <Row marginY="10">
              <H4 bold>6. Use of Information</H4>
            </Row>
            <P>
              You will either pay for your Plan in our Application, or upon
              receipt of an invoice issued by us.
            </P>
            <P>6.1 Payment in Application</P>
            <P>
              Monthly Plans. For monthly Plans, we will charge you on the first
              day of your subscription term and automatically on the same date
              of each subsequent month ("Monthly Pay Date"). We will continue to
              charge you for your Plan, including any Add-Ons, on a monthly
              basis unless you decide to cancel at any time by accessing the
              "Billing" page within the Application ("Billing Page"). If you
              cancel in the month preceding your Monthly Pay Date, you will not
              be issued any refunds or credits of prepaid and unused fees for
              the remainder of the subscription term and you will continue to
              have access to the Products until the following Monthly Pay Date.
              We reserve the right to increase pricing for our monthly Plans at
              any time as we may determine in our sole and absolute discretion.
              Any price changes to a monthly Plan will take effect on the next
              Monthly Pay Date following notice to you.
            </P>
            <P>
              Annual Plans. For annual Plans, we will charge you on the first
              day of your subscription term and automatically on the same date
              of each subsequent year ("Annual Pay Date"). We will continue to
              charge you for your Plan, including any Add-Ons, on an annual
              basis unless you decide to cancel prior to the Annual Pay Date by
              accessing the Billing Page. If you cancel during the subscription
              term, you will not be issued any refunds or credits of any prepaid
              and unused fees for the remainder of the subscription term and you
              will continue to have access to the Products until the following
              Annual Pay Date. Medical.watch reserves the right to increase
              subscription fees for your annual Plan on your Annual Pay Date;
              provided, however, that such increase shall not exceed 7% over the
              fees related to the immediately preceding subscription term unless
              we provide you notice of different pricing at least sixty (60)
              days prior to the Annual Pay Date.
            </P>
            <P>
              Annual Plans with monthly payment. For annual Plans that pay on a
              monthly basis, we will charge you on the first day of your
              subscription term and automatically on the same date of each
              subsequent month of your subscription term. We will continue to
              charge you for your Plan, including any Add-Ons, on a monthly
              basis throughout the duration of your subscription term and any
              subsequent renewal terms, unless you decide to cancel at least
              thirty days prior to the end of your current subscription term by
              notifying your account manager or{" "}
              <Anchor
                inline
                bold
                line
                label="hello@medical.watch"
                blank
                to="mailto:hello@medical.watch"
              />
              . If you cancel during the subscription term, you will not be
              issued any refunds for or credits of any prepaid and unused fees
              for the remainder of the subscription term, and you will be
              required to pay any and all unpaid fees related to the
              subscription term. If you fail to make timely payments, any and
              all unpaid fees that are outstanding under the applicable service
              order may become immediately due and payable at Medical.watch’s
              discretion. Medical.watch reserves the right to increase
              subscription fees for your Plan upon the first day of your renewal
              subscription term; provided, however, that such increase shall not
              exceed 7% over the fees related to the immediately preceding
              subscription term unless we provide you notice of different
              pricing at least sixty (60) days prior to the applicable renewal
              term.
            </P>
            <P>
              6.2 Payment By Invoice. If we invoice you for your Plan, your
              subscription term will be detailed on the service order and your
              payment will be due upon receipt of the applicable invoice. Unless
              otherwise specified on your service order, if we do not receive
              payment within thirty (30) days of us issuing you the invoice,
              your account may be suspended and you will lose access to the
              Products. Unless otherwise specified on your service order, your
              Plan will automatically renew at the end of the subscription term.
              If you would like to cancel your Plan, you must provide such
              notice via email to{" "}
              <Anchor
                inline
                bold
                line
                label="hello@medical.watch"
                blank
                to="mailto:hello@medical.watch"
              />{" "}
              at least thirty (30) days prior to the end of the subscription
              term. Medical.watch reserves the right to increase subscription
              fees upon renewal; provided, however, that such increase shall not
              exceed 7% over the fees related to the immediately preceding
              subscription term unless we provide you notice of different
              pricing at least sixty (60) days prior to the applicable renewal
              term.
            </P>
            <P>
              6.3 Changes To Your Plan. If you choose to upgrade your Plan or
              add any Add-Ons to your Plan during your subscription term, you
              will be charged for the then-current price for the upgrade or
              Add-Ons prorated based on the number of days remaining in your
              subscription term. Unless otherwise specified on your service
              order, any upgrade or Add-Ons that you add will be coterminous
              with the existing Plan and automatically renew at the end of the
              subscription term along with your Plan. If you choose to downgrade
              your Plan or remove any Add-Ons from your Plan, you will not be
              issued any refunds or credits for the unused and prepaid fees in
              connection with the downgrade or removal. Downgrading your Plan
              may cause the loss of content, features, or capacity of your
              account and we will not be liable for any such loss.
            </P>
            <P>
              6.4 Credit Card Authorization. By submitting your credit card to
              Medical.watch, you authorize Medical.watch to store this
              information with its third party service providers and to charge
              the credit card you have provided to us until your account is
              terminated. In addition, you authorize us to use a third-party
              payment processor in processing payments. If your credit card
              expires, or is declined, we will provide you notice via email. If,
              for any reason, your payment cannot be completed through credit
              card, we may suspend your account until we receive payment. You
              can choose to set up a backup payment method that will be used if
              the primary method fails for any reason (such as an expired credit
              card or insufficient funds). By adding a backup payment method,
              you agree that, if your primary payment method fails,
              Medical.watch can automatically charge your backup payment method
              to avoid any interruptions or suspensions to your account.
            </P>
            <P>
              6.5 Disputes and Late Payments. You must notify us in writing of
              any amounts you wish to dispute prior to the date such amounts
              would otherwise be due. Any undisputed amount not paid when due
              shall be subject to a finance charge of one and one-half percent
              (1.5%) of the unpaid balance per month (determined and compounded
              daily from the date due until the date paid) or the highest rate
              permitted by applicable law, whichever is less. You will also be
              required to reimburse us for any costs or expenses (including any
              reasonable attorneys’ fees) we incur to collect past due amounts.
              Any amounts due under these Terms shall not be withheld or offset
              by you against amounts due to you for any reason.
            </P>
            <P>
              6.6 Taxes. You are responsible for the payment of any applicable
              taxes on amounts due to Medical.watch, including, but not limited
              to, state and local sales, use, excise and value-added taxes (but
              excluding any taxes due on Medical.watch’s income, property or
              employees). Applicable taxes will be charged to you if you pay
              within the Application or presented to you on an invoice, unless
              you provide a current and valid applicable tax exemption
              certificate to{" "}
              <Anchor
                inline
                bold
                line
                label="billing@medical.watch"
                blank
                to="mailto:billing@medical.watch"
              />{" "}
              before charges are incurred or an invoice is generated. For
              customers located outside of the United States, all amounts
              payable by you hereunder shall be grossed up for any withholding
              taxes imposed by any applicable foreign government on your payment
              of amounts to Medical.watch.
            </P>
            <Row marginY="10">
              <H4 bold>7. Cancellation and Termination</H4>
            </Row>
            <P>
              7.1 Termination by You. You may only cancel your Plan in
              accordance with Section 6 (Payment) or in the event of an uncured
              material breach by Medical.watch. If we fail to cure a material
              breach of these Terms within thirty (30) days of our receipt of
              written notice from you describing the breach, you may terminate
              your account and receive a prorated refund of any prepaid and
              unused fees. In all other instances of termination, you will not
              be entitled to any refunds of any prepaid and unused fees, and any
              unpaid fees under your Plan for the applicable subscription term
              will remain due and payable.
            </P>
            <P>
              7.2 Termination by Us. We may restrict functionality of the
              Products or temporarily suspend your account if we reasonably
              believe that you have violated these Terms. Unless we believe that
              the need to restrict or suspend access is time-sensitive and
              requires immediate action without notice, or we are prohibited
              from providing notice under law or legal order, we will use
              commercially reasonable efforts to notify you by email prior to
              such suspension. We will not be liable to you or any third parties
              for any of the foregoing actions. We may terminate your account
              and use of the Products for any of the following reasons: (i) you
              fail to comply with these Terms, (ii) you do not pay your fees in
              accordance with the payment terms under your Plan, (iii) at the
              expiration of the subscription period of your Plan if we provide
              prior written notice to you, (iv) you become the subject of a
              petition in bankruptcy or any other proceeding relating to
              insolvency, receivership, liquidation or assignment for the
              benefit of creditors, or (v) if we reasonably determine you are
              acting or have acted in a way that could present substantial
              reputational harm to Medical.watch or our current or prospective
              partners or customers. In no event will any termination by us for
              the foregoing reasons entitle you to any refunds of any prepaid
              and unused fees or relieve you of your obligation to pay any fees
              payable to us prior to the date of termination, and any unpaid
              fees under your Plan will remain due and payable. Any suspected
              fraudulent, abusive, hateful, discriminatory or illegal activity
              may be grounds for immediate termination of your use of the
              Product and may be referred to law enforcement authorities.
            </P>
            <P>
              7.3 Post Termination. If your account is terminated, you must
              cease using the Products and Medical.watch reserves the right to
              delete your account settings and Content within thirty (30) days
              of such cancellation or termination with no liability or notice to
              you. Once your account settings and Content are deleted, you will
              not be able to recover such account settings or Content, except
              any Content that remains on Third Party Services pursuant to such
              Third Party Service Terms.
            </P>
            <Row marginY="10">
              <H4 bold>8. Your Use of the Products</H4>
            </Row>
            <P>
              8.1 You agree not to, nor authorize or permit any User or third
              party to: (i) license, sublicense, sell, rent, lease, or otherwise
              permit third parties to use the Products; (ii) circumvent or
              disable any security or other technological features or measures
              of the Products; (iii) reverse engineer any element of the
              Products, or use the Products to compete with the Products; (iv)
              modify, adapt or present the Products to falsely imply any
              sponsorship or association with Medical.watch; (e) use the
              Products in any manner that interferes with or disrupts the
              integrity or performance of the Products or the components of the
              Products; (v) attempt to use any method to gain unauthorized
              access to any paid or restricted features of the Sites or to the
              Products and its related systems or networks; (vi) use automated
              scripts to collect information from or otherwise interact with
              Third Party Services or the Products; (vii) impersonate any other
              user of the Products; or (viii) use the Products in violation of
              applicable law or any Third Party Service Terms.
            </P>
            <P>
              8.2 You agree not to use, and not to knowingly display,
              distribute, or otherwise make content or information derived from
              the Products available to any entity for the purpose of: (i)
              conducting or providing surveillance or gathering intelligence,
              including, but not limited to, investigating or tracking
              individual social media users or their content; (ii) tracking,
              alerting, or other monitoring of sensitive events (including, but
              not limited to, protests, rallies, or community organizing
              meetings); (iii) conducting or providing surveillance, analyses or
              research that isolates a group of individuals or any single
              individual on social media for any unlawful or discriminatory
              purpose or in a manner that would be inconsistent with the
              individual users’ reasonable expectations of privacy; or (iv)
              targeting, segmenting, or profiling individuals based on sensitive
              personal information, including health (e.g. pregnancy), negative
              financial status or condition, political affiliation or beliefs,
              racial or ethnic origin, religious or philosophical affiliation or
              beliefs, sex life or sexual orientation, trade union membership,
              data relating to any alleged or actual commission of a crime, or
              any other sensitive categories of personal information prohibited
              by law.
            </P>
            <P>
              8.3 If you are a government entity or an entity performing
              services on behalf of a government entity whose primary function
              or mission includes conducting surveillance or gathering
              intelligence, you may not access Twitter content through the
              Products, unless otherwise expressly pre-approved by Medical.watch
              and Twitter. Medical.watch and Twitter reserve the right to
              approve each of your use cases for our Products if you are a
              government entity or an entity performing services on behalf of a
              government entity, and failure to obtain such approval may result
              in suspension and potential termination pursuant to Sections 7.2
              and 8.4.
            </P>
            <P>
              8.4 We have the right to immediately terminate your account or
              suspend your access to the Products, if we reasonably suspect that
              you have violated any of the restrictions in this Section 8.
            </P>
            <P>
              8.5 By accessing or using the Products, you represent and warrant
              that your activities are lawful in every jurisdiction where you
              access or use the Products. Our Products are not intended to hold
              any Sensitive Information (defined below). You represent and
              warrant that you will not use our Products to transmit, upload,
              collect, manage, or otherwise process any Sensitive Information.
              WE WILL NOT BE LIABLE FOR ANY DAMAGES THAT MAY RESULT FROM YOUR
              USE OF OUR PRODUCTS IN TRANSMITTING, COLLECTING, MANAGING, OR
              PROCESSING ANY SENSITIVE INFORMATION. "Sensitive Information"
              means any passwords, credit card or debit card information,
              personal financial account information, personal health
              information, social security numbers, passport numbers, driver’s
              license numbers, employment records, physical or mental health
              condition or information, information on racial or ethnic origin,
              political opinions, religious or philosophical beliefs, trade
              union membership, genetic data, biometric data for the purpose of
              uniquely identifying a natural person, data concerning health or
              data concerning a natural person’s sex life or sexual orientation,
              or any other information that would be subject to Health Insurance
              Portability and Accountability Act (HIPAA), the Payment Card
              Industry Data Security Standards (PCI DSS), or other laws,
              regulations, or industry standards designed to protect similar
              information.
            </P>
            <P>
              8.6 Although we do not monitor content published through our
              Products and are not responsible for any content published through
              our Products, we reserve the right to delete, edit, or move
              messages or materials that we deem necessary to be removed,
              including, but not limited to, public postings, advertisements,
              and messages.
            </P>
            <P>
              8.7 Export and Link Sharing. Should you choose to export a copy of
              or share any public or private links via the Products, you
              acknowledge and agree that we are not responsible for, and shall
              have no liability related to: (i) the security of the information
              contained in (a) any exported copy of Medical.watch or (b) any
              public or private link shared through the Products; or (ii)
              compliance with any applicable law of any federal, state, local,
              or foreign government or political subdivision thereof, including
              applicable privacy law, as a result of fulfilling your request to
              (a) export a copy of or (b) share any public or private link
              through the Products.
            </P>
            <Row marginY="10">
              <H4 bold>9. Confidential Information</H4>
            </Row>
            <P>
              9.1 For the purpose of these Terms, "Confidential Information"
              means non-public information disclosed by either party to the
              other party, either directly or indirectly, in writing, orally, or
              to which the other party may have access, which (i) a reasonable
              person would consider confidential, or (ii) is marked
              "confidential" or "proprietary" or some similar designation by the
              disclosing party.
            </P>
            <P>
              9.2 Confidential Information will not, however, include any
              information that (i) was publicly known and made generally
              available in the public domain prior to the time of disclosure by
              the disclosing party; (ii) becomes publicly known and made
              generally available after disclosure by the disclosing party to
              the receiving party other than as a result of a violation of these
              Terms by the receiving party; (iii) is already in the possession
              of the receiving party at the time of disclosure by the disclosing
              party; (iv) is obtained by the receiving party from a third party
              without a breach of confidentiality obligations; or (v) is
              independently developed by the receiving party without use of or
              reference to the disclosing party’s Confidential Information.
            </P>
            <P>
              9.3 The receiving party shall not disclose, use, transmit, inform
              or make available to any entity, person or body any of the
              Confidential Information for any purpose outside of the scope of
              these.
            </P>
            <P>
              9.4 Terms. The receiving party shall take all actions reasonably
              necessary and appropriate to prevent the unauthorized disclosure
              of the Confidential Information, and shall at all times exercise
              at least a reasonable level of care. Each party agrees to restrict
              access to the Confidential Information of the other party to those
              employees, advisors, agents and other representatives who require
              access in order to perform its obligations under these Terms. The
              receiving party shall not be in violation of its confidentiality
              obligations for disclosing Confidential Information as required by
              applicable law or regulation or in response to a valid order by a
              court or other governmental body, as long as the receiving party
              provides the disclosing party (to the extent legally permissible)
              with prior written notice of the disclosure to permit the
              disclosing party to seek confidential treatment of that
              information and will limit such disclosure to what is required by
              law or legal order.
            </P>
            <Row marginY="10">
              <H4 bold>10. Agencies</H4>
            </Row>
            <P>
              10.1 If you are an Agency (defined below), you may use our
              Products on behalf of Users that are your clients and charge your
              clients for such use of our Products (each, a "Client"). As an
              Agency, you will be liable for all use of the Products by your
              Clients. By adding any Client to your account, you represent and
              warrant that you have obtained all necessary authorizations and
              consents from such Clients to bind them to these Terms. If you use
              the Products on behalf of your Clients, or grant access to the
              Products to your Clients, you will be responsible for ensuring
              that such Clients are not able to access confidential or
              proprietary information of another Client. "Agency" shall mean a
              business or organization providing advertising, marketing, or
              social media services on behalf of another business, person, or
              group.
            </P>
            <Row marginY="10">
              <H4 bold>
                11. Cookies, similar tracking technology, and analytics
              </H4>
            </Row>
            <P>
              11.1 We access your data to enable us to respond to your service
              requests and as necessary to provide you with the Application and
              Products. We share your data with third parties if required by
              law, permitted by you, or pursuant to our Medical.watch Privacy
              Policy ("Privacy Policy"), which is available{" "}
              <Anchor inline bold line label="here" to="/privacy" blank /> and
              incorporated into these Terms. You agree to all actions that are
              taken with respect to your data that are consistent with our
              Privacy Policy. Before sharing your data, we will take steps
              designed to ensure that any third-party service provider maintains
              commercially reasonable data practices for maintaining the
              confidentiality and security of your data and for preventing
              unauthorized access to such data. We do not share your data with
              third parties for their own marketing purposes.
            </P>
            <P>
              11.2 If your use of our Products includes processing personal data
              or personal information subject to applicable EU or US data
              protection laws, you must enter into a Data Processing Addendum
              ("DPA") with Medical.watch that is available{" "}
              <Anchor
                inline
                bold
                line
                label="here"
                blank
                to="mailto:legal@medical.watch?subject=Medical.watch Data Processing Addendum request"
              />
              . Both our Privacy Policy and any DPA that you enter into with
              Medical.watch form part of these Terms and apply to the processing
              of personal data. You may review our Privacy Policy to understand
              how we collect and use your data. While Medical.watch relies on
              the Standard Contractual Clauses approved by the European
              Commission as the legal basis for the transfer of personal data
              from the EU to a third country, Medical.watch maintains its
              Privacy Shield certification under both the EU-U.S. and Swiss-U.S.
              Privacy Shield frameworks established by the U.S. Department of
              Commerce regarding the transfer of personal data from the European
              Economic Area and/or Switzerland, as applicable, to the U.S.
            </P>
            <Row marginY="10">
              <H4 bold>
                12. Your Rights and Our Rights to Intellectual Property
              </H4>
            </Row>
            <P>
              12.1 What You Own. You own all of the Content you provide to us.
              You grant us a nonexclusive, revocable, worldwide, limited, fully
              paid-up and royalty-free right to use, copy, prepare derivative
              works of, distribute, publish, remove, retain, add, process, or
              analyze this information for the sole purpose of providing the
              Applications and Products to you and your Users. You represent and
              warrant that you are entitled to and authorized to submit the
              Content and that such Content you submit is accurate and not in
              violation of any contractual restrictions or third party rights.
            </P>
            <P>
              12.2 What We Own. We own and retain all rights, title, and
              interest in and to the Products along with all patents,
              inventions, copyrights, trademarks, domain names, trade secrets,
              know-how, and any other intellectual property and/or proprietary
              rights ("Intellectual Property Rights") related to the Products.
              Your use of the Products under these Terms does not give you
              additional rights in the Products or ownership of any Intellectual
              Property Rights associated with the Products. Subject to your
              compliance with and limitations set forth in these Terms and upon
              your subscription to the Products, we grant you a non-exclusive,
              non-transferable, non- sublicensable, revocable right to access
              and use our Products and Applications.
            </P>
            <P>
              12.3 Ownership of Your Feedback and Suggestions. Although you are
              not required to provide feedback or suggestions, you assign to us
              all of your worldwide right, title and interest in and to any and
              all feedback, suggestions, requests, recommendations, or other
              comments that you choose to provide to us regarding our Products,
              including all Intellectual Property Rights therein. You shall,
              upon the request of Medical.watch, its successors or assigns,
              execute any and all documents that may be deemed necessary to
              effectuate this assignment. You also agree to waive any right of
              approval for our use of the rights granted herein and agree to
              waive any moral rights that you may have in any feedback,
              suggestions, or other comments, even if it is altered or changed
              in a manner not agreeable to you. You understand that you will not
              receive any fees, sums, consideration, or remuneration for any of
              the rights granted in this section. Our receipt of your feedback,
              suggestions, and other comments is not an admission of their
              novelty, priority, or originality, and it does not impair our
              right to any existing or future Intellectual Property Rights.
            </P>
            <P>
              12.4 Data Use. You acknowledge and authorize our use of
              de-identified or aggregated data: (i) to compile usage and
              performance information related to the Applications; (ii) to
              operate, improve, and support the Applications; (iii) to develop
              and publish benchmarks and similar informational reports; or (iv)
              for any other lawful purpose; provided that to the extent such
              de-identified or aggregated data is Personal Data (as defined in
              the DPA), we use such data only in accordance with the Privacy
              Policy. We will not disclose such data externally unless such data
              is de-identified so that it does not identify You, Your users, or
              any other person. We will own all intellectual property rights in
              such de-identified or aggregated data and any data derived
              therefrom.
            </P>
            <Row marginY="10">
              <H4 bold>13. WARRANTY</H4>
            </Row>
            <P>
              THE APPLICATIONS AND PRODUCTS ARE PROVIDED ON AN "AS IS" BASIS,
              WITHOUT ANY WARRANTIES, GUARANTEES, CONDITIONS, OR REPRESENTATIONS
              OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED BY LAW, WE EXPRESSLY
              DISCLAIM ANY AND ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, OR
              STATUTORY, INCLUDING, BUT NOT LIMITED TO, EXPRESS OR IMPLIED
              WARRANTIES OF MERCHANTABILITY, DESIGN, TITLE, QUALITY, FITNESS FOR
              A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE CANNOT AND DO NOT
              WARRANT THAT THE APPLICATIONS AND PRODUCTS WILL BE UNINTERRUPTED,
              AVAILABLE, ACCESSIBLE, SECURE, TIMELY, ACCURATE, COMPLETE, FREE
              FROM VIRUSES, OR ERROR-FREE. MEDICAL.WATCH DISCLAIMS ALL LIABILITY
              FOR ANY MALFUNCTIONING, IMPOSSIBILITY OF ACCESS, OR POOR USE
              CONDITIONS OF THE SERVICES DUE TO INAPPROPRIATE EQUIPMENT,
              DISTURBANCES RELATED TO INTERNET SERVICE PROVIDERS, TO THE
              SATURATION OF THE INTERNET NETWORK OR ANY OTHER ERROR, OMISSION,
              INTERRUPTION, DELETION, DEFECT, DELAY IN OPERATION OR
              TRANSMISSION, COMMUNICATIONS LINE FAILURE, THEFT OR DESTRUCTION OR
              UNAUTHORIZED ACCESS TO, OR ALTERATION OF, DATA NOT WITHIN
              MEDICAL.WATCH’S REASONABLE CONTROL.
            </P>
            <Row marginY="10">
              <H4 bold>14. Our Indemnification of You</H4>
            </Row>
            <P>
              14.1 We agree to defend, indemnify, and hold you harmless from any
              and all claims, losses, demands, liabilities, damages,
              settlements, expenses, and costs (including reasonable attorney’s
              fees and costs) brought by a third party against you alleging your
              use of any Product as permitted herein infringes or
              misappropriates any Intellectual Property Right of such third
              party. We will not have any obligation under this Section 14.1 for
              any infringement or misappropriation that arises from or is based
              upon: (i) any use of the Products in combination with other
              products or services if such infringement or misappropriation
              would not have arisen but for such combination, (ii) your use of
              the Products for purposes not intended, permitted, or outside of
              the scope of the rights granted to you under these Terms, (iii)
              any modification of the Products not made or authorized in writing
              by Medical.watch or (iv) your Content, Third Party Content or the
              Third Party Services, (the "Excluded Claims"). If you are enjoined
              or otherwise prohibited from using a Product or a portion thereof
              based on an allegation that the Product violates any third party
              Intellectual Property Right, or if we reasonably determine that
              such prohibition is likely, then we will, at our sole expense and
              option: (a) obtain for you the right to use the allegedly
              infringing portions of the Products; (b) modify the allegedly
              infringing portions of the Products so as to render them
              non-infringing without substantially diminishing or impairing
              their functionality; or (c) replace the allegedly infringing
              portions of the Products with non-infringing items of
              substantially similar functionality. If we determine that the
              foregoing remedies are not commercially reasonable, then we may
              terminate the impacted subscription, or portion thereof, and will
              promptly provide a prorated refund or credit to you for any
              prepaid and unused fees.
            </P>
            <P>
              14.2 This Section 14 states Medical.watch’s sole and exclusive
              liability, and your sole and exclusive remedy, for the actual or
              alleged infringement or misappropriation of any third party
              Intellectual Property Rights by the Products.
            </P>
            <Row marginY="10">
              <H4 bold>15. Your Indemnification of Us</H4>
            </Row>
            <P>
              15.1 Your failure to comply with any of your obligations set forth
              in these Terms shall be considered a breach of these Terms. You
              agree to defend, indemnify, and hold harmless Medical.watch and
              its Affiliates, and each of its and their respective officers,
              directors, employees, agents, successors, and assigns from any and
              all third party claims, losses, demands, liabilities, damages,
              settlements, expenses, and costs (including attorney’s fees and
              costs), arising from, in connection with, or based on: (i)
              allegations of, your or your Users’ breach of these Terms or use
              of Third Party Services; or (ii) for any action arising from the
              Excluded Claims.
            </P>
            <P>
              15.2 Requirements for Indemnification. Either party’s
              indemnification obligations shall be contingent on: (i) the
              indemnified party ("Indemnitee") providing the indemnifying party
              ("Indemnitor") prompt written notice of the claim (provided that
              the Indemnitee’s failure to provide such prompt notice will not
              release the Indemnitor from its indemnification obligations except
              to the extent the Indemnitor is materially prejudiced thereby),
              (ii) Indemnitee granting Indemnitor full and complete control over
              the defense and settlement of the claim, and (iii) Indemnitee
              providing reasonable assistance in connection with the defense and
              settlement of the claim as Indemnitor shall reasonably request.
            </P>
            <Row marginY="10">
              <H4 bold>16. LIMITATION OF LIABILITY</H4>
            </Row>
            <P>
              16.1 Exclusion of Consequential and Related Damages. NEITHER PARTY
              NOR ITS AFFILIATES WILL, UNDER ANY CIRCUMSTANCES, BE LIABLE TO THE
              OTHER PARTY, UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH
              OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, OR
              OTHERWISE, FOR CONSEQUENTIAL, INCIDENTAL, INDIRECT, SPECIAL,
              EXEMPLARY, ENHANCED, OR PUNITIVE DAMAGES ARISING OUT OF OR RELATED
              TO THESE TERMS, INCLUDING BUT NOT LIMITED TO LOST PROFITS,
              REVENUE, BUSINESS, OR DATA; BUSINESS INTERRUPTION; OR LOSS OF
              GOODWILL OR REPUTATION, REGARDLESS OF WHETHER THE PARTY IS
              APPRISED OF THE LIKELIHOOD OF SUCH DAMAGES OCCURRING OR ANY LOSSES
              OR DAMAGES WERE OTHERWISE FORESEEABLE.
            </P>
            <P>
              16.2 Monetary Cap on Liability. UNDER NO CIRCUMSTANCES WILL THE
              MAXIMUM AGGREGATE LIABILITY OF MEDICAL.WATCH (INCLUDING ITS
              AFFILIATES) TO YOU (INCLUDING YOUR AFFILIATES) ARISING OUT OF OR
              RELATED TO THESE TERMS (INCLUDING BUT NOT LIMITED TO WARRANTY
              CLAIMS), REGARDLESS OF THE FORUM AND REGARDLESS OF WHETHER ANY
              ACTION OR CLAIM IS BASED ON CONTRACT, TORT (INCLUDING NEGLIGENCE),
              STRICT LIABILITY, OR ANY OTHER LEGAL OR EQUITABLE THEORY, EXCEED
              THE TOTAL AMOUNT PAID BY YOU TO MEDICAL.WATCH UNDER THE APPLICABLE
              PLAN DURING THE TWELVE MONTHS PRECEDING THE EVENT GIVING RISE TO
              THE CLAIM. THE FOREGOING LIABILITY LIMITATIONS WILL NOT IN ANY WAY
              LIMIT YOUR PAYMENT OBLIGATIONS UNDER SECTION 6 ABOVE.
            </P>
            <P>
              16.3 Independent Allocations of Risk. EACH PROVISION OF THESE
              TERMS THAT PROVIDES FOR A LIMITATION OF LIABILITY, DISCLAIMER OF
              WARRANTIES, OR EXCLUSION OF DAMAGES IS TO ALLOCATE THE RISKS OF
              THESE TERMS BETWEEN THE PARTIES. THIS ALLOCATION IS REFLECTED IN
              THE PRICING OFFERED BY MEDICAL.WATCH TO YOU AND IS AN ESSENTIAL
              ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN THE PARTIES. EACH OF
              THESE PROVISIONS IS SEVERABLE AND INDEPENDENT OF ALL OTHER
              PROVISIONS OF THESE TERMS. THE LIMITATIONS IN THIS SECTION WILL
              APPLY NOTWITHSTANDING THE FAILURE OF ESSENTIAL PURPOSE OF ANY
              LIMITED REMEDY.
            </P>
            <P>
              16.4 State Prohibition of Limitation of Liability and Disclaimer
              of Implied Warranties. Some states do not allow the exclusion of
              implied warranties or limitation of liability for incidental or
              consequential damages, which means that some of the above
              limitations may not apply. IN THESE STATES, EACH PARTY’S LIABILITY
              WILL BE LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW.
            </P>
            <Row marginY="10">
              <H4 bold>17. Miscellaneous</H4>
            </Row>
            <P>
              17.1 Updates To Terms of Service. We may revise and update these
              Terms of Service from time to time, in our sole discretion. Any
              changes we make to these Terms are effective immediately when we
              post them. We will provide notice to the account owner designated
              on the account of any material changes to these Terms. Continued
              use of our Products after we provide you notice of the updated
              Terms shall constitute acceptance of the updated Terms.
            </P>
            <P>
              17.2 Export Compliance and Anti-Corruption. The Products may be
              subject to export laws and regulations of the United States and
              other jurisdictions. You represent that you are not named on any
              U.S. government denied-party list. You will not permit Users or
              any other third party to access or use the Products subject to a
              U.S. government embargo or in violation of any U.S. or other
              applicable export law or regulation. You further represent that
              you have not received or been offered any illegal or improper
              bribe, kickback, payment, gift, or thing of value in connection
              with your purchase or use of our Products (excluding any
              reasonable gifts and entertainment provided in the ordinary course
              of business).
            </P>
            <P>
              17.3 Federal Government End Use Provisions. If you are a U.S.
              federal government end user, the Products are "Commercial Items"
              as that term is defined at 48 C.F.R. §2.101, consisting of
              "Commercial Computer Software" and "Commercial Computer Software
              Documentation", as those terms are used in 48 C.F.R. §12.212 or 48
              C.F.R. §227.7202. Consistent with 48 C.F.R. §12.212 or 48 C.F.R.
              §227.7202-1 through 227.7202-4, as applicable, the Products are
              provided to you with only those rights as provided under these
              Terms.
            </P>
            <P>
              17.4 Assignability. Neither party may assign its right, duties,
              and obligations under these Terms without the other party’s prior
              written consent, which consent will not be unreasonably withheld
              or delayed; provided that Medical.watch may assign these Terms,
              and the rights granted to Medical.watch under these Terms, without
              your consent to a successor (including a successor by way of
              merger, acquisition, sale of assets, or operation of law) if the
              successor agrees to assume and fulfill all of Medical.watch’s
              obligations under these Terms.
            </P>
            <P>
              17.5 Notices. Except as otherwise specified in these terms, any
              notices under these Terms must be sent to Medical.watch by email
              to{" "}
              <Anchor
                inline
                bold
                line
                label="legal@medical.watch"
                blank
                to="mailto:legal@medical.watch"
              />
              , with a duplicate copy sent via registered mail (return receipt
              requested) to:
            </P>
            <P>
              Medical.watch
              <br />
              Attention: Legal Department
              <br />
              135 Commonwealth Drive
              <br />
              Menlo Park, California 94025
              <br />
            </P>
            <P>
              Any notices under these Terms that are sent to you shall be sent
              via email to the current named account owner of your Medical.watch
              account. You are responsible for maintaining the accuracy of the
              email address and other contact information of your named account
              owner on the "Personal Settings" page within the Application.
            </P>
            <P>
              17.6 Force Majeure. Neither party will be liable for or be
              considered to be in breach or default of these Terms on account of
              any delay or failure to perform as required by these Terms (except
              for your obligations to make payments to Medical.watch hereunder)
              as a result of any cause or condition beyond its reasonable
              control, so long as that party uses commercially reasonable
              efforts to avoid or remove the causes of non-performance.
            </P>
            <P>
              17.7 Governing Law. These Terms will be interpreted, construed,
              and enforced in all respects in accordance with the local laws of
              the State of California, U.S.A., without reference to its choice
              of law rules and not including the provisions of the 1980 U.N.
              Convention on Contracts for the International Sale of Goods.
            </P>
            <P>
              17.8 Venue. In circumstances where the Agreement to Arbitrate
              Disputes (Section 17.10) permits the parties to litigate in court,
              these Terms shall be governed by and construed in accordance with
              the laws of the State of California, excluding its conflict of law
              rules. Under such limited circumstances, each party hereby
              expressly and irrevocably consents to the exclusive jurisdiction
              and venue of the federal, state, and local courts in San Mateo
              County, California in connection with such an action.
            </P>
            <P>
              17.9 Agreement to Arbitrate Disputes. You and Medical.watch agree
              to resolve any claims relating to these Terms through final and
              binding arbitration, except to the extent either party has
              breached or threatened to breach its confidentiality obligations
              or either party has in any manner violated or threatened to
              violate the other party’s Intellectual Property Rights. Under such
              limited circumstances, Medical.watch may bring a lawsuit solely
              for injunctive relief to stop unauthorized use or abuse of the
              Products, or intellectual property infringement without first
              engaging in arbitration or the informal dispute-resolution process
              described herein. In all other cases, both parties hereby agree to
              submit to arbitration administered by the American Arbitration
              Association under its Commercial Arbitration Rule with one (1)
              arbitrator to be selected by mutual agreement of the parties. If
              we cannot mutually agree on the arbitrator selection, then the
              American Arbitration Association shall choose an arbitrator for
              the parties from the National Panel of Arbitrators. You agree that
              an arbitrator cannot award punitive damages to either party and to
              abide by and perform any award rendered by the arbitrator.
              Judgment upon the award rendered by the arbitrator may be entered
              in any court having jurisdiction, which shall include, but not be
              limited to, the courts within San Mateo County, California.
            </P>
            <P>
              17.10 Waiver and Severability. The waiver by Medical.watch of any
              term or condition set out in these Terms shall not be deemed a
              further or continuing waiver of any other provision of these
              Terms, and any failure of Medical.watch to assert a right or
              provision under these Terms shall not constitute a waiver of such
              right or provision. If any provision of these Terms is held by a
              court or other tribunal of competent jurisdiction to be invalid,
              unenforceable, or illegal for any reason, such provision shall be
              limited to the minimum extent such that the remaining provisions
              of the Terms will continue in full force and effect.
            </P>
            <P>
              17.11 Entire Agreement. Except for any service order, these Terms
              are the final and complete expression of the agreement between
              these parties regarding your use of the Products and Applications.
              These Terms supersede and govern all previous representations and
              oral and written communications regarding these matters.
              Medical.watch will not be bound by, and specifically objects to,
              any term, condition, or other provision that is different from or
              in addition to these Terms (whether or not it would materially
              alter this agreement) that is proffered by you in any receipt,
              invoice, acceptance, purchase order, confirmation, correspondence,
              or otherwise, regardless of Medical.watch’s failure to object to
              such terms, provisions or conditions.
            </P>
            <P>
              17.12 Relationship; Independent Contractor. Nothing herein shall
              be so construed as to constitute the parties as principal and
              agent, employer and employee, partners or joint venturers, nor
              shall any similar relationship be deemed to exist between the
              parties. Neither party shall have any power to obligate or bind
              the other party, except as specifically provided herein.
            </P>
            <P>
              17.13 Survival. Section 5 (Use of Third Party Services), your
              payment obligations under Section 6 (Payment Terms), Section 7
              (Cancellation and Termination), Section 8 (Your Use of the
              Product), Section 9 (Confidential Information), Section 12 (Your
              Rights and Our Rights to IP), Section 13 (Warranty), Section 14
              (Our Indemnification of You), Section 15 (Your Indemnification of
              Us), Section 16 (Limitation of Liability), and Section 17
              (Miscellaneous) will survive any termination of these Terms.
            </P>
            <P>
              17.14 DMCA. The Digital Millennium Copyright Act of 1998 (the
              "DMCA") provides recourse for copyright owners who believe that
              material appearing on the Internet infringes their rights under
              U.S. copyright law. If you believe in good faith that content or
              material on the Products, the Applications, the Sites, or on
              Medical.watch’s website (www.medical.watch) or any of its web
              properties hosted on the medical.watch domain infringes a
              copyright owned by you, you (or your agent) may send
              Medical.watch’s DMCA agent a notice requesting that the material
              be removed, or access to it blocked. This request should be sent
              to:{" "}
              <Anchor
                inline
                bold
                line
                label="legal@medical.watch"
                blank
                to="mailto:legal@medical.watch"
              />
              ; with a copy to:
            </P>
            <P>
              Medical.watch
              <br />
              Attention: Legal Department
              <br />
              135 Commonwealth Drive
              <br />
              Menlo Park, California 94025
              <br />
            </P>
            <P>
              The notice must include the following information: (a) a physical
              or electronic signature of a person authorized to act on behalf of
              the owner of an exclusive right that is allegedly infringed; (b)
              identification of the copyrighted work claimed to have been
              infringed; (c) identification of the material that is claimed to
              be infringing or the subject of infringing activity; (d) the name,
              address, telephone number, and email address of the complaining
              party; (e) a statement that the complaining party has a good faith
              belief that use of the material in the manner complained of is not
              authorized by the copyright owner, its agent or the law; and (f) a
              statement that the information in the notification is accurate
              and, under penalty of perjury, that the complaining party is
              authorized to act on behalf of the owner of an exclusive right
              that is allegedly infringed. If you believe in good faith that a
              notice of copyright infringement has been wrongly filed against
              you, the DMCA permits you to send us a counter-notice. Notices and
              counter-notices must meet the then-current statutory requirements
              imposed by the DMCA. Notices and counter-notices with respect to
              the website should be sent to the address above.
            </P>
          </Column>
        </Column>
      </Content>
    </MainWrapper>
  );
};
