import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Drawer } from "../../components";
import { settingsActions } from "../../store/actions";
import { useEffect } from "react";

const Items = [
  { id: "accounts", label: "Accounts", to: "/admin/accounts" },
  { id: "ai", label: "AI", to: "/admin/ai" },
  { id: "conferences", label: "Congresses", to: "/admin/conferences" },
  { id: "diseases", label: "Diseases", to: "/admin/diseases" },
  {
    id: "notificationMessages",
    label: "Custom Messages",
    to: "/admin/notificationMessages",
  },
  // { id: "hcps", label: "HCPs" },
  // { id: "highlights", label: "Highlights" },
  // { id: "institutions", label: "Institutions" },
  { id: "monitors", label: "Monitors", to: "/admin/monitors" },
  { id: "notifications", label: "Notifications", to: "/admin/notifications" },
  {
    id: "notificationEvents",
    label: "Notification Events",
    to: "/admin/notificationEvents",
  },
  { id: "plans", label: "Plans", to: "/admin/plans" },
  { id: "tweets", label: "Posts", to: "/admin/tweets" },
  { id: "products", label: "Products", to: "/admin/products" },
  { id: "tags", label: "Tags", to: "/admin/tags" },
  { id: "users", label: "Users", to: "/admin/users" },
];

export const Menu = ({ active }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const role = useSelector((state) => state.auth.data?.mwRole);

  useEffect(() => {}, []);

  const itemOnChange = (item) => {
    // history.push(`/admin/${item.id}`);
    dispatch(
      settingsActions.set({
        adminPage: item.id,
      })
    );
  };

  if (role === "ADMIN") {
    return <Drawer items={Items} itemOnChange={itemOnChange} active={active} />;
  }
  return null;
};
