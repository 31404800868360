import { createAction, createActionTypes } from "../../utils";

export const COMMON_ACTIONS = createActionTypes("COMMON_ACTIONS", [
  "REQUEST_MEANINGS",
  "SUCCESS_MEANINGS",
  "FAILURE_MEANINGS",

  "REQUEST_ACCOUNT_SUBTYPE",
  "SUCCESS_ACCOUNT_SUBTYPE",
  "FAILURE_ACCOUNT_SUBTYPE",

  "REQUEST_USER_ROLE",
  "SUCCESS_USER_ROLE",
  "FAILURE_USER_ROLE",

  "REQUEST_COUNTRIES",
  "SUCCESS_COUNTRIES",
  "FAILURE_COUNTRIES",
]);

export const commonActions = {
  requestMeanings: () =>
    createAction(COMMON_ACTIONS.REQUEST_MEANINGS, {
      fetching: true,
      success: false,
      error: null,
    }),
  successMeanings: (data) =>
    createAction(COMMON_ACTIONS.SUCCESS_MEANINGS, {
      ...data,
      fetching: false,
      success: true,
      error: null,
    }),
  failureMeanings: (error) =>
    createAction(COMMON_ACTIONS.FAILURE_MEANINGS, {
      ...error,
      fetching: false,
      success: false,
    }),

  requestAccountSubtypes: () =>
    createAction(COMMON_ACTIONS.REQUEST_ACCOUNT_SUBTYPE, {
      fetching: true,
      success: false,
      error: null,
    }),
  successAccountSubtypes: (data) =>
    createAction(COMMON_ACTIONS.SUCCESS_ACCOUNT_SUBTYPE, {
      ...data,
      fetching: false,
      success: true,
      error: null,
    }),
  failureAccountSubtypes: (error) =>
    createAction(COMMON_ACTIONS.FAILURE_ACCOUNT_SUBTYPE, {
      ...error,
      fetching: false,
      success: false,
    }),

  requestUserRoles: () =>
    createAction(COMMON_ACTIONS.REQUEST_USER_ROLE, {
      fetching: true,
      success: false,
      error: null,
    }),
  successUserRoles: (data) =>
    createAction(COMMON_ACTIONS.SUCCESS_USER_ROLE, {
      ...data,
      fetching: false,
      success: true,
      error: null,
    }),
  failureUserRoles: (error) =>
    createAction(COMMON_ACTIONS.FAILURE_USER_ROLE, {
      ...error,
      fetching: false,
      success: false,
    }),

  requestCountries: () =>
    createAction(COMMON_ACTIONS.REQUEST_COUNTRIES, {
      fetching: true,
      success: false,
      error: null,
    }),
  successCountries: (data) =>
    createAction(COMMON_ACTIONS.SUCCESS_COUNTRIES, {
      ...data,
      fetching: false,
      success: true,
      error: null,
    }),
  failureCountries: (error) =>
    createAction(COMMON_ACTIONS.FAILURE_COUNTRIES, {
      ...error,
      fetching: false,
      success: false,
    }),
};
