import { createAction, createActionTypes } from "../../utils";

export const PLAN_ACTIONS = createActionTypes("PLAN_ACTIONS", [
  "REQUEST",
  "SUCCESS",
  "FAILURE",

  "BASE_PLANS_REQUEST",
  "BASE_PLANS_SUCCESS",
  "BASE_PLANS_FAILURE",

  "ALL_USER_PLANS_REQUEST",
  "ALL_USER_PLANS_SUCCESS",
  "ALL_USER_PLANS_FAILURE",

  "USER_PLANS_REQUEST",
  "USER_PLANS_SUCCESS",
  "USER_PLANS_FAILURE",
  "USER_PLANS_UPDATE",

  "REQUEST_ONE",
  "ONE_SUCCESS",
  "ONE_FAILURE",

  "CLEAR",

  "SYNC",
  "SYNC_SUCCESS",
  "SYNC_FAILURE",

  "SESSION",
  "SESSION_SUCCESS",
  "SESSION_FAILURE",
]);

export const planActions = {
  request: (data) =>
    createAction(PLAN_ACTIONS.REQUEST, {
      ...data,
      fetching: true,
      success: false,
      error: null,
    }),
  success: ({ data }) =>
    createAction(PLAN_ACTIONS.SUCCESS, {
      data,
      fetching: false,
      success: true,
      error: null,
    }),
  failure: (error) =>
    createAction(PLAN_ACTIONS.FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  basePlansRequest: (data) =>
    createAction(PLAN_ACTIONS.BASE_PLANS_REQUEST, {
      ...data,
      fetching: true,
      success: false,
      error: null,
    }),
  basePlansSuccess: ({ data }) =>
    createAction(PLAN_ACTIONS.BASE_PLANS_SUCCESS, {
      data,
      fetching: false,
      success: true,
      error: null,
    }),
  basePlansFailure: (error) =>
    createAction(PLAN_ACTIONS.BASE_PLANS_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  allUserPlansRequest: (data) =>
    createAction(PLAN_ACTIONS.ALL_USER_PLANS_REQUEST, {
      ...data,
      fetching: true,
      success: false,
      error: null,
    }),
  allUserPlansSuccess: ({ data, pageNum }) =>
    createAction(PLAN_ACTIONS.ALL_USER_PLANS_SUCCESS, {
      data,
      pageNum,
      fetching: false,
      success: true,
      error: null,
    }),
  allUserPlansFailure: (error) =>
    createAction(PLAN_ACTIONS.ALL_USER_PLANS_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  userPlansRequest: (data) =>
    createAction(PLAN_ACTIONS.USER_PLANS_REQUEST, {
      ...data,
      fetching: true,
      success: false,
      error: null,
    }),
  userPlansUpdate: (data) =>
    createAction(PLAN_ACTIONS.USER_PLANS_UPDATE, {
      ...data,
      fetching: true,
      success: false,
      error: null,
    }),
  userPlansSuccess: ({ data }) =>
    createAction(PLAN_ACTIONS.USER_PLANS_SUCCESS, {
      data,
      fetching: false,
      success: true,
      error: null,
    }),
  userPlansFailure: (error) =>
    createAction(PLAN_ACTIONS.USER_PLANS_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  requestOne: (id) =>
    createAction(PLAN_ACTIONS.REQUEST_ONE, {
      id,
      fetching: true,
      success: false,
      error: null,
    }),
  oneSuccess: ({ data }) =>
    createAction(PLAN_ACTIONS.ONE_SUCCESS, {
      data,
      fetching: false,
      success: true,
      error: null,
    }),
  oneFailure: (error) =>
    createAction(PLAN_ACTIONS.ONE_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  sync: () =>
    createAction(PLAN_ACTIONS.SYNC, {
      fetching: true,
      success: false,
      error: null,
    }),
  syncSuccess: ({ data }) =>
    createAction(PLAN_ACTIONS.SYNC_SUCCESS, {
      data,
      fetching: false,
      success: true,
      error: null,
    }),
  syncFailure: (error) =>
    createAction(PLAN_ACTIONS.SYNC_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  session: (session) =>
    createAction(PLAN_ACTIONS.SESSION, {
      session,
      fetching: true,
      success: false,
      error: null,
    }),
  sessionSuccess: ({ data }) =>
    createAction(PLAN_ACTIONS.SESSION_SUCCESS, {
      data,
      fetching: false,
      success: true,
      error: null,
    }),
  sessionFailure: (error) =>
    createAction(PLAN_ACTIONS.SESSION_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),
  clear: () =>
    createAction(PLAN_ACTIONS.CLEAR, {
      data: [],
      fetching: true,
      success: false,
      error: null,
    }),
};
