import { createAction, createActionTypes } from "../../utils";

export const AUTH_ACTIONS = createActionTypes("AUTH_ACTIONS", [
  "SET_TOKEN",
  "REQUEST",
  "SUCCESS",
  "FAILURE",
  "DELETE",
  "CLEAR",
  "USER_REQUEST",
  "USER_SUCCESS",
  "USER_FAILURE",
  "TOKEN_REQUEST",
  "TOKEN_SUCCESS",
  "TOKEN_FAILURE",
]);

export const authActions = {
  setToken: (data) =>
    createAction(AUTH_ACTIONS.SET_TOKEN, {
      data,
    }),
  request: (data) =>
    createAction(AUTH_ACTIONS.REQUEST, {
      ...data,
      fetching: true,
      success: false,
      error: null,
    }),
  success: (data) =>
    createAction(AUTH_ACTIONS.SUCCESS, {
      ...data,
      fetching: false,
      success: true,
      error: null,
    }),
  failure: (error) =>
    createAction(AUTH_ACTIONS.FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),
  clear: () =>
    createAction(AUTH_ACTIONS.CLEAR, {
      data: {},
      user: {},
      fetching: false,
      success: false,
      error: null,
    }),

  userRequest: (user) =>
    createAction(AUTH_ACTIONS.USER_REQUEST, {
      ...user,
      fetching: true,
      success: false,
      error: null,
    }),
  userSuccess: (data) =>
    createAction(AUTH_ACTIONS.USER_SUCCESS, {
      ...data,
      fetching: false,
      success: true,
      error: null,
    }),
  userFailure: (error) =>
    createAction(AUTH_ACTIONS.USER_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  tokenRequest: (data) =>
    createAction(AUTH_ACTIONS.TOKEN_REQUEST, {
      ...data,
      fetching: true,
      success: false,
      error: null,
    }),
  tokenSuccess: (data) =>
    createAction(AUTH_ACTIONS.TOKEN_SUCCESS, {
      ...data,
      fetching: false,
      success: true,
      error: null,
    }),
  tokenFailure: (error) =>
    createAction(AUTH_ACTIONS.TOKEN_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  delete: () => createAction(AUTH_ACTIONS.DELETE, {}),
};
