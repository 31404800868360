import React from "react";
import moment from "moment";
import { NavHashLink } from "react-router-hash-link";
import styled from "styled-components";
import { formatShortDates } from "../../utils";

import {
  Row,
  Column,
  Toolbar,
  Label,
  List,
  ListItem,
  Spinner,
  Html,
  ContentGrid,
  NoContent,
} from "../../components";

const Menu = styled(Column)`
  position: sticky;
  top: -20px;
`;

export const Highlights = ({
  activeTab,
  monitorName,
  highlights,
  highlightsLoading,
  onDateClick,
}) => (
  <Column>
    {highlightsLoading ? (
      <Row expand paddingAll center>
        <Spinner />
      </Row>
    ) : (
      <Column>
        {highlights?.length > 0 ? (
          <Column>
            <ContentGrid>
              <Column>
                {highlights.map((item, index) => (
                  <Column
                    id={`highlight-${item.id}`}
                    key={item.id}
                    marginTop={index === 0 ? "20" : "40"}
                  >
                    <Row marginBottom>
                      <Label large bold>
                        {formatShortDates(item.startDate, item.endDate, true)}
                      </Label>
                    </Row>
                    <div key={item.id}>
                      <Html string={item.content} />
                    </div>
                  </Column>
                ))}
              </Column>
              <Row show="laptopL">
                <List sticky>
                  <Menu>
                    {highlights.map((item, index) => (
                      <NavHashLink
                        id={`highlight-menu-${item.id}`}
                        key={`menu-${item.id}`}
                        smooth
                        to={`/${window.location.search}#highlight-${item.id}`}
                        activeClassName="active"
                      >
                        <ListItem gap="10">
                          <Label small color="gray" fade="6">
                            {index + 1}
                          </Label>
                          <Label small>
                            {formatShortDates(
                              item.startDate,
                              item.endDate,
                              true
                            )}
                          </Label>
                        </ListItem>
                      </NavHashLink>
                    ))}
                  </Menu>
                </List>
              </Row>
            </ContentGrid>
          </Column>
        ) : (
          <NoContent label="highlights" {...{ onDateClick }} />
        )}
      </Column>
    )}
  </Column>
);
