import React, { useState, useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";

import {
  Header,
  Drawer,
  Circle,
  H4,
  Row,
  Icon,
  Label,
  Toast,
} from "../../../components";
import { Color } from "../../../utils/variables";

export const HeaderAndDrawer = ({ signUpClick }) => {
  const location = useLocation();
  const path = location.pathname;

  const [menu, setMenu] = useState([
    {
      id: "congress_influencers",
      label: "Congress influencers",
      dropdown: true,
    },
    {
      id: "disease_influencers",
      label: "Disease influencers",
      dropdown: true,
    },
    {
      id: "disease_insights",
      label: "Disease insights",
      dropdown: true,
    },
  ]);

  useEffect(() => {
    const newMenu = menu.map((item) => ({
      ...item,
      active: path.includes(item.id),
      to: path.includes(item.id) ? undefined : `/${item.id}`,
    }));
    setMenu(newMenu);
  }, [path]);

  const handleMenuItemChange = (item) => {
    if (item.to) {
      window.location.href = item.to;
    }
  };

  return (
    <>
      <Header isHome login user {...{ menu, signUpClick }} />
      <Drawer
        login
        headerMenu
        items={menu}
        active={menu.find((item) => item.active)?.id}
        itemOnChange={handleMenuItemChange}
        signUpClick={signUpClick}
      />
    </>
  );
};

export const Counter = ({ number }) => {
  return (
    <Circle radius="17" bkg="gray" fade="1">
      <H4 bold>{number}</H4>
    </Circle>
  );
};

const ButtonWrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${Color("gray", "4")};
  background-color: white;
  color: black;
  border-radius: 4px;
  transition: all 0.3s ease;
  width: 44px;
  height: 44px;
  cursor: pointer;

  &:hover {
    background-color: ${Color("gray", "1")} !important;
  }

  &:focus {
    outline: none;
    border: 1px solid ${Color("gray", "4")};
  }

  svg {
    fill: black !important;
    width: 20px !important;
    height: 20px !important;
  }
`;

const AnchorWrapper = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${Color("gray", "4")};
  background-color: white;
  color: black;
  border-radius: 4px;
  transition: all 0.3s ease;
  width: 44px;
  height: 44px;
  cursor: pointer;

  &:hover {
    background-color: ${Color("gray", "1")} !important;
  }

  svg {
    fill: black !important;
    width: 20px !important;
    height: 20px !important;
  }
`;

const ShareButton = ({ icon, onClick }) => {
  return (
    <ButtonWrapper onClick={() => onClick?.() || {}}>
      <Icon name={icon} />
    </ButtonWrapper>
  );
};

const ShareAnchor = ({ icon, blank, to }) => {
  return (
    <AnchorWrapper href={to} target={blank ? "_blank" : undefined}>
      <Icon name={icon} />
    </AnchorWrapper>
  );
};

export const Share = ({ title }) => {
  const [showToast, setShowToast] = useState(false);

  const handleCopyToClipboard = () => {
    setShowToast(true);
  };

  return (
    <Row gap="10" middle>
      <Label>Share with your friends!</Label>
      <CopyToClipboard
        text={window.location.href}
        onCopy={() => handleCopyToClipboard()}
      >
        <ShareButton icon="link" />
      </CopyToClipboard>
      <ShareAnchor
        to={`mailto:?subject=${title}&body=${window.location.href}`}
        blank
        icon="email"
      />
      <FacebookShareButton url={window.location.href} title={title}>
        <ShareButton icon="facebook" />
      </FacebookShareButton>
      <TwitterShareButton url={window.location.href} title={title}>
        <ShareButton icon="twitter" />
      </TwitterShareButton>
      <LinkedinShareButton url={window.location.href} title={title}>
        <ShareButton icon="linkedin" />
      </LinkedinShareButton>

      <Toast
        visible={showToast}
        toggleVisibility={setShowToast}
        expiration={2000}
      >
        <Row gap="5" middle>
          <Icon name="check" />
          <Label>URL copied to your clipboard!</Label>
        </Row>
      </Toast>
    </Row>
  );
};
