import React from "react";
import {
  Row,
  Label,
  Column,
  H3,
  H4,
  Anchor,
  Circle,
  Button,
} from "../../../components";

export const Bullet = ({ number, children }) => (
  <Row gap="10" middle>
    <Circle bkg="white" radius="20">
      <Label large bold>
        {number}
      </Label>
    </Circle>
    {children}
  </Row>
);

export const Footer = ({ first, firstLink, second, seeMore }) => (
  <Column stretch maxWidth="600">
    <Column bkg="green" fade="2" paddingX paddingY="40" radius="10">
      <H3 bold>Suggestions</H3>
      <Column marginTop gap="20">
        <Bullet number="1">
          <Label large bold>
            {first || "Bring up at your next meeting."}
            {firstLink && (
              <Anchor
                inline
                color="black"
                line
                large
                bold
                label={firstLink.label}
                to={firstLink.to}
                blank
              />
            )}
          </Label>
        </Bullet>
        <Bullet number="2">
          <Label large bold>
            {second || "Tell us what you think."}{" "}
            <Anchor
              inline
              color="black"
              line
              large
              bold
              label="Share feedback"
              to={
                "mailto:hello@medical.watch?subject=My feedback on Medical.watch"
              }
              blank
            />
          </Label>
        </Bullet>
      </Column>
    </Column>

    {seeMore && (
      <Column
        bkg="gray"
        fade="1"
        paddingX
        paddingY="40"
        radius="10"
        marginTop="20"
        center
      >
        <H4 bold center>
          {seeMore}
        </H4>
        <Row marginTop="40">
          <Button
            label="Visit Medical.watch"
            to="https://my.medical.watch/"
            blank
          />
        </Row>
      </Column>
    )}

    <Column marginTop="30" gap="2">
      <Label color="gray" small fade="6">
        Search for biomarkers, trials and more on{" "}
        <Anchor
          inline
          line
          bold
          label="Medical.watch"
          to="https://my.medical.watch/"
          blank
        />
      </Label>
      <Label color="gray" small fade="6">
        Best practices, tips and more:{" "}
        <Anchor
          inline
          line
          bold
          label="resources.medical.watch"
          to="https://resources.medical.watch/"
          blank
        />
      </Label>
      <Row marginTop>
        <Label color="gray" small fade="6">
          Questions? Just reply to this email.
        </Label>
      </Row>
      <Row marginTop>
        <Label color="gray" small fade="6">
          © 2023 Medical.watch
        </Label>
      </Row>
      <Row>
        <Label color="gray" small fade="6">
          135 Commonwealth Drive, Menlo Park, CA 94025
        </Label>
      </Row>
    </Column>
  </Column>
);
