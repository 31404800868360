import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { monitorActions } from "../../store/actions";

import { view } from "./WatchListView";

export const WatchList = ({}) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth.data);
  const contentRef = useRef(null);

  useEffect(() => {
    return () => {
      // while unmounting, make it sure cache is updated properly
      dispatch(monitorActions.monitorsRequest(auth.id));
    };
  }, []);

  return view({
    isAdmin: auth?.mwRole === "ADMIN",
    contentRef,
  });
};
