import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useInfiniteScroll from "react-infinite-scroll-hook";

import useDebounce from "../../../utils/useDebounce";
import { conferenceActions } from "../../../store/actions";
import { cancelConferenceRequests } from "../../../store/sagas";
import { view } from "./ConferencesView";

export const AdminConferences = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  // --> mapStateToProp
  const conferences = useSelector((state) => state.conference.all);
  // <-- mapStateToProp

  // --> STATE
  const [statusItems] = useState([
    { id: "ALL", label: "All" },
    { id: "ACTIVE", label: "Active" },
    { id: "BLACKLISTED", label: "Inactive" },
  ]);
  const [selectedStatusId, setSelectedStatusId] = useState("ACTIVE");

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const debounceTerm = useDebounce(searchTerm, 700);
  // <-- STATE

  // --> EFFECT
  useEffect(() => {
    return () => {
      dispatch(conferenceActions.clearAll());
      cancelConferenceRequests.abort();
    };
  }, []);

  useEffect(() => {
    requestConferences(0);
    setPage(0);
  }, [dispatch, debounceTerm, selectedStatusId]);

  useEffect(() => {
    setLoading(conferences.fetching);
  }, [conferences.fetching]);

  // <-- EFFECT

  const requestConferences = (pageNum) => {
    let ignore = null;
    let onlyParents = true;
    switch (selectedStatusId) {
      case "ACTIVE": // active
        ignore = false;
        break;
      case "BLACKLISTED": // inactive
        ignore = true;
        break;
    }

    dispatch(
      conferenceActions.request({
        ignore,
        onlyParents,
        pageNum,
        ...(searchTerm !== "" && { term: searchTerm }),
        nocache: true,
        details: true,
      })
    );
  };

  const handleMoreItems = () => {
    if (!loading) {
      const newPage = page + 1;
      requestConferences(newPage);
      setPage(newPage);
    }
  };

  const [infiniteScrollRef] = useInfiniteScroll({
    loading,
    hasNextPage: !conferences.isLastPage,
    onLoadMore: handleMoreItems,
  });

  const handleStatusChange = (item) => {
    setSelectedStatusId(item.id);
  };

  const getActiveFilters = () => {
    let activeFilters = [];
    if (searchTerm) {
      activeFilters.push(`Search: ${searchTerm}`);
    }
    if (selectedStatusId !== "ALL") {
      activeFilters.push(
        `Status: ${statusItems.find((a) => selectedStatusId === a.id)?.label}`
      );
    }
    return activeFilters;
  };

  return view({
    history,
    conferences: conferences.data.filter((c) => !c.parentId) || [],
    infiniteScrollRef,
    hasNextPage: !conferences.isLastPage,
    loading,
    searchTerm,
    setSearchTerm,
    activeFilters: getActiveFilters(),
    selectedStatusId,
    handleStatusChange,
    statusItems,
  });
};
