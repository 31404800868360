import React from "react";
import styled from "styled-components";
import {
  MainWrapper,
  Column,
  Header,
  Row,
  H3,
  H4,
  H2,
  Dropdown,
  P,
  Label,
  Button,
  Spinner,
  Grid,
  Icon,
  Pill,
  Card,
  HR,
  Anchor,
  Footer,
  Drawer,
  Metadata,
} from "../../components";
import { Color, device } from "../../utils/variables";

const BkgColumn = styled(Column)`
  background: url(/images/pricing_bkg.jpg) no-repeat center 300px;
  background-size: contain;
`;

const GradientColumn = styled(Column)`
  background: linear-gradient(180deg, rgba(247, 247, 247, 0) 0%, #eeeeef 100%);
`;

const Quote = styled(Column)`
  position: relative;
  margin-top: 110px;

  &:after {
    content: "“";
    position: absolute;
    top: -80px;
    font-size: 100px;
    color: ${Color("green", "3")};
  }
`;

const StyledColumn = styled(Column)`
  border-radius: 10px;
  padding: 15px;
  border: 1px solid ${Color("gray", "4")};
  height: 100%;
  ${(props) =>
    props.popular &&
    ` 
      background: linear-gradient(
        180deg,
        rgba(212, 244, 233, 0.2) 0%,
        rgba(212, 244, 233, 0.5) 100%
      );
      border: 1px solid ${Color("green", "2")};
  `}
`;

// background: linear-gradient(180deg, rgba(42, 198, 151, 0.4) 0%, #2ac697 100%),
//   linear-gradient(
//     180deg,
//     rgba(212, 244, 233, 0.2) 0%,
//     rgba(212, 244, 233, 0.5) 100%
//   );

const UL = styled(Column)`
  margin-top: 20px;
  margin-bottom: 40px;
`;

const LI = styled(Row)`
  gap: 8px;
  display: flex;
  align-items: center;

  & + & {
    margin-top: 20px;
  }

  div {
    margin-bottom: 0;
  }
`;

export const Item = ({ text }) => (
  <LI>
    <Row noShrink>
      <Icon name="check" color="green" />
    </Row>
    <P>{text}</P>
  </LI>
);

const menu = [
  { id: "marketing", label: "Marketing", to: "/", dropdown: true },
  {
    id: "medicalAffairs",
    label: "Medical Affairs",
    to: "/?section=medicalAffairs",
    dropdown: true,
  },
  {
    id: "sales",
    label: "Sales",
    to: "/?section=sales",
    dropdown: true,
  },
  // { id: "pricing", label: "Pricing" },
];

export const view = ({
  goToBasic,
  goToSignup,
  goToPro,
  goToSales,

  period,
  periods,
  handlePeriodChange,

  handleSignUpClick,
  handleConferenceListClick,

  planPrice,
  userPlan,
  isActive,
}) => {
  return (
    <MainWrapper className="dashboard">
      <Metadata />
      <Header login user menu={menu} signUpClick={handleSignUpClick} />
      <Drawer
        login
        headerMenu
        items={menu.filter((item) => item.dropdown)}
        itemOnChange={(item) => {
          if (item.to) {
            window.location.href = item.to;
          }
        }}
        signUpClick={handleSignUpClick}
      />
      <Column>
        <BkgColumn center paddingTop="110" paddingX="60">
          <Column bkg="white">
            <Row marginBottom="30" center>
              <H3 bold>
                See what HCPs are saying about your brand on social media
              </H3>
            </Row>
            {planPrice?.basicYearly?.price && planPrice?.proYearly?.price ? (
              <>
                <Column center marginBottom="40">
                  <Row marginBottom expand center gap="10" middle>
                    <Label bold>Billing frequency</Label>
                    <Dropdown
                      select
                      maxWidth="160"
                      items={periods}
                      value={period}
                      onChange={handlePeriodChange}
                    />
                  </Row>
                  <Label color="gray" fade="7">
                    Save 10% with yearly billing 😍
                  </Label>
                </Column>
                <Grid fourToTwo>
                  <StyledColumn>
                    <Row marginBottom="10">
                      <H3 bold>Free</H3>
                    </Row>
                    <P color="gray" fade="7">
                      See what HCPs are saying on social media about your
                      disease
                    </P>
                    <Row marginTop="60">
                      <H2>Free</H2>
                    </Row>

                    <Row
                      center
                      marginTop={period === "YEAR" ? "60" : "80"}
                      marginBottom="20"
                    >
                      <Button
                        small
                        secondary
                        maxWidth="200"
                        label="Sign up"
                        onClick={goToSignup}
                        disabled={userPlan || isActive}
                      />
                    </Row>
                    <UL>
                      <Item text="Track 1 disease with weekly reports" />
                      <Item text="Daily reports for 1 congress" />
                      <Item text="Track 1 brand with daily alerts (free for 30 days)" />
                      <Item text="Unlimited search for HCP and Institution profiles" />
                    </UL>
                  </StyledColumn>
                  <StyledColumn>
                    <Row marginBottom="10">
                      <H3 bold>Basic</H3>
                    </Row>
                    <P color="gray" fade="7">
                      Discover what HCPs are saying on social media about your
                      brand and competitors
                    </P>
                    {period === "YEAR" ? (
                      <Row marginTop="65" bottom gap="5">
                        <H2>$179</H2>
                        <Label color="gray" fade="7">
                          /month billed yearly
                        </Label>
                      </Row>
                    ) : (
                      <Column marginBottom="-20">
                        <Row marginTop="65" bottom gap="5" marginBottom="10">
                          <H2>$199</H2>
                          <Label color="gray" fade="7">
                            /month
                          </Label>
                        </Row>
                        <Row>
                          <Pill
                            bkg="green"
                            fade="1"
                            small
                            noMargin
                            maxWidth="200"
                            label="Save $240 with yearly"
                          />
                        </Row>
                      </Column>
                    )}
                    <Row center marginTop="55">
                      <Button
                        small
                        maxWidth="200"
                        label="Buy Now"
                        onClick={goToBasic}
                        // disabled={userPlan || isActive}
                      />
                    </Row>
                    <Row marginTop="40">
                      <Label bold>Everything in Free, plus:</Label>
                    </Row>
                    <UL>
                      <Item text="Up to 2 brands and 2 diseases" />
                      <Item text="Bi-weekly reports for Top Influencers" />
                      <Item text="Daily reports for up to 2 congresses across your diseases" />
                      <Item text="Advanced HCP and Institution search filters" />
                    </UL>
                  </StyledColumn>
                  <StyledColumn popular>
                    <Row marginBottom="10">
                      <H3 bold>Pro</H3>
                      <Row fit right>
                        <Pill
                          bold
                          bkg="green"
                          fade="1"
                          small
                          noMargin
                          label="Most popular"
                        />
                      </Row>
                    </Row>
                    <P color="gray" fade="7">
                      Track your brand portfolio and congresses while
                      accelerating your business impact with AI analytics and
                      reports{" "}
                    </P>

                    {period === "YEAR" ? (
                      <Row marginTop="20" bottom gap="5">
                        <H2>$329</H2>
                        <Label color="gray" fade="7">
                          /month billed yearly
                        </Label>
                      </Row>
                    ) : (
                      <Column marginBottom="-20">
                        <Row marginTop="20" bottom gap="5" marginBottom="10">
                          <H2>$369</H2>
                          <Label color="gray" fade="7">
                            /month
                          </Label>
                        </Row>
                        <Row>
                          <Pill
                            bkg="green"
                            fade="1"
                            small
                            noMargin
                            maxWidth="200"
                            label="Save $480 with yearly"
                          />
                        </Row>
                      </Column>
                    )}
                    <Row marginTop="50" center>
                      <Button
                        small
                        maxWidth="200"
                        label="Buy Now"
                        onClick={goToPro}
                        // disabled={userPlan || isActive}
                      />
                    </Row>
                    <Row marginTop="40">
                      <Label bold>Everything in Basic, plus:</Label>
                    </Row>
                    <UL>
                      <Item text="Up to 10 brands and 10 diseases" />
                      <Item text="Get reports for your territory and indication" />
                      <Item text="Export brand insights and Top Influencers to PowerPoint" />
                      <Item text="Daily reports for up to 10 congresses across your diseases" />
                      <Item text="AI chat to uncover market trends and medical insights" />
                    </UL>
                  </StyledColumn>
                  <StyledColumn>
                    <Row marginBottom="10">
                      <H3 bold>Enterprise</H3>
                    </Row>
                    <P color="gray" fade="7">
                      Drive your omnichannel marketing and field activities with
                      real-time HCP engagement data across your portfolio{" "}
                    </P>
                    <Row marginTop={period === "YEAR" ? "110" : "130"} center>
                      <Button
                        small
                        maxWidth="200"
                        label="Contact Sales"
                        onClick={goToSales}
                      />
                    </Row>
                    <Row marginTop="40">
                      <Label bold>Everything in Pro, plus:</Label>
                    </Row>
                    <UL>
                      <Item text="Unlimited brands and diseases" />
                      <Item text="Access real-time data for your CRM and NBA platforms across your portfolio" />
                      <Item text="Concierge service with custom onboarding, customer success and ongoing check-ins" />
                      <Item text="Automated monthly insights and market research reports" />
                      <Item text="Daily reports for unlimited congresses across all your diseases" />
                      <Item text="AI chat+ for Enterprise" />
                    </UL>
                  </StyledColumn>
                </Grid>
              </>
            ) : (
              <Column center middle>
                <Spinner />
              </Column>
            )}
          </Column>
        </BkgColumn>

        <GradientColumn center paddingY="120" paddingX="60">
          <H2 center bold>
            What customers are saying
          </H2>
          <Grid columns="3" gap="50">
            <Quote>
              <Column gap="10">
                <P>
                  We found HCP influencers who weren't on our radar. I really
                  like the sentiment ratings so you can see which influencers
                  are advocates for our product.
                </P>
                <Label color="gray" bold fade="6">
                  Executive Director, Marketing, Gilead
                </Label>
              </Column>
            </Quote>
            <Quote>
              <Column gap="10">
                <P>
                  We use real-time social media data as triggers in the
                  omni-channel journey to create better engagement with our
                  targets.
                </P>
                <Label color="gray" bold fade="6">
                  Sr. Director, Data Science and Commercial Analytics, Novartis
                </Label>
              </Column>
            </Quote>
            <Quote>
              <Column gap="10">
                <P>
                  It's important to know what the feelings are in the real world
                  and get a pulse. If HCPs are talking about our competitor and
                  not us, it's important to know why. This could inform our
                  medical strategy.
                </P>
                <Label color="gray" bold fade="6">
                  MSL Director, AbbVie
                </Label>
              </Column>
            </Quote>
          </Grid>
        </GradientColumn>
        <Column center>
          <Column maxWidth={1130} paddingY="120" paddingX="60">
            <H2 center bold>
              Frequently asked questions
            </H2>
            <Card border marginTop="80">
              <Column marginTop gap="12">
                <Label bold>Which brands and diseases do you cover?</Label>
                <P>
                  We support all brands and diseases in oncology/hematology and
                  neurology. To request support for a brand, disease or
                  congress, email us at{" "}
                  <Anchor
                    label="hello@medical.watch"
                    line
                    blank
                    to="mailto:hello@medical.watch"
                  />
                  .
                </P>
              </Column>
              <Row marginTop>
                <HR />
              </Row>
              <Column marginTop gap="12">
                <Label bold>Which medical congresses do you cover?</Label>
                <P>
                  Medical.watch tracks oncology/hematology and neurology
                  congresses including ASCO, ESMO, ASH, AAN and more. See the{" "}
                  <Anchor
                    label="full list"
                    line
                    onClick={() => {
                      handleConferenceListClick();
                      window.open(
                        "https://www.resources.medical.watch/post/medical-congress-coverage",
                        "_blank"
                      );
                    }}
                  />
                  .
                </P>
              </Column>
              <Row marginTop>
                <HR />
              </Row>
              <Column marginTop gap="12">
                <Label bold>How do I change my brands and diseases?</Label>
                <P>
                  Just tap "Manage Preferences" to change your brands and
                  diseases.
                </P>
              </Column>
              <Row marginTop>
                <HR />
              </Row>
              <Column marginTop gap="12">
                <Label bold>Can I customize my email alerts?</Label>
                <P>
                  Just tap "Manage Preferences" to change your email frequency
                  to weekly, daily, instant or never. If you're on a Pro or
                  Enterprise plan, you can customize your results by indication
                  and region.
                </P>
              </Column>
              <Row marginTop>
                <HR />
              </Row>
              <Column marginTop gap="12">
                <Label bold>Do we have to give you a list of HCPs?</Label>
                <P>
                  We automatically track all verified HCPs in your disease
                  categories. No need to give us a list.
                </P>
              </Column>
              <Row marginTop>
                <HR />
              </Row>
              <Column marginTop gap="12">
                <Label bold>
                  How do you verify HCPs? Do you use the Twitter blue
                  checkmarks?
                </Label>
                <P>
                  We verify HCPs using our own AI processes. We do not use
                  verification processes from Twitter or other platforms.
                </P>
              </Column>
              <Row marginTop>
                <HR />
              </Row>
              <Column marginTop gap="12">
                <Label bold>Can I send you a list of target HCPs?</Label>
                <P>
                  To see our coverage for your targets, please email your target
                  list to{" "}
                  <Anchor
                    label="hello@medical.watch"
                    line
                    blank
                    to="mailto:hello@medical.watch"
                  />
                  . We'll get back to you in 24 hours.
                </P>
              </Column>
              <Row marginTop>
                <HR />
              </Row>
              <Column marginTop gap="12">
                <Label bold>Does your free trial require a credit card?</Label>
                <P>
                  No credit card required. You can sign up for a Free Trial to
                  our premium subscription plans without a credit card.
                </P>
              </Column>
              <Row marginTop>
                <HR />
              </Row>
              <Column marginTop gap="12">
                <Label bold>Is it easy to cancel?</Label>
                <P>
                  Cancel anytime. Tap "Manage Preferences", then tap "Manage
                  billing info" to cancel your subscription.
                </P>
              </Column>
              <Row marginTop>
                <HR />
              </Row>
              <Column marginTop gap="12" marginBottom="10">
                <Label bold>Can I request a refund?</Label>
                <P>
                  Please email us at{" "}
                  <Anchor
                    label="hello@medical.watch"
                    line
                    blank
                    to="mailto:hello@medical.watch"
                  />{" "}
                  to request a refund. When you cancel a subscription, your plan
                  remains active through the end of your billing cycle.
                </P>
              </Column>
            </Card>
          </Column>
        </Column>
        <Footer />
      </Column>
    </MainWrapper>
  );
};
