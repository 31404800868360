import React from "react";

import {
  Row,
  Column,
  MainWrapper,
  Content,
  Label,
  Anchor,
  TextLogo,
  Metadata,
} from "../../components";
import { CreateUser } from "./CreateUser";

export const view = ({
  checkoutEmail,
  selectedPlan,
  showEmail,

  data,
  errors,
  handleUpdateData,
  isSubmitPressed,
  loading,
  emailSent,
  history,
  step,
  handleNext,
  handleModalBack,

  diseases,
  setSearchDisease,
  searchDisease,
  selectedDiseases,
  addSelectedDisease,
  addSelectedDiseases,
  removeSelectedDiseases,
  clearSelectedDiseases,
  diseasesInfiniteScroll,

  products,
  setSearchProduct,
  searchProduct,
  selectedProducts,
  addSelectedProduct,
  addSelectedProducts,
  removeSelectedProducts,
  clearSelectedProducts,
  productsInfiniteScroll,

  nextDisabled,
  backendError,
  resendEmail,
  pressedResend,
  missingData,
}) => (
  <MainWrapper noHeader>
    <Metadata />
    <Content bkg="gray" fade="1">
      <Column center>
        <Row paddingBottom>
          <TextLogo />
        </Row>
        <CreateUser
          {...{
            checkoutEmail,
            selectedPlan,
            showEmail,

            data,
            errors,
            handleUpdateData,
            isSubmitPressed,
            loading,
            emailSent,
            history,
            step,
            handleNext,
            handleModalBack,

            diseases,
            setSearchDisease,
            searchDisease,
            selectedDiseases,
            addSelectedDisease,
            addSelectedDiseases,
            removeSelectedDiseases,
            clearSelectedDiseases,
            diseasesInfiniteScroll,

            products,
            setSearchProduct,
            searchProduct,
            selectedProducts,
            addSelectedProduct,
            addSelectedProducts,
            removeSelectedProducts,
            clearSelectedProducts,
            productsInfiniteScroll,

            nextDisabled,
            backendError,
            resendEmail,
            pressedResend,
            missingData,
          }}
        />
        {step === 2 && (
          <Column marginTop="40" gap="10">
            <Label center small>
              By signing up for Medical.watch, you agree to our
              <Anchor
                small
                inline
                line
                label="Terms of Service"
                to="/terms"
                blank
              />{" "}
              and{" "}
              <Anchor
                small
                inline
                line
                label="Privacy Policy"
                to="/privacy"
                blank
              />
              .
            </Label>
            <Label center small>
              We don't share your data with anyone. Cancel anytime.
            </Label>
          </Column>
        )}
      </Column>
    </Content>
  </MainWrapper>
);
