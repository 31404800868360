import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { view } from "./AiChatView";
import { chatActions } from "../../store/actions";
import { cancelChatRequests } from "../../store/sagas";
import useKeyPress from "../../utils/useKeyPress";
import { logAnalyticEvent } from "../../utils";

export const AiChat = () => {
  const { id } = useParams();
  const scrollRef = useRef(null);

  const dispatch = useDispatch();
  const enterPress = useKeyPress("Enter");

  const auth = useSelector((state) => state.auth.data);
  const chats = useSelector((state) => state.chat.all);
  const chat = useSelector((state) => state.chat.one);
  const newChat = useSelector((state) => state.chat.additional);
  const initialExamples = useSelector((state) => state.ai.chat);
  const userPlans = useSelector((state) => state.plan.userPlans);

  const [sessionId, setSessionId] = useState(id);
  const [data, setData] = useState({});
  const [question, setQuestion] = useState("");
  const [waitForQuestion, setWaitForQuestion] = useState("");
  // const [examples, setExamples] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [regenerate, setRegenerate] = useState(false);
  const [showToast, setShowToast] = useState(false);
  // const [copyText, setCopyText] = useState({ value: "", copied: false });

  useEffect(() => {
    dispatch(chatActions.request());

    // if previous page is 'aiChat', do not push amplitude event
    if (document.referrer && document.referrer?.indexOf("aiChat") < 0) {
      logAnalyticEvent(
        "AI Chat",
        {},
        auth?.id || null,
        auth?.displayName || null,
        auth?.email || null,
        auth?.createdAt || null,
        auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
          ?.preferenceStringValue || "MEDICAL",
        userPlans?.data?.[0]?.plan?.name || null
      );
    }

    return () => {
      dispatch(chatActions.clearAll());
      dispatch(chatActions.clear());
      cancelChatRequests.abort();
    };
  }, []);

  useEffect(() => {
    if (waitForQuestion && scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [waitForQuestion]);

  useEffect(() => {
    if (sessionId) {
      dispatch(chatActions.oneRequest(sessionId));
    }
  }, [sessionId]);

  // useEffect(() => {
  //   if (chats.success) {
  //     if (chats.data?.length > 0) {
  //       setExamples([]);
  //     } else {
  //       setExamples(initialExamples);
  //     }
  //   }
  // }, [chats.data]);

  useEffect(() => {
    if (chats.error) {
      console.log("error :>> ", chats.error);
    }
  }, [chats.error]);

  useEffect(() => {
    if (chat.success) {
      // create has only one item (coming from example)
      if (chat.data?.sessionId) {
        window.history.pushState(
          window.location.href,
          "",
          `/aiChat/${chat.data.sessionId}`
        );
        setSessionId(chat.data.sessionId);
        setData([chat.data]);
        // setExamples([]);
      }
      // from session sessionId, we have an array of items
      if (chat.data?.length > 0) {
        setData(chat.data);
      }
      setWaitForQuestion("");
    }
  }, [chat.data]);

  useEffect(() => {
    if (chat.success) {
      dispatch(chatActions.oneRequest(sessionId));
      setRegenerate(false);
    }
  }, [newChat.success]);

  useEffect(() => {
    if (enterPress && question) {
      handleSubmitNewChat();
    }
  }, [enterPress]);

  const handleSubmitNewChat = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (question) {
      if (sessionId) {
        dispatch(
          chatActions.createAdditional({
            sessionId: sessionId,
            question,
          })
        );
      } else {
        dispatch(
          chatActions.create({
            question,
          })
        );
      }

      logAnalyticEvent(
        "AI Chat - User Prompt",
        {
          prompt: question,
          ...(sessionId && { sessionId }),
        },
        auth?.id || null,
        auth?.displayName || null,
        auth?.email || null,
        auth?.createdAt || null,
        auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
          ?.preferenceStringValue || "MEDICAL",
        userPlans?.data?.[0]?.plan?.name || null
      );
    }

    if (!waitForQuestion) {
      setWaitForQuestion(question);
      setQuestion("");
    }
  };

  const handleRegenerate = (item) => {
    if (sessionId) {
      dispatch(
        chatActions.update({
          sessionId: sessionId,
          id: item.id,
          userId: auth.id,
        })
      );
      setRegenerate(true);
    }

    logAnalyticEvent(
      "AI Chat - Regenerate",
      {},
      auth?.id || null,
      auth?.displayName || null,
      auth?.email || null,
      auth?.createdAt || null,
      auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
        ?.preferenceStringValue || "MEDICAL",
      userPlans?.data?.[0]?.plan?.name || null
    );
  };

  const handleExampleClick = (id) => {
    const selectedExample = initialExamples.find(
      (item) => item.id === id
    )?.text;
    if (selectedExample) {
      setWaitForQuestion(selectedExample);
      dispatch(
        chatActions.create({
          question: selectedExample,
        })
      );
    }

    logAnalyticEvent(
      "AI Chat - Example",
      {
        prompt: selectedExample,
      },
      auth?.id || null,
      auth?.displayName || null,
      auth?.email || null,
      auth?.createdAt || null,
      auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
        ?.preferenceStringValue || "MEDICAL",
      userPlans?.data?.[0]?.plan?.name || null
    );
  };

  const handleSessionClick = (sessionId) => {
    window.location.href = `/aiChat/${sessionId}`;

    logAnalyticEvent(
      "AI Chat - Previous Chat",
      {
        sessionId: sessionId,
      },
      auth?.id || null,
      auth?.displayName || null,
      auth?.email || null,
      auth?.createdAt || null,
      auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
        ?.preferenceStringValue || "MEDICAL",
      userPlans?.data?.[0]?.plan?.name || null
    );
  };

  // event handler for 'Copy To Clipboard' link click
  const handleCopyToClipboard = () => {
    setShowToast(true);

    logAnalyticEvent(
      "AI Chat - Copy To Clipboard",
      {},
      auth?.id || null,
      auth?.displayName || null,
      auth?.email || null,
      auth?.createdAt || null,
      auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
        ?.preferenceStringValue || "MEDICAL",
      userPlans?.data?.[0]?.plan?.name || null
    );
  };

  // event handler for 'New Chat' button click
  const handleNewChatClick = () => {
    logAnalyticEvent(
      "AI Chat - New Chat",
      {},
      auth?.id || null,
      auth?.displayName || null,
      auth?.email || null,
      auth?.createdAt || null,
      auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
        ?.preferenceStringValue || "MEDICAL",
      userPlans?.data?.[0]?.plan?.name || null
    );

    window.location.href = "/aiChat";
  };

  useEffect(() => {
    if (scrollRef && chat.success) {
      setTimeout(() => {
        scrollRef.current.scrollIntoView({
          alignToTop: true,
          behavior: "smooth",
        });
      }, 200);
    }
  }, [chat.success]);

  return view({
    data,
    username: auth.userName,
    examples: initialExamples,
    previousChats: chats.data?.toReversed() || [],
    handleExampleClick,
    question,
    setQuestion,
    waitForQuestion,
    handleSubmitNewChat,
    handleSessionClick,
    scrollRef,
    showAll,
    setShowAll,
    regenerate,
    handleRegenerate,
    isFetching: chat.fetching || newChat.fetching,

    // copyText,
    // setCopyText,
    showToast,
    setShowToast,

    handleCopyToClipboard,
    handleNewChatClick,
  });
};
