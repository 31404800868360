import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { accountActions, commonActions } from "../../../store/actions";
import { cancelHcpRequests } from "../../../store/sagas";
import { isReady } from "../../../utils";
import useDebounce from "../../../utils/useDebounce";
import { debounce, isEmpty } from "lodash";

import { view } from "./NewAccountView";
import { act } from "react-test-renderer";

export const NewAccount = ({ isInstitution, isPerson }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const errorsMessage = {
    name: "Required field",
    username: "Required field",
    externalId: "Required field",
    npiId: "Required field",
  };

  const initialAccount = {
    name: "",
    firstName: "",
    lastName: "",
    username: "",
    description: "",
    accountType: isPerson ? "PERSON" : "INSTITUTION",
    accountSubtype: isPerson ? "HCP" : "OTHER",
    url: "",
    imageURL: "",
    twitterURL: "",
    externalId: "",
    location: "",
    city: "",
    county: "",
    stateProvince: "",
    stateProvinceCode: "",
    country: "",
    countryCode: "",
    postalCode: "",
    forIngestion: true,
    verified: false,
    ingestionReason: "PRIMARY",
    npiId: "",
  };

  // --> mapStateToProp
  const auth = useSelector((state) => state.auth.data);
  const checkAccount = useSelector((state) => state.account.check);
  const initialCountries = useSelector((state) => state.common.countries);
  const storedHcp = useSelector((state) => state.account.one);
  const initialInstitutions = useSelector((state) => state.account.all);
  const [backendError, setBackendError] = useState("");

  // --> STATE
  const [isSubmitPressed, setIsSubmitPressed] = useState(false);
  const [hcp, setHcp] = useState(initialAccount);
  const [hcps, setHcps] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedInstitution, setSelectedInstitution] = useState([]);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const debounceTerm = useDebounce(searchTerm, 700);
  const [errors, setErrors] = useState({});
  const [showModat, toggleModal] = useState(false);
  const [countryTerm, setCountryTerm] = useState("");

  // <-- STATE

  useEffect(() => {
    dispatch(commonActions.requestCountries());

    return () => {
      dispatch(accountActions.clear());
      cancelHcpRequests.abort();
    };
  }, [id]);

  useEffect(() => {
    if (initialCountries?.length > 0) {
      const list = initialCountries.map((a) => ({
        id: a.countryCode,
        label: a.country,
      }));

      const uniqueList = list.filter(
        (v, i, a) => a.findIndex((t) => t.id === v.id) === i
      );

      setCountries(uniqueList);
    }
  }, [initialCountries]);

  // useEffect(() => {
  //   const checkError = checkAccount.error?.response?.status;
  //   if (checkError === 404) {
  //   }
  // }, [checkAccount.error]);

  useEffect(() => {
    if (storedHcp.error?.response?.status === 409) {
      setBackendError(
        storedHcp.error?.response?.data?.message ||
          "This Account already exists"
      );
      toggleModal(true);
    }
  }, [storedHcp.error]);

  useEffect(() => {
    if (isReady(initialAccount)) {
      setCountryTerm(initialAccount.data.country);
    }
  }, [initialAccount.data]);

  useEffect(() => {
    if (hcp?.countryCode && initialCountries?.length > 0) {
      const list = initialCountries.filter(
        (a) => a.countryCode === hcp.countryCode
      );
      const statesList = list
        .filter((a) => a.stateProvince)
        .map((a) => ({
          id: a.stateProvinceCode,
          label: a.stateProvince,
        }));
      if (statesList.length > 0) {
        setStates(statesList);
      }
    }
  }, [initialCountries, hcp?.countryCode]);

  useEffect(() => {
    if (initialInstitutions.data?.length > 0) {
      const institutions = initialInstitutions.data.map((a) => ({
        ...a,
        label: a.name,
      }));
      setHcps(institutions);
    } else {
      setHcps([]);
    }
  }, [initialInstitutions.data]);

  useEffect(() => {
    if (isPerson) {
      requestInstitutions(0);
      setPage(0);
    }
  }, [debounceTerm]);

  const handleUpdate = () => {
    // checkErrors();
  };

  const clearError = (field) => {
    setBackendError("");
    toggleModal(false);
    setErrors((oldData) => ({
      ...oldData,
      [field]: "",
    }));
  };

  const checkErrors = () => {
    let newErrors = {};
    for (const item in errorsMessage) {
      if (hcp[item] === "") {
        if (item === "npiId") {
          if (
            hcp.accountType === "PERSON" &&
            hcp.accountSubtype === "HCP" &&
            hcp.country === "USA"
          ) {
            newErrors.npiId = errorsMessage.npiId;
          } else {
            delete newErrors.npiId;
          }
        } else {
          newErrors[item] = errorsMessage[item];
        }
      } else {
        delete newErrors[item];
      }
    }
    setErrors(newErrors);
  };

  const handleChange = ({ value, field }) => {
    setHcp((oldData) => ({
      ...oldData,
      [field]: value,
    }));
    // checkErrors();
    clearError(field);
  };

  const handleChangeAndUpdate = ({ value, field }) => {
    const newData = { ...hcp, [field]: value };
    setHcp(newData);
  };

  const requestInstitutions = (pageNum) => {
    dispatch(
      accountActions.request({
        pageNum,
        ...(searchTerm !== "" && { term: searchTerm }),
        type: "INSTITUTION",
      })
    );
  };

  const handleMoreInstitions = () => {
    if (!initialInstitutions.fetching) {
      const newPage = page + 1;
      setPage(newPage);
      requestInstitutions(newPage);
    }
  };

  const handleUpdateInstitution = (e) => {
    setSelectedInstitution([e]);
  };

  const handleClearInstitution = () => {
    setSelectedInstitution([]);
  };

  const handleClearCountry = () => {
    setCountryTerm([]);
    const newData = { ...hcp, country: "" };
    setHcp(newData);
  };

  const handleUpdateState = (e) => {
    const newData = { ...hcp, stateProvince: e.label, stateProvinceCode: e.id };
    setHcp(newData);
  };

  const handleUpdateCountry = (e) => {
    setCountryTerm(e.label);
    const newData = { ...hcp, country: e.label, countryCode: e.id };
    setHcp(newData);
  };

  const handleCreate = () => {
    setIsSubmitPressed(true);
    checkErrors();
    if (isEmpty(errors)) {
      debounceSubmit();
    }
  };

  const debounceSubmit = debounce(() => {
    // dispatch(accountActions.accountCheck(hcp));
    dispatch(accountActions.create(hcp));
  });

  useEffect(() => {
    checkErrors();
  }, [hcp]);

  return view({
    errors,
    backendError,
    isSubmitPressed,
    hcp,
    handleUpdate,
    handleChange,
    handleChangeAndUpdate,
    isInstitution,
    isPerson,
    searchTerm,
    setSearchTerm,
    hcps,
    infiniteScroll: {
      loading: initialInstitutions.fetching,
      hasNextPage: !initialInstitutions.isLastPage,
      onLoadMore: handleMoreInstitions,
    },
    selectedInstitution,
    handleUpdateInstitution,
    handleClearInstitution,

    countries,
    countryTerm,
    setCountryTerm,
    handleUpdateCountry,
    clearCountry: handleClearCountry,
    states,
    handleUpdateState,
    handleCreate,
    showModat,
    toggleModal,
    loadingInstitutions: initialInstitutions.fetching,
  });
};
