import { createAction, createActionTypes } from "../../utils";

export const ACCOUNT_ACTIONS = createActionTypes("ACCOUNT_ACTIONS", [
  "REQUEST",
  "SUCCESS",
  "FAILURE",

  "ONE_REQUEST",
  "ONE_SUCCESS",
  "ONE_FAILURE",

  "CLEAR",

  "PRODUCTS_REQUEST",
  "PRODUCTS_SUCCESS",
  "PRODUCTS_FAILURE",

  "COUNT_REQUEST",
  "COUNT_SUCCESS",
  "COUNT_FAILURE",

  "RELATIONS_TO_REQUEST",
  "RELATIONS_TO_SUCCESS",
  "RELATIONS_TO_FAILURE",

  "RELATIONS_FROM_REQUEST",
  "RELATIONS_FROM_SUCCESS",
  "RELATIONS_FROM_FAILURE",

  "UPDATE",
  "DELETE",
  "UPDATE_SUCCESS",
  "UPDATE_FAILURE",

  "CREATE",
  "CREATE_SUCCESS",
  "CREATE_FAILURE",

  "ADD_RELATIONSHIP",
  "DELETE_RELATIONSHIP",
  "RELATIONSHIP_SUCCESS",
  "RELATIONSHIP_FAILURE",

  "GPT_REQUEST",
  "GPT_SUCCESS",
  "GPT_FAILURE",
  "GPT_CLEAR",
]);

export const accountActions = {
  request: (data) =>
    createAction(ACCOUNT_ACTIONS.REQUEST, {
      ...data,
      fetching: true,
      success: false,
      error: null,
    }),
  success: ({ data, pageNum }) =>
    createAction(ACCOUNT_ACTIONS.SUCCESS, {
      data,
      pageNum,
      fetching: false,
      success: true,
      error: null,
    }),
  failure: (error) =>
    createAction(ACCOUNT_ACTIONS.FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  oneRequest: (id) =>
    createAction(ACCOUNT_ACTIONS.ONE_REQUEST, {
      id,
      fetching: true,
      success: false,
      error: null,
    }),
  oneSuccess: (data) =>
    createAction(ACCOUNT_ACTIONS.ONE_SUCCESS, {
      ...data,
      fetching: false,
      success: true,
      error: null,
    }),
  oneFailure: (error) =>
    createAction(ACCOUNT_ACTIONS.ONE_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  delete: ({ id, accountType }) =>
    createAction(ACCOUNT_ACTIONS.DELETE, {
      id,
      accountType,
      fetching: true,
      success: false,
      error: null,
    }),
  update: (data) =>
    createAction(ACCOUNT_ACTIONS.UPDATE, {
      data,
      fetching: true,
      success: false,
      error: null,
    }),
  updateSuccess: (data) =>
    createAction(ACCOUNT_ACTIONS.UPDATE_SUCCESS, {
      ...data,
      fetching: false,
      success: true,
      error: null,
    }),
  updateFailure: (error) =>
    createAction(ACCOUNT_ACTIONS.UPDATE_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  create: (data) =>
    createAction(ACCOUNT_ACTIONS.CREATE, {
      data,
      fetching: true,
      success: false,
      error: null,
    }),
  createSuccess: (data) =>
    createAction(ACCOUNT_ACTIONS.CREATE_SUCCESS, {
      ...data,
      fetching: false,
      success: true,
      error: null,
    }),
  createFailure: (error) =>
    createAction(ACCOUNT_ACTIONS.CREATE_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  clear: () =>
    createAction(ACCOUNT_ACTIONS.CLEAR, {
      data: [],
      fetching: true,
      success: false,
      error: null,
    }),

  productsRequest: (data) =>
    createAction(ACCOUNT_ACTIONS.PRODUCTS_REQUEST, {
      ...data,
      fetching: true,
      success: false,
      error: null,
    }),
  productsSuccess: ({ data }) =>
    createAction(ACCOUNT_ACTIONS.PRODUCTS_SUCCESS, {
      data: data,
      fetching: false,
      success: true,
      error: null,
    }),
  productsFailure: (error) =>
    createAction(ACCOUNT_ACTIONS.PRODUCTS_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  countRequest: (data) =>
    createAction(ACCOUNT_ACTIONS.COUNT_REQUEST, {
      ...data,
      fetching: true,
      success: false,
      error: null,
    }),
  countSuccess: ({ data }) =>
    createAction(ACCOUNT_ACTIONS.COUNT_SUCCESS, {
      data: data,
      fetching: false,
      success: true,
      error: null,
    }),
  countFailure: (error) =>
    createAction(ACCOUNT_ACTIONS.COUNT_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  relationsToRequest: (data) =>
    createAction(ACCOUNT_ACTIONS.RELATIONS_TO_REQUEST, {
      ...data,
      fetching: true,
      success: false,
      error: null,
    }),
  relationsToSuccess: ({ data, pageNum }) =>
    createAction(ACCOUNT_ACTIONS.RELATIONS_TO_SUCCESS, {
      data,
      pageNum,
      fetching: false,
      success: true,
      error: null,
    }),
  relationsToFailure: (error) =>
    createAction(ACCOUNT_ACTIONS.RELATIONS_TO_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  relationsFromRequest: (data) =>
    createAction(ACCOUNT_ACTIONS.RELATIONS_FROM_REQUEST, {
      ...data,
      fetching: true,
      success: false,
      error: null,
    }),
  relationsFromSuccess: ({ data, pageNum }) =>
    createAction(ACCOUNT_ACTIONS.RELATIONS_FROM_SUCCESS, {
      data,
      pageNum,
      fetching: false,
      success: true,
      error: null,
    }),
  relationsFromFailure: (error) =>
    createAction(ACCOUNT_ACTIONS.RELATIONS_FROM_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  addRelationship: ({ hcpId, institutionId }) =>
    createAction(ACCOUNT_ACTIONS.ADD_RELATIONSHIP, {
      hcpId,
      institutionId,
      fetching: true,
      success: false,
      error: null,
    }),
  deleteRelationship: ({ hcpId, institutionId }) =>
    createAction(ACCOUNT_ACTIONS.DELETE_RELATIONSHIP, {
      hcpId,
      institutionId,
      fetching: true,
      success: false,
      error: null,
    }),
  relationshipSuccess: ({ data }) =>
    createAction(ACCOUNT_ACTIONS.RELATIONSHIP_SUCCESS, {
      data,
      fetching: false,
      success: true,
      error: null,
    }),
  relationshipFailure: (error) =>
    createAction(ACCOUNT_ACTIONS.RELATIONSHIP_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  gtpRequest: (data) =>
    createAction(ACCOUNT_ACTIONS.GPT_REQUEST, {
      data,
      fetching: true,
      success: false,
      error: null,
    }),
  gptSuccess: (data) =>
    createAction(ACCOUNT_ACTIONS.GPT_SUCCESS, {
      ...data,
      fetching: false,
      success: true,
      error: null,
    }),
  gptFailure: (error) =>
    createAction(ACCOUNT_ACTIONS.GPT_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),
  gptClear: () =>
    createAction(ACCOUNT_ACTIONS.GPT_CLEAR, {
      data: [],
      fetching: true,
      success: false,
      error: null,
    }),
};
