import { createAction, createActionTypes } from "../../utils";

export const SCROLL_ACTIONS = createActionTypes("SCROLL_ACTIONS", ["SET"]);

export const scrollActions = {
  set: (data) =>
    createAction(SCROLL_ACTIONS.SET, {
      data,
    }),
};
