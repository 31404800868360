import { createAction, createActionTypes } from "../../utils";

export const HIGHLIGHT_ACTIONS = createActionTypes("HIGHLIGHT_ACTIONS", [
  "REQUEST",
  "SUCCESS",
  "FAILURE",

  "ONE_REQUEST",
  "ONE_SUCCESS",
  "ONE_FAILURE",
  "CREATE",
  "UPDATE",
  "DELETE",
  "CLEAR",
]);

export const highlightActions = {
  request: (data) =>
    createAction(HIGHLIGHT_ACTIONS.REQUEST, {
      ...data,
      fetching: true,
      success: false,
      error: null,
    }),
  success: ({ data, pageNum }) =>
    createAction(HIGHLIGHT_ACTIONS.SUCCESS, {
      data,
      pageNum,
      fetching: false,
      success: true,
      error: null,
    }),
  failure: (error) =>
    createAction(HIGHLIGHT_ACTIONS.FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  oneRequest: (id) =>
    createAction(HIGHLIGHT_ACTIONS.ONE_REQUEST, {
      id,
      fetching: true,
      success: false,
      error: null,
    }),
  oneSuccess: (data) =>
    createAction(HIGHLIGHT_ACTIONS.ONE_SUCCESS, {
      ...data,
      fetching: false,
      success: true,
      error: null,
    }),
  oneFailure: (error) =>
    createAction(HIGHLIGHT_ACTIONS.ONE_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),
  create: (data) =>
    createAction(HIGHLIGHT_ACTIONS.CREATE, {
      data,
      fetching: true,
      success: false,
      error: null,
    }),
  update: (data) =>
    createAction(HIGHLIGHT_ACTIONS.UPDATE, {
      data,
      fetching: true,
      success: false,
      error: null,
    }),
  delete: (id) =>
    createAction(HIGHLIGHT_ACTIONS.DELETE, {
      id,
      fetching: true,
      success: false,
      error: null,
    }),
  clear: () =>
    createAction(HIGHLIGHT_ACTIONS.CLEAR, {
      data: [],
      fetching: true,
      success: false,
      error: null,
    }),
};
