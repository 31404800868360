import React from "react";

import {
  Column,
  Row,
  MainWrapper,
  Header,
  Content,
  H2,
  Avatar,
  Input,
  Label,
  TextArea,
  Anchor,
  Dropdown,
  Grid,
  Switch,
  HR,
  AutoComplete,
  Collapsible,
  Button,
  Toast,
  Icon,
} from "../../../components";

import { Menu } from "../Menu";

export const Group = ({ children, label, marginTop }) => (
  <Column marginTop={marginTop || 0}>
    <Row marginBottom="10">
      <Label bold>{label}</Label>
    </Row>
    <Row marginTop="5">{children}</Row>
  </Column>
);

export const DropdownGroup = ({
  field,
  label,
  value,
  handleChangeAndUpdate,
  items,
}) => (
  <Group {...{ label }}>
    <Dropdown
      expand
      select
      {...{ value, items }}
      onChange={(e) =>
        handleChangeAndUpdate({
          value: e.id,
          field,
        })
      }
    />
  </Group>
);

export const InputGroup = ({
  field,
  label,
  value,
  handleChange,
  handleUpdate,
  multiline,
  marginTop,
  error,
}) => (
  <Group {...{ label, marginTop }}>
    {!multiline ? (
      <Input
        small
        placeholder={label}
        value={value}
        errorMessage={error}
        onChange={(e) =>
          handleChange({
            value: e.target?.value,
            field,
          })
        }
        onBlur={handleUpdate}
      />
    ) : (
      <TextArea
        small
        expand
        placeholder={label}
        value={value}
        // errorMessage={errors[field]}
        onChange={(e) =>
          handleChange({
            value: e.target?.value,
            field,
          })
        }
        onBlur={handleUpdate}
      />
    )}
  </Group>
);

const getHCPAccountTypes = () => {
  return [{ id: "PERSON", label: "Person" }];
};

const getHCPAccountSubtypes = () => {
  return [
    { id: "HCP", label: "HCP" },
    { id: "ADVOCATE", label: "Advocate" },
    { id: "RESEARCHER", label: "Researcher" },
  ];
};

const getInstitutionAccountTypes = () => {
  return [{ id: "INSTITUTION", label: "Institution" }];
};

const getInstitutionAccountSubtypes = () => {
  return [
    { id: "PHARMA", label: "Pharma" },
    { id: "ACADEMIC", label: "Academic" },
    { id: "ADVOCATE", label: "Advocate" },
    { id: "GOVERNMENT", label: "Government" },
    { id: "HOSPITAL", label: "Hospital" },
    { id: "RESEARCH", label: "Research" },
    { id: "SOCIETY", label: "Society" },
    { id: "CMEPROVIDER", label: "CME Provider" },
    { id: "LAB", label: "Lab" },
  ];
};

const getAccountTypes = (accountType) => {
  // if (accountType === "PERSON") return getHCPAccountTypes();
  // else if (
  //   accountType === "INSTITUTION"
  // )
  //   return getInstitutionAccountTypes();
  // else
  //   return [
  //     ...getHCPAccountTypes(),
  //     ...getInstitutionAccountTypes(),
  //     { id: "OTHER", label: "Other" },
  //     { id: "UNKNOWN", label: "Unknown" },
  //   ];
  return [...getHCPAccountTypes(), ...getInstitutionAccountTypes()];
};

const getAccountSubtypes = (accountType) => {
  if (accountType === "PERSON")
    return [
      ...getHCPAccountSubtypes(),
      { id: "OTHER", label: "Other" },
      { id: "UNKNOWN", label: "Unknown" },
    ];
  else if (accountType !== "PERSON")
    return [
      ...getInstitutionAccountSubtypes(),
      { id: "OTHER", label: "Other" },
      { id: "UNKNOWN", label: "Unknown" },
    ];
  else
    return [
      ...getHCPAccountSubtypes(),
      ...getInstitutionAccountSubtypes(),
      { id: "OTHER", label: "Other" },
      { id: "UNKNOWN", label: "Unknown" },
    ];
};

export const view = ({
  hcp,
  errors,
  backendError,
  isSubmitPressed,
  isPerson,
  handleUpdate,
  handleChange,
  handleChangeAndUpdate,
  isInstitution,
  searchTerm,
  setSearchTerm,
  hcps,
  selectedInstitution,
  handleUpdateInstitution,
  infiniteScroll,
  handleClearInstitution,
  loadingInstitutions,

  countries,
  countryTerm,
  setCountryTerm,
  handleUpdateCountry,
  clearCountry,

  states,
  handleUpdateState,
  handleCreate,
  showModat,
  toggleModal,
}) => (
  <MainWrapper drawer>
    <Header
      drawer
      rightContent={
        <Row>
          <Row marginLeft gap="20" middle>
            <Button primary label="Create" onClick={() => handleCreate()} />
            <Anchor label="Cancel" to={"/admin/accounts"} />
          </Row>
        </Row>
      }
    />
    <Menu active={"accounts"} />
    {hcp && (
      <Content>
        <Column center>
          <Column maxWidth="700" paddingBottom="40">
            <Row expand paddingY="20">
              <H2 bold>New Account</H2>
              <Row marginLeft>
                {hcp.imageURL && (
                  <Avatar imageUrl={hcp.imageURL} name={hcp.name} />
                )}
              </Row>
            </Row>

            <Column marginTop>
              <Grid columns={2} gap="30">
                {!isInstitution && (
                  <InputGroup
                    label="First Name"
                    field="firstName"
                    value={hcp.firstName}
                    {...{ handleChange, handleUpdate }}
                  />
                )}
                {!isInstitution && (
                  <InputGroup
                    label="Last Name"
                    field="lastName"
                    value={hcp.lastName}
                    {...{ handleChange, handleUpdate }}
                  />
                )}
                <InputGroup
                  label="Name"
                  field="name"
                  value={hcp.name}
                  error={isSubmitPressed && errors.name}
                  {...{ handleChange, handleUpdate }}
                />
                <InputGroup
                  label="Twitter Handle"
                  field="username"
                  value={hcp.username}
                  error={isSubmitPressed && errors.username}
                  {...{ handleChange, handleUpdate }}
                />
                <InputGroup
                  label="External Id"
                  field="externalId"
                  value={hcp.externalId}
                  error={isSubmitPressed && errors.externalId}
                  {...{ handleChange, handleUpdate }}
                />
                <InputGroup
                  label="NPI Id"
                  field="npiId"
                  value={hcp.npiId}
                  error={
                    isSubmitPressed &&
                    hcp.country === "USA" &&
                    hcp.accountType === "PERSON" &&
                    errors.npiId
                  }
                  {...{ handleChange, handleUpdate }}
                />
              </Grid>
              <InputGroup
                label="Description"
                field="description"
                value={hcp.description}
                multiline
                marginTop="30"
                {...{ handleChange, handleUpdate }}
              />

              <Column marginY="40">
                <HR />
              </Column>
              <Grid columns={2} gap="30">
                <DropdownGroup
                  label="Account Type"
                  field="accountType"
                  value={hcp.accountType}
                  items={getAccountTypes(hcp?.accountType)}
                  {...{ handleChangeAndUpdate }}
                />
                <DropdownGroup
                  label="Account Subtype"
                  field="accountSubtype"
                  value={hcp.accountSubtype}
                  items={getAccountSubtypes(hcp?.accountType)}
                  {...{ handleChangeAndUpdate }}
                />
                <InputGroup
                  label="URL"
                  field="url"
                  value={hcp.url}
                  {...{ handleChange, handleUpdate }}
                />
                <InputGroup
                  label="Image URL"
                  field="imageURL"
                  value={hcp.imageURL}
                  {...{ handleChange, handleUpdate }}
                />
                <InputGroup
                  label="Twitter URL"
                  field="twitterURL"
                  value={hcp.twitterURL}
                  {...{ handleChange, handleUpdate }}
                />
              </Grid>

              <Column marginY="40">
                <Collapsible label={"Location"} open={isInstitution}>
                  <Column paddingTop="30" paddingBottom="40" paddingX>
                    <Grid columns={2} gap="30">
                      <InputGroup
                        label="Location"
                        field="location"
                        value={hcp.location}
                        {...{ handleChange, handleUpdate }}
                      />
                      <InputGroup
                        label="City"
                        field="city"
                        value={hcp.city}
                        {...{ handleChange, handleUpdate }}
                      />
                      <InputGroup
                        label="County"
                        field="county"
                        value={hcp.county}
                        {...{ handleChange, handleUpdate }}
                      />
                      <Group label="State / Province">
                        <Dropdown
                          expand
                          select
                          value={hcp.stateProvinceCode}
                          items={states}
                          onChange={handleUpdateState}
                        />
                      </Group>
                      <Group label="Country">
                        {/* <Dropdown
                          expand
                          select
                          value={hcp.countryCode}
                          items={countries}
                          onChange={handleUpdateCountry}
                        /> */}
                        <AutoComplete
                          showOnEmptyTerm
                          placeholder="Country"
                          displayTextFromSelected
                          searchResults={countries}
                          setSearchTerm={setCountryTerm}
                          searchTerm={countryTerm}
                          selectedItems={hcp.countryCode}
                          addItem={handleUpdateCountry}
                          hasClearButton
                          clearSearchResults={clearCountry}
                        />
                      </Group>
                      <InputGroup
                        label="Postal Code"
                        field="postalCode"
                        value={hcp.postalCode}
                        {...{ handleChange, handleUpdate }}
                      />
                    </Grid>
                  </Column>
                </Collapsible>
              </Column>
              <Grid columns={2} gap="30" marginTop="-10">
                <Switch
                  label="For Ingestion"
                  checked={hcp.forIngestion}
                  onChange={(e) => {
                    handleChangeAndUpdate({
                      value: e,
                      field: "forIngestion",
                    });
                  }}
                />
                <Switch
                  label="Verified"
                  checked={hcp.verified}
                  onChange={(e) => {
                    handleChangeAndUpdate({
                      value: e,
                      field: "verified",
                    });
                  }}
                />
                <DropdownGroup
                  label="Ingestion Reason"
                  field="ingestionReason"
                  value={hcp.ingestionReason}
                  items={[
                    { id: "PRIMARY", label: "Primary" },
                    { id: "SECONDARY", label: "Secondary" },
                    { id: "USERRQUEST", label: "User request" },
                    { id: "DEDUCED", label: "Deduced" },
                    { id: "FOLLOWED", label: "Followed" },
                    {
                      id: "SECONDARYFROMRETWEET",
                      label: "Secondary from repost",
                    },
                  ]}
                  {...{ handleChangeAndUpdate }}
                />
              </Grid>

              {hcp.accountType === "PERSON" && (
                <>
                  <Column marginY="40">
                    <HR />
                  </Column>
                  <Row marginBottom="10">
                    <Label bold>Institution</Label>
                  </Row>
                  <Grid columns={2} gap="30">
                    <AutoComplete
                      showOnEmptyTerm
                      placeholder="Institution"
                      displayTextFromSelected
                      searchResults={hcps}
                      setSearchTerm={setSearchTerm}
                      searchTerm={searchTerm}
                      selectedItems={selectedInstitution}
                      addItem={handleUpdateInstitution}
                      clearSearchResults={handleClearInstitution}
                      infiniteScroll={infiniteScroll}
                      hasClearButton
                      //Commenting this as it shows a double spinner
                      // searchLoading={loadingInstitutions}
                    />
                  </Grid>
                  {selectedInstitution && selectedInstitution.length > 0 ? (
                    <Column paddingTop>
                      <Collapsible
                        label="Institution Location"
                        open
                        rightAction={
                          <Anchor
                            bold
                            label="edit"
                            // reloadDocument
                            onClick={() =>
                              window.location.assign(
                                `/editAccount/${selectedInstitution[0].id}`
                              )
                            }
                          />
                        }
                      >
                        <Column paddingAll gap="5">
                          {selectedInstitution[0].location && (
                            <Row gap="10">
                              <Label>Location:</Label>
                              <Label bold>
                                {selectedInstitution[0].location}
                              </Label>
                            </Row>
                          )}
                          {selectedInstitution[0].city && (
                            <Row gap="10">
                              <Label>City:</Label>
                              <Label bold>{selectedInstitution[0].city}</Label>
                            </Row>
                          )}
                          {selectedInstitution[0].county && (
                            <Row gap="10">
                              <Label>County:</Label>
                              <Label bold>
                                {selectedInstitution[0].county}
                              </Label>
                            </Row>
                          )}
                          {selectedInstitution[0].stateProvince && (
                            <Row gap="10">
                              <Label>State:</Label>
                              <Label bold>
                                {selectedInstitution[0].stateProvince}
                              </Label>
                            </Row>
                          )}
                          {selectedInstitution[0].country && (
                            <Row gap="10">
                              <Label>Country:</Label>
                              <Label bold>
                                {selectedInstitution[0].country}
                              </Label>
                            </Row>
                          )}
                          {selectedInstitution[0].postalCode && (
                            <Row gap="10">
                              <Label>Postal Code:</Label>
                              <Label bold>
                                {selectedInstitution[0].postalCode}
                              </Label>
                            </Row>
                          )}
                        </Column>
                      </Collapsible>
                    </Column>
                  ) : (
                    <Column paddingBottom="250" />
                  )}
                </>
              )}
            </Column>
          </Column>
        </Column>

        <Toast
          visible={showModat}
          toggleVisibility={toggleModal}
          expiration={2000}
          warning
        >
          <Row gap="5" middle>
            <Icon name="warning" />
            <Label>{backendError}</Label>
          </Row>
        </Toast>
      </Content>
    )}
  </MainWrapper>
);
