import { put, call, takeLatest, all } from "redux-saga/effects";
import axios from "axios";

import { getApiPath, MAX_RESULTS } from "../../utils/variables";
import { authHeader } from "../store";
import { objectToUrlEncoded } from "../../utils/constants";
import { HIGHLIGHT_ACTIONS, highlightActions } from "../actions";

export let cancelHighlightRequests = new AbortController();

/**
 * get list
 */
function* handleRequest(action) {
  try {
    cancelHighlightRequests = new AbortController();
    const {
      objectId,
      objectType,
      objectNameTerm,
      startDate,
      endDate,
      pageNum,
      maxResult,
      term,
    } = action.payload;
    const params = objectToUrlEncoded({
      objectId,
      objectType,
      objectNameTerm,
      startDate,
      endDate,
      pageNum,
      term,
      maxResult: maxResult || MAX_RESULTS,
    });
    const { data } = yield call(
      axios.get,
      `${getApiPath(false)}/highlights?${params}`,
      { signal: cancelHighlightRequests.signal }
    );
    yield put(highlightActions.success({ data, pageNum }));
  } catch (e) {
    if (e?.response?.status === 401) {
      yield put((window.location.href = "/logout"));
    }
    yield put(highlightActions.failure({ error: { ...e } }));
  }
}

/**
 * get a highlight
 */
function* handleRequestOne(action) {
  try {
    cancelHighlightRequests = new AbortController();
    const { id } = action.payload;
    const { data } = yield call(axios.get, `${getApiPath()}/highlight/${id}`, {
      headers: authHeader(),
      signal: cancelHighlightRequests.signal,
    });
    yield put(highlightActions.oneSuccess({ data }));
  } catch (e) {
    yield put(highlightActions.oneFailure({ error: { ...e } }));
  }
}

/**
 * create highlight
 */
function* handleCreate(action) {
  const body = action.payload.data;

  try {
    cancelHighlightRequests = new AbortController();
    const { data } = yield call(axios.post, `${getApiPath()}/highlight`, body, {
      headers: authHeader(),
      signal: cancelHighlightRequests.signal,
    });
    yield put(highlightActions.oneSuccess({ data }));
    yield put((window.location.href = "/admin/highlights"));
  } catch (e) {
    yield put(highlightActions.oneFailure({ error: { ...e } }));
  }
}

/**
 * update highlight
 */
function* handleUpdate(action) {
  const body = action.payload.data;

  try {
    cancelHighlightRequests = new AbortController();
    const { data } = yield call(axios.put, `${getApiPath()}/highlight`, body, {
      headers: authHeader(),
      signal: cancelHighlightRequests.signal,
    });
    yield put(highlightActions.oneSuccess({ data }));
  } catch (e) {
    yield put(highlightActions.oneFailure({ error: { ...e } }));
  }
}

/**
 * delete highlight
 */
function* handleDelete(action) {
  try {
    cancelHighlightRequests = new AbortController();
    const { data } = yield call(
      axios.delete,
      `${getApiPath()}/highlight?id=${action.payload.id}`,
      {
        headers: authHeader(),
        signal: cancelHighlightRequests.signal,
      }
    );
    yield put(highlightActions.oneSuccess({ data }));
    yield put((window.location.href = "/admin/highlights"));
  } catch (e) {
    yield put(highlightActions.oneFailure({ error: { ...e } }));
  }
}

function* watchHighlightSagas() {
  yield all([
    takeLatest(HIGHLIGHT_ACTIONS.REQUEST, handleRequest),
    takeLatest(HIGHLIGHT_ACTIONS.CREATE, handleCreate),
    takeLatest(HIGHLIGHT_ACTIONS.UPDATE, handleUpdate),
    takeLatest(HIGHLIGHT_ACTIONS.DELETE, handleDelete),
    takeLatest(HIGHLIGHT_ACTIONS.ONE_REQUEST, handleRequestOne),
  ]);
}

export default watchHighlightSagas;
