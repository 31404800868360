import { CONFERENCE_ACTIONS } from "../actions";
import { MAX_RESULTS } from "../../utils/variables";

const initialState = {
  all: { data: [], fetching: false, success: false, error: null },
  one: { data: [], fetching: false, success: false, error: null },
  dol: { data: [], fetching: false, success: false, error: null },
  tweets: { data: [], fetching: false, success: false, error: null },
  tweetsCount: { data: [], fetching: false, success: false, error: null },
};

export default function conferenceReducer(state = initialState, action) {
  switch (action.type) {
    case CONFERENCE_ACTIONS.REQUEST:
    case CONFERENCE_ACTIONS.SELECTED_REQUEST:
    case CONFERENCE_ACTIONS.FAILURE:
      return { ...state, all: { ...state.all, ...action.payload } };
    case CONFERENCE_ACTIONS.SUCCESS:
      // If we have pagination, we need to merge state data & payload data
      const newState = {
        ...state,
        all: {
          ...state.all,
          ...action.payload,
          isLastPage: action.payload.data.length < MAX_RESULTS,
        },
      };
      if (action.payload.pageNum && action.payload.pageNum !== 0) {
        newState.all.data = [...state.all.data, ...action.payload.data];
      }
      return newState;
    case CONFERENCE_ACTIONS.CLEAR_ALL:
      return {
        ...state,
        all: action.payload,
      };

    case CONFERENCE_ACTIONS.DOL_REQUEST:
    case CONFERENCE_ACTIONS.DOL_SUCCESS:
    case CONFERENCE_ACTIONS.DOL_FAILURE:
      return {
        ...state,
        dol: { ...state.dol, ...action.payload },
      };

    case CONFERENCE_ACTIONS.ONE_REQUEST:
    case CONFERENCE_ACTIONS.ONE_SUCCESS:
    case CONFERENCE_ACTIONS.ONE_FAILURE:
    case CONFERENCE_ACTIONS.CREATE:
    case CONFERENCE_ACTIONS.UPDATE:
    case CONFERENCE_ACTIONS.CLEAR:
      return { ...state, one: action.payload };

    case CONFERENCE_ACTIONS.CLEAR_TAGS:
      return {
        ...state,
        tweets: action.payload,
        tweetsCount: action.payload,
      };

    case CONFERENCE_ACTIONS.TWEETS_REQUEST:
    case CONFERENCE_ACTIONS.TWEETS_FAILURE:
      return { ...state, tweets: { ...state.tweets, ...action.payload } };
    case CONFERENCE_ACTIONS.TWEETS_SUCCESS:
      // If we have pagination, we need to merge state data & payload data
      const newTweetState = {
        ...state,
        tweets: {
          ...state.tweets,
          ...action.payload,
          isLastPage: action.payload.data.length < MAX_RESULTS,
        },
      };
      if (action.payload.pageNum && action.payload.pageNum !== 0) {
        newTweetState.tweets.data = [
          ...state.tweets.data,
          ...action.payload.data,
        ];
      }
      return newTweetState;

    case CONFERENCE_ACTIONS.TWEETS_COUNT_REQUEST:
    case CONFERENCE_ACTIONS.TWEETS_COUNT_SUCCESS:
    case CONFERENCE_ACTIONS.TWEETS_COUNT_FAILURE:
      return {
        ...state,
        tweetsCount: { ...state.tweetsCount, ...action.payload },
      };

    default:
      return state;
  }
}
