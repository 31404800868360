import React from "react";

import {
  Row,
  Column,
  Content,
  H3,
  Label,
  Metadata,
  MainWrapper,
  Header,
  Menu,
  Anchor,
} from "../../components";

export const NoMonitors = ({ handleSignUpClick }) => {
  return (
    <MainWrapper drawer className="dashboard">
      <Header isHome drawer login user signUpClick={handleSignUpClick} />
      <Menu />

      <Content>
        <Metadata />

        <Column>
          <Row marginBottom="5" marginRight style={{ minHeight: "20px" }}>
            <H3 bold>No diseases or products selected</H3>
          </Row>
          <Row marginTop="30" marginBottom>
            <Label>
              Go to <Anchor line label="Manage preferences" to="/preferences" />{" "}
              to add at least one disease or product.
            </Label>
          </Row>
        </Column>
      </Content>
    </MainWrapper>
  );
};
