import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";

import {
  Column,
  Row,
  Label,
  H1,
  H2,
  H3,
  H4,
  P,
  Button,
  Anchor,
  Icon,
  Footer,
  Logo,
  Grid,
  MainWrapper,
  Header,
  Drawer,
  Metadata,
} from "../../components";
import { Color, device, currentYear } from "../../utils/variables";
import { logAnalyticEvent, getUrlParams } from "../../utils";
import { useLocation } from "react-router-dom";

const Links = styled(Column)`
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;

  @media ${device.mobileL} {
    grid-template-columns: 1fr 1fr;
  }
  @media ${device.tablet} {
    display: flex;
    flex-direction: row;
  }
`;

const Logos = styled(Row)`
  flex-wrap: wrap;
`;

const Companies = styled.img`
  height: 115px;
  width: 180px;
  border-radius: 10px;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  max-width: 100%;
`;

const Quote = styled(Column)`
  position: relative;
  margin-top: 130px;

  &:after {
    content: "“";
    position: absolute;
    top: -80px;
    font-size: 100px;
    color: ${Color("green", "3")};
  }
`;

const Bkg1 = styled(Column)`
  background: url("/images/home/bkg1.jpg") no-repeat bottom center;
  background-size: cover;
`;

const Bkg2 = styled(Column)`
  background: url("/images/home/bkg2.jpg") no-repeat center center;
  background-size: contain;
`;

const Bkg3 = styled(Column)`
  background: url("/images/home/bkg3.jpg") no-repeat bottom center;
  background-size: contain;
`;

const Bkg4 = styled(Column)`
  background: url("/images/home/bkg4.jpg") no-repeat bottom center;
  background-size: contain;
  width: 100%;
`;

const GradientColumn = styled(Column)`
  background: linear-gradient(180deg, rgba(247, 247, 247, 0) 0%, #eeeeef 100%);
`;

export const Signup3 = ({ signUpClick }) => {
  const location = useLocation();
  const auth = useSelector((state) => state.auth.data);
  const abVersion = useSelector((state) => state.settings.abVersion);

  const [show, setShow] = useState(false);
  const [queryParams] = useState(getUrlParams(location.search));
  const [menu, setMenu] = useState([
    { id: "marketing", label: "Marketing", dropdown: true },
    {
      id: "medicalAffairs",
      label: "Medical Affairs",
      dropdown: true,
    },
    {
      id: "sales",
      label: "Sales",
      dropdown: true,
    },
    {
      id: "resources",
      label: "Resources",
      dropdown: true,
      blank: true,
      to: "https://www.resources.medical.watch/",
    },
  ]);
  const isMedicalAffairs = queryParams.section === "medicalAffairs";
  const isSales = queryParams.section === "sales";

  const cookies = useSelector((state) => state.settings.cookies);

  useEffect(() => {
    // delay render to avoid flash
    setTimeout(() => {
      setShow(true);
      // check for cookies first before publishing event
      if (!auth?.active && cookies?.seen && !cookies?.accepted) {
        return;
      }
      // call common helper function
      logAnalyticEvent(
        "Landing",
        {
          page: "Home",
          section: queryParams.section ? queryParams.section : "marketing",
        },
        auth?.id || queryParams?.u || null,
        auth?.displayName || queryParams?.n || null,
        auth?.email || queryParams?.e || null,
        auth?.createdAt || null,
        auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
          ?.preferenceStringValue || queryParams?.t || "MEDICAL",
        null
      );
    }, 1000);
  }, []);

  useEffect(() => {
    console.log("abVersion :>> ", abVersion);
  }, [abVersion]);

  useEffect(() => {
    const newMenu = [...menu];
    if (isSales) {
      const index1 = newMenu.findIndex((item) => item.id === "marketing");
      if (index1 > -1) {
        newMenu[index1].to = "/";
      }
      const index2 = newMenu.findIndex((item) => item.id === "medicalAffairs");
      if (index2 > -1) {
        newMenu[index2].to = "/?section=medicalAffairs";
      }
    }
    if (isMedicalAffairs) {
      const index1 = newMenu.findIndex((item) => item.id === "marketing");
      if (index1 > -1) {
        newMenu[index1].to = "/";
      }
      const index2 = newMenu.findIndex((item) => item.id === "sales");
      if (index2 > -1) {
        newMenu[index2].to = "/?section=sales";
      }
    }
    if (!isMedicalAffairs && !isSales) {
      const index1 = newMenu.findIndex((item) => item.id === "medicalAffairs");
      if (index1 > -1) {
        newMenu[index1].to = "/?section=medicalAffairs";
      }
      const index2 = newMenu.findIndex((item) => item.id === "sales");
      if (index2 > -1) {
        newMenu[index2].to = "/?section=sales";
      }
    }

    setMenu(newMenu);
  }, [queryParams]);

  return (
    <MainWrapper className="dashboard">
      <Metadata />
      <Header isHome login user {...{ signUpClick, menu }} />
      <Drawer
        login
        headerMenu
        items={menu.filter((item) => item.dropdown)}
        active={
          isSales ? "sales" : isMedicalAffairs ? "medicalAffairs" : "marketing"
        }
        itemOnChange={(item) => {
          if (item.to) {
            window.location.href = item.to;
          }
        }}
        {...{ signUpClick }}
      />
      {/* <Content> */}
      <Column>
        {show && (
          <Column marginTop="40">
            <Column center paddingTop="70" paddingX="20">
              <Column maxWidth="900" center gap="30">
                <Logo />
                {isMedicalAffairs ? (
                  <>
                    <H2 center bold>
                      Actionable insights
                    </H2>
                    <H4 center>
                      Discover what physicians say about your products. Spot your
                      advocates and detractors.
                    </H4>
                  </>
                ) : (
                  <>
                    {isSales ? (
                      <>
                        <H2 center bold>
                          Top influencer lists
                        </H2>
                        <H4 center>
                          Find your believers. Personalize your outreach!
                        </H4>
                      </>
                    ) : (
                      <>
                        <H2 center bold>
                          See what physicians are saying about your brand on social
                          media
                        </H2>
                        <H4 center>
                          Get brand activity reports in your inbox. Find doctors
                          for partnership opportunities.
                        </H4>
                      </>
                    )}
                  </>
                )}
                <Button
                  label="Get Started"
                  to="/signup"
                  icon="arrowRight"
                  reverse
                />
                <Row middle>
                  <Icon name="check" color="gray" fade="7" size="26" />
                  <Label color="gray" fade="7">
                    No credit card required
                  </Label>
                </Row>
              </Column>
            </Column>

            {!isMedicalAffairs && !isSales && (
              <Bkg1 center paddingTop="70" paddingBottom="410" paddingX="20">
                <Column maxWidth="900" center gap="30">
                  <Image src="/images/home/inbox.png" />
                </Column>
              </Bkg1>
            )}

            {!isMedicalAffairs && !isSales && (
              <Column center paddingX="20">
                <Column maxWidth="900" center gap="40">
                  <H2 regular center>
                    Powering the best teams in future-driven biopharma
                    organizations
                  </H2>
                  <Column middle gap="30">
                    <Logos columns="5" center>
                      <Companies src="/images/logos/black/AstraZeneca.jpg" />
                      <Companies src="/images/logos/black/Eisai.jpg" />
                      <Companies src="/images/logos/black/Gilead.jpg" />
                      <Companies src="/images/logos/black/Pfizer.jpg" />
                      <Companies src="/images/logos/black/Novartis.jpg" />
                    </Logos>
                  </Column>
                </Column>
              </Column>
            )}

            {!isSales && (
              <Bkg2
                center
                paddingTop={!isMedicalAffairs ? "160" : "80"}
                paddingX="20"
              >
                <Column maxWidth="900" center>
                  {!isMedicalAffairs && (
                    <>
                      <H2 center>Brand activity reports</H2>
                      <Row marginTop marginBottom="60">
                        <P large>
                          Discover what physicians say about your brand. Optimize your
                          message journeys.
                        </P>
                      </Row>
                    </>
                  )}
                  <Column maxWidth="640" center>
                    <Image src="/images/home/brand.png" />
                  </Column>
                </Column>
              </Bkg2>
            )}

            {!isMedicalAffairs && !isSales && (
              <Bkg3 center paddingX="20" paddingTop="290">
                <Column maxWidth="900" center>
                  <H2 center>Top influencer lists</H2>
                  <Row marginTop marginBottom="60">
                    <P large>
                      Find your advocates and detractors. Spot the online
                      conversation leaders.
                    </P>
                  </Row>
                  <Column maxWidth="640" center>
                    <Image src="/images/home/top_influencers.png" />
                  </Column>
                </Column>
              </Bkg3>
            )}

            {isSales && (
              <Bkg3 center paddingX="20" paddingTop="80">
                <Column maxWidth="900" center>
                  <Column maxWidth="640" center>
                    <Image src="/images/home/top_influencers_2.png" />
                  </Column>
                </Column>
              </Bkg3>
            )}

            {isSales && (
              <Bkg2 center paddingTop="240" paddingX="20">
                <Column maxWidth="900" center>
                  <H2 center>Brand activity reports</H2>
                  <Row marginTop marginBottom="60">
                    <P large>
                      Discover what targets say about your brand. Influence your
                      late adopters.
                    </P>
                  </Row>
                  <Column maxWidth="640" center>
                    <Image src="/images/home/brand_2.png" />
                  </Column>
                </Column>
              </Bkg2>
            )}

            <Column
              center
              paddingTop={isMedicalAffairs || isSales ? "240" : "320"}
            >
              <Bkg4 center paddingX="20">
                <H2 center>Daily conference highlights</H2>
                <Row marginTop marginBottom="60">
                  <P large>
                    Get daily reports for medical congresses. Never miss
                    practice changing news!
                  </P>
                </Row>
                <Column maxWidth="640" center paddingBottom="75">
                  <Image src="/images/home/conference.png" />
                </Column>
              </Bkg4>
            </Column>

            {isMedicalAffairs && (
              <Bkg3 center paddingX="20" paddingTop="240" paddingBottom="200">
                <Column maxWidth="900" center>
                  <H2 center>Weekly disease reports</H2>
                  <Row marginTop marginBottom="60">
                    <P large>
                      See who's driving online discussions. Reach out for
                      scientific collaborations.
                    </P>
                  </Row>
                  <Column maxWidth="640" center>
                    <Image src="/images/home/product_alert.png" />
                  </Column>
                </Column>
              </Bkg3>
            )}

            <GradientColumn center marginY="160" paddingY="120" paddingX="60">
              <H2 center bold>
                What customers are saying
              </H2>
              {isMedicalAffairs ? (
                <Grid columns="3" gap="50">
                  <Quote>
                    <Column gap="10">
                      <P>
                        When something comes out of social media it's amazing,
                        but you don't know when it's going to happen and you
                        can't plan for it. These things just happen and you have
                        to be there.
                      </P>
                      <Label color="gray" fade="6">
                        Medical Director, GSK
                      </Label>
                    </Column>
                  </Quote>
                  <Quote>
                    <Column gap="10">
                      <P>
                        It tells us what’s top of mind for thought leaders, so
                        we have better interactions.
                      </P>
                      <Label color="gray" fade="6">
                        MSL Field Director, Lilly
                      </Label>
                    </Column>
                  </Quote>
                  <Quote>
                    <Column gap="10">
                      <P>
                        I really love the AI summary which saves me a lot of
                        time. The weekly reports are definitely relevant… It's a
                        great platform.
                      </P>
                      <Label color="gray" fade="6">
                        Senior MSL, Janssen
                      </Label>
                    </Column>
                  </Quote>
                </Grid>
              ) : (
                <>
                  {isSales ? (
                    <Grid columns="3" gap="50">
                      <Quote>
                        <Column gap="10">
                          <P>
                            It's very helpful because I'm not on social media.
                            It's always important to know what our customers are
                            saying.
                          </P>
                          <Label color="gray" fade="6">
                            SVP Commercial, AbbVie
                          </Label>
                        </Column>
                      </Quote>
                      <Quote>
                        <Column gap="10">
                          <P>
                            I get everything I need to know about our brand
                            advocates, right in email.
                          </P>
                          <Label color="gray" fade="6">
                            Regional Marketer, Pfizer
                          </Label>
                        </Column>
                      </Quote>
                      <Quote>
                        <Column gap="10">
                          <P>
                            I really like the Top Influencers email. Every once
                            in a while, I don't recognize an HCP's name so I'll
                            look up the person and forward to the rep who covers
                            that territory.
                          </P>
                          <Label color="gray" fade="6">
                            Executive Director, National Accounts, Sanofi
                          </Label>
                        </Column>
                      </Quote>
                    </Grid>
                  ) : (
                    <Grid columns="3" gap="50">
                      <Quote>
                        <Column gap="10">
                          <P>
                            Social media drives our trigger-based engagement
                            with physicians. Every time we see a post about our
                            brand, we use it as a trigger to follow-up with the
                            HCP via NPP or rep visit.
                          </P>
                          <Label color="gray" fade="6">
                            Senior Director, Omnichannel Marketing, GSK
                          </Label>
                        </Column>
                      </Quote>
                      <Quote>
                        <Column gap="10">
                          <P>
                            Social media insights help to inform our media
                            strategy. When there's a new approach to dosing or
                            new biomarker, this informs our key messages and
                            tactics.
                          </P>
                          <Label color="gray" fade="6">
                            Associate Marketing Director, Amgen
                          </Label>
                        </Column>
                      </Quote>
                      <Quote>
                        <Column gap="10">
                          <P>
                            Medical.watch helps me figure out what content I
                            need to put out there to help drive engagement.
                          </P>
                          <Label color="gray" fade="6">
                            Marketing Director, Novartis
                          </Label>
                        </Column>
                      </Quote>
                    </Grid>
                  )}
                </>
              )}
            </GradientColumn>

            <Column center paddingBottom="160" paddingX="20" gap="40">
              <Logo />
              <H2 center>Ready to get started?</H2>
              <Button
                label="Get Started"
                to="/signup"
                icon="arrowRight"
                reverse
              />
              <Row middle>
                <Icon name="check" color="gray" fade="7" size="26" />
                <Label color="gray" fade="7">
                  No credit card required
                </Label>
              </Row>
            </Column>

            <Footer />
          </Column>
        )}
      </Column>
      {/* </Content> */}
    </MainWrapper>
  );
};
