import React from "react";
import { Row, Circle } from "./Layout";
import { H4, Label } from "./Typography";

export const Sentiment = ({ value }) => (
  <>
    {value > 0 && (
      <Row gap="5" middle>
        <Circle radius="10" bkg="green" />
        <H4 bold>Positive</H4>
      </Row>
    )}
    {value === 0 && (
      <Row gap="5" middle>
        <Circle radius="10" bkg="gray" />
        <H4 bold>Neutral</H4>
      </Row>
    )}
    {value < 0 && (
      <Row gap="5" middle>
        <Circle radius="10" bkg="red" />
        <H4 bold>Negative</H4>
      </Row>
    )}
    {value == null && (
      <Row gap="5" middle>
        <H4 bold>-</H4>
      </Row>
    )}
  </>
);

export const Influence = ({ value }) => (
  <>
    {value > 666 && (
      <Row gap="5" middle>
        <Circle radius="10" bkg="green" />
        <img height="30" src="/emails/images/influence-high.png" />
        <Label bold>High</Label>
      </Row>
    )}
    {value <= 666 && value >= 333 && (
      <Row gap="5" middle>
        <img height="30" src="/emails/images/influence-medium.png" />
        <Label bold>Medium</Label>
      </Row>
    )}
    {value < 333 && (
      <Row gap="5" middle>
        <img height="30" src="/emails/images/influence-low.png" />
        <Label bold>Low</Label>
      </Row>
    )}
  </>
);
