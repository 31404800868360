import { useEffect } from "react";
import { useSelector } from "react-redux";


import { view } from "./MentionsView";
import {
  getUrlParams,
  logAnalyticEvent,
} from "../../../utils";

export const EmailTopMentions = () => {

  const { nId, name, email } =
    getUrlParams(location.search);

  // --> mapStateToProp
  const auth = useSelector((state) => state.auth.data);
  // <-- mapStateToProp

  const cookies = useSelector((state) => state.settings.cookies);

  // --> EFFECT
  useEffect(() => {
    let _userType = "MEDICAL";
    if (auth?.id) {
      _userType =
        auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
          ?.preferenceStringValue || "MEDICAL";
    }
    // check for cookies first before publishing event
    if (!auth?.active && cookies?.seen && !cookies?.accepted) {
      return;
    }
    // call common helper function
    logAnalyticEvent(
      "View In Browser",
      {
        page: "TOP_BRAND_MENTIONS",
      },
      auth?.id || null,
      auth?.displayName || name || null,
      auth?.email || email || null,
      auth?.createdAt || null,
      _userType
    );
  }, []);
  // <-- EFFECT

  return view({
    nId,
  });
};
