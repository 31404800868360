import React from "react";
import {
  MainWrapper,
  Content,
  Column,
  Header,
  Card,
  P,
  H3,
  Row,
} from "../../components";

export const view = ({}) => (
  <MainWrapper>
    <Header logo login />

    <Content>
      <Column center middle>
        <Card center>
          <H3 bold center>
            This page requires authentication.
          </H3>
          <Row marginTop>
            <P center bold>
              Please Sign In or Sign Up.
            </P>
          </Row>
        </Card>
      </Column>
    </Content>
  </MainWrapper>
);
