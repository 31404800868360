import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  highlightActions,
  diseaseActions,
  conferenceActions,
  tweetActions,
} from "../../../store/actions";
import {
  cancelHighlightRequests,
  cancelDiseaseRequests,
  cancelConferenceRequests,
  cancelTweetRequests,
} from "../../../store/sagas";
import { view } from "./HighlightsView";
import {
  getISOFormattedDateTimeString,
  getUrlParams,
  logAnalyticEvent,
} from "../../../utils";

export const EmailHighlights = () => {
  const dispatch = useDispatch();

  const {
    nId,
    name,
    email,
    diseaseId,
    conferenceId,
    diseaseName,
    startDate,
    endDate,
    tweets,
  } = getUrlParams(location.search);

  const dates = {
    start: getISOFormattedDateTimeString(startDate),
    end: endDate ? getISOFormattedDateTimeString(endDate) : null,
  };

  // --> mapStateToProp
  const auth = useSelector((state) => state.auth.data);
  const isLoggedIn = useSelector((state) => state.auth.data.id);
  const disease = useSelector((state) => state.disease.one.data);
  const conference = useSelector((state) => state.conference.one.data);
  const highlights = useSelector((state) => state.highlight.all);
  const allTweets = useSelector((state) => state.tweet.all);
  // <-- mapStateToProp

  const cookies = useSelector((state) => state.settings.cookies);

  // --> EFFECT
  useEffect(() => {
    if ((diseaseId || conferenceId) && startDate) {
      diseaseId && requestDisease();
      conferenceId && requestConference();

      if (!nId) {
        if (tweets) {
          // No highlights
          const tweetIds = tweets.split(",");
          requestTweets(tweetIds);
        } else {
          // Highlights
          requestHighlights();
        }
      }
    }

    return () => {
      tweetActions.clear();
      diseaseActions.clear();
      conferenceActions.clear();
      highlightActions.clear();
      cancelHighlightRequests.abort();
      cancelDiseaseRequests.abort();
      cancelConferenceRequests.abort();
      cancelTweetRequests.abort();
    };
  }, []);

  useEffect(() => {
    if (!_.isEmpty(disease) || !_.isEmpty(conference)) {
      const category = tweets
        ? `Top Tweets ${conferenceId ? "Congress" : ""}`
        : diseaseId
        ? "Disease Highlights"
        : "Congress Highlights";
      let userType = null;
      if (auth?.id)
        userType =
          auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
            ?.preferenceStringValue || "MEDICAL";
      // check for cookies first before publishing event
      if (!auth?.active && cookies?.seen && !cookies?.accepted) {
        return;
      }
      // call common helper function
      logAnalyticEvent(
        "View In Browser",
        {
          id: diseaseId || conferenceId,
          tagId: disease?.tagId || conference?.tagId,
          objectType: diseaseId ? "DISEASE" : "CONFERENCE",
          name: disease?.primaryName || conference?.preferenceName,
          ...(dates.start && { startDate: dates.start }),
          ...(dates.end && { endDate: dates.end }),
          category,
          ...(tweets && { tweetIds: tweets }),
        },
        auth?.id || null,
        auth?.displayName || name || null,
        auth?.email || email || null,
        auth?.createdAt || null,
        userType
      );
    }
  }, [disease, conference]);
  // <-- EFFECT

  const requestHighlights = () => {
    dispatch(
      highlightActions.request({
        startDate: dates.start,
        ...(dates.end && { endDate: dates.end }),
        objectType: diseaseId ? "DISEASE" : "CONFERENCE",
        objectId: diseaseId || conferenceId,
      })
    );
  };

  const requestDisease = () => {
    dispatch(diseaseActions.oneRequest(diseaseId));
  };

  const requestConference = () => {
    dispatch(conferenceActions.oneRequest(conferenceId));
  };

  const requestTweets = (ids) => {
    dispatch(
      tweetActions.multipleRequest({
        ids,
        originalOnly: "true",
        includeReplies: "false",
        sortBy: "score",
      })
    );
  };

  return view({
    nId,
    name,
    highlights: highlights.data || [],
    loading: highlights.fetching || allTweets.fetching,
    dates,
    monitor: { ...disease, ...conference },
    diseaseName,
    tweets: allTweets.data,
  });
};
