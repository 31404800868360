import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { cloneDeep, isEmpty, uniqBy } from "lodash";
import useDebounce from "../../../utils/useDebounce";

import useKeyPress from "../../../utils/useKeyPress";
import { tagActions, commonActions } from "../../../store/actions";
import { view } from "./EditTagView.js";
import { MAX_RESULTS } from "../../../utils/variables";

const initialData = {
  name: "",
  meaning: "OTHER",
  alwaysIgnore: false,
  alwaysMatch: true,
  reviewed: false,
  visible: true,
};

const errorsMessage = {
  name: "Required field",
};

export const EditTag = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const enterPress = useKeyPress("Enter");

  const tag = useSelector((state) => state.tag.one.data);
  const initialTags = useSelector((state) => state.tag.all);
  const whitelist = useSelector((state) => state.tag.whitelist.data);
  const meaningState = useSelector((state) => state.common.meanings);

  const [isSavePressed, setIsSavePressed] = useState(false);
  const [errors, setErrors] = useState({});
  const [deleteModal, toggleDeleteModal] = useState(false);
  const [data, setData] = useState(initialData);

  const [meaningItems, setMeaningItems] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const debounceTag = useDebounce(searchTerm, 700);
  const [pageTags, setPageTags] = useState(0);

  const requestTags = (pageNum) => {
    dispatch(
      tagActions.request({
        meaning: null,
        ignore: false,
        match: null,
        preferred: null,
        maxResult: MAX_RESULTS,
        ...(searchTerm !== "" && { term: searchTerm }),
        pageNum,
      })
    );
  };

  useEffect(() => {
    if (meaningState?.length > 0) {
      const newMeanigns = [];
      for (const key in meaningState) {
        newMeanigns.push({
          id: meaningState[key].enumStringValue,
          label: meaningState[key].enumValueDisplayName,
        });
      }
      setMeaningItems(newMeanigns);
    }
  }, [meaningState]);

  useEffect(() => {
    // checking `isSavePressed` to prevent reseting fields after creating a new user
    if (!isSavePressed) {
      if (id && tag) {
        setData({
          id: tag.id,
          name: tag.name,
          meaning: tag.meaning,
          alwaysIgnore: tag.alwaysIgnore,
          alwaysMatch: tag.alwaysMatch,
          preferredTagId: tag?.preferredTagId,
          preferredTagName: tag?.preferredTagName,
        });
      } else {
        setData(initialData);
      }
    }
  }, [tag]);

  useEffect(() => {
    dispatch(commonActions.requestMeanings());
    if (id) {
      dispatch(tagActions.oneRequest(id));
      // requestTags();
      dispatch(tagActions.whitelistRequest({
        ids: [id],
        nocache: true,
      }));
    }

    return () => {
      dispatch(tagActions.clear());
      dispatch(tagActions.clearTags());
    };
  }, [dispatch]);

  useEffect(() => {
    if (initialTags.data.length > 0) {
      const newTags = initialTags.data.map((item) => ({
        ...item,
        label: item.name,
      }));

      // Excluding current tag name
      let finalTags = newTags.filter((tag) => tag.id !== data.id);
      // exclude tags which are substitutes to others
      finalTags = finalTags.filter((k) => !k.substitutedIds);
      // exclude tags which are whitelisted
      finalTags = finalTags.filter((k) => !k.preferredTagId);
      // console.log("after => ", finalTags)

      if (whitelist?.length > 0) {
        // exclude which are already associated
        whitelist.forEach((wTag) => {
          finalTags = finalTags.filter((tag) => tag.id !== wTag.id);
        });
        const whitelistedTags = whitelist.map((tag) => ({
          ...tag,
          label: tag.name,
        }));
        setSelectedTags(whitelistedTags);
      }
      setTags(finalTags);
    } else {
      setTags([]);
    }
  }, [initialTags.data, whitelist]);

  useEffect(() => {
    setPageTags(0);
    requestTags(0);
  }, [debounceTag]);

  const checkErrors = () => {
    let newErrors = {};
    for (const item in errorsMessage) {
      if (data[item] === "") {
        newErrors[item] = errorsMessage[item];
      } else {
        delete newErrors[item];
      }
    }
    setErrors(newErrors);
  };

  const addSelectedTags = (item) => {
    const clonedTags = cloneDeep(selectedTags);
    setSelectedTags([...clonedTags, item]);
    setSelectedTags([...clonedTags, item]);

    dispatch(
      tagActions.whitelistUpdate({ id: item.id, preferredTagId: data.id })
    );
  };

  const removeSelectedTags = (item) => {
    const clonedTags = cloneDeep(selectedTags);
    setSelectedTags(clonedTags.filter((i) => i.id !== item.id));

    dispatch(tagActions.whitelistUpdate({ id: item.id, preferredTagId: null }));
  };

  const handleSearchTag = (e) => {
    setSearchTerm(e);
  };

  const handleChange = ({ value, field }) => {
    setData((oldData) => ({
      ...oldData,
      [field]: value,
    }));
  };

  const handleDelete = () => {
    dispatch(tagActions.delete({ id: data.id }));
  };

  const handleSave = () => {
    setIsSavePressed(true);
    if (isEmpty(errors)) {
      const newData = {
        ...data,
        alwaysMatch: true, // always exact match
      };
      dispatch(tagActions.create(newData));
    }
  };

  const handleNameOnBlur = () => {
    if (id && isEmpty(errors)) {
      dispatch(tagActions.update(data));
    }
  };

  const handleMeaningOnBlur = () => {
    if (id && isEmpty(errors)) {
      dispatch(tagActions.update(data));
    }
  };

  const handleSwitchChange = ({ value, field }) => {
    const newData = {
      ...data,
      [field]: value,
    };
    setData(newData);
    id && dispatch(tagActions.update(newData));
  };

  useEffect(() => {
    checkErrors();
  }, [data]);

  useEffect(() => {
    if (enterPress) handleSave();
  }, [enterPress]);

  const handleMoreTags = () => {
    if (!initialTags.fetching) {
      const tagsNewPage = pageTags + 1;
      setPageTags(tagsNewPage);
      requestTags(tagsNewPage);
    }
  };

  return view({
    errors,
    handleSave,
    data,
    handleChange,
    isSavePressed,
    isNew: !id,
    deleteModal,
    toggleDeleteModal,
    handleDelete,
    handleSwitchChange,

    tags,
    selectedTags,
    addSelectedTags,
    removeSelectedTags,
    meaningItems,
    searchTerm,
    handleSearchTag,
    tagsInfinteScroll: {
      loading: initialTags.fetching,
      hasNextPage: !initialTags.isLastPage,
      onLoadMore: handleMoreTags,
    },

    handleNameOnBlur,
    handleMeaningOnBlur,
  });
};
