import React from "react";
import {
  Row,
  Column,
  Avatar,
  H4,
  Anchor,
  MainWrapper,
  Header,
  Content,
  Card,
  Label,
  ResponsiveButton,
  RowToColumn,
  Filters,
  Input,
  Dropdown,
  List,
  ListItem,
  Switch,
  Grid,
  Spinner,
} from "../../../components";
import { MicroLineChart } from "../../../components/MicroLineChart";

import { Menu } from "../Menu";

const Group = ({ label, value }) => (
  <>
    {value && (
      <Column gap="2" marginBottom="5">
        <Row>
          <Label small color="gray" fade="7">
            {label}
          </Label>
        </Row>
        <Label small bold>
          {value}
        </Label>
      </Column>
    )}
  </>
);

export const view = ({
  users,
  infiniteScrollRef,
  hasNextPage,
  loading,

  handleUserStatusChange,
  searchTerm,
  handleSearchTermChange,

  usersCount,
  activeFilters,

  sortByOptions,
  sortBy,
  handleSortByChange,

  sortOrderOptions,
  sortOrder,
  handleSortOrderChange,

  roleOptions,
  role,
  handleRoleChange,

  activeOptions,
  active,
  handleActiveChange,

  createdByOptions,
  createdBy,
  handleCreatedByChange,

  userTypeOptions,
  userType,
  handleUserTypeChange,

  userTypeSummary,

  // chartLoading,
  timeSegmentedSummary,

  exportCSV,
}) => {
  return (
    <MainWrapper drawer>
      <Header
        drawer
        rightContent={
          <>
            <Row fit />
            <ResponsiveButton label="Add User" to="/newUser" />
          </>
        }
      />
      <Menu active="users" />

      <Content>
        <Column>
          <Column marginBottom>
            <Filters counter={`${usersCount} Users`} {...{ activeFilters }}>
              <Grid>
                <Column gap="10">
                  <Label bold>Name Or Email</Label>
                  <Input
                    small
                    placeholder="Search"
                    icon="search"
                    value={searchTerm}
                    onChange={(e) => handleSearchTermChange(e.target.value)}
                  />
                </Column>
                <Column gap="10">
                  <Label bold>Sort By</Label>
                  <Dropdown
                    expand
                    select
                    value={sortBy}
                    items={sortByOptions}
                    onChange={(e) => handleSortByChange(e)}
                  />
                </Column>
                <Column gap="10">
                  <Label bold>Role</Label>
                  <Dropdown
                    expand
                    select
                    value={role}
                    items={roleOptions}
                    onChange={(e) => handleRoleChange(e.id)}
                  />
                </Column>
                <Column gap="10">
                  <Label bold>Sort Order</Label>
                  <Dropdown
                    expand
                    select
                    value={sortOrder}
                    items={sortOrderOptions}
                    onChange={(e) => handleSortOrderChange(e)}
                  />
                </Column>
                <Column gap="10">
                  <Label bold>Status</Label>
                  <Dropdown
                    expand
                    select
                    value={active}
                    items={activeOptions}
                    onChange={(e) => handleActiveChange(e.id)}
                  />
                </Column>
                <Column gap="10">
                  <Label bold>Created By</Label>
                  <Dropdown
                    expand
                    select
                    value={createdBy}
                    items={createdByOptions}
                    onChange={(e) => handleCreatedByChange(e.id)}
                  />
                </Column>
                <Column gap="10">
                  <Label bold>User Type</Label>
                  <Dropdown
                    expand
                    select
                    value={userType}
                    items={userTypeOptions}
                    onChange={(e) => handleUserTypeChange(e.id)}
                  />
                </Column>
              </Grid>
            </Filters>
          </Column>
          <RowToColumn marginBottom="30" gap="30" columnGap="10">
            {users?.length > 0 && (
              <Row noShrink marginRight="10">
                <Anchor
                  bold
                  label="Export"
                  icon="download"
                  onClick={() => exportCSV()}
                />
              </Row>
            )}
            {userTypeSummary.length > 0 && userType === "ALL" && (
              <Row gap="10" middle>
                <Row gap="5" small>
                  <Label small>Type:</Label>
                </Row>
                {userTypeSummary.map((s, i) => (
                  <Row gap="5">
                    <Label color="gray" fade="0.1" small>
                      {s.category}:
                    </Label>
                    <Label bold color="gray" fade="0.3" small>
                      {s.count}
                    </Label>
                  </Row>
                ))}
              </Row>
            )}
            {timeSegmentedSummary.length > 0 && (
              <Row gap="10">
                <Row gap="5" middle>
                  <Label small>Trend:</Label>
                </Row>
                <Row middle>
                  <MicroLineChart
                    id={`microLineChart`}
                    data={timeSegmentedSummary}
                    handleNameClick={() => {}}
                  />
                </Row>
              </Row>
            )}
          </RowToColumn>

          {/* <Column marginBottom>
            {timeSegmentedSummary.length > 0 ? (
              <Card border>
                <LineChart
                  id={`lineChart`}
                  data={timeSegmentedSummary}
                  handleNameClick={() => {}}
                />
            </Card>
            ) : (
              <>
                {chartLoading && (
                  <Row expand paddingAll="80" center>
                    <Spinner />
                  </Row>
                )}
              </>
            )}
          </Column> */}

          <Column paddingBottom="40" maxWidth="700">
            {users.map((user, i) => (
              <Card border key={user.id}>
                <Row middle gap="20" marginBottom="10" marginTop="-5">
                  {user.firstName || user.lastName ? (
                    <Row middle gap="15">
                      <Avatar name={user.firstName} />
                      <H4 bold title={`${user.firstName} ${user.lastName}`}>
                        {user.firstName} {user.lastName}
                      </H4>
                    </Row>
                  ) : (
                    <Row>
                      <Avatar name="?" />
                    </Row>
                  )}
                  <Row gap="10" middle fit right>
                    <Switch
                      checked={user.active}
                      onChange={(value) => {
                        handleUserStatusChange(user.id, value);
                      }}
                    />
                    <Row noShrink>
                      <Anchor bold label="Edit" to={`/editUser/${user.id}`} />
                    </Row>
                  </Row>
                </Row>
                <Grid columns={3}>
                  <Group label="Email" value={user.email} />
                  <Group label="Role" value={user.mwRole} />
                  <Group label="Type" value={user.userType || "MEDICAL"} />
                  <Group
                    label="Created"
                    value={new Date(user.createdAt).toLocaleString("en-US")}
                  />
                  <Group
                    label="Modified"
                    value={new Date(user.lastModified).toLocaleString("en-US")}
                  />
                  {user.lastLogin && (
                    <Group
                      label="Last Login"
                      value={new Date(user.lastLogin).toLocaleString("en-US")}
                    />
                  )}
                  {user.lastNotificationEvent?.eventTimestamp && (
                    <Group
                      label="Last email open"
                      value={`${new Date(
                        user.lastNotificationEvent?.eventTimestamp
                      ).toLocaleString("en-US")} (${
                        user.lastNotificationEvent?.category || ""
                      })`}
                    />
                  )}
                  {user.userPlans?.length > 0 && (
                    <Group
                      label="Active Plan"
                      value={user.userPlans[0].plan.name}
                    />
                  )}
                </Grid>
              </Card>
            ))}
          </Column>
          {loading || hasNextPage ? (
            <Row expand paddingAll="40" center ref={infiniteScrollRef}>
              <Spinner />
            </Row>
          ) : (
            <>
              {users?.length === 0 && (
                <List center>
                  <ListItem>No Users found</ListItem>
                </List>
              )}
            </>
          )}
        </Column>
      </Content>
    </MainWrapper>
  );
};
