import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { view } from "./DiseaseView";
import {
  logAnalyticEvent,
  getISOFormattedDateTimeString,
  getUrlParams,
  months,
} from "../../utils";
import { useDiseaseInfluencers } from "../../store/queries/influencers";
import { useGetTweets } from "../../store/queries/tweets";
import { settingsActions } from "../../store/actions";

export const DiseaseInfluencers = ({ signUpClick }) => {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings.influencers);
  // const auth = useSelector((state) => state.auth.data);

  const date = new Date();
  const currentMonth = date.getMonth();
  const currentYear = date.getFullYear();

  const getMonthInfo = (monthOffset) => {
    const targetDate = new Date(currentYear, currentMonth - monthOffset, 1);
    const monthIndex = targetDate.getMonth();
    const year = targetDate.getFullYear();
    return { month: months[monthIndex], year };
  };

  const dateItems = [1, 2, 3].map((offset) => {
    const { month, year } = getMonthInfo(offset);
    return {
      id: `${month}-${year}`,
      label: `${month.charAt(0).toUpperCase() + month.slice(1)} ${year}`,
    };
  });

  const [selectedDisease, setSelectedDisease] = useState(null);
  const [selectedDate, setSelectedDate] = useState(
    settings?.disease?.date || dateItems[0].id
  );
  const [selectedMonth, selectedYear] = selectedDate.split("-");

  const firstDayOfMonth = new Date(
    selectedYear,
    months.indexOf(selectedMonth),
    1
  );
  const lastDayOfMonth = new Date(
    selectedYear,
    months.indexOf(selectedMonth) + 1,
    0
  );
  lastDayOfMonth.setHours(23, 59, 59, 999);

  const {
    data: diseases,
    isLoading: loadingDiseases,
    isSuccess,
    refetch: refetchDiseases,
  } = useDiseaseInfluencers({
    startDate: getISOFormattedDateTimeString(firstDayOfMonth),
    endDate: getISOFormattedDateTimeString(lastDayOfMonth),
  });

  const {
    data: tweets,
    isLoading: isLoadingTweets,
    refetch: refetchTweets,
  } = useGetTweets(
    {
      ...(selectedDisease && { addTagIds: [selectedDisease] }),
      // ...(auth?.id && { userId: auth.id }),
      startDate: getISOFormattedDateTimeString(firstDayOfMonth),
      endDate: getISOFormattedDateTimeString(lastDayOfMonth),
      sortBy: "score",
      sortOrder: "desc",
      maxResult: 1,
      pageNum: 0,
    },
    { enabled: !!selectedDisease }
  );

  const handleDateChange = (item) => {
    setSelectedDate(item.id);
    dispatch(
      settingsActions.set({
        influencers: {
          ...settings,
          // disease: { ...settings?.disease, date: "january-2024" },
          disease: { ...settings?.disease, date: item.id },
        },
      })
    );
    setTimeout(() => {
      refetchDiseases();
    }, 200);
  };

  useEffect(() => {
    // const storedDate = settings?.disease?.date;
    // if (storedDate && storedDate !== selectedDate) {
    //   dispatch(
    //     settingsActions.set({
    //       influencers: {
    //         ...settings,
    //         disease: { ...settings?.disease, date: selectedDate },
    //       },
    //     })
    //   );
    //   refetchDiseases();
    // }
    // Uncomment and update this section when ready to implement analytics
    // const queryParams = getUrlParams();
    // logAnalyticEvent(
    //   "Landing",
    //   {
    //     page: "Home",
    //     section: queryParams.section ? queryParams.section : "marketing",
    //   },
    //   auth?.id || queryParams?.u || null,
    //   auth?.displayName || queryParams?.n || null,
    //   auth?.email || queryParams?.e || null,
    //   auth?.createdAt || null,
    //   auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
    //     ?.preferenceStringValue ||
    //     queryParams?.t ||
    //     "MEDICAL",
    //   null
    // );
  }, []);

  useEffect(() => {
    if (diseases?.data?.length > 0) {
      setSelectedDisease(diseases.data[0].id);
    }
  }, [diseases?.data]);

  useEffect(() => {
    if (selectedDisease) {
      refetchTweets();
    }
  }, [selectedDisease]);

  return view({
    signUpClick,
    diseases: diseases?.data,
    loadingDiseases,
    isSuccess,
    handleDateChange,
    dateItems,
    selectedDate,
    tweet: tweets?.data?.[0],
    isLoadingTweets,
  });
};
