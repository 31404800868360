import { put, call, takeLatest, all } from "redux-saga/effects";
import axios from "axios";

import { getApiPath, MAX_RESULTS } from "../../utils/variables";
import { authHeader } from "../store";
import { MONITOR_ACTIONS, monitorActions } from "../actions";
import { objectToUrlEncoded } from "../../utils/constants";

export let cancelMonitorRequests = new AbortController();

/**
 * get monitor by id
 */
function* handleOneRequest(action) {
  const { id } = action.payload;

  try {
    cancelMonitorRequests = new AbortController();
    const { data } = yield call(
      axios.get,
      `${getApiPath()}/user/monitor/${id}`,
      {
        headers: authHeader(),
        signal: cancelMonitorRequests.signal,
      }
    );
    yield put(monitorActions.oneSuccess({ data }));
  } catch (e) {
    if (e?.response?.status === 401) {
      yield put((window.location.href = "/logout"));
    }
    yield put(monitorActions.oneFailure({ error: { ...e } }));
  }
}

/**
 * get all monitors in the system
 */
function* handleRequest(action) {
  const { userId, objectType, objectTerm, interval, visible, pageNum, maxResult } =
    action.payload;

  const params = objectToUrlEncoded({
    userId,
    objectType,
    objectTerm,
    interval,
    visible,
    pageNum,
    maxResult: maxResult || MAX_RESULTS,
  });
  try {
    cancelMonitorRequests = new AbortController();
    const { data } = yield call(
      axios.get,
      `${getApiPath()}/monitors?${params}`,
      {
        headers: authHeader(),
        signal: cancelMonitorRequests.signal,
      }
    );
    yield put(monitorActions.success({ data, pageNum }));
  } catch (e) {
    if (e?.response?.status === 401) {
      yield put((window.location.href = "/logout"));
    }
    yield put(monitorActions.failure({ error: { ...e } }));
  }
}

/**
 * get all monitors for a user
 */
function* handleMonitorsRequest(action) {
  const { id } = action.payload;
  try {
    cancelMonitorRequests = new AbortController();
    const { data } = yield call(
      axios.get,
      `${getApiPath()}/user/${id}/monitors`,
      {
        headers: authHeader(),
        signal: cancelMonitorRequests.signal,
      }
    );
    yield put(monitorActions.monitorsSuccess({ data }));
  } catch (e) {
    if (e?.response?.status === 401) {
      yield put((window.location.href = "/logout"));
    }
    yield put(monitorActions.monitorsFailure({ error: { ...e } }));
  }
}

/**
 * method to fetch monitors for a user
 * @param {*} monitor 
 * @param {*} userId 
 * @returns 
 */
export const getMonitors = (userId) => {
  return axios.get(
    `${getApiPath()}/user/${userId}/monitors`,
    { headers: authHeader() }
  );
};

/**
 * method to create monitor, but not to refresh monitor list
 * @param {*} monitor 
 * @param {*} userId 
 * @returns 
 */
export const createMonitor = (monitor, userId) => {
  return axios.post(
    `${getApiPath()}/user/${userId}/monitor`,
    monitor, 
    { headers: authHeader() }
  );
};

/**
 * method to update monitor, but not to refresh monitor list
 * @param {*} monitor 
 * @param {*} userId 
 * @returns 
 */
export const updateMonitor = (monitor) => {
  return axios.put(
    `${getApiPath()}/user/monitor`,
    monitor, 
    { headers: authHeader() }
  );
};

/**
 * method to delete monitor, but not to refresh monitor list
 * @param {*} monitor 
 * @param {*} userId 
 * @returns 
 */
export const deleteMonitor = (monitorId) => {
  return axios.delete(
    `${getApiPath()}/user/monitor?id=${monitorId}`,
    { headers: authHeader() }
  );
};

function* watchMonitorSagas() {
  yield all([
    takeLatest(MONITOR_ACTIONS.ONE_REQUEST, handleOneRequest),
    takeLatest(MONITOR_ACTIONS.REQUEST, handleRequest),
    takeLatest(MONITOR_ACTIONS.MONITORS_REQUEST, handleMonitorsRequest),
  ]);
}

export default watchMonitorSagas;
