import { useQuery } from "react-query";
import axios from "axios";
import { getApiPath } from "../../utils/variables";
import { authHeader } from "../store";
import { objectToUrlEncoded } from "../../utils/constants";

export function getRelatedSearch() {
  return axios.get(`${getApiPath()}/accounts/related`, {
    headers: authHeader(),
  });
}
export function useGetRelatedSearch({}) {
  return useQuery(["relatedSearch"], () => getRelatedSearch(), {
    cacheTime: 0,
  });
}

export function getSearch(params) {
  const { pattern, size, page, type, region, userId, userType } = params;
  const encodedParams = objectToUrlEncoded({
    pattern,
    size,
    page,
    type,
    region,
    userId,
    userType,
  });
  if (!pattern) {
    return new Promise((resolve) => {
      resolve({
        data: {
          data: {
            hits: 0,
            items: [],
            nextPage: 0,
          },
        },
      });
    });
  }
  return axios.get(`${getApiPath(false)}/search/account?${encodedParams}`, {});
}
export function useGetSearch({ params }) {
  return useQuery(["search"], () => getSearch(params), {
    cacheTime: 0,
  });
}
