import React from "react";
import {
  Column,
  Row,
  MainWrapper,
  Header,
  Content,
  H2,
  Label,
  Input,
  Button,
  Switch,
  Anchor,
  DateRange,
  AutoCompleteWithResponses,
  Modal,
  ModalFooter,
  Pill,
  Collapsible,
} from "../../../components";
import { Menu } from "../Menu";

export const Group = ({ children, label, secondLabel, first }) => (
  <Column marginTop={first ? "0" : "30"}>
    <Row marginBottom="10" gap="5">
      <Label>
        <Label bold>{label}</Label>
        {secondLabel && <Label> {secondLabel}</Label>}
      </Label>
    </Row>
    <Row marginTop="5">{children}</Row>
  </Column>
);

export const view = ({
  isEditMode,
  errors,
  handleSave,
  handleChange,
  handleSwitchChange,
  data,
  isSavePressed,
  isNew,
  deleteModal,
  toggleDeleteModal,
  handleDelete,
  handleNameOnBlur,

  selectedProducts,
  dates,
  handleDateChange,
  metrics,
  usersSubscribed,

  mergableTags,
  mergedTags,
  addMergedTags,
  removeMergedTags,
  searchMergableTag,
  handleSearchMergableTag,

  tagsInfinteScroll,
  handleMoreTags,
}) => (
  <MainWrapper drawer editMode={isEditMode}>
    {!isEditMode && (
      <Header
        drawer
        close={isNew}
        back={!isNew}
        bkg="white"
        rightContent={
          <Row>
            {isNew && <Button label={"Create"} onClick={() => handleSave()} />}
            {!isNew && (
              <Row marginX>
                <Anchor
                  label="Delete"
                  color="red"
                  onClick={() => toggleDeleteModal(true)}
                />
              </Row>
            )}
          </Row>
        }
      ></Header>
    )}
    <Menu active="diseases" />

    <Content>
      <Column center>
        <Column maxWidth="700" paddingBottom={isEditMode ? "20" : "220"}>
          {!isEditMode && (
            <>
              <Row expand paddingY="15">
                <H2 bold>{isNew ? "Add a new Disease" : "Edit Disease"}</H2>
              </Row>
              <Group label="Name">
                <Column maxWidth="400">
                  <Input
                    small
                    placeholder="Name"
                    value={data?.primaryName}
                    errorMessage={
                      isSavePressed && errors.primaryName
                        ? "Required field"
                        : ""
                    }
                    onChange={(e) =>
                      handleChange({
                        value: e.target?.value,
                        field: "primaryName",
                      })
                    }
                    onBlur={handleNameOnBlur}
                  />
                </Column>
              </Group>

              <Column marginTop>
                <Switch
                  label="Active"
                  checked={!data?.tag?.alwaysIgnore}
                  disabled={!data.alwaysIgnore && mergedTags.length > 0}
                  onChange={(e) =>
                    handleSwitchChange({ value: !e, field: "alwaysIgnore" })
                  }
                />
                <Row marginTop="-10" marginLeft="50">
                  <Label color="gray" fade="6">
                    This Tag is active and visible.
                    {!data.alwaysIgnore &&
                      mergedTags.length > 0 &&
                      ` Cannot be deactivated as there are merged tags`}
                  </Label>
                </Row>
              </Column>
              {/* <Column marginTop>
                <Switch
                  label="Exact Match"
                  checked={data?.tag?.alwaysMatch}
                  onChange={(e) =>
                    handleSwitchChange({ value: e, field: "alwaysMatch" })
                  }
                />
                <Row marginTop="-10" marginLeft="50">
                  <Label color="gray" fade="6">
                    Set exact match toggle ON for important tags you always want
                    to assign when the exact tag text is found in free text.
                  </Label>
                </Row>
              </Column> */}
            </>
          )}

          {!isNew && (
            <Column marginTop="40">
              {!_.isEmpty(metrics) && (
                <Column marginBottom="20">
                  <Collapsible label="Metrics">
                    <Column paddingAll gap="15">
                      <Row gap="10" middle>
                        <Label>From </Label>
                        <DateRange
                          noBackground
                          period
                          initialDate={dates.start}
                          endDate={dates.end}
                          onChange={handleDateChange}
                          noFutureDates
                          allTime
                        />
                      </Row>
                      <Row flexWrap marginBottom="-10">
                        <Label>Posts:</Label>
                        <Label bold>
                          {metrics.tweetCount !== -1 ? metrics.tweetCount : 0}
                        </Label>
                      </Row>
                      <Row flexWrap marginBottom="-10">
                        <Label>Accounts:</Label>
                        <Label bold>{metrics.accountCount}</Label>
                      </Row>
                    </Column>
                  </Collapsible>
                </Column>
              )}

              {usersSubscribed?.length > 0 && (
                <Column marginBottom="20">
                  <Collapsible label="Users Subscribed">
                    <Column paddingAll gap="15">
                      {/* <Group label="Users Subscribed"> */}
                      <Row flexWrap marginBottom="-10">
                        {usersSubscribed.map((user) => (
                          <Pill label={user?.displayName} key={user?.id} />
                        ))}
                      </Row>
                      {/* </Group> */}
                    </Column>
                  </Collapsible>
                </Column>
              )}

              {selectedProducts?.length > 0 && (
                <Column marginBottom="20">
                  <Collapsible label="Products (Deprecated)">
                    <Column paddingAll gap="15">
                      {/* <Group label="Products (Deprecated)"> */}
                      <Row flexWrap marginBottom="-10">
                        {selectedProducts.map((tag) => (
                          <Pill label={tag?.label} key={tag?.id} />
                        ))}
                      </Row>
                      {/* </Group> */}
                    </Column>
                  </Collapsible>
                </Column>
              )}
              {data?.tagsAssociatedWith?.length > 0 && (
                <Column marginBottom="20">
                  <Collapsible label="Congresses (Deprecated)">
                    {data?.tagsAssociatedWith.filter(
                      (t) => t.fromTag.conferenceId
                    )?.length > 0 && (
                      <Column paddingAll gap="15">
                        {/* <Group label="Conferences (Deprecated)"> */}
                        <Row flexWrap marginBottom="-10">
                          {data.tagsAssociatedWith
                            .filter((t) => t.fromTag.conferenceId)
                            .map((tag) => (
                              <Pill
                                label={tag?.fromTag?.name}
                                key={tag?.fromTag?.name}
                              />
                            ))}
                        </Row>
                        {/* </Group> */}
                      </Column>
                    )}
                  </Collapsible>
                </Column>
              )}

              {!data.preferredTagId && (
                <>
                  {(!isEditMode || mergedTags.length) > 0 && (
                    <Column>
                      <Collapsible open label="Merged Tags">
                        <Column paddingAll>
                          {!isEditMode && (
                            <Column marginBottom="10">
                              <Label>
                                Search the list of tags to merge selected tags
                                into the preferred tag shown above. Only the
                                preferred tag will be shown in reports and will
                                be applied to free text even if the merged tag
                                is originally detected.
                              </Label>
                            </Column>
                          )}
                          <Row marginTop={isEditMode ? "0" : "20"} fit>
                            <AutoCompleteWithResponses
                              editMode={isEditMode}
                              showOnEmptyTerm
                              searchResults={mergableTags}
                              placeholder="Add Tags"
                              setSearchTerm={(e) => handleSearchMergableTag(e)}
                              searchTerm={searchMergableTag}
                              selectedItems={mergedTags}
                              addItem={addMergedTags}
                              removeItem={removeMergedTags}
                              clearSearchResults={() => {}}
                              searchLoading={false}
                              infiniteScroll={{
                                ...tagsInfinteScroll,
                                onLoadMore: () => handleMoreTags(),
                              }}
                            />
                          </Row>
                        </Column>
                      </Collapsible>
                    </Column>
                  )}
                </>
              )}
            </Column>
          )}
        </Column>

        <Modal
          visible={deleteModal}
          toggleVisibility={toggleDeleteModal}
          title="Delete Disease"
          close
        >
          <Column marginBottom gap="20" center>
            <Label>
              <Label large bold>
                {data?.primaryName}
              </Label>
              <Label large> will be deleted</Label>
            </Label>
            <Label large>
              Are you sure you want to continue? This action cannot be undone.
            </Label>
          </Column>
          <ModalFooter right>
            <Button secondary label="Delete" onClick={() => handleDelete()} />
          </ModalFooter>
        </Modal>
      </Column>
    </Content>
  </MainWrapper>
);
