import React from "react";

import {
  MainWrapper,
  Header,
  Content,
} from "../../../components";

export const view = ({
  nId,
}) => {
  return (
    <MainWrapper>
      <Header logo login user></Header>
      {nId && (
        <Content noScroll paddingAll="0">
          <iframe
            style={{
              border: "0",
              height: "1000px",
              width: "100%",
              borderRadius: "10px",
              background: "transparent",
            }}
            src={`${window.location.origin}/notauth/mail/${nId}`}
          />
        </Content>
      )}
    </MainWrapper>
  );
};
