import { useQuery } from "react-query";
import axios from "axios";
import { getApiPath } from "../../utils/variables";
import { authHeader } from "../store";

export function getRegions(auth) {
  if (auth?.active) {
    return axios.get(`${getApiPath()}/regions`, { headers: authHeader() });
  } else {
    return new Promise((resolve) => resolve({ data: [] }));
  }
}
export function useGetRegions(auth) {
  return useQuery(["regions"], () => getRegions(auth), {
    cacheTime: 0,
  });
}
