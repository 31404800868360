import { createAction, createActionTypes } from "../../utils";

export const SETTINGS_ACTIONS = createActionTypes("SETTINGS_ACTIONS", ["SET"]);

export const settingsActions = {
  set: (data) =>
    createAction(SETTINGS_ACTIONS.SET, {
      data,
    }),
};
