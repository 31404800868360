import { put, call, takeLatest, all } from "redux-saga/effects";
import axios from "axios";

import { getApiPath } from "../../utils/variables";
import { authHeader } from "../store";
import { objectToUrlEncoded } from "../../utils/constants";

import { AI_ACTIONS, aiActions } from "../actions";

export let cancelAiRequests = new AbortController();

export const getGPTParameters = ({
  name, objectId, objectType, category1, category2, category3,
}) => {
  const params = objectToUrlEncoded({
    name, objectId, objectType, category1, category2, category3,
  });

  return axios.get(`${getApiPath()}/gptParameters?${params}`, {
    headers: authHeader(),
  });
};

function* handleRequestOne(action) {
  const { name, objectId, objectType, category1 } = action.payload;
  const params = objectToUrlEncoded({
    name, // prompt, role, etc.
    objectId,
    objectType,
    category1, // model, i.e., gpt-4, etc.
  });

  try {
    cancelAiRequests = new AbortController();
    const { data } = yield call(
      axios.get,
      `${getApiPath()}/gptParameter?${params}`,
      {
        headers: authHeader(),
        signal: cancelAiRequests.signal,
      }
    );
    yield put(aiActions.oneSuccess({ data }));
  } catch (e) {
    yield put(aiActions.oneFailure({ error: { ...e } }));
  }
}

function* handleRequestProduct(action) {
  const { name, objectId, category1 } = action.payload;
  const params = objectToUrlEncoded({
    name, // prompt, role, etc.
    objectId,
    category1, // model, i.e., gpt-4, etc.
  });

  try {
    cancelAiRequests = new AbortController();
    const { data } = yield call(
      axios.get,
      `${getApiPath()}/gptParameter?${params}&objectType=PRODUCT`,
      {
        headers: authHeader(),
        signal: cancelAiRequests.signal,
      }
    );
    yield put(aiActions.productSuccess({ data }));
  } catch (e) {
    yield put(aiActions.productFailure({ error: { ...e } }));
  }
}

function* handleRequestDisease(action) {
  const { name, objectId, category1 } = action.payload;
  const params = objectToUrlEncoded({ 
    name, // prompt, role, etc.
    objectId,
    category1, // model, i.e., gpt-4, etc.
  });
  try {
    cancelAiRequests = new AbortController();
    const { data } = yield call(
      axios.get,
      `${getApiPath()}/gptParameter?${params}&objectType=DISEASE`,
      {
        headers: authHeader(),
        signal: cancelAiRequests.signal,
      }
    );
    yield put(aiActions.diseaseSuccess({ data }));
  } catch (e) {
    yield put(aiActions.diseaseFailure({ error: { ...e } }));
  }
}

function* handleUpdate(action) {
  const { name, objectId, objectType, value, category1, category2, category3 } =
    action.payload;
  const body = {
    name,
    objectId,
    objectType,
    value,
    category1, // model, i.e., gpt-4, etc.
    category2,
    category3, // user type
    version: 1,
  };
  try {
    cancelAiRequests = new AbortController();
    const { data } = yield call(
      axios.post,
      `${getApiPath()}/gptParameter`,
      body,
      {
        headers: authHeader(),
        signal: cancelAiRequests.signal,
      }
    );
    yield put(aiActions.updateSuccess({ data }));
  } catch (e) {
    yield put(aiActions.updateFailure({ error: { ...e } }));
  }
}

function* watchAISagas() {
  yield all([
    takeLatest(AI_ACTIONS.ONE_REQUEST, handleRequestOne),
    takeLatest(AI_ACTIONS.PRODUCT_REQUEST, handleRequestProduct),
    takeLatest(AI_ACTIONS.DISEASE_REQUEST, handleRequestDisease),
    takeLatest(AI_ACTIONS.UPDATE, handleUpdate),
  ]);
}

export default watchAISagas;
