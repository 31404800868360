export const objectToUrlEncoded = (body = {}) =>
Object.entries(body)
  .map(([key, value]) => {
    if (value === null || value === undefined) return "";
    const encodedKey = encodeURIComponent(key);
    const encodedValue = encodeURIComponent(value);
    return `${encodedKey}=${encodedValue}`;
  })
  .filter((elem) => elem !== "")
  .join("&");


// enumeration constants
export const Tag_Tag_Relationship = {
  GENERICNAME: "GENERICNAME",
  COMPETITOR: "COMPETITOR",
  MEDICATION: "MEDICATION",
  TOPIC: "TOPIC",
};

export const TAccount_Tag_Relationship = {
  OWNER: "OWNER",
  SPECIALTY: "SPECIALTY",
  ROLE: "ROLE",
};
