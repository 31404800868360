import styled from "styled-components";
import { P } from "./Typography";
import { Color } from "../utils/variables";

const colorsList = ["pink", "green", "purple", "red", "blue"];

export const Keyword = styled(P)`
  mark {
    font-family: arial;
    background: transparent;
    font-weight: bold;

    &[class$="0"],
    &[class$="5"] {
      color: ${Color(colorsList[0], "6")};
    }
    &[class$="1"],
    &[class$="6"] {
      color: ${Color(colorsList[1], "6")};
    }
    &[class$="2"],
    &[class$="7"] {
      color: ${Color(colorsList[2], "6")};
    }
    &[class$="3"],
    &[class$="8"] {
      color: ${Color(colorsList[3], "6")};
    }
    &[class$="4"],
    &[class$="9"] {
      color: ${Color(colorsList[4], "6")};
    }
  }
`;
