import { SCROLL_ACTIONS } from "../actions";

const initialState = {};

export default function scrollReducer(state = initialState, action) {
  switch (action.type) {
    case SCROLL_ACTIONS.SET:
      return { ...state, ...action.payload.data };

    default:
      return state;
  }
}
