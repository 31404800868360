import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useInfiniteScroll from "react-infinite-scroll-hook";

import { useHistory } from "react-router-dom";
import { uniqBy } from "lodash";
import moment from "moment";
import useDebounce from "../../../utils/useDebounce";

import {
  tweetActions,
  tagActions,
  accountActions,
  settingsActions,
} from "../../../store/actions";
import { cancelTweetRequests, cancelTagRequests } from "../../../store/sagas";
import { view } from "./TweetsView";
import { MAX_RESULTS } from "../../../utils/variables";
import { getISOFormattedDateTimeString } from "../../../utils";
import { getTimeSegmentedSummary } from "../../../store/sagas/tweetSagas";
import { useGetRegions } from "../../../store/queries/regions";

export const AdminTweets = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const periods = [
    { id: 1, label: "Last week" },
    { id: 7, label: "Last 2 weeks" },
    { id: 8, label: "Last 3 weeks" },
    { id: 2, label: "Last month" },
    { id: 9, label: "Last 2 months" },
    { id: 3, label: "Last 3 months" },
    { id: 4, label: "Last 6 months" },
    { id: 5, label: "Last 12 months" },
    { id: 6, label: "All time" },
  ];
  const { data: initialRegions } = useGetRegions();

  // --> mapStateToProp
  const tags = useSelector((state) => state.tag.all);
  const accounts = useSelector((state) => state.account.all);
  const tweets = useSelector((state) => state.tweet.all);
  const tweetsCount = useSelector((state) => state.tweet.tweetsCount.data);
  const tweetsSettings = useSelector((state) => state.settings?.tweets);
  // <-- mapStateToProp

  // --> STATE
  const [timePeriod, setTimePeriod] = useState(tweetsSettings?.period || 1);
  const [dates, setDates] = useState({ start: null, end: null });

  const [searchAuthor, setSearchAuthor] = useState(
    tweetsSettings?.author?.[0]?.label
  );
  const debounceAuthor = useDebounce(searchAuthor, 700);
  const [selectedAuthor, setSelectedAuthor] = useState(tweetsSettings?.author);

  const [searchTag, setSearchTag] = useState(tweetsSettings.tag?.[0]?.label);
  const debounceTag = useDebounce(searchTag, 700);
  const [selectedTag, setSelectedTag] = useState(tweetsSettings.tag);
  const [tweetTypes] = useState([
    { id: "1", label: "Original Tweets" },
    { id: "2", label: "Include Replies & Quotes" },
    { id: "3", label: "Include All" },
  ]);
  const [selectedTweetType, setSelectedTweetType] = useState(
    tweetsSettings?.type || "1"
  );
  const [sentiments] = useState([
    { id: "2", label: "Include All" },
    { id: "3", label: "Only With Sentiments" },
    { id: "1", label: "Positive" },
    { id: "0", label: "Neutral" },
    { id: "-1", label: "Negative" },
  ]);
  const [selectedSentiment, setSelectedSentiment] = useState(
    tweetsSettings?.sentiment || "2"
  );
  const [sources] = useState([
    { id: "-1", label: "All" },
    { id: "X", label: "X" },
    { id: "YT", label: "YouTube" },
    { id: "PC", label: "Podcast" },
  ]);
  const [selectedSource, setSelectedSource] = useState(
    tweetsSettings?.source || "X"
  );
  const [searchTerm, setSearchTerm] = useState(
    tweetsSettings?.searchTerm || null
  );
  const debounceTerm = useDebounce(searchTerm, 700);
  const [selectedRegion, setSelectedRegion] = useState("ALL");

  const [regions, setRegions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageTweets, setPageTweets] = useState(0);
  const [pageTags, setPageTags] = useState(0);
  const [pageAccounts, setPageAccounts] = useState(0);
  const [chartLoading, setChartLoading] = useState(false);
  const [timeSegmentedSummary, setTimeSegmentedSummary] = useState([]);
  const [withTagsOnly, setWithTagsOnly] = useState(false);
  // <-- STATE

  const requestTags = (pageNum) => {
    dispatch(
      tagActions.request({
        meaning: null,
        ignore: false,
        // preferred: false,
        includeDiseases: true,
        includeProducts: true,
        includeConferences: true,
        maxResult: MAX_RESULTS,
        ...(searchTag !== "" && { term: searchTag }),
        pageNum,
      })
    );
  };

  const requestAccounts = (pageNum) => {
    dispatch(
      accountActions.request({
        forIngestion: "true",
        maxResult: MAX_RESULTS,
        ...(searchAuthor !== "" && { term: searchAuthor }),
        pageNum,
      })
    ); // HCPs & Institutions
  };

  const requestTweets = (pageNum, start, end) => {
    const selectedTagId = selectedTag?.[0]?.id;
    const selectedAuthorId = selectedAuthor?.[0]?.id;
    const withSentiment = selectedSentiment;
    dispatch(
      tweetActions.request({
        ...(!!selectedAuthorId && { externalId: selectedAuthorId }),
        ...(!!selectedTagId && { tagIds: [selectedTagId] }),
        ...(selectedTweetType === "2" && {
          originalOnly: false,
          includeReplies: true,
        }), // original and replies
        ...(selectedTweetType === "3" && { originalOnly: false }), // all tweets
        ...(withSentiment === "3" && { withSentiment: true }),
        ...(withSentiment === "1" && { withSentiment: true, sentiment: 1 }),
        ...(withSentiment === "0" && { withSentiment: true, sentiment: 0 }),
        ...(withSentiment === "-1" && { withSentiment: true, sentiment: -1 }),
        ...(selectedSource !== "-1" && { source: selectedSource }),
        term: searchTerm,
        pageNum,
        startDate: start,
        endDate: end,
        sortBy: "date",
        maxResult: MAX_RESULTS,
        ...(selectedRegion !== "ALL" && { regionName: [selectedRegion] }),
        withTags: withTagsOnly,
      })
    );

    // get count only for page 1
    if (pageNum === 0) {
      dispatch(
        tweetActions.tweetsCountRequest({
          ...(!!selectedAuthorId && { externalId: selectedAuthorId }),
          ...(!!selectedTagId && { tagIds: [selectedTagId] }),
          ...(selectedTweetType === "2" && {
            originalOnly: false,
            includeReplies: true,
          }), // original and replies
          ...(selectedTweetType === "3" && { originalOnly: false }), // all tweets
          ...(withSentiment === "3" && { withSentiment: true }),
          ...(withSentiment === "1" && { withSentiment: true, sentiment: 1 }),
          ...(withSentiment === "0" && { withSentiment: true, sentiment: 0 }),
          ...(withSentiment === "-1" && { withSentiment: true, sentiment: -1 }),
          ...(selectedSource !== "-1" && { source: selectedSource }),
          term: searchTerm,
          startDate: start,
          endDate: end,
          ...(selectedRegion !== "ALL" && { regionName: [selectedRegion] }),
          withTags: withTagsOnly,
        })
      );
    }
  };

  const handleMoreTweets = () => {
    if (!loading) {
      const newPage = pageTweets + 1;
      requestTweets(newPage, dates.start, dates.end);
      setPageTweets(newPage);
    }
  };

  const handleMoreTags = () => {
    if (!tags.fetching) {
      const tagsNewPage = pageTags + 1;
      setPageTags(tagsNewPage);
      requestTags(tagsNewPage);
    }
  };

  const handleMoreAccounts = () => {
    if (!accounts.fetching) {
      const accountsNewPage = pageAccounts + 1;
      setPageAccounts(accountsNewPage);
      requestAccounts(accountsNewPage);
    }
  };

  const [tweetsRef] = useInfiniteScroll({
    loading,
    hasNextPage: !tweets.isLastPage,
    onLoadMore: handleMoreTweets,
  });

  useEffect(() => {
    setLoading(tweets.fetching);
  }, [tweets.fetching]);

  const handleTimePeriodChange = (item) => {
    setTimePeriod(item.id);
    dispatch(
      settingsActions.set({
        tweets: { ...tweetsSettings, period: item.id },
      })
    );
  };

  const getAllAuthors = () => {
    let tempAll = [];
    if (accounts.data.length > 0) {
      accounts.data.forEach((a) => {
        tempAll.push({
          id: a.externalId,
          label: a.name,
        });
      });
    }
    return uniqBy(tempAll, "id");
  };

  const clearAuthor = () => {
    setSearchAuthor("");
    addSelectedAuthor(null);
  };

  const handleSearchAuthor = (e) => {
    setSearchAuthor(e);
  };

  const addSelectedAuthor = (e) => {
    setSelectedAuthor([e]);
    dispatch(
      settingsActions.set({
        tweets: { ...tweetsSettings, author: [e] },
      })
    );
  };

  const getAllTags = () => {
    let tempAll = [];
    if (tags.data.length > 0) {
      tags.data.forEach((a) => {
        tempAll.push({
          id: a.id,
          label: a.name,
        });
      });
    }
    return tempAll;
  };

  const clearTag = () => {
    setSearchTag("");
    addSelectedTag(null);
  };

  const handleTweetTypeChange = (item) => {
    setSelectedTweetType(item.id);
    dispatch(
      settingsActions.set({
        tweets: { ...tweetsSettings, type: item.id },
      })
    );
  };

  const handleSentimentChange = (item) => {
    setSelectedSentiment(item.id);
    dispatch(
      settingsActions.set({
        tweets: { ...tweetsSettings, sentiment: item.id },
      })
    );
  };

  const handleSourceChange = (item) => {
    setSelectedSource(item.id);
    dispatch(
      settingsActions.set({
        tweets: { ...tweetsSettings, source: item.id },
      })
    );
  };

  const handleRegionChange = (item) => {
    setSelectedRegion(item.id);
    dispatch(
      settingsActions.set({
        tweets: { ...tweetsSettings, region: item.id },
      })
    );
  };

  // --> EFFECT
  useEffect(() => {
    // requestAccounts(); // HCPs & Institutions

    // cleanup
    return () => {
      tagActions.clearTags({});
      cancelTweetRequests.abort();
      cancelTagRequests.abort();
    };
  }, [dispatch]);

  useEffect(() => {
    if (initialRegions?.data?.length > 0) {
      const rgns = [
        {
          id: "ALL",
          name: "All Regions",
          label: "All Regions",
        },
      ];
      initialRegions.data.forEach((row) => {
        rgns.push({
          id: row.id,
          name: row.name,
          label: row.name,
        });
      });
      setRegions(rgns);
    }
  }, [initialRegions?.data]);

  useEffect(() => {
    const selectedTagId = selectedTag?.[0]?.id;
    const selectedAuthorId = selectedAuthor?.[0]?.id;
    const currentDate = new Date();
    let startDate = currentDate;
    let endDate = new Date();
    if (timePeriod === 1) {
      startDate = new Date(currentDate.setDate(currentDate.getDate() - 6));
    } else if (timePeriod === 2) {
      currentDate.setMonth(currentDate.getMonth() - 1);
      startDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
    } else if (timePeriod === 3) {
      currentDate.setMonth(currentDate.getMonth() - 3);
      startDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
    } else if (timePeriod === 4) {
      currentDate.setMonth(currentDate.getMonth() - 6);
      startDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
    } else if (timePeriod === 5) {
      currentDate.setMonth(currentDate.getMonth() - 12);
      startDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
    } else if (timePeriod === 6) {
      startDate = null;
      endDate = null;
    } else if (timePeriod === 7) {
      startDate = new Date(currentDate.setDate(currentDate.getDate() - 13));
    } else if (timePeriod === 8) {
      startDate = new Date(currentDate.setDate(currentDate.getDate() - 20));
    } else if (timePeriod === 9) {
      currentDate.setMonth(currentDate.getMonth() - 2);
      startDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
    }
    const start = startDate
      ? getISOFormattedDateTimeString(moment(startDate).startOf("day"))
      : null;
    const end = endDate
      ? getISOFormattedDateTimeString(moment(endDate).endOf("day"))
      : null;
    setDates({
      start: start,
      end: end,
    });
    requestTweets(0, start, end);
    setPageTweets(0);

    // fetch time segmented summary
    const dayDiff = moment(end).diff(moment(start), "days");
    (async () => {
      setChartLoading(true);
      setTimeSegmentedSummary([]);
      const temp = await getTimeSegmentedSummary({
        ...(!!selectedTagId && { tagIds: [selectedTagId] }),
        authorId: selectedAuthorId,
        term: searchTerm,
        ...(selectedTweetType === "2" && {
          originalOnly: false,
          includeReplies: true,
        }),
        ...(selectedTweetType === "3" && { originalOnly: false }), // all tweets
        ...(selectedSentiment === "3" && { withSentiment: true }),
        ...(selectedSentiment === "1" && { withSentiment: true, sentiment: 1 }),
        ...(selectedSentiment === "0" && { withSentiment: true, sentiment: 0 }),
        ...(selectedSentiment === "-1" && {
          withSentiment: true,
          sentiment: -1,
        }),
        ...(selectedSource !== "-1" && { source: selectedSource }),
        startDate: start,
        endDate: end,
        segment: dayDiff > 31 ? "WEEK" : "DAY",
        ...(selectedRegion !== "ALL" && { regionName: selectedRegion }),
        withTags: withTagsOnly,
      });
      // console.log(temp.data);
      // transform
      if (temp.data.length > 0) {
        setTimeSegmentedSummary(
          temp.data.map((row) => ({
            // Total: row.count,
            X: row.x,
            YouTube: row.yt,
            Podcast: row.pc,
            time: row.timesegment,
          }))
        );
      }
      setChartLoading(false);
    })();
  }, [
    selectedAuthor,
    selectedTag,
    selectedTweetType,
    selectedSentiment,
    selectedSource,
    timePeriod,
    debounceTerm,
    selectedRegion,
    withTagsOnly,
  ]);

  useEffect(() => {
    setPageTags(0);
    requestTags(0);
  }, [debounceTag]);

  useEffect(() => {
    setPageAccounts(0);
    requestAccounts(0); // HCPs & Institutions
  }, [debounceAuthor]);
  // <-- EFFECT

  const handleSearchTag = (e) => {
    setSearchTag(e);
  };

  const handleSearchTermChange = (e) => {
    // console.log(e);
    if (!_.isEmpty(e) /* && _.trim(e).length >= 4 */) {
      setSearchTerm(_.trim(e));
    } else {
      setSearchTerm(null);
    }
    dispatch(
      settingsActions.set({
        tweets: { ...tweetsSettings, searchTerm: _.trim(e) },
      })
    );
  };

  const addSelectedTag = (e) => {
    setSelectedTag([e]);
    dispatch(
      settingsActions.set({
        tweets: { ...tweetsSettings, tag: [e] },
      })
    );
  };

  const getActiveFilters = () => {
    let activeFilters = [];
    if (searchTerm) {
      activeFilters.push(`Search: ${searchTerm}`);
    }
    if (selectedTag?.[0]?.label) {
      activeFilters.push(`Tag: ${selectedTag[0].label}`);
    }
    if (selectedTweetType !== "3") {
      activeFilters.push(
        `Type: ${tweetTypes.find((a) => selectedTweetType === a.id)?.label}`
      );
    }
    if (selectedAuthor?.[0]?.label) {
      activeFilters.push(`Author: ${selectedAuthor[0].label}`);
    }
    if (timePeriod !== 6) {
      activeFilters.push(
        `Period: ${periods.find((a) => timePeriod === a.id)?.label}`
      );
    }
    if (selectedSentiment !== "2") {
      activeFilters.push(
        `Sentiment: ${
          sentiments.find((a) => selectedSentiment === a.id)?.label
        }`
      );
    }
    if (selectedSource !== "-1") {
      activeFilters.push(
        `Source: ${sources.find((a) => selectedSource === a.id)?.label}`
      );
    }
    if (selectedRegion !== "ALL") {
      activeFilters.push(
        `Region: ${regions.find((a) => selectedRegion === a.id)?.label}`
      );
    }

    return activeFilters;
  };

  return view({
    history,
    loading,
    tweetTypes,
    selectedTweetType,
    handleTweetTypeChange,
    withTagsOnly,
    setWithTagsOnly,
    activeFilters: getActiveFilters(),

    sentiments,
    selectedSentiment,
    handleSentimentChange,

    sources,
    selectedSource,
    handleSourceChange,

    regions,
    selectedRegion,
    handleRegionChange,

    allAuthors: getAllAuthors(),
    searchAuthor,
    selectedAuthor,
    handleSearchAuthor,
    addSelectedAuthor,
    clearAuthor,
    authorsInfinteScroll: {
      loading: accounts.fetching,
      hasNextPage: !accounts.isLastPage,
      onLoadMore: handleMoreAccounts,
    },

    timePeriod,
    periods,
    handleTimePeriodChange,
    tweetsRef,
    tweetsNextPage: !tweets.isLastPage,
    tweets: tweets.data || [],
    tweetsCount: tweetsCount || 0,
    dates,

    tags: getAllTags(),
    searchTag,
    selectedTag,
    handleSearchTag,
    addSelectedTag,
    clearTag,
    tagsInfinteScroll: {
      loading: tags.fetching,
      hasNextPage: !tags.isLastPage,
      onLoadMore: handleMoreTags,
    },

    searchTerm,
    handleSearchTermChange,

    chartLoading,
    timeSegmentedSummary,
  });
};
