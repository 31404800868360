/* eslint-disable no-underscore-dangle */
import React from "react";

import { Row, Column } from "./Layout";
import { Label } from "./Typography";
import { Anchor } from "./Button";

export const NoContent = ({ onDateClick, onClearFilters, label = "posts" }) => (
  <Column paddingY gap="10">
    <Label>{`Sorry, we couldn't find any matching ${label}.`}</Label>

    {(onDateClick || onClearFilters) && (
      <Label>
        <Label bold>{"Tip: "}</Label>
        {onDateClick && (
          <>
            <Anchor
              inline
              line
              bold
              label="change your date range"
              onClick={() => onDateClick()}
            />
          </>
        )}
        {onDateClick && onClearFilters && <Label>{" or "}</Label>}
        {onClearFilters && (
          <Anchor
            inline
            line
            bold
            onClick={() => onClearFilters()}
            label="clear filters"
          />
        )}
      </Label>
    )}
  </Column>
);
