import { NAV_ACTIONS } from "../actions";

const initialState = {
  showDrawer: false,
};

export default function navReducer(state = initialState, action) {
  switch (action.type) {
    case NAV_ACTIONS.SET:
      return { ...state, ...action.payload.data };

    default:
      return state;
  }
}
