import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useInfiniteScroll from "react-infinite-scroll-hook";

import { view } from "./SearchView";
import { MAX_RESULTS } from "../../utils/variables";
import { getUrlParams, logAnalyticEvent, capitalize } from "../../utils";
import useDebounce from "../../utils/useDebounce";

import { useGetRegions } from "../../store/queries/regions";
import { settingsActions } from "../../store/actions";
import { useGetSearch, useGetRelatedSearch } from "../../store/queries/search";

export const Search = () => {
  const dispatch = useDispatch();
  const { data: initialRegions } = useGetRegions();

  const auth = useSelector((state) => state.auth.data);
  const userPlans = useSelector((state) => state.plan.userPlans);
  const settings = useSelector((state) => state.settings?.search);

  const [queryParams, setQueryParams] = useState(getUrlParams(location.search));
  const [searchPage, setSearchPage] = useState(0);
  const [searchResults, setSearchResults] = useState({});
  const [searchValue, setSearchValue] = useState(queryParams?.pattern || "");
  const debounceSearch = useDebounce(searchValue, 700);
  const [source] = useState(queryParams?.source || "");
  const [hasNextPage, setHasNextPage] = useState(true);
  const [filterVisibility, toggleFilterVisibility] = useState(false);

  const [regions, setRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(settings?.regions || []);
  const [regionName, setRegionName] = useState(null);
  const [selectedAccountType, setSelectedAccountType] = useState(
    settings?.type || "ALL"
  );
  const paidAccount =
    userPlans?.data?.[0]?.plan?.name?.includes("Basic") ||
    userPlans?.data?.[0]?.plan?.name?.includes("Pro") ||
    userPlans?.data?.[0]?.plan?.name?.includes("Enterprise");

  const { data, refetch, isLoading } = useGetSearch({
    params: {
      pattern: searchValue,
      page: searchPage,
      size: MAX_RESULTS,
      ...(selectedAccountType !== "ALL" && { type: selectedAccountType }),
      ...(selectedRegion.length > 0 && {
        region: selectedRegion.map((item) => item.id).join(","),
      }),
      ...(auth?.id && { userId: auth.id }),
      ...(auth?.mwRole && { userType: auth.mwRole }),
    },
  });
  const { data: relatedData } = auth.active ? useGetRelatedSearch({}) : {};

  let recentlyViewed = useSelector(
    (state) => state.settings.account?.recentlyViewed || []
  );
  recentlyViewed = Object.values(recentlyViewed);
  if (recentlyViewed) {
    recentlyViewed.sort((a, b) => b.timestamp - a.timestamp);
  }

  const accountTypes = [
    { id: "ALL", label: "All" },
    { id: "PERSON", label: "Person" },
    { id: "INSTITUTION", label: "Institution" },
  ];

  const cookies = useSelector((state) => state.settings.cookies);

  const handleSignUpClick = () => {
    // check for cookies first before publishing event
    if (!auth?.active && cookies?.seen && !cookies?.accepted) {
      return;
    }
    // call common helper function
    logAnalyticEvent(
      "Sign Up",
      {
        page: "Search",
        ...(queryParams?.source && { source: queryParams.source }),
      },
      queryParams?.u || null,
      queryParams?.n || null,
      queryParams?.e || null,
      null,
      queryParams?.t || null
    );
  };

  useEffect(() => {
    if (paidAccount && initialRegions?.data?.length > 0) {
      const rgns = [
        {
          id: "ALL",
          name: "All Regions",
          label: "All Regions",
        },
      ];
      initialRegions.data.forEach((row) => {
        rgns.push({
          id: row.id,
          name: row.name,
          label: row.name,
        });
      });
      setRegions(rgns);
    }
  }, [initialRegions?.data]);

  useEffect(() => {
    if (regions?.length > 0) {
      if (selectedRegion?.length === regions.length) {
        setRegionName([]);
      } else {
        setRegionName(selectedRegion?.map((item) => item.id) || []);
      }
    }

    if (!auth.active) {
      setRegionName([]);
    }
  }, [selectedRegion, regions]);

  const handleRegionChange = (items) => {
    setSelectedRegion(items);
    dispatch(
      settingsActions.set({
        search: {
          ...settings,
          regions: items,
        },
      })
    );
  };

  const getActiveFilters = () => {
    let activeFilters = [];
    if (regionName?.length > 0) {
      activeFilters = regions
        .filter((r) => regionName.includes(r.id))
        .map((a) => a.label);
    }
    if (selectedAccountType !== "ALL") {
      activeFilters.push(`Account Type: ${capitalize(selectedAccountType)}`);
    }
    return activeFilters;
  };

  const handleClearFilters = () => {
    setSelectedAccountType("ALL");
    setSelectedRegion([]);
    setRegionName([]);
    dispatch(
      settingsActions.set({
        search: {
          type: "ALL",
          regions: [],
        },
      })
    );
  };

  useEffect(() => {
    if (searchValue) {
      refetch?.();

      if (searchPage === 0 && searchValue !== "") {
        // amplitude event
        logAnalyticEvent(
          "Search",
          {
            page: "Search",
            term: searchValue,
            ...(selectedAccountType !== "ALL" && {
              accountType: selectedAccountType,
            }),
            ...(selectedRegion.length > 0 && {
              region: selectedRegion.map((item) => item.id).join(","),
            }),
            source: source,
          },
          auth?.id || null,
          auth?.displayName || null,
          auth?.email || null,
          auth?.createdAt || null,
          auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
            ?.preferenceStringValue || "MEDICAL",
          null
        );
      }
    } else {
      setHasNextPage(false);
      setSearchResults({
        items: [],
      });
    }
  }, [searchPage, debounceSearch, selectedAccountType, selectedRegion]);

  useEffect(() => {
    if (data?.data) {
      setHasNextPage(data.data.nextPage);
      setSearchResults((oldResults) => ({
        ...data.data,
        items:
          oldResults?.items && searchPage > 0
            ? [...oldResults.items, ...data.data.items]
            : data.data.items,
      }));
    }
  }, [data]);

  const handleMoreItems = () => {
    if (!isLoading) {
      setSearchPage(searchResults?.nextPage);
    }
  };

  const [loadingRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage,
    onLoadMore: handleMoreItems,
  });

  const updateSearch = (e) => {
    setSearchValue(e.target.value);
    setSearchPage(0);
  };

  const handleChangeAccountType = (type) => {
    setSelectedAccountType(type);
    setSearchPage(0);
    dispatch(
      settingsActions.set({
        search: {
          ...settings,
          type,
        },
      })
    );
  };

  const filteredRelatedData = relatedData?.data?.filter((item) => {
    const tempData = searchValue === "" ? recentlyViewed : searchResults?.items;
    return !tempData?.find((d) => d.id === item.id);
  });
  if (filteredRelatedData?.length > 5) {
    filteredRelatedData.length = 5;
  }

  return view({
    auth,
    handleSignUpClick,
    data: searchValue === "" ? recentlyViewed : searchResults?.items || [],
    counter: searchValue === "" ? 0 : searchResults?.hits || null,
    isLoading: searchValue === "" ? false : isLoading,
    hasNextPage: searchValue === "" ? false : hasNextPage,
    loadingRef: searchValue === "" ? null : loadingRef,
    searchValue,
    updateSearch,
    relatedData: filteredRelatedData || [],

    showFilter: paidAccount,
    filterVisibility,
    toggleFilterVisibility,
    regions,
    selectedRegion,
    handleRegionChange,
    accountTypes,
    selectedAccountType,
    handleChangeAccountType,
    activeFilters: getActiveFilters(),
    handleClearFilters,
    hideFilters: searchValue === "",
  });
};
