import { useState, useEffect } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";

import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import {
  conferenceActions,
  // tagActions,
  navActions,
  settingsActions,
} from "../../store/actions";
import {
  getISOFormattedDateTimeString,
  logAnalyticEvent,
  formatNumberWithCommas,
} from "../../utils";
import { view } from "./ConferenceView";

export const Conference = ({ props }) => {
  const {
    homeSettings,
    auth,
    handleSignUpClick,
    noFetching,
    queryParams,
    contentRef,
    monitors,
    selectedMonitor,
    handleDateChange,
    chartType,
    handleChartTypeChange,
    handleChartClick,
    timeSegmentedData,
    setShareUrl,
    tags,
    searchTag,
    selectedTags,
    handleSearchTag,
    addSelectedTag,
    removeSelectedTag,
    clearTag,
    tagsInfinteScroll,
    clearFilters,
    allKeywords,
    mainKeywords,
    sortBy,
    handleSortByChange,
    filterTip,
    closeFilterTip,
    urlTab,
    activeTab,
    handleActiveTab,
    showToast,
    toggleToast,
  } = props;

  const tabs = [{ id: "tweets", label: "Posts" }];

  const dispatch = useDispatch();

  const conference = useSelector((state) => state.conference.one);
  const conferenceTweets = useSelector((state) => state.conference.tweets);
  const loading = conferenceTweets.fetching;
  const isReady = conferenceTweets.success;
  const conferenceTweetsCount = useSelector(
    (state) => state.conference.tweetsCount.data
  );
  const tweetsReady = useSelector(
    (state) => state.conference.tweetsCount.success
  );

  const initialConferences = useSelector((state) => state.conference.all.data);
  // const initialTagsAssociated = useSelector(
  //   (state) => state.tag.tagsAssociated.data
  // );
  const userPlans = useSelector((state) => state.plan.userPlans);

  const [conferences, setConferences] = useState([]);
  const [selectedConference, setSelectedConference] = useState("");
  const [conferenceDates, setConferenceDates] = useState({
    start: null,
    end: null,
  });
  const [pageConferenceTweets, setPageConferenceTweets] = useState(0);
  // const [associatedDiseases, setAssociatedDiseases] = useState([]);
  // const [selectedAssociatedDisease, setSelectedAssociatedDisease] =
  //   useState(null);
  const [tweetsCounter, setTweetsCounter] = useState(0);

  const cookies = useSelector((state) => state.settings.cookies);

  useEffect(() => {
    handleActiveTab(
      urlTab || homeSettings.activeTabs?.[selectedMonitor.id] || "tweets"
    );
  }, []);

  useEffect(() => {
    if (selectedMonitor.id) {
      dispatch(conferenceActions.oneRequest(selectedMonitor.id));

      if (!auth.id && queryParams?.startDate && queryParams.endDate) {
        setConferenceDates({
          start: getISOFormattedDateTimeString(queryParams.startDate),
          end: getISOFormattedDateTimeString(queryParams.endDate),
        });
      }
    }
  }, [selectedMonitor?.id]);

  // once all associated conferences are found, get data for first
  useEffect(() => {
    if (initialConferences.length > 0) {
      let newConferences = initialConferences.map((item) => ({
        id: item.tag.id,
        label: item.primaryName,
        startDate: item.startDate,
        endDate: item.endDate,
        monitorStartTime: item.monitorStartTime,
        monitorEndTime: item.monitorEndTime,
      }));
      // sort by conference monitor time
      newConferences = _.orderBy(
        newConferences,
        ["monitorStartTime", "monitorEndTime"],
        ["desc", "desc"]
      );
      setConferences(newConferences);
      setSelectedConference(newConferences[0].id);
      setConferenceDates({
        start: getISOFormattedDateTimeString(
          moment(newConferences[0].monitorStartTime).utc()
        ),
        end: getISOFormattedDateTimeString(
          moment(newConferences[0].monitorEndTime).utc()
        ),
      });
    }
  }, [initialConferences]);

  // Update url
  useEffect(() => {
    const domain = window.location.origin;
    const url = `/?conferenceId=${selectedMonitor.id}&startDate=${conferenceDates.start}&endDate=${conferenceDates.end}`;
    window.history.replaceState(null, "Medical.watch", url);
    setShareUrl(domain + url);
    dispatch(
      navActions.set({
        last: domain + url,
      })
    );
  }, [selectedMonitor, conferenceDates]);

  // get all the associated conferences for the selected conference
  useEffect(() => {
    if (conference.data?.id && auth?.id) {
      dispatch(
        conferenceActions.request({
          parentId: conference.data.id,
          ignore: false,
          maxResult: -1,
        })
      );
      // // get associated tags
      // dispatch(
      //   tagActions.tagAssociationsRequest({ id: conference.data.tagId })
      // );
    }
  }, [conference.data]);

  useEffect(() => {
    if (
      !conference.fetching &&
      !_.isEmpty(conference.data) &&
      selectedMonitor?.id === conference.data.id
    ) {
      // console.log(conference.data);

      logAnalyticEvent(
        "Dashboard - " + selectedMonitor.type,
        {
          id: selectedMonitor.id,
          tagId: conference.data.tagId,
          type: selectedMonitor.type,
          ...(selectedMonitor.source && { source: selectedMonitor.source }),
          name: conference.data.primaryName,
          activeTab: activeTab,
          chartType: chartType,
          // "startDate": conferenceDates.start,
          // "endDate": conferenceDates.end
        },
        auth?.id || null,
        auth?.displayName || null,
        auth?.email || null,
        auth?.createdAt || null,
        auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
          ?.preferenceStringValue || "MEDICAL",
        userPlans?.data?.[0].plan.name || null
      );
    }
  }, [conference.data]);

  useEffect(() => {
    if (noFetching.current && conferenceTweets.data.length > 0) {
      return;
    }
    if (selectedConference !== "" /*&& selectedAssociatedDisease  */) {
      setPageConferenceTweets(0);
      requestConferenceTweets(0, conferenceDates.start, conferenceDates.end);
      requestConferenceTweetsCount(
        0,
        conferenceDates.start,
        conferenceDates.end
      );
    }
  }, [
    selectedConference,
    // associatedDiseases,
    selectedTags,
    // selectedAssociatedDisease,
  ]);

  useEffect(() => {
    setTweetsCounter(conferenceTweetsCount);
  }, [conferenceTweetsCount]);

  useEffect(() => {
    if (!auth.id) {
      // we need a value to pass the condition:
      // if (selectedConference !== "" && selectedAssociatedDisease)
      // which triggers all calls when a user is unauth
      setSelectedConference("none");
      // setSelectedAssociatedDisease("ALL");
    }
  }, [auth]);

  // useEffect(() => {
  //   if (initialTagsAssociated?.TOPIC) {
  //     const newTags = initialTagsAssociated.TOPIC.map((tag) => ({
  //       id: tag.toTag.diseaseId,
  //       label: tag.toTag.name,
  //     }));

  //     if (newTags.length > 0) {
  //       setSelectedAssociatedDisease(newTags[0].id);
  //       if (newTags.length > 1) {
  //         newTags.unshift({ id: "ALL", label: "Include all" });
  //         setAssociatedDiseases(newTags);
  //       }
  //     }
  //   }
  // }, [initialTagsAssociated]);

  // const handleAssociatedDiseaseChange = (e) => {
  //   setSelectedAssociatedDisease(e.id);
  // };

  const handleMoreConferenceTweets = () => {
    if (!loading) {
      const newPage = pageConferenceTweets + 1;
      requestConferenceTweets(
        newPage,
        conferenceDates.start,
        conferenceDates.end
      );
      requestConferenceTweetsCount(
        newPage,
        conferenceDates.start,
        conferenceDates.end
      );
      setPageConferenceTweets(newPage);
    }
  };

  const requestConferenceTweets = (pageNum, startDate, endDate, sort) => {
    dispatch(
      conferenceActions.tweetsRequest({
        id: selectedMonitor.id,
        addTagIds: selectedTags?.map((t) => t.id) || [],
        startDate: startDate,
        endDate: endDate,
        sortBy: sort || sortBy,
        pageNum,
        // ...(selectedAssociatedDisease !== "ALL" && {
        //   diseaseId: selectedAssociatedDisease,
        // }),
      })
    );
  };
  const requestConferenceTweetsCount = (pageNum, startDate, endDate) => {
    if (!selectedMonitor.id) {
      return;
    }
    // get count only for page 1
    if (pageNum === 0) {
      dispatch(
        conferenceActions.tweetsCountRequest({
          id: selectedMonitor.id,
          addTagIds: selectedTags?.map((t) => t.id) || [],
          startDate: startDate,
          endDate: endDate,
          // ...(selectedAssociatedDisease !== "ALL" && {
          //   diseaseId: selectedAssociatedDisease,
          // }),
        })
      );
    }
  };

  const [conferenceTweetsRef] = useInfiniteScroll({
    loading,
    hasNextPage: !conferenceTweets.isLastPage,
    onLoadMore: handleMoreConferenceTweets,
  });

  const tabOnChange = (id) => {
    handleActiveTab(id);
    contentRef.current.scrollTo(0, 0);
    dispatch(
      settingsActions.set({
        home: {
          ...homeSettings,
          activeTabs: {
            ...homeSettings.activeTabs,
            [selectedMonitor.id]: id,
          },
        },
      })
    );
    // log event
    const selectedTab = tabs.filter((t) => t.id === id);
    logAnalyticEvent(
      "Congress Monitor - " + (selectedTab?.[0]?.label || ""),
      {
        id: selectedMonitor.id,
        tagId: conference.data.tagId,
        type: selectedMonitor.type,
        ...(selectedMonitor.source && { source: selectedMonitor.source }),
        name: conference.data.primaryName,
        activeTab: id,
        chartType: chartType,
        startDate: conferenceDates.start,
        endDate: conferenceDates.end,
      },
      auth?.id || null,
      auth?.displayName || null,
      auth?.email || null,
      auth?.createdAt || null,
      auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
        ?.preferenceStringValue || "MEDICAL",
      userPlans?.data?.[0]?.plan?.name || null
    );
  };

  const handleConferenceChange = (e) => {
    setSelectedConference(e.id);
    setConferenceDates({
      start: getISOFormattedDateTimeString(moment(e.monitorStartTime).utc()),
      end: getISOFormattedDateTimeString(moment(e.monitorEndTime).utc()),
    });
    // log event
    logAnalyticEvent(
      "Child Congress Change",
      {
        id: selectedMonitor.id,
        tagId: conference.data.tagId,
        type: selectedMonitor.type,
        ...(selectedMonitor.source && { source: selectedMonitor.source }),
        name: conference.data.primaryName,
        child: e.label,
        activeTab: activeTab,
        chartType: chartType,
        startDate: getISOFormattedDateTimeString(
          moment(e.monitorStartTime).utc()
        ),
        endDate: getISOFormattedDateTimeString(moment(e.monitorEndTime).utc()),
      },
      auth?.id || null,
      auth?.displayName || null,
      auth?.email || null,
      auth?.createdAt || null,
      auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
        ?.preferenceStringValue || "MEDICAL",
      userPlans?.data?.[0]?.plan?.name || null
    );
  };

  const handleClearFilters = () => {
    // setSelectedAssociatedDisease("ALL");
    clearFilters();

    // log event
    logAnalyticEvent(
      "Clear Filters",
      {
        id: selectedMonitor.id,
        tagId: conference.data.tagId,
        type: selectedMonitor.type,
        ...(selectedMonitor.source && { source: selectedMonitor.source }),
        name: conference.data.primaryName,
        activeTab: activeTab,
        // chartType: chartType,
        startDate: conferenceDates.start,
        endDate: conferenceDates.end,
      },
      auth?.id || null,
      auth?.displayName || null,
      auth?.email || null,
      auth?.createdAt || null,
      auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
        ?.preferenceStringValue || "MEDICAL",
      userPlans?.data?.[0]?.plan?.name || null
    );
  };

  const handleChartTypeChangeInt = (item) => {
    // call actual event
    handleChartTypeChange(item);
    // log event
    logAnalyticEvent(
      "Chart Change",
      {
        id: selectedMonitor.id,
        tagId: conference.data.tagId,
        type: selectedMonitor.type,
        ...(selectedMonitor.source && { source: selectedMonitor.source }),
        name: conference.data.primaryName,
        activeTab: activeTab,
        chartType: item.id,
        startDate: conferenceDates.start,
        endDate: conferenceDates.end,
      },
      auth?.id || null,
      auth?.displayName || null,
      auth?.email || null,
      auth?.createdAt || null,
      auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
        ?.preferenceStringValue || "MEDICAL",
      userPlans?.data?.[0]?.plan?.name || null
    );
  };

  const sortByOnChange = (item) => {
    handleSortByChange(item);
    setPageConferenceTweets(0);
    requestConferenceTweets(
      0,
      conferenceDates.start,
      conferenceDates.end,
      item.id
    );
    requestConferenceTweetsCount(0, conferenceDates.start, conferenceDates.end);
  };

  const getCounter = () => {
    let counter;
    if (tweetsReady) {
      counter = `${formatNumberWithCommas(tweetsCounter)} posts`;
    }
    return counter;
  };

  const getActiveFilters = () => {
    let activeFilters = [];
    if (selectedTags.length > 0) {
      activeFilters = selectedTags.map((a) => a.label);
    }

    if (conferences.length > 1) {
      activeFilters.push(
        `Name: ${conferences.find((a) => selectedConference === a.id)?.label}`
      );
    }

    // if (associatedDiseases.length > 0 && selectedAssociatedDisease !== "ALL") {
    //   activeFilters.push(
    //     `Disease: ${
    //       associatedDiseases.find((a) => selectedAssociatedDisease === a.id)
    //         ?.label
    //     }`
    //   );
    // }

    return activeFilters;
  };

  return view({
    userId: auth.id,
    handleSignUpClick,
    contentRef,
    monitors,
    selectedMonitor,
    chartType,
    handleChartTypeChange: handleChartTypeChangeInt,
    handleChartClick,
    loading,
    isReady,
    handleDateChange,
    activeFilters: getActiveFilters(),

    conferenceName: conference.data?.primaryName,
    conferences,
    conferenceDates,
    selectedConference,
    setSelectedConference,
    conferenceTweets: conferenceTweets.data,
    conferenceTweetsRef,
    conferenceNextPage: !conferenceTweets.isLastPage,
    handleConferenceChange,
    // associatedDiseases,
    // selectedAssociatedDisease,
    // handleAssociatedDiseaseChange,

    timeSegmentedData,
    counter: getCounter(),
    tweetsReady,
    activeTab,
    tabs,
    tabOnChange,
    tags,
    searchTag,
    selectedTags: selectedTags || [],
    handleSearchTag,
    addSelectedTag,
    removeSelectedTag,
    clearTag,
    tagsInfinteScroll,
    handleClearFilters,
    allKeywords,
    mainKeywords,
    sortBy,
    handleSortByChange: sortByOnChange,
    filterTip,
    closeFilterTip,
    showToast,
    toggleToast,
  });
};
