import { put, call, takeLatest, all } from "redux-saga/effects";
import axios from "axios";

import { getApiPath, MAX_RESULTS } from "../../utils/variables";
import { authHeader } from "../store";
import { objectToUrlEncoded } from "../../utils/constants";

import { ACCOUNT_ACTIONS, accountActions } from "../actions";

export let cancelAccountRequests = new AbortController();

export const getTimeSegmentedSummary = ({
  type,
  subType,
  forIngestion,
  term,
  segment,
  externalId,
  npiId,
}) => {
  const params = objectToUrlEncoded({
    type,
    subtype: subType,
    term,
    segment,
    forIngestion: forIngestion,
    externalId,
    npiId,
  });

  return axios.get(`${getApiPath()}/accounts/timeSegmentedSummary?${params}`, {
    headers: authHeader(),
  });
};

export async function getNPITaxonomies() {
  try {
    return await axios.get(`${getApiPath()}/npiTaxonomies`, {
      headers: authHeader(),
    });
  } catch (error) {
    // do nothing
    /*
    if (error?.response?.status === 401) {
      window.location.href = "/logout";
    }
    */
  }
}

export async function getTopArticles({
  id,
  startDate,
  endDate,
}) {
  try {
    const params = objectToUrlEncoded({
      startDate,
      endDate,
    });

    return await axios.get(`${getApiPath(false)}/account/${id}/topArticles?${params}`, );
  } catch (error) {
    // do nothing
  }
}

function* handleRequest(action) {
  let {
    type,
    subType,
    forIngestion,
    term,
    externalId,
    npiId,
    pageNum,
    maxResult,
    sortOrder,
  } = action.payload;
  const params = objectToUrlEncoded({
    type,
    subtype: subType,
    term,
    forIngestion: forIngestion,
    externalId,
    npiId,
    pageNum,
    maxResult: maxResult || MAX_RESULTS,
    sortOrder,
  });
  try {
    cancelAccountRequests = new AbortController();
    const { data } = yield call(
      axios.get,
      `${getApiPath()}/accounts?${params}`,
      {
        headers: authHeader(),
        signal: cancelAccountRequests.signal,
      }
    );

    yield put(accountActions.success({ data, pageNum }));
  } catch (e) {
    if (e?.response?.status === 401) {
      yield put((window.location.href = "/logout"));
    }
    yield put(accountActions.failure({ error: { ...e } }));
  }
}

// get count of accounts
function* handleCountRequest(action) {
  let { type, subType, forIngestion, term, externalId, npiId } = action.payload;
  const params = objectToUrlEncoded({
    type,
    subtype: subType,
    term,
    forIngestion: forIngestion,
    externalId,
    npiId,
  });
  try {
    cancelAccountRequests = new AbortController();
    const { data } = yield call(
      axios.get,
      `${getApiPath()}/accounts/count?${params}`,
      {
        headers: authHeader(),
        signal: cancelAccountRequests.signal,
      }
    );
    yield put(accountActions.countSuccess({ data }));
  } catch (e) {
    yield put(accountActions.countFailure({ error: { ...e } }));
  }
}

function* handleRequestOne(action) {
  try {
    cancelAccountRequests = new AbortController();
    const { id } = action.payload;
    const { data } = yield call(
      axios.get,
      `${getApiPath(
        false
      )}/account/${id}?${Date.now()}&relations=false&tags=false`,
      { signal: cancelAccountRequests.signal }
    );
    // data.associatedTags = yield call(handleTagsRequest, action);
    yield put(accountActions.oneSuccess({ data }));
  } catch (e) {
    yield put(accountActions.oneFailure({ error: { ...e } }));
  }
}

function* handleTagsRequest(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(
      axios.get,
      `${getApiPath(false)}/account/${id}/tags`
    );
    return data;
  } catch (e) {
    return [];
  }
}

export function getAllRelationsTo({
  id,
  relationship,
  type,
  subtype,
  term,
  sortBy,
  sortOrder,
}) {
  const params = objectToUrlEncoded({
    relationship,
    type,
    subtype,
    term,
    sortBy, // name, location, numFollowers
    sortOrder,
    pageNum: 0,
    maxResult: -1,
  });
  return axios.get(`${getApiPath(false)}/account/${id}/relationsTo?${params}`);
}

function* handleRelationsToRequest(action) {
  try {
    cancelAccountRequests = new AbortController();
    const {
      id,
      relationship,
      type,
      subtype,
      term,
      pageNum,
      maxResult,
      sortBy,
      sortOrder,
    } = action.payload;
    const params = objectToUrlEncoded({
      relationship,
      type,
      subtype,
      term,
      sortBy, // name, location, numFollowers
      sortOrder,
      pageNum,
      maxResult: maxResult || MAX_RESULTS,
    });
    const { data } = yield call(
      axios.get,
      `${getApiPath(false)}/account/${id}/relationsTo?${params}`,
      {
        signal: cancelAccountRequests.signal,
      }
    );
    yield put(accountActions.relationsToSuccess({ data, pageNum }));
  } catch (e) {
    yield put(accountActions.relationsToFailure({ error: { ...e } }));
  }
}

export function getAllRelationsFrom({
  id,
  relationship,
  type,
  subtype,
  term,
  sortBy,
  sortOrder,
}) {
  const params = objectToUrlEncoded({
    relationship,
    type,
    subtype,
    term,
    sortBy, // name, location, numFollowers
    sortOrder,
    pageNum: 0,
    maxResult: -1,
  });
  return axios.get(
    `${getApiPath(false)}/account/${id}/relationsFrom?${params}`
  );
}

export function getRelationsToByCountry({ id, type, subtype }) {
  const params = objectToUrlEncoded({
    type,
    subtype,
  });
  return axios.get(
    `${getApiPath(false)}/account/${id}/relationsToByCountry?${params}`
  );
}

export function getRelationsFromByCountry({ id, type, subtype }) {
  const params = objectToUrlEncoded({
    type,
    subtype,
  });
  return axios.get(
    `${getApiPath(false)}/account/${id}/relationsFromByCountry?${params}`
  );
}

function* handleRelationsFromRequest(action) {
  try {
    cancelAccountRequests = new AbortController();
    const {
      id,
      relationship,
      type,
      subtype,
      term,
      pageNum,
      maxResult,
      sortBy,
      sortOrder,
    } = action.payload;
    const params = objectToUrlEncoded({
      relationship,
      type,
      subtype,
      term,
      sortBy, // name, location, numFollowers
      sortOrder,
      pageNum,
      maxResult: maxResult || MAX_RESULTS,
    });
    const { data } = yield call(
      axios.get,
      `${getApiPath(false)}/account/${id}/relationsFrom?${params}`,
      {
        signal: cancelAccountRequests.signal,
      }
    );
    yield put(accountActions.relationsFromSuccess({ data, pageNum }));
  } catch (e) {
    yield put(accountActions.relationsFromFailure({ error: { ...e } }));
  }
}

function* handleRequestProducts(action) {
  try {
    cancelAccountRequests = new AbortController();
    const { id, startDate, endDate } = action.payload;
    const params = objectToUrlEncoded({
      startDate,
      endDate,
    });
    const { data } = yield call(
      axios.get,
      `${getApiPath(false)}/account/${id}/productTags?${params}`,
      { signal: cancelAccountRequests.signal }
    );
    yield put(accountActions.productsSuccess({ data }));
  } catch (e) {
    yield put(accountActions.productsFailure({ error: { ...e } }));
  }
}

function* handleUpdate(action) {
  const body = action.payload.data;
  try {
    cancelAccountRequests = new AbortController();
    const { data } = yield call(axios.put, `${getApiPath()}/account`, body, {
      headers: authHeader(),
      signal: cancelAccountRequests.signal,
    });
    yield put(accountActions.updateSuccess({ data }));
  } catch (e) {
    if (e?.response?.status === 401) {
      yield put((window.location.href = "/logout"));
    }
    yield put(accountActions.updateFailure({ error: { ...e } }));
  }
}

function* handleDelete(action) {
  const { id, accountType } = action.payload;
  try {
    cancelAccountRequests = new AbortController();
    const { data } = yield call(
      axios.delete,
      `${getApiPath()}/account?id=${id}`,
      {
        headers: authHeader(),
        signal: cancelAccountRequests.signal,
      }
    );
    yield put(accountActions.updateSuccess({ data }));
    yield put((window.location.href = "/admin/accounts"));
  } catch (e) {
    yield put(accountActions.updateFailure({ error: { ...e } }));
  }
}

function* handleCreate(action) {
  const body = action.payload.data;
  try {
    cancelAccountRequests = new AbortController();
    const { data } = yield call(axios.post, `${getApiPath()}/account`, body, {
      headers: authHeader(),
      signal: cancelAccountRequests.signal,
    });
    yield put(accountActions.createSuccess({ data }));
    yield put((window.location.href = "/admin/accounts"));
  } catch (e) {
    if (e?.response?.status === 401) {
      yield put((window.location.href = "/logout"));
    }
    yield put(accountActions.createFailure({ error: { ...e } }));
  }
}

function* handleAddRelationship(action) {
  const { hcpId, institutionId } = action.payload;
  const body = {
    tAccountIdFrom: hcpId,
    tAccountIdTo: institutionId,
    relationship: "EMPLOYER",
    primaryRelationship: true,
  };
  try {
    cancelAccountRequests = new AbortController();
    const { data } = yield call(
      axios.post,
      `${getApiPath()}/account/relationship`,
      body,
      {
        headers: authHeader(),
        signal: cancelAccountRequests.signal,
      }
    );
    yield put(accountActions.relationshipSuccess({ data }));
  } catch (e) {
    if (e?.response?.status === 401) {
      yield put((window.location.href = "/logout"));
    }
    yield put(accountActions.relationshipFailure({ error: { ...e } }));
  }
}

function* handleDeleteRelationship(action) {
  const { hcpId, institutionId } = action.payload;
  try {
    cancelAccountRequests = new AbortController();
    const { data } = yield call(
      axios.delete,
      `${getApiPath()}/account/relationship?tAccountIdFrom=${hcpId}&tAccountidTo=${institutionId}`,
      {
        headers: authHeader(),
        signal: cancelAccountRequests.signal,
      }
    );
    yield put(accountActions.relationshipSuccess({ data }));
  } catch (e) {
    if (e?.response?.status === 401) {
      yield put((window.location.href = "/logout"));
    }
    yield put(accountActions.relationshipFailure({ error: { ...e } }));
  }
}

function* handleGptRequest(action) {
  const {
    id,
    startDate,
    endDate,
    sortBy,
    pageNum,
    maxResult,
    q,
    model,
    source,
    userType,
    temperature,
    topP,
    frequencyPenalty,
    presencePenalty,
    addTagIds,
    originalOnly,
    includeReplies,
    withSentiment,
    sentiment,
    nocache,
  } = action.payload.data;
  let tagIdList = "";
  if (addTagIds?.length > 0) {
    addTagIds.forEach((tagId) => {
      tagIdList += `addTagId=${tagId}&`;
    });
  }
  const body = objectToUrlEncoded({
    startDate,
    endDate,
    sortBy,
    pageNum,
    maxResult,
    q,
    model,
    source,
    userType,
    temperature,
    topP,
    frequencyPenalty,
    presencePenalty,
    originalOnly,
    includeReplies,
    withSentiment,
    sentiment,
    nocache,
  });

  try {
    cancelAccountRequests = new AbortController();
    const { data } = yield call(
      axios.post,
      `${getApiPath()}/account/${id}/gptQuery${nocache ? "?nocache=true" : ""}`,
      `${tagIdList}${body}`,
      {
        headers: authHeader({
          "Content-Type": "application/x-www-form-urlencoded",
        }),
        signal: cancelAccountRequests.signal,
      }
    );
    yield put(accountActions.gptSuccess({ data }));
  } catch (e) {
    yield put(accountActions.gptFailure({ error: { ...e } }));
  }
}

function* watchAccountSagas() {
  yield all([
    takeLatest(ACCOUNT_ACTIONS.REQUEST, handleRequest),
    takeLatest(ACCOUNT_ACTIONS.COUNT_REQUEST, handleCountRequest),
    takeLatest(ACCOUNT_ACTIONS.ONE_REQUEST, handleRequestOne),
    takeLatest(ACCOUNT_ACTIONS.RELATIONS_TO_REQUEST, handleRelationsToRequest),
    takeLatest(
      ACCOUNT_ACTIONS.RELATIONS_FROM_REQUEST,
      handleRelationsFromRequest
    ),
    takeLatest(ACCOUNT_ACTIONS.PRODUCTS_REQUEST, handleRequestProducts),
    takeLatest(ACCOUNT_ACTIONS.UPDATE, handleUpdate),
    takeLatest(ACCOUNT_ACTIONS.DELETE, handleDelete),
    takeLatest(ACCOUNT_ACTIONS.CREATE, handleCreate),
    takeLatest(ACCOUNT_ACTIONS.ADD_RELATIONSHIP, handleAddRelationship),
    takeLatest(ACCOUNT_ACTIONS.DELETE_RELATIONSHIP, handleDeleteRelationship),
    takeLatest(ACCOUNT_ACTIONS.GPT_REQUEST, handleGptRequest),
  ]);
}

export default watchAccountSagas;
