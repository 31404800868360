import React from "react";
import styled from "styled-components";

import {
  Column,
  Row,
  MainWrapper,
  Header,
  Content,
  H2,
  Label,
  Dropdown,
  Button,
  Anchor,
  ListItem,
  Modal,
  ModalFooter,
  Editor,
  DateRange,
} from "../../../components";
import { Menu } from "../Menu";

const EditorWrapper = styled(Column)`
  .ql-toolbar {
    position: sticky;
    top: -41px;
    background: white;
    z-index: 1;
  }
`;

export const Group = ({ children, label, errorMessage }) => (
  <Column marginTop="30">
    <Row marginBottom="10">
      <Label bold>{label}</Label>
    </Row>
    <Row marginTop="5">{children}</Row>
    {errorMessage && (
      <Row marginTop="10">
        <Label color="red">{errorMessage}</Label>
      </Row>
    )}
  </Column>
);

export const Monitor = ({ monitor, handleDeleteMonitor }) => (
  <ListItem simple hover middle>
    <Label>{monitor.label}</Label>
    <Row marginRight="5" />
    <Label>
      (
      {monitor.notificationInterval === 168
        ? "Weekly"
        : monitor.notificationInterval === 24
        ? "Daily"
        : monitor.notificationInterval === 0 && monitor.active
        ? "Instant"
        : "Off"}
      )
    </Label>
    <Row fit right>
      <Anchor
        small
        label="Delete"
        onClick={() => handleDeleteMonitor(monitor.id)}
      />
    </Row>
  </ListItem>
);

export const view = ({
  errors,
  isSavePressed,
  handleSave,
  isNew,

  objectTypes,
  selectedObjectType,
  handleObjectTypeChange,

  diseases,
  selectedDisease,
  handleDiseaseChange,

  conferences,
  selectedConference,
  handleConferenceChange,

  text,
  handleTextChange,
  handleEditorBlur,
  deleteModal,
  toggleDeleteModal,
  handleDelete,
  date,
  handleDateChange,
}) => (
  <MainWrapper drawer>
    <Header
      drawer
      close={isNew}
      back={!isNew}
      bkg="white"
      rightContent={
        <Row>
          {isNew && <Button label="Create" onClick={() => handleSave()} />}
          {!isNew && (
            <Row marginX>
              <Anchor
                label="Delete"
                color="red"
                onClick={() => toggleDeleteModal(true)}
              />
            </Row>
          )}
        </Row>
      }
    ></Header>
    <Menu active="highlights" />

    <Content>
      <Column center>
        <Column maxWidth="800" marginBottom>
          <Row expand paddingY="20">
            <H2 bold>{isNew ? "Add a new Highlight" : "Edit Highlight"}</H2>
          </Row>
          <Column>
            <Group label="Monitor Type">
              <Column maxWidth="400">
                <Dropdown
                  expand
                  value={selectedObjectType}
                  onChange={handleObjectTypeChange}
                  items={objectTypes}
                />
              </Column>
            </Group>

            {selectedObjectType === "DISEASE" && (
              <Group
                label="Disease"
                errorMessage={isSavePressed && errors.disease}
              >
                <Column maxWidth="400">
                  <Dropdown
                    expand
                    value={selectedDisease}
                    onChange={handleDiseaseChange}
                    placeholder="Select one"
                    items={diseases}
                  />
                </Column>
              </Group>
            )}

            {selectedObjectType === "CONFERENCE" && (
              <Group
                label="Congress"
                errorMessage={isSavePressed && errors.disease}
              >
                <Column maxWidth="400">
                  <Dropdown
                    expand
                    value={selectedConference}
                    onChange={handleConferenceChange}
                    placeholder="Select one"
                    items={conferences}
                  />
                </Column>
              </Group>
            )}

            <Group label="Date Range">
              <Column maxWidth="400">
                <DateRange
                  initialDate={date.start}
                  endDate={date.end}
                  onChange={handleDateChange}
                />
              </Column>
            </Group>

            <Group
              label="Highlight"
              errorMessage={isSavePressed && errors.text}
            >
              <EditorWrapper>
                <Editor
                  theme="snow"
                  value={text}
                  onChange={handleTextChange}
                  onBlur={handleEditorBlur}
                  placeholder="New Highlight"
                />
              </EditorWrapper>
            </Group>
          </Column>
        </Column>

        <Modal
          visible={deleteModal}
          toggleVisibility={toggleDeleteModal}
          title="Delete Highlight"
          close
        >
          <Column marginBottom gap="20" center>
            <Label>
              <Label large bold>
                Highlight
              </Label>
              <Label large> will be deleted</Label>
            </Label>
            <Label large>
              Are you sure you want to continue? This action cannot be undone.
            </Label>
          </Column>
          <ModalFooter right>
            <Button secondary label="Delete" onClick={() => handleDelete()} />
          </ModalFooter>
        </Modal>
      </Column>
    </Content>
  </MainWrapper>
);
