import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import navReducer from "./navReducer";
import settingsReducer from "./settingsReducer";
import scrollReducer from "./scrollReducer";
import authReducer from "./authReducer";
import hcpReducer from "./hcpReducer";
import companyReducer from "./companyReducer";
import productReducer from "./productReducer";
import diseaseReducer from "./diseaseReducer";
import conferenceReducer from "./conferenceReducer";
import tweetReducer from "./tweetReducer";
import tagReducer from "./tagReducer";
import commonReducer from "./commonReducer";
import userReducer from "./userReducer";
import accountReducer from "./accountReducer";
import highlightReducer from "./highlightReducer";
import notificationReducer from "./notificationReducer";
import monitorReducer from "./monitorReducer";
import aiReducer from "./aiReducer";
import chatReducer from "./chatReducer";
import planReducer from "./planReducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "plan", "settings", "nav", "monitor"],
};

const rootReducer = combineReducers({
  nav: navReducer,
  settings: settingsReducer,
  scroll: scrollReducer,
  auth: authReducer,
  hcp: hcpReducer,
  company: companyReducer,
  product: productReducer,
  disease: diseaseReducer,
  conference: conferenceReducer,
  tweet: tweetReducer,
  tag: tagReducer,
  common: commonReducer,
  user: userReducer,
  account: accountReducer,
  highlight: highlightReducer,
  notification: notificationReducer,
  monitor: monitorReducer,
  ai: aiReducer,
  chat: chatReducer,
  plan: planReducer,
});

export default persistReducer(persistConfig, rootReducer);
