import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { productActions, tweetActions } from "../../../store/actions";
import {
  cancelProductRequests,
  cancelTweetRequests,
} from "../../../store/sagas";
import { view } from "./ProductAlertView";
import { getUrlParams, logAnalyticEvent } from "../../../utils";

export const EmailProductAlert = () => {
  const dispatch = useDispatch();

  const { nId, name, email, productId, productName, tweetId } = getUrlParams(
    location.search
  );

  // --> mapStateToProp
  const auth = useSelector((state) => state.auth.data);
  const isLoggedIn = useSelector((state) => state.auth.data.id);
  const product = useSelector((state) => state.product.one.data);
  const tweet = useSelector((state) => state.tweet.one);
  const impressions = useSelector((state) => state.tweet.impressions?.data);
  const sentimentTags = useSelector((state) => state.tweet.sentimentTags?.data);
  // <-- mapStateToProp

  const [productSentiment, setProductSentiment] = useState(undefined);

  const cookies = useSelector((state) => state.settings.cookies);

  // --> EFFECT
  useEffect(() => {
    if (productId) {
      productId && requestProduct();
    }

    if (!nId && tweetId) {
      requestTweet();
    }

    return () => {
      tweetActions.clear();
      productActions.clear();
      cancelProductRequests.abort();
      cancelTweetRequests.abort();
    };
  }, []);
  useEffect(() => {
    if (sentimentTags?.length > 0) {
      const productSentiment = sentimentTags.filter(
        (i) => i.productId === productId
      );
      if (productSentiment.length > 0)
        setProductSentiment(productSentiment[0].sentimentAverage);
      // console.log(sentimentTags, productSentiment);
    }
  }, [sentimentTags]);

  useEffect(() => {
    if (!_.isEmpty(product)) {
      const category = "Product Alert";
      let userType = null;
      if (auth?.id)
        userType =
          auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
            ?.preferenceStringValue || "MEDICAL";
      // check for cookies first before publishing event
      if (!auth?.active && cookies?.seen && !cookies?.accepted) {
        return;
      }
      // call common helper function
      logAnalyticEvent(
        "View In Browser",
        {
          id: product.id || productId,
          tagId: product.tagId,
          objectType: "PRODUCT",
          name: product.primaryName || productName,
          category,
          tweetId: tweetId,
        },
        auth?.id || null,
        auth?.displayName || name || null,
        auth?.email || email || null,
        auth?.createdAt || null,
        userType
      );
    }
  }, [product]);
  // <-- EFFECT

  const requestProduct = () => {
    dispatch(productActions.oneRequest(productId));
  };

  const requestTweet = () => {
    dispatch(tweetActions.oneRequest(tweetId));
    // get impressions
    dispatch(tweetActions.impressionsRequest({ id: tweetId }));
    // get tags with sentiments which are associated with the tweet
    dispatch(tweetActions.tagSentimentRequest(tweetId));
  };

  return view({
    nId,
    isLoggedIn,
    name,
    loading: tweet.fetching,
    monitor: product,
    productName,
    tweet: tweet.data,
    productSentiment,

    impressions,
    impressionsPercentage: impressions.analysis?.percentage,
  });
};
