import React from "react";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";

import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./styles/styles.scss";
import amplitude from "amplitude-js";

import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store/store";
import { UseToast } from "./utils/useToast";
import { getAnalyticsApiKey } from "./utils/variables";
import { QueryClient, QueryClientProvider } from "react-query";
// import { ReactQueryDevtools } from "react-query/devtools";
export const history = createBrowserHistory();

// overwrite console errors
if (window.console && console.error) {
  var backup = console.error;
  console.error = function filterWarnings(msg) {
    const supressedWarnings = [
      "validateDOMNesting",
      "Uncaught ReferenceError: process is not defined",
    ];
    if (
      typeof msg === "string" &&
      !supressedWarnings.some((entry) => msg?.includes(entry))
    ) {
      backup.apply(console, arguments);
    }
  };
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // avoid refetch when refocus on window
      refetchOnWindowFocus: false,
      // only fetch once
      retry: false,
    },
  },
});

const render = (Component) => {
  ReactDOM.render(
    <HelmetProvider>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <BrowserRouter history={history}>
            <UseToast />
            <QueryClientProvider client={queryClient}>
              <Component />
              {/* <ReactQueryDevtools initialIsOpen={false} /> */}
            </QueryClientProvider>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </HelmetProvider>,
    document.getElementById("root")
  );
};

render(App);

// initialize amplitude with options
amplitude.getInstance().init(
  getAnalyticsApiKey(),
  null,
  {
    includeUtm: true,
    includeReferrer: true,
    includeGclid: true,
    includeFbclid: true,
  }
  // function(instance) {
  //   console.log(instance.options.deviceId);  // access Amplitude's deviceId after initialization
  // }
);
// regenerate device id (first time when page loads)
// amplitude.getInstance().regenerateDeviceId();

if (module.hot) {
  module.hot.accept("./App", () => {
    // eslint-disable-next-line
    const App = require("./App").default;
    render(App);
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
