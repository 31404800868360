import axios from "axios";

import { getApiPath } from "../../utils/variables";
import { objectToUrlEncoded } from "../../utils/constants";
import { authHeader } from "../store";

// get all objects with summary
export const getObjectsWithSummary = () => {
  return axios.get(`${getApiPath(false)}/aiSummaries/objects`);
};

// get object summary, with pagination
export const getObjectSummaries = ({
  objectId,
  startDate,
  endDate,
  pageNum,
  maxResult,
}) => {
  const params = objectToUrlEncoded({
    objectId,
    startDate,
    endDate,
    pageNum,
    maxResult,
  });
  return axios.get(`${getApiPath(false)}/aiSummaries/objectSummary?${params}`);
};

export const getAISummaryGptQuery = ({
  id,
  startDate,
  endDate,
  sortBy,
  pageNum,
  maxResult,
  model,
  q,
  source,
  temperature,
  topP,
  frequencyPenalty,
  presencePenalty,
  // timeout,
}) => {
  const body = objectToUrlEncoded({
    startDate,
    endDate,
    sortBy,
    pageNum,
    maxResult,
    model,
    q,
    source,
    temperature,
    topP,
    frequencyPenalty,
    presencePenalty,
    // timeout,
  });
  return axios.post(`${getApiPath()}/aiSummaries/gptQuery?objectId=${id}`, body, {
    headers: authHeader({
      "Content-Type": "application/x-www-form-urlencoded",
    }),
  });
};

export const getEmailSuggestionsGptQuery = ({
  objectId,
  objectType,
  userType,
  startDate,
  endDate,
  sortBy,
  pageNum,
  maxResult,
  model,
  q,
  source,
  temperature,
  topP,
  frequencyPenalty,
  presencePenalty,
  // timeout,
}) => {
  const body = objectToUrlEncoded({
    userType,
    startDate,
    endDate,
    sortBy,
    pageNum,
    maxResult,
    model,
    q,
    source,
    temperature,
    topP,
    frequencyPenalty,
    presencePenalty,
    // timeout,
  });
  return axios.post(`${getApiPath()}/notification/gptQuery?objectId=${objectId}&objectType=${objectType}`, body, {
    headers: authHeader({
      "Content-Type": "application/x-www-form-urlencoded",
    }),
  });
};

// update summary title
export const updateSummaryTitle = ({ id, title }) => {
  const params = objectToUrlEncoded({
    id,
  });
  const data = `title=${title}`;

  return axios.put(`${getApiPath()}/aiSummaries/updateTitle?${params}`, data, {
    headers: authHeader({
      "Content-Type": "application/x-www-form-urlencoded",
    }),
  });
};
