import React from "react";
import Table from "rc-table";
import "../../../components/styles/rc-table.scss";
import { Link } from "react-router-dom";

import {
  Row,
  Column,
  MainWrapper,
  Header,
  Content,
  Label,
  Input,
  Filters,
  Dropdown,
  AutoComplete,
  Spinner,
  Grid,
  Anchor,
  Icon,
} from "../../../components";
import { Menu } from "../Menu";

const getNotificationInterval = (interval) => {
  if (interval === 168) {
    return "Weekly";
  } else if (interval === 24) {
    return "Daily";
  } else if (interval === 0) {
    return "Instant";
  } else if (interval === -1) {
    return "Off";
  } else {
    return "";
  }
};

const getMonitorType = (type) => {
  if (type === "CONFERENCE") return "Congress";
  else if (type === "PRODUCT") return "Product";
  else if (type === "DISEASE") {
    return "Disease";
  } else {
    return "";
  }
};

export const view = ({
  // history,
  loading,
  monitors,

  selectedListType,
  setSelectedListType,
  handleListTypeChange,
  listGroupByUser,
  listGroupByMonitor,
  summaryByUser,

  users,
  searchUser,
  selectedUser,
  handleSearchUser,
  addSelectedUser,
  clearUser,
  usersInfiniteScroll,

  monitorTypes,
  selectedMonitorType,
  handleMonitorTypeChange,

  searchObjectTerm,
  handleObjectTermChange,

  monitorStatuses,
  selectedMonitorStatus,
  handleMonitorStatusChange,

  monitorIntervals,
  selectedMonitorInterval,
  handleMonitorIntervalChange,

  exportToXlsx,
}) => {
  const columns = [
    {
      title: "#",
      render: (text, record, index) => `${index + 1}`, //RDT provides index by default
    },
    {
      title: "Monitor Type",
      key: "monitorComponent.object1Type",
      render: (cell) => (
        <Label small>{getMonitorType(cell.monitorComponent.object1Type)}</Label>
      ),
    },
    {
      title: "Monitor",
      key: "monitorComponent.object1Name",
      render: (cell) => (
        <Label small>{cell.monitorComponent.object1Name}</Label>
      ),
    },
    {
      title: "User",
      key: "user",
      render: (cell) => (
        <Link to={`/editUser/${cell.user.id}`}>
          <Anchor label={cell.user.displayName} color="black" small />
        </Link>
      ),
    },
    {
      title: "Active",
      key: "visible",
      render: (cell) => (
        <Row center expand>
          <Icon
            size={cell.visible ? 34 : 20}
            color={cell.visible ? "green" : "red"}
            name={cell.visible ? "check" : "close"}
          />
        </Row>
      ),
    },
    {
      title: "Interval",
      key: "interval",
      render: (cell) => (
        <Label small>
          {getNotificationInterval(cell.notificationInterval)}
        </Label>
      ),
    },
    {
      title: "Created At",
      key: "createdAt",
      render: (cell) => (
        <Label small>
          {new Date(cell.createdAt).toLocaleString(
            "en-US",
            { timeZone: "UTC" } // remove this if we want in local timezone
          )}
        </Label>
      ),
    },
  ];

  const columnsGroupByUser = [
    {
      title: "#",
      render: (text, record, index) => `${index + 1}`, //RDT provides index by default
      width: 50,
    },
    {
      title: "User",
      key: "name",
      render: (cell) => (
        <Link to={`/editUser/${cell.id}`}>
          <Anchor label={cell.name} />
        </Link>
      ),
      // width: 150,
    },
    {
      title: "Monitors",
      key: "monitors",
      render: (cell) => (
        <>
          <Label style={{ whiteSpace: "normal" }}>{cell.monitors}</Label>
          <br />
          <Label
            style={{ whiteSpace: "normal", textDecoration: "line-through" }}
          >
            {cell.inactiveMonitors}
          </Label>
        </>
      ),
      width: 750,
    },
  ];

  const columnsGroupByMonitor = [
    {
      title: "#",
      render: (text, record, index) => `${index + 1}`, //RDT provides index by default
    },
    {
      title: "Type",
      key: "type",
      render: (cell) => <Label>{getMonitorType(cell.type)}</Label>,
    },
    {
      title: "Monitor",
      key: "name",
      render: (cell) => <Label>{cell.name}</Label>,
    },
    {
      title: "Users",
      key: "user",
      render: (cell) => <Label style={{ whiteSpace: "normal" }}>{cell.users}</Label>,
    },
  ];

  const columnsSummaryByUser = [
    {
      title: "#",
      render: (text, record, index) => `${index + 1}`, //RDT provides index by default
      width: 50,
      ellipsis: true,
    },
    {
      title: "User",
      key: "name",
      render: (cell) => (
        <Link to={`/editUser/${cell.id}`}>
          <Anchor label={cell.name} />
        </Link>
      ),
      // width: 150,
    },
    {
      title: "Diseases",
      key: "diseaseMonitors",
      render: (cell) => (
        <>
          <Label style={{ whiteSpace: "normal" }}>{cell.diseaseMonitors}</Label>
          <br />
          <Label
            style={{ whiteSpace: "normal", textDecoration: "line-through" }}
          >
            {cell.inactiveDiseaseMonitors}
          </Label>
        </>
      ),
      // width: 250,
    },
    {
      title: "Products",
      key: "productMonitors",
      render: (cell) => (
        <>
          <Label style={{ whiteSpace: "normal" }}>{cell.productMonitors}</Label>
          <br />
          <Label
            style={{ whiteSpace: "normal", textDecoration: "line-through" }}
          >
            {cell.inactiveProductMonitors}
          </Label>
        </>
      ),
      // width: 250,
    },
    {
      title: "Congresses",
      key: "conferenceMonitors",
      render: (cell) => (
        <>
          <Label style={{ whiteSpace: "normal" }}>
            {cell.conferenceMonitors}
          </Label>
          <br />
          <Label
            style={{ whiteSpace: "normal", textDecoration: "line-through" }}
          >
            {cell.inactiveConferenceMonitors}
          </Label>
        </>
      ),
      // width: 250,
    },
  ];

  return (
    <MainWrapper drawer>
      <Header
        drawer
        rightContent={
          <>
            <Row fit />
          </>
        }
      />
      <Menu active="monitors" />

      <Content>
        <Column>
          <Column marginBottom>
            <Filters counter={`${monitors?.length} monitors`}>
              <Grid columns={3}>
                <Column gap="10">
                  <Label bold>Monitor Type</Label>
                  <Dropdown
                    expand
                    right
                    select
                    value={selectedMonitorType}
                    onChange={handleMonitorTypeChange}
                    items={monitorTypes}
                  />
                </Column>
                <Column gap="10">
                  <Label bold>Monitor</Label>
                  <Input
                    expand
                    icon="search"
                    small
                    placeholder="search name"
                    value={searchObjectTerm}
                    onChange={(e) => {
                      handleObjectTermChange(e.target?.value || null);
                    }}
                  />
                </Column>
                <Column gap="10">
                  <Label bold>User</Label>
                  <AutoComplete
                    showOnEmptyTerm
                    icon="search"
                    displayTextFromSelected
                    searchResults={users}
                    setSearchTerm={handleSearchUser}
                    searchTerm={searchUser}
                    selectedItems={selectedUser}
                    addItem={addSelectedUser}
                    clearSearchResults={clearUser}
                    hasClearButton
                    infiniteScroll={usersInfiniteScroll}
                  />
                </Column>
                <Column gap="10">
                  <Label bold>Status</Label>
                  <Dropdown
                    expand
                    right
                    select
                    value={selectedMonitorStatus}
                    onChange={handleMonitorStatusChange}
                    items={monitorStatuses}
                  />
                </Column>
                <Column gap="10">
                  <Label bold>Interval</Label>
                  <Dropdown
                    expand
                    right
                    select
                    value={selectedMonitorInterval}
                    onChange={handleMonitorIntervalChange}
                    items={monitorIntervals}
                  />
                </Column>
              </Grid>
            </Filters>
          </Column>

          {!loading && monitors?.length > 0 && (
            <Column>
              <Row fit />
              <Row right gap="10" middle>
                <Dropdown
                  right
                  select
                  style={{ width: "250px" }}
                  label="List"
                  value={selectedListType}
                  onChange={(item) => {
                    handleListTypeChange(item);
                  }}
                  items={[
                    { id: "1", label: "All" },
                    { id: "2", label: "Group by User" },
                    { id: "3", label: "Group by Monitor" },
                    { id: "4", label: "Summary by User" },
                  ]}
                />
                <Anchor
                  bold
                  label="Export"
                  icon="download"
                  onClick={() => exportToXlsx()}
                />
              </Row>
            </Column>
          )}

          <Column marginTop="20">
            {!loading && monitors?.length > 0 ? (
              <>
                {selectedListType === "1" && (
                  <Table
                    rowKey="id"
                    data={monitors}
                    {...{ columns }}
                    style={{ maxWidth: 980 }}
                    scroll={{ x: 978 }}
                  />
                )}
                {selectedListType === "2" && (
                  <Table
                    rowKey="id"
                    data={listGroupByUser}
                    {...{ columns: columnsGroupByUser }}
                    style={{ maxWidth: 980 }}
                    scroll={{ x: 978 }}
                  />
                )}
                {selectedListType === "3" && (
                  <Table
                    rowKey="id"
                    data={listGroupByMonitor}
                    {...{ columns: columnsGroupByMonitor }}
                    style={{ maxWidth: 980 }}
                    scroll={{ x: 978 }}
                  />
                )}
                {selectedListType === "4" && (
                  <Table
                    rowKey="id"
                    data={summaryByUser}
                    {...{ columns: columnsSummaryByUser }}
                    style={{ maxWidth: 980 }}
                    scroll={{ x: 978 }}
                  />
                )}
              </>
            ) : (
              <>
                {loading ? (
                  <Row expand paddingAll="80" center>
                    <Spinner />
                  </Row>
                ) : (
                  <Row marginTop>
                    <Label>No monitors found</Label>
                  </Row>
                )}
              </>
            )}
          </Column>
        </Column>
      </Content>
    </MainWrapper>
  );
};
