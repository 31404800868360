import React, { useEffect, useRef, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4plugins_overlapBuster from "@amcharts/amcharts4/plugins/overlapBuster";
import { isEqual, cloneDeep } from "lodash";

export const XYScatterChart = ({
  id,
  typeX,
  typeY,
  data,
  handleChartClick,
}) => {
  // types: sentiment, influence and mentions

  const chartRef = useRef(null);
  // const legendRef = useRef(null);
  const CHART_ID = id || "XYScatterChart";
  // const LEGEND_ID = CHART_ID + "LEGEND";

  const [previousData, setPreviousData] = useState([]);
  const createChart = () => {
    am4core.useTheme(am4themes_animated);
    am4core.options.commercialLicense = true;

    chartRef.current = am4core.create(CHART_ID, am4charts.XYChart);
    chartRef.current.data = data;
    chartRef.current.maskBullets = false;

    var topContainer = chartRef.current.chartContainer.createChild(
      am4core.Container
    );
    topContainer.layout = "absolute";
    topContainer.toBack();
    topContainer.width = am4core.percent(100);

    var axisTitle = topContainer.createChild(am4core.Label);
    axisTitle.align = "left";
    axisTitle.paddingBottom = 30;

    // Create X axis
    if (typeX === "sentiment") {
      var labelX = chartRef.current.chartContainer.createChild(am4core.Label);
      labelX.text = "Sentiment";
      labelX.align = "right";
      let allX = data.map((a) => a.x);
      let maxValue = Math.max(Math.abs(Math.min(...allX)), Math.max(...allX));
      var valueAxisX = chartRef.current.xAxes.push(new am4charts.ValueAxis());
      valueAxisX.min = -maxValue;
      valueAxisX.max = maxValue;
      valueAxisX.renderer.baseGrid.strokeWidth = 0;
      valueAxisX.renderer.labels.template.fill = am4core.color("#666769");

      valueAxisX.renderer.grid.template.disabled = true;
      valueAxisX.renderer.labels.template.disabled = true;

      function createXGrid(value) {
        var rangeX = valueAxisX.axisRanges.create();
        rangeX.value = value;
        if (value === maxValue) {
          rangeX.label.text = "Advocate";
          rangeX.grid.strokeOpacity = 0;
        }
        if (value === -maxValue) {
          rangeX.label.text = "Detractor";
          rangeX.grid.strokeOpacity = 0;
        }
      }

      createXGrid(-maxValue);
      createXGrid(0);
      createXGrid(maxValue);
    } else if (typeX === "influence") {
      var labelX = chartRef.current.chartContainer.createChild(am4core.Label);
      labelX.text = "Influence";
      labelX.align = "right";

      var valueAxisX = chartRef.current.xAxes.push(new am4charts.ValueAxis());
      valueAxisX.min = 0;
      valueAxisX.max = 1000;
      valueAxisX.renderer.baseGrid.strokeWidth = 0;
      valueAxisX.renderer.labels.template.fill = am4core.color("#666769");

      valueAxisX.renderer.grid.template.disabled = true;
      valueAxisX.renderer.labels.template.disabled = true;

      function createGrid(value) {
        var range = valueAxisX.axisRanges.create();
        range.value = value;
        if (value === 166) {
          range.label.text = "Low";
          range.grid.strokeOpacity = 0;
        }
        if (value === 500) {
          range.label.text = "Medium";
          range.grid.strokeOpacity = 0;
        }
        if (value === 834) {
          range.label.text = "High";
          range.grid.strokeOpacity = 0;
        }
      }

      createGrid(0);
      createGrid(166);
      createGrid(333);
      createGrid(500);
      createGrid(666);
      createGrid(834);
      createGrid(1000);
    }

    // Create Y axis
    if (typeY === "mentions") {
      axisTitle.text = "Mentions";

      var valueAxisY = chartRef.current.yAxes.push(new am4charts.ValueAxis());
      valueAxisY.renderer.baseGrid.strokeWidth = 0;
      valueAxisY.renderer.grid.template.strokeDasharray = "2,4";
      valueAxisY.renderer.labels.template.fill = am4core.color("#666769");
      valueAxisY.renderer.labels.template.labelX = -20;
      valueAxisY.renderer.labels.template.fontSize = 14;

      valueAxisY.renderer.ticks.template.disabled = true;
      valueAxisY.renderer.axisFills.template.disabled = true;
    } else if (typeY === "influence") {
      axisTitle.text = "Influence";

      var valueAxisY = chartRef.current.yAxes.push(new am4charts.ValueAxis());
      valueAxisY.min = 0;
      valueAxisY.max = 1000;

      valueAxisY.renderer.baseGrid.strokeWidth = 0;
      valueAxisY.renderer.labels.template.fill = am4core.color("#666769");

      valueAxisY.renderer.grid.template.disabled = true;
      valueAxisY.renderer.labels.template.disabled = true;

      function createGrid(value) {
        var range = valueAxisY.axisRanges.create();
        range.value = value;
        if (value === 166) {
          range.label.text = "Low";
          range.grid.strokeOpacity = 0;
        }
        if (value === 500) {
          range.label.text = "Medium";
          range.grid.strokeOpacity = 0;
        }
        if (value === 834) {
          range.label.text = "High";
          range.grid.strokeOpacity = 0;
        }
      }

      createGrid(0);
      createGrid(166);
      createGrid(333);
      createGrid(500);
      createGrid(666);
      createGrid(834);
      createGrid(1000);
    }

    var series = chartRef.current.series.push(new am4charts.LineSeries());
    series.dataFields.valueX = "x";
    series.dataFields.valueY = "y";
    series.dataFields.value = "value";
    series.strokeOpacity = 0;
    series.sequencedInterpolation = true;
    series.tooltip.pointerOrientation = "vertical";
    series.tooltip.getFillFromObject = false;
    series.tooltip.background.fill = am4core.color("#000000");

    if (data.length > 20) {
      // RENDER DOTS
      var bullet = series.bullets.push(new am4core.Circle());
      bullet.fill = am4core.color("#fffff");
      bullet.propertyFields.fill = "color";
      bullet.strokeOpacity = 0;
      bullet.strokeWidth = 2;
      bullet.fillOpacity = 1;
      bullet.stroke = am4core.color("#ffffff");
      bullet.hiddenState.properties.opacity = 0;

      series.heatRules.push({
        target: bullet,
        min: 9,
        max: 9,
        property: "radius",
      });

      var overlap = chartRef.current.plugins.push(
        new am4plugins_overlapBuster.OverlapBuster()
      );
      overlap.targets.push(bullet);
    } else {
      // RENDER IMAGES
      var bullet = series.bullets.push(new am4charts.Bullet());
      bullet.fill = am4core.color("#fffff");
      bullet.strokeOpacity = 0;
      bullet.strokeWidth = 1;
      bullet.fillOpacity = 0.5;

      var image = bullet.createChild(am4core.Image);
      image.width = 30;
      image.height = 30;
      // image.hiddenState.properties.opacity = 0;
      image.horizontalCenter = "middle";
      image.verticalCenter = "middle";
      image.propertyFields.href = "bullet";

      var mask = new am4core.Circle();
      mask.radius = 15;
      mask.horizontalCenter = "middle";
      mask.verticalCenter = "middle";
      mask.align = "center";
      mask.valign = "top";
      mask.parent = bullet;

      image.adapter.add("mask", function (mask, target) {
        return target.parent.children.getIndex(1);
      });

      series.heatRules.push({
        target: bullet,
        min: 20,
        max: 20,
        property: "radius",
      });
    }

    // RENDER COMMON
    var outline = chartRef.current.plotContainer.createChild(am4core.Circle);
    outline.fillOpacity = 0;
    outline.strokeOpacity = 1;
    outline.strokeWidth = 2;
    outline.hide(0);

    var blurFilter = new am4core.BlurFilter();
    outline.filters.push(blurFilter);

    bullet.events.on("over", function (event) {
      var target = event.target;
      outline.radius = target.pixelRadius + 2;
      outline.x = target.pixelX;
      outline.y = target.pixelY;
      outline.show();
    });

    bullet.events.on("out", function (event) {
      outline.hide();
    });

    bullet.events.on("hit", function (event) {
      handleChartClick?.(event.target.dataItem.dataContext);
    });

    var hoverState = bullet.states.create("hover");
    hoverState.properties.fillOpacity = 1;
    hoverState.properties.strokeOpacity = 1;

    bullet.adapter.add("tooltipY", function (tooltipY, target) {
      return -target.radius;
    });

    bullet.tooltipText = "[bold]{name}[/]\n@{username}\n{location}";
  };

  useEffect(() => {
    return () => {
      chartRef.current && chartRef.current.dispose();
      // legendRef.current && legendRef.current.dispose();
    };
  }, []);

  useEffect(() => {
    // deep compare
    if (!isEqual(data, previousData)) {
      // change state only when there is a change
      chartRef.current && chartRef.current.dispose();
      // legendRef.current && legendRef.current.dispose();
      createChart();
      setPreviousData(cloneDeep(data)); // save for next compare
    }
  }, [data]);

  return (
    <div>
      <div
        id={CHART_ID}
        style={{
          width: "100%",
          height: "400px",
          margin: "20px 0",
        }}
      />
      {/* <div
        id={LEGEND_ID}
        style={{
          width: "100%",
          height: "120px",
          // margin: "20px 0",
        }}
      /> */}
    </div>
  );
};
