import React from "react";
import {
  Row,
  Column,
  H3,
  MainWrapper,
  Content,
  Button,
  Input,
  CardBody,
  Card,
  TextLogo,
  Metadata,
} from "../../components";

export const view = ({
  userName,
  password,
  handleUserName,
  handlePassword,
  submit,
  error,
  loading,
}) => (
  <MainWrapper noHeader>
    <Metadata />
    <Content bkg="gray" fade="1">
      <Column center>
        <Row paddingBottom>
          <TextLogo />
        </Row>
        <Card medium radius="30">
          <CardBody center>
            <Column stretch center>
              <form onSubmit={(e) => submit(e)}>
                <Column center maxWidth="400" stretch>
                  <H3 bold>Welcome</H3>
                  <Column marginTop="60" gap="20" stretch>
                    <Input
                      id="input-username"
                      small
                      placeholder="Email"
                      value={userName}
                      onChange={(e) => handleUserName(e.target.value)}
                    />

                    <Input
                      id="input-password"
                      small
                      expand
                      errorMessage={error}
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => handlePassword(e.target.value)}
                    />
                  </Column>
                  <Column marginTop="60" center>
                    <Button
                      id="button-login"
                      minWidth="240"
                      label="Log In"
                      onClick={(e) => submit(e)}
                      loading={loading}
                    />
                  </Column>
                </Column>
              </form>
            </Column>
          </CardBody>
        </Card>
      </Column>
    </Content>
  </MainWrapper>
);
