import { put, call, takeLatest, all } from "redux-saga/effects";
import axios from "axios";

import { getApiPath } from "../../utils/variables";
import { authHeader } from "../store";
import { PLAN_ACTIONS, planActions } from "../actions";
import { objectToUrlEncoded } from "../../utils/constants";

export let cancelPlanRequests = new AbortController();

// list all plans
function* handleRequest(action) {
  const { active } = action.payload;
  const params = objectToUrlEncoded({ active });
  try {
    cancelPlanRequests = new AbortController();
    const { data } = yield call(
      axios.get,
      `${getApiPath(false)}/plans?${params}`,
      {
        signal: cancelPlanRequests.signal,
      }
    );
    yield put(planActions.success({ data }));
  } catch (e) {
    yield put(planActions.failure({ error: { ...e } }));
  }
}

// fetch one plan by id, includes features
function* handleRequestOne(action) {
  const { id } = action.payload;

  try {
    cancelPlanRequests = new AbortController();
    const { data } = yield call(axios.get, `${getApiPath(false)}/plan/${id}`, {
      signal: cancelPlanRequests.signal,
    });
    yield put(planActions.oneSuccess({ data }));
  } catch (e) {
    yield put(planActions.oneFailure({ error: { ...e } }));
  }
}

// base plans
function* handleBasePlansRequest(action) {
  const { active } = action.payload;
  let params = "";
  params += active ? `active=${active}` : "";
  try {
    cancelPlanRequests = new AbortController();
    const { data } = yield call(
      axios.get,
      `${getApiPath(false)}/basePlans?${params}`,
      {
        headers: authHeader(),
        signal: cancelPlanRequests.signal,
      }
    );
    yield put(planActions.basePlansSuccess({ data }));
  } catch (e) {
    if (e?.response?.status === 401) {
      yield put((window.location.href = "/logout"));
    }
    yield put(planActions.basePlansFailure({ error: { ...e } }));
  }
}

// all user plan associations
function* handleAllUserPlansRequest(action) {
  const { userId, active, planId, pageNum, maxResult } = action.payload;
  let params = "";
  params += userId ? `userId=${userId}&` : "";
  params += active ? `active=${active}&` : "";
  params += planId ? `planId=${planId}&` : "";
  params += pageNum ? `pageNum=${pageNum}&` : "";
  params += maxResult ? `maxResult=${maxResult}` : "";
  try {
    cancelPlanRequests = new AbortController();
    const { data } = yield call(
      axios.get,
      `${getApiPath()}/allUserPlans?${params}`,
      {
        headers: authHeader(),
        signal: cancelPlanRequests.signal,
      }
    );
    yield put(planActions.allUserPlansSuccess({ data, pageNum }));
  } catch (e) {
    if (e?.response?.status === 401) {
      yield put((window.location.href = "/logout"));
    }
    yield put(planActions.allUserPlansFailure({ error: { ...e } }));
  }
}

// single user's plans
function* handleUserPlansRequest(action) {
  const { userId, active } = action.payload;
  let params = "";
  params += userId ? `userId=${userId}&` : "";
  params += active ? `active=${active}` : "";
  try {
    cancelPlanRequests = new AbortController();
    const { data } = yield call(
      axios.get,
      `${getApiPath()}/userPlans?${params}`,
      {
        headers: authHeader(),
        signal: cancelPlanRequests.signal,
      }
    );
    yield put(planActions.userPlansSuccess({ data }));
  } catch (e) {
    if (e?.response?.status === 401) {
      yield put((window.location.href = "/logout"));
    }
    yield put(planActions.userPlansFailure({ error: { ...e } }));
  }
}

function* handleUserPlansUpdate(action) {
  const { userId, planId } = action.payload;
  const body = { userId, planId };
  try {
    cancelPlanRequests = new AbortController();
    const { data } = yield call(axios.put, `${getApiPath()}/userPlan`, body, {
      headers: authHeader(),
      signal: cancelPlanRequests.signal,
    });
    yield put(planActions.userPlansSuccess({ data }));
  } catch (e) {
    if (e?.response?.status === 401) {
      yield put((window.location.href = "/logout"));
    }
    yield put(planActions.userPlansFailure({ error: { ...e } }));
  }
}

function* handleSync() {
  try {
    cancelPlanRequests = new AbortController();
    const { data } = yield call(
      axios.put,
      `${getApiPath()}/plans/synchronize`,
      {
        headers: authHeader(),
        signal: cancelPlanRequests.signal,
      }
    );
    yield put(planActions.syncSuccess({ data }));
  } catch (e) {
    if (e?.response?.status === 401) {
      yield put((window.location.href = "/logout"));
    }
    yield put(planActions.syncFailure({ error: { ...e } }));
  }
}

function* handleSession(action) {
  const { session } = action.payload;
  try {
    cancelPlanRequests = new AbortController();
    const { data } = yield call(
      axios.get,
      `${getApiPath(false)}/get-checkout-session?session_id=${session}`,
      {
        signal: cancelPlanRequests.signal,
      }
    );
    yield put(planActions.sessionSuccess({ data }));
  } catch (e) {
    yield put(planActions.sessionFailure({ error: { ...e } }));
  }
}

export async function assignDefaultPlan() {
  try {
    return await axios.post(`${getApiPath()}/assignDefaultPlan`, {}, {
      headers: authHeader(),
    });
  } catch (error) {
    // do nothing
    /*
    if (error?.response?.status === 401) {
      window.location.href = "/logout";
    }
    */
  }
}

function* watchPlanSagas() {
  yield all([takeLatest(PLAN_ACTIONS.REQUEST, handleRequest)]);
  yield all([takeLatest(PLAN_ACTIONS.REQUEST_ONE, handleRequestOne)]);
  yield all([
    takeLatest(PLAN_ACTIONS.BASE_PLANS_REQUEST, handleBasePlansRequest),
  ]);
  yield all([
    takeLatest(PLAN_ACTIONS.USER_PLANS_REQUEST, handleUserPlansRequest),
  ]);
  yield all([
    takeLatest(PLAN_ACTIONS.USER_PLANS_UPDATE, handleUserPlansUpdate),
  ]);
  yield all([
    takeLatest(PLAN_ACTIONS.ALL_USER_PLANS_REQUEST, handleAllUserPlansRequest),
  ]);
  yield all([takeLatest(PLAN_ACTIONS.SYNC, handleSync)]);
  yield all([takeLatest(PLAN_ACTIONS.SESSION, handleSession)]);
}

export default watchPlanSagas;
