import { PRODUCT_ACTIONS } from "../actions";
import { MAX_RESULTS } from "../../utils/variables";

const initialState = {
  all: { data: [], fetching: false, success: false, error: null },
  tags: { data: [], fetching: false, success: false, error: null },
  one: { data: [], fetching: false, success: false, error: null },
  count: { data: [], fetching: false, success: false, error: null },
  tweets: { data: [], fetching: false, success: false, error: null },
  tweetsCount: { data: [], fetching: false, success: false, error: null },
  dol: { data: [], fetching: false, success: false, error: null },
  gpt: { data: [], fetching: false, success: false, error: null },
};

export default function productReducer(state = initialState, action) {
  switch (action.type) {
    case PRODUCT_ACTIONS.REQUEST:
    case PRODUCT_ACTIONS.FAILURE:
      return { ...state, all: { ...state.all, ...action.payload } };
    case PRODUCT_ACTIONS.SUCCESS:
      // If we have pagination, we need to merge state data & payload data
      const newState = {
        ...state,
        all: {
          ...state.all,
          ...action.payload,
          isLastPage: action.payload.data.length < MAX_RESULTS,
        },
      };
      if (action.payload.pageNum && action.payload.pageNum !== 0) {
        newState.all.data = [...state.all.data, ...action.payload.data];
      }
      return newState;

    case PRODUCT_ACTIONS.TAGS_REQUEST:
    case PRODUCT_ACTIONS.TAGS_FAILURE:
      return { ...state, tags: { ...state.tags, ...action.payload } };
    case PRODUCT_ACTIONS.TAGS_SUCCESS:
      // If we have pagination, we need to merge state data & payload data
      const newState1 = {
        ...state,
        tags: {
          ...state.tags,
          ...action.payload,
          isLastPage: action.payload.data.length < MAX_RESULTS,
        },
      };
      if (action.payload.pageNum && action.payload.pageNum !== 0) {
        newState1.tags.data = [...state.tags.data, ...action.payload.data];
      }
      return newState1;

    case PRODUCT_ACTIONS.ONE_REQUEST:
    case PRODUCT_ACTIONS.ONE_SUCCESS:
    case PRODUCT_ACTIONS.ONE_FAILURE:
    case PRODUCT_ACTIONS.CLEAR:
    case PRODUCT_ACTIONS.CREATE:
    case PRODUCT_ACTIONS.UPDATE:
      return { ...state, one: { ...state.one, ...action.payload } };

    case PRODUCT_ACTIONS.COUNT_REQUEST:
    case PRODUCT_ACTIONS.COUNT_SUCCESS:
    case PRODUCT_ACTIONS.COUNT_FAILURE:
      return {
        ...state,
        count: { ...state.count, ...action.payload },
      };

    case PRODUCT_ACTIONS.CLEAR_TAGS:
      return {
        ...state,
        tweets: action.payload,
        tweetsCount: action.payload,
        count: action.payload,
      };

    case PRODUCT_ACTIONS.TWEETS_REQUEST:
    case PRODUCT_ACTIONS.TWEETS_FAILURE:
      return { ...state, tweets: { ...state.tweets, ...action.payload } };
    case PRODUCT_ACTIONS.TWEETS_SUCCESS:
      // If we have pagination, we need to merge state data & payload data
      const newTweetState = {
        ...state,
        tweets: {
          ...state.tweets,
          ...action.payload,
          isLastPage: action.payload.data.length < MAX_RESULTS,
        },
      };
      if (action.payload.pageNum && action.payload.pageNum !== 0) {
        newTweetState.tweets.data = [
          ...state.tweets.data,
          ...action.payload.data,
        ];
      }
      return newTweetState;

    case PRODUCT_ACTIONS.TWEETS_COUNT_REQUEST:
    case PRODUCT_ACTIONS.TWEETS_COUNT_SUCCESS:
    case PRODUCT_ACTIONS.TWEETS_COUNT_FAILURE:
      return {
        ...state,
        tweetsCount: { ...state.tweetsCount, ...action.payload },
      };

    case PRODUCT_ACTIONS.DOL_REQUEST:
    case PRODUCT_ACTIONS.DOL_SUCCESS:
    case PRODUCT_ACTIONS.DOL_FAILURE:
      return {
        ...state,
        dol: { ...state.dol, ...action.payload },
      };

    case PRODUCT_ACTIONS.GPT_REQUEST:
    case PRODUCT_ACTIONS.GPT_SUCCESS:
    case PRODUCT_ACTIONS.GPT_FAILURE:
      return {
        ...state,
        gpt: { ...state.gpt, ...action.payload },
      };

    default:
      return state;
  }
}
