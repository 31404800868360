import { PLAN_ACTIONS } from "../actions";
import { MAX_RESULTS } from "../../utils/variables";

const initialState = {
  all: { data: [], fetching: false, success: false, error: null },
  one: { data: [], fetching: false, success: false, error: null },
  basePlans: { data: [], fetching: false, success: false, error: null },
  allUserPlans: { data: [], fetching: false, success: false, error: null },
  userPlans: { data: [], fetching: false, success: false, error: null },
  sync: { data: [], fetching: false, success: false, error: null },
  session: { data: [], fetching: false, success: false, error: null },
};

export default function planReducer(state = initialState, action) {
  switch (action.type) {
    case PLAN_ACTIONS.REQUEST:
    case PLAN_ACTIONS.FAILURE:
    case PLAN_ACTIONS.SUCCESS:
      return { ...state, all: action.payload };

    case PLAN_ACTIONS.REQUEST_ONE:
    case PLAN_ACTIONS.ONE_FAILURE:
    case PLAN_ACTIONS.ONE_SUCCESS:
      return { ...state, one: action.payload };

    case PLAN_ACTIONS.BASE_PLANS_REQUEST:
    case PLAN_ACTIONS.BASE_PLANS_FAILURE:
    case PLAN_ACTIONS.BASE_PLANS_SUCCESS:
      return { ...state, basePlans: action.payload };

    case PLAN_ACTIONS.ALL_USER_PLANS_REQUEST:
    case PLAN_ACTIONS.ALL_USER_PLANS_FAILURE:
      return { ...state, allUserPlans: { ...state.allUserPlans, ...action.payload } };
    case PLAN_ACTIONS.ALL_USER_PLANS_SUCCESS:
      // If we have pagination, we need to merge state data & payload data
      const newState = {
        ...state,
        allUserPlans: {
          ...state.allUserPlans,
          ...action.payload,
          isLastPage: action.payload?.data?.length < MAX_RESULTS,
        },
      };
      if (action.payload.pageNum && action.payload.pageNum !== 0) {
        newState.allUserPlans.data = [...state.allUserPlans.data, ...action.payload.data];
      }
      return newState;

    case PLAN_ACTIONS.USER_PLANS_REQUEST:
    case PLAN_ACTIONS.USER_PLANS_FAILURE:
    case PLAN_ACTIONS.USER_PLANS_SUCCESS:
    case PLAN_ACTIONS.USER_PLANS_UPDATE:
      return { ...state, userPlans: action.payload };

    case PLAN_ACTIONS.SYNC:
    case PLAN_ACTIONS.SYNC_FAILURE:
    case PLAN_ACTIONS.SYNC_SUCCESS:
      return { ...state, sync: action.payload };

    case PLAN_ACTIONS.SESSION:
    case PLAN_ACTIONS.SESSION_FAILURE:
    case PLAN_ACTIONS.SESSION_SUCCESS:
      return { ...state, session: action.payload };

    case PLAN_ACTIONS.CLEAR:
      return {
        ...state,
        // all: action.payload,
        one: action.payload,
        userPlanAll: action.payload,
        sync: action.payload,
        session: action.payload,
      };

    default:
      return state;
  }
}
