import { put, call, takeLatest, all } from "redux-saga/effects";
import axios from "axios";

import { getApiPath, MAX_RESULTS } from "../../utils/variables";
import { authHeader } from "../store";
import { objectToUrlEncoded } from "../../utils/constants";

import { HCP_ACTIONS, hcpActions } from "../actions";

export let cancelHcpRequests = new AbortController();

function* handleRequest(action) {
  let { term, pageNum } = action.payload;
  const params = objectToUrlEncoded({
    term,
    forIngestion: true,
    pageNum,
    maxResult: MAX_RESULTS,
  });

  try {
    cancelHcpRequests = new AbortController();
    const { data } = yield call(
      axios.get,
      `${getApiPath()}/accounts?type=PERSON&subtype=HCP&${params}`,
      {
        headers: authHeader(),
        signal: cancelHcpRequests.signal,
      }
    );

    yield put(hcpActions.success({ data, pageNum }));
  } catch (e) {
    if (e?.response?.status === 401) {
      yield put((window.location.href = "/logout"));
    }
    yield put(hcpActions.failure({ error: { ...e } }));
  }
}

function* handleRequestOne(action) {
  try {
    cancelHcpRequests = new AbortController();
    const { id } = action.payload;
    const { data } = yield call(
      axios.get,
      `${getApiPath(
        false
      )}/account/${id}?${Date.now()}&relations=false&tags=false`,
      {
        signal: cancelHcpRequests.signal,
      }
    );
    yield put(hcpActions.oneSuccess({ data }));
  } catch (e) {
    yield put(hcpActions.oneFailure({ error: { ...e } }));
  }
}

function* handleTagsRequest(action) {
  try {
    cancelHcpRequests = new AbortController();
    const { id } = action.payload;
    const { data } = yield call(
      axios.get,
      `${getApiPath(false)}/account/${id}/tags`,
      {
        signal: cancelHcpRequests.signal,
      }
    );
    yield put(hcpActions.tagsSuccess({ data }));
  } catch (e) {
    yield put(hcpActions.tagsFailure({ error: { ...e } }));
  }
}

function* watchHcpSagas() {
  yield all([
    takeLatest(HCP_ACTIONS.REQUEST, handleRequest),
    takeLatest(HCP_ACTIONS.ONE_REQUEST, handleRequestOne),
    takeLatest(HCP_ACTIONS.TAGS_REQUEST, handleTagsRequest),
  ]);
}

export default watchHcpSagas;
