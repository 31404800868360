import React from "react";
import moment from "moment";
import Table from "rc-table";
import "../../../components/styles/rc-table.scss";

import {
  Row,
  Column,
  MainWrapper,
  Header,
  Content,
  Card,
  Label,
  Anchor,
  Toolbar,
  Filters,
  Dropdown,
  DateRange,
  AutoComplete,
  Spinner,
  Switch,
  Grid,
} from "../../../components";
import { LineChart } from "../../../components/LineChart";
import { StackedColumnChart } from "../../../components/StackedColumnChart";
import { Menu } from "../Menu";

const getMonitorNotificationInterval = (notificationInterval) => {
  if (notificationInterval === 168) {
    return "Weekly";
  } else if (notificationInterval === 24) {
    return "Daily";
  } else if (notificationInterval === 0) {
    return "Instant";
  } else if (notificationInterval === -1) {
    return "Inactive";
  } else {
    return "";
  }
};

export const view = ({
  infiniteRef,
  hasNextPage,
  loading,
  notifications,
  activeFilters,

  chartLoading,
  timeSegmentedSummary,

  users,
  searchUser,
  selectedUser,
  handleSearchUser,
  addSelectedUser,
  clearUser,
  usersInfiniteScroll,

  notificationTypes,
  selectedNotificationType,
  handleNotificationTypeChange,

  notificationContentTypes,
  selectedNotificationContentType,
  handleNotificationContentTypeChange,

  excludeEmployees,
  setExcludeEmployees,

  totalsInChart,
  setTotalsInChart,

  uniqueUsers,
  setUniqueUsers,

  chartTypes,
  chartType,
  handleChartTypeChange,

  dates,
  handleDateChange,
}) => {
  const columns = [
    {
      title: "User",
      key: "user",
      render: (cell) => (
        <>
          <Label>{cell?.user.displayName || "-"}</Label>
          <br />
          <Label small color="gray" fade="5">({cell?.user.email || "-"})</Label>
          <br />
          <Label small color="gray" fade="5">{cell?.user.userType || "-"}</Label>
        </>
      ),
    },
    {
      title: "Monitor",
      key: "monitor",
      render: (cell) => (
        <Column>
          <Row middle gap="20">
            <Label>
              {cell.notificationMonitor?.monitor?.monitorComponent
                .object1Name || "-"}
            </Label>
            {cell?.notificationTweet?.externalTweetId && (
              <i>
                <Anchor
                  to={`/post/${cell?.notificationTweet?.externalTweetId}`}
                  color="twitter"
                  label="(Tweet)"
                />
              </i>
            )}
          </Row>
          <Row>
            <Label color="gray" fade="5" small>
              {cell.notificationMonitor?.monitor?.monitorComponent
                .object1Type || " "}
            </Label>
          </Row>
          <Label color="gray" fade="5" small>
            {getMonitorNotificationInterval(
              cell.notificationMonitor?.monitor?.notificationInterval
            )}
          </Label>
        </Column>
      ),
    },
    {
      title: "Notification Type",
      key: "notificationType",
      render: (cell) => (
        <Label>{notificationTypes.find(k => k.id === cell.notificationType)?.label}</Label>
      ),
    },
    {
      title: "Notification Content",
      key: "notificationContentType",
      render: (cell) => (
        <Column>
          <Row middle gap="20">
            <Label>
              {notificationContentTypes.find(m => m.id === cell.notificationContentType)?.label}
            </Label>
          </Row>
          {cell.notificationContentMeta && (
            <Row>
              <Anchor
                small
                label="Preview"
                to={`/notauth/mail/${cell.id}`}
                blank
              />
            </Row>
          )}
        </Column>
      ),
    },
    {
      title: "Sent On",
      key: "sentOn",
      render: (cell) => (
        <Label>
          {new Date(cell.notificationSent).toLocaleString("en-US")}
        </Label>
      ),
    },
  ];

  return (
    <MainWrapper drawer>
      <Header
        drawer
        rightContent={
          <>
            <Row fit />
          </>
        }
      />
      <Menu active="notifications" />

      <Content>
        <Column>
          <Column marginBottom>
            <Filters
              {...{ activeFilters }}
              datePicker={
                <Row gap="10" middle>
                  <Label>From </Label>
                  <DateRange
                    noBackground
                    period
                    initialDate={dates.start}
                    endDate={dates.end}
                    onChange={handleDateChange}
                    noFutureDates
                  />
                </Row>
              }
            >
              <Grid columns={4}>
                <Column gap="10">
                  <Label bold>User</Label>
                  <AutoComplete
                    showOnEmptyTerm
                    icon="search"
                    displayTextFromSelected
                    searchResults={users}
                    setSearchTerm={handleSearchUser}
                    searchTerm={searchUser}
                    selectedItems={selectedUser}
                    addItem={addSelectedUser}
                    clearSearchResults={clearUser}
                    hasClearButton
                    infiniteScroll={usersInfiniteScroll}
                  />
                </Column>
                <Column gap="10">
                  <Label bold>Type</Label>
                  <Dropdown
                    expand
                    right
                    select
                    value={selectedNotificationType}
                    onChange={handleNotificationTypeChange}
                    items={notificationTypes}
                  />
                </Column>
                <Column gap="10">
                  <Label bold>Content</Label>
                  <Dropdown
                    expand
                    right
                    select
                    value={selectedNotificationContentType}
                    onChange={handleNotificationContentTypeChange}
                    items={notificationContentTypes}
                  />
                </Column>
                <Column marginTop="10">
                  <Label bold>Exclude Employees</Label>
                  <Switch
                    checked={excludeEmployees}
                    onChange={setExcludeEmployees}
                  />
                </Column>
              </Grid>
            </Filters>
          </Column>

          <Toolbar>
            {dates.start && (
              <Label small color="gray" fade="5">
                {moment(dates.start).utcOffset(0, true).format("M/D/YYYY")}
                {" - "}
                {moment(dates.end).utcOffset(0, true).format("M/D/YYYY")}
              </Label>
            )}
          </Toolbar>

          {timeSegmentedSummary.length > 0 && (
            <Grid columns={3} paddingBottom>
              <Switch
                label="Show Totals"
                right={true}
                checked={totalsInChart}
                onChange={setTotalsInChart}
              />
              <Switch
                label="Show Uniques"
                right={true}
                checked={uniqueUsers}
                onChange={setUniqueUsers}
              />
              <Dropdown
                id="dropdown-chart-types"
                expand
                right
                select
                value={chartType}
                label="Chart"
                items={chartTypes}
                onChange={(e) => handleChartTypeChange(e)}
              />
            </Grid>
          )}

          <Column>
            {timeSegmentedSummary.length > 0 ? (
              <Card border>
                {chartType === 1 && (
                  <LineChart
                    id={`lineChart`}
                    data={timeSegmentedSummary}
                    handleNameClick={() => {}}
                  />
                )}
                {chartType === 2 && (
                  <StackedColumnChart
                    id={`stackedChart`}
                    data={timeSegmentedSummary}
                    handleNameClick={() => {}}
                  />
                )}
              </Card>
            ) : (
              <>
                {chartLoading && (
                  <Row expand paddingAll="80" center>
                    <Spinner />
                  </Row>
                )}
              </>
            )}
          </Column>

          <Column marginTop="20">
            <Table
              rowKey="id"
              data={notifications}
              {...{ columns }}
              style={{ maxWidth: 980 }}
              scroll={{ x: 978 }}
            />
            {loading || hasNextPage ? (
              <Row expand paddingAll="40" center ref={infiniteRef}>
                <Spinner />
              </Row>
            ) : (
              <>
                {notifications?.length === 0 && (
                  <Row marginTop>
                    <Label>No notifications found</Label>
                  </Row>
                )}
              </>
            )}
          </Column>
        </Column>
      </Content>
    </MainWrapper>
  );
};
