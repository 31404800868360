import { useQuery } from "react-query";
import axios from "axios";
import { getApiPath } from "../../utils/variables";
import { authHeader } from "../store";

export function getMonitors(userId) {
  return axios.get(`${getApiPath()}/user/${userId}/monitors`, {
    headers: authHeader(),
  });
}
export function useGetMonitors(userId) {
  return useQuery(["monitors"], () => getMonitors(userId), {
    cacheTime: 0,
  });
}
