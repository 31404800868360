import { put, call, takeLatest, all } from "redux-saga/effects";
import axios from "axios";

import { getApiPath, MAX_RESULTS } from "../../utils/variables";
import { CONFERENCE_ACTIONS, conferenceActions } from "../actions";
import { authHeader } from "../store";
import { objectToUrlEncoded } from "../../utils/constants";

export let cancelConferenceRequests = new AbortController();

function* handleRequest(action) {
  const {
    term,
    parentId,
    onlyParents,
    ignore,
    startDate,
    endDate,
    pageNum,
    maxResult,
    nocache,
    details,
  } = action.payload;
  const params = objectToUrlEncoded({
    term,
    parentId,
    onlyParents,
    ignore,
    startDate,
    endDate,
    pageNum,
    maxResult: maxResult || MAX_RESULTS,
    nocache,
    details,
  });
  try {
    cancelConferenceRequests = new AbortController();
    const { data } = yield call(
      axios.get,
      `${getApiPath()}/conferences?${params}`,
      {
        headers: authHeader(),
        signal: cancelConferenceRequests.signal,
      }
    );
    yield put(conferenceActions.success({ data, pageNum }));
  } catch (e) {
    if (e?.response?.status === 401) {
      yield put((window.location.href = "/logout"));
    }
    yield put(conferenceActions.failure({ error: { ...e } }));
  }
}

function* handleRequestOne(action) {
  try {
    cancelConferenceRequests = new AbortController();
    const { id, nocache } = action.payload;
    const { data } = yield call(
      axios.get,
      `${getApiPath(false)}/conference/${id}?${Date.now()}${
        nocache ? "&nocache=true" : ""
      }`,
      {
        signal: cancelConferenceRequests.signal,
      }
    );
    yield put(conferenceActions.oneSuccess({ data }));
  } catch (e) {
    if (e?.response?.status === 401) {
      yield put((window.location.href = "/logout"));
    }
    yield put(conferenceActions.oneFailure({ error: { ...e } }));
  }
}
function* handleSelected(action) {
  const { monitorStartDate, pageNum, maxResult } = action.payload;

  const params = objectToUrlEncoded({
    monitorStartDate,
    pageNum: 0,
    maxResult: 10,
  });

  try {
    cancelConferenceRequests = new AbortController();
    const { data } = yield call(
      axios.get,
      `${getApiPath(false)}/conferences/selected?${params}`,
      {
        signal: cancelConferenceRequests.signal,
      }
    );
    yield put(conferenceActions.success({ data }));
  } catch (e) {
    // if (e?.response?.status === 401) {
    //   yield put((window.location.href = "/logout"));
    // }
    yield put(conferenceActions.failure({ error: { ...e } }));
  }
}

function* handleDolRequest(action) {
  const {
    id,
    userId,
    startDate,
    endDate,
    accountType,
    accountSubType,
    regionName,
    addTagIds,
    originalOnly,
  } = action.payload;
  let regionList = "";
  if (regionName?.length > 0) {
    regionName.forEach((region) => {
      regionList += `regionName=${region}&`;
    });
  }
  let tagIdList = "";
  if (addTagIds?.length > 0) {
    addTagIds.forEach((tagId) => {
      tagIdList += `addTagId=${tagId}&`;
    });
  }
  const params = objectToUrlEncoded({
    userId,
    startDate,
    endDate,
    accountType: accountType || "PERSON",
    accountSubType: accountSubType,
    originalOnly,
  });

  try {
    cancelConferenceRequests = new AbortController();
    const { data } = yield call(
      axios.get,
      `${getApiPath(
        false
      )}/conference/${id}/dols?${tagIdList}${regionList}${params}`,
      {
        signal: cancelConferenceRequests.signal,
      }
    );
    yield put(conferenceActions.dolSuccess({ data }));
  } catch (e) {
    yield put(conferenceActions.dolFailure({ error: { ...e } }));
  }
}

function* handleCreate(action) {
  const body = action.payload.data;
  const redirect = action.payload.redirect;
  try {
    cancelConferenceRequests = new AbortController();
    const { data } = yield call(
      axios.post,
      `${getApiPath()}/conference`,
      body,
      {
        headers: authHeader(),
        signal: cancelConferenceRequests.signal,
      }
    );
    yield put(conferenceActions.oneSuccess({ data }));
    if (redirect) yield put((window.location.href = redirect));
  } catch (e) {
    yield put(conferenceActions.oneFailure({ error: { ...e } }));
  }
}

function* handleUpdate(action) {
  const body = action.payload.data;
  body.tagId = action.payload.data.tag.id;

  try {
    cancelConferenceRequests = new AbortController();
    const { data } = yield call(axios.put, `${getApiPath()}/conference`, body, {
      headers: authHeader(),
      signal: cancelConferenceRequests.signal,
    });
    yield put(conferenceActions.oneSuccess({ data }));
  } catch (e) {
    yield put(conferenceActions.oneFailure({ error: { ...e } }));
  }
}

function* handleTweetsRequest(action) {
  const {
    id,
    addTagIds,
    authorId,
    startDate,
    endDate,
    withSentiment,
    sentiment,
    sortBy = "score", // 'score' or 'date'
    pageNum,
    diseaseId,
  } = action.payload;
  let tagIdList = "";
  if (addTagIds?.length > 0) {
    addTagIds.forEach((tagId) => {
      tagIdList += `addTagId=${tagId}&`;
    });
  }
  const params = objectToUrlEncoded({
    authorId,
    startDate,
    endDate,
    sortBy,
    withSentiment,
    sentiment,
    // source: 'X',
    pageNum,
    maxResult: MAX_RESULTS,
    diseaseId,
  });
  try {
    cancelConferenceRequests = new AbortController();
    const { data } = yield call(
      axios.get,
      `${getApiPath(false)}/conference/${id}/tweets?${tagIdList}${params}`,
      {
        signal: cancelConferenceRequests.signal,
      }
    );
    yield put(conferenceActions.tweetsSuccess({ data, pageNum }));
  } catch (e) {
    yield put(conferenceActions.tweetsFailure({ error: { ...e } }));
  }
}

function* handleTweetsCountRequest(action) {
  const {
    id,
    addTagIds,
    authorId,
    startDate,
    endDate,
    withSentiment,
    sentiment,
    diseaseId,
  } = action.payload;
  let tagIdList = "";
  if (addTagIds?.length > 0) {
    addTagIds.forEach((tagId) => {
      tagIdList += `addTagId=${tagId}&`;
    });
  }
  const params = objectToUrlEncoded({
    authorId,
    startDate,
    endDate,
    withSentiment,
    sentiment,
    // source: "X",
    diseaseId,
  });
  try {
    cancelConferenceRequests = new AbortController();
    const { data } = yield call(
      axios.get,
      `${getApiPath(
        false
      )}/conference/${id}/tweets/count?${tagIdList}${params}`,
      {
        signal: cancelConferenceRequests.signal,
      }
    );
    yield put(conferenceActions.tweetsCountSuccess({ data }));
  } catch (e) {
    yield put(conferenceActions.tweetsCountFailure({ error: { ...e } }));
  }
}

function* watchConferenceSagas() {
  yield all([
    takeLatest(CONFERENCE_ACTIONS.REQUEST, handleRequest),
    takeLatest(CONFERENCE_ACTIONS.ONE_REQUEST, handleRequestOne),
    takeLatest(CONFERENCE_ACTIONS.SELECTED_REQUEST, handleSelected),
    takeLatest(CONFERENCE_ACTIONS.CREATE, handleCreate),
    takeLatest(CONFERENCE_ACTIONS.UPDATE, handleUpdate),
    takeLatest(CONFERENCE_ACTIONS.TWEETS_REQUEST, handleTweetsRequest),
    takeLatest(
      CONFERENCE_ACTIONS.TWEETS_COUNT_REQUEST,
      handleTweetsCountRequest
    ),
    takeLatest(CONFERENCE_ACTIONS.DOL_REQUEST, handleDolRequest),
  ]);
}

export default watchConferenceSagas;
