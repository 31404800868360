import { createAction, createActionTypes } from "../../utils";

export const DISEASE_ACTIONS = createActionTypes("DISEASE_ACTIONS", [
  "REQUEST",
  "SUCCESS",
  "FAILURE",

  "TAGS_REQUEST",
  "TAGS_SUCCESS",
  "TAGS_FAILURE",

  "ONE_REQUEST",
  "ONE_SUCCESS",
  "ONE_FAILURE",
  "CREATE",
  "UPDATE",
  // "DELETE",
  "CLEAR",

  "CLEAR_TAGS",
  "CLEAR_TWEETS",

  "TWEETS_REQUEST",
  "TWEETS_SUCCESS",
  "TWEETS_FAILURE",

  "TWEETS_COUNT_REQUEST",
  "TWEETS_COUNT_SUCCESS",
  "TWEETS_COUNT_FAILURE",

  "DOL_REQUEST",
  "DOL_SUCCESS",
  "DOL_FAILURE",

  "GPT_REQUEST",
  "GPT_SUCCESS",
  "GPT_FAILURE",
]);

export const diseaseActions = {
  request: (data) =>
    createAction(DISEASE_ACTIONS.REQUEST, {
      ...data,
      fetching: true,
      success: false,
      error: null,
    }),
  success: ({ data, pageNum }) =>
    createAction(DISEASE_ACTIONS.SUCCESS, {
      data,
      pageNum,
      fetching: false,
      success: true,
      error: null,
    }),
  failure: (error) =>
    createAction(DISEASE_ACTIONS.FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  tagsRequest: (data) =>
    createAction(DISEASE_ACTIONS.TAGS_REQUEST, {
      ...data,
      fetching: true,
      success: false,
      error: null,
    }),
  tagsSuccess: ({ data, pageNum }) =>
    createAction(DISEASE_ACTIONS.TAGS_SUCCESS, {
      data,
      pageNum,
      fetching: false,
      success: true,
      error: null,
    }),
  tagsFailure: (error) =>
    createAction(DISEASE_ACTIONS.TAGS_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  oneRequest: (id, nocache) =>
    createAction(DISEASE_ACTIONS.ONE_REQUEST, {
      id,
      nocache,
      fetching: true,
      success: false,
      error: null,
    }),
  oneSuccess: (data) =>
    createAction(DISEASE_ACTIONS.ONE_SUCCESS, {
      ...data,
      fetching: false,
      success: true,
      error: null,
    }),
  oneFailure: (error) =>
    createAction(DISEASE_ACTIONS.ONE_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  create: (data) =>
    createAction(DISEASE_ACTIONS.CREATE, {
      data,
      fetching: true,
      success: false,
      error: null,
    }),
  update: (data) =>
    createAction(DISEASE_ACTIONS.UPDATE, {
      data,
      fetching: true,
      success: false,
      error: null,
    }),
  /*
  delete: (id) =>
    createAction(DISEASE_ACTIONS.DELETE, {
      id,
      fetching: true,
      success: false,
      error: null,
    }),
  */
  clear: () =>
    createAction(DISEASE_ACTIONS.CLEAR, {
      data: [],
      fetching: true,
      success: false,
      error: null,
    }),

  clearTags: () =>
    createAction(DISEASE_ACTIONS.CLEAR_TAGS, {
      data: [],
      fetching: false,
      success: false,
      error: null,
    }),

  clearTweets: () =>
    createAction(DISEASE_ACTIONS.CLEAR_TWEETS, {
      data: [],
      fetching: false,
      success: false,
      error: null,
    }),

  tweetsRequest: (data) =>
    createAction(DISEASE_ACTIONS.TWEETS_REQUEST, {
      ...data,
      fetching: true,
      success: false,
      error: null,
    }),
  tweetsSuccess: ({ data, pageNum }) =>
    createAction(DISEASE_ACTIONS.TWEETS_SUCCESS, {
      data: data,
      pageNum: pageNum,
      fetching: false,
      success: true,
      error: null,
    }),
  tweetsFailure: (error) =>
    createAction(DISEASE_ACTIONS.TWEETS_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  tweetsCountRequest: (data) =>
    createAction(DISEASE_ACTIONS.TWEETS_COUNT_REQUEST, {
      ...data,
      fetching: true,
      success: false,
      error: null,
    }),
  tweetsCountSuccess: ({ data }) =>
    createAction(DISEASE_ACTIONS.TWEETS_COUNT_SUCCESS, {
      data: data,
      fetching: false,
      success: true,
      error: null,
    }),
  tweetsCountFailure: (error) =>
    createAction(DISEASE_ACTIONS.TWEETS_COUNT_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  dolRequest: (data) =>
    createAction(DISEASE_ACTIONS.DOL_REQUEST, {
      ...data,
      fetching: true,
      success: false,
      error: null,
    }),
  dolSuccess: (data) =>
    createAction(DISEASE_ACTIONS.DOL_SUCCESS, {
      ...data,
      fetching: false,
      success: true,
      error: null,
    }),
  dolFailure: (error) =>
    createAction(DISEASE_ACTIONS.DOL_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  gtpRequest: (data) =>
    createAction(DISEASE_ACTIONS.GPT_REQUEST, {
      data,
      fetching: true,
      success: false,
      error: null,
    }),
  gptSuccess: (data) =>
    createAction(DISEASE_ACTIONS.GPT_SUCCESS, {
      ...data,
      fetching: false,
      success: true,
      error: null,
    }),
  gptFailure: (error) =>
    createAction(DISEASE_ACTIONS.GPT_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),
};
