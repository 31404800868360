import React from "react";
import { Link } from "react-router-dom";

import {
  Row,
  Column,
  MainWrapper,
  Header,
  Content,
  Label,
  ResponsiveButton,
  Input,
  List,
  ListItem,
  Dropdown,
  Grid,
  Spinner,
  Filters,
  Icon,
} from "../../../components";

import { Menu } from "../Menu";

const getColor = (tag) => {
  return tag.alwaysIgnore ? "gray" : "black";
};

export const view = ({
  conferences,
  infiniteScrollRef,
  hasNextPage,
  loading,
  searchTerm,
  setSearchTerm,
  activeFilters,
  selectedStatusId,
  handleStatusChange,
  statusItems,
}) => {
  return (
    <MainWrapper drawer>
      <Header
        drawer
        rightContent={
          <>
            <Row fit />
            <ResponsiveButton label="Add congress" to="/newConference" />
          </>
        }
      />
      <Menu active="conferences" />

      <Content>
        <Column>
          <Filters {...{ activeFilters }}>
            <Grid>
              <Column gap="10">
                <Label bold>Name</Label>
                <Input
                  small
                  placeholder="Search"
                  icon="search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </Column>
              <Column gap="10">
                <Label bold>Status</Label>
                <Dropdown
                  right
                  select
                  expand
                  value={selectedStatusId}
                  onChange={handleStatusChange}
                  items={statusItems}
                />
              </Column>
            </Grid>
          </Filters>

          <Column marginTop="10">
            <List>
              <ListItem>
                <Row fit style={{ width: "300px" }}>
                  <Label color="gray" fade="5" bold>
                    Name
                  </Label>
                </Row>
                <Row paddingLeft center style={{ width: "200px" }}>
                  <Label color="gray" fade="5" bold>
                    Created At
                  </Label>
                </Row>
                <Row paddingLeft center style={{ width: "200px" }}>
                  <Label color="gray" fade="5" bold>
                    Tag Created At
                  </Label>
                </Row>
                <Row paddingLeft center style={{ width: "70px" }}>
                  <Label color="gray" fade="5" bold>
                    Match
                  </Label>
                </Row>
                <Row paddingLeft center style={{ width: "50px" }}>
                  <Label color="gray" fade="5" bold>
                    Posts
                  </Label>
                </Row>
                {/* <Row paddingLeft right style={{ width: "50px" }}>
                    <Label color="gray" fade="3" bold>
                      Child
                    </Label>
                  </Row> */}
              </ListItem>
              {conferences.map((item) => (
                <Link key={item.id} to={`/editConference/${item.id}`}>
                  <ListItem>
                    <Row fit style={{ width: "300px" }}>
                      <Column paddingY="5">
                        <Row>
                          <Label color={getColor(item.tag)}>
                            {item.primaryName}
                          </Label>
                        </Row>
                        {!_.isEmpty(item.associatedConferences) && (
                          <Row gap="10">
                            <Label small bold color="gray" fade="5">
                              {item.associatedConferences.replaceAll(",", ", ")}
                            </Label>
                          </Row>
                        )}
                        {!_.isEmpty(item.substitutedTagNames) && (
                          <Row gap="10">
                            <Label small color="green" fade="6">
                              {item.substitutedTagNames}
                            </Label>
                          </Row>
                        )}
                      </Column>
                    </Row>
                    <Row paddingLeft center style={{ width: "200px" }}>
                      <Label color="gray" fade="3">
                        {new Date(item.createdAt).toLocaleString(
                          "en-US",
                          { timeZone: "UTC" } // remove this if we want in local timezone
                        )}
                      </Label>
                    </Row>
                    <Row paddingLeft center style={{ width: "200px" }}>
                      <Label color="gray" fade="3">
                        {new Date(item.tag.createdAt).toLocaleString(
                          "en-US",
                          { timeZone: "UTC" } // remove this if we want in local timezone
                        )}
                      </Label>
                    </Row>
                    <Row paddingLeft center style={{ width: "70px" }}>
                      {item.tag.alwaysMatch ? (
                        <Icon size="30" name="check" color="green" />
                      ) : (
                        <Icon size="20" name="close" color="red" />
                      )}
                    </Row>
                    <Row paddingLeft center style={{ width: "50px" }}>
                      {item.tag.count && item.tag.count !== -1 ? (
                        <Label
                          color={item.tag?.alwaysIgnore ? "gray" : "black"}
                        >
                          {item.tag.count.toLocaleString()}
                        </Label>
                      ) : (
                        <Label
                          color={item.tag?.alwaysIgnore ? "gray" : "black"}
                        >
                          {" "}
                          -{" "}
                        </Label>
                      )}
                    </Row>
                    {/* <Row paddingLeft right style={{ width: "50px" }}>
                        {item.associatedConferences ? (
                          <Label fade="4">
                            {`(${item.associatedConferences.split(",").length})`}
                          </Label>
                        ) : (
                          <Label fade="4">
                            {`(0)`}
                          </Label>
                        )}
                      </Row> */}
                  </ListItem>
                </Link>
              ))}
            </List>
            {loading || hasNextPage ? (
              <Row expand paddingAll="40" center ref={infiniteScrollRef}>
                <Spinner />
              </Row>
            ) : (
              <>
                {conferences.length == 0 && (
                  <List center>
                    <ListItem>No conferences found</ListItem>
                  </List>
                )}
              </>
            )}
          </Column>
        </Column>
      </Content>
    </MainWrapper>
  );
};
