import { ACCOUNT_ACTIONS } from "../actions";
import { MAX_RESULTS } from "../../utils/variables";

const initialState = {
  all: { data: [], fetching: false, success: false, error: null },
  one: { data: [], fetching: false, success: false, error: null },
  count: { data: [], fetching: false, success: false, error: null },
  relationsTo: { data: [], fetching: false, success: false, error: null },
  relationsFrom: { data: [], fetching: false, success: false, error: null },
  products: { data: [], fetching: false, success: false, error: null },
  relationship: { data: [], fetching: false, success: false, error: null },
  gpt: { data: [], fetching: false, success: false, error: null },
};

export default function accountReducer(state = initialState, action) {
  switch (action.type) {
    case ACCOUNT_ACTIONS.REQUEST:
    case ACCOUNT_ACTIONS.FAILURE:
      return { ...state, all: { ...state.all, ...action.payload } };
    case ACCOUNT_ACTIONS.SUCCESS:
      // If we have pagination, we need to merge state data & payload data
      const newState = {
        ...state,
        all: {
          ...state.all,
          ...action.payload,
          isLastPage: action.payload.data.length < MAX_RESULTS,
        },
      };
      if (action.payload.pageNum && action.payload.pageNum !== 0) {
        newState.all.data = [...state.all.data, ...action.payload.data];
      }
      return newState;

    case ACCOUNT_ACTIONS.COUNT_REQUEST:
    case ACCOUNT_ACTIONS.COUNT_SUCCESS:
    case ACCOUNT_ACTIONS.COUNT_FAILURE:
      return {
        ...state,
        count: { ...state.count, ...action.payload },
      };

    case ACCOUNT_ACTIONS.RELATIONS_TO_REQUEST:
    case ACCOUNT_ACTIONS.RELATIONS_TO_FAILURE:
      return {
        ...state,
        relationsTo: { ...state.relationsTo, ...action.payload },
      };
    case ACCOUNT_ACTIONS.RELATIONS_TO_SUCCESS:
      // If we have pagination, we need to merge state data & payload data
      const newState1 = {
        ...state,
        relationsTo: {
          ...state.relationsTo,
          ...action.payload,
          isLastPage: action.payload.data.length < MAX_RESULTS,
        },
      };
      if (action.payload.pageNum && action.payload.pageNum !== 0) {
        newState1.relationsTo.data = [
          ...state.relationsTo.data,
          ...action.payload.data,
        ];
      }
      return newState1;

    case ACCOUNT_ACTIONS.RELATIONS_FROM_REQUEST:
    case ACCOUNT_ACTIONS.RELATIONS_FROM_FAILURE:
      return {
        ...state,
        relationsFrom: { ...state.relationsFrom, ...action.payload },
      };
    case ACCOUNT_ACTIONS.RELATIONS_FROM_SUCCESS:
      // If we have pagination, we need to merge state data & payload data
      const newState2 = {
        ...state,
        relationsFrom: {
          ...state.relationsFrom,
          ...action.payload,
          isLastPage: action.payload.data.length < MAX_RESULTS,
        },
      };
      if (action.payload.pageNum && action.payload.pageNum !== 0) {
        newState2.relationsFrom.data = [
          ...state.relationsFrom.data,
          ...action.payload.data,
        ];
      }
      return newState2;

    case ACCOUNT_ACTIONS.PRODUCTS_REQUEST:
    case ACCOUNT_ACTIONS.PRODUCTS_SUCCESS:
    case ACCOUNT_ACTIONS.PRODUCTS_FAILURE:
      return {
        ...state,
        products: { ...state.products, ...action.payload },
      };

    case ACCOUNT_ACTIONS.ONE_REQUEST:
    case ACCOUNT_ACTIONS.ONE_SUCCESS:
    case ACCOUNT_ACTIONS.ONE_FAILURE:
    case ACCOUNT_ACTIONS.CREATE:
    case ACCOUNT_ACTIONS.CREATE_SUCCESS:
    case ACCOUNT_ACTIONS.CREATE_FAILURE:
    case ACCOUNT_ACTIONS.CLEAR:
      return {
        ...state,
        one: {
          ...state.one,
          ...action.payload,
        },
        // all: action.payload,
        // relationsTo: action.payload,
        // relationsFrom: action.payload,
        // products: action.payload,
        // gpt: action.payload,
      };

    case ACCOUNT_ACTIONS.UPDATE:
    case ACCOUNT_ACTIONS.DELETE:
    case ACCOUNT_ACTIONS.UPDATE_SUCCESS:
    case ACCOUNT_ACTIONS.UPDATE_FAILURE:
      return { ...state, one: action.payload };

    case ACCOUNT_ACTIONS.ADD_RELATIONSHIP:
    case ACCOUNT_ACTIONS.DELETE_RELATIONSHIP:
    case ACCOUNT_ACTIONS.RELATIONSHIP_SUCCESS:
    case ACCOUNT_ACTIONS.RELATIONSHIP_FAILURE:
      return {
        ...state,
        relationship: { ...state.relationship, ...action.payload },
      };

    case ACCOUNT_ACTIONS.GPT_REQUEST:
    case ACCOUNT_ACTIONS.GPT_SUCCESS:
    case ACCOUNT_ACTIONS.GPT_FAILURE:
    case ACCOUNT_ACTIONS.GPT_CLEAR:
      return {
        ...state,
        gpt: { ...state.gpt, ...action.payload },
      };

    default:
      return state;
  }
}
