import { useQuery } from "react-query";
import axios from "axios";
import { getApiPath } from "../../utils/variables";

export function getChannel(id) {
  return axios.get(`${getApiPath(false)}/channel/${id}`, {});
}
export function useGetChannel(id) {
  return useQuery(["ytChannel"], () => getChannel(id), {
    cacheTime: 0,
  });
}

export function getVideo(id) {
  if (id.includes("YT_")) {
    return axios.get(`${getApiPath(false)}/video/${id}`, {});
  } else {
    return Promise.resolve({ data: { data: {} } });
  }
}
export function useGetVideo(id) {
  return useQuery(["ytVideo"], () => getVideo(id), {
    cacheTime: 0,
  });
}
