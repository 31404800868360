import { AI_ACTIONS } from "../actions";

const initialState = {
  one: { data: [], fetching: false, success: false, error: null },
  product: { data: [], fetching: false, success: false, error: null },
  disease: { data: [], fetching: false, success: false, error: null },
  update: { fetching: false, success: false, error: null },
  chat: [
    {
      id: 1,
      text: "What are the most discussed research papers in NSCLC right now?",
    },
    { id: 2, text: "What are physician's perceptions of Keytruda at the moment?" },
    {
      id: 3,
      text: "Create a compelling social media post highlighting a groundbreaking breast cancer treatment study.",
    },
  ],
};

export default function aiReducer(state = initialState, action) {
  switch (action.type) {
    case AI_ACTIONS.ONE_REQUEST:
    case AI_ACTIONS.ONE_SUCCESS:
    case AI_ACTIONS.ONE_FAILURE:
      return { ...state, one: { ...state.one, ...action.payload } };

    case AI_ACTIONS.PRODUCT_REQUEST:
    case AI_ACTIONS.PRODUCT_SUCCESS:
    case AI_ACTIONS.PRODUCT_FAILURE:
      return { ...state, product: { ...state.product, ...action.payload } };

    case AI_ACTIONS.DISEASE_REQUEST:
    case AI_ACTIONS.DISEASE_SUCCESS:
    case AI_ACTIONS.DISEASE_FAILURE:
      return { ...state, disease: { ...state.disease, ...action.payload } };

    case AI_ACTIONS.UPDATE_SUCCESS:
    case AI_ACTIONS.UPDATE_FAILURE:
      return { ...state, update: { ...state.update, ...action.payload } };

    case AI_ACTIONS.CHAT:
      return { ...state, chat: action.payload.data };

    default:
      return state;
  }
}
