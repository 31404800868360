import { loadStripe } from "@stripe/stripe-js/pure";

import { useEffect } from "react";

export const Checkout = () => {
  const origin = window.location.origin;
  let stripePromise;
  const getStripe = () => {
    if (!stripePromise) {
      loadStripe.setLoadParameters({ advancedFraudSignals: false });
      stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
    }
    return stripePromise;
  };

  const checkoutOptions = {
    lineItems: [{ price: "price_1NQwPF2VvAxwgXysIaWfXqyu", quantity: 1 }],
    mode: "subscription",
    successUrl: `${origin}/checkoutSuccess?success=true&session_id={CHECKOUT_SESSION_ID}`,
    cancelUrl: `${origin}/checkoutCancel?canceled=true`,
  };

  const redirectToSrtipe = async () => {
    const stripe = await getStripe();
    await stripe.redirectToCheckout(checkoutOptions);
    const { error } = await stripe.redirectToCheckout(checkoutOptions);
    console.log("error :>> ", error);
  };

  useEffect(() => {
    redirectToSrtipe();
  }, []);

  return null;
};
