import { put, call, takeLatest, all } from "redux-saga/effects";
import axios from "axios";

import { getApiPath } from "../../utils/variables";
import { authHeader } from "../store";
import { utf8_to_b64 } from "../../utils";
import { AUTH_ACTIONS, authActions } from "../actions";

export let cancelAuthRequests = new AbortController();

// function* handleRequest(action) {
//   const { userName, password, lastLocation } = action.payload.data;
//   const encodedData = utf8_to_b64(`${userName}:${password}`);

//   const headers = {
//     Authorization: `Basic ${encodedData}`,
//   };

//   try {
//     cancelAuthRequests = new AbortController();
//     const response = yield call(axios.get, `${getApiPath()}/login`, {
//       headers,
//       signal: cancelAuthRequests.signal,
//     });
//     const data = {
//       token: response.headers["authentication-info"],
//     };
//     yield put(authActions.success({ data }));
//     yield put(
//       authActions.userRequest({
//         data: { userId: response.headers.userid, lastLocation },
//       })
//     );
//   } catch (e) {
//     // if (e?.response?.status === 401) {
//     //   yield put((window.location.href = "/logout"));
//     // }
//     yield put(authActions.failure({ error: e }));
//   }
// }

function* handleFormBasedAuthRequest(action) {
  const { userName, password, lastLocation } = action.payload.data;
  try {
    cancelAuthRequests = new AbortController();
    const response = yield call(
      axios.post,
      `${getApiPath()}/login`,
      `username=${encodeURIComponent(userName)}&password=${encodeURIComponent(
        password
      )}`,
      {
        signal: cancelAuthRequests.signal,
      }
    );
    const data = {
      token: response.headers["authentication-info"],
    };
    yield put(authActions.success({ data }));
    yield put(
      authActions.userRequest({
        data: { userId: response.headers.userid, lastLocation },
      })
    );
  } catch (e) {
    // if (e?.response?.status === 401) {
    //   yield put((window.location.href = "/logout"));
    // }
    yield put(authActions.failure({ error: e }));
  }
}

function* handleUserRequest(action) {
  try {
    cancelAuthRequests = new AbortController();
    const { userId, lastLocation, target } = action.payload.data;

    const { data } = yield call(
      axios.get,
      `${getApiPath()}/user/${userId}?${Date.now()}`,
      {
        headers: authHeader(),
        signal: cancelAuthRequests.signal,
      }
    );
    yield put(authActions.userSuccess({ data }));
    if (target) {
      yield put((window.location = target));
    } else if (lastLocation !== "current") {
      yield put((window.location = lastLocation || "/"));
    }
  } catch (e) {
    yield put(authActions.userFailure({ error: { ...e } }));
  }
}

function* handleTokenRequest(action) {
  try {
    cancelAuthRequests = new AbortController();
    const { token, userId } = action.payload.data;
    const { data } = yield call(
      axios.post,
      `${getApiPath(false)}/user/validateToken`,
      `token=${token}&userId=${userId}`,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        signal: cancelAuthRequests.signal,
      }
    );
    yield put(authActions.tokenSuccess({ data }));
  } catch (e) {
    yield put(authActions.tokenFailure({ error: { ...e } }));
  }
}

export function expireToken({ userId }) {
  return axios.get(`${getApiPath()}/user/${userId}/logout`, {
    headers: authHeader(),
  });
}

function* watchAuthSagas() {
  yield all([
    takeLatest(AUTH_ACTIONS.REQUEST, handleFormBasedAuthRequest),
    takeLatest(AUTH_ACTIONS.USER_REQUEST, handleUserRequest),
    takeLatest(AUTH_ACTIONS.TOKEN_REQUEST, handleTokenRequest),
  ]);
}

export default watchAuthSagas;
