import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { Label } from "./Typography";

const TooltipContainer = styled.div`
  position: absolute;
  top: ${({ top }) => (top ? top + "px" : "0")};
  ${({ right }) => (right ? "right: 0" : "left: 0")};
  display: block;
  background: black;
  border-radius: 6px;
  color: white;
  display: flex;
  ${({ width }) =>
    width ? `width: ${width}px` : "width: auto; white-space: nowrap;"};
  word-wrap: no-wrap;
  padding: 10px;
  transform: translateY(40px);
  opacity: 0;
  z-index: 11;
  max-height: 290px;
  overflow: auto;
  pointer-events: none;
  margin-top: 10px;
  ${({ hide }) => !hide && `transition: all 0.2s;`}

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const Container = styled.div`
  position: relative;
  margin-bottom: -20px;
  padding-bottom: 20px;
  ${({ hide }) =>
    !hide &&
    css`
      &:hover ${TooltipContainer} {
        pointer-events: all;
        opacity: 1;
        transform: translateY(25px);
      }
    `};
`;

const Children = styled.div`
  cursor: pointer;
`;

export const Tooltip = (props) => {
  return (
    <Container hide={props.hide}>
      <Children>{props.children}</Children>
      <TooltipContainer {...props}>
        {props.content || <Label color="white">{props.label}</Label>}
      </TooltipContainer>
    </Container>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node,
  content: PropTypes.node,
  label: PropTypes.string,
  top: PropTypes.string,
  right: PropTypes.bool,
  center: PropTypes.bool,
  width: PropTypes.string,
  hide: PropTypes.bool,
};
