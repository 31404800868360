import { createAction, createActionTypes } from "../../utils";

export const NAV_ACTIONS = createActionTypes("NAV_ACTIONS", ["SET"]);

export const navActions = {
  set: (data) =>
    createAction(NAV_ACTIONS.SET, {
      data,
    }),
};
