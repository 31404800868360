import { put, call, takeLatest, all } from "redux-saga/effects";
import axios from "axios";

import { getApiPath } from "../../utils/variables";
import { authHeader } from "../store";
import { objectToUrlEncoded } from "../../utils/constants";
import { NOTIFICATION_ACTIONS, notificationActions } from "../actions";

export let cancelNotificationRequests = new AbortController();

/**
 * get notification list
 */
function* handleRequest(action) {
  try {
    cancelNotificationRequests = new AbortController();
    const {
      recipientId,
      objectType,
      notificationType,
      notificationContentType,
      excludeEmployees,
      startDate,
      endDate,
      maxResult,
      pageNum,
    } = action.payload;
    const params = objectToUrlEncoded({
      recipientId,
      objectType,
      notificationType,
      notificationContentType,
      excludeEmployees,
      startDate,
      endDate,
      maxResult: maxResult || -1,
      pageNum,
    });
    const { data } = yield call(
      axios.get,
      `${getApiPath()}/notifications?${params}`,
      {
        headers: authHeader(),
        signal: cancelNotificationRequests.signal,
      }
    );
    yield put(notificationActions.success({ data, pageNum }));
  } catch (e) {
    if (e?.response?.status === 401) {
      yield put((window.location.href = "/logout"));
    }
    yield put(notificationActions.failure({ error: { ...e } }));
  }
}

function* handleSendNotification(action) {
  try {
    cancelNotificationRequests = new AbortController();
    const {
      userId,
      objectTypes,
      notificationType,
      // startDate,
      // endDate,
      intervals,
    } = action.payload.data;

    let objectTypeList = "";
    if (objectTypes?.length > 0) {
      objectTypes.forEach((objectType) => {
        objectTypeList += `objectType=${objectType}&`;
      });
    }

    let intervalList = "";
    if (intervals?.length > 0) {
      intervals.forEach((interval) => {
        intervalList += `interval=${interval}&`;
      });
    }

    const params = objectToUrlEncoded({
      notificationType,
      // startDate,
      // endDate,
    });

    const { data } = yield call(
      axios.get,
      `${getApiPath()}/notification/send?userId=${userId}&${objectTypeList}${intervalList}${params}`,
      {
        headers: authHeader(),
        signal: cancelNotificationRequests.signal,
      }
    );
    yield put(notificationActions.sendSuccess({ data }));
  } catch (e) {
    yield put(notificationActions.sendFailure({ error: { ...e } }));
  }
}

export function getNotificationSummary({
  recipientId,
  notificationType,
  notificationContentType,
  excludeEmployees,
  uniqueUsers,
  startDate,
  endDate,
  segment,
}) {
  const params = objectToUrlEncoded({
    recipientId,
    notificationType,
    notificationContentType,
    excludeEmployees,
    uniqueUsers,
    startDate,
    endDate,
    segment,
  });
  return axios.get(`${getApiPath()}/notification/summary?${params}`, {headers: authHeader()});
};

export function getNotificationEventSummary({
  eventType,
  category,
  excludeEmployees,
  uniqueUsers,
  startDate,
  endDate,
  segment,
}) {
  const params = objectToUrlEncoded({
    eventType,
    category,
    excludeEmployees,
    uniqueUsers,
    startDate,
    endDate,
    segment,
  });
  return axios.get(`${getApiPath()}/notification/eventSummary?${params}`, {headers: authHeader()});
};

export function getNotificationEvents({
  eventType,
  excludeEmployees,
  startDate,
  endDate,
}) {
  const params = objectToUrlEncoded({
    eventType,
    excludeEmployees,
    startDate,
    endDate,
  });
  return axios.get(`${getApiPath()}/notification/events?${params}`, {headers: authHeader()});
};

function* watchNotificationSagas() {
  yield all([
    takeLatest(NOTIFICATION_ACTIONS.REQUEST, handleRequest),
    takeLatest(NOTIFICATION_ACTIONS.SEND_REQUEST, handleSendNotification),
  ]);
}

export default watchNotificationSagas;
