import React from "react";
import { Link } from "react-router-dom";
import { formatMonthDayDates, formatNumberWithCommas } from "../../../utils";
import { Footer, Subscribe } from "../components";

import {
  Row,
  MainWrapper,
  Header,
  Content,
  Label,
  Column,
  Spinner,
  H4,
  HR,
  Sentiment,
  Card,
  Avatar,
  TweetCard,
} from "../../../components";

const getSentiment = (tweet, product) => {
  if (tweet.tags.length === 0) return null;
  const tagAssoc = tweet.tags.filter((t) => t.productId === product.id);
  if (tagAssoc.length > 0 && tagAssoc[0].sentimentAverage != null)
    return tagAssoc[0].sentimentAverage;
  return null;
};

export const view = ({
  nId,
  conference,
  name,
  userType,
  loading,
  dates,
  product,
  tweets,
}) => {
  return (
    <MainWrapper>
      <Header logo login user></Header>
      {nId ? (
        <Content noScroll paddingAll="0">
          <iframe
            style={{
              border: "0",
              height: "1000px",
              width: "100%",
              borderRadius: "10px",
              background: "transparent",
            }}
            src={`${window.location.origin}/notauth/mail/${nId}`}
          />
        </Content>
      ) : (
        <Content>
          <Column center>
            <Column maxWidth="600">
              <Column style={{ verticalAlign: "top" }} marginTop="10">
                <Subscribe />
                <Column marginBottom="30" marginTop="10" stretch gap="20">
                  {name && (
                    <Label>
                      Hi{name ? ` ${decodeURIComponent(name)}` : ""},
                    </Label>
                  )}
                  {conference && (
                    <Label>
                      During{" "}
                      <Label bold>{decodeURIComponent(conference)}</Label>,
                      you'll receive a daily digest when physicians mention{" "}
                      <Label bold>{product.primaryName}</Label> on Twitter.
                    </Label>
                  )}
                  {dates.start ? (
                    <Label>
                      Here are the posts
                      {product?.primaryName && ` for ${product.primaryName}`}
                      {" from "}
                      {formatMonthDayDates(dates)}
                      {" : "}
                    </Label>
                  ) : (
                    <Column gap="20">
                      <Label>
                        Welcome to Medical.watch Alerts
                        {product?.primaryName && ` for ${product.primaryName}.`}
                      </Label>
                      <Label>Here are the latest tweets from physicians:</Label>
                    </Column>
                  )}
                </Column>
                {tweets.map((t, index) => (
                  <Column
                    stretch
                    id={`tweet-${t.externalId}`}
                    key={t.externalId}
                    marginBottom
                  >
                    {index !== 0 && (
                      <Row marginBottom="30" marginTop="5">
                        <HR />
                      </Row>
                    )}

                    <Column marginBottom>
                      <Link to={`/post/${t.externalId}?source=email`}>
                        <TweetCard data={t} />
                        {/* 
                            {t?.medias?.length > 0 && (
                              <Row marginTop="10">
                                <TweetMedia medias={t.medias} grid />
                              </Row>
                            )}
                            {t?.quoted?.length > 0 && (
                              <Column>
                                <Row flexWrap expand>
                                  {t?.quoted.map((i) => (
                                    <Tweet
                                      id={`quoted-tweet-${t.externalId}`}
                                      key={i.externalId}
                                      data={i}
                                    />
                                  ))}
                                </Row>
                              </Column>
                            )} 
                          </TweetCard>
                            */}
                      </Link>
                    </Column>

                    <Row marginBottom gap="10">
                      <Row fit>
                        <Card border>
                          <Row marginBottom="10">
                            <Label>Sentiment</Label>
                          </Row>
                          <Row>
                            <Sentiment value={getSentiment(t, product)} />
                          </Row>
                        </Card>
                      </Row>
                      <Row fit>
                        <Card border>
                          <Row marginBottom="10">
                            <Label>Views</Label>
                          </Row>
                          <Row gap="10">
                            <Label bold>
                              {formatNumberWithCommas(t.numViews)}
                            </Label>
                            {t.numViews > 0 && (
                              <>
                                {t.numViews >= 15000 && (
                                  <Row gap="5" middle>
                                    <Label>🚀</Label>
                                    <Label bold>Excellent</Label>
                                  </Row>
                                )}
                                {t.numViews < 15000 && t.numViews >= 5000 && (
                                  <Row gap="5" middle>
                                    <Label>😎</Label>
                                    <Label bold>Good</Label>
                                  </Row>
                                )}
                                {t.numViews < 5000 && t.numViews >= 1000 && (
                                  <Row gap="5" middle>
                                    <Label>😐</Label>
                                    <Label bold>Fair</Label>
                                  </Row>
                                )}
                                {t.numViews < 1000 && (
                                  <Row gap="5" middle>
                                    <Label>😔</Label>
                                    <Label bold>Poor</Label>
                                  </Row>
                                )}
                              </>
                            )}
                          </Row>
                        </Card>
                      </Row>
                    </Row>

                    <Column bkg="gray" fade="1" paddingX paddingY radius="10">
                      <Row gap="10" middle>
                        <Avatar
                          large
                          imageUrl={t.tAccount.imageURL}
                          name={t.tAccount.name}
                        />
                        <Column gap="5">
                          <Row middle>
                            <H4 large bold>
                              {t.tAccount.name}
                            </H4>
                          </Row>
                          <Row>
                            <Link
                              id={`tweet-account-image-${t.tAccount.id}`}
                              to={`/account/${t.tAccount.id}`}
                            >
                              {`@${t.tAccount.username}`}
                            </Link>
                          </Row>
                        </Column>
                      </Row>

                      <Column gap="5">
                        <Label>{t.tAccount.location}</Label>
                        <Label color="gray" fade="6">
                          {t.tAccount.description}
                        </Label>
                      </Column>
                    </Column>
                  </Column>
                ))}

                {loading && (
                  <Row expand paddingTop marginBottom="60" center>
                    <Spinner />
                  </Row>
                )}

                {tweets.length === 0 && !loading && (
                  <Row expand paddingTop paddingBottom="50" center>
                    <Label large color="gray" fade="6">
                      No posts found
                    </Label>
                  </Row>
                )}
              </Column>
            </Column>
            <Footer
              first="Share it with a colleague. "
              seeMore={`See more ${
                userType !== "MEDICAL" ? "brand" : ""
              } insights ${product?.primaryName ? " for " : ""}${
                product?.primaryName || ""
              }.`}
            />
          </Column>
        </Content>
      )}
    </MainWrapper>
  );
};
