import React from "react";
import { Label, Column, Anchor } from "../../../components";

export const Subscribe = () => (
  <Column
    marginTop="10"
    bkg="green"
    fade="2"
    paddingY="10"
    paddingX
    marginBottom="30"
    radius="5"
  >
    <Label large>
      Email forwarded to you?{" "}
      <Anchor
        inline
        line
        bold
        large
        color="black"
        label="Subscribe here"
        to="https://my.medical.watch/pricing"
        blank
      />
    </Label>
  </Column>
);
