import React from "react";
import {
  Row,
  Column,
  Content,
  Dropdown,
  H3,
  H4,
  Label,
  Spinner,
  AutoCompleteWithResponses,
  Pill,
  MainWrapper,
  Header,
  Menu,
  Filters,
  NoContent,
  Grid,
  Toast,
  Metadata,
} from "../../components";
import { Chart } from "./Chart";
import { Highlights } from "./Highlights";
import { TopTweets } from "./TopTweets";

export const view = ({
  handleSignUpClick,
  contentRef,
  selectedMonitor,
  loading,
  isReady,
  activeFilters,
  conferenceName,
  conferences,
  selectedConference,
  conferenceTweets,
  conferenceTweetsRef,
  conferenceNextPage,
  handleConferenceChange,
  timeSegmentedData,
  highlights,
  highlightsLoading,
  counter,
  tweetsReady,
  activeTab,
  tabOnChange,
  // associatedDiseases,
  // selectedAssociatedDisease,
  // handleAssociatedDiseaseChange,
  tags,
  searchTag,
  selectedTags,
  handleSearchTag,
  addSelectedTag,
  removeSelectedTag,
  clearTag,
  tagsInfinteScroll,
  handleClearFilters,
  allKeywords,
  sortBy,
  handleSortByChange,
  filterTip,
  closeFilterTip,
  showToast,
  toggleToast,
}) => (
  <MainWrapper drawer className="dashboard">
    <Header
      search
      isHome
      drawer
      login
      user
      signUpClick={handleSignUpClick}
      source="conference"
    />
    <Menu
      {...{
        selectedMonitor,
        activeTab,
        tabOnChange,
      }}
    />

    <Content ref={contentRef}>
      <Metadata title={selectedMonitor.label || conferenceName} />
      <Column>
        <Row marginBottom="5" marginRight style={{ minHeight: "20px" }}>
          <H3 bold>{selectedMonitor.label || conferenceName}</H3>
        </Row>
        <Row marginTop="10" marginBottom>
          <Label color="gray" fade="7">
            Discover what physicians are saying on social media
          </Label>
        </Row>

        <Column marginBottom>
          <Filters
            {...{ counter, activeFilters }}
            onClearFilters={handleClearFilters}
            footer={
              selectedTags.length > 0 && (
                <Row flexWrap marginTop="10" marginLeft>
                  {selectedTags.map((t) => (
                    <Pill
                      key={t.id}
                      bkg="green"
                      fade="2"
                      label={t.label}
                      close
                      onClose={() => removeSelectedTag({ id: t.id })}
                    />
                  ))}
                </Row>
              )
            }
          >
            <Grid columns={3}>
              <Column gap="10">
                <Label bold>Keywords</Label>
                <AutoCompleteWithResponses
                  showOnEmptyTerm
                  searchResults={tags}
                  icon="search"
                  placeholder="Biomarker or other"
                  searchTerm={searchTag}
                  setSearchTerm={handleSearchTag}
                  selectedItems={selectedTags}
                  addItem={addSelectedTag}
                  removeItem={removeSelectedTag}
                  clearSearchResults={clearTag}
                  searchLoading={false}
                  infiniteScroll={tagsInfinteScroll}
                  hidePills
                  hasClearButton
                  showSuggestion
                />
              </Column>
              {selectedConference !== "" && conferences.length > 1 && (
                <Column gap="10">
                  <Label bold>Name</Label>
                  <Dropdown
                    expand
                    id="dropdown-conferences"
                    select
                    value={selectedConference}
                    items={conferences}
                    onChange={handleConferenceChange}
                  />
                </Column>
              )}
              {/* {selectedConference !== "" && associatedDiseases.length > 1 && (
                <Column gap="10">
                  <Label bold>Disease</Label>
                  <Dropdown
                    expand
                    id="dropdown-diseases"
                    select
                    value={selectedAssociatedDisease}
                    items={associatedDiseases}
                    onChange={handleAssociatedDiseaseChange}
                  />
                </Column>
              )} */}
            </Grid>
          </Filters>
        </Column>

        {/* {conferences.length <= 1 && associatedDiseases.length <= 1 && (
          <Row marginTop="-20" />
        )} */}

        <Column>
          {activeTab === "tweets" && (
            <Column marginTop>
              {loading ? (
                <Row expand paddingAll="80" center>
                  <Spinner />
                </Row>
              ) : (
                <>
                  {conferenceTweets.length > 0 ? (
                    <Column>
                      <Row marginY>
                        <H4 bold>Posts</H4>
                      </Row>
                      <TopTweets
                        source={selectedMonitor.type.toLowerCase()}
                        tweets={conferenceTweets}
                        tweetsRef={conferenceTweetsRef}
                        hasNextPage={conferenceNextPage}
                        keywords={allKeywords}
                        onClearFilters={handleClearFilters}
                        {...{
                          loading,
                          isReady,
                          sortBy,
                          handleSortByChange,
                        }}
                      />
                    </Column>
                  ) : (
                    <>
                      {tweetsReady && (
                        <NoContent
                          // onDateClick={() => setShowCalendar(true)}
                          onClearFilters={handleClearFilters}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </Column>
          )}
        </Column>
      </Column>
    </Content>
    <Toast
      visible={showToast}
      toggleVisibility={toggleToast}
      warning
      expiration={3000}
    >
      <Row gap="10" middle>
        <Icon color="white" name="warning" />
        <Label color="white" bold>
          You can only select 9 keywords
        </Label>
      </Row>
    </Toast>
  </MainWrapper>
);
