import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { productActions, tweetActions } from "../../../store/actions";
import {
  cancelProductRequests,
  cancelTweetRequests,
} from "../../../store/sagas";
import { view } from "./DigestView";
import {
  getISOFormattedDateTimeString,
  getUrlParams,
  logAnalyticEvent,
} from "../../../utils";

export const EmailDigest = () => {
  const dispatch = useDispatch();

  const { nId, name, email, productId, startDate, tweets, conference } =
    getUrlParams(location.search);

  const dates = {
    start: startDate ? getISOFormattedDateTimeString(startDate) : undefined,
  };

  // --> mapStateToProp
  const auth = useSelector((state) => state.auth.data);
  const product = useSelector((state) => state.product.one.data);
  const allTweets = useSelector((state) => state.tweet.all);
  // <-- mapStateToProp

  const [userType, setUserType] = useState("MEDICAL");

  const cookies = useSelector((state) => state.settings.cookies);

  // --> EFFECT
  useEffect(() => {
    if (productId) {
      productId && requestProduct();
    }

    if (!nId && tweets) {
      const tweetIds = tweets.split(",");
      requestTweets(tweetIds);
    }

    return () => {
      tweetActions.clear();
      productActions.clear();
      cancelProductRequests.abort();
      cancelTweetRequests.abort();
    };
  }, []);

  useEffect(() => {
    if (!_.isEmpty(product)) {
      const category = dates.start
        ? "Product Alert Digest"
        : "Initial Product Alert";
      let _userType = null;
      if (auth?.id) {
        _userType =
          auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
            ?.preferenceStringValue || "MEDICAL";
        setUserType(_userType);
      }
      // check for cookies first before publishing event
      if (!auth?.active && cookies?.seen && !cookies?.accepted) {
        return;
      }
      // call common helper function
      logAnalyticEvent(
        "View In Browser",
        {
          id: product.id,
          tagId: product.tagId,
          objectType: "PRODUCT",
          name: product.primaryName,
          ...(dates.start && { startDate: dates.start }),
          category,
          ...(conference && { conference: conference }),
          tweetIds: tweets,
        },
        auth?.id || null,
        auth?.displayName || name || null,
        auth?.email || email || null,
        auth?.createdAt || null,
        _userType
      );
    }
  }, [product]);
  // <-- EFFECT

  const requestProduct = () => {
    dispatch(productActions.oneRequest(productId));
  };

  const requestTweets = (ids) => {
    dispatch(tweetActions.multipleRequest({ ids }));
  };

  return view({
    nId,
    name,
    userType,
    loading: allTweets.fetching,
    dates,
    product,
    tweets: allTweets.data,
    conference,
  });
};
