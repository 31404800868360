import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useInfiniteScroll from "react-infinite-scroll-hook";

import moment from "moment";
import useDebounce from "../../../utils/useDebounce";
import {
  productActions,
  // accountActions,
  settingsActions,
} from "../../../store/actions";
import {
  cancelProductRequests,
  // cancelAccountRequests,
} from "../../../store/sagas";
import { getProducts } from "../../../store/sagas/productSagas";
import { view } from "./ProductsView";
import { MAX_RESULTS } from "../../../utils/variables";
import { getISOFormattedDateTimeString } from "../../../utils";

export const AdminProducts = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentDate = moment().add(-1, "days").endOf("day");
  const lastMonth = moment().add(-6, "months").startOf("day");

  // --> mapStateToProp
  const products = useSelector((state) => state.product.all);
  // const accounts = useSelector((state) => state.account.all.data);
  const productSettings = useSelector((state) => state.settings?.product);
  const productsCount = useSelector((state) => state.product.count);
  const statusTypes = [
    { id: 1, label: "All" },
    { id: 2, label: "Active" },
    { id: 3, label: "Inactive" },
  ];
  // <-- mapStateToProp

  // --> STATE
  const [dates, setDates] = useState({
    start:
      productSettings?.dates?.start ||
      getISOFormattedDateTimeString(lastMonth),
    end:
      productSettings?.dates?.end ||
      getISOFormattedDateTimeString(currentDate),
  });
  // const [searchInstitution, setSearchInstitution] = useState(
  //   productSettings?.institution?.[0]?.label || ""
  // );
  // const [selectedInstitution, setSelectedInstitution] = useState(
  //   productSettings?.institution || []
  // );
  // const [institutions, setInstitutions] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(
    productSettings?.status || 2
  );

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const debounceTerm = useDebounce(searchTerm, 700);
  // <-- STATE

  // --> EFFECT
  useEffect(() => {
    return () => {
      cancelProductRequests.abort();
      // cancelAccountRequests.abort();
    };
  }, []);

  // useEffect(() => {
  //   dispatch(accountActions.request({
  //     type: "INSTITUTION",
  //     forIngestion: "true",
  //   }));
  // }, [dispatch]);

  useEffect(() => {
    requestProducts(0);
    setPage(0);
    requestProductsCount();
  }, [dispatch, debounceTerm, /*selectedInstitution,*/ selectedStatus, dates]);

  useEffect(() => {
    setLoading(products.fetching);
  }, [products.fetching]);

  // useEffect(() => {
  //   if (accounts.length > 0) {
  //     const tempOwners = [];
  //     accounts.forEach((a) => {
  //       tempOwners.push({
  //         id: a.id,
  //         label: a.name,
  //       });
  //     });
  //     setInstitutions(tempOwners);
  //   }
  // }, [accounts]);
  // <-- EFFECT

  const requestProducts = (pageNum) => {
    let ignore = null;
    switch (selectedStatus) {
      case 2: // active (non-blacklisted & non-whitelisted)
        ignore = false;
        break;
      case 3: // blacklisted
        ignore = true;
        break;
    }
    dispatch(
      productActions.request({
        startDate: dates.start,
        endDate: dates.end,
        pageNum,
        maxResult: MAX_RESULTS,
        ignore,
        ...(searchTerm !== "" && { term: searchTerm }),
        // ...(!!selectedInstitution[0]?.id && {
        //   ownerIds: [selectedInstitution[0].id],
        // }),
        details: true,
      })
    );
  };

  const requestProductsCount = () => {
    let ignore = null;
    switch (selectedStatus) {
      case 2: // active (non-blacklisted & non-whitelisted)
        ignore = false;
        break;
      case 3: // blacklisted
        ignore = true;
        break;
    }
    dispatch(
      productActions.countRequest({
        ignore,
        ...(searchTerm !== "" && { term: searchTerm }),
        // ...(!!selectedInstitution[0]?.id && {
        //   ownerIds: [selectedInstitution[0].id],
        // }),
      })
    );
  };

  const exportCSV = async () => {
    let ignore = null;
    switch (selectedStatus) {
      case 2: // active (non-blacklisted & non-whitelisted)
        ignore = false;
        break;
      case 3: // blacklisted
        ignore = true;
        break;
    }
    const products = await getProducts({
      startDate: dates.start,
      endDate: dates.end,
      pageNum: 0,
      maxResult: -1,
      ignore,
      ...(searchTerm !== "" && { term: searchTerm }),
      // ...(!!selectedInstitution[0]?.id && {
      //   ownerIds: [selectedInstitution[0].id],
      // }),
      details: true,
    });
    if (products.data.length > 0) {
      let csvContent = "";
      // header
      csvContent += `Id, PrimaryName, Merged, Created, Last Modified, Tag Created, Match, Count\n`;
      _.forEach(products.data, (item) => {
        csvContent += `"${item.id}",`;
        csvContent += `"${item.primaryName}",`;
        csvContent += `"${item.substitutedTagNames || ''}",`;
        csvContent += `"${item.createdAt}",`;
        csvContent += `"${item.lastModified}",`;
        csvContent += `"${item.tag.createdAt}",`;
        csvContent += `"${item.tag.alwaysMatch ? 'Yes' : 'No'}",`;
        csvContent += `"${item.tag?.count !== -1 ? item.tag.count : 0}",`;
        csvContent += `\n`;
      });
      const url = window.URL.createObjectURL(new Blob([csvContent]));
      const link = document.createElement("a");
      link.href = url;
      link.id = "mw-download-link";
      link.setAttribute(
        "download",
        `Products_${moment().format("YYYYMMDDHHmmss")}.csv`
      );
      document.body.appendChild(link);
      link.click();
    }
  };

  const handleMoreItems = () => {
    if (!loading) {
      const newPage = page + 1;
      requestProducts(newPage);
      setPage(newPage);
    }
  };

  const [infiniteScrollRef] = useInfiniteScroll({
    loading,
    hasNextPage: !products.isLastPage,
    onLoadMore: handleMoreItems,
  });

  const handleStatusChange = (id) => {
    setSelectedStatus(id);
    dispatch(
      settingsActions.set({
        product: { ...productSettings, status: id },
      })
    );
  };

  const handleDateChange = (e) => {
    const start = getISOFormattedDateTimeString(e.startDate);
    const end = getISOFormattedDateTimeString(e.endDate);
    setDates({
      start,
      end,
    });
    dispatch(
      settingsActions.set({
        product: {
          ...productSettings,
          dates: {
            start: getISOFormattedDateTimeString(e.startDate),
            end: getISOFormattedDateTimeString(e.endDate),
          },
        },
      })
    );
  };

  // const handleSearchInstitution = (e) => {
  //   setSearchInstitution(e);
  // };

  // const clearInstitution = () => {
  //   setSearchInstitution("");
  //   addSelectedInstitution(null);
  // };

  // const addSelectedInstitution = (e) => {
  //   setSelectedInstitution([e]);
  //   dispatch(
  //     settingsActions.set({
  //       product: { ...productSettings, institution: [e] },
  //     })
  //   );
  // };

  const getActiveFilters = () => {
    let activeFilters = [];
    if (searchTerm) {
      activeFilters.push(`Search: ${searchTerm}`);
    }
    if (selectedStatus !== 1) {
      activeFilters.push(
        `Status: ${statusTypes.find((a) => selectedStatus === a.id)?.label}`
      );
    }
    // if (selectedInstitution?.[0]?.label) {
    //   activeFilters.push(`Owner: ${selectedInstitution[0].label}`);
    // }
    return activeFilters;
  };

  return view({
    history,
    products: products.data || [],
    productsCount: productsCount.data || 0,
    activeFilters: getActiveFilters(),
    statusTypes,
    selectedStatus,
    handleStatusChange,
    // institutions,
    // selectedInstitution,
    // searchInstitution,
    // handleSearchInstitution,
    // addSelectedInstitution,
    // clearInstitution,
    infiniteScrollRef,
    hasNextPage: !products.isLastPage,
    loading,
    searchTerm,
    setSearchTerm,

    exportCSV,
    dates,
    handleDateChange,
  });
};
