import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authActions, monitorActions } from "../../store/actions";
import { resetAnalyticSession } from "../../utils";
import { useEffect } from "react";
import { expireToken } from "../../store/sagas/authSagas";
import React from "react";
import { MainWrapper, Content, Row, Column, Spinner } from "../../components";

export const Logout = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const auth = useSelector((state) => state.auth.data);

  useEffect(() => {
    (async () => {
      try {
        // expire token
        await expireToken({ userId: auth.id });
      } catch (e) {
        console.log(e);
      }
      // clear redux
      dispatch(authActions.delete());
      dispatch(monitorActions.monitorsClear());
      dispatch(monitorActions.date(null));
      // reset analytic session id
      resetAnalyticSession();
      // redirect
      history.push(
        process.env.REACT_APP_LOGIN_BY_PASSWORD ? "/loginByPassword" : "/login"
      );
    })();
  }, []);

  // setTimeout(() => {
  //   dispatch(authActions.delete());
  //   // reset analytic session id
  //   resetAnalyticSession();
  //   history.push("/login");
  // }, 100);

  return (
    <MainWrapper>
      <Content>
        <Column>
          <Row expand paddingAll="80" center>
            <Spinner />
          </Row>
        </Column>
      </Content>
    </MainWrapper>
  );
};
