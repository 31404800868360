import React from "react";

import {
  Row,
  Column,
  Content,
  H3,
  H4,
  Label,
  Spinner,
  DateRange,
  AutoCompleteWithResponses,
  Pill,
  Card,
  Dropdown,
  NoContent,
  MainWrapper,
  Header,
  Menu,
  Filters,
  Grid,
  Summary,
  Toast,
  Icon,
  FilterModal,
  Metadata,
} from "../../components";
import { Chart } from "./Chart";
import { TopTweets } from "./TopTweets";
import { TopHCPs } from "./TopHCPs";
import { XYScatterChart } from "../../components/XYScatterChart";
import { capitalize } from "../../utils";

export const view = ({
  isRegistered,
  userType,
  handleSignUpClick,
  contentRef,
  downloadRef,
  handleScroll,
  selectedMonitor,
  dates,
  handleDateChange,
  activeFilters,
  loading,
  isReady,
  productName,
  productLoading,
  productTweets,
  productTweetsRef,
  productNextPage,
  exportCSVForTweets,
  timeSegmentedData,
  counter,
  showExport,
  showFilter,
  filterVisibility,
  toggleFilterVisibility,
  exportCSVForHCPs,
  activeTab,
  tabOnChange,
  tags,
  searchTag,
  selectedTags,
  handleSearchTag,
  addSelectedTag,
  removeSelectedTag,
  clearTag,
  tagsInfinteScroll,
  hcps,
  hcpsChartData,
  handleClearFilters,
  // selectedHcp,
  // handleSelectedHcp,
  sortTableBy,
  setSortTableBy,
  sortDirection,
  setSortDirection,

  handleInfluencersChartClick,
  influencerTypes,
  selectedInfluence,
  handleSelectedInfluence,

  sentimentTypes,
  selectedSentiment,
  handleSelectedSentiment,

  // influencerTabs,
  // influencerTab,
  // handleInfluencersTab,
  loadingHcps,
  hcpsReady,
  allKeywords,
  sortBy,
  handleSortByChange,
  filterTip,
  closeFilterTip,
  showCalendar,
  setShowCalendar,

  handleAiRefresh,
  isFetchingAI,
  aiResponse,
  hideAI,
  isNewAI,
  AIKeywords,
  refreshAI,
  isAISuccess,

  regions,
  selectedRegion,
  handleRegionChange,

  visible,
  toggleVisibility,
  errorMessage,
  error,

  pptInProgress,
  exportPptReport,
  showToast,
  toggleToast,
}) => {
  const monitorName = selectedMonitor.label || productName;

  return (
    <MainWrapper drawer className="dashboard">
      <Header
        search
        isHome
        drawer
        login
        user
        signUpClick={handleSignUpClick}
        source="product"
      />
      <Menu
        {...{
          selectedMonitor,
          activeTab,
          tabOnChange,
        }}
      />

      <Content ref={contentRef} onScroll={handleScroll}>
        <Metadata title={selectedMonitor.label || productName} />
        <Column>
          <Row marginBottom="5" marginRight style={{ minHeight: "20px" }}>
            <H3 bold>{selectedMonitor.label || productName}</H3>
          </Row>
          <Row marginTop="10" marginBottom>
            <Label color="gray" fade="7">
              Discover what physicians are saying on social media
            </Label>
          </Row>
          <Column marginBottom>
            <Filters
              {...{ counter, activeFilters }}
              onClearFilters={handleClearFilters}
              datePicker={
                dates.start && (
                  <Row gap="5" middle>
                    {counter && (
                      <Row marginLeft="5">
                        <Label>from</Label>
                      </Row>
                    )}
                    <DateRange
                      noBackground
                      id="date-range"
                      period
                      initialDate={dates.start}
                      endDate={dates.end}
                      onChange={handleDateChange}
                      noFutureDates
                      {...{ showCalendar, setShowCalendar }}
                    />
                  </Row>
                )
              }
              footer={
                selectedTags.length > 0 && (
                  <Row flexWrap marginTop="10" marginLeft>
                    {selectedTags.map((t, index) => (
                      <Row>
                        {index !== 0 && (
                          <Row marginRight="8" marginLeft="-2" marginTop="3">
                            <Label color="gray" fade="7">
                              or
                            </Label>
                          </Row>
                        )}
                        <Pill
                          key={t.id}
                          bkg="green"
                          fade="2"
                          label={t.label}
                          close
                          onClose={() => removeSelectedTag({ id: t.id })}
                        />
                      </Row>
                    ))}
                  </Row>
                )
              }
            >
              <Grid columns={3} gap="20">
                <Column gap="10">
                  <Label bold>Keywords</Label>
                  <div
                    onClick={(e) => {
                      if (!showFilter) {
                        toggleFilterVisibility(true);
                        e.stopPropagation();
                      }
                    }}
                    className="pointer"
                  >
                    {showFilter ? (
                      <AutoCompleteWithResponses
                        showOnEmptyTerm
                        searchResults={tags}
                        icon="search"
                        placeholder="Biomarker or other"
                        searchTerm={searchTag}
                        setSearchTerm={handleSearchTag}
                        selectedItems={selectedTags}
                        addItem={addSelectedTag}
                        removeItem={removeSelectedTag}
                        clearSearchResults={clearTag}
                        searchLoading={false}
                        infiniteScroll={tagsInfinteScroll}
                        hidePills
                        hasClearButton
                        showSuggestion
                      />
                    ) : (
                      <div
                        onClick={(e) => {
                          if (!showFilter) {
                            toggleFilterVisibility(true);
                            e.stopPropagation();
                          }
                        }}
                        className="pointer"
                      >
                        <Dropdown
                          expand
                          placeholder="Biomarker or other"
                          value={[]}
                          items={null}
                          disabled
                          onChange={() => {}}
                        />
                      </div>
                    )}
                  </div>
                </Column>
                {isRegistered && (
                  <Column gap="10">
                    <Label bold>Region</Label>
                    <div
                      onClick={(e) => {
                        if (!showFilter) {
                          toggleFilterVisibility(true);
                          e.stopPropagation();
                        }
                      }}
                      className="pointer"
                    >
                      <Dropdown
                        expand
                        multiple
                        multipleLabel="Regions"
                        value={selectedRegion}
                        items={regions}
                        disabled={!showFilter}
                        onChange={(e) => handleRegionChange(e)}
                      />
                    </div>
                  </Column>
                )}

                {activeTab === "influencers" && (
                  <>
                    <Column gap="10">
                      <Label bold>Influence</Label>
                      <div
                        onClick={(e) => {
                          if (!showFilter) {
                            toggleFilterVisibility(true);
                            e.stopPropagation();
                          }
                        }}
                        className="pointer"
                      >
                        <Dropdown
                          expand
                          select
                          items={influencerTypes}
                          value={selectedInfluence}
                          disabled={!showFilter}
                          onChange={handleSelectedInfluence}
                        />
                      </div>
                    </Column>
                    <Column gap="10">
                      <Label bold>Sentiment</Label>
                      <div
                        onClick={(e) => {
                          if (!showFilter) {
                            toggleFilterVisibility(true);
                            e.stopPropagation();
                          }
                        }}
                        className="pointer"
                      >
                        <Dropdown
                          expand
                          select
                          items={sentimentTypes}
                          value={selectedSentiment}
                          disabled={!showFilter}
                          onChange={handleSelectedSentiment}
                        />
                      </div>
                    </Column>
                  </>
                )}
              </Grid>
            </Filters>
          </Column>

          {activeTab === "tweets" && (
            <Column>
              {isRegistered && productTweets?.length >= 4 && !hideAI && (
                <Summary
                  keywords={AIKeywords}
                  // monitors={[
                  //   monitorName,
                  //   monitorName?.toLowerCase(),
                  //   capitalize(monitorName),
                  // ]}
                  isFetching={isFetchingAI}
                  response={aiResponse}
                  isSuccess={isAISuccess}
                  {...{
                    addSelectedTag,
                    handleAiRefresh,
                    isFetchingAI,
                    isNewAI,
                    refreshAI,
                  }}
                />
              )}

              {productTweets.length > 0 && (
                <Row marginY>
                  <H4 bold>Posts</H4>
                </Row>
              )}
              <TopTweets
                source={selectedMonitor.type.toLowerCase()}
                tweets={productTweets}
                tweetsRef={productTweetsRef}
                hasNextPage={productNextPage}
                keywords={allKeywords}
                onClearFilters={handleClearFilters}
                onDateClick={() => setShowCalendar(true)}
                {...{
                  isRegistered,
                  showExport,
                  loading,
                  isReady,
                  sortBy,
                  handleSortByChange,
                  exportCSVForTweets,
                  exportPptReport,
                  pptInProgress,
                }}
              />
            </Column>
          )}

          {activeTab === "influencers" && (
            <>
              {loadingHcps ? (
                <Row expand paddingAll="80" center>
                  <Spinner />
                </Row>
              ) : (
                <>
                  {hcpsChartData.length === 0 && hcps.length === 0 ? (
                    <>
                      {hcpsReady && (
                        <NoContent
                          label="physicians"
                          onClearFilters={handleClearFilters}
                          onDateClick={() => setShowCalendar(true)}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      {userType !== "OTHER" &&
                        userType !== "HCP" &&
                        hcpsChartData.length > 0 && (
                          <Card border marginBottom="40">
                            <H3 bold>Top influencers</H3>
                            <XYScatterChart
                              id="hcps"
                              typeX="sentiment"
                              typeY="influence"
                              data={hcpsChartData}
                              handleChartClick={handleInfluencersChartClick}
                            />

                            <Row marginBottom="-30" />
                          </Card>
                        )}
                      <Column ref={downloadRef}>
                        {hcps.length > 0 && (
                          <TopHCPs
                            source={selectedMonitor.type.toLowerCase()}
                            hasSentiment
                            hcps={
                              hcps
                              // selectedHcp !== "all"
                              //   ? hcps.filter((h) => h.id === selectedHcp)
                              //   : hcps
                            }
                            {...{
                              showExport,
                              exportCSVForHCPs,
                              sortTableBy,
                              setSortTableBy,
                              sortDirection,
                              setSortDirection,
                              userType,
                            }}
                          />
                        )}
                      </Column>
                    </>
                  )}
                </>
              )}
            </>
          )}
          <Toast
            {...{ visible, toggleVisibility }}
            warning={error}
            expiration={4000}
          >
            <Row gap={error ? "10" : "5"} middle>
              <Icon name={error ? "warning" : "check"} />
              <Label>{errorMessage}</Label>
            </Row>
          </Toast>
          <FilterModal
            visible={filterVisibility}
            toggleVisibility={() => toggleFilterVisibility(!filterVisibility)}
          />
        </Column>
      </Content>
      <Toast
        visible={showToast}
        toggleVisibility={toggleToast}
        warning
        expiration={3000}
      >
        <Row gap="10" middle>
          <Icon color="white" name="warning" />
          <Label color="white" bold>
            You can only select 9 keywords
          </Label>
        </Row>
      </Toast>
    </MainWrapper>
  );
};
