import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import { isEmpty } from "lodash";

import {
  // diseaseActions,
  // productActions,
  // conferenceActions,
} from "../../../store/actions";
import {
  getNotificationMessage,
  createNotificationMessage,
  updateNotificationMessage,
  deleteNotificationMessage,
} from "../../../store/sagas/notificationMessageSagas.js";
import { view } from "./EditNotificationMessageView";
import { getISOFormattedDateTimeString } from "../../../utils";

const errorsMessage = {
  text: "Required field",
};

export const EditNotificationMessage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  // --> mapStateToProp
  const auth = useSelector((state) => state.auth.data);
  // const initialDiseases = useSelector((state) => state.disease.all.data);
  // const initialProducts = useSelector((state) => state.product.all.data);
  // const initialConferences = useSelector((state) => state.conference.all.data);
  // <-- mapStateToProp

  const notificationTypes = [
    { id: "ALL", label: "All" },
    { id: "INSTANT", label: "Instant" },
    { id: "DAILY", label: "Daily" },
    { id: "WEEKLY", label: "Weekly" },
    { id: "MONTHLY", label: "Monthly" },
    // { id: "USERREQUEST", label: "User Request" },
    // { id: "TRIAL", label: "Trial" },
  ];
  const notificationContentTypes = [
    { id: "ALL", label: "All" },
    { id: "DISEASE", label: "Disease" },
    { id: "CONFERENCE", label: "Congress" },
    { id: "PRODUCT", label: "Product" },
    { id: "TOPINFLUENCER", label: "Top Influencers" },
    { id: "TOP_BRAND_MENTIONS", label: "Top Brand Mentions" },
    { id: "TOP_ARTICLES", label: "Top Articles" },
  ];
  const [userTypes] = useState([
    { id: "ALL", label: "All" },
    { id: "HCP", label: "Physician" },
    { id: "MARKETING", label: "Marketing" },
    { id: "MEDICAL", label: "Medical Affairs" },
    { id: "SALES", label: "Sales" },
    { id: "OTHER", label: "Other" },
  ]);

  // --> STATE
  const [deleteModal, toggleDeleteModal] = useState(false);
  const [isSavePressed, setIsSavePressed] = useState(false);
  const [errors, setErrors] = useState({});

  const [notificationMessage, setNotificationMessage] = useState(null);
  const [userType, setUserType] = useState("ALL");
  const [notificationType, setNotificationType] = useState("ALL");
  const [notificationContentType, setNotificationContentType] = useState("ALL");
  const [active, setActive] = useState(true);

  // const [diseases, setDiseases] = useState(null);
  // const [disease, setDisease] = useState([]);
  // const [products, setProducts] = useState(null);
  // const [product, setProduct] = useState([]);
  // const [conferences, setConferences] = useState([]);
  // const [conference, setConference] = useState([]);

  const [text, setText] = useState("");
  const [dates, setDates] = useState({
    start: getISOFormattedDateTimeString(moment().add(1, "days").startOf("day")),
    end: getISOFormattedDateTimeString(moment().add(2, "weeks").endOf("day")),
  });
  // <-- STATE

  useEffect(() => {
    // // TODO: work on paginated result
    // dispatch(diseaseActions.request({ ignore: false, maxResult: -1 }));
    // dispatch(productActions.request({ ignore: false, maxResult: -1 }));
    // dispatch(
    //   conferenceActions.request({
    //     ignore: false,
    //     onlyParents: true,
    //     maxResult: -1,
    //   })
    // );

    (async () => {
      if (id) {
        const nm = await getNotificationMessage(id);
        if (nm.data != null) {
          setNotificationMessage(nm.data);
          setUserType(nm.data.userType || "ALL");
          setNotificationType(nm.data.notificationType || "ALL");
          setNotificationContentType(nm.data.notificationContentType || "ALL");
          setText(nm.data.content);
          setActive(nm.data.active);
          setDates({
            start: getISOFormattedDateTimeString(nm.data.startDate),
            end: getISOFormattedDateTimeString(nm.data.endDate),
          });
        }
      }
    })();

    return () => {
    };
  }, [dispatch]);

  // useEffect(() => {
  //   if (initialDiseases.length > 0) {
  //     const newItems = initialDiseases.map((item) => ({
  //       id: item.id,
  //       label: item.primaryName,
  //     }));
  //     setDiseases(newItems);
  //   }
  // }, [initialDiseases]);

  // useEffect(() => {
  //   if (initialProducts.length > 0) {
  //     const newItems = initialProducts.map((item) => ({
  //       id: item.id,
  //       label: item.primaryName,
  //     }));
  //     setProducts(newItems);
  //   }
  // }, [initialProducts]);

  // useEffect(() => {
  //   if (initialConferences.length > 0) {
  //     const newItems = initialConferences
  //       .filter((c) => !c.parentId)
  //       .map((item) => ({
  //         id: item.id,
  //         label: item.primaryName,
  //       }));
  //     setConferences(newItems);
  //   }
  // }, [initialConferences]);

  useEffect(() => {
    checkErrors();
  }, [notificationContentType, text]);

  const checkErrors = () => {
    let newErrors = {};
    if (text === "") {
      newErrors.text = errorsMessage.text;
    }
    setErrors(newErrors);
  };

  // const handleDiseaseChange = (e) => {
  //   setDisease(e.id);
  // };

  // const handleProductChange = (e) => {
  //   setProduct(e.id);
  // };

  // const handleConferenceChange = (e) => {
  //   setConference(e.id);
  // };

  const handleNotificationTypeChange = (item) => {
    setNotificationType(item.id);
  };

  const handleNotificationContentTypeChange = (item) => {
    setNotificationContentType(item.id);
  };

  const handleUserTypeChange = (item) => {
    setUserType(item.id);
  };

  const handleTextChange = (html) => {
    setText(html);
  };

  const handleEditorBlur = () => {
    if (id) {
      const wrappedText = `<div class="fromEditor">${text}</div>`;
      const data = {
        ...notificationMessage,
        content: wrappedText.toString("html"),
      };
    }

    // update
  };

  const handleActiveChange = (value) => {
    setActive(value);
  };

  const handleDatesChange = (e) => {
    setDates({
      start: getISOFormattedDateTimeString(e.startDate),
      end: getISOFormattedDateTimeString(e.endDate),
    });
  };

  const handleSave = async () => {
    if (isSavePressed) return;
    setIsSavePressed(true);
    if (isEmpty(errors)) {
      const wrappedText = `<div class="fromEditor">${text}</div>`;
      const data = {
        id,
        ...notificationMessage,
        // objectId: null, // disease / product / conference id
        // objectType: null, // DISEASE / PRODUCT / CONFERENCE
        notificationContentType: notificationContentType !== "ALL" ? notificationContentType : null,
        notificationType: notificationType !== "ALL" ? notificationType : null,
        userType: userType !== "ALL" ? userType : null,
        content: wrappedText.toString("html"),
        active: active,
        startDate: dates.start,
        endDate: dates.end,
      };

      // save
      if (id) {
        await updateNotificationMessage(data);
      } else {
        await createNotificationMessage(data);
      }
      setIsSavePressed(false);

      // if success, go back
      window.location.href = "/admin/notificationMessages";
    }
  };

  const handleDelete = async () => {
    // delete
    await deleteNotificationMessage(id);

    // if success, go back
    window.location.href = "/admin/notificationMessages";
  };

  return view({
    handleSave,
    errors,
    isSavePressed,
    isNew: !id,

    // diseases,
    // disease,
    // handleDiseaseChange,

    // products,
    // product,
    // handleProductChange,

    // conferences,
    // conference,
    // handleConferenceChange,

    userTypes,
    userType,
    handleUserTypeChange,

    notificationTypes,
    notificationType,
    handleNotificationTypeChange,

    notificationContentTypes,
    notificationContentType,
    handleNotificationContentTypeChange,

    active,
    handleActiveChange,

    dates,
    handleDatesChange,

    text,
    handleTextChange,
    handleEditorBlur,

    deleteModal,
    toggleDeleteModal,
    handleDelete,
  });
};
