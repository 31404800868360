import { NOTIFICATION_ACTIONS } from "../actions";
import { MAX_RESULTS } from "../../utils/variables";

const initialState = {
  all: { data: [], fetching: false, success: false, error: null },
  send: { data: [], fetching: false, success: false, error: null },
};

export default function notificationReducer(state = initialState, action) {
  switch (action.type) {
    case NOTIFICATION_ACTIONS.REQUEST:
    case NOTIFICATION_ACTIONS.FAILURE:
      return { ...state, all: { ...state.all, ...action.payload } };

    case NOTIFICATION_ACTIONS.SUCCESS:
      // If we have pagination, we need to merge state data & payload data
      const newState = {
        ...state,
        all: {
          ...state.all,
          ...action.payload,
          isLastPage: action?.payload?.data?.length < MAX_RESULTS,
        },
      };
      if (action.payload.pageNum && action.payload.pageNum !== 0) {
        newState.all.data = [...state.all.data, ...action.payload.data];
      }
      return newState;

    case NOTIFICATION_ACTIONS.CLEAR:
      return { ...state, all: action.payload };

    case NOTIFICATION_ACTIONS.SEND_REQUEST:
    case NOTIFICATION_ACTIONS.SEND_SUCCESS:
    case NOTIFICATION_ACTIONS.SEND_FAILURE:
      return { ...state, send: action.payload };

    default:
      return state;
  }
}
