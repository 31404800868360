import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { groupBy } from "lodash";
import zipcelx from "zipcelx";
import moment from "moment";

import useDebounce from "../../../utils/useDebounce";
import { MAX_RESULTS } from "../../../utils/variables";
import { monitorActions, userActions } from "../../../store/actions";
import {
  cancelMonitorRequests,
  cancelUserRequests,
} from "../../../store/sagas";
import { view } from "./MonitorsView";

export const AdminMonitors = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const monitorTypes = [
    { id: "ALL", label: "All" },
    { id: "DISEASE", label: "Disease" },
    { id: "PRODUCT", label: "Product" },
    { id: "CONFERENCE", label: "Congress" },
  ];
  const monitorStatuses = [
    { id: "ALL", label: "All" },
    { id: "true", label: "Active" },
    { id: "false", label: "Inactive" },
  ];
  const monitorIntervals = [
    { id: "ALL", label: "All" },
    { id: "WEEKLY", label: "Weekly", hours: 168 },
    { id: "DAILY", label: "Daily", hours: 24 },
    { id: "INSTANT", label: "Instant", hours: 0 },
    { id: "NEVER", label: "Never", hours: -1 },
  ];

  // --> mapStateToProp
  const monitors = useSelector((state) => state.monitor?.all);
  const users = useSelector((state) => state.user.all);
  // <-- mapStateToProp

  // --> STATE
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchUser, setSearchUser] = useState("");
  const [pageUsers, setPageUsers] = useState("");
  const debounceUser = useDebounce(searchUser, 700);

  const [searchObjectTerm, setSearchObjectTerm] = useState(null);
  const debounceObjectTerm = useDebounce(searchObjectTerm, 700);

  const [selectedMonitorType, setSelectedMonitorType] = useState("ALL");
  const [selectedMonitorStatus, setSelectedMonitorStatus] = useState("true");
  const [selectedMonitorInterval, setSelectedMonitorInterval] = useState("ALL");

  const [selectedListType, setSelectedListType] = useState("4");
  const [listGroupByUser, setListGroupByUser] = useState([]);
  const [listGroupByMonitor, setListGroupByMonitor] = useState([]);
  const [summaryByUser, setSummaryByUser] = useState([]);

  const [loading, setLoading] = useState(false);
  // <-- STATE

  const requestMonitors = () => {
    dispatch(
      monitorActions.request({
        userId: selectedUser?.[0]?.id,
        ...(selectedMonitorType !== "ALL" && {
          objectType: selectedMonitorType,
        }),
        ...(selectedMonitorStatus !== "ALL" && {
          visible: selectedMonitorStatus,
        }),
        ...(selectedMonitorInterval !== "ALL" && {
          interval: selectedMonitorInterval,
        }),
        objectTerm: searchObjectTerm,
        pageNum: 0,
        maxResult: -1,
      })
    );
  };

  const requestUsers = (pageNum) => {
    dispatch(
      userActions.request({
        ...(searchUser !== "" && { term: searchUser }),
        maxResult: MAX_RESULTS,
        pageNum,
      })
    );
  };

  // --> EFFECT
  useEffect(() => {
    // requestMonitors();
    return () => {
      dispatch(monitorActions.clearAll());
      dispatch(userActions.clear());

      cancelMonitorRequests.abort();
      cancelUserRequests.abort();
    };
  }, []);

  useEffect(() => {
    setLoading(monitors?.fetching);
  }, [monitors?.fetching]);

  useEffect(() => {
    if (selectedListType === "2") {
      if (
        _.isEmpty(listGroupByUser) &&
        monitors?.data?.length > 0 &&
        !monitors.fetching
      ) {
        const groupByUser = groupBy(
          _.orderBy(monitors.data, "user.displayName"),
          "user.email"
        );
        // console.log(groupByUser);
        let temp1 = [];
        Object.keys(groupByUser).forEach((key) => {
          const t = {
            id: groupByUser[key][0].user.id,
            name: key,
            monitors: groupByUser[key]
              .filter((t) => t.visible)
              .map((t) => t.monitorComponent.object1Name)
              .join(", "),
            inactiveMonitors: groupByUser[key]
              .filter((t) => !t.visible)
              .map((t) => t.monitorComponent.object1Name)
              .join(", "),
          };
          temp1 = [...temp1, t];
        });
        setListGroupByUser(temp1);
      }
    } else if (selectedListType === "3") {
      if (
        _.isEmpty(listGroupByMonitor) &&
        monitors?.data?.length > 0 &&
        !monitors.fetching
      ) {
        const groupByMonitor = groupBy(
          monitors.data,
          "monitorComponent.object1Name"
        );
        // console.log(groupByMonitor);
        const temp = [];
        Object.keys(groupByMonitor).forEach((key) => {
          const t = {
            id: groupByMonitor[key][0].monitorComponent.object1Id,
            name: key,
            type: groupByMonitor[key][0].monitorComponent.object1Type,
            tagId: groupByMonitor[key][0].monitorComponent.object1TagId,
            users: groupByMonitor[key]
              .map((t) => t.user.displayName)
              .join(", "),
          };
          temp.push(t);
        });
        setListGroupByMonitor(temp);
      }
    } else if (selectedListType === "4") {
      const groupByUser = groupBy(
        _.orderBy(monitors.data, "user.displayName"),
        "user.email"
      );
      // console.log(groupByUser);
      let temp1 = [];
      Object.keys(groupByUser).forEach((key) => {
        const t = {
          id: groupByUser[key][0].user.id,
          name: key,
          diseaseMonitors: groupByUser[key]
            .filter(
              (t) => t.visible && t.monitorComponent.object1Type === "DISEASE"
            )
            .map((t) => t.monitorComponent.object1Name)
            .join(", "),
          productMonitors: groupByUser[key]
            .filter(
              (t) => t.visible && t.monitorComponent.object1Type === "PRODUCT"
            )
            .map((t) => t.monitorComponent.object1Name)
            .join(", "),
          conferenceMonitors: groupByUser[key]
            .filter(
              (t) =>
                t.visible && t.monitorComponent.object1Type === "CONFERENCE"
            )
            .map((t) => t.monitorComponent.object1Name)
            .join(", "),
          inactiveDiseaseMonitors: groupByUser[key]
            .filter(
              (t) => !t.visible && t.monitorComponent.object1Type === "DISEASE"
            )
            .map((t) => t.monitorComponent.object1Name)
            .join(", "),
          inactiveProductMonitors: groupByUser[key]
            .filter(
              (t) => !t.visible && t.monitorComponent.object1Type === "PRODUCT"
            )
            .map((t) => t.monitorComponent.object1Name)
            .join(", "),
          inactiveConferenceMonitors: groupByUser[key]
            .filter(
              (t) =>
                !t.visible && t.monitorComponent.object1Type === "CONFERENCE"
            )
            .map((t) => t.monitorComponent.object1Name)
            .join(", "),
        };
        temp1 = [...temp1, t];
      });
      setSummaryByUser(temp1);
    } else {
      // do nothing
    }
  }, [monitors?.data, selectedListType]);

  useEffect(() => {
    requestMonitors();
    setListGroupByUser([]);
    setListGroupByMonitor([]);
    setSummaryByUser([]);
  }, [
    selectedUser,
    selectedMonitorType,
    selectedMonitorStatus,
    selectedMonitorInterval,
    debounceObjectTerm,
  ]);

  useEffect(() => {
    users.data?.forEach((u) => {
      u.label = u.displayName || u.email;
    });
  }, [users.data]);

  useEffect(() => {
    setPageUsers(0);
    requestUsers(0);
  }, [debounceUser]);
  // <-- EFFECT

  const handleMonitorTypeChange = (item) => {
    setSelectedMonitorType(item.id);
  };

  const handleMonitorStatusChange = (item) => {
    setSelectedMonitorStatus(item.id);
  };

  const handleMonitorIntervalChange = (item) => {
    setSelectedMonitorInterval(item.id);
  };

  const clearUser = () => {
    setSearchUser("");
    addSelectedUser(null);
  };

  const handleSearchUser = (e) => {
    setSearchUser(e);
  };

  const addSelectedUser = (e) => {
    setSelectedUser([e]);
  };

  const handleMoreUsers = () => {
    if (!users.fetching) {
      const usersNewPage = pageUsers + 1;
      setPageUsers(usersNewPage);
      requestUsers(usersNewPage);
    }
  };
  const handleObjectTermChange = (e) => {
    // console.log(e);
    if (!_.isEmpty(e) /* && _.trim(e).length >= 4 */) {
      setSearchObjectTerm(_.trim(e));
    } else {
      setSearchObjectTerm(null);
    }
  };
  const handleListTypeChange = (e) => {
    setSelectedListType(e.id);
  };

  const exportToXlsx = () => {
    if (monitors?.data?.length > 0) {
      let xlsxContent = [];
      const s = "string";
      xlsxContent.push([
        { value: "Type", type: s },
        { value: "Monitor", type: s },
        { value: "User", type: s },
        { value: "Email", type: s },
        { value: "Active", type: s },
        { value: "Interval", type: s },
        { value: "Created At", type: s },
      ]);
      _.forEach(monitors.data, (monitor) => {
        const interval = monitorIntervals.find(
          (i) => i?.hours === monitor.notificationInterval
        );
        const monitorType = monitorTypes.find(
          (t) => t?.id === monitor.monitorComponent.object1Type
        );
        xlsxContent.push([
          { value: monitorType.label, type: s },
          { value: monitor.monitorComponent.object1Name, type: s },
          { value: monitor.user.displayName, type: s },
          { value: monitor.user.email, type: s },
          { value: monitor.visible ? "Yes" : "No", type: s },
          { value: interval.label, type: s },
          {
            value: `${moment(monitor.createdAt)
              .utc()
              .format("YYYY-MM-DD HH:mm:ss")}`,
            type: s,
          },
        ]);
      });
      const config = {
        filename: `Monitors_${moment().format("YYYYMMDDHHmmss")}`,
        sheet: {
          data: xlsxContent,
        },
      };

      zipcelx(config);
      return;
    }
  };

  return view({
    history,
    loading,
    monitors: monitors?.data || [],

    selectedListType,
    setSelectedListType,
    handleListTypeChange,
    listGroupByUser,
    listGroupByMonitor,
    summaryByUser,

    users: users?.data || [],
    searchUser,
    selectedUser,
    handleSearchUser,
    addSelectedUser,
    clearUser,
    usersInfiniteScroll: {
      loading: users.fetching,
      hasNextPage: !users.isLastPage,
      onLoadMore: handleMoreUsers,
    },

    monitorTypes,
    selectedMonitorType,
    handleMonitorTypeChange,

    searchObjectTerm,
    handleObjectTermChange,

    monitorStatuses,
    selectedMonitorStatus,
    handleMonitorStatusChange,

    monitorIntervals,
    selectedMonitorInterval,
    handleMonitorIntervalChange,

    exportToXlsx,
  });
};
