import { TWEET_ACTIONS } from "../actions";
import { MAX_RESULTS } from "../../utils/variables";

const initialState = {
  all: { data: [], fetching: false, success: false, error: null },
  one: { data: [], fetching: false, success: false, error: null },
  replies: { data: [], fetching: false, success: false, error: null },
  tweetsCount: { data: [], fetching: false, success: false, error: null },
  tags: { data: [], fetching: false, success: false, error: null },
  sentimentTags: { data: [], fetching: false, success: false, error: null },
  replyUrls: { data: [], fetching: false, success: false, error: null },
  accountsReplied: { data: [], fetching: false, success: false, error: null },
  accountsRetweeted: { data: [], fetching: false, success: false, error: null },
  accountsQuoted: { data: [], fetching: false, success: false, error: null },
  impressions: { data: {}, fetching: false, success: false, error: null },
};

export default function tweetReducer(state = initialState, action) {
  switch (action.type) {
    case TWEET_ACTIONS.REQUEST:
    case TWEET_ACTIONS.MULTIPLE_REQUEST:
    case TWEET_ACTIONS.FAILURE:
      return { ...state, all: { ...state.all, ...action.payload } };
    case TWEET_ACTIONS.SUCCESS:
      // If we have pagination, we need to merge state data & payload data
      const newState = {
        ...state,
        all: {
          ...state.all,
          ...action.payload,
          isLastPage: action.payload.data.length < MAX_RESULTS,
        },
      };
      if (action.payload.pageNum && action.payload.pageNum !== 0) {
        newState.all.data = [...state.all.data, ...action.payload.data];
      }
      return newState;

    case TWEET_ACTIONS.ONE_REQUEST:
    case TWEET_ACTIONS.ONE_SUCCESS:
    case TWEET_ACTIONS.ONE_FAILURE:
      return { ...state, one: { ...state.one, ...action.payload } };

    case TWEET_ACTIONS.CLEAR:
      return {
        ...state,
        all: action.payload,
        one: action.payload,
        replies: action.payload,
        tweetsCount: action.payload,
        replyUrls: action.payload,
        accountsReplied: action.payload,
        accountsRetweeted: action.payload,
      };

    case TWEET_ACTIONS.TWEET_REPLIES_REQUEST:
    case TWEET_ACTIONS.TWEET_REPLIES_SUCCESS:
    case TWEET_ACTIONS.TWEET_REPLIES_FAILURE:
      return { ...state, replies: { ...state.replies, ...action.payload } };

    case TWEET_ACTIONS.REPLY_URLS_REQUEST:
    case TWEET_ACTIONS.REPLY_URLS_SUCCESS:
    case TWEET_ACTIONS.REPLY_URLS_FAILURE:
      return { ...state, replyUrls: { ...state.replyUrls, ...action.payload } };

    case TWEET_ACTIONS.CLEAR_TWEETS:
      return {
        ...state,
        all: action.payload,
        tweetsCount: action.payload,
        tags: action.payload,
        replies: action.payload,
        replyUrls: action.payload,
      };

    case TWEET_ACTIONS.TWEETS_COUNT_REQUEST:
    case TWEET_ACTIONS.TWEETS_COUNT_SUCCESS:
    case TWEET_ACTIONS.TWEETS_COUNT_FAILURE:
      return {
        ...state,
        tweetsCount: { ...state.tweetsCount, ...action.payload },
      };

    case TWEET_ACTIONS.ACCOUNTS_REPLIED_REQUEST:
    case TWEET_ACTIONS.ACCOUNTS_REPLIED_SUCCESS:
    case TWEET_ACTIONS.ACCOUNTS_REPLIED_FAILURE:
      return {
        ...state,
        accountsReplied: { ...state.accountsReplied, ...action.payload },
      };

    case TWEET_ACTIONS.ACCOUNTS_RETWEETED_REQUEST:
    case TWEET_ACTIONS.ACCOUNTS_RETWEETED_SUCCESS:
    case TWEET_ACTIONS.ACCOUNTS_RETWEETED_FAILURE:
      return {
        ...state,
        accountsRetweeted: { ...state.accountsRetweeted, ...action.payload },
      };

    case TWEET_ACTIONS.ACCOUNTS_QUOTED_REQUEST:
    case TWEET_ACTIONS.ACCOUNTS_QUOTED_SUCCESS:
    case TWEET_ACTIONS.ACCOUNTS_QUOTED_FAILURE:
      return {
        ...state,
        accountsQuoted: { ...state.accountsQuoted, ...action.payload },
      };

    case TWEET_ACTIONS.TAG_REQUEST:
    case TWEET_ACTIONS.TAG_CREATE:
    case TWEET_ACTIONS.TAG_DELETE:
    case TWEET_ACTIONS.ONE_SUCCESS:
    case TWEET_ACTIONS.ONE_FAILURE:
    case TWEET_ACTIONS.CLEAR:
      return { ...state, tags: { ...state.one, ...action.payload } };

    case TWEET_ACTIONS.TAG_SENTIMENT_REQUEST:
    case TWEET_ACTIONS.TAG_SENTIMENT_SUCCESS:
    case TWEET_ACTIONS.TAG_SENTIMENT_FAILURE:
      return {
        ...state,
        sentimentTags: { ...state.sentimentTags, ...action.payload },
      };

    case TWEET_ACTIONS.IMPRESSIONS_REQUEST:
    case TWEET_ACTIONS.IMPRESSIONS_SUCCESS:
    case TWEET_ACTIONS.IMPRESSIONS_FAILURE:
      return {
        ...state,
        impressions: { ...state.impressions, ...action.payload },
      };

    default:
      return state;
  }
}
