import React from "react";
import styled from "styled-components";
import { Anchor } from "./Button";
import { TextLogo } from "./Header";
import { Row, Column, Grid, Circle } from "./Layout";
import { Label } from "./Typography";
import { currentYear } from "../utils/variables";

const Wrapper = styled(Column)`
  grid-area: footer;
`;

export const Footer = ({ grid }) => {
  return (
    <Wrapper
      center
      bkg="gray"
      fade="1"
      paddingY="100"
      paddingX="60"
      {...{ grid }}
    >
      <Grid columns={4} gap="60">
        <Column maxHeight spaceBetween gap="40">
          <TextLogo />
          <Column gap="5">
            <Label color="gray" fade="6" small>
              Copyright {currentYear} Medical.watch
            </Label>
            <Label color="gray" fade="6" small>
              All rights reserved
            </Label>
          </Column>
        </Column>
        <Column gap="20">
          <Label bold>Product</Label>
          <Row>
            <Anchor
              label="Physicians"
              color="black"
              blank
              to="https://www.resources.medical.watch/resources/categories/physicians"
            />
          </Row>
          <Row>
            <Anchor
              label="Marketing"
              color="black"
              blank
              to="https://www.resources.medical.watch/resources/categories/marketing"
            />
          </Row>
          <Row>
            <Anchor
              label="Medical Affairs"
              color="black"
              blank
              to="https://www.resources.medical.watch/resources/categories/medical-affairs"
            />
          </Row>
          <Anchor
            label="Omnichannel"
            color="black"
            blank
            to="https://www.resources.medical.watch/resources/categories/omnichannel"
          />

          <Row>
            <Anchor
              label="Sales"
              color="black"
              blank
              to="https://www.resources.medical.watch/resources/categories/sales"
            />
          </Row>
          {/* <Anchor label="Pricing" color="black" to="/pricing" /> */}
        </Column>
        <Column gap="20">
          <Label bold>Company</Label>
          <Anchor
            label="Frequently asked questions"
            color="black"
            blank
            to="https://www.resources.medical.watch/faqs"
          />
          <Anchor
            label="Contact us"
            color="black"
            blank
            to="mailto:hello@medical.watch"
          />
          <Anchor
            label="Resources"
            color="black"
            blank
            to="https://www.resources.medical.watch/"
          />
          <Anchor label="Privacy Policy" color="black" to="/privacy" />
          <Anchor label="Terms of Service" color="black" to="/terms" />
        </Column>
        <Column gap="20">
          <Label bold>Data Science & Analytics</Label>
          <Anchor
            label="API"
            color="black"
            blank
            to="https://www.resources.medical.watch/partner-api"
          />
          {/* <Anchor label="Insights" color="black" to="/insights" />
          <Anchor label="Top influencers" color="black" to="/top_influencers" /> */}
          <Anchor
            label="Congress influencers"
            color="black"
            to="/congress_influencers"
          />
          <Anchor
            label="Disease influencers"
            color="black"
            to="/disease_influencers"
          />
          <Anchor
            label="Disease insights"
            color="black"
            to="/disease_insights"
          />
          <Row gap="10" bottom>
            <Label>Status</Label>
            <Circle radius="8" bkg="green" fade="5" />
          </Row>
        </Column>
      </Grid>
    </Wrapper>
  );
};
