import { createAction, createActionTypes } from "../../utils";

export const CHAT_ACTIONS = createActionTypes("CHAT_ACTIONS", [
  "REQUEST",
  "SUCCESS",
  "FAILURE",
  "CLEAR_ALL",

  "ONE_REQUEST",
  "ONE_SUCCESS",
  "ONE_FAILURE",
  "CREATE",
  "SIGNUP",
  "RESEND_SIGNUP",
  "CLEAR",

  "UPDATE",

  "CREATE_ADDITIONAL",
  "ADDITIONAL_SUCCESS",
  "ADDITIONAL_FAILURE",
]);

export const chatActions = {
  request: () =>
    createAction(CHAT_ACTIONS.REQUEST, {
      fetching: true,
      success: false,
      error: null,
    }),
  success: ({ data }) =>
    createAction(CHAT_ACTIONS.SUCCESS, {
      data,
      fetching: false,
      success: true,
      error: null,
    }),
  failure: (error) =>
    createAction(CHAT_ACTIONS.FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),
  clearAll: () =>
    createAction(CHAT_ACTIONS.CLEAR_ALL, {
      data: [],
      fetching: true,
      success: false,
      error: null,
    }),

  oneRequest: (id) =>
    createAction(CHAT_ACTIONS.ONE_REQUEST, {
      id,
      fetching: true,
      success: false,
      error: null,
    }),
  oneSuccess: (data) =>
    createAction(CHAT_ACTIONS.ONE_SUCCESS, {
      ...data,
      fetching: false,
      success: true,
      error: null,
    }),
  oneFailure: (error) =>
    createAction(CHAT_ACTIONS.ONE_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),
  create: (data) =>
    createAction(CHAT_ACTIONS.CREATE, {
      data,
      fetching: true,
      success: false,
      error: null,
    }),
  clear: () =>
    createAction(CHAT_ACTIONS.CLEAR, {
      data: [],
      fetching: true,
      success: false,
      error: null,
    }),

  update: (data) =>
    createAction(CHAT_ACTIONS.UPDATE, {
      data,
      fetching: true,
      success: false,
      error: null,
    }),
  createAdditional: (data) =>
    createAction(CHAT_ACTIONS.CREATE_ADDITIONAL, {
      data,
      fetching: true,
      success: false,
      error: null,
    }),
  additionalSuccess: (data) =>
    createAction(CHAT_ACTIONS.ADDITIONAL_SUCCESS, {
      ...data,
      fetching: false,
      success: true,
      error: null,
    }),
  additionalFailure: (error) =>
    createAction(CHAT_ACTIONS.ADDITIONAL_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),
};
