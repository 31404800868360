import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { authActions } from "../../store/actions";
import { getUrlParams } from "../../utils";
import { view } from "./AutoLoginView";

export const AutoLogin = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [queryParams] = useState(getUrlParams(location.search));
  let lastLocation = useSelector((state) => state.nav.last);

  const userId = queryParams.u;
  const token = queryParams.t;
  const { target } = queryParams;

  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    return () => {
      dispatch(authActions.clear());
    };
  }, []);

  useEffect(() => {
    if (token && userId) {
      if (auth.data.expireTime) {
        if (
          lastLocation?.includes("login") ||
          lastLocation?.includes("autoLogin")
        ) {
          lastLocation = "/";
        }
        dispatch(
          authActions.userRequest({
            data: {
              userId,
              lastLocation,
              target,
            },
          })
        );
      } else {
        dispatch(authActions.tokenRequest({ data: { token, userId } }));
      }
    }
  }, [auth.data.expireTime]);

  useEffect(() => {
    if (auth.error?.response?.data?.indexOf("Unauthorized Error") > -1) {
      setTimeout(() => {
        history.push("/login");
      }, 500);
    }
  }, [auth.error]);

  return view({});
};
