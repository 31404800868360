import React from "react";
import moment from "moment";

import {
  Column,
  Row,
  MainWrapper,
  Header,
  Content,
  H2,
  Label,
  Input,
  Button,
  Switch,
  Anchor,
  Pill,
  AutoCompleteWithResponses,
  Modal,
  ModalFooter,
  DateRange,
  Collapsible,
  TextArea,
} from "../../../components";
import { Menu } from "../Menu";

export const Group = ({ children, label, secondLabel }) => (
  <Column marginTop="30">
    <Row marginBottom="10" gap="5">
      <Label>
        <Label bold>{label}</Label>
        {secondLabel && <Label> {secondLabel}</Label>}
      </Label>
    </Row>
    <Row marginTop="5">{children}</Row>
  </Column>
);

export const view = ({
  parentId,
  errors,
  handleSave,
  handleChange,
  handleSwitchChange,
  data,
  isSavePressed,
  isNew,
  deleteModal,
  toggleDeleteModal,
  handleDelete,
  handleNameOnBlur,
  handleDescriptionOnBlur,
  handleUrlOnBlur,
  handleDateChange,
  handleMonitorDateChange,

  childConferences,
  usersSubscribed,

  mergableTags,
  mergedTags,
  addMergedTags,
  removeMergedTags,
  searchMergableTag,
  handleSearchMergableTag,
  tagsInfinteScroll,
  createMergedTag,
  handleVisibleChange,

  editMode,
}) => (
  <MainWrapper drawer editMode={editMode}>
    {!editMode && (
      <Header
        drawer
        close={isNew}
        // back={!isNew}
        backTo="/admin/conferences"
        bkg="white"
        rightContent={
          <Row>
            {isNew && <Button label={"Create"} onClick={() => handleSave()} />}
            {!isNew && !data?.associatedConferences && (
              <Row marginX>
                <Anchor
                  label="Delete"
                  color="red"
                  onClick={() => toggleDeleteModal(true)}
                />
              </Row>
            )}
          </Row>
        }
      ></Header>
    )}
    <Menu active="conferences" />

    <Content>
      <Column center>
        <Column maxWidth="700" paddingBottom={editMode ? "0" : "300"}>
          {!editMode && (
            <Row expand paddingY="15">
              <H2 bold>{isNew ? "Add a new congress" : "Edit congress"}</H2>
            </Row>
          )}
          <Group label={parentId || data?.parentId ? "Child name" : "Name"}>
            <Column maxWidth="400">
              <Input
                small
                placeholder={parentId || data?.parentId ? "Child name" : "Name"}
                value={data?.primaryName}
                errorMessage={isSavePressed && errors.name}
                onChange={(e) =>
                  handleChange({ value: e.target?.value, field: "primaryName" })
                }
                onBlur={handleNameOnBlur}
              />
              {(parentId || data?.parentId) && (
                <Column marginTop marginBottom="-20">
                  <Switch
                    label={`Show in top influencers page`}
                    checked={data?.visible}
                    onChange={handleVisibleChange}
                  />
                </Column>
              )}
            </Column>
          </Group>

          <Column marginTop="30" marginBottom>
            <Switch
              label="Active"
              checked={!data?.tag?.alwaysIgnore}
              disabled={!data.alwaysIgnore && mergedTags.length > 0}
              onChange={(e) =>
                handleSwitchChange({ value: !e, field: "alwaysIgnore" })
              }
            />
            <Row marginTop="-10" marginLeft="50">
              <Label color="gray" fade="6">
                This Tag is active and visible
                {!data.alwaysIgnore &&
                  mergedTags.length > 0 &&
                  ` Cannot be deactivated as there are merged tags`}
              </Label>
            </Row>
          </Column>

          {(parentId || data?.parentId) && data?.startDate && data?.endDate && (
            <Group label="Congress Dates">
              <Column maxWidth="400">
                <DateRange
                  initialDate={data.startDate}
                  endDate={data.endDate}
                  onChange={handleDateChange}
                  editMode={editMode}
                />
              </Column>
            </Group>
          )}

          {(parentId || data?.parentId) &&
            data?.monitorStartTime &&
            data?.monitorEndTime && (
              <Group label="Monitoring Dates">
                <Column maxWidth="400">
                  <DateRange
                    initialDate={data.monitorStartTime}
                    endDate={data.monitorEndTime}
                    onChange={handleMonitorDateChange}
                    editMode={editMode}
                  />
                </Column>
              </Group>
            )}

          {!isNew && (
            <Column marginBottom>
              <Column gap="15">
                {/* associated conferences*/}
                {childConferences.length > 0 && (
                  <Group label="Associated congresses">
                    <Column gap="15" stretch>
                      {childConferences.map((item) => (
                        <Collapsible
                          key={item.id}
                          label={`${item.primaryName} (${moment(
                            item.monitorStartTime
                          )
                            .utc()
                            .format("MMM Do")} - ${moment(item.monitorEndTime)
                            .utc()
                            .format("MMM Do")})`}
                          rightAction={
                            <Anchor
                              color="green"
                              label="Delete"
                              onClick={() => handleDelete(item.tag.id)}
                            />
                          }
                        >
                          <iframe
                            style={{
                              border: "0",
                              height: "470px",
                              borderRadius: "10px",
                              background: "transparent",
                            }}
                            src={`${window.location.origin}/editConference/${item.id}/editMode`}
                          />
                        </Collapsible>
                      ))}
                    </Column>
                  </Group>
                )}

                {!data.parentId && (
                  <Row marginTop="10">
                    <Anchor
                      icon="add"
                      label="Add child congress"
                      to={`/newConference/${data.id}`}
                    />
                  </Row>
                )}

                {!data.parentId && usersSubscribed.length > 0 && (
                  <Collapsible label="Users Subscribed">
                    <Column paddingAll gap="15">
                      {/* <Group label="Users Subscribed"> */}
                      <Row flexWrap marginBottom="-10">
                        {usersSubscribed.map((u) => (
                          <Pill
                            label={u.displayName || u.firstName}
                            key={u.Id}
                          />
                        ))}
                      </Row>
                      {/* </Group> */}
                    </Column>
                  </Collapsible>
                )}

                {!data.parentId && data.associatedTags?.length > 0 && (
                  <Collapsible label="Disease and Products (Deprecated)">
                    <Column paddingAll gap="15">
                      {/* <Group label="Disease and Products (Deprecated)"> */}
                      <Row flexWrap marginBottom="-10">
                        {data.associatedTags.map((t) => (
                          <Pill
                            bkg={`${
                              t.relationship === "TOPIC" ? "green" : "purple"
                            }`}
                            fade="1"
                            label={t.toTag.name}
                            key={t.toTag.Id}
                          />
                        ))}
                      </Row>
                      {/* </Group> */}
                    </Column>
                  </Collapsible>
                )}

                {!data.parentId && (
                  <>
                    {/* manage mergable tags */}
                    <Group label="Merged Tags">
                      <AutoCompleteWithResponses
                        showOnEmptyTerm
                        searchResults={mergableTags}
                        placeholder="Add Tags"
                        setSearchTerm={(e) => handleSearchMergableTag(e)}
                        searchTerm={searchMergableTag}
                        selectedItems={mergedTags}
                        addItem={addMergedTags}
                        removeItem={removeMergedTags}
                        clearSearchResults={() => {}}
                        searchLoading={false}
                        infiniteScroll={tagsInfinteScroll}
                        noResultsMessage={
                          <Column gap="5">
                            <Label>Don't see congress tag?</Label>
                            <Anchor
                              bold
                              line
                              label="Click here to create new tag and merge it"
                              blank
                              onClick={() => createMergedTag()}
                            />
                          </Column>
                        }
                      />
                    </Group>
                  </>
                )}
              </Column>
            </Column>
          )}

          {!data.parentId && (
            <Collapsible style={{paddingTop:"20px"}} label="About conference">
              <Column paddingLeft paddingBottom>
                <Group label="Description">
                  <Column maxWidth="400">
                    <TextArea
                      small expand
                      placeholder="Description"
                      value={data?.description}
                      // errorMessage={isSavePressed && errors.description}
                      onChange={(e) =>
                        handleChange({ value: e.target?.value, field: "description" })
                      }
                      onBlur={handleDescriptionOnBlur}
                    />
                  </Column>
                </Group>

                <Group label="Url">
                  <Column maxWidth="400">
                    <Input
                      small
                      placeholder="Url"
                      value={data?.url}
                      // errorMessage={isSavePressed && errors.url}
                      onChange={(e) =>
                        handleChange({ value: e.target?.value, field: "url" })
                      }
                      onBlur={handleUrlOnBlur}
                    />
                  </Column>
                </Group>
              </Column>
            </Collapsible>
          )}
        </Column>

        <Modal
          visible={deleteModal}
          toggleVisibility={toggleDeleteModal}
          title="Delete congress"
          close
        >
          <Column marginBottom gap="20" center>
            <Label>
              <Label large bold>
                {data?.primaryName}
              </Label>
              <Label large> will be deleted</Label>
            </Label>
            <Label large>
              Are you sure you want to continue? This action cannot be undone.
            </Label>
          </Column>
          <ModalFooter right>
            <Button secondary label="Delete" onClick={() => handleDelete()} />
          </ModalFooter>
        </Modal>
      </Column>
    </Content>
  </MainWrapper>
);
