import { createAction, createActionTypes } from "../../utils";

export const HCP_ACTIONS = createActionTypes("HCP_ACTIONS", [
  "REQUEST",
  "SUCCESS",
  "FAILURE",

  "ONE_REQUEST",
  "ONE_SUCCESS",
  "ONE_FAILURE",

  "TAGS_REQUEST",
  "TAGS_SUCCESS",
  "TAGS_FAILURE",

  "CLEAR",
]);

export const hcpActions = {
  request: (data) =>
    createAction(HCP_ACTIONS.REQUEST, {
      ...data,
      fetching: true,
      success: false,
      error: null,
    }),
  success: ({ data, pageNum }) =>
    createAction(HCP_ACTIONS.SUCCESS, {
      data,
      pageNum,
      fetching: false,
      success: true,
      error: null,
    }),
  failure: (error) =>
    createAction(HCP_ACTIONS.FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  oneRequest: (id) =>
    createAction(HCP_ACTIONS.ONE_REQUEST, {
      id,
      fetching: true,
      success: false,
      error: null,
    }),
  oneSuccess: (data) =>
    createAction(HCP_ACTIONS.ONE_SUCCESS, {
      ...data,
      fetching: false,
      success: true,
      error: null,
    }),
  oneFailure: (error) =>
    createAction(HCP_ACTIONS.ONE_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  tagsRequest: (data) =>
    createAction(HCP_ACTIONS.TAGS_REQUEST, {
      ...data,
      fetching: true,
      success: false,
      error: null,
    }),
  tagsSuccess: (data) =>
    createAction(HCP_ACTIONS.TAGS_SUCCESS, {
      ...data,
      fetching: false,
      success: true,
      error: null,
    }),
  tagsFailure: (error) =>
    createAction(HCP_ACTIONS.TAGS_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  clear: () =>
    createAction(HCP_ACTIONS.CLEAR, {
      data: [],
      fetching: true,
      success: false,
      error: null,
    }),

  // save: data => createAction(HCP_ACTIONS.SAVE, {
  //   ...data, fetching: true, success: false, error: null,
  // }),
  // put: (id, data) => createAction(HCP_ACTIONS.PUT, {
  //   id, ...data, fetching: true, success: false, error: null,
  // }),
  // patch: (id, data) => createAction(HCP_ACTIONS.PATCH, {
  //   id, ...data, fetching: true, success: false, error: null,
  // }),
  // delete: id => createAction(HCP_ACTIONS.DELETE, {
  //   id, fetching: true, success: false, error: null,
  // }),
};
