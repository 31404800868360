import React from "react";
import {
  Column,
  Row,
  MainWrapper,
  TextLogo,
  Content,
  Label,
} from "../../components";

export const view = ({}) => (
  <MainWrapper>
    <Column center middle>
      <Row>
        <TextLogo />
      </Row>
    </Column>
    <Content>
      <Column center middle>
        <Label color="gray" fade="6" large>
          Redirecting...
        </Label>
      </Column>
    </Content>
  </MainWrapper>
);
