import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// import useInfiniteScroll from 'react-infinite-scroll-hook';

import moment from "moment";
import { groupBy, sortBy } from "lodash";
import { getISOFormattedDateTimeString } from "../../../utils";
import { settingsActions } from "../../../store/actions";
import { view } from "./NotificationEventsView";
import {
  getNotificationEventSummary,
  getNotificationEvents,
} from "../../../store/sagas/notificationSagas";

export const AdminNotificationEvents = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentDate = moment().endOf("day");
  const lastMonth = moment().add(-5, "weeks").startOf("day");
  const eventTypes = [
    { id: "ALL", label: "All" },
    { id: "Delivered", label: "Delivered" },
    { id: "Opened", label: "Opened" },
    { id: "Clicked", label: "Clicked" },
    { id: "Failed", label: "Failed" },
  ];
  const chartTypes = [
    { id: 1, label: "Line" },
    { id: 2, label: "Stacked Columns" },
  ];

  // --> mapStateToProp
  const notificationEventsSettings = useSelector(
    (state) => state.settings?.notificationEvents
  );
  // <-- mapStateToProp

  // --> STATE
  const [dates, setDates] = useState({
    start:
      // notificationEventsSettings?.dates?.start ||
      getISOFormattedDateTimeString(lastMonth),
    end:
      // notificationEventsSettings?.dates?.end ||
      getISOFormattedDateTimeString(currentDate),
  });

  const [selectedNotificationEventType, setSelectedNotificationEventType] =
    useState(notificationEventsSettings?.eventType || "ALL");

  const [allLoading, setAllLoading] = useState(false);
  const [allSummary, setAllSummary] = useState([]);
  const [chartTopTweetsLoading, setChartTopTweetsLoading] = useState(false);
  const [topTweetsSummary, setTopTweetsSummary] = useState([]);
  const [chartTopTweetsConfLoading, setChartTopTweetsConfLoading] =
    useState(false);
  const [topTweetsConfSummary, setTopTweetsConfSummary] = useState([]);
  const [chartTopInfluencersLoading, setChartTopInfluencersLoading] =
    useState(false);
  const [topBrandsSummary, setTopBrandsSummary] = useState([]);
  const [chartTopBrandsLoading, setChartTopBrandsLoading] = useState(false);
  const [topArticlesSummary, setTopArticlesSummary] = useState([]);
  const [chartTopArticlesLoading, setChartTopArticlesLoading] = useState(false);
  const [topInfluencersSummary, setTopInfluencersSummary] = useState([]);
  const [chartProductDigestLoading, setChartProductDigestLoading] =
    useState(false);
  const [productDigestSummary, setProductDigestSummary] = useState([]);

  const [chartType, setChartType] = useState(1);

  const [excludeEmployees, setExcludeEmployees] = useState(true);
  const [totalsInChart, setTotalsInChart] = useState(false);
  const [uniqueUsers, setUniqueUsers] = useState(true);
  const [rate, setRate] = useState(false);
  // <-- STATE

  // --> EFFECT
  useEffect(() => {
    return () => {};
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      setAllLoading(true);
      setChartTopTweetsLoading(true);
      setChartTopTweetsConfLoading(true);
      setChartTopInfluencersLoading(true);
      setChartTopBrandsLoading(true);
      setChartTopArticlesLoading(true);
      setChartProductDigestLoading(true);
      setAllSummary([]);
      setTopTweetsSummary([]);
      setTopTweetsConfSummary([]);
      setTopInfluencersSummary([]);
      setProductDigestSummary([]);
      const dayDiff = moment(dates.end).diff(moment(dates.start), "days");
      // fetch time segmented data
      const temp = await getNotificationEventSummary({
        ...(selectedNotificationEventType !== "ALL" && {
          eventType: selectedNotificationEventType,
        }),
        // category: "Top Tweets",
        excludeEmployees,
        uniqueUsers,
        startDate: dates.start,
        endDate: dates.end,
        segment: dayDiff > 31 ? "WEEK" : "DAY",
      });
      // transform
      if (temp.data.length > 0) {
        // filter monitor mail events
        const events = temp.data?.filter(
          (d) =>
            d.category === "Top Tweets" ||
            d.category === "Top Tweets Conference" ||
            d.category === "Top Influencers" ||
            d.category === "Product Alert Digest" ||
            d.category === "Product Alert" ||
            d.category === "Initial Product Alert"
        );
        // summation of all events, only when unique users are not selected
        if (!uniqueUsers) {
          setAllSummary(transformData(events));
        }
        // top tweets
        const _topTweetsSummary = temp.data.filter(
          (d) => d.category === "Top Tweets"
        );
        setTopTweetsSummary(transformData(_topTweetsSummary));
        // top tweets conference
        const _topTweetsConferenceSummary = temp.data.filter(
          (d) => d.category === "Top Tweets Conference"
        );
        setTopTweetsConfSummary(transformData(_topTweetsConferenceSummary));
        // top influencers
        const _topInfluencersSummary = temp.data.filter(
          (d) => d.category === "Top Influencers"
        );
        setTopInfluencersSummary(transformData(_topInfluencersSummary));
        // top brands
        const _topBrandsSummary = temp.data.filter(
          (d) => d.category === "Top Brands"
        );
        setTopBrandsSummary(transformData(_topBrandsSummary));
        // top articles
        const _topArticlesSummary = temp.data.filter(
          (d) => d.category === "Top Articles"
        );
        setTopArticlesSummary(transformData(_topArticlesSummary));
        // product alert digest
        const _productDigestSummary = temp.data.filter(
          (d) =>
            d.category === "Product Alert Digest" ||
            d.category === "Product Alert" ||
            d.category === "Initial Product Alert"
        );
        _productDigestSummary.forEach((d) => {
          if (d.category === "Product Alert Digest")
            d.category = "Product Alert";
          if (d.category === "Initial Product Alert")
            d.category = "Product Alert";
        });
        setProductDigestSummary(transformData(_productDigestSummary));
      } else {
        setAllSummary([]);
        setTopTweetsSummary([]);
        setTopTweetsConfSummary([]);
        setTopInfluencersSummary([]);
        setTopBrandsSummary([]);
        setTopArticlesSummary([]);
        setProductDigestSummary([]);
      }
      setAllLoading(false);
      setChartTopTweetsLoading(false);
      setChartTopTweetsConfLoading(false);
      setChartTopInfluencersLoading(false);
      setChartTopBrandsLoading(false);
      setChartTopArticlesLoading(false);
      setChartProductDigestLoading(false);
    })();
  }, [
    dates,
    selectedNotificationEventType,
    excludeEmployees,
    uniqueUsers,
    totalsInChart,
    rate,
  ]);
  // <-- EFFECT

  const transformData = (summaryData) => {
    // const sortedData = sortBy(summaryData, "timeSegment");
    const groupByTime = groupBy(summaryData, "timeSegment");
    const data = [];
    Object.keys(groupByTime).forEach((key) => {
      const row = {};
      row.time = key;
      groupByTime[key].forEach((d) => {
        if (totalsInChart)
          row["all events"] = (row["all events"] || 0) + d.count;
        else row[d.eventType] = (row[d.eventType] || 0) + d.count;
      });
      if (rate) {
        row["Open rate"] =
          row["Opened"] && row["Delivered"]
            ? ((row["Opened"] || 0) / row["Delivered"]) * 1
            : 0;
        row["Click rate"] =
          row["Clicked"] && row["Opened"]
            ? ((row["Clicked"] || 0) / row["Opened"]) * 1
            : 0;
        // remover other chart values
        delete row["Delivered"];
        delete row["Opened"];
        delete row["Clicked"];
        delete row["Failed"];
      }
      data.push(row);
    });
    return data;
  };

  const handleDateChange = (e) => {
    const start = getISOFormattedDateTimeString(
      moment(e.startDate).startOf("day")
    );
    const end = getISOFormattedDateTimeString(moment(e.endDate).endOf("day"));
    setDates({
      start,
      end,
    });
    // dispatch(
    //   settingsActions.set({
    //     notificationEvents: {
    //       ...notificationEventsSettings,
    //       dates: {
    //         start: getISOFormattedDateTimeString(e.startDate),
    //         end: getISOFormattedDateTimeString(e.endDate),
    //       },
    //     },
    //   })
    // );
  };

  const handleNotificationEventTypeChange = (item) => {
    setSelectedNotificationEventType(item.id);
    // dispatch(
    //   settingsActions.set({
    //     notificationEvents: { ...notificationEventsSettings, notificationType: item.id },
    //   })
    // );
  };

  const handleChartTypeChange = (item) => {
    setChartType(item.id);
  };

  const getActiveFilters = () => {
    let activeFilters = [];
    if (selectedNotificationEventType !== "ALL") {
      activeFilters.push(
        `Type: ${
          eventTypes.find((a) => selectedNotificationEventType === a.id)?.label
        }`
      );
    }
    if (excludeEmployees) {
      activeFilters.push("Exclude Employees");
    }
    return activeFilters;
  };

  const exportEventCSV = async () => {
    const temp = await getNotificationEvents({
      ...(selectedNotificationEventType !== "ALL" && {
        eventType: selectedNotificationEventType,
      }),
      excludeEmployees,
      startDate: dates.start,
      endDate: dates.end,
    });
    if (temp.data.length > 0) {
      let csvContent = "";
      // header
      csvContent += `EventId, MessageId, Recipient, EventType, DeliveryMessage, Category, EventTimestamp\n`;
      _.forEach(temp.data, (event) => {
        csvContent += `"${event.eventId}",`;
        csvContent += `"${event.messageId}",`;
        csvContent += `"${event.recipient}",`;
        csvContent += `"${event.eventType}",`;
        csvContent += `"${event.deliveryMessage?.replaceAll('"', "'") || ""}",`;
        csvContent += `"${event.category}",`;
        csvContent += `"${event.eventTimestamp}",`;
        csvContent += `\n`;
      });
      const url = window.URL.createObjectURL(new Blob([csvContent]));
      const link = document.createElement("a");
      link.href = url;
      link.id = "mw-download-link";
      link.setAttribute(
        "download",
        `Events_${moment().format("YYYYMMDDHHmmss")}.csv`
      );
      document.body.appendChild(link);
      link.click();
    }
  };

  return view({
    history,

    activeFilters: getActiveFilters(),

    allLoading,
    allSummary,

    chartTopTweetsLoading,
    topTweetsSummary,

    chartTopTweetsConfLoading,
    topTweetsConfSummary,

    chartTopInfluencersLoading,
    topInfluencersSummary,

    chartTopBrandsLoading,
    topBrandsSummary,

    chartTopArticlesLoading,
    topArticlesSummary,

    chartProductDigestLoading,
    productDigestSummary,

    eventTypes,
    selectedNotificationEventType,
    handleNotificationEventTypeChange,

    excludeEmployees,
    setExcludeEmployees,

    exportEventCSV,

    totalsInChart,
    setTotalsInChart,

    uniqueUsers,
    setUniqueUsers,

    rate,
    setRate,

    chartTypes,
    chartType,
    handleChartTypeChange,

    dates,
    handleDateChange,
  });
};
