import { USER_ACTIONS } from "../actions";
import { MAX_RESULTS } from "../../utils/variables";

const initialState = {
  all: { data: [], fetching: false, success: false, error: null },
  one: { data: [], fetching: false, success: false, error: null },
  count: { data: [], fetching: false, success: false, error: null },
  email: { data: [], fetching: false, success: false, error: null },
  preferences: { data: [], fetching: false, success: false, error: null },
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case USER_ACTIONS.REQUEST:
    case USER_ACTIONS.FAILURE:
      return { ...state, all: { ...state.all, ...action.payload } };
    case USER_ACTIONS.SUCCESS:
      // If we have pagination, we need to merge state data & payload data
      const newState = {
        ...state,
        all: {
          ...state.all,
          ...action.payload,
          isLastPage: action.payload.data.length < MAX_RESULTS,
        },
      };
      if (action.payload.pageNum && action.payload.pageNum !== 0) {
        newState.all.data = [...state.all.data, ...action.payload.data];
      }
      return newState;
    case USER_ACTIONS.CLEAR_ALL:
      return { ...state, all: action.payload, count: action.payload };

    case USER_ACTIONS.ONE_REQUEST:
    case USER_ACTIONS.ONE_SUCCESS:
    case USER_ACTIONS.ONE_FAILURE:
    case USER_ACTIONS.CREATE:
    case USER_ACTIONS.SIGNUP:
    case USER_ACTIONS.RESEND_SIGNUP:
    case USER_ACTIONS.UPDATE:
    case USER_ACTIONS.UPDATE_STATUS:
    case USER_ACTIONS.DELETE:
    case USER_ACTIONS.CLEAR:
      return { ...state, one: action.payload };

    case USER_ACTIONS.COUNT_REQUEST:
    case USER_ACTIONS.COUNT_SUCCESS:
    case USER_ACTIONS.COUNT_FAILURE:
      return {
        ...state,
        count: { ...state.count, ...action.payload },
      };

    case USER_ACTIONS.SEND_LOGIN_EMAIL:
    case USER_ACTIONS.SEND_EMAIL_SUCCESS:
    case USER_ACTIONS.SEND_EMAIL_FAILURE:
    case USER_ACTIONS.SEND_EMAIL_CLEAR:
      return { ...state, email: action.payload };

    case USER_ACTIONS.REQUEST_PREFERENCES:
    case USER_ACTIONS.UPDATE_PREFERENCES:
    case USER_ACTIONS.DELETE_PREFERENCES:
    case USER_ACTIONS.SUCCESS_PREFERENCES:
    case USER_ACTIONS.FAILURE_PREFERENCES:
    case USER_ACTIONS.CLEAR_PREFENCES:
      return {
        ...state,
        preferences: action.payload,
      };

    default:
      return state;
  }
}
