import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { view } from "./AiSummariesView";

import {
  getObjectsWithSummary,
  updateSummaryTitle,
} from "../../store/sagas/summary";
import { textToId, idToText, logAnalyticEvent } from "../../utils";
import { useGetInsights, useRegenerate } from "../../store/queries/insights";

export const AiSummaries = () => {
  const { id } = useParams();
  const {
    mutateAsync: regenerateInsight,
    isLoading: isRegenerating,
    error: regenerateError,
  } = useRegenerate();

  const contentRef = useRef(null);
  const user = useSelector((state) => state.auth.data);
  const userPlans = useSelector((state) => state.plan.userPlans);

  // --> STATE
  const [objectsWithSummary, setObjectsWithSummary] = useState([]);
  const [objectSummaries, setObjectSummaries] = useState([]);
  const [objectId, setObjectId] = useState("");
  const [inProgress, setInProgress] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [title, setTitle] = useState("");
  const [error, setError] = useState("");

  const { data: inisghts, refetch: refetchInsights } = useGetInsights({
    data: {
      objectId,
      pageNum: 0,
      maxResult: 4,
    },
    enabled: objectId !== "",
  });
  // <-- STATE

  // --> EFFECT
  useEffect(() => {
    (async () => {
      setInProgress(true);
      const tempSummary = await getObjectsWithSummary();
      if (tempSummary.data) {
        setObjectsWithSummary(tempSummary.data);
      }

      setInProgress(false);
    })();
  }, []);

  useEffect(() => {
    if (regenerateError?.message) {
      setError(regenerateError.message);
    }
  }, [regenerateError]);

  useEffect(() => {
    setError("");
    contentRef?.current?.scrollTo(0, 0);
  }, [id]);

  useEffect(() => {
    if (inisghts?.data?.length > 0) {
      setObjectSummaries(inisghts.data);
    }
  }, [inisghts]);

  useEffect(() => {
    // console.log(objectId, objectsWithSummary?.filter((item) => item.id === objectId)?.[0]?.primaryName);
    if (objectId) {
      refetchInsights();
      logAnalyticEvent(
        "Landing - Insights",
        {
          objectId: objectId,
          name: objectsWithSummary?.filter((item) => item.id === objectId)?.[0]
            ?.primaryName,
        },
        user?.id || null,
        user?.displayName || null,
        user?.email || null,
        user?.createdAt || null,
        user?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
          ?.preferenceStringValue || "MEDICAL",
        userPlans?.data?.[0]?.plan?.name || null
      );
    }
  }, [objectId]);

  useEffect(() => {
    if (objectsWithSummary?.length > 0) {
      if (id) {
        setObjectId(
          textToId({
            text: id,
            items: objectsWithSummary?.map((item) => {
              return { id: item.id, label: item.primaryName };
            }),
          })
        );
      } else {
        setObjectId(objectsWithSummary[0].id);
      }
    }
  }, [objectsWithSummary, id]);

  // <-- EFFECT

  const handleSignUpClick = () => {
    return;
  };

  const handleRegenerate = async (id) => {
    if (id) {
      await regenerateInsight(id);
    }
  };

  const handleUpdateTitle = (id) => {
    (async () => {
      const temp = await updateSummaryTitle({
        id,
        title,
      });
      if (temp.data) {
        const newSummaries = [...objectSummaries];
        const index = newSummaries.findIndex((item) => item.id === id);
        if (index > -1) {
          newSummaries[index].title = title;
        }
        setObjectSummaries(newSummaries);
      }
    })();
    setEditMode(false);
  };
  const handleCancelEditing = () => {
    setEditMode(false);
  };

  return view({
    user,
    isAdmin: user?.mwRole === "ADMIN",
    selectedObjectId: id || objectsWithSummary?.[0]?.id,
    objectSummaries,
    inProgress,
    items:
      objectsWithSummary?.map((item) => {
        return {
          id: item.id,
          label: item.primaryName,
          to: `/insights/${idToText(item.primaryName)}`,
        };
      }) || [],
    handleSignUpClick,
    editMode,
    setEditMode,
    title,
    setTitle,
    handleUpdateTitle,
    handleCancelEditing,
    handleRegenerate,
    isRegenerating,
    error,
    contentRef,
  });
};
