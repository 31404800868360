import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { isEmpty, cloneDeep, uniqBy } from "lodash";
import moment from "moment";
import useDebounce from "../../../utils/useDebounce";

import {
  productActions,
  tagActions,
  accountActions,
  userActions,
} from "../../../store/actions";
import { getProductMetrics } from "../../../store/sagas/productSagas";
import {
  TAccount_Tag_Relationship,
  Tag_Tag_Relationship,
} from "../../../utils/constants";
import { view } from "./EditProductView";
import { MAX_RESULTS } from "../../../utils/variables";
import { getISOFormattedDateTimeString } from "../../../utils";

const errorsMessage = {
  name: "Required field",
};

export const EditProduct = () => {
  const dispatch = useDispatch();
  const { id, params } = useParams();
  const isEditMode = params === "editMode";
  const currentDate = moment().add(-1, "days").endOf("day");
  const lastMonth = moment().add(-6, "months").startOf("day");

  const product = useSelector((state) => state.product.one.data);
  const usersSubscribed = useSelector((state) => state.user.all.data);
  const institutions = useSelector((state) => state.account.all.data);
  const initialTags = useSelector((state) => state.tag.all); // all tags in system
  const initialProducts = useSelector((state) => state.product.all); // all products in system
  const tagOwner = useSelector((state) => state.tag.owner.data);
  // initial tags which are whitelisted
  const whitelist = useSelector((state) => state.tag.whitelist.data);
  // // initial all tags which are associated
  // const initialAllTagAssociations = useSelector(
  //   (state) => state.tag.tagsAssociated.data
  // );
  const initialAllTagMonitorRules = useSelector(
    (state) => state.tag.tagMonitorRules.data
  );
  const tagMonitorRule = useSelector((state) => state.tag.tagMonitorRule);

  const [selectedInstitution, setSelectedInstitution] = useState([]);
  const [searchInstitution, setSearchInstitution] = useState("");

  const [isSavePressed, setIsSavePressed] = useState(false);
  const [errors, setErrors] = useState({
    primaryName: true,
  });
  const [deleteModal, toggleDeleteModal] = useState(false);

  // tailored list of products which CAN BE associated
  const [products, setProducts] = useState([]);
  const [pageProducts, setPageProducts] = useState(0);
  // associated competitor products
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [searchProduct, setSearchProduct] = useState("");
  const debounceSearchProduct = useDebounce(searchProduct, 700);

  // tailored list of mergable tags
  const [mergableTags, setMergableTags] = useState([]);
  // merged tags
  const [mergedTags, setMergedTags] = useState([]);
  const [searchMergableTag, setSearchMergableTag] = useState("");
  const debounceMergableTag = useDebounce(searchMergableTag, 700);
  // selected tag monitor 'pair' rules for inclusion
  const [tagPairs, setTagPairs] = useState([]);
  const [firstTagPair, setFirstTagPair] = useState("");
  const [firstTagTermPair, setFirstTagTermPair] = useState("");
  const debounceFirstTagToInclude = useDebounce(firstTagTermPair, 700);
  const [secondTagPair, setSecondTagPair] = useState("");
  const [secondTagTermPair, setSecondTagTermPair] = useState("");
  const debounceSecondTagToInclude = useDebounce(secondTagTermPair, 700);

  // tailored list of tags which CAN BE associated
  const [tags, setTags] = useState([]);
  const [pageTags, setPageTags] = useState(0);
  // selected tag monitor rules for inclusion
  const [includedTagMonitorRules, setIncludedTagMonitorRules] = useState([]);
  const [allIncludedTagMonitorRules, setAllIncludedTagMonitorRules] = useState(
    []
  );
  const [searchTagToInclude, setSearchTagToInclude] = useState("");
  const debounceTagToInclude = useDebounce(searchTagToInclude, 700);
  const [metrics, setMetrics] = useState({});
  const [dates, setDates] = useState({
    start: getISOFormattedDateTimeString(lastMonth),
    end: getISOFormattedDateTimeString(currentDate),
  });

  const [data, setData] = useState({
    primaryName: "",
    tag: {
      alwaysMatch: true,
      alwaysIgnore: false,
    },
    meaning: "MEDICATION",
    // tagsAssociatedWith: [],
  });

  const requestTags = (pageNum, searchTerm) => {
    if (isEditMode) return; // no need to go further
    dispatch(
      tagActions.request({
        meaning: null,
        ignore: false,
        match: null,
        preferred: true,
        pageNum: pageNum || 0,
        maxResult: MAX_RESULTS,
        includeProducts: true,
        includeDiseases: true,
        ...(searchTerm !== "" && { term: searchTerm }),
        // ...(searchTagToInclude !== "" && { term: searchTagToInclude }),
        // ...(searchMergableTag !== "" && { term: searchMergableTag }),
      })
    );
  };

  const requestUsersSubscribed = (id) => {
    if (id) {
      dispatch(
        userActions.request({
          monitorObjectId: id,
          maxResult: -1,
        })
      );
    }
  };

  const requestAccounts = () => {
    if (isEditMode) return; // no need to go further
    dispatch(accountActions.request({
      type: "INSTITUTION",
      forIngestion: "true",
    }));
  };

  useEffect(() => {
    if (id) {
      // get product metrics
      (async () => {
        const temp = await getProductMetrics({
          id: id,
          startDate: dates.start,
          endDate: dates.end,
        });
        setMetrics(temp?.data || {});
      })();
    }
  }, [dates]);

  useEffect(() => {
    if (product && id) {
      setData({
        id: product?.id,
        primaryName: product?.primaryName,
        tag: product?.tag,
        // tagsAssociatedWith: product?.tagsAssociatedWith || [],
      });
    }
  }, [product]);

  useEffect(() => {
    if (id) {
      // get the product
      dispatch(productActions.oneRequest(id, true));
      // get all institutions, for drop down
      // requestAccounts();
      // get all keywords, for drop down
      // requestTags();
      // fetch users subscribed to disease
      requestUsersSubscribed(id);
    }

    return () => {
      // Clean data
      dispatch(productActions.clear());
      dispatch(tagActions.clearTags());
      // Update products
      // dispatch(productActions.request({}));
      dispatch(userActions.clear());
    };
  }, [dispatch]);

  useEffect(() => {
    setPageProducts(0);
    // requestProducts(0);
  }, [debounceSearchProduct]);

  useEffect(() => {
    checkErrors();
  }, [data]);

  useEffect(() => {
    if (data.tag?.id) {
      // get all tags which are merged or substituted
      dispatch(tagActions.whitelistRequest({
        ids: [data.tag.id],
        nocache: true,
      }));
      // get all tags associated
      // dispatch(tagActions.tagAssociationsRequest({ id: data.tag.id }));
      // get owner
      // dispatch(tagActions.ownerRequest(data.tag.id));
      // get all tag monitor rules
      dispatch(tagActions.tagMonitorRulesRequest({ id: data.tag.id }));
    }
  }, [data.tag?.id]);

  useEffect(() => {
    if (initialTags.data.length > 0) {
      let newTags = initialTags.data.map((item) => ({
        ...item,
        label: item.name,
      }));
      // exclude self, because now we also fetch disease tags
      newTags = newTags.filter((k) => k.id !== data.tag?.id);

      let finalTags = cloneDeep(newTags);
      // remove blacklisted
      // finalTags = finalTags.filter((k) => !k.alwaysIgnore);
      // exclude tags which are substitutes to others
      finalTags = finalTags.filter((k) => !k.substitutedIds);
      // exclude tags which are whitelisted
      finalTags = finalTags.filter((k) => !k.preferredTagId);
      // exclude tags which are products
      finalTags = finalTags.filter((k) => !k.productId);
      // exclude tags which are diseases
      finalTags = finalTags.filter((k) => !k.diseaseId);
      // console.log("after => ", finalTags)

      setMergableTags(finalTags);

      // list for monitor rules
      newTags = newTags.filter((k) => !k.preferredTagId);
      setTags(newTags);
    } else {
      setTags([]);
    }
  }, [initialTags]);

  useEffect(() => {
    if (whitelist?.length > 0) {
      const whitelistedTags = whitelist.map((tag) => ({
        ...tag,
        label: tag.name,
      }));
      setMergedTags(whitelistedTags);
    }
  }, [whitelist]);

  useEffect(() => {
    if (initialProducts.data.length > 0) {
      let newProducts = initialProducts.data.map((item) => ({
        id: item.tagId,
        label: item.primaryName,
      }));
      setProducts(newProducts);
    }
  }, [initialProducts.data]);

  // useEffect(() => {
  //   const initialSelectedProducts =
  //     initialAllTagAssociations?.[Tag_Tag_Relationship.COMPETITOR];

  //   // if there is initial selection
  //   if (initialSelectedProducts?.length > 0) {
  //     const sproducts = initialSelectedProducts.map((assoc) => ({
  //       id: assoc.toTagId,
  //       label: assoc.toTag.name,
  //     }));
  //     setSelectedProducts(sproducts);
  //   }
  // }, [initialAllTagAssociations]);

  useEffect(() => {
    // if there is initial selection
    if (initialAllTagMonitorRules?.length > 0) {
      // included tags (both true)
      let stags = initialAllTagMonitorRules
        .filter((r) => r.categorizeForMonitor && r.displayInGraphs)
        .filter((r) => isEmpty(r.secondTagId))
        .map((assoc) => ({
          id: assoc.tagId,
          label: assoc.tagName,
        }));
      setIncludedTagMonitorRules(uniqBy(stags, "id"));

      // included pairs
      const tagPairsMonitorRules =
        initialAllTagMonitorRules?.filter((a) => !isEmpty(a.secondTagId)) || [];
      setTagPairs(tagPairsMonitorRules);

      // included tags + pairs
      const extractedPairs = [];
      tagPairsMonitorRules.forEach((item) => {
        extractedPairs.push({
          id: item.tagId,
          label: item.tagName,
        });
        extractedPairs.push({
          id: item.secondTagId,
          label: item.secondTagName,
        });
      });

      setAllIncludedTagMonitorRules([
        ...stags,
      ]);
    }
  }, [initialAllTagMonitorRules]);

  useEffect(() => {
    if (tagOwner?.length > 0) {
      tagOwner[0].tAccount.label = tagOwner[0].tAccount.name;
      setSelectedInstitution([tagOwner[0].tAccount]);
      setSearchInstitution(tagOwner[0].tAccount.name);
    }
  }, [tagOwner]);

  useEffect(() => {
    setPageTags(0);
    requestTags(0, searchTagToInclude);
  }, [debounceTagToInclude]);

  useEffect(() => {
    setPageTags(0);
    requestTags(0, searchMergableTag);
  }, [debounceMergableTag]);

  useEffect(() => {
    setPageTags(0);
    requestTags(0, firstTagTermPair);
  }, [debounceFirstTagToInclude]);

  useEffect(() => {
    setPageTags(0);
    requestTags(0, secondTagTermPair);
  }, [debounceSecondTagToInclude]);


  useEffect(() => {
    if (data.tag?.id && !tagMonitorRule.fetching && tagMonitorRule.success && !tagMonitorRule.error) {
      // console.log(tagMonitorRule);
      // console.log("updating rules.... ")
      updateRules();
    }
  }, [tagMonitorRule]);

  const resetTags = () => {
    setPageTags(0);
    requestTags(0, "");
  };

  const requestProducts = (pageNum) => {
    dispatch(
      productActions.request({
        ...(searchProduct !== "" && { term: searchProduct }),
        maxResult: MAX_RESULTS,
        pageNum,
      })
    );
  };

  const handleSearchInstitution = (e) => {
    setSearchInstitution(e);
  };

  const clearInstitution = () => {
    if (selectedInstitution[0]?.id) {
      const body = {
        tAccountId: selectedInstitution[0].id,
        tagId: data.tag.id,
      };
      dispatch(tagActions.ownerDelete(body));
      setSearchInstitution(null);
      setSelectedInstitution([]);
    }
  };

  const checkErrors = () => {
    let newErrors = {};
    for (const item in errorsMessage) {
      if (data[item] === "") {
        newErrors[item] = errorsMessage[item];
      } else {
        delete newErrors[item];
      }
    }
    setErrors(newErrors);
  };

  // add product owner
  const addSelectedInstitution = (item) => {
    const body = {
      tAccountId: item.id,
      tagId: data.tag.id,
      relationship: TAccount_Tag_Relationship.OWNER,
    };
    setSelectedInstitution([item]);
    setSearchInstitution(item.label);

    if (selectedInstitution[0]) {
      dispatch(tagActions.ownerUpdate(body));
    } else {
      dispatch(tagActions.ownerCreate(body));
    }
  };

  // add merged (aka substituted) tags
  const addMergedTags = (item) => {
    const clonedTags = cloneDeep(mergedTags);
    setMergedTags([...clonedTags, item]);
    dispatch(
      tagActions.whitelistUpdate({ id: item.id, preferredTagId: data.tag.id })
    );

    setData((oldData) => ({
      ...oldData,
      alwaysMatch: true,
    }));
  };

  // remove merged (aka substituted) tags
  const removeMergedTags = (item) => {
    const clonedTags = cloneDeep(mergedTags);
    setMergedTags(clonedTags.filter((i) => i.id !== item.id));

    dispatch(tagActions.whitelistUpdate({ id: item.id, preferredTagId: null }));
  };

  // add product (tag) association
  const addSelectedCompetitorProducts = (item) => {
    setSelectedProducts([
      ...selectedProducts,
      {
        id: item.id,
        label: item.label,
      },
    ]);
    dispatch(
      tagActions.tagAssociationCreate({
        from: data.tag.id,
        to: item.id,
        relationship: "COMPETITOR",
      })
    );
  };

  // remove product (tag) association
  const removeSelectedCompetitorProducts = (item) => {
    setSelectedProducts(selectedProducts.filter((i) => i.id !== item.id));
    dispatch(
      tagActions.tagAssociationDelete({ from: data.tag.id, to: item.id })
    );
  };

  // add tag monitor rule
  const addTagMonitorRules = (item, include, includeGraph) => {
    const _total = [
      ...includedTagMonitorRules,
    ];
    const exist = _total.filter((r) => r.id === item.id);
    // there can only be one rule combining inclusions / exclusions
    // TODO : Show error message - "Same tag cannot be in inclusion / exclusion list"
    if (exist.length > 0) return;

    // if (include && includeGraph) {
    //   setIncludedTagMonitorRules([
    //     ...includedTagMonitorRules,
    //     {
    //       id: item.id,
    //       label: item.label,
    //     },
    //   ]);
    // }

    dispatch(
      tagActions.tagMonitorRuleCreate({
        monitorObjectTagId: data.tag.id,
        tagId: item.id,
        secondTagId: "",
        categorizeForMonitor: include,
        displayInGraphs: includeGraph,
        tagName: item.label, // pseudo field
      })
    );
  };

  // remove tag monitor rule
  const removeTagMonitorRules = (item) => {
    // update local lists
    setIncludedTagMonitorRules(
      includedTagMonitorRules.filter((i) => i.id !== item.id)
    );
    // server update
    dispatch(
      tagActions.tagMonitorRuleDelete({
        monitorObjectTagId: data.tag.id,
        tagId: item.id,
        secondTagId: "",
      })
    );
  };

  const handleSearchMergableTag = (e) => {
    setSearchMergableTag(e);
  };

  const handleSearchTagToInclude = (e) => {
    setSearchTagToInclude(e);
  };

  const addTagPair = () => {
    dispatch(
      tagActions.tagMonitorRuleCreate({
        monitorObjectTagId: data.tag.id,
        tagId: firstTagPair.id,
        secondTagId: secondTagPair.id,
        categorizeForMonitor: true,
        displayInGraphs: true,
      })
    );
    setFirstTagTermPair("");
    setSecondTagTermPair("");
  };

  const removeTagPair = (tags) => {
    dispatch(
      tagActions.tagMonitorRuleDelete({
        monitorObjectTagId: data.tag.id,
        tagId: tags.tagId,
        secondTagId: tags.secondTagId,
      })
    );
  };

  const updateRules = () => {
    dispatch(tagActions.tagMonitorRulesRequest({ id: data.tag.id }));
  };

  const handleChange = ({ value, field }) => {
    setData((oldData) => ({
      ...oldData,
      [field]: value,
    }));

    setErrors((oldError) => ({
      ...oldError,
      [field]: value === "",
    }));
  };

  // product delete
  const handleDelete = () => {
    dispatch(tagActions.delete({ id: data?.tag.id, type: "products" }));
  };

  // product save
  const handleSave = () => {
    setIsSavePressed(true);
    if (isEmpty(errors)) {
      const newData = {
        ...data,
        alwaysMatch: true, // always exact match
      };
      dispatch(productActions.create(newData));
    }
  };

  // product name update
  const handleNameOnBlur = () => {
    if (id && isEmpty(errors)) {
      dispatch(productActions.update(data));
    }
  };

  // product active / inactive update
  const handleSwitchChange = ({ value, field }) => {
    const newData = {
      ...data,
      tag: {
        ...data.tag,
        [field]: value,
      },
    };
    setData(newData);
    id && dispatch(productActions.update(newData));
  };

  const handleMoreTags = (term) => {
    if (!initialTags.fetching) {
      const tagsNewPage = pageTags + 1;
      setPageTags(tagsNewPage);
      requestTags(tagsNewPage, term);
    }
  };

  const handleMoreProducts = () => {
    if (!initialProducts.fetching) {
      const productsNewPage = pageProducts + 1;
      setPageProducts(productsNewPage);
      requestProducts(productsNewPage);
    }
  };

  const handleDateChange = (e) => {
    const start = getISOFormattedDateTimeString(e.startDate);
    const end = getISOFormattedDateTimeString(e.endDate);
    setDates({
      start,
      end,
    });
  };

  return view({
    isEditMode,
    errors,
    handleSave,
    data,
    handleChange,
    handleSwitchChange,
    isSavePressed,
    isNew: !id,
    deleteModal,
    toggleDeleteModal,
    handleDelete,
    handleNameOnBlur,

    dates,
    handleDateChange,
    metrics,
    usersSubscribed,

    tagPairs,
    addTagPair,
    removeTagPair,
    firstTagTermPair,
    setFirstTagTermPair,
    setFirstTagPair,
    secondTagTermPair,
    setSecondTagTermPair,
    secondTagPair,
    setSecondTagPair,
    resetTags,

    institutions: institutions.map((item) => ({
      id: item.id,
      label: item.name,
    })),
    selectedInstitution,
    addSelectedInstitution,
    searchInstitution,
    handleSearchInstitution,
    clearInstitution,

    tags,
    addTagMonitorRules,
    removeTagMonitorRules,
    tagsInfiniteScroll: {
      loading: initialTags.fetching,
      hasNextPage: !initialTags.isLastPage,
    },
    handleMoreTags,

    allIncludedTagMonitorRules,
    includedTagMonitorRules,
    searchTagToInclude,
    handleSearchTagToInclude,

    mergableTags, // tags which can be merged
    mergedTags, // tags which are already merged
    addMergedTags,
    removeMergedTags,
    searchMergableTag,
    handleSearchMergableTag,

    competitorProducts: products.filter((item) => item.id !== id),
    selectedProducts,
    addSelectedCompetitorProducts,
    removeSelectedCompetitorProducts,
    searchProduct,
    setSearchProduct,
    productsInfiniteScroll: {
      loading: initialProducts.fetching,
      hasNextPage: !initialProducts.isLastPage,
      onLoadMore: handleMoreProducts,
    },
  });
};
