import { createAction, createActionTypes } from "../../utils";

export const MONITOR_ACTIONS = createActionTypes("MONITOR_ACTIONS", [
  "ONE_REQUEST",
  "ONE_REQUEST",
  "ONE_SUCCESS",
  "ONE_FAILURE",
  "ONE_CLEAR",

  "REQUEST",
  "SUCCESS",
  "FAILURE",
  "CLEAR_ALL",

  "SET_MONITORS",
  "CLEAR_MONITORS",

  // for specific user
  "MONITORS_REQUEST",
  "MONITORS_SUCCESS",
  "MONITORS_FAILURE",
  "MONITORS_CLEAR",

  "DATE",
]);

export const monitorActions = {
  setMonitors: (data) =>
    createAction(MONITOR_ACTIONS.SET_MONITORS, {
      data,
    }),
  clearMonitors: () => createAction(MONITOR_ACTIONS.CLEAR_MONITORS, {}),

  date: (data) =>
    createAction(MONITOR_ACTIONS.DATE, {
      data,
    }),

  request: (data) =>
    createAction(MONITOR_ACTIONS.REQUEST, {
      ...data,
      fetching: true,
      success: false,
      error: null,
    }),
  success: ({ data, pageNum }) =>
    createAction(MONITOR_ACTIONS.SUCCESS, {
      data,
      pageNum,
      fetching: false,
      success: true,
      error: null,
    }),
  failure: (error) =>
    createAction(MONITOR_ACTIONS.FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),
  clearAll: () =>
    createAction(MONITOR_ACTIONS.CLEAR_ALL, {
      data: [],
      fetching: true,
      success: false,
      error: null,
    }),

  // for specific user
  oneRequest: (id) =>
    createAction(MONITOR_ACTIONS.ONE_REQUEST, {
      id,
      fetching: true,
      success: false,
      error: null,
    }),
  oneSuccess: (data) =>
    createAction(MONITOR_ACTIONS.ONE_SUCCESS, {
      ...data,
      fetching: false,
      success: true,
      error: null,
    }),
  oneFailure: (error) =>
    createAction(MONITOR_ACTIONS.ONE_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),
  oneClear: () =>
    createAction(MONITOR_ACTIONS.ONE_CLEAR, {
      data: [],
      fetching: true,
      success: false,
      error: null,
    }),

  monitorsRequest: (id) =>
    createAction(MONITOR_ACTIONS.MONITORS_REQUEST, {
      id,
      fetching: true,
      success: false,
      error: null,
    }),
  monitorsSuccess: (data) =>
    createAction(MONITOR_ACTIONS.MONITORS_SUCCESS, {
      ...data,
      fetching: false,
      success: true,
      error: null,
    }),
  monitorsFailure: (error) =>
    createAction(MONITOR_ACTIONS.MONITORS_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  monitorsClear: () =>
    createAction(MONITOR_ACTIONS.MONITORS_CLEAR, {
      data: [],
      fetching: true,
      success: false,
      error: null,
    }),
};
