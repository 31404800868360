import React, { useEffect, useRef, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { isEqual, cloneDeep } from "lodash";

import { Color } from "../utils/variables";

export const MicroLineChart = ({ id, data }) => {
  const chartRef = useRef(null);
  const CHART_ID = id || "MicroLineChart";
  const [previousData, setPreviousData] = useState([]);

  // Add series
  function makeSeries(valueY, categoryX) {
    var series = chartRef.current.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = valueY;
    series.dataFields.categoryX = categoryX;
    series.name = valueY;
    series.tooltipText = "{categoryX}: [bold]{valueY}";
    series.strokeWidth = 2;
    // series.smoothing = "monotoneX";
    series.tensionX = 0.8;

    // Adding end bullet
    let bullet = series.bullets.push(new am4charts.CircleBullet());
    bullet.circle.opacity = 1;
    bullet.circle.propertyFields.opacity = "opacity";
    bullet.circle.radius = 2;

    var segment = series.segments.template;
    segment.interactionsEnabled = true;

    var hs = segment.states.create("hover");
    hs.properties.strokeWidth = 4;
  }

  const createChart = () => {
    am4core.useTheme(am4themes_animated);
    am4core.options.commercialLicense = true;

    chartRef.current = am4core.create(CHART_ID, am4charts.XYChart);

    chartRef.current.data = data;

    chartRef.current.colors.list = [
      am4core.color(Color("green")),
      am4core.color(Color("pink")),
      am4core.color(Color("purple")),
      am4core.color(Color("red")),
      am4core.color(Color("yellow")),
      am4core.color(Color("blue")),
    ];

    // Removing chart padding
    chartRef.current.padding(0, 0, 0, 0);

    // Create axes
    var categoryAxis = chartRef.current.xAxes.push(
      new am4charts.CategoryAxis()
    );
    categoryAxis.dataFields.category = "time";
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.labels.template.disabled = true;

    var valueAxis = chartRef.current.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.renderer.baseGrid.disabled = true;
    valueAxis.renderer.labels.template.disabled = true;

    // Create series
    const seriesLabel = [];
    data.forEach((item, index) => {
      Object.keys(item).forEach((k) => {
        if (k !== "time" && !seriesLabel.includes(k)) {
          seriesLabel.push(k);
        }
      });
    });

    seriesLabel.forEach((item) => {
      makeSeries(item, "time");
    });

    // Add cursor
    chartRef.current.cursor = new am4charts.XYCursor();
    // Stripping down cursor
    chartRef.current.cursor.lineX.disabled = false;
    chartRef.current.cursor.lineY.disabled = false;

    // Disable axis tooltips
    categoryAxis.cursorTooltipEnabled = false;
    valueAxis.cursorTooltipEnabled = false;
    // Disable zoom
    chartRef.current.cursor.behavior = "none";
  };

  useEffect(() => {
    return () => {
      chartRef.current && chartRef.current.dispose();
    };
  }, []);

  useEffect(() => {
    // deep compare
    if (!isEqual(data, previousData)) {
      // change state only when there is a change
      chartRef.current && chartRef.current.dispose();
      createChart();
      setPreviousData(cloneDeep(data)); // save for next compare
    }
  }, [data]);

  return (
    <div>
      <div
        id={CHART_ID}
        style={{
          width: "300px",
          height: "30px",
          margin: "0 0 0 0",
        }}
      />
    </div>
  );
};
