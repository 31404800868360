import React, { useEffect, useRef, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { isEqual, cloneDeep } from "lodash";

import { Color } from "../utils/variables";

export const LineChart = ({ id, data }) => {
  const chartRef = useRef(null);
  const legendRef = useRef(null);
  const CHART_ID = id || "LineColumnChart";
  const LEGEND_ID = CHART_ID + "LEGEND";
  const [previousData, setPreviousData] = useState([]);

  // Add series
  function makeSeries(valueY, categoryX) {
    var series = chartRef.current.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = valueY;
    series.dataFields.categoryX = categoryX;
    series.name = valueY;
    series.tooltipText = "{name}: [bold]{valueY}[/]";
    series.strokeWidth = 3;
    // series.smoothing = "monotoneX";
    series.tensionX = 0.9;

    // // Add label
    // var labelBullet = series.bullets.push(new am4charts.LabelBullet());
    // labelBullet.label.text = "{valueY}";
    // labelBullet.locationY = 0.5;
    // labelBullet.label.hideOversized = true;

    var bullet = series.bullets.push(new am4charts.CircleBullet());
    bullet.circle.stroke = am4core.color("#fff");
    bullet.circle.strokeWidth = 2;
    
    var segment = series.segments.template;
    segment.interactionsEnabled = true;
    
    var hs = segment.states.create("hover");
    hs.properties.strokeWidth = 6;
  }

  const createChart = () => {
    am4core.useTheme(am4themes_animated);
    am4core.options.commercialLicense = true;

    chartRef.current = am4core.create(CHART_ID, am4charts.XYChart);

    chartRef.current.data = data;

    chartRef.current.colors.list = [
      am4core.color(Color("pink")),
      am4core.color(Color("purple")),
      am4core.color(Color("red")),
      am4core.color(Color("yellow")),
      am4core.color(Color("blue")),
      am4core.color(Color("green")),
    ];

    // Create axes
    var categoryAxis = chartRef.current.xAxes.push(
      new am4charts.CategoryAxis()
    );
    categoryAxis.dataFields.category = "time";
    // categoryAxis.title.text = "Date";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 20;

    var valueAxis = chartRef.current.yAxes.push(new am4charts.ValueAxis());

    // Create series
    const seriesLabel = [];
    data.forEach((item, index) => {
      Object.keys(item).forEach((k) => {
        if (k !== "time" && !seriesLabel.includes(k)) {
          seriesLabel.push(k);
        }
      });
    });

    seriesLabel.forEach((item) => {
      makeSeries(item, "time");
    });

    // Rotating labels
    let label = categoryAxis.renderer.labels.template;
    label.rotation = -30;
    label.fontSize = 11;
    label.horizontalCenter = "right";
    label.verticalCenter = "middle";

    // Add cursor
    chartRef.current.cursor = new am4charts.XYCursor();

    // Disable axis lines
    // chartRef.current.cursor.lineX.disabled = true;
    // chartRef.current.cursor.lineY.disabled = true;

    // Disable axis tooltips
    categoryAxis.cursorTooltipEnabled = false;
    valueAxis.cursorTooltipEnabled = false;

    // Disable zoom
    chartRef.current.cursor.behavior = "none";

    // Legend
    legendRef.current = am4core.create(LEGEND_ID, am4core.Container);
    legendRef.current.width = am4core.percent(100);
    legendRef.current.height = am4core.percent(100);
    // Legend
    var legend = new am4charts.Legend();
    chartRef.current.legend = legend;
    legend.parent = legendRef.current; // chartRef.current.chartContainer;
    // legend.itemContainers.template.togglable = false;
    legend.marginTop = 20;
    legend.marginBottom = -20;
    legend.scrollable = true;
  };

  useEffect(() => {
    return () => {
      chartRef.current && chartRef.current.dispose();
      legendRef.current && legendRef.current.dispose();
    };
  }, []);

  useEffect(() => {
    // deep compare
    if (!isEqual(data, previousData)) {
      // change state only when there is a change
      chartRef.current && chartRef.current.dispose();
      legendRef.current && legendRef.current.dispose();
      createChart();
      setPreviousData(cloneDeep(data)); // save for next compare
    }
  }, [data]);

  return (
    <div>
      <div
        id={CHART_ID}
        style={{
          width: "100%",
          height: "300px",
          margin: "20px 0 0 0",
        }}
      />
      <div
        id={LEGEND_ID}
        style={{
          width: "100%",
          height: "80px",
          // margin: "20px 0",
        }}
      />
    </div>
  );
};
