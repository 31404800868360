/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from "react";
import moment from "moment";
import styled, { css } from "styled-components";
import { Calendar, DateRange as ReactDateRange } from "react-date-range";
import PropTypes from "prop-types";

import "./styles/react-date-range.css";
import "./styles/react-date-range-theme.css";

import { Row, Column } from "./Layout";
import { Icon } from "./Icon";
import { Label } from "./Typography";
import { Button } from "./Button";
import { Dropdown } from "./Dropdown";
import { formatShortDates } from "../utils";
import { Color, device } from "../utils/variables";

const textFormat = "MMM D, YYYY";

export const DatePicker = (props) => {
  const { icon, small, noFutureDates, allTime } = props;

  const [isListVisible, setListVisibility] = useState(false);
  const toggleList = React.useCallback(() => {
    setListVisibility(!isListVisible);
  });
  const [initialDate, setInitialDate] = useState(props.initialDate || moment());

  const onChange = (date) => {
    props.onChange && props.onChange(moment(date));
    setListVisibility(!isListVisible);
    setInitialDate(moment(date));
  };

  return (
    <Container {...{ small }}>
      <ToggleButton {...{ small }} onClick={() => toggleList()}>
        <Row>
          <Label large={!small} semibold={!small} onClick={() => toggleList()}>
            {initialDate.format(textFormat)}
          </Label>
        </Row>
        <Icon color="gray" fade="8" name={icon || "arrowDropDown"} />
      </ToggleButton>
      <CalendarContainer
        editMode={props.editMode}
        className={isListVisible ? "visible" : ""}
      >
        <CalendarBackdrop onClick={() => setListVisibility(false)} />
        <Calendar
          date={moment(initialDate)._d}
          onChange={onChange}
          minDate={
            props.allTime
              ? moment("01 Jan 2000")._d
              : moment().subtract(1, "years")._d
          }
          maxDate={noFutureDates ? moment()._d : moment().endOf("year")._d}
          weekStartsOn={1}
        />
      </CalendarContainer>
    </Container>
  );
};

export const DateRange = (props) => {
  const [isListVisible, setListVisibility] = useState(
    props.showCalendar || false
  );
  const toggleList = React.useCallback(() => {
    setListVisibility(!isListVisible);
    props.setShowCalendar?.(!isListVisible);
  });
  const [initialDate, setInitialDate] = React.useState(
    props.initialDate || moment()
  );
  const [endDate, setEndDate] = React.useState(props.endDate || moment());
  const [selectedPeriod, setSelectedPeriod] = React.useState("0");

  const setNewDate = () => {
    props.onChange &&
      props.onChange({
        startDate: moment(rangeDates[0].startDate).startOf("day")._i,
        endDate: moment(rangeDates[0].endDate).endOf("day")._i,
        selectedPeriod,
      });
    setListVisibility(false);
    props.setShowCalendar?.(false);
    setInitialDate(moment(rangeDates[0].startDate).startOf("day"));
    setEndDate(moment(rangeDates[0].endDate).endOf("day"));
  };

  const resetNewDate = () => {
    // TODO: review period names after pressing cancel, going back to custom
    setListVisibility(false);
    props.setShowCalendar?.(false);
    // resetPeriod();
    setTimeout(() => {
      updateRanges();
    }, 200);
  };

  const updateRanges = () => {
    setRangeDates([
      {
        startDate: moment(initialDate).startOf("day")._d,
        endDate: moment(endDate).endOf("day")._d,
        key: "selection",
      },
    ]);
  };

  const [rangeDates, setRangeDates] = useState([
    {
      startDate: moment(initialDate).startOf("day")._d,
      endDate: moment(endDate).endOf("day")._d,
      key: "selection",
    },
  ]);

  const [toggleButtonText, setToggleButtonText] = useState("");

  let periodItems = [
    { id: "1", label: "Last 7 Days" },
    { id: "2", label: "Last 30 Days" },
    { id: "0", label: "Custom" },
  ];

  if (props.allTime) {
    periodItems = [
      { id: "1", label: "Last 7 Days" },
      { id: "2", label: "Last 30 Days" },
      { id: "3", label: "All time" },
      { id: "0", label: "Custom" },
    ];
  }

  const resetPeriod = () => {
    // TODO: We should compare the list of periods to select the correct one
    // Now setting it to `Custom`
    // if (selectedPeriod !== "0") {
    //   setSelectedPeriod("0");
    // }
  };

  const periodOnChange = (e) => {
    setSelectedPeriod(e.id);
    switch (e.id) {
      case "1": // last 7 days
        setRangeDates([
          {
            startDate: moment().subtract(6, "days").startOf("day")._d,
            endDate: moment().endOf("day")._d,
            key: "selection",
          },
        ]);
        break;
      case "2": // last 30 days
        setRangeDates([
          {
            startDate: moment().subtract(1, "month").startOf("day")._d,
            endDate: moment().endOf("day")._d,
            key: "selection",
          },
        ]);
        break;
      case "3": // All time
        setRangeDates([
          {
            startDate: moment("01 Jan 2000").startOf("day")._d,
            endDate: moment().endOf("day")._d,
            key: "selection",
          },
        ]);
        break;

      default:
        // starting first day of previous month, ending today
        setRangeDates([
          {
            startDate: moment().subtract(1, "month").startOf("month")._d,
            endDate: moment().endOf("day")._d,
            key: "selection",
          },
        ]);
        break;
    }
  };

  React.useEffect(() => {
    if (props.showCalendar) {
      setListVisibility(true);
    }
  }, [props?.showCalendar]);

  React.useEffect(() => {
    updateRanges();
  }, [initialDate, endDate]);

  React.useEffect(() => {
    setInitialDate(props.initialDate);
    setEndDate(props.endDate);
    setRangeDates([
      {
        startDate: moment(props.initialDate).startOf("day")._d,
        endDate: moment(props.endDate).endOf("day")._d,
        key: "selection",
      },
    ]);
  }, [props.initialDate, props.endDate]);

  React.useEffect(() => {
    const formatedDates = formatShortDates(
      rangeDates[0].startDate,
      rangeDates[0].endDate
    );
    setToggleButtonText(formatedDates);
    if (props.period) {
      updatePeriod();
    }
  }, [rangeDates]);

  React.useEffect(() => {
    if (selectedPeriod !== "0") {
      const periodLabel = periodItems.find(
        (i) => i.id === selectedPeriod
      )?.label;
      if (periodLabel) {
        setToggleButtonText(periodLabel);
      }
    }
  }, [rangeDates, selectedPeriod]);

  const updatePeriod = () => {
    if (isLast7Days()) {
      setSelectedPeriod("1");
    } else if (isLast30Days()) {
      setSelectedPeriod("2");
    } else if (isAllTime()) {
      setSelectedPeriod("3");
    } else {
      setSelectedPeriod("0");
    }
  };

  const isLast30Days = () => {
    const start1 = moment(rangeDates[0].startDate).format("YYYY-MM-DD");
    const start2 = moment().subtract(1, "month").format("YYYY-MM-DD");
    const end1 = moment(rangeDates[0].endDate).format("YYYY-MM-DD");
    const end2 = moment().format("YYYY-MM-DD");

    return start1 === start2 && end1 === end2;
  };

  const isLast7Days = () => {
    const start1 = moment(rangeDates[0].startDate).format("YYYY-MM-DD");
    const start2 = moment().subtract(6, "days").format("YYYY-MM-DD");
    const end1 = moment(rangeDates[0].endDate).format("YYYY-MM-DD");
    const end2 = moment().format("YYYY-MM-DD");

    return start1 === start2 && end1 === end2;
  };

  const isAllTime = () => {
    const start1 = moment(rangeDates[0].startDate).format("YYYY-MM-DD");
    const start2 = moment("01 Jan 2000").format("YYYY-MM-DD");
    const end1 = moment(rangeDates[0].endDate).format("YYYY-MM-DD");
    const end2 = moment().format("YYYY-MM-DD");

    return start1 === start2 && end1 === end2;
  };

  return (
    <Container noBackground={props.noBackground}>
      <ToggleButton
        onClick={() => toggleList()}
        noBackground={props.noBackground}
      >
        {props.label && (
          <Row marginRight="10">
            <Label bold>{props.label}</Label>
          </Row>
        )}
        <Row fit={!props.noBackground} marginRight="10" truncate>
          <Label
            semibold
            truncate
            onClick={() => toggleList()}
            style={props.noBackground && { borderBottom: "1px solid black" }}
          >
            {toggleButtonText}
          </Label>
        </Row>
        <Icon
          name="arrowDropDown"
          className={isListVisible ? "rotate180" : ""}
        />
      </ToggleButton>

      <CalendarContainer
        editMode={props.editMode}
        className={isListVisible ? "visible" : ""}
      >
        <CalendarBackdrop onClick={() => resetNewDate()} />
        <CalendarColumn>
          {props.period && (
            <Period middle>
              <Dropdown
                label="Period"
                items={periodItems}
                value={selectedPeriod}
                onChange={periodOnChange}
              />
            </Period>
          )}
          <Row
            show="tablet"
            className={props.period ? "range-with-period" : ""}
          >
            <ReactDateRange
              onChange={(item) => {
                // props.period && resetPeriod();
                // replacing endDate time, to be end of day (default start of day)
                item.selection.endDate = moment(item.selection.endDate).endOf(
                  "day"
                )._d;
                setRangeDates([item.selection]);
              }}
              showSelectionPreview={true}
              months={2}
              ranges={rangeDates}
              direction="horizontal"
              rangeColors={[Color("green", 3)]}
              minDate={
                props.allTime
                  ? moment("01 Jan 2000")._d
                  : moment().subtract(1, "years")._d
              }
              maxDate={props.noFutureDates ? moment()._d : undefined}
              weekStartsOn={1}
            />
          </Row>
          <Row
            hide="tablet"
            className={props.period ? "range-with-period" : ""}
          >
            <ReactDateRange
              onChange={(item) => {
                props.period && resetPeriod();
                // replacing endDate time, to be end of day (default start of day)
                item.selection.endDate = moment(item.selection.endDate).endOf(
                  "day"
                )._d;
                setRangeDates([item.selection]);
              }}
              showSelectionPreview={true}
              showDateDisplay={false}
              months={1}
              ranges={rangeDates}
              direction="horizontal"
              rangeColors={[Color("green", 3)]}
              minDate={
                props.allTime
                  ? moment("01 Jan 2000")._d
                  : moment().subtract(1, "years")._d
              }
              maxDate={props.noFutureDates ? moment()._d : undefined}
              weekStartsOn={1}
            />
          </Row>
          <Row
            paddingX
            paddingBottom="40"
            marginTop="-10"
            center
            style={{ zIndex: 32 }}
          >
            <Button label="Cancel" secondary onClick={() => resetNewDate()} />
            <Row marginLeft="30">
              <Button label="Apply" onClick={() => setNewDate()} />
            </Row>
          </Row>
        </CalendarColumn>
      </CalendarContainer>
    </Container>
  );
};

const CalendarColumn = styled(Column)`
  background: white;
  z-index: 32;
  position: relative;

  @media ${device.mobileXL} {
    border-radius: 15px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  ${({ noBackground }) =>
    !noBackground &&
    css`
      width: 100%;
    `}
`;

const ToggleButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 4px;
  margin: -4px 0;
  border-radius: 5px;

  ${({ noBackground }) =>
    !noBackground &&
    css`
      background-color: ${Color("gray", 2)};
      border-radius: 15px;
      max-width: 400px;
      width: 100%;
      height: 44px;
      padding: ${(props) => (props.small ? `7px 10px 7px 20px` : `10px 20px`)};
    `}

  &:hover {
    background-color: ${Color("gray", 2)};
  }
`;

const CalendarBackdrop = styled.div`
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  z-index: 30;
`;

const Period = styled(Row)`
  position: absolute;
  top: 32px;
  left: 30px;
  z-index: 32;
`;

const CalendarContainer = styled.div`
  position: fixed;
  ${(props) =>
    props.editMode
      ? `top: -40px;`
      : css`
          top: 63px;
          overflow-y: auto;
          @media ${device.mobileXL} {
            padding: 50px 0;
          }
        `}
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  opacity: 0;
  z-index: 31;
  transition: all 0.2s ease;
  pointer-events: none;

  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  .rdrCalendarWrapper {
    z-index: 31;
    border-radius: 15px;
    padding: 0 10px;
    min-height: 374px;
  }

  .rdrDateDisplayWrapper {
    background: transparent;
  }

  .rdrNextPrevButton {
    background: transparent;
    &:focus {
      outline: 0;
    }
  }

  .rdrPprevButton i {
    border-right-color: ${Color("green")};
    border-width: 7px 11px 7px 7px;
  }

  .rdrNextButton i {
    border-left-color: ${Color("green")};
    border-width: 7px 7px 7px 11px;
  }

  .rdrSelected {
    border-radius: 0;
    background-color: ${Color("green")};
  }

  .rdrMonthAndYearWrapper {
    height: 70px;
  }

  .rdrDay {
    font-size: 14px;
  }

  .rdrDayStartPreview.rdrDayEndPreview {
    border: none !important ;
  }

  .rdrDayHovered .rdrDayNumber:after {
    display: none !important;
  }

  .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
    color: black;
  }

  .rdrDateRangeWrapper {
    padding: 20px;
    background: white;
    min-height: 464px;

    @media ${device.tablet} {
      min-height: 528px;
    }
  }

  .range-with-period .rdrDateRangeWrapper {
    padding: 80px 20px 20px;
    min-height: 530px;

    @media ${device.tablet} {
      padding: 20px;
    }
  }

  .rdrDateDisplay {
    background: white;
    justify-content: flex-end;
  }

  .rdrDateDisplayItem {
    box-shadow: unset;
    border-radius: 8px;
    background-color: ${Color("gray", 0.2)};
    border-width: 2px;
    width: 120px;
    flex: 0 1 auto;
  }

  .rdrDateDisplayItem + .rdrDateDisplayItem {
    margin-left: 35px;
  }

  .rdrDateDisplayItem:before {
    position: absolute;
    left: -40px;
    top: 12px;
    content: "from";
    color: ${Color("gray", 0.5)};
  }
  .rdrDateDisplayItem + .rdrDateDisplayItem:before {
    left: -25px;
    content: "to";
  }

  .rdrDateDisplayItemActive {
    border-color: ${Color("green")};
  }

  .rdrDateDisplayItem {
    border-radius: 15px;
    background: transparent;

    input {
      height: 40px;
      line-height: 40px;
      color: black;
      font-size: 14px;
      border-radius: 13px;
      background: ${Color("gray", 2)};
    }
  }

  .rdrDayStartPreview,
  .rdrDayInPreview,
  .rdrDayEndPreview {
    border: none;
    border-radius: 0;
  }

  .rdrDayHovered:not(.rdrDayPassive) .rdrDayNumber {
    background-color: ${Color("green", 3)};
  }

  .rdrDayHovered:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber,
  .rdrDayHovered:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber {
    background-color: ${Color("green")};
  }

  .rdrDay:not(.rdrDayPassive) .rdrDayStartPreview ~ .rdrDayNumber,
  .rdrDay:not(.rdrDayPassive) .rdrDayEndPreview ~ .rdrDayNumber {
    background-color: ${Color("green")};
  }

  .rdrDay:not(.rdrDayPassive) .rdrDayInPreview ~ .rdrDayNumber {
    background-color: ${Color("green", 3)};
  }

  .rdrDay:not(.rdrDayPassive)
    .rdrDayStartPreview.rdrDayEndPreview
    ~ .rdrDayNumber {
    background-color: ${Color("green", 3)};
  }

  .rdrInRange,
  .rdrStartEdge,
  .rdrEndEdge {
    left: 0;
    right: 0;
    border-radius: 0;
  }

  .rdrStartEdge,
  .rdrEndEdge {
    background-color: ${Color("green")} !important;
  }

  .rdrDayToday .rdrDayNumber span {
    font-family: "Oxigen-Bold";
    font-weight: normal;
    color: black;
  }

  .rdrDayToday .rdrDayNumber span:after {
    display: none;
  }

  .rdrMonthName {
    text-align: center;
    color: black;
    font-size: 16px;
    font-weight: normal;
    font-family: "Oxigen-Bold";
  }

  .rdrMonthAndYearPickers select {
    color: black;
    font-size: 16px;
    border-radius: 8px;
  }

  .rdrMonthAndYearPickers select:hover {
    background-color: ${Color("green", 3)};
  }

  hr {
    width: 1px;
    margin: 0 10px;
    border: none;
  }
`;

DatePicker.propTypes = {
  initialDate: PropTypes.any,
  onChange: PropTypes.func,
  expand: PropTypes.bool,
  small: PropTypes.bool,
  noFutureDates: PropTypes.bool,
  period: PropTypes.bool,
  icon: PropTypes.string,
};

DateRange.propTypes = {
  initialDate: PropTypes.any,
  endDate: PropTypes.any,
  onChange: PropTypes.func,
  noFutureDates: PropTypes.bool,
};
