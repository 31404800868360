import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { view } from "./PricingView";
import { planActions } from "../../store/actions";
import { cancelPlanRequests } from "../../store/sagas";
import { logAnalyticEvent } from "../../utils";
import { getApiPath } from "../../utils/variables";

export const Pricing = () => {
  const dispatch = useDispatch();
  // const sync = useSelector((state) => state.plan.sync);
  const userPlans = useSelector((state) => state.plan.userPlans);
  const plans = useSelector((state) => state.plan.all);
  const auth = useSelector((state) => state.auth.data);
  // const isAdmin = auth?.mwRole === "ADMIN";

  const [planPrice, setPlanPrice] = useState({});
  const [period, setPeriod] = useState("MONTH");
  const [userPlan, setUserPlan] = useState(null);

  const periods = useRef([
    { id: "YEAR", label: "Yearly" },
    { id: "MONTH", label: "Monthly" },
  ]);

  const cookies = useSelector((state) => state.settings.cookies);

  useEffect(() => {
    dispatch(planActions.request({ active: true }));
    window.scrollTo(0, 0);

    // check for cookies first before publishing event
    if (!auth?.active && cookies?.seen && !cookies?.accepted) {
      return;
    }
    // call common helper function
    logAnalyticEvent(
      "Pricing",
      {},
      auth?.id || null,
      auth?.displayName || null,
      auth?.email || null,
      auth?.createdAt || null,
      auth.data?.preferences?.filter(
        (p) => p.preferenceName === "UserType"
      )?.[0]?.preferenceStringValue || "MEDICAL",
      userPlans?.data?.[0]?.plan?.name || null
    );
    return () => {
      dispatch(planActions.clear());
      cancelPlanRequests.abort();
    };
  }, []);

  useEffect(() => {
    if (auth?.active) {
      dispatch(planActions.userPlansRequest({ userId: auth.id }));
    }
  }, [auth]);

  useEffect(() => {
    if (!userPlans?.data?.[0]) {
      return;
    }
    if (userPlans?.data?.[0].plan.name.includes("Basic")) {
      setUserPlan("BASIC");
    }
    if (userPlans?.data?.[0].plan.name.includes("Pro")) {
      setUserPlan("PRO");
    }
  }, [userPlans]);

  useEffect(() => {
    if (plans.data?.length > 0) {
      setPlanPrice({
        basicDaily: plans.data.find(
          (p) => p.name.includes("Basic") && p.period === "DAY" && p.active
        ),
        basicMonthly: plans.data.find(
          (p) => p.name.includes("Basic") && p.period === "MONTH" && p.active
        ),
        basicYearly: plans.data.find(
          (p) => p.name.includes("Basic") && p.period === "YEAR" && p.active
        ),
        proDaily: plans.data.find(
          (p) => p.name.includes("Pro") && p.period === "DAY" && p.active
        ),
        proMonthly: plans.data.find(
          (p) => p.name.includes("Pro") && p.period === "MONTH" && p.active
        ),
        proYearly: plans.data.find(
          (p) => p.name.includes("Pro") && p.period === "YEAR" && p.active
        ),
        enterprise: plans.data.find(
          (p) => p.name.includes("Enterprise") && p.active
        ),
      });
      // const basicActivePlans = plans.data
      //   .filter((p) => p.name.includes("Basic") && p.active)
      //   .map((p) => p.period);
      // const proActivePlans = plans.data
      //   .filter((p) => p.name.includes("Pro") && p.active)
      //   .map((p) => p.period);
      // setBasicPeriods([
      //   ...periods.filter((p) => basicActivePlans.includes(p.id)),
      // ]);
      // setProPeriods([...periods.filter((p) => proActivePlans.includes(p.id))]);
    }
  }, [plans.data]);

  useEffect(() => {
    if (planPrice?.basicDaily) {
      const hasDay = periods.current.find((p) => p.id === "DAY");
      if (!hasDay) {
        periods.current.push({ id: "DAY", label: "Daily" });
      }
    }
  }, [planPrice]);

  const goToSignup = () => {
    window.location.href = "/";

    // check for cookies first before publishing event
    if (!auth?.active && cookies?.seen && !cookies?.accepted) {
      return;
    }
    // call common helper function
    logAnalyticEvent(
      "Pricing Click",
      { plan: "Free" },
      auth?.id || null,
      auth?.displayName || null,
      auth?.email || null,
      auth?.createdAt || null,
      auth.data?.preferences?.filter(
        (p) => p.preferenceName === "UserType"
      )?.[0]?.preferenceStringValue || "MEDICAL",
      userPlans?.data?.[0]?.plan?.name || null
    );
  };

  const goToBasic = () => {
    const priceId =
      period === "YEAR"
        ? planPrice?.basicYearly?.externalPriceId
        : period === "DAY"
        ? planPrice?.proDaily?.externalPriceId
        : planPrice?.basicMonthly?.externalPriceId;
    const skipTrial = "&skip_trial=true"; // period === "DAY" ? "&skip_trial=true" : "";
    if (!priceId) return;

    window.location.href = `${getApiPath(
      false
    )}/create-checkout-session?price_id=${priceId}${skipTrial}${
      auth?.email ? `&email=${auth.email}` : ""
    }`;

    // check for cookies first before publishing event
    if (!auth?.active && cookies?.seen && !cookies?.accepted) {
      return;
    }
    // call common helper function
    logAnalyticEvent(
      "Pricing Click",
      { plan: "Basic", billingFrequency: period },
      auth?.id || null,
      auth?.displayName || null,
      auth?.email || null,
      auth?.createdAt || null,
      auth.data?.preferences?.filter(
        (p) => p.preferenceName === "UserType"
      )?.[0]?.preferenceStringValue || "MEDICAL",
      userPlans?.data?.[0]?.plan?.name || null
    );
  };

  const goToPro = () => {
    const priceId =
      period === "YEAR"
        ? planPrice?.proYearly?.externalPriceId
        : period === "DAY"
        ? planPrice?.proDaily?.externalPriceId
        : planPrice?.proMonthly?.externalPriceId;
    const skipTrial = "&skip_trial=true"; // period === "DAY" ? "&skip_trial=true" : "";
    if (!priceId) return;

    window.location.href = `${getApiPath(
      false
    )}/create-checkout-session?price_id=${priceId}${skipTrial}${
      auth?.email ? `&email=${auth.email}` : ""
    }`;

    // check for cookies first before publishing event
    if (!auth?.active && cookies?.seen && !cookies?.accepted) {
      return;
    }
    // call common helper function
    logAnalyticEvent(
      "Pricing Click",
      { plan: "Pro", billingFrequency: period },
      auth?.id || null,
      auth?.displayName || null,
      auth?.email || null,
      auth?.createdAt || null,
      auth.data?.preferences?.filter(
        (p) => p.preferenceName === "UserType"
      )?.[0]?.preferenceStringValue || "MEDICAL",
      userPlans?.data?.[0]?.plan?.name || null
    );
  };

  const goToSales = () => {
    window.open(
      "mailto:hello@medical.watch?subject=Interested in Medical.watch for my team",
      "_blank"
    );

    // check for cookies first before publishing event
    if (!auth?.active && cookies?.seen && !cookies?.accepted) {
      return;
    }
    // call common helper function
    logAnalyticEvent(
      "Pricing Click",
      { plan: "Enterprise" },
      auth?.id || null,
      auth?.displayName || null,
      auth?.email || null,
      auth?.createdAt || null,
      auth.data?.preferences?.filter(
        (p) => p.preferenceName === "UserType"
      )?.[0]?.preferenceStringValue || "MEDICAL",
      userPlans?.data?.[0]?.plan?.name || null
    );
  };

  const handlePeriodChange = (e) => {
    setPeriod(e.id);

    // check for cookies first before publishing event
    if (!auth?.active && cookies?.seen && !cookies?.accepted) {
      return;
    }
    // call common helper function
    logAnalyticEvent(
      "Pricing - Billing Frequency Change",
      { billingFrequency: e.id },
      auth?.id || null,
      auth?.displayName || null,
      auth?.email || null,
      auth?.createdAt || null,
      auth.data?.preferences?.filter(
        (p) => p.preferenceName === "UserType"
      )?.[0]?.preferenceStringValue || "MEDICAL",
      userPlans?.data?.[0]?.plan?.name || null
    );
  };

  const handleConferenceListClick = () => {
    // check for cookies first before publishing event
    if (!auth?.active && cookies?.seen && !cookies?.accepted) {
      return;
    }
    // call common helper function
    logAnalyticEvent(
      "Pricing - Conference List Click",
      {},
      auth?.id || null,
      auth?.displayName || null,
      auth?.email || null,
      auth?.createdAt || null,
      auth.data?.preferences?.filter(
        (p) => p.preferenceName === "UserType"
      )?.[0]?.preferenceStringValue || "MEDICAL",
      userPlans?.data?.[0]?.plan?.name || null
    );
  };

  const handleSignUpClick = () => {
    // check for cookies first before publishing event
    if (!auth?.active && cookies?.seen && !cookies?.accepted) {
      return;
    }
    // call common helper function
    logAnalyticEvent(
      "Sign Up",
      {
        page: "Pricing",
      },
      queryParams?.u || null,
      queryParams?.n || null,
      queryParams?.e || null,
      null,
      queryParams?.t || null
    );
  };

  return view({
    goToBasic,
    goToSignup,
    goToPro,
    goToSales,

    period,
    periods: periods.current,
    handlePeriodChange,

    handleSignUpClick,
    handleConferenceListClick,

    planPrice,
    userPlan,
    isActive: auth?.active,
  });
};
