import { TAG_ACTIONS } from "../actions";
import { MAX_RESULTS } from "../../utils/variables";

const initialState = {
  all: { data: [], fetching: false, success: false, error: null },
  one: { data: [], fetching: false, success: false, error: null },
  whitelist: { data: [], fetching: false, success: false, error: null },
  tagsAssociated: { data: [], fetching: false, success: false, error: null },
  tagAssociated: { data: [], fetching: false, success: false, error: null },
  tagMonitorRules: { data: [], fetching: false, success: false, error: null },
  tagMonitorRule: { data: [], fetching: false, success: false, error: null },
  adminSummary: { data: [], fetching: false, success: false, error: null },
  owner: { data: [], fetching: false, success: false, error: null },
};

export default function tagReducer(state = initialState, action) {
  switch (action.type) {
    case TAG_ACTIONS.REQUEST:
    case TAG_ACTIONS.FAILURE:
      return { ...state, all: { ...state.all, ...action.payload } };
    case TAG_ACTIONS.SUCCESS:
      // If we have pagination, we need to merge state data & payload data
      const newTagState = {
        ...state,
        all: {
          ...state.all,
          ...action.payload,
          isLastPage: action.payload.data.length < MAX_RESULTS,
        },
      };
      if (action.payload.pageNum && action.payload.pageNum !== 0) {
        newTagState.all.data = [...state.all.data, ...action.payload.data];
      }
      return newTagState;

    case TAG_ACTIONS.ONE_REQUEST:
    case TAG_ACTIONS.ONE_SUCCESS:
    case TAG_ACTIONS.ONE_FAILURE:
    case TAG_ACTIONS.CREATE:
    case TAG_ACTIONS.UPDATE:
    case TAG_ACTIONS.DELETE:
    case TAG_ACTIONS.CLEAR:
      return { ...state, one: action.payload };

    case TAG_ACTIONS.CLEAR_TAGS:
      return {
        ...state,
        whitelist: action.payload,
        tagsAssociated: action.payload,
        tagAssociated: action.payload,
        tagMonitorRules: action.payload,
        owner: action.payload,
        adminSummary: action.payload,
        adminSummaryCount: action.payload,
      };

    case TAG_ACTIONS.WHITELIST_REQUEST:
    case TAG_ACTIONS.WHITELIST_SUCCESS:
    case TAG_ACTIONS.WHITELIST_FAILURE:
    case TAG_ACTIONS.WHITELIST_UPDATE:
      return { ...state, whitelist: action.payload };

    case TAG_ACTIONS.TAG_ASSOCIATIONS_REQUEST:
    case TAG_ACTIONS.TAG_ASSOCIATIONS_FAILURE:
    case TAG_ACTIONS.TAG_ASSOCIATIONS_SUCCESS:
      return { ...state, tagsAssociated: action.payload };

    case TAG_ACTIONS.TAG_ASSOCIATION_CREATE:
    case TAG_ACTIONS.TAG_ASSOCIATION_DELETE:
    case TAG_ACTIONS.TAG_ASSOCIATION_FAILURE:
    case TAG_ACTIONS.TAG_ASSOCIATION_SUCCESS:
      return { ...state, tagAssociated: action.payload };

    case TAG_ACTIONS.TAG_MONITOR_RULES_REQUEST:
    case TAG_ACTIONS.TAG_MONITOR_RULES_FAILURE:
    case TAG_ACTIONS.TAG_MONITOR_RULES_SUCCESS:
      return { ...state, tagMonitorRules: action.payload };

    case TAG_ACTIONS.TAG_MONITOR_RULE_CREATE:
    case TAG_ACTIONS.TAG_MONITOR_RULE_DELETE:
    case TAG_ACTIONS.TAG_MONITOR_RULE_FAILURE:
    case TAG_ACTIONS.TAG_MONITOR_RULE_SUCCESS:
      return { ...state, tagMonitorRule: action.payload };

    case TAG_ACTIONS.ADMIN_SUMMARY_REQUEST:
    case TAG_ACTIONS.ADMIN_SUMMARY_SUCCESS:
    case TAG_ACTIONS.ADMIN_SUMMARY_FAILURE:
      return {
        ...state,
        adminSummary: { ...state.adminSummary, ...action.payload },
      };

    case TAG_ACTIONS.TAG_OWNER_REQUEST:
    case TAG_ACTIONS.TAG_OWNER_CREATE:
    case TAG_ACTIONS.TAG_OWNER_UPDATE:
    case TAG_ACTIONS.TAG_OWNER_DELETE:
    case TAG_ACTIONS.TAG_OWNER_FAILURE:
    case TAG_ACTIONS.TAG_OWNER_SUCCESS:
      return { ...state, owner: action.payload };

    default:
      return state;
  }
}
