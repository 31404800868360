import React from "react";
import { renderToString } from "react-dom/server";
import { Column, Row } from "./Layout";
import { H3 } from "./Typography";
import styled from "styled-components";

// import { LiveProvider, LiveEditor, LivePreview, LiveError } from "react-live";

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

export const Example = ({
  name,
  code,
  text,
  prepend,
  last,
  comment,
  inline,
}) => (
  <Column paddingBottom={last ? "10" : "60"}>
    <ConditionalWrapper
      condition={inline}
      wrapper={(children) => <Row middle>{children}</Row>}
    >
      <>
        {name && (
          <Row marginBottom="30">
            <H3>{name}</H3>
          </Row>
        )}
        {comment && (
          <Row marginBottom="30" marginTop="-20">
            {comment}
          </Row>
        )}
        {code && <Row marginBottom={inline ? "0" : "30"}>{code}</Row>}
        <Row
          marginLeft={inline ? "20" : "0"}
          bkg="rgb(50, 42, 56)"
          style={{ borderRadius: "8px" }}
        >
          <CodeWrapper>
            <Column stretch>
              {prepend && <PrependWrapper>{prepend}</PrependWrapper>}
              {text && (
                <pre>
                  <code>{text}</code>
                </pre>
              )}
            </Column>
          </CodeWrapper>
        </Row>
      </>
    </ConditionalWrapper>
  </Column>
);

const CodeWrapper = styled.div`
  display: flex;
  background-color: rgb(50, 42, 56);
  padding: 10px 20px;
  border-radius: 8px;
  code {
    color: white;
  }
`;

const PrependWrapper = styled.pre`
  margin: 10px 10px 10px 0;
  font-size: 16px;
  font-family: monospace;
  color: #ebbf6b;
`;

const CodeInner = styled.pre`
  display: inline-flex;
  background-color: rgb(50, 42, 56);
  margin: 10px 20px;
  font-size: 16px;
  font-family: monospace;
  color: #ebbf6b;
`;

export const Code = ({ name, content }) => (
  <Column paddingTop="40" paddingBottom="60">
    {name && <H3>{name}</H3>}
    <CodeWrapper>
      <CodeInner>{content}</CodeInner>
    </CodeWrapper>
  </Column>
);
