import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../store/actions";
import { view } from "./LoginByPasswordView";

export const LoginByPassword = () => {
  const dispatch = useDispatch();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isSubmitPressed, setIsSubmitPressed] = useState(false);

  const auth = useSelector((state) => state.auth);
  const lastLocation = useSelector((state) => state.nav.last);

  const submit = (e) => {
    e.preventDefault();
    setIsSubmitPressed(true);
    dispatch(
      authActions.request({ data: { userName, password, lastLocation } })
    );
  };

  const handleUserName = (text) => {
    setUserName(text);
    resetError();
  };

  const handlePassword = (text) => {
    setPassword(text);
    resetError();
  };

  const resetError = () => {
    setError("");
  };

  useEffect(() => {
    // clear previous user data, just in case
    dispatch(authActions.delete());
  }, []);

  useEffect(() => {
    if (isSubmitPressed) {
      if (auth.error?.message && auth.error.message !== "") {
        if (auth.error.message === "Request failed with status code 401") {
          setError("Username and password do not match");
        } else {
          setError(auth.error.message || "Login error, please try later");
        }
      }
    }
  }, [auth.error]);

  return view({
    userName,
    password,
    handleUserName,
    handlePassword,
    submit,
    isSubmitPressed,
    error,
    loading: auth.fetching,
  });
};
