import React from "react";
import styled from "styled-components";

import {
  Column,
  Row,
  MainWrapper,
  Header,
  Content,
  H2,
  Label,
  Dropdown,
  Button,
  Anchor,
  ListItem,
  Modal,
  ModalFooter,
  Editor,
  Switch,
  DateRange,
} from "../../../components";
import { Menu } from "../Menu";

const EditorWrapper = styled(Column)`
  .ql-toolbar {
    position: sticky;
    top: -41px;
    background: white;
    z-index: 1;
  }
`;

export const Group = ({ children, label, errorMessage }) => (
  <Column marginTop="30">
    <Row marginBottom="10">
      <Label bold>{label}</Label>
    </Row>
    <Row marginTop="5">{children}</Row>
    {errorMessage && (
      <Row marginTop="10">
        <Label color="red">{errorMessage}</Label>
      </Row>
    )}
  </Column>
);

export const Monitor = ({ monitor, handleDeleteMonitor }) => (
  <ListItem simple hover middle>
    <Label>{monitor.label}</Label>
    <Row marginRight="5" />
    <Label>
      (
      {monitor.notificationInterval === 168
        ? "Weekly"
        : monitor.notificationInterval === 24
        ? "Daily"
        : monitor.notificationInterval === 0 && monitor.active
        ? "Instant"
        : "Off"}
      )
    </Label>
    <Row fit right>
      <Anchor
        small
        label="Delete"
        onClick={() => handleDeleteMonitor(monitor.id)}
      />
    </Row>
  </ListItem>
);

export const view = ({
  handleSave,
  errors,
  isSavePressed,
  isNew,

  userTypes,
  userType,
  handleUserTypeChange,

  notificationTypes,
  notificationType,
  handleNotificationTypeChange,

  notificationContentTypes,
  notificationContentType,
  handleNotificationContentTypeChange,

  active,
  handleActiveChange,

  dates,
  handleDatesChange,

  text,
  handleTextChange,
  handleEditorBlur,

  deleteModal,
  toggleDeleteModal,
  handleDelete,
}) => (
  <MainWrapper drawer>
    <Header
      drawer
      close={isNew}
      back={!isNew}
      bkg="white"
      rightContent={
        <Row>
          {true && <Button label="Save" onClick={() => handleSave()} />}
          {!isNew && (
            <Row marginX>
              <Anchor
                label="Delete"
                color="red"
                onClick={() => toggleDeleteModal(true)}
              />
            </Row>
          )}
        </Row>
      }
    ></Header>
    <Menu active="notificationMessages" />

    <Content>
      <Column center>
        <Column maxWidth="800" marginBottom>
          <Row expand paddingY="20">
            <H2 bold>{isNew ? "Add a new message" : "Edit message"}</H2>
          </Row>
          <Column>
            <Group label="Email Type">
              <Column maxWidth="400">
                <Dropdown
                  expand
                  value={notificationContentType}
                  onChange={handleNotificationContentTypeChange}
                  items={notificationContentTypes}
              />
              </Column>
            </Group>
            {/* <Group label="Occurrence">
              <Column maxWidth="400">
                <Dropdown
                  expand
                  value={notificationType}
                  onChange={handleNotificationTypeChange}
                  items={notificationTypes}
                />
              </Column>
            </Group> */}
            <Group label="User Type">
              <Column maxWidth="400">
                <Dropdown
                  expand
                  value={userType}
                  onChange={handleUserTypeChange}
                  items={userTypes}
            />
              </Column>
            </Group>

            <Group label="Date Range">
              <Column maxWidth="400">
                <DateRange
                  initialDate={dates.start}
                  endDate={dates.end}
                  onChange={handleDatesChange}
                />
              </Column>
            </Group>

            <Group label="Active">
              <Column maxWidth="400">
                <Switch
                    checked={active}
                    onChange={handleActiveChange}
                  />
              </Column>
            </Group>

            <Group
              label="Custom Message"
              errorMessage={isSavePressed && errors.text}
            >
              <EditorWrapper>
                <Editor
                  theme="snow"
                  value={text}
                  onChange={handleTextChange}
                  onBlur={handleEditorBlur}
                  placeholder="New custom message"
                />
              </EditorWrapper>
            </Group>
          </Column>
        </Column>

        <Modal
          visible={deleteModal}
          toggleVisibility={toggleDeleteModal}
          title="Delete Custom Message"
          close
        >
          <Column marginBottom gap="20" center>
            <Label>
              <Label large bold>
                Custom Message
              </Label>
              <Label large> will be deleted</Label>
            </Label>
            <Label large>
              Are you sure you want to continue? This action cannot be undone.
            </Label>
          </Column>
          <ModalFooter right>
            <Button secondary label="Delete" onClick={() => handleDelete()} />
          </ModalFooter>
        </Modal>
      </Column>
    </Content>
  </MainWrapper>
);
