import React from "react";
import {
  MainWrapper,
  Content,
  Column,
  Header,
  Row,
  Spinner,
  Drawer,
  H2,
  H4,
  Button,
  P,
  Footer,
  Label,
  Icon,
  Metadata,
  Anchor,
  TextArea,
} from "../../components";
import { formatShortDates, insightHighlight } from "../../utils";
import { Color } from "../../utils/variables";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Article = styled(Column)`
  background: ${Color("gray", "1")};

  &:hover {
    background: ${Color("green", "1")};
  }
`;

const Image = styled.img`
  max-width: 368px;
  max-height: 368px;
  float: left;
  margin-right: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
`;

const HTML = styled.div`
  .highlights {
    min-width: 100%;
    display: block;

    br + br {
      margin-top: 0;
    }
  }
`;

export const view = ({
  user,
  isAdmin,
  objectSummary,
  previousSummaries,
  inProgress,
  items,
  handleSignUpClick,
  editMode,
  setEditMode,
  title,
  setTitle,
  handleUpdateTitle,
  handleCancelEditing,
  handleRegenerate,
  isRegenerating,
  error,
}) => (
  <MainWrapper drawer footer>
    <Header drawer white login user signUpClick={handleSignUpClick} />
    <Drawer
      white
      login
      {...{ items }}
      active={
        items?.find((item) => item.id === objectSummary?.objectId)?.id ||
        items?.[0]?.id
      }
      signUpClick={handleSignUpClick}
    />
    <Content>
      <Column expand>
        {inProgress ? (
          <Column expand paddingAll center middle>
            <Spinner />
          </Column>
        ) : (
          <>
            {objectSummary?.id ? (
              <Column marginTop="10" maxWidth={750}>
                <Metadata
                  title={`See what physicians are discussing in ${objectSummary.name}`}
                  description={objectSummary.title}
                  image={objectSummary.imageUrl}
                />
                <Column marginBottom="40">
                  <Row marginBottom expand gap="10" top>
                    {!editMode ? (
                      <H2 like="h4" bold>
                        <title>{objectSummary.title}</title>
                      </H2>
                    ) : (
                      <Column marginTop="-10" stretch gap="10">
                        <TextArea
                          expand
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                        <Row expand marginBottom gap="10">
                          <Button
                            minWidth={100}
                            small
                            label="Save"
                            onClick={() => handleUpdateTitle(objectSummary.id)}
                          />
                          <Button
                            secondary
                            minWidth={100}
                            small
                            label="Cancel"
                            onClick={handleCancelEditing}
                          />
                        </Row>
                      </Column>
                    )}
                  </Row>
                  {isAdmin && !editMode && (
                    <Row marginBottom>
                      {isRegenerating ? (
                        <Row expand center>
                          <Spinner />
                        </Row>
                      ) : (
                        <Column gap="20">
                          <Row gap="20">
                            <Anchor
                              bold
                              line
                              icon="reload"
                              label="Regenerate Insight"
                              onClick={() => handleRegenerate(objectSummary.id)}
                            />
                            <Anchor
                              bold
                              line
                              icon={objectSummary.title ? "edit" : "add"}
                              label={
                                objectSummary.title ? "Edit Title" : "Add title"
                              }
                              onClick={() => {
                                setTitle(objectSummary.title);
                                setEditMode(true);
                              }}
                            />
                          </Row>
                          {error && (
                            <Column gap="5">
                              <Label color="red">{error}</Label>
                              <Label color="red">Please, try again.</Label>
                            </Column>
                          )}
                        </Column>
                      )}
                    </Row>
                  )}

                  <div>
                    {objectSummary.imageUrl && (
                      <Image src={objectSummary.imageUrl} />
                    )}
                    <article>
                      <HTML>
                        {insightHighlight({
                          text: objectSummary.content,
                          excludeBreaks: true,
                        })}
                      </HTML>
                    </article>
                  </div>
                  <Column marginTop="30" gap="5" right>
                    <Label large color="gray" fade="7">
                      {formatShortDates(
                        objectSummary.startDate,
                        objectSummary.endDate
                      )}
                    </Label>
                    <Label color="gray" fade="7">
                      by Medical.watch Research
                    </Label>
                  </Column>
                </Column>
                {previousSummaries[0] &&
                  NextArticle({ item: previousSummaries[0] })}
                {previousSummaries[1] &&
                  NextArticle({ item: previousSummaries[1] })}
                <Column
                  marginBottom="30"
                  bkg="gray"
                  fade="1"
                  paddingAll="30"
                  center
                  gap="20"
                  radius="10"
                >
                  {user?.id ? (
                    <>
                      <H4 bold center>
                        See more HCP insights about {objectSummary?.name}
                      </H4>
                      <Button
                        label="View Insights"
                        to={`/?diseaseId=${objectSummary.objectId}`}
                      />
                    </>
                  ) : (
                    <>
                      <H4 bold center>
                        Get social media insights for your products and diseases
                      </H4>
                      <Button label="Sign up for Medical.watch" to="/" />
                    </>
                  )}
                </Column>
              </Column>
            ) : (
              <Column paddingAll="20" center middle>
                <P color="gray" fade="7">
                  No summary found
                </P>
              </Column>
            )}
          </>
        )}
      </Column>
    </Content>
    <Footer />
  </MainWrapper>
);

const NextArticle = ({ item }) => (
  <Link to={`/insight/${item.objectId}/${item.id}`}>
    <Article
      marginBottom="30"
      bkg="gray"
      fade="1"
      paddingAll="20"
      gap="20"
      radius="10"
      className="pointer"
    >
      <Row middle>
        <Row fit>
          <Column gap="10">
            <H4>{item.title}</H4>
            <Label color="gray" fade="8">
              {formatShortDates(item.startDate, item.endDate)}
            </Label>
          </Column>
        </Row>
        <Row noShrink marginLeft="10">
          <Icon color="green" fade="6" name="chevronRight" />
        </Row>
      </Row>
    </Article>
  </Link>
);
