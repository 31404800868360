import { useQueryClient, useMutation, useQuery } from "react-query";
import axios from "axios";
import { objectToUrlEncoded } from "../../utils/constants";
import { getApiPath } from "../../utils/variables";
import { authHeader } from "../store";

export function changePassword(body) {
  return axios.put(`${getApiPath()}/user/changePassword`, body, {
    headers: authHeader(),
  });
}
export function useChangePassword() {
  return useMutation((body) => changePassword(body), {});
}

export function getUserByEmail(email) {
  return axios.get(`${getApiPath(false)}/user/byEmail?email=${email}`, {});
}
export function useGetUserByEmail() {
  return useMutation((email) => getUserByEmail(email), {});
}
