import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Link,
  Switch,
  Route,
  Redirect,
  useLocation,
  useParams,
} from "react-router-dom";
// import { TransitionGroup, CSSTransition } from "react-transition-group";
import { useSelector, useDispatch } from "react-redux";

import { AppWrapper } from "./components";
import { WithErrors } from "./hocs/WithErrors";
import { getUrlParams } from "./utils";
import { navActions, settingsActions } from "./store/actions";

import { Styleguide } from "./pages/StyleGuide/StyleguideContainer";
import { Dashboard } from "./pages/Dashboard/DashboardContainer";
import { WatchList } from "./pages/Watchlist/WatchListContainer";
import { WatchListDetails } from "./pages/Watchlist/WatchListDetailsContainer";
import { Login } from "./pages/Login/LoginContainer";
import { Maintenance } from "./pages/Login/Maintenance";
import { LoginByPassword } from "./pages/Login/LoginByPasswordContainer";
import { AutoLogin } from "./pages/Login/AutoLoginContainer";
import { Signup } from "./pages/Signup/SignupContainer";
import { Signup1 } from "./pages/Dashboard/Signup1";
import { Signup2 } from "./pages/Dashboard/Signup2";
import { Signup3 } from "./pages/Dashboard/Signup3";
import { Signup4 } from "./pages/Dashboard/Signup4";
import { Signup5 } from "./pages/Dashboard/Signup5";
import { Logout } from "./pages/Logout/LogoutContainer";
import { NotFound } from "./pages/NotFound/NotFoundContainer";
import { Auth } from "./pages/NotFound/AuthContainer";
import { Search } from "./pages/Search/SearchContainer";
import { AdminAI } from "./pages/Admin/AI/AIContainer";
import { AdminUsers } from "./pages/Admin/Users/UsersContainer";
import { AdminDiseases } from "./pages/Admin/Diseases/DiseasesContainer";
import { AdminProducts } from "./pages/Admin/Products/ProductsContainer";
import { AdminConferences } from "./pages/Admin/Conferences/ConferencesContainer";
import { AdminTags } from "./pages/Admin/Tags/TagsContainer";
// import { AdminHcps } from "./pages/Admin/Hcps/HpcsContainer";
// import { AdminInstitutions } from "./pages/Admin/Institutions/InstitutionsContainer";
import { AdminAccounts } from "./pages/Admin/Accounts/AccountsContainer";
import { AdminTweets } from "./pages/Admin/Tweets/TweetsContainer";
import { AdminHighlights } from "./pages/Admin/Highlights/HighlightsContainer";
import { AdminMonitors } from "./pages/Admin/Monitors/MonitorsContainer";
import { AdminNotifications } from "./pages/Admin/Notifications/NotificationsContainer";
import { AdminNotificationEvents } from "./pages/Admin/NotificationEvents/NotificationEventsContainer";
import { AdminNotificationMessages } from "./pages/Admin/NotificationMessages/NotificationMessagesContainer";
import { AdminPlans } from "./pages/Admin/Plans/PlansContainer";
import { EditUser } from "./pages/Admin/Users/EditUserContainer";
import { NewUser } from "./pages/Admin/Users/NewUserContainer";
import { EditDisease } from "./pages/Admin/Diseases/EditDiseaseContainer";
import { EditProduct } from "./pages/Admin/Products/EditProductContainer";
import { EditConference } from "./pages/Admin/Conferences/EditConferenceContainer";
import { EditTag } from "./pages/Admin/Tags/EditTagContainer";
import { EditHighlight } from "./pages/Admin/Highlights/EditHighlightContainer";
import { EditNotificationMessage } from "./pages/Admin/NotificationMessages/EditNotificationMessageContainer";
import { Account } from "./pages/Account/AccountContainer";
import { EditAccount } from "./pages/Admin/Hcps/EditAccountContainer";
import { NewAccount } from "./pages/Admin/Hcps/NewAccountContainer";
import { Tweet } from "./pages/Tweet/TweetContainer";
import { EmailHighlights } from "./pages/Email/Highlights/HighlightsContainer";
import { EmailTopTweets } from "./pages/Email/TopTweets/TopTweetsContainer";
import { EmailProductAlert } from "./pages/Email/ProductAlert/ProductAlertContainer";
import { EmailDigest } from "./pages/Email/Digest/DigestContainer";
import { EmailTopMentions } from "./pages/Email/Mentions/MentionsContainer";
import { EmailTopArticles } from "./pages/Email/Articles/ArticlesContainer";
import { EmailTopInfluencers } from "./pages/Email/TopInfluencers/TopInfluencersContainer";
import { AiChat } from "./pages/AiChat/AiChatContainer";
import { Checkout } from "./pages/Checkout/CheckoutContainer";
import { CheckoutSuccess } from "./pages/Checkout/Success";
import { CheckoutError } from "./pages/Checkout/Error";
import { Pricing } from "./pages/Pricing/PricingContainer";
import { AiSummaries } from "./pages/AiSummary/AiSummariesContainer";
import { AiSummary } from "./pages/AiSummary/AiSummaryContainer";
import { Influencers } from "./pages/Influencers/InfluencersContainer";
import { CongressInfluencers } from "./pages/Influencers/CongressContainer";
import { CongressDetails } from "./pages/Influencers/CongressDetailsContainer";
import { DiseaseInfluencers } from "./pages/Influencers/DiseaseContainer";
import { DiseaseDetails } from "./pages/Influencers/DiseaseDetailsContainer";
import { DiseaseInsights } from "./pages/Influencers/InsightsContainer";
import { InsightDetails } from "./pages/Influencers/InsightDetailsContainer";
import { Privacy } from "./pages/Dashboard/Privacy";
import { Terms } from "./pages/Dashboard/Terms";

import { logAnalyticEvent } from "./utils";

export const App = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.data);
  const abVersion = useSelector((state) => state.settings.abVersion);
  const isUserRegistered = user?.active;
  const isAdmin = user?.mwRole === "ADMIN";
  const isHighlightsAdmin = user?.mwRole === "HIGHLIGHTSADMIN";

  const [queryParams] = useState(getUrlParams(location.search));
  const [route, setRoute] = useState({
    from: location.pathname,
    to: location.pathname,
  });

  const cookies = useSelector((state) => state.settings.cookies);

  // const getHomePath = () => {
  //   if (
  //     isUserRegistered ||
  //     queryParams?.productId ||
  //     queryParams?.diseaseId ||
  //     queryParams?.conferenceId
  //   ) {
  //     return Dashboard;
  //   } else {
  //     return Login;
  //   }
  // };

  const logUnload = () => {
    // check for cookies first before publishing event
    if (!user?.active && cookies?.seen && !cookies?.accepted) {
      return;
    }
    // call common helper function
    logAnalyticEvent(
      "User leaving",
      {
        // any event properties
      },
      user?.id || queryParams?.u || null,
      user?.displayName || queryParams?.n || null,
      user?.email || queryParams?.e || null,
      user?.createdAt || null,
      user?.userType || queryParams?.t || null
    );
  };

  const logBlip = () => {
    // check for cookies first before publishing event
    if (!user?.active && cookies?.seen && !cookies?.accepted) {
      return;
    }
    // call common helper function
    logAnalyticEvent(
      "Blip",
      {
        // any event properties
      },
      user?.id || queryParams?.u || null,
      user?.displayName || queryParams?.n || null,
      user?.email || queryParams?.e || null,
      user?.createdAt || null,
      user?.userType || queryParams?.t || null
    );
  };

  useEffect(() => {
    // AB Testing
    if (!abVersion) {
      // random number between 1 and 3
      const randomNumber = Math.floor(Math.random() * 3) + 1;
      dispatch(
        settingsActions.set({
          abVersion: randomNumber,
        })
      );
    }

    window.addEventListener("beforeunload", logUnload);
    const timeoutId = setTimeout(() => {
      // logBlip();
    }, 1000 * 30);
    return () => {
      window.removeEventListener("beforeunload", logUnload);
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    setRoute((prev) => {
      // storing locations:
      // `last` used to redirect after a logout
      // after a login `prev === current`
      // we use them to block back navigation
      setTimeout(() => {
        dispatch(
          navActions.set({
            ...(location.pathname !== "/logout" &&
              location.pathname !== "/login" && {
                last: window.location.href,
              }),
            prev: prev.to,
            current: location.pathname,
          })
        );
      }, 100);
      return { from: prev.to, to: location.pathname };
    });
  }, [location]);

  const CommonRoutes = [
    <Route
      path="/dashboard/:monitorType/:monitorId"
      key="dashboard"
      component={Dashboard}
    />,
    <Route
      path="/top_influencers/:type?/:name?/:month?/:year?"
      key="influencers"
      component={Influencers}
    />,
    <Route
      path="/congress_influencers"
      key="congress_influencers"
      component={CongressInfluencers}
    />,
    <Route
      path="/congress_details/:id/:index"
      key="congress_details"
      component={CongressDetails}
    />,
    <Route
      path="/disease_insights"
      key="disease_insights"
      component={DiseaseInsights}
    />,
    <Route
      path="/disease_details/:id"
      key="disease_details"
      component={DiseaseDetails}
    />,
    <Route
      path="/disease_influencers/:month?/:year?"
      key="disease_influencers"
      component={DiseaseInfluencers}
    />,
    <Route path="/privacy" key="privacy" component={Privacy} />,
    <Route path="/terms" key="terms" component={Terms} />,
    <Route path="/post/:id?" key="post" component={Tweet} />,
    <Route path="/account/:id" key="account" component={Account} />,
    <Route path="/maintenance" key="maintenance" component={Maintenance} />,
    <Route
      path="/login"
      key="login"
      component={isUserRegistered ? Dashboard : Login}
    />,
    <Route
      path="/loginByPassword"
      key="loginByPassword"
      component={isUserRegistered ? Dashboard : LoginByPassword}
    />,
    <Route path="/autoLogin" key="autoLogin" component={AutoLogin} />,
    <Route path="/forward" key="forward" component={AutoLogin} />,
    <Route path="/logout" key="logout" component={Logout} />,
    <Route path="/signup" key="signup" component={Signup} />,
    <Route path="/signup1" key="signup1" component={Signup1} />, // Simple version
    <Route path="/signup2" key="signup2" component={Signup2} />, // Medium version
    <Route path="/signup3" key="signup3" component={Signup3} />, // Full version
    <Route path="/signup4" key="signup4" component={Signup4} />, // Simple version, text variant 1
    <Route path="/signup5" key="signup5" component={Signup5} />, // Simple version, text variant 1
    <Route
      path="/email/highlights"
      key="emailHighlights"
      component={EmailHighlights}
    />,
    <Route
      path="/email/topTweets"
      key="emailTopTweets"
      component={EmailTopTweets}
    />,
    <Route
      path="/email/productAlert"
      key="emailProductAlert"
      component={EmailProductAlert}
    />,
    <Route path="/email/digest" key="emailDigest" component={EmailDigest} />,
    <Route
      path="/email/mentions"
      key="emailMentions"
      component={EmailTopMentions}
    />,
    <Route
      path="/email/articles"
      key="emailArticles"
      component={EmailTopArticles}
    />,
    <Route
      path="/email/topInfluencers"
      key="emailTopInfluencers"
      component={EmailTopInfluencers}
    />,
    <Route path="/styleguide" key="styleguide" component={Styleguide} />,
    <Route
      path="/checkoutSuccess"
      key="checkoutSuccess"
      component={CheckoutSuccess}
    />,
    <Route
      path="/checkoutCancel"
      key="checkoutError"
      component={CheckoutError}
    />,

    <Route path="/pricing" key="pricing" component={Pricing} />,
    <Route path="/insights/:id?" key="insights" component={AiSummaries} />,
    <Route path="/insight/:objectId/:id" key="insight" component={AiSummary} />,
    <Route
      path="/insight_details/:id"
      key="insight_details"
      component={InsightDetails}
    />,
    // pages that require login should redirect to Auth
    <Route path="/preferences" key="authPref" component={Auth} />,
    <Route
      path="/preferencesDetails/:id/:user?"
      key="authPrefDetails"
      component={Auth}
    />,
    <Route path="/aiChat/:id?" key="authAiChat" component={Auth} />,
    <Route path="/search" key="search" component={Search} />,
    <Route path="" key="notFound" component={NotFound} />,
  ];

  const LoggedInRoutes = [
    <Route path="/aiChat/:id?" key="aiChat" component={AiChat} />,
    <Route path="/preferences" key="preferences" component={WatchList} />,
    <Route
      path="/preferencesDetails/:id/:user?"
      key="preferencesDetails"
      component={WatchListDetails}
    />,
  ];

  const HighlightsRoutes = [
    <Route
      path="/admin/highlights"
      key="adminHighlights"
      component={AdminHighlights}
    />,
    <Route path="/newHighlight" key="newHighlight" component={EditHighlight} />,
    <Route
      path="/editHighlight/:id"
      key="editHighlight"
      component={EditHighlight}
    />,
  ];

  const AdminRoutes = [
    <Route path="/checkout" key="checkout" component={Checkout} />,

    <Route path="/admin/ai" key="adminAI" component={AdminAI} />,
    <Route path="/admin/users" key="adminUsers" component={AdminUsers} />,
    <Route
      path="/admin/diseases"
      key="adminDiseases"
      component={AdminDiseases}
    />,
    <Route
      path="/admin/products"
      key="adminProducts"
      component={AdminProducts}
    />,
    <Route
      path="/admin/conferences"
      key="adminConferences"
      component={AdminConferences}
    />,
    <Route path="/admin/tags" key="adminTags" component={AdminTags} />,
    // <Route path="/admin/hcps" key="adminHcps" component={AdminHcps} />,
    // <Route
    //   path="/admin/institutions"
    //   key="adminInstitutions"
    //   component={AdminInstitutions}
    // />,
    <Route
      path="/admin/accounts"
      key="adminAccounts"
      component={AdminAccounts}
    />,
    <Route path="/admin/tweets" key="adminTweets" component={AdminTweets} />,
    <Route
      path="/admin/highlights"
      key="adminHighlights"
      component={AdminHighlights}
    />,
    <Route
      path="/admin/monitors"
      key="adminMonitors"
      component={AdminMonitors}
    />,
    <Route
      path="/admin/notifications"
      key="adminNotifications"
      component={AdminNotifications}
    />,
    <Route
      path="/admin/notificationEvents"
      key="adminNotificationEvents"
      component={AdminNotificationEvents}
    />,
    <Route
      path="/admin/notificationMessages"
      key="adminNotificationMessages"
      component={AdminNotificationMessages}
    />,
    <Route path="/admin/plans" key="adminPlans" component={AdminPlans} />,
    <Route path="/newUser" key="newUser" component={NewUser} />,
    <Route path="/editUser/:id" key="editUser" component={EditUser} />,
    <Route path="/newDisease" key="newDisease" component={EditDisease} />,
    <Route
      path="/editDisease/:id/:params?"
      key="editDisease"
      component={EditDisease}
    />,
    <Route path="/newProduct" key="newProduct" component={EditProduct} />,
    <Route
      path="/editProduct/:id/:params?"
      key="editProduct"
      component={EditProduct}
    />,
    <Route
      path="/newConference/:parentId?"
      key="newConference"
      component={EditConference}
    />,
    <Route
      path="/editConference/:id/:params?"
      key="editConference"
      component={EditConference}
    />,
    <Route path="/newTag" key="newTag" component={EditTag} />,
    <Route path="/editTag/:id" key="editTag" component={EditTag} />,
    <Route path="/newHighlight" key="newHighlight" component={EditHighlight} />,
    <Route
      path="/editHighlight/:id"
      key="editHighlight"
      component={EditHighlight}
    />,
    <Route
      path="/newNotificationMessage"
      key="newNotificationMessage"
      component={EditNotificationMessage}
    />,
    <Route
      path="/editNotificationMessage/:id"
      key="editNotificationMessage"
      component={EditNotificationMessage}
    />,
    <Route path="/editAccount/:id" key="editAccount" component={EditAccount} />,
    <Route path="/newAccount" key="newAccount">
      <NewAccount isPerson />
    </Route>,
    // <Route path="/newHcp" key="newHcp">
    //   <NewAccount isPerson />
    // </Route>,
    // <Route path="/newInstitution" key="newInstitution">
    //   <NewAccount isInstitution />
    // </Route>,
  ];

  const myLocation = useLocation();
  return (
    <AppWrapper>
      {/* <TransitionGroup>
          <CSSTransition timeout={1000} classNames="fade" key={myLocation.key}> */}
      <Switch location={myLocation}>
        {/* <Route exact path="/" key="home" component={getHomePath()} />, */}
        <Route exact path="/" key="home" component={Dashboard} />
        {isAdmin && AdminRoutes}
        {isHighlightsAdmin && HighlightsRoutes}
        {isUserRegistered && LoggedInRoutes}
        {CommonRoutes}
      </Switch>
      {/* </CSSTransition>
        </TransitionGroup> */}
    </AppWrapper>
  );
};

export default WithErrors(App);
