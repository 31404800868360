import { HIGHLIGHT_ACTIONS } from "../actions";
import { MAX_RESULTS } from "../../utils/variables";

const initialState = {
  all: { data: [], fetching: false, success: false, error: null },
  one: { data: [], fetching: false, success: false, error: null },
};

export default function highlightReducer(state = initialState, action) {
  switch (action.type) {
    case HIGHLIGHT_ACTIONS.REQUEST:
    case HIGHLIGHT_ACTIONS.FAILURE:
      return { ...state, all: { ...state.all, ...action.payload } };
    case HIGHLIGHT_ACTIONS.SUCCESS: // If we have pagination, we need to merge state data & payload data
      const newState = {
        ...state,
        all: {
          ...state.all,
          ...action.payload,
          isLastPage: action.payload.data.length < MAX_RESULTS,
        },
      };
      if (action.payload.pageNum && action.payload.pageNum !== 0) {
        newState.all.data = [...state.all.data, ...action.payload.data];
      }
      return newState;

    case HIGHLIGHT_ACTIONS.ONE_REQUEST:
    case HIGHLIGHT_ACTIONS.ONE_SUCCESS:
    case HIGHLIGHT_ACTIONS.ONE_FAILURE:
    case HIGHLIGHT_ACTIONS.CREATE:
    case HIGHLIGHT_ACTIONS.UPDATE:
    case HIGHLIGHT_ACTIONS.DELETE:
    case HIGHLIGHT_ACTIONS.CLEAR:
      return { ...state, one: action.payload };

    default:
      return state;
  }
}
