import styled from "styled-components";
import { Column } from "./Layout";
import { Color } from "../utils/variables";

const BluredBox = styled(Column)`
  position: relative;
`;
const BluredContent = styled(Column)`
  filter: blur(5px);
  pointer-events: none;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);
  }
`;
const BluredTextBox = styled(Column)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
`;
const BluredText = styled(Column)`
  background: ${Color("green", "1")};
  padding: 20px 40px;
  border-radius: 10px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
`;

export const Blured = ({ children, text, enabled }) => {
  if (enabled) {
    return children;
  } else {
    return (
      <BluredBox>
        <BluredContent>{children}</BluredContent>
        {text && (
          <BluredTextBox>
            <BluredText>{text}</BluredText>
          </BluredTextBox>
        )}
      </BluredBox>
    );
  }
};
