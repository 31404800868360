import React, { useEffect, useRef, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4plugins_wordCloud from "@amcharts/amcharts4/plugins/wordCloud";
import { isEqual, cloneDeep } from "lodash";

import { Color } from "../utils/variables";

export const WordCloudChart = ({ id, data, handleChartClick }) => {
  const CHART_ID = id || "wordCloudChart";
  const chartRef = useRef(null);
  const [previousData, setPreviousData] = useState([]);

  const createChart = () => {
    am4core.useTheme(am4themes_animated);
    am4core.options.commercialLicense = true;

    chartRef.current = am4core.create(CHART_ID, am4plugins_wordCloud.WordCloud);

    chartRef.current.data = data;

    // Create series
    let series = chartRef.current.series.push(
      new am4plugins_wordCloud.WordCloudSeries()
    );
    series.dataFields.word = "label";
    series.dataFields.value = "value";

    series.colors = new am4core.ColorSet();
    series.colors.passOptions = {};
    series.colors.list = [
      am4core.color(Color("pink")),
      am4core.color(Color("purple")),
      am4core.color(Color("red")),
      am4core.color(Color("yellow")),
      am4core.color(Color("blue")),
      am4core.color(Color("green")),
    ];

    series.randomness = 0.3;
    series.minFontSize = am4core.percent("6");
    series.maxFontSize = am4core.percent("25");
    series.accuracy = 4;
    series.labels.template.text = "{word} ({value})";
    series.labels.template.tooltipText = "{word}: [bold]{value}[/]";
    series.labels.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;

    // attach custom event with word cloud label
    // TODO
    // - should be based on flag (required or not)
    series.labels.template.events.on("hit", function (ev) {
      handleChartClick?.(ev.target.dataItem.dataContext);
    });
  };

  useEffect(() => {
    return () => {
      // Handle component unmounting, dispose chart
      chartRef.current && chartRef.current.dispose();
    };
  }, []);

  useEffect(() => {
    // deep compare
    if (!isEqual(data, previousData)) {
      // change state only when there is a change
      chartRef.current && chartRef.current.dispose();
      createChart();
      setPreviousData(cloneDeep(data)); // save for next compare
    }
  }, [data]);

  return (
    <div
      id={CHART_ID}
      style={{
        width: "100%",
        height: "300px",
      }}
    />
  );
};
