import React from "react";
import {
  MainWrapper,
  Content,
  Column,
  Header,
  Card,
  H3,
  Metadata,
} from "../../components";

export const view = ({}) => (
  <MainWrapper>
    <Metadata />
    <Header logo />

    <Content>
      <Column center middle>
        <Card small center>
          <H3 bold>Page not found</H3>
        </Card>
      </Column>
    </Content>
  </MainWrapper>
);
