import React from "react";
import { Link } from "react-router-dom";
import { Footer, Subscribe } from "../components";

import {
  Row,
  MainWrapper,
  Header,
  Content,
  Label,
  Column,
  Spinner,
  H4,
  Sentiment,
  Card,
  Avatar,
  TweetCard,
} from "../../../components";
import { formatNumberWithCommas } from "../../../utils";

export const view = ({
  nId,
  name,
  loading,
  monitor,
  productName,
  tweet,
  productSentiment,
  impressions,
  impressionsPercentage,
}) => {
  const hasQuote = tweet?.quoted?.length > 0;
  return (
    <MainWrapper>
      <Header logo login user></Header>
      {nId ? (
        <Content noScroll paddingAll="0">
          <iframe
            style={{
              border: "0",
              height: "1000px",
              width: "100%",
              borderRadius: "10px",
              background: "transparent",
            }}
            src={`${window.location.origin}/notauth/mail/${nId}`}
          />
        </Content>
      ) : (
        <Content>
          <Column center>
            <Column maxWidth="600">
              <Column style={{ verticalAlign: "top" }} marginTop="10">
                <Subscribe />
                <Column marginBottom="30" marginTop="10" stretch gap="20">
                  <Label>Hi{name ? ` ${decodeURIComponent(name)}` : ""},</Label>
                  {tweet.externalId && tweet.tAccount?.id && (
                    <Label>
                      <Label bold>{tweet.tAccount.name} </Label>just mentioned
                      <Label bold>
                        {monitor?.primaryName
                          ? ` ${monitor.primaryName}.`
                          : ` ${productName}.`}
                      </Label>
                      {" Here's the tweet:"}
                    </Label>
                  )}
                </Column>

                {tweet.externalId && (
                  <Column stretch marginBottom>
                    <Column marginBottom>
                      <Link to={`/post/${tweet.externalId}?source=email`}>
                        <Card
                          border={hasQuote}
                          paddingAll={!hasQuote ? "0" : "20"}
                        >
                          <TweetCard data={tweet} {...{ hasQuote }} />
                          {hasQuote && (
                            <Column marginTop="30">
                              <TweetCard
                                key={tweet.quoted[0].externalId}
                                data={tweet.quoted[0]}
                              />
                            </Column>
                          )}
                        </Card>
                        {/* {tweet?.medias?.length > 0 && (
                              <Row marginTop="10">
                                <TweetMedia medias={tweet.medias} grid />
                              </Row>
                            )}
                            {tweet?.quoted?.length > 0 && (
                              <Column>
                                <Row flexWrap expand>
                                  {tweet?.quoted.map((i) => (
                                    <Tweet
                                      id={`quoted-tweet-${tweet.externalId}`}
                                      key={i.externalId}
                                      data={i}
                                    />
                                  ))}
                                </Row>
                              </Column>
                            )}
                          </TweetCard> */}
                      </Link>
                    </Column>

                    <Row marginBottom gap="10">
                      <Row fit>
                        <Card border>
                          <Row marginBottom="10">
                            <Label>Sentiment</Label>
                          </Row>
                          {productSentiment != null ? (
                            <Row>
                              <Sentiment value={productSentiment} />
                            </Row>
                          ) : (
                            <Row>
                              <Label>-</Label>
                            </Row>
                          )}
                        </Card>
                      </Row>
                      <Row fit>
                        <Card border>
                          <Row marginBottom="10">
                            <Label>Views</Label>
                          </Row>
                          <Row gap="10">
                            <Label bold>
                              {formatNumberWithCommas(tweet.numViews)}
                            </Label>
                            {tweet.numViews > 0 && (
                              <>
                                {tweet.numViews >= 15000 && (
                                  <Row gap="5" middle>
                                    <Label>🚀</Label>
                                    <Label bold>Excellent</Label>
                                  </Row>
                                )}
                                {tweet.numViews < 15000 &&
                                  tweet.numViews >= 5000 && (
                                    <Row gap="5" middle>
                                      <Label>😎</Label>
                                      <Label bold>Good</Label>
                                    </Row>
                                  )}
                                {tweet.numViews < 5000 &&
                                  tweet.numViews >= 1000 && (
                                    <Row gap="5" middle>
                                      <Label>😐</Label>
                                      <Label bold>Fair</Label>
                                    </Row>
                                  )}
                                {tweet.numViews < 1000 && (
                                  <Row gap="5" middle>
                                    <Label>😔</Label>
                                    <Label bold>Poor</Label>
                                  </Row>
                                )}
                              </>
                            )}
                          </Row>
                        </Card>
                      </Row>
                    </Row>

                    <Column bkg="gray" fade="1" paddingX paddingY radius="10">
                      <Row gap="10" middle>
                        <Avatar
                          large
                          imageUrl={tweet.tAccount.imageURL}
                          name={tweet.tAccount.name}
                        />
                        <Column gap="5">
                          <Row middle>
                            <H4 large bold>
                              {tweet.tAccount.name}
                            </H4>
                          </Row>
                          <Row>
                            <Link
                              id={`tweet-account-image-${tweet.tAccount.id}`}
                              className
                              to={`/account/${tweet.tAccount.id}`}
                            >
                              {`@${tweet.tAccount.username}`}
                            </Link>
                          </Row>
                        </Column>
                      </Row>

                      <Column gap="5">
                        <Label>{tweet.tAccount.location}</Label>
                        <Label color="gray" fade="6">
                          {tweet.tAccount.description}
                        </Label>
                      </Column>
                    </Column>
                  </Column>
                )}

                {loading && (
                  <Row expand paddingTop marginBottom="60" center>
                    <Spinner />
                  </Row>
                )}
                {!tweet.externalId && !loading && (
                  <Row expand paddingTop paddingBottom="50" center>
                    <Label large color="gray" fade="6">
                      Post not found
                    </Label>
                  </Row>
                )}
              </Column>
            </Column>
            <Footer
              first="Share it with a colleague."
              seeMore="See post analytics on the web."
            />
          </Column>
        </Content>
      )}
    </MainWrapper>
  );
};
