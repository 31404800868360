import { useQueryClient, useMutation, useQuery } from "react-query";
import axios from "axios";
import { objectToUrlEncoded } from "../../utils/constants";
import { getApiPath } from "../../utils/variables";
import { authHeader } from "../store";

export function getInsight(data) {
  const { objectId, summaryId, summaryDate } = data;
  const params = objectToUrlEncoded({
    objectId,
    summaryId,
    summaryDate,
  });
  return axios.get(`${getApiPath(false)}/aiSummaries/objectSummary?${params}`);
}
export function useGetInsight({ data, enabled }) {
  return useQuery(["aiInsights"], () => getInsight(data), {
    cacheTime: 0,
    enabled,
  });
}

export function getInsights(data) {
  const {
    summaryId,
    objectId,
    startDateLessThan,
    endDateGreaterThan,
    pageNum,
    maxResult
  } = data;
  const params = objectToUrlEncoded({
    summaryId,
    objectId,
    startDateLessThan,
    endDateGreaterThan,
    pageNum,
    maxResult,
  });
  try {
    return axios.get(`${getApiPath(false)}/aiSummaries/objectSummary?${params}`);
  } catch (error) {
    // error handling
  }
}
export function useGetInsights({ data, enabled }) {
  return useQuery(["aiInsights"], () => getInsights(data), {
    cacheTime: 0,
    enabled,
  });
}

export function regenerate(id) {
  return axios.put(
    `${getApiPath()}/aiSummaries/regenerate?id=${id}`,
    {},
    { headers: authHeader() }
  );
}
export function useRegenerate() {
  const queryClient = useQueryClient();
  return useMutation((id) => regenerate(id), {
    onSuccess: () => {
      queryClient.invalidateQueries("aiInsights");
    },
  });
}
