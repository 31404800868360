import { createAction, createActionTypes } from "../../utils";

export const COMPANY_ACTIONS = createActionTypes("COMPANY_ACTIONS", [
  "REQUEST",
  "SUCCESS",
  "FAILURE",
  "ONE_REQUEST",
  "ONE_SUCCESS",
  "ONE_FAILURE",
  "CLEAR",
]);

export const companyActions = {
  request: () =>
    createAction(COMPANY_ACTIONS.REQUEST, {
      fetching: true,
      success: false,
      error: null,
    }),
  success: (data) =>
    createAction(COMPANY_ACTIONS.SUCCESS, {
      ...data,
      fetching: false,
      success: true,
      error: null,
    }),
  failure: (error) =>
    createAction(COMPANY_ACTIONS.FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  oneRequest: (id) =>
    createAction(COMPANY_ACTIONS.ONE_REQUEST, {
      id,
      fetching: true,
      success: false,
      error: null,
    }),
  oneSuccess: (data) =>
    createAction(COMPANY_ACTIONS.ONE_SUCCESS, {
      ...data,
      fetching: false,
      success: true,
      error: null,
    }),
  oneFailure: (error) =>
    createAction(COMPANY_ACTIONS.ONE_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  clear: () =>
    createAction(COMPANY_ACTIONS.CLEAR, {
      data: [],
      fetching: true,
      success: false,
      error: null,
    }),
};
