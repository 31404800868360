import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import {
  Column,
  Row,
  Label,
  H3,
  H4,
  P,
  Icon,
  Footer,
  MainWrapper,
  Metadata,
  Spinner,
  RowToColumn,
  Anchor,
} from "../../components";
import { Color } from "../../utils/variables";
import { formatShortDate } from "../../utils";
import { HeaderAndDrawer, Share } from "./components";

export const view = ({
  signUpClick,
  summaries,
  loadingSummaries,
  isSuccess,
}) => {
  return (
    <MainWrapper className="dashboard">
      <Metadata />
      <HeaderAndDrawer {...{ signUpClick }} />
      <Column marginTop="100">
        <Column paddingBottom="100">
          <Column center>
            <Column maxWidth="700" paddingX>
              <Column gap="10">
                <H3 bold>Disease insights</H3>
                <P color="gray" fade="7">
                  Analysis of top news from HCPs written by our research team at
                  Medical.watch
                </P>
                <Share title="Disease insights" />
              </Column>
              <RowToColumn>
                <Column stretch>
                  {loadingSummaries && (
                    <Row marginY="40" expand center>
                      <Spinner />
                    </Row>
                  )}

                  {isSuccess && summaries?.length === 0 && (
                    <Row marginY="40" expand center>
                      <Label color="gray" fade="7">
                        No influencers found!
                      </Label>
                    </Row>
                  )}

                  {summaries?.length > 0 && (
                    <Column gap="30" marginTop="40">
                      {summaries?.map((summary) => (
                        <InsightsCard data={summary} key={summary.id} />
                      ))}
                    </Column>
                  )}
                </Column>
              </RowToColumn>
            </Column>
          </Column>
        </Column>
        <Footer />
      </Column>
    </MainWrapper>
  );
};

const InsightsCard = ({ data }) => {
  const [imageError, setImageError] = React.useState(false);

  const handleImageError = () => {
    setImageError(true);
  };
  return (
    <Column gap="10">
      <Row gap="10">
        <H4 bold>{data.name}</H4>
        <Row marginTop="2">
          <Anchor
            bold
            label="See more"
            to={`/insight_details/${data.objectId}`}
          />
        </Row>
      </Row>
      <Link to={`/insight/${data.objectId}/${data.id}`}>
        <RowToColumn
          bkg="gray"
          fade="0.5"
          radius="15"
          paddingAll="10"
          gap="10"
          middle
          device="mobileXL"
        >
          <ImageWrapper>
            {data.post.tAccount.imageURL && !imageError ? (
              <Image
                src={data.post.tAccount.imageURL?.replace("_bigger", "")}
                onError={handleImageError}
                alt={`${data.post.tAccount.name}'s profile`}
              />
            ) : (
              <FallbackImage>
                <Icon name="noImage" />
              </FallbackImage>
            )}
          </ImageWrapper>

          <Column paddingAll="10" gap="10">
            <Label bold color="gray" fade="7">
              Last article ({formatShortDate(data.post.createdAt)})
            </Label>
            <Label large>{data.title}</Label>
            <Label color="gray" fade="7">
              By {data.post.tAccount.name}
            </Label>
          </Column>
        </RowToColumn>
      </Link>
    </Column>
  );
};

const ImageWrapper = styled.div`
  width: 244px;
  height: 122px;
`;

const Image = styled.img`
  width: 244px;
  height: 122px;
  object-fit: cover;
  border-radius: 10px;
`;

const FallbackImage = styled.div`
  width: 244px;
  height: 122px;
  background-color: ${Color("gray", "1")};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
