import React from "react";
import {
  Column,
  Row,
  RowToColumn,
  MainWrapper,
  Header,
  Content,
  H2,
  HR,
  Label,
  Input,
  Button,
  IconButton,
  Anchor,
  Switch,
  DateRange,
  Pill,
  Modal,
  ModalFooter,
  AutoComplete,
  AutoCompleteWithResponses,
  Collapsible,
} from "../../../components";
import { Menu } from "../Menu";

export const Group = ({ children, label, first }) => (
  <Column marginTop={first ? "0" : "30"}>
    <Row marginBottom="10">
      <Label bold>{label}</Label>
    </Row>
    <Row marginTop="5">{children}</Row>
  </Column>
);

export const view = ({
  isEditMode,
  errors,
  handleSave,
  handleChange,
  handleSwitchChange,
  data,
  isSavePressed,
  isNew,
  deleteModal,
  toggleDeleteModal,
  handleDelete,
  handleNameOnBlur,

  dates,
  handleDateChange,
  metrics,
  usersSubscribed,

  tagPairs,
  addTagPair,
  removeTagPair,
  firstTagTermPair,
  setFirstTagTermPair,
  setFirstTagPair,
  secondTagTermPair,
  setSecondTagTermPair,
  setSecondTagPair,
  resetTags,

  institutions,
  selectedInstitution,
  addSelectedInstitution,
  searchInstitution,
  handleSearchInstitution,
  clearInstitution,

  tags,
  addTagMonitorRules,
  removeTagMonitorRules,
  tagsInfiniteScroll,
  handleMoreTags,

  allIncludedTagMonitorRules,
  includedTagMonitorRules,
  searchTagToInclude,
  handleSearchTagToInclude,

  mergableTags,
  mergedTags,
  addMergedTags,
  removeMergedTags,
  searchMergableTag,
  handleSearchMergableTag,

  competitorProducts,
  selectedProducts,
  addSelectedCompetitorProducts,
  removeSelectedCompetitorProducts,
  searchProduct,
  setSearchProduct,
  productsInfiniteScroll,
}) => (
  <MainWrapper drawer editMode={isEditMode}>
    {!isEditMode && (
      <Header
        drawer
        close={isNew}
        back={!isNew}
        bkg="white"
        rightContent={
          <Row>
            {isNew && <Button label={"Create"} onClick={() => handleSave()} />}
            {!isNew && (
              <Row marginX>
                <Anchor
                  label="Delete"
                  color="red"
                  onClick={() => toggleDeleteModal(true)}
                />
              </Row>
            )}
          </Row>
        }
      ></Header>
    )}
    <Menu active="products" />

    <Content>
      <Column center>
        <Column maxWidth="700" paddingBottom={isEditMode ? "20" : "320"}>
          {!isEditMode && (
            <>
              <Row expand paddingY="20">
                <H2 bold>{isNew ? "Add a new Product" : "Edit Product"}</H2>
              </Row>
              <Group label="Name">
                <Column maxWidth="400">
                  <Input
                    small
                    placeholder="Name"
                    value={data?.primaryName}
                    errorMessage={
                      isSavePressed && errors.primaryName
                        ? "Required field"
                        : ""
                    }
                    onChange={(e) =>
                      handleChange({
                        value: e.target?.value,
                        field: "primaryName",
                      })
                    }
                    onBlur={handleNameOnBlur}
                  />
                </Column>
              </Group>
              <Column marginTop>
                <Switch
                  label="Active"
                  checked={!data?.tag?.alwaysIgnore}
                  disabled={!data.alwaysIgnore && mergedTags.length > 0}
                  onChange={(e) =>
                    handleSwitchChange({ value: !e, field: "alwaysIgnore" })
                  }
                />
                <Row marginTop="-10" marginLeft="50">
                  {" "}
                  <Label color="gray" fade="6">
                    This Tag is active and visible.
                    {!data.alwaysIgnore &&
                      mergedTags.length > 0 &&
                      ` Cannot be deactivated as there are merged tags`}
                  </Label>
                </Row>
              </Column>
              {/* <Column marginTop>
                <Switch
                  label="Exact Match"
                  checked={data?.tag?.alwaysMatch}
                  onChange={(e) =>
                    handleSwitchChange({ value: e, field: "alwaysMatch" })
                  }
                />
                <Row marginTop="-10" marginLeft="50">
                  <Label color="gray" fade="6">
                    Set exact match toggle ON for important tags you always want
                    to assign when the exact tag text is found in free text.
                  </Label>
                </Row>
              </Column> */}
            </>
          )}

          {!isNew && (
            <>
              <Column marginTop="40">
                <Collapsible label="Tags">
                  <Column paddingAll gap="15">
                    {/* manage institutions */}
                    {/* {(!isEditMode || searchInstitution) && (
                      <Group label="Product Owner (optional)">
                        <AutoComplete
                          editMode={isEditMode}
                          showOnEmptyTerm
                          displayTextFromSelected
                          searchResults={institutions}
                          placeholder="Select Institution"
                          setSearchTerm={handleSearchInstitution}
                          searchTerm={searchInstitution}
                          selectedItems={selectedInstitution}
                          addItem={addSelectedInstitution}
                          clearSearchResults={clearInstitution}
                          hasClearButton
                        />
                      </Group>
                    )} */}

                    {/* manage competitor products */}
                    {/* {(!isEditMode || selectedProducts.length > 0) && (
                      <Group label="Competitor Products (optional)">
                        <AutoCompleteWithResponses
                          editMode={isEditMode}
                          showOnEmptyTerm
                          searchResults={competitorProducts}
                          placeholder="Add Products"
                          setSearchTerm={(e) => setSearchProduct(e)}
                          searchTerm={searchProduct}
                          selectedItems={selectedProducts}
                          addItem={addSelectedCompetitorProducts}
                          removeItem={removeSelectedCompetitorProducts}
                          clearSearchResults={() => {}}
                          searchLoading={false}
                          hasClearButton
                          infiniteScroll={productsInfiniteScroll}
                        />
                      </Group>
                    )} */}

                    {/* <Row marginTop="40" marginX="-20">
                      <HR />
                    </Row> */}

                    {/* tag monitor inclusions */}
                    {(!isEditMode || includedTagMonitorRules.length > 0) && (
                      <Group
                        first
                        label="Always show posts which include these keywords:"
                      >
                        <AutoCompleteWithResponses
                          editMode={isEditMode}
                          showOnEmptyTerm
                          searchResults={
                            tags.filter((i) => !i.productId && !i.diseaseId) // no products & diseases
                          }
                          placeholder="Add Tags"
                          setSearchTerm={(e) => handleSearchTagToInclude(e)}
                          searchTerm={searchTagToInclude}
                          selectedItems={includedTagMonitorRules}
                          addItem={(e) => addTagMonitorRules(e, true, true)}
                          removeItem={removeTagMonitorRules}
                          clearSearchResults={() => {}}
                          searchLoading={false}
                          excludeItems={allIncludedTagMonitorRules}
                          hasClearButton
                          infiniteScroll={{
                            ...tagsInfiniteScroll,
                            onLoadMore: () =>
                              handleMoreTags(searchTagToInclude),
                          }}
                          onFocus={resetTags}
                          arrowOnClick={resetTags}
                        />
                      </Group>
                    )}

                    {/* tag monitor pair inclusions */}
                    {(!isEditMode || tagPairs.length > 0) && (
                      <Group
                        label="Or"
                        secondLabel="show posts which include these keyword combinations:"
                      >
                        <Column>
                          <RowToColumn gap="20" device="mobileXL">
                            <Row>
                              <AutoComplete
                                editMode={isEditMode}
                                showOnEmptyTerm
                                placeholder="First Tag"
                                displayTextFromSelected
                                searchResults={tags}
                                setSearchTerm={setFirstTagTermPair}
                                searchTerm={firstTagTermPair}
                                selectedItems={includedTagMonitorRules}
                                addItem={setFirstTagPair}
                                clearSearchResults={() => {}}
                                // excludeItems={excludedTagMonitorRules}
                                infiniteScroll={{
                                  ...tagsInfiniteScroll,
                                  onLoadMore: () =>
                                    handleMoreTags(firstTagTermPair),
                                }}
                                hasClearButton
                                onFocus={resetTags}
                                arrowOnClick={resetTags}
                              />
                            </Row>
                            <Row gap="20">
                              <AutoComplete
                                editMode={isEditMode}
                                showOnEmptyTerm
                                placeholder="Second Tag"
                                displayTextFromSelected
                                searchResults={tags}
                                setSearchTerm={setSecondTagTermPair}
                                searchTerm={secondTagTermPair}
                                selectedItems={includedTagMonitorRules}
                                addItem={setSecondTagPair}
                                clearSearchResults={() => {}}
                                // excludeItems={excludedTagMonitorRules}
                                infiniteScroll={{
                                  ...tagsInfiniteScroll,
                                  onLoadMore: () =>
                                    handleMoreTags(secondTagTermPair),
                                }}
                                hasClearButton
                                onFocus={resetTags}
                                arrowOnClick={resetTags}
                              />
                              {!isEditMode && (
                                <Row>
                                  <IconButton
                                    icon="add"
                                    disabled={
                                      !firstTagTermPair ||
                                      !secondTagTermPair ||
                                      firstTagTermPair === secondTagTermPair
                                    }
                                    onClick={() => addTagPair()}
                                  />
                                </Row>
                              )}
                            </Row>
                          </RowToColumn>

                          {tagPairs.length > 0 && (
                            <Row
                              multiline
                              marginTop={isEditMode ? "0" : "20"}
                              flexWrap
                            >
                              {tagPairs.map((item) => (
                                <Pill
                                  key={item.tagId + item.secondTagId}
                                  fade="6"
                                  label={item.tagName}
                                  secondLabel={item.secondTagName}
                                  close
                                  onClose={() => {
                                    removeTagPair(item);
                                  }}
                                />
                              ))}
                            </Row>
                          )}
                        </Column>
                      </Group>
                    )}
                  </Column>
                </Collapsible>

                {!_.isEmpty(metrics) && (
                  <Column marginTop="20">
                    <Collapsible label="Metrics">
                      <Column paddingAll gap="15">
                        <Row gap="10" middle>
                          <Label>From </Label>
                          <DateRange
                            noBackground
                            period
                            initialDate={dates.start}
                            endDate={dates.end}
                            onChange={handleDateChange}
                            noFutureDates
                            allTime
                          />
                        </Row>
                        <Row flexWrap marginBottom="-10">
                          <Label>Posts:</Label>
                          <Label bold>{metrics.tweetCount !== -1 ? metrics.tweetCount : 0}</Label>
                        </Row>
                        <Row flexWrap marginBottom="-10">
                          <Label>Positive Posts:</Label>
                          <Label bold>{metrics.tweetCountBySentiment?.Positive || 0}</Label>
                        </Row>
                        <Row flexWrap marginBottom="-10">
                          <Label>Negative Posts:</Label>
                          <Label bold>{metrics.tweetCountBySentiment?.Negative || 0}</Label>
                        </Row>
                        <Row flexWrap marginBottom="-10">
                          <Label>Neutral Posts:</Label>
                          <Label bold>{metrics.tweetCountBySentiment?.Neutral || 0}</Label>
                        </Row>
                        <Row flexWrap marginBottom="-10">
                          <Label>Accounts:</Label>
                          <Label bold>{metrics.accountCount}</Label>
                        </Row>
                      </Column>
                    </Collapsible>
                  </Column>
                )}

                {usersSubscribed?.length > 0 && (
                  <Column marginTop="20">
                    <Collapsible label="Users Subscribed">
                      <Column paddingAll gap="15">
                        {/* <Group label="Users Subscribed"> */}
                        <Row flexWrap marginBottom="-10">
                          {usersSubscribed.map((user) => (
                            <Pill label={user?.displayName} key={user?.id} />
                          ))}
                        </Row>
                        {/* </Group> */}
                      </Column>
                    </Collapsible>
                  </Column>
                )}

                {data?.tagsAssociatedWith?.length > 0 && (
                  <>
                    {data?.tagsAssociatedWith.filter((t) => t.fromTag.diseaseId)
                      ?.length > 0 && (
                      <Column marginTop="20">
                        <Collapsible label="Diseases (Deprecated)">
                          <Column paddingAll gap="15">
                            {/* <Group label="Diseases (Deprecated)"> */}
                            <Row flexWrap marginBottom="-10">
                              {data.tagsAssociatedWith
                                .filter((t) => t.fromTag.diseaseId)
                                .map((tag) => (
                                  <Pill
                                    label={tag?.fromTag?.name}
                                    key={tag?.fromTag?.name}
                                  />
                                ))}
                            </Row>
                            {/* </Group> */}
                          </Column>
                        </Collapsible>
                      </Column>
                    )}
                    {data?.tagsAssociatedWith.filter(
                      (t) => t.fromTag.conferenceId
                    )?.length > 0 && (
                      <Column marginTop="20">
                        <Collapsible label="Congresses (Deprecated)">
                          <Column paddingAll gap="15">
                            {/* <Group label="Conferences (Deprecated)"> */}
                            <Row flexWrap marginBottom="-10">
                              {data.tagsAssociatedWith
                                .filter((t) => t.fromTag.conferenceId)
                                .map((tag) => (
                                  <Pill
                                    label={tag?.fromTag?.name}
                                    key={tag?.fromTag?.name}
                                  />
                                ))}
                            </Row>
                            {/* </Group> */}
                          </Column>
                        </Collapsible>
                      </Column>
                    )}
                  </>
                )}
              </Column>

              {!data.preferredTagId && (
                <Column marginTop>
                  {(!isEditMode || mergedTags.length > 0) && (
                    <Collapsible open label="Merged Tags">
                      <Column paddingAll gap="10">
                        {!isEditMode && (
                          <Label>
                            Search the list of tags to merge selected tags into
                            the preferred tag shown above. Only the preferred
                            tag will be shown in reports and will be applied to
                            free text even if the merged tag is originally
                            detected.
                          </Label>
                        )}
                        <Row marginTop={isEditMode ? "0" : "20"} fit>
                          <AutoCompleteWithResponses
                            editMode={isEditMode}
                            showOnEmptyTerm
                            searchResults={mergableTags}
                            placeholder="Add Tags"
                            setSearchTerm={(e) => handleSearchMergableTag(e)}
                            searchTerm={searchMergableTag}
                            selectedItems={mergedTags}
                            addItem={addMergedTags}
                            removeItem={removeMergedTags}
                            clearSearchResults={() => {}}
                            searchLoading={false}
                            hasClearButton
                            infiniteScroll={{
                              ...tagsInfiniteScroll,
                              onLoadMore: () =>
                                handleMoreTags(searchTagToInclude),
                            }}
                          />
                        </Row>
                      </Column>
                    </Collapsible>
                  )}
                </Column>
              )}
            </>
          )}
        </Column>

        <Modal
          visible={deleteModal}
          toggleVisibility={toggleDeleteModal}
          title="Delete Product"
          close
        >
          <Column marginBottom gap="20" center>
            <Label>
              <Label large bold>
                {data?.primaryName}
              </Label>
              <Label large> will be deleted</Label>
            </Label>
            <Label large>
              Are you sure you want to continue? This action cannot be undone.
            </Label>
          </Column>
          <ModalFooter right>
            <Button secondary label="Delete" onClick={() => handleDelete()} />
          </ModalFooter>
        </Modal>
      </Column>
    </Content>
  </MainWrapper>
);
