import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import {
  Column,
  Row,
  Label,
  H3,
  H4,
  P,
  Icon,
  Footer,
  MainWrapper,
  Metadata,
  Spinner,
  RowToColumn,
  TweetCard,
  Dropdown,
  ListItem,
  Anchor,
  Avatar,
} from "../../components";
import { HeaderAndDrawer, Counter, Share } from "./components";
import { capitalize } from "../../utils";

const StyledLink = styled(Link)`
  width: 100%;
`;

export const view = ({
  signUpClick,
  disease,
  dols,
  isLoading: loadingDols,
  isSuccess,
  dateItems,
  handleDateChange,
  selectedDate,
  tweet,
  isLoadingTweets,
}) => {
  return (
    <MainWrapper className="dashboard">
      <Metadata />
      <HeaderAndDrawer {...{ signUpClick }} />
      <Column marginTop="100">
        <Column paddingBottom="100">
          <Column center>
            <Column maxWidth="1200" paddingX>
              <Anchor
                bold
                icon="arrowLeft"
                to="/disease_influencers"
                label="Back to top diseases"
              />
              <Column gap="10">
                <H3 bold>
                  Top influencers{" "}
                  {disease?.primaryName && ` in ${disease.primaryName}`}
                </H3>
                <P color="gray" fade="7">
                  Click an influencer to see more information
                </P>
                <RowToColumn device="tablet" gap="20">
                  <Column maxWidth="200">
                    <Dropdown
                      expand
                      value={selectedDate}
                      // In case we have a value from in the redux store
                      // which is not in the dateItems array:
                      placeholder={
                        selectedDate
                          ? `${capitalize(selectedDate.split("-")?.[0])} ${
                              selectedDate.split("-")?.[1]
                            }`
                          : "Select a date"
                      }
                      items={dateItems}
                      onChange={handleDateChange}
                    />
                  </Column>
                  <Share title="Top diseases" />
                </RowToColumn>
              </Column>
              <RowToColumn gap="40" columnGap="10">
                <Column fit maxWidth="600">
                  {loadingDols && (
                    <Row marginY="40" expand center>
                      <Spinner />
                    </Row>
                  )}
                  {isSuccess && dols?.length === 0 && (
                    <Row marginY="40" expand center>
                      <Label color="gray" fade="7">
                        No influencers found!
                      </Label>
                    </Row>
                  )}
                  {dols?.length > 0 && (
                    <Column gap="10" marginTop="40">
                      {dols?.map((dol, index) => (
                        <Column marginX="-10" key={dol.account.id}>
                          <Link to={`/account/${dol.account.id}`}>
                            <ListItem expand middle hover>
                              <RowToColumn gap="10" middle>
                                <Row fit middle gap="10">
                                  <Counter number={index + 1} />
                                  <Avatar
                                    imageUrl={dol.account.imageURL}
                                    name={dol.account.name}
                                    large
                                  />
                                  <Column gap="5">
                                    <Row gap="10" middle>
                                      <H4 bold>{dol.account.name}</H4>
                                      <Label>@{dol.account.username}</Label>
                                    </Row>
                                    <Label color="gray" fade="7">
                                      {dol.account.institutionName}
                                      {dol.account.institutionName &&
                                        dol.account.country &&
                                        ", "}
                                      {dol.account.country}
                                    </Label>
                                  </Column>
                                  <Row fit />
                                </Row>
                                <Label color="gray" fade="7" bold noShrink>
                                  {dol.mentions === 1
                                    ? `${dol.mentions} post`
                                    : `${dol.mentions} posts`}
                                </Label>
                              </RowToColumn>
                            </ListItem>
                          </Link>
                        </Column>
                      ))}
                    </Column>
                  )}
                </Column>
                <Column marginTop="30" maxWidth="560">
                  {isLoadingTweets && (
                    <Row marginY="40" expand center>
                      <Spinner />
                    </Row>
                  )}
                  {tweet && (
                    <Link to={`/post/${tweet.externalId}`}>
                      <TweetCard
                        data={tweet}
                        title={`Top post ${
                          disease?.primaryName && `from ${disease.primaryName}`
                        }`}
                        subtitle={
                          tweet?.tAccount?.lastName &&
                          `Congratulations Dr. ${tweet.tAccount.lastName}. Keep up the good work!`
                        }
                      />
                    </Link>
                  )}
                </Column>
              </RowToColumn>
            </Column>
          </Column>
        </Column>
        <Footer />
      </Column>
    </MainWrapper>
  );
};
