import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import useInfiniteScroll from "react-infinite-scroll-hook";

import { view } from "./PlansView";
import { planActions } from "../../../store/actions";
import { cancelPlanRequests } from "../../../store/sagas";
import { isReady } from "../../../utils";
import { MAX_RESULTS } from "../../../utils/variables";
import { assignDefaultPlan } from "../../../store/sagas/planSagas";

export const AdminPlans = () => {
  const dispatch = useDispatch();

  const statusTypes = [
    { id: "ALL", label: "All" },
    { id: "true", label: "Active" },
    { id: "false", label: "Inactive" },
  ];
  const [tabs, setTabs] = useState([
    { id: "allPlans", label: "Plans", visible: true },
    { id: "planFeatures", label: "Features", visible: true },
    { id: "userPlans", label: "User Plans", visible: true },
  ]);

  // --> mapStateToProp
  // const sync = useSelector((state) => state.plan.sync);
  const plans = useSelector((state) => state.plan.all);
  const basePlans = useSelector((state) => state.plan.basePlans);
  const allUserPlans = useSelector((state) => state.plan.allUserPlans || {});
  const auth = useSelector((state) => state.auth.data);
  // <-- mapStateToProp

  // --> STATE
  const [page, setPage] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState("true");
  const [allPlans, setAllPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState("ALL");
  const [activeTab, setActiveTab] = useState("allPlans");
  // <-- STATE

  const requestAllUserPlans = (pageNum) => {
    dispatch(
      planActions.allUserPlansRequest({
        ...(selectedStatus !== "ALL" && { active: selectedStatus }),
        ...(selectedPlan !== "ALL" && { planId: selectedPlan }),
        pageNum: pageNum,
        maxResult: MAX_RESULTS,
      })
    );
  };

  // --> EFFECT
  useEffect(() => {
    // dispatch(planActions.sync());
    dispatch(planActions.request());
    dispatch(planActions.basePlansRequest());

    return () => {
      cancelPlanRequests.abort();
    };
  }, []);

  useEffect(() => {
    if (isReady(plans) && plans?.data?.length > 0) {
      const _allPlans = [{ id: "ALL", label: "All" }];
      plans.data.forEach((element) => {
        _allPlans.push({
          id: element.id,
          label: element.name + " (" + element.period + ")",
        });
      });
      setAllPlans(_allPlans);
    }
  }, [plans?.data]);

  useEffect(() => {
    requestAllUserPlans(0);
    setPage(0);
  }, [selectedStatus, selectedPlan]);
  // <-- EFFECT

  const handleMoreItems = () => {
    if (!allUserPlans.fetching) {
      const newPage = page + 1;
      requestAllUserPlans(newPage);
      setPage(newPage);
    }
  };

  const [infiniteScrollRef] = useInfiniteScroll({
    loading: allUserPlans.fetching,
    hasNextPage: !allUserPlans.isLastPage,
    onLoadMore: handleMoreItems,
  });

  const handleStatusChange = (id) => {
    setSelectedStatus(id);
  };

  const handlePlanChange = (id) => {
    setSelectedPlan(id);
  };

  const [inProgress, setInProgress] = useState(false);
  const handleAssignDefaultPlan = async () => {
    if (inProgress) return;
    setInProgress(true);
    await assignDefaultPlan();
    setInProgress(false);
  };

  return view({
    activeTab,
    setActiveTab,
    tabs,

    plans: plans.data,
    loading: plans.fetching,

    basePlans: basePlans?.data || [],
    basePlansLoading: basePlans?.fetching || false,

    userPlans: allUserPlans?.data || [],
    userPlansLoading: allUserPlans?.fetching || false,
    infiniteScrollRef,
    hasNextPage: !allUserPlans.isLastPage,

    statusTypes,
    selectedStatus,
    handleStatusChange,

    allPlans,
    selectedPlan,
    handlePlanChange,

    role : auth?.mwRole,
    handleAssignDefaultPlan
  });
};
