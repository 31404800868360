import React, { useEffect } from "react";

import { MainWrapper, Content, Column, Card, Header, H3 } from "../../components";

export const CheckoutError = ({}) => {
  useEffect(() => {
    window.location.replace("/");
  }, []);
  
  return (
  <MainWrapper drawer>
    <Header login user logo />
    <Content>
      <Column center middle>
        <Card small center>
          <H3 bold>Cancelling. . .</H3>
        </Card>
      </Column>
    </Content>
  </MainWrapper>
)};
