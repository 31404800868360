import React from "react";
import Table from "rc-table";
import "../../../components/styles/rc-table.scss";

import {
  Column,
  Row,
  MainWrapper,
  Header,
  Content,
  H2,
  Label,
  Button,
  Dropdown,
  TextArea,
  Grid,
  AutoComplete,
  DateRange,
  Summary,
  HR,
  Toast,
  Icon,
  Anchor,
  Collapsible,
} from "../../../components";
import { Menu } from "../Menu";

export const Group = ({ children, label, description, color = "gray" }) => (
  <Column marginTop="30">
    <Row marginBottom="10">
      <Label bold>{label}</Label>
    </Row>
    {description && (
      <Row marginBottom="10">
        <Label small color={color} fade="0.6">
          {description}
        </Label>
      </Row>
    )}
    <Column marginTop="5">{children}</Column>
  </Column>
);

const columns = [
  {
    title: "Name",
    key: "name",
    render: (cell) => (
      <>
      <Label>{cell.name}</Label>
      <br />
      <Label small bold>Category1 : </Label>
      <Label small bold color={cell.defaultModel ? "green": "red"}>{cell.category1}</Label>
      <br />
      <Label small>ObjectType : {cell.objectType}</Label>
      <br />
      <Label small>ObjectId : {cell.objectId || '-'}</Label>
      <br />
      <Label small>Category2 : {cell.category2 || '-'}</Label>
      <br />
      <Label small bold>Category3 : </Label>
      <Label small bold color={cell.category3 ? "blue": "black"}>{cell.category3 || '-'}</Label>
      </>
    ),
  },
  // {
  //   title: "Category1",
  //   key: "category1",
  //   render: (cell) => (
  //     <Label>{cell.category1}</Label>
  //   ),
  // },
  // {
  //   title: "Object Type",
  //   key: "objectType",
  //   render: (cell) => (
  //     <Label>{cell.objectType}</Label>
  //   ),
  // },
  // {
  //   title: "Category3",
  //   key: "category3",
  //   render: (cell) => (
  //     <Label>{cell.category3}</Label>
  //   ),
  // },
  {
    title: "Value",
    key: "value",
    render: (cell) => (
      <Label style={{ whiteSpace: "normal" }}>{cell.value}</Label>
    ),
  },
]

export const view = ({
  parameterNames,
  objectTypes,

  errors,
  handleSave,

  parameterName,
  handleParameterNameChange,

  promptValue,
  setPromptValue,

  objectType,
  setObjectType,

  monitorTypes,
  monitorType,
  setMonitorType,

  userTypes,
  userType,
  handleUserTypeChange,

  isSavePressed,
  handleRegenerate,
  showRegenerate,
  showToast,
  setShowToast,

  backendError,
  showErrorToast,
  setShowErrorToast,

  model,
  models,
  handleModelChange,

  diseases,
  setSearchDisease,
  searchDisease,
  selectedDiseases,
  addSelectedDiseases,
  clearSelectedDiseases,
  diseasesInfiniteScroll,

  products,
  setSearchProduct,
  searchProduct,
  selectedProducts,
  addSelectedProducts,
  clearSelectedProducts,
  productsInfiniteScroll,

  accounts,
  setSearchAccount,
  searchAccount,
  selectedAccounts,
  addSelectedAccounts,
  clearSelectedAccounts,
  accountsInfiniteScroll,

  date,
  handleDateChange,

  sortBy,
  handleSortByChange,

  temperature,
  handleTemperatureChange,

  maxPosts,
  handleMaxPostsChange,

  source,
  handleSourceChange,

  topP,
  handleTopPChange,

  frequencyPenalty,
  handleFrequencyPenaltyChange,

  presencePenalty,
  handlePresencePenaltyChange,

  gptParameterVariants,
  getGPTParameterVariants,

  handleAiRefresh,
  response,
  requestTokens,
  responseTokens,
  keywords,
  fetchingResponse,
}) => (
  <MainWrapper drawer>
    <Header
      drawer
      bkg="white"
      rightContent={
        <Button small secondary label="Save" onClick={() => handleSave()} />
      }
    />
    <Menu active="ai" />
    <Content>
      <Toast
        visible={showToast}
        toggleVisibility={setShowToast}
        expiration={2000}
      >
        <Row gap="5" middle>
          <Icon name="check" />
          <Label>AI Prompt updated</Label>
        </Row>
      </Toast>
      <Toast
        visible={showErrorToast}
        toggleVisibility={setShowErrorToast}
        expiration={2000}
        warning
      >
        <Row gap="5" middle>
          <Icon name="warning" />
          <Label>{backendError}</Label>
        </Row>
      </Toast>

      <Column center>
        <Column maxWidth="700" marginBottom>
          <Row paddingTop="20">
            <H2 bold>AI configuration</H2>
          </Row>
          <Column>
            <Column>
              {/* <Grid columns={3}>
                <Group label="Configurtion Name">
                  <Dropdown
                    select
                    value="Default"
                    items={[{ id: "Default", label: "Default" }]}
                    onChange={(e) => setObjectType(e.id)}
                  />
                </Group>
                <div />
                <Row right gap="10" bottom>
                  <Button small secondary label="Save as ..." />
                </Row>
              </Grid> 
              <Column marginTop="30">
                <HR />
                <Row marginTop="30">
                  <Label color="gray" fade="7">
                    Select a Disease or Product to see a response
                  </Label>
                </Row>
              </Column>
              */}
              <Grid columns={2}>
                <Group
                  label="Parameter Name"
                  description={"GPT Parameter (from database)"}
                >
                  <Dropdown
                    expand
                    select
                    value={parameterName}
                    items={parameterNames}
                    onChange={handleParameterNameChange}
                  />
                </Group>
                <Group label="Model" description={"Language model"}>
                  <Dropdown
                    expand
                    small
                    select
                    value={model}
                    items={models}
                    onChange={handleModelChange}
                  />
                </Group>
              </Grid>
              <Grid columns={3}>
                {(parameterName === "GPTSummaryQuery" ||
                  parameterName === "GPTSummaryRole") && (
                  <>
                    <Group label="Type" description={"Save object type in DB"}>
                      <Dropdown
                        expand
                        select
                        value={objectType}
                        items={objectTypes}
                        onChange={(e) => setObjectType(e.id)}
                      />
                    </Group>
                    {objectType === "DISEASE" &&
                      parameterName === "GPTSummaryQuery" && (
                        <Group
                          label="Disease"
                          description={
                            "For testing; NOT saved in DB"
                          }
                          color="red"
                        >
                          <AutoComplete
                            showOnEmptyTerm
                            displayTextFromSelected
                            searchResults={diseases}
                            placeholder="Select one"
                            setSearchTerm={setSearchDisease}
                            searchTerm={searchDisease}
                            selectedItems={selectedDiseases}
                            addItem={addSelectedDiseases}
                            clearSearchResults={clearSelectedDiseases}
                            searchLoading={false}
                            hasClearButton
                            infiniteScroll={diseasesInfiniteScroll}
                          />
                        </Group>
                      )}
                    {objectType === "PRODUCT" &&
                      parameterName === "GPTSummaryQuery" && (
                        <Group
                          label="Product"
                          description={
                            "For testing; NOT saved in DB"
                          }
                          color="red"
                        >
                          <AutoComplete
                            showOnEmptyTerm
                            displayTextFromSelected
                            searchResults={products}
                            placeholder="Select one"
                            setSearchTerm={setSearchProduct}
                            searchTerm={searchProduct}
                            selectedItems={selectedProducts}
                            addItem={addSelectedProducts}
                            clearSearchResults={clearSelectedProducts}
                            searchLoading={false}
                            hasClearButton
                            infiniteScroll={productsInfiniteScroll}
                          />
                        </Group>
                      )}
                    {objectType === "ACCOUNT" &&
                      parameterName === "GPTSummaryQuery" && (
                        <Group
                          label="Account"
                          description={
                            "For testing; NOT saved in DB"
                          }
                          color="red"
                        >
                          <AutoComplete
                            showOnEmptyTerm
                            displayTextFromSelected
                            searchResults={accounts}
                            placeholder="Select one"
                            setSearchTerm={setSearchAccount}
                            searchTerm={searchAccount}
                            selectedItems={selectedAccounts}
                            addItem={addSelectedAccounts}
                            clearSearchResults={clearSelectedAccounts}
                            searchLoading={false}
                            hasClearButton
                            infiniteScroll={accountsInfiniteScroll}
                          />
                        </Group>
                      )}
                    {(parameterName === "GPTSummaryQuery" || parameterName === "GPTSummaryRole") && (
                      <Group label="User type" description={"Save user type in DB"}>
                        <Dropdown
                          expand
                          select
                          value={userType}
                          items={userTypes}
                          onChange={handleUserTypeChange}
                        />
                      </Group>
                    )}
                  </>
                )}
              </Grid>

              <Grid columns={3}>
                {(parameterName === "GPTInsightSummaryQuery" ||
                  parameterName === "GPTMailSummaryQuery") && (
                  <>
                    {parameterName === "GPTMailSummaryQuery" && (
                      <Group
                        label="Monitor type"
                        description={"For testing; NOT saved in DB"}
                        color="red"
                      >
                        <Dropdown
                          expand
                          select
                          value={monitorType}
                          items={monitorTypes}
                          onChange={(e) => setMonitorType(e.id)}
                        />
                      </Group>
                    )}
                    {(parameterName === "GPTInsightSummaryQuery" ||
                      (monitorType === "DISEASE" &&
                        parameterName === "GPTMailSummaryQuery")) && (
                          <Group
                            label="Disease"
                            description={"For testing; NOT saved in DB"}
                            color="red"
                          >
                            <AutoComplete
                              showOnEmptyTerm
                              displayTextFromSelected
                              searchResults={diseases}
                              placeholder="Select one"
                              setSearchTerm={setSearchDisease}
                              searchTerm={searchDisease}
                              selectedItems={selectedDiseases}
                              addItem={addSelectedDiseases}
                              clearSearchResults={clearSelectedDiseases}
                              searchLoading={false}
                              hasClearButton
                              infiniteScroll={diseasesInfiniteScroll}
                            />
                          </Group>
                        )}
                    {monitorType === "PRODUCT" &&
                      parameterName === "GPTMailSummaryQuery" && (
                        <Group
                          label="Product"
                          description={"For testing; NOT saved in DB"}
                          color="red"
                        >
                          <AutoComplete
                            showOnEmptyTerm
                            displayTextFromSelected
                            searchResults={products}
                            placeholder="Select one"
                            setSearchTerm={setSearchProduct}
                            searchTerm={searchProduct}
                            selectedItems={selectedProducts}
                            addItem={addSelectedProducts}
                            clearSearchResults={clearSelectedProducts}
                            searchLoading={false}
                            hasClearButton
                            infiniteScroll={productsInfiniteScroll}
                          />
                        </Group>
                      )}
                    {parameterName === "GPTMailSummaryQuery" && (
                      <Group label="User type" description={"For testing; NOT saved in DB"} color="red">
                        <Dropdown
                          expand
                          select
                          value={userType}
                          items={userTypes}
                          onChange={handleUserTypeChange}
                        />
                      </Group>
                    )}
                  </>
                )}
              </Grid>

              {(parameterName === "GPTSummaryQuery" ||
                parameterName === "GPTInsightSummaryQuery" ||
                parameterName === "GPTMailSummaryQuery") && (
                <Column marginTop="20">
                  <Collapsible label="Additional attributes">
                    <Column paddingAll="10">
                      <Column>
                        <Row marginTop>
                          <Label bold color="gray" fade="0.7">
                            NOTE:{" "}
                          </Label>
                          <Label color="blue" fade="0.7">
                            Below attributes are only for summary API result
                            testing and are NOT saved in database
                          </Label>
                        </Row>
                      </Column>
                      <Grid columns={2}>
                        <Group
                          label="Date Range"
                          description={
                            "Select date range to be applied while selecting posts for preparing summary"
                          }
                        >
                          <DateRange
                            initialDate={date.start}
                            endDate={date.end}
                            onChange={handleDateChange}
                            noFutureDates
                          />
                        </Group>

                        <Group
                          label="Max Posts"
                          description={
                            "Maximum number of posts to be considered while preparing summary"
                          }
                        >
                          <Dropdown
                            expand
                            select
                            value={maxPosts}
                            items={[
                              3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
                              17, 18, 19, 20, 21, 22, 23, 24, 25,
                            ].map((t) => ({ id: t, label: t }))}
                            onChange={handleMaxPostsChange}
                          />
                        </Group>

                        <Group
                          label="Sort by"
                          description="Posts selection should be done by sorting either by influence or newest"
                        >
                          <Dropdown
                            expand
                            small
                            select
                            value={sortBy}
                            items={[
                              { id: "score", label: "Most Influential" },
                              { id: "date", label: "Newest" },
                            ]}
                            onChange={handleSortByChange}
                          />
                        </Group>

                        <Group
                          label="Source"
                          description="Select the source from where the posts should be considered while creating summary"
                        >
                          <Dropdown
                            expand
                            select
                            value={source}
                            items={[
                              { id: "ALL", label: "All" },
                              { id: "X", label: "X" },
                              { id: "YT", label: "YouTube" },
                              { id: "PC", label: "Podcast" },
                            ]}
                            onChange={handleSourceChange}
                          />
                        </Group>

                        <Group
                          label="Temperature"
                          description="Temperature can be used to control the overall randomness of the generated text. Generally, it recommended to either change this or top_p, but not both"
                        >
                          <Dropdown
                            expand
                            select
                            value={temperature}
                            items={[
                              0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0,
                            ].map((t) => ({ id: t, label: t }))}
                            onChange={handleTemperatureChange}
                          />
                        </Group>

                        <Group
                          label="Top_p"
                          description="Top_p sampling (aka nucleus sampling) is an alternative to temperature. If top_p is set to 0.1, only the tokens that make up the top 10% of the probability mass"
                        >
                          <Dropdown
                            expand
                            select
                            value={topP}
                            items={[
                              0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0,
                            ].map((t) => ({ id: t, label: t }))}
                            onChange={handleTopPChange}
                          />
                        </Group>

                        <Group
                          label="Frequency Penalty"
                          description="Frequency Penalty penalizes repetition; higher numbers make the output diverse, promoting rarer words, while negative values get a more common, sometimes repetitive, language"
                        >
                          <Dropdown
                            expand
                            select
                            value={frequencyPenalty}
                            items={[
                              "-1.0",
                              "-0.9",
                              "-0.8",
                              "-0.7",
                              "-0.6",
                              "-0.5",
                              "-0.4",
                              "-0.3",
                              "-0.2",
                              "-0.1",
                              "0",
                              "0.1",
                              "0.2",
                              "0.3",
                              "0.4",
                              "0.5",
                              "0.6",
                              "0.7",
                              "0.8",
                              "0.9",
                              "1.0",
                            ].map((t) => ({ id: t, label: t }))}
                            onChange={handleFrequencyPenaltyChange}
                          />
                        </Group>

                        <Group
                          label="Presence Penalty"
                          description="Presence penalty gives a penalty to tokens that have been used in the input. Affects how often the AI repeats words and phrases from your input. Positive values discourage repetition"
                        >
                          <Dropdown
                            expand
                            select
                            value={presencePenalty}
                            items={[
                              "-1.0",
                              "-0.9",
                              "-0.8",
                              "-0.7",
                              "-0.6",
                              "-0.5",
                              "-0.4",
                              "-0.3",
                              "-0.2",
                              "-0.1",
                              "0",
                              "0.1",
                              "0.2",
                              "0.3",
                              "0.4",
                              "0.5",
                              "0.6",
                              "0.7",
                              "0.8",
                              "0.9",
                              "1.0",
                            ].map((t) => ({ id: t, label: t }))}
                            onChange={handlePresencePenaltyChange}
                          />
                        </Group>
                      </Grid>
                    </Column>
                  </Collapsible>
                </Column>
              )}
              <Column marginTop="30">
                <HR />
              </Column>
            </Column>

            <Group label="Prompt">
              <TextArea
                placeholder="AI Prompt"
                expand
                value={promptValue}
                errorMessage={isSavePressed && errors.value}
                onChange={(e) => setPromptValue(e.target?.value)}
                onBlur={handleRegenerate}
              />
              {(parameterName === "GPTSummaryQuery" ||
                parameterName === "GPTInsightSummaryQuery" ||
                parameterName === "GPTMailSummaryQuery") && (
                <Row marginTop>
                  <Anchor
                    line
                    icon="reload"
                    label="Regenerate"
                    bold
                    disabled={!showRegenerate}
                    onClick={() => handleRegenerate()}
                  />
                </Row>
              )}
            </Group>

            <Column marginTop="30" marginBottom>
              <HR />
            </Column>

            {(parameterName === "GPTSummaryQuery" ||
              parameterName === "GPTInsightSummaryQuery" ||
              parameterName === "GPTMailSummaryQuery") && (
              <>
                {requestTokens > 0 && (
                  <Row marginTop="10">
                    <Label>
                      Request tokens: <b>{requestTokens}</b>
                    </Label>
                  </Row>
                )}
                {responseTokens > 0 && (
                  <Row marginTop="10" marginBottom="10">
                    <Label>
                      Response tokens: <b>{responseTokens}</b>
                    </Label>
                  </Row>
                )}
                <Summary
                  monitors={
                    objectType === "DISEASE" && selectedDiseases[0]?.label
                      ? [selectedDiseases[0].label]
                      : objectType === "PRODUCT" && selectedProducts[0]?.label
                      ? [selectedProducts[0].label]
                      : []
                  }
                  isNewAI={false}
                  isAdminUI={true}
                  isFetching={fetchingResponse}
                  isSuccess={false}
                  {...{
                    //   addSelectedTag,
                    response,
                    handleAiRefresh,
                    keywords,
                  }}
                />
              </>
            )}
          </Column>
        </Column>
      </Column>

      <Column marginTop="30" marginBottom>
        <HR />
      </Column>
      <Column>
        <Row paddingTop paddingBottom gap="10">
          <Label>Variants of {parameterName}: </Label>
          <Anchor
            bold
            label={`Default model`}
            onClick={() => getGPTParameterVariants(true)}
          />
          <Anchor
            bold
            label={`All models`}
            onClick={() => getGPTParameterVariants(false)}
          />
        </Row>
      </Column>
      <Column>
        {gptParameterVariants.length > 0 && (
          <Table
            rowKey="id"
            data={gptParameterVariants}
            {...{ columns }}
            style={{ maxWidth: 980 }}
            scroll={{ x: 978 }}
          />
        )}
      </Column>
    </Content>
  </MainWrapper>
);
