import React, { useEffect, useState } from "react";
import { view } from "./CongressView";
import { useSelector } from "react-redux";
import {
  logAnalyticEvent,
  getUrlParams,
  getISOFormattedDateTimeString,
} from "../../utils";
import { useConferenceInfluencers } from "../../store/queries/influencers";
import { useGetTweets } from "../../store/queries/tweets";
import moment from "moment";

export const CongressInfluencers = ({ signUpClick }) => {
  const auth = useSelector((state) => state.auth.data);
  const [selectedConference, setSelectedConference] = useState(null);

  const {
    data: conferences,
    isLoading: loadingConferences,
    isSuccess,
  } = useConferenceInfluencers({});

  const {
    data: tweets,
    isLoading: isLoadingTweets,
    refetch: refetchTweets,
  } = useGetTweets(
    {
      ...(selectedConference && { addTagIds: selectedConference }),
      ...(auth?.id && { userId: auth.id }),
      sortBy: "score",
      sortOrder: "desc",
      maxResult: 1,
      pageNum: 0,
      sortBy: "score",
      sortOrder: "desc",
      includeDiseasesForConference: "true",
      originalOnly: "false",
      includeReplies: "true",
      startDate: getISOFormattedDateTimeString(
        conferences?.data?.[0]?.monitorStartTime
      ),
      endDate: getISOFormattedDateTimeString(
        moment(conferences?.data?.[0]?.monitorEndTime)?.add(7, "day")?._d
      ),
    },
    { enabled: !!selectedConference }
  );

  useEffect(() => {
    // Uncomment and update this section when ready to implement analytics
    // const queryParams = getUrlParams();
    // logAnalyticEvent(
    //   "Landing",
    //   {
    //     page: "Home",
    //     section: queryParams.section ? queryParams.section : "marketing",
    //   },
    //   auth?.id || queryParams?.u || null,
    //   auth?.displayName || queryParams?.n || null,
    //   auth?.email || queryParams?.e || null,
    //   auth?.createdAt || null,
    //   auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
    //     ?.preferenceStringValue ||
    //     queryParams?.t ||
    //     "MEDICAL",
    //   null
    // );
  }, []);

  useEffect(() => {
    if (conferences?.data?.length > 0) {
      setSelectedConference([
        conferences.data[0].tagId,
        conferences.data[0].parentTagId,
      ]);
    }
  }, [conferences?.data]);

  useEffect(() => {
    if (selectedConference) {
      refetchTweets();
    }
  }, [selectedConference]);

  return view({
    signUpClick,
    conferences: conferences?.data,
    loadingConferences,
    isSuccess,
    tweet: tweets?.data?.[0],
    isLoadingTweets,
  });
};
