import { useQuery } from "react-query";
import axios from "axios";
import { getApiPath } from "../../utils/variables";
import { objectToUrlEncoded } from "../../utils/constants";
import { MAX_RESULTS } from "../../utils/variables";

export function getDisease(id) {
  const url = `${getApiPath(false)}/disease/${id}?${Date.now()}`;
  //${nocache ? "&nocache=true" : "}
  return axios.get(url, {});
}

export function useGetDisease(id) {
  return useQuery([`disease-${id}`], () => getDisease(id), {
    refetchOnMount: false,
    cacheTime: 300000,
  });
}

export function getDiseaseDols(data) {
  const {
    id,
    userId,
    startDate,
    endDate,
    accountType,
    accountSubType,
    regionName,
    addTagIds,
    originalOnly,
    maxResult,
  } = data;

  let regionList = "";
  if (regionName?.length > 0) {
    regionName.forEach((region) => {
      regionList += `regionName=${region}&`;
    });
  }
  let tagIdList = "";
  if (addTagIds?.length > 0) {
    addTagIds.forEach((tagId) => {
      tagIdList += `addTagId=${tagId}&`;
    });
  }
  const params = objectToUrlEncoded({
    userId,
    startDate,
    endDate,
    accountType: accountType || "PERSON",
    accountSubType: accountSubType,
    originalOnly,
    maxResult: maxResult || MAX_RESULTS,
  });
  const url = `${getApiPath(
    false
  )}/disease/${id}/dols?${tagIdList}${regionList}${params}`;
  return axios.get(url, {});
}

export function useGetDiseaseDols(data) {
  return useQuery(
    [`disease-dols-${data.id}${data?.startDate ? `-${data.startDate}` : ""}`],
    () => getDiseaseDols(data),
    {
      refetchOnMount: false,
      cacheTime: 300000,
    }
  );
}
