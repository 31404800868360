import React from "react";

import {
  MainWrapper,
  Content,
  Column,
  Header,
  Row,
  Spinner,
  Drawer,
  H1,
  H2,
  H4,
  Button,
  P,
  Anchor,
  TextArea,
  Label,
  Icon,
  Footer,
  Metadata,
  HR,
} from "../../components";
import { formatShortDates, idToText, insightHighlight } from "../../utils";
import { Color } from "../../utils/variables";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Article = styled(Column)`
  background: ${Color("gray", "1")};

  &:hover {
    background: ${Color("green", "1")};
  }
`;

const Image = styled.img`
  max-width: 368px;
  max-height: 368px;
  float: left;
  margin-right: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
`;

const HTML = styled.div`
  .highlights {
    min-width: 100%;
    display: block;

    br + br {
      margin-top: 0;
    }
  }
`;

export const view = ({
  user,
  isAdmin,
  selectedObjectId,
  objectSummaries,
  inProgress,
  items,
  handleSignUpClick,
  editMode,
  setEditMode,
  title,
  setTitle,
  handleUpdateTitle,
  handleCancelEditing,
  handleRegenerate,
  isRegenerating,
  error,
  contentRef,
}) => (
  <MainWrapper drawer footer>
    <Header drawer white login user signUpClick={handleSignUpClick} />
    <Drawer
      white
      login
      {...{ items }}
      active={
        items?.find((item) => idToText(item.label) === selectedObjectId)?.id ||
        items?.[0]?.id
      }
      signUpClick={handleSignUpClick}
    />

    <Content ref={contentRef}>
      {selectedObjectId ? (
        <Column expand>
          {inProgress ? (
            <Column expand paddingAll center middle>
              <Spinner />
            </Column>
          ) : (
            <>
              {objectSummaries.length > 0 ? (
                <Column marginTop="10" maxWidth={750}>
                  <Metadata
                    title={`See what physicians are discussing in ${objectSummaries[0].name}`}
                    description={objectSummaries[0].title}
                    image={objectSummaries[0].imageUrl}
                  />

                  <Row marginBottom="30">
                    <H1 like="h2" bold>
                      See what physicians are discussing in{" "}
                      {objectSummaries[0].name}
                    </H1>
                  </Row>

                  {objectSummaries.slice(0, 2).map((item) => (
                    <Column key={item.id} marginBottom="40">
                      <Row marginBottom expand gap="10" top>
                        {!editMode ? (
                          <H2 like="h4" bold>
                            <title>{item.title}</title>
                          </H2>
                        ) : (
                          <Column marginTop="-10" stretch gap="10">
                            <TextArea
                              expand
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                            />
                            <Row expand marginBottom gap="10">
                              <Button
                                minWidth={100}
                                small
                                label="Save"
                                onClick={() => handleUpdateTitle(item.id)}
                              />
                              <Button
                                secondary
                                minWidth={100}
                                small
                                label="Cancel"
                                onClick={handleCancelEditing}
                              />
                            </Row>
                          </Column>
                        )}
                      </Row>
                      {isAdmin && !editMode && (
                        <Row marginBottom>
                          {isRegenerating ? (
                            <Row expand center>
                              <Spinner />
                            </Row>
                          ) : (
                            <Column gap="20">
                              <Row gap="20">
                                <Anchor
                                  bold
                                  line
                                  icon="reload"
                                  label="Regenerate Insight"
                                  onClick={() => handleRegenerate(item.id)}
                                />
                                <Anchor
                                  bold
                                  line
                                  icon={item.title ? "edit" : "add"}
                                  label={
                                    item.title ? "Edit Title" : "Add title"
                                  }
                                  onClick={() => {
                                    setTitle(item.title);
                                    setEditMode(true);
                                  }}
                                />
                              </Row>
                              {error && (
                                <Column gap="5">
                                  <Label color="red">{error}</Label>
                                  <Label color="red">Please, try again.</Label>
                                </Column>
                              )}
                            </Column>
                          )}
                        </Row>
                      )}

                      <div>
                        {item.imageUrl && <Image src={item.imageUrl} />}
                        <article>
                          <HTML>
                            {insightHighlight({
                              text: item.content,
                              excludeBreaks: true,
                            })}
                          </HTML>
                        </article>
                      </div>
                      <Column marginTop="30" gap="5" right>
                        <Label large color="gray" fade="7">
                          {formatShortDates(item.startDate, item.endDate)}
                        </Label>
                        <Label color="gray" fade="7">
                          by Medical.watch Research
                        </Label>
                      </Column>

                      <Row marginTop="40">
                        <HR />
                      </Row>
                    </Column>
                  ))}

                  {objectSummaries[2] &&
                    NextArticle({ item: objectSummaries[2] })}
                  {objectSummaries[3] &&
                    NextArticle({ item: objectSummaries[3] })}

                  <Column
                    marginBottom="30"
                    bkg="gray"
                    fade="1"
                    paddingAll="30"
                    center
                    gap="20"
                    radius="10"
                  >
                    {user?.id ? (
                      <>
                        <H4 bold center>
                          See more physician insights about{" "}
                          {objectSummaries[0].name}
                        </H4>
                        <Button
                          label="View Insights"
                          to={`/?diseaseId=${objectSummaries[0].objectId}`}
                        />
                      </>
                    ) : (
                      <>
                        <H4 bold center>
                          Get social media insights for your products and
                          diseases
                        </H4>
                        <Button label="Sign up for Medical.watch" to="/" />
                      </>
                    )}
                  </Column>
                </Column>
              ) : (
                <Column paddingAll="20" center middle>
                  <P color="gray" fade="7">
                    No summary found for the disease
                  </P>
                </Column>
              )}
            </>
          )}
        </Column>
      ) : (
        <Column paddingAll="20" center middle>
          <P color="gray" fade="7">
            No Diseases found
          </P>
        </Column>
      )}
    </Content>
    <Footer />
  </MainWrapper>
);

const NextArticle = ({ item }) => (
  <Link to={`/insight/${item.objectId}/${item.id}`}>
    <Article
      marginBottom="30"
      bkg="gray"
      fade="1"
      paddingAll="20"
      gap="20"
      radius="10"
      className="pointer"
    >
      <Row middle>
        <Row fit>
          <Column gap="10">
            <H4>{item.title}</H4>
            <Label color="gray" fade="8">
              {formatShortDates(item.startDate, item.endDate)}
            </Label>
          </Column>
        </Row>
        <Row noShrink marginLeft="10">
          <Icon color="green" fade="6" name="chevronRight" />
        </Row>
      </Row>
    </Article>
  </Link>
);
