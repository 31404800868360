import React from "react";
import { Link } from "react-router-dom";
import { formatMonthDayDates } from "../../../utils";
import { Footer, Subscribe } from "../components";

import {
  Row,
  MainWrapper,
  Header,
  Content,
  Label,
  Column,
  Spinner,
  H4,
  HR,
  Card,
  TweetCard,
} from "../../../components";

export const view = ({ isLoggedIn, name, loading, dates, monitor, tweets }) => {
  return (
    <MainWrapper>
      <Header linkHome login user></Header>
      <Content>
        <Column center>
          <Column maxWidth="600">
            <Column style={{ verticalAlign: "top" }} marginTop="10">
              <Subscribe />
              <Column marginBottom="30" marginTop="10" stretch gap="20">
                <Label>Hi{name ? ` ${decodeURIComponent(name)}` : ""},</Label>
                <Label>
                  Here is your social media report
                  {monitor?.primaryName && ` for ${monitor.primaryName}`},{" "}
                  {formatMonthDayDates(dates)}
                </Label>
              </Column>

              {tweets.map((t, index) => {
                const hasQuote = t?.quoted?.length > 0;

                return (
                  <Column
                    stretch
                    id={`tweet-${t.externalId}`}
                    key={t.externalId}
                    marginBottom
                  >
                    {index !== 0 && (
                      <Row marginBottom="30" marginTop="5">
                        <HR />
                      </Row>
                    )}
                    <Column>
                      <Link to={`/post/${t.externalId}?source=email`}>
                        <Card
                          border={hasQuote}
                          paddingAll={!hasQuote ? "0" : "20"}
                        >
                          <TweetCard data={t} {...{ hasQuote }} />
                          {hasQuote && (
                            <Column marginTop="30">
                              <TweetCard
                                key={t.quoted[0].externalId}
                                data={t.quoted[0]}
                              />
                            </Column>
                          )}
                        </Card>{" "}
                        {/* {t?.medias?.length > 0 && (
                          <Row marginTop="10">
                            <TweetMedia medias={t.medias} grid />
                          </Row>
                        )}
                        {t?.quoted?.length > 0 && (
                          <Column>
                            <Row flexWrap expand>
                              {t?.quoted.map((i) => (
                                <TweetCard
                                  id={`quoted-tweet-${t.externalId}`}
                                  key={i.externalId}
                                  data={i}
                                />
                              ))}
                            </Row>
                          </Column>
                        )}
                      </TweetCard> */}
                      </Link>
                    </Column>

                    <Column marginTop>
                      <Column gap="5">
                        <H4>{t.tAccount.name}</H4>
                        <Label color="gray" fade="6">
                          {t.tAccount.description}
                        </Label>
                      </Column>
                      {t.tAccount.location && (
                        <Column gap="5" marginTop>
                          <Label color="gray" fade="6">
                            Location
                          </Label>
                          <Label>{t.tAccount.location}</Label>
                        </Column>
                      )}
                    </Column>
                  </Column>
                );
              })}
              {loading && (
                <Row expand paddingTop marginBottom="60" center>
                  <Spinner />
                </Row>
              )}
              {tweets.length === 0 && !loading && (
                <Row expand paddingTop paddingBottom="50" center>
                  <Label large color="gray" fade="6">
                    No posts found
                  </Label>
                </Row>
              )}
            </Column>
          </Column>
          <Footer />
        </Column>
      </Content>
    </MainWrapper>
  );
};
