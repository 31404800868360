import { AUTH_ACTIONS } from "../actions";

const initialState = {
  data: {},
  user: {},
  fetching: false,
  success: false,
  error: null,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case AUTH_ACTIONS.SET_TOKEN:
      return {
        ...state,
        data: { ...state.data, ...action.payload.data },
      };

    case AUTH_ACTIONS.REQUEST:
    case AUTH_ACTIONS.SUCCESS:
    case AUTH_ACTIONS.TOKEN_REQUEST:
    case AUTH_ACTIONS.TOKEN_SUCCESS:
      return {
        ...state,
        data: { ...state.data, ...action.payload.data },
      };

    case AUTH_ACTIONS.USER_REQUEST:
    case AUTH_ACTIONS.USER_SUCCESS:
      return {
        ...state,
        data: { ...state.data, ...action.payload.data },
      };

    case AUTH_ACTIONS.FAILURE:
    case AUTH_ACTIONS.USER_FAILURE:
    case AUTH_ACTIONS.TOKEN_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };

    case AUTH_ACTIONS.CLEAR:
      return {
        ...state,
        data: {},
        error: null,
      };

    case AUTH_ACTIONS.DELETE:
      return {
        ...state,
        data: {},
        user: {},
      };
    default:
      return state;
  }
}
