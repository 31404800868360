import { CHAT_ACTIONS } from "../actions";

const initialState = {
  all: { data: [], fetching: false, success: false, error: null },
  one: { data: [], fetching: false, success: false, error: null },
  additional: { data: [], fetching: false, success: false, error: null },
};

export default function chatReducer(state = initialState, action) {
  switch (action.type) {
    case CHAT_ACTIONS.REQUEST:
    case CHAT_ACTIONS.FAILURE:
    case CHAT_ACTIONS.SUCCESS:
    case CHAT_ACTIONS.CLEAR_ALL:
      return { ...state, all: action.payload };

    case CHAT_ACTIONS.ONE_REQUEST:
    case CHAT_ACTIONS.ONE_SUCCESS:
    case CHAT_ACTIONS.ONE_FAILURE:
    case CHAT_ACTIONS.CREATE:
      return { ...state, one: action.payload };

    case CHAT_ACTIONS.UPDATE:
    case CHAT_ACTIONS.CREATE_ADDITIONAL:
    case CHAT_ACTIONS.ADDITIONAL_SUCCESS:
    case CHAT_ACTIONS.ADDITIONAL_FAILURE:
      return { ...state, additional: action.payload };

    case CHAT_ACTIONS.CLEAR:
      return { ...state, one: action.payload, additional: action.payload };

    default:
      return state;
  }
}
