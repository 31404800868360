import { COMPANY_ACTIONS } from "../actions";

const initialState = {
  all: { data: [], fetching: false, success: false, error: null },
  one: { data: [], fetching: false, success: false, error: null },
};

export default function companyReducer(state = initialState, action) {
  switch (action.type) {
    case COMPANY_ACTIONS.REQUEST:
    case COMPANY_ACTIONS.SUCCESS:
    case COMPANY_ACTIONS.FAILURE:
      return { ...state, all: { ...state.all, ...action.payload } };

    case COMPANY_ACTIONS.ONE_REQUEST:
    case COMPANY_ACTIONS.ONE_SUCCESS:
    case COMPANY_ACTIONS.ONE_FAILURE:
    case COMPANY_ACTIONS.CLEAR:
      return { ...state, one: { ...state.one, ...action.payload } };

    default:
      return state;
  }
}
