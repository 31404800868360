import { useQuery } from "react-query";
import axios from "axios";
import { getApiPath } from "../../utils/variables";
import { objectToUrlEncoded } from "../../utils/constants";

export function getTagSummary(data) {
  const { id, maxResult, objectType } = data;
  const params = objectToUrlEncoded({ maxResult, objectType });
  return axios.get(
    `${getApiPath(false)}/account/${id}/tagSummary?${params}`,
    {}
  );
}

export function useGetTagSummary(data) {
  return useQuery(["accountTagSummary"], () => getTagSummary(data), {
    cacheTime: 0,
  });
}
