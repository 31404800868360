import { createAction, createActionTypes } from "../../utils";

export const TWEET_ACTIONS = createActionTypes("TWEET_ACTIONS", [
  "REQUEST",
  "MULTIPLE_REQUEST",
  "SUCCESS",
  "FAILURE",

  "ONE_REQUEST",
  "ONE_SUCCESS",
  "ONE_FAILURE",
  "CLEAR",
  "CLEAR_TWEETS",

  "TWEET_REPLIES_REQUEST",
  "TWEET_REPLIES_SUCCESS",
  "TWEET_REPLIES_FAILURE",

  "REPLY_URLS_REQUEST",
  "REPLY_URLS_SUCCESS",
  "REPLY_URLS_FAILURE",

  "TWEETS_COUNT_REQUEST",
  "TWEETS_COUNT_SUCCESS",
  "TWEETS_COUNT_FAILURE",

  "ACCOUNTS_REPLIED_REQUEST",
  "ACCOUNTS_REPLIED_SUCCESS",
  "ACCOUNTS_REPLIED_FAILURE",

  "ACCOUNTS_RETWEETED_REQUEST",
  "ACCOUNTS_RETWEETED_SUCCESS",
  "ACCOUNTS_RETWEETED_FAILURE",

  "ACCOUNTS_QUOTED_REQUEST",
  "ACCOUNTS_QUOTED_SUCCESS",
  "ACCOUNTS_QUOTED_FAILURE",

  "TAG_REQUEST",
  "TAG_CREATE",
  "TAG_DELETE",
  "TAG_SUCCESS",
  "TAG_FAILURE",

  "TAG_SENTIMENT_REQUEST",
  "TAG_SENTIMENT_SUCCESS",
  "TAG_SENTIMENT_FAILURE",

  "IMPRESSIONS_REQUEST",
  "IMPRESSIONS_SUCCESS",
  "IMPRESSIONS_FAILURE",
]);

export const tweetActions = {
  request: (data) =>
    createAction(TWEET_ACTIONS.REQUEST, {
      ...data,
      fetching: true,
      success: false,
      error: null,
    }),
  multipleRequest: (data) =>
    createAction(TWEET_ACTIONS.MULTIPLE_REQUEST, {
      ...data,
      fetching: true,
      success: false,
      error: null,
    }),
  success: ({ data, pageNum }) =>
    createAction(TWEET_ACTIONS.SUCCESS, {
      data,
      pageNum,
      fetching: false,
      success: true,
      error: null,
    }),
  failure: (error) =>
    createAction(TWEET_ACTIONS.FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  oneRequest: (id) =>
    createAction(TWEET_ACTIONS.ONE_REQUEST, {
      id,
      fetching: true,
      success: false,
      error: null,
    }),
  oneSuccess: (data) =>
    createAction(TWEET_ACTIONS.ONE_SUCCESS, {
      ...data,
      fetching: false,
      success: true,
      error: null,
    }),
  oneFailure: (error) =>
    createAction(TWEET_ACTIONS.ONE_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  clear: () =>
    createAction(TWEET_ACTIONS.CLEAR, {
      data: [],
      fetching: true,
      success: false,
      error: null,
    }),

  clearTweets: () =>
    createAction(TWEET_ACTIONS.CLEAR_TWEETS, {
      data: [],
      fetching: true,
      success: false,
      error: null,
    }),

  tweetRepliesRequest: (id) =>
    createAction(TWEET_ACTIONS.TWEET_REPLIES_REQUEST, {
      id,
      fetching: true,
      success: false,
      error: null,
    }),
  tweetRepliesSuccess: ({ data }) =>
    createAction(TWEET_ACTIONS.TWEET_REPLIES_SUCCESS, {
      data: data,
      fetching: false,
      success: true,
      error: null,
    }),
  tweetRepliesFailure: (error) =>
    createAction(TWEET_ACTIONS.TWEET_REPLIES_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  replyUrlsRequest: (ids) =>
    createAction(TWEET_ACTIONS.REPLY_URLS_REQUEST, {
      ids,
      fetching: true,
      success: false,
      error: null,
    }),
  replyUrlsSuccess: ({ data }) =>
    createAction(TWEET_ACTIONS.REPLY_URLS_SUCCESS, {
      data,
      fetching: false,
      success: true,
      error: null,
    }),
  replyUrlsFailure: (error) =>
    createAction(TWEET_ACTIONS.REPLY_URLS_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  tweetsCountRequest: (data) =>
    createAction(TWEET_ACTIONS.TWEETS_COUNT_REQUEST, {
      ...data,
      fetching: true,
      success: false,
      error: null,
    }),
  tweetsCountSuccess: ({ data }) =>
    createAction(TWEET_ACTIONS.TWEETS_COUNT_SUCCESS, {
      data: data,
      fetching: false,
      success: true,
      error: null,
    }),
  tweetsCountFailure: (error) =>
    createAction(TWEET_ACTIONS.TWEETS_COUNT_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  accountsRepliedRequest: (data) =>
    createAction(TWEET_ACTIONS.ACCOUNTS_REPLIED_REQUEST, {
      ...data,
      fetching: true,
      success: false,
      error: null,
    }),
  accountsRepliedSuccess: ({ data }) =>
    createAction(TWEET_ACTIONS.ACCOUNTS_REPLIED_SUCCESS, {
      data: data,
      fetching: false,
      success: true,
      error: null,
    }),
  accountsRepliedFailure: (error) =>
    createAction(TWEET_ACTIONS.ACCOUNTS_REPLIED_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  accountsRetweetedRequest: (data) =>
    createAction(TWEET_ACTIONS.ACCOUNTS_RETWEETED_REQUEST, {
      ...data,
      fetching: true,
      success: false,
      error: null,
    }),
  accountsRetweetedSuccess: ({ data }) =>
    createAction(TWEET_ACTIONS.ACCOUNTS_RETWEETED_SUCCESS, {
      data: data,
      fetching: false,
      success: true,
      error: null,
    }),
  accountsRetweetedFailure: (error) =>
    createAction(TWEET_ACTIONS.ACCOUNTS_RETWEETED_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  accountsQuotedRequest: (data) =>
    createAction(TWEET_ACTIONS.ACCOUNTS_QUOTED_REQUEST, {
      ...data,
      fetching: true,
      success: false,
      error: null,
    }),
  accountsQuotedSuccess: ({ data }) =>
    createAction(TWEET_ACTIONS.ACCOUNTS_QUOTED_SUCCESS, {
      data: data,
      fetching: false,
      success: true,
      error: null,
    }),
  accountsQuotedFailure: (error) =>
    createAction(TWEET_ACTIONS.ACCOUNTS_QUOTED_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  tagRequest: (id) =>
    createAction(TWEET_ACTIONS.TAG_REQUEST, {
      id,
      fetching: true,
      success: false,
      error: null,
    }),
  tagCreate: (data) =>
    createAction(TWEET_ACTIONS.TAG_CREATE, {
      data,
      fetching: true,
      success: false,
      error: null,
    }),
  tagDelete: (data) =>
    createAction(TWEET_ACTIONS.TAG_DELETE, {
      data,
      fetching: true,
      success: false,
      error: null,
    }),
  tagSuccess: (data) =>
    createAction(TWEET_ACTIONS.TAG_SUCCESS, {
      ...data,
      fetching: false,
      success: true,
      error: null,
    }),
  tagFailure: (error) =>
    createAction(TWEET_ACTIONS.TAG_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  tagSentimentRequest: (id) =>
    createAction(TWEET_ACTIONS.TAG_SENTIMENT_REQUEST, {
      id,
      fetching: true,
      success: false,
      error: null,
    }),
  tagSentimentSuccess: (data) =>
    createAction(TWEET_ACTIONS.TAG_SENTIMENT_SUCCESS, {
      ...data,
      fetching: false,
      success: true,
      error: null,
    }),
  tagSentimentFailure: (error) =>
    createAction(TWEET_ACTIONS.TAG_SENTIMENT_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  impressionsRequest: (data) =>
    createAction(TWEET_ACTIONS.IMPRESSIONS_REQUEST, {
      ...data,
      fetching: true,
      success: false,
      error: null,
    }),
  impressionsSuccess: (data) =>
    createAction(TWEET_ACTIONS.IMPRESSIONS_SUCCESS, {
      ...data,
      fetching: false,
      success: true,
      error: null,
    }),
  impressionsFailure: (error) =>
    createAction(TWEET_ACTIONS.IMPRESSIONS_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  // save: data => createAction(TWEET_ACTIONS.SAVE, {
  //   ...data, fetching: true, success: false, error: null,
  // }),
  // put: (id, data) => createAction(TWEET_ACTIONS.PUT, {
  //   id, ...data, fetching: true, success: false, error: null,
  // }),
  // patch: (id, data) => createAction(TWEET_ACTIONS.PATCH, {
  //   id, ...data, fetching: true, success: false, error: null,
  // }),
  // delete: id => createAction(TWEET_ACTIONS.DELETE, {
  //   id, fetching: true, success: false, error: null,
  // }),
};
