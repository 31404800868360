import React from "react";
import { useSelector } from "react-redux";

export const Dictionary = ({ word, prepend }) => {
  const user = useSelector((state) => state.auth.data);
  let userType = "MEDICAL";
  if (user?.active) {
    userType =
      user?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
        ?.preferenceStringValue || "MEDICAL";
  }

  const replaceWord = (text) => {
    switch (text) {
      case "product":
        return "brand";
        break;
      case "products":
        return "brands";
        break;
      case "Product":
        return "Brand";
        break;
      case "Products":
        return "Brands";
        break;
    }
    return text;
  };

  return (
    <span>
      {prepend}
      {userType === "MARKETING" || userType === "SALES"
        ? replaceWord(word)
        : word}
    </span>
  );
};
