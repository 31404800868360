import { createAction, createActionTypes } from "../../utils";

export const TAG_ACTIONS = createActionTypes("TAG_ACTIONS", [
  "REQUEST",
  "SUCCESS",
  "FAILURE",

  "ONE_REQUEST",
  "ONE_SUCCESS",
  "ONE_FAILURE",

  "CREATE",
  "UPDATE",
  "DELETE",
  "CLEAR",

  "CLEAR_TAGS",

  "WHITELIST_REQUEST",
  "WHITELIST_SUCCESS",
  "WHITELIST_FAILURE",
  "WHITELIST_UPDATE",

  "TAG_ASSOCIATIONS_REQUEST",
  "TAG_ASSOCIATIONS_SUCCESS",
  "TAG_ASSOCIATIONS_FAILURE",

  "TAG_ASSOCIATION_CREATE",
  "TAG_ASSOCIATION_DELETE",
  "TAG_ASSOCIATION_SUCCESS",
  "TAG_ASSOCIATION_FAILURE",

  "TAG_MONITOR_RULES_REQUEST",
  "TAG_MONITOR_RULES_SUCCESS",
  "TAG_MONITOR_RULES_FAILURE",

  "TAG_MONITOR_RULE_CREATE",
  "TAG_MONITOR_RULE_DELETE",
  "TAG_MONITOR_RULE_SUCCESS",
  "TAG_MONITOR_RULE_FAILURE",

  "ADMIN_SUMMARY_REQUEST",
  "ADMIN_SUMMARY_SUCCESS",
  "ADMIN_SUMMARY_FAILURE",

  "TAG_OWNER_REQUEST",
  "TAG_OWNER_CREATE",
  "TAG_OWNER_UPDATE",
  "TAG_OWNER_DELETE",
  "TAG_OWNER_SUCCESS",
  "TAG_OWNER_FAILURE",
]);

export const tagActions = {
  request: (data) =>
    createAction(TAG_ACTIONS.REQUEST, {
      ...data,
      fetching: true,
      success: false,
      error: null,
    }),
  success: ({ data, pageNum }) =>
    createAction(TAG_ACTIONS.SUCCESS, {
      data,
      pageNum,
      fetching: false,
      success: true,
      error: null,
    }),
  failure: (error) =>
    createAction(TAG_ACTIONS.FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  ////////////////////////////////////////////////////
  oneRequest: (id) =>
    createAction(TAG_ACTIONS.ONE_REQUEST, {
      id,
      fetching: true,
      success: false,
      error: null,
    }),
  oneSuccess: (data) =>
    createAction(TAG_ACTIONS.ONE_SUCCESS, {
      ...data,
      fetching: false,
      success: true,
      error: null,
    }),
  oneFailure: (error) =>
    createAction(TAG_ACTIONS.ONE_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  ////////////////////////////////////////////////////
  create: (data, redirect = true) =>
    createAction(TAG_ACTIONS.CREATE, {
      data,
      redirect,
      fetching: true,
      success: false,
      error: null,
    }),
  update: (data) =>
    createAction(TAG_ACTIONS.UPDATE, {
      data,
      fetching: true,
      success: false,
      error: null,
    }),
  delete: (data) =>
    createAction(TAG_ACTIONS.DELETE, {
      data,
      fetching: true,
      success: false,
      error: null,
    }),
  clear: () =>
    createAction(TAG_ACTIONS.CLEAR, {
      data: [],
      fetching: true,
      success: false,
      error: null,
    }),

  ////////////////////////////////////////////////////
  clearTags: () =>
    createAction(TAG_ACTIONS.CLEAR_TAGS, {
      data: [],
      fetching: true,
      success: false,
      error: null,
    }),

  ////////////////////////////////////////////////////
  whitelistRequest: (data) =>
    createAction(TAG_ACTIONS.WHITELIST_REQUEST, {
      ...data,
      fetching: true,
      success: false,
      error: null,
    }),
  whitelistSuccess: (data) =>
    createAction(TAG_ACTIONS.WHITELIST_SUCCESS, {
      ...data,
      fetching: false,
      success: true,
      error: null,
    }),
  whitelistFailure: (error) =>
    createAction(TAG_ACTIONS.WHITELIST_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),
  whitelistUpdate: (data) =>
    createAction(TAG_ACTIONS.WHITELIST_UPDATE, {
      data,
      fetching: true,
      success: false,
      error: null,
    }),

  ////////////////////////////////////////////////////
  tagAssociationsRequest: (data) =>
    createAction(TAG_ACTIONS.TAG_ASSOCIATIONS_REQUEST, {
      ...data,
      fetching: true,
      success: false,
      error: null,
    }),
  tagAssociationsSuccess: (data) =>
    createAction(TAG_ACTIONS.TAG_ASSOCIATIONS_SUCCESS, {
      ...data,
      fetching: false,
      success: true,
      error: null,
    }),
  tagAssociationsFailure: (error) =>
    createAction(TAG_ACTIONS.TAG_ASSOCIATIONS_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),
  tagAssociationCreate: (data) =>
    createAction(TAG_ACTIONS.TAG_ASSOCIATION_CREATE, {
      data,
      fetching: true,
      success: false,
      error: null,
    }),
  tagAssociationDelete: (data) =>
    createAction(TAG_ACTIONS.TAG_ASSOCIATION_DELETE, {
      data,
      fetching: true,
      success: false,
      error: null,
    }),
  tagAssociationSuccess: (data) =>
    createAction(TAG_ACTIONS.TAG_ASSOCIATION_SUCCESS, {
      ...data,
      fetching: false,
      success: true,
      error: null,
    }),
  tagAssociationFailure: (error) =>
    createAction(TAG_ACTIONS.TAG_ASSOCIATION_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  ////////////////////////////////////////////////////
  tagMonitorRulesRequest: (data) =>
    createAction(TAG_ACTIONS.TAG_MONITOR_RULES_REQUEST, {
      ...data,
      fetching: true,
      success: false,
      error: null,
    }),
  tagMonitorRulesSuccess: (data) =>
    createAction(TAG_ACTIONS.TAG_MONITOR_RULES_SUCCESS, {
      ...data,
      fetching: false,
      success: true,
      error: null,
    }),
  tagMonitorRulesFailure: (error) =>
    createAction(TAG_ACTIONS.TAG_MONITOR_RULES_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),
  tagMonitorRuleCreate: (data) =>
    createAction(TAG_ACTIONS.TAG_MONITOR_RULE_CREATE, {
      data,
      fetching: true,
      success: false,
      error: null,
    }),
  tagMonitorRuleDelete: (data) =>
    createAction(TAG_ACTIONS.TAG_MONITOR_RULE_DELETE, {
      data,
      fetching: true,
      success: false,
      error: null,
    }),
  tagMonitorRuleSuccess: (data) =>
    createAction(TAG_ACTIONS.TAG_MONITOR_RULE_SUCCESS, {
      ...data,
      fetching: false,
      success: true,
      error: null,
    }),
  tagMonitorRuleFailure: (error) =>
    createAction(TAG_ACTIONS.TAG_MONITOR_RULE_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  ////////////////////////////////////////////////////
  adminSummaryRequest: (data) =>
    createAction(TAG_ACTIONS.ADMIN_SUMMARY_REQUEST, {
      ...data,
      fetching: true,
      success: false,
      error: null,
    }),
  adminSummarySuccess: (data) =>
    createAction(TAG_ACTIONS.ADMIN_SUMMARY_SUCCESS, {
      data,
      fetching: false,
      success: true,
      error: null,
    }),
  adminSummaryFailure: (error) =>
    createAction(TAG_ACTIONS.ADMIN_SUMMARY_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  ////////////////////////////////////////////////////
  ownerRequest: (id) =>
    createAction(TAG_ACTIONS.TAG_OWNER_REQUEST, {
      id,
      fetching: true,
      success: false,
      error: null,
    }),
  ownerCreate: (data) =>
    createAction(TAG_ACTIONS.TAG_OWNER_CREATE, {
      data,
      fetching: true,
      success: false,
      error: null,
    }),
  ownerUpdate: (data) =>
    createAction(TAG_ACTIONS.TAG_OWNER_UPDATE, {
      data,
      fetching: true,
      success: false,
      error: null,
    }),
  ownerDelete: (data) =>
    createAction(TAG_ACTIONS.TAG_OWNER_DELETE, {
      data,
      fetching: true,
      success: false,
      error: null,
    }),
  ownerSuccess: (data) =>
    createAction(TAG_ACTIONS.TAG_OWNER_SUCCESS, {
      ...data,
      fetching: false,
      success: true,
      error: null,
    }),
  ownerFailure: (error) =>
    createAction(TAG_ACTIONS.TAG_OWNER_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),
};
