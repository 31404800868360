import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

import {
  Column,
  Row,
  H2,
  H4,
  P,
  UL,
  LI,
  Anchor,
  MainWrapper,
  Content,
  Metadata,
} from "../../components";

export const Privacy = () => {
  return (
    <MainWrapper noHeader>
      <Metadata />
      <Content>
        <Column center>
          <H4 bold color="green">
            <Link to="/">Medical.watch</Link>
          </H4>
          <Column>
            <Row marginY>
              <H2 bold>Privacy Policy</H2>
            </Row>
            <Row marginBottom="10">
              <P>Last updated: May 10, 2024</P>
            </Row>
            <P>
              Medical.watch respects your right to privacy. This privacy policy
              ("Privacy Policy") explains who we are, how we collect, share and
              use information about you and how you can exercise your privacy
              rights.
            </P>
            <P>
              Our Privacy Policy applies to all users of Medical.watch websites
              and services, including but not limited to www.medical.watch
              (depending on your use, the "Site" and/or the "Service"). For
              further information on the terms which govern your use of the
              respective Service, please see https://medical.watch/terms{" "}
              <Anchor
                inline
                bold
                line
                label="https://medical.watch/terms"
                blank
                to="https://medical.watch/terms"
              />{" "}
              or any service order you may have signed with us.
            </P>
            <Row marginY="10">
              <H4 bold>1. What does Medical.watch do?</H4>
            </Row>
            <P>
              Medical.watch is a provider of software for social analytics and
              listening headquartered in the U.S. For more information about
              Medical.watch, please see our Site at{" "}
              <Anchor
                inline
                bold
                line
                label="https://medical.watch"
                blank
                to="https://medical.watch"
              />
              .
            </P>
            <P>
              If you have any questions or concerns about our use of your
              information, then please contact us using the contact details
              provided in the "How to Contact Us" section of this Privacy
              Policy.
            </P>
            <Row marginY="10">
              <H4 bold>2. Information Collected</H4>
            </Row>
            <P>
              We collect information about visitors to our Site, our customers
              and their users of the Service, job applicants, and users of our
              customers’ social media pages or properties.
            </P>
            <P> Such information is collected from the following sources: </P>
            <P>
              Information We Collect Directly from You: The type of information
              that we collect directly from you varies based on your interaction
              with our Site and our Service. For example, we collect information
              directly from you when you register an account with us, liaise
              with us on a customer service issue, apply for a job, and complete
              an application or other forms on the Site and/or Service. We also
              collect information that you send us via any medium, including,
              but not limited to email address and phone number. In addition, we
              collect information about you from your social media interactions
              on third party social media properties, such as your social media
              handle, username, profile, postings, and messages you exchange
              with our customers.
            </P>
            <P>
              Information We Receive from Customers: We receive information from
              customers in order to provide our Service. Such information may
              include a user’s first name, last name and email address. We
              collect information from individuals employed by our customers
              such as their contact information in order to provide our Service.
            </P>
            <Row marginY="10">
              <H4 bold>3. Information Collected Automatically</H4>
            </Row>
            <P>
              When you visit our Site or use our Service, we may collect certain
              information automatically from your device. This information may
              be considered personal information under applicable data
              protection laws.
            </P>
            <P>
              Specifically, the information we collect automatically may include
              information like your IP address, device type, unique device
              identification numbers, browser-type, broad geographic location
              (e.g. country or city-level location), third party webpages
              accessed via the Service and other technical information. We may
              also collect information about how your device has interacted with
              our Site (including the pages accessed and links clicked) or
              Service (including content accessed).
            </P>
            <P>
              Collecting this information enables us to better understand the
              visitors who come to our Site or use our Service, where they come
              from, and what content and functionality is of interest to them.
              We use this information for our internal analytics purposes and to
              improve the quality and relevance of our Site and/or Service to
              our visitors and users.
            </P>
            <P>
              Some of this information may be collected using cookies and
              similar tracking technology, as explained further under the
              heading "Cookies, similar tracking technology, and analytics"
              below and in our Cookie Notice.
            </P>
            <Row marginY="10">
              <H4 bold>4. Information from Other Sources</H4>
            </Row>
            <P>
              We also collect information from other sources. The following are
              the categories of sources we collect information from:
            </P>
            <UL>
              <LI>
                Data brokers or resellers from which we purchase data to verify
                and supplement the data we collect.
              </LI>
              <LI>
                Social networks when you engage with our content, reference our
                Site or Service, or grant us permission to access information
                from the social networks.
              </LI>
              <LI>
                Customers that provide us with information which we process as a
                service provider.
              </LI>
            </UL>
            <Row marginY="10">
              <H4 bold>5. Do-Not-Track</H4>
            </Row>
            <P>
              Your browser settings may allow you to automatically transmit a
              "Do Not Track" signal to online services you visit. Note, however,
              there is no industry consensus as to what site and app operators
              should do with regard to these signals. Accordingly, unless and
              until the law is interpreted to require us to do so, our systems
              do not recognize browser "do-not-track" requests. You may,
              however, disable certain tracking as discussed in this Privacy
              Policy (e.g., by disabling cookies, or using private browsing
              modes).
            </P>
            <Row marginY="10">
              <H4 bold>6. Use of Information</H4>
            </Row>
            <P>
              Medical.watch processes information for business and commercial
              purposes in accordance with the practices described in this
              Privacy Policy. Our business purposes for collecting and using
              information, including in the last 12 months, include the
              following:
            </P>
            <UL>
              <LI>Operate and improve the Site and Service;</LI>
              <LI>
                Provide users or customers with the Service and other products
                and services that a user or customer may request or that a user
                or customer has expressed interest in;
              </LI>
              <LI>Facilitate subscription processing, reviews and analysis;</LI>
              <LI>
                Evaluate user interest and needs in order to improve the Service
                and make available other offers, products or services;
              </LI>
              <LI>
                Record calls with customers for quality, training and Service
                improvement purposes (upon receipt of consent where required by
                applicable law);
              </LI>
              <LI>
                Evaluate the types of offers, products or services we make
                available to users or customers and potential users or
                customers; Monitor use of the Service, including for
                troubleshooting and product improvement purposes;
              </LI>
              <LI>Provide customer support</LI>
              <LI>
                Communicate and provide additional information that may be of
                interest to users through email or other means, such as special
                offers, announcements, and marketing materials;
              </LI>
              <LI>
                Conduct online research surveys regarding the Site and/or
                Service;
              </LI>
              <LI>
                Combine datasets to create aggregated data for internal
                evaluation and analysis;
              </LI>
              <LI>
                Share anonymized or personalized tokens across the Medical.watch
                and Employee Advocacy platforms in order to provide the Service;
              </LI>
              <LI>
                Send you reminders, technical notices, updates, product
                announcements, security alerts and support and administrative
                messages, service bulletins, or marketing;
              </LI>
              <LI>
                As necessary to consider your job application for open
                positions, process your employment and education history,
                transcript, writing samples, and references;
              </LI>
              <LI>
                Manage our everyday business needs such as Site and/or Service
                administration, forum management, fulfillment, analytics, fraud
                prevention, enforcement of our corporate reporting obligations,
                legal terms and any other contractual agreement relating to our
                Service or to comply with the law; and
              </LI>
              <LI>
                Fulfill any other business or commercial purposes at your
                direction or with your notice and/or consent.
              </LI>
            </UL>
            <P>
              Notwithstanding the above, we may use information that does not
              identify you (including information that has been aggregated or
              de-identified) for any purpose except as prohibited by applicable
              law. For information on your rights and choices regarding how we
              use information about you, please see "Your Data Protection
              Rights" below.
            </P>
            <Row marginY="10">
              <H4 bold>
                7. Categories of Third Parties With Whom We May Share Your
                Information
              </H4>
            </Row>
            <P>
              We share information we collect in accordance with the practices
              described in this Privacy Policy. The following are the categories
              of recipients:
            </P>
            <UL>
              <LI>
                to our customers (including information pertaining to customer
                end-users as well as internal personnel) in order to effectively
                provide our social media management, social advocacy, social
                analytics, and social listening services and to manage the
                customer relationship. For example, we share information with
                our customers in order to facilitate the processing of their
                orders, maintain and administer their online accounts, respond
                to their questions and comments, comply with their requests,
                market and advertise to them and otherwise comply with our
                contractual obligations and with applicable law;
              </LI>
              <LI>
                to any competent law enforcement body, regulatory, government
                agency, court or other third party where we believe disclosure
                is necessary (i) as a matter of applicable law or court order or
                regulation, (ii) to exercise, establish or defend our legal
                rights, or (iii) to protect your vital interests or those of any
                other person;
              </LI>
              <LI>
                to a potential buyer (and its agents, investors, and/or
                advisors) in connection with any proposed or actual purchase,
                merger or acquisition of any part of our business, provided that
                we inform the buyer it must use your information only for the
                purposes disclosed in this Privacy Policy;
              </LI>
            </UL>
            <P>
              Please note the Service uses YouTube API Services as a service
              provider, and if you use these services through the Service, you
              are subject to the Google Privacy Policy, located at{" "}
              <Anchor
                inline
                bold
                line
                label="https://policies.google.com/privacy"
                blank
                to="https://policies.google.com/privacy"
              />
              .
            </P>
            <P>
              Notwithstanding the above, we may share information that does not
              identify you (including information that has been aggregated or
              de-identified) except as prohibited by applicable law. For
              information on your rights and choices regarding how we share
              information about you, please see the "Your Data Protection
              Rights" section below.
            </P>
            <P>
              In certain situations, we may be required to disclose personal
              data in response to lawful requests by public authorities,
              including to meet national security or law enforcement
              requirements.
            </P>
            <Row marginY="10">
              <H4 bold>8. Data Retention</H4>
            </Row>
            <P>
              We retain information we collect from you where we have an ongoing
              legitimate business need to do so (for example, to provide you
              with a service you have requested or to comply with applicable
              legal, tax or accounting requirements).
            </P>
            <P>
              When we have no ongoing legitimate business need to process your
              information, we will either delete or anonymize it in accordance
              with our data retention policy, or, in the limited circumstances
              where this is not possible (for example, because your information
              has been stored in backup archives), then we will securely store
              your information and isolate it from any further processing until
              deletion is possible.
            </P>
            <Row marginY="10">
              <H4 bold>9. Sensitive personal information</H4>
            </Row>
            <P>
              The terms that govern your use of the Service prohibit you from
              providing Medical.watch with sensitive personal information as
              that term is defined under applicable law.
            </P>
            <Row marginY="10">
              <H4 bold>
                10. Legal basis for processing personal data (EEA or UK visitors
                / users only)
              </H4>
            </Row>
            <P>
              If you are a visitor or user from the European Economic Area or
              the UK, our legal basis for collecting and using the personal data
              described above will depend on the personal data concerned and the
              specific context in which we collect it.
            </P>
            <P>
              However, we will normally collect personal data from you only
              where we have your consent to do so, where we need the personal
              data to perform a contract with you, or where the processing is in
              our legitimate interests and not overridden by your data
              protection interests or fundamental rights and freedoms. In some
              cases, we may also have a legal obligation to collect personal
              data from you.
            </P>
            <P>
              If we ask you to provide personal data to comply with a legal
              requirement or to perform a contract with you, we will make this
              clear at the relevant time and advise you whether the provision of
              your personal data is mandatory or not (as well as of the possible
              consequences if you do not provide your personal data).
            </P>
            <P>
              Similarly, if we collect and use your personal data in reliance of
              our legitimate interests (or those of any third party), we will
              make clear to you at the relevant time what those legitimate
              interests are.
            </P>
            <P>
              If you have questions about or need further information concerning
              the legal basis on which we collect and use your personal data,
              please contact us using the contact details provided under the
              "How to Contact Us" heading below.
            </P>
            <Row marginY="10">
              <H4 bold>
                11. Cookies, similar tracking technology, and analytics
              </H4>
            </Row>
            <P>
              We use cookies and similar tracking technology (collectively,
              "Cookies") to collect and use information about you to improve our
              Service. For further information about the types of Cookies we
              use, why, and how you can control Cookies, please see our Cookie
              Policy.
            </P>
            <P>
              We also use analytics services, such as Google Analytics, to help
              us understand how users access and use the Service. Google
              provides tools to allow you to opt out of the use of certain
              information collected by Google Analytics at{" "}
              <Anchor
                inline
                bold
                line
                label="https://tools.google.com/dlpage/gaoptout"
                blank
                to="https://tools.google.com/dlpage/gaoptout"
              />{" "}
              and by Google Analytics for Display Advertising or the Google
              Display Network at{" "}
              <Anchor
                inline
                bold
                line
                label="https://www.google.com/settings/..."
                blank
                to="https://www.google.com/settings/ads/onweb/"
              />
              .
            </P>
            <Row marginY="10">
              <H4 bold>12. Social Media and Technology Integrations</H4>
            </Row>
            <P>
              We offer parts of our Service through websites, platforms, and
              services operated or controlled by separate entities. In addition,
              we integrate technologies operated or controlled by separate
              entities into parts of our Site and Service. Some examples
              include:
            </P>
            <UL>
              <LI>
                Links. Our Site and Service includes links that hyperlink to
                websites, platforms, and other services not operated or
                controlled by us.
              </LI>
              <LI>
                Brand Pages and Chatbots. We may offer our content through
                social media. Any information you provide to us when you engage
                with our content (such as through our brand page or via our
                chatbot on the Site or Service) is treated in accordance with
                this Privacy Policy. Also, if you publicly reference our Service
                on social media (e.g., by using a hashtag associated with
                Medical.watch in a tweet or post), we may use your reference on
                or in connection with our Site or Service.
              </LI>
              <LI>
                Please note that when you interact with other entities,
                including when you leave our Site or Service, those entities may
                independently collect information about you and solicit
                information from you. The information collected and stored by
                those entities remains subject to their own policies, terms, and
                practices, including what information they share with us, your
                rights and choices on their services and devices, and whether
                they store information in the U.S. or elsewhere. We encourage
                you to familiarize yourself with and consult their privacy
                policies and terms of use.
              </LI>
            </UL>
            <Row marginY="10">
              <H4 bold>13. Your Data Protection Rights</H4>
            </Row>
            <P>You have the following data protection rights:</P>
            <UL>
              <LI>
                If you wish to access, correct, update or request deletion of
                your information, you can do so at any time by contacting us
                using the contact details provided under the "How to Contact Us"
                heading below. Note that if you submit a request to delete your
                information, this may prohibit you from using the Site and/or
                Service. If you have authorized us to access your data via the
                YouTube API Services, then in addition to our normal procedure
                for deleting stored data, you may revoke our access to your data
                via the Google security settings page, located at{" "}
                <Anchor
                  inline
                  bold
                  line
                  label="https://security.google.com/..."
                  blank
                  to="https://security.google.com/settings/security/permissions"
                />
                .
              </LI>
              <LI>
                In addition, if you are a resident of the European Economic Area
                or the United Kingdom, you can object to processing of your
                personal data, ask us to restrict processing of your personal
                data, or request portability of your personal data. Again, you
                can exercise these rights by contacting us using the contact
                details provided under the "How to Contact Us" heading below.
                Note that if you submit such a request, this may prohibit you
                from using the Site and/or Service.
              </LI>
              <LI>
                You have the right to opt-out of marketing communications we
                send you at any time. You can exercise this right by clicking on
                the "Manage Preferences" link in the marketing emails we send
                you. To opt-out of other forms of marketing (such as postal
                marketing or telemarketing), then please contact us using the
                contact details provided under the "How to Contact Us" heading
                below. Please note that your opt out is limited to the email
                address, device, and phone number used and will not affect
                subsequent subscriptions.
              </LI>
              <LI>
                Similarly, if we have collected and processed your information
                with your consent, then you can withdraw your consent at any
                time. Withdrawing your consent will not affect the lawfulness of
                any processing we conducted prior to your withdrawal, nor will
                it affect processing of your information conducted in reliance
                on lawful processing grounds other than consent.
              </LI>
              <LI>
                You have the right to complain to a data protection authority
                about our collection and use of your information. For more
                information, please contact your local data protection
                authority.
              </LI>
            </UL>
            <P>
              We respond to all requests we receive from individuals wishing to
              exercise their data protection rights in accordance with
              applicable data protection laws. Where we process your information
              solely on behalf of a customer, we may be legally required to
              forward your request directly to our customer and/or social media
              business partners for their review / handling. Residents of
              certain states may have additional rights as set out in Section 23
              ("Additional Disclosures for California, Colorado, and Virginia
              Residents") below.
            </P>
            <Row marginY="10">
              <H4 bold>14. Security and Email Username/Password</H4>
            </Row>
            <P>
              Our Site and/or Service implements and maintains various
              reasonable and appropriate administrative, physical, and technical
              security safeguards to help protect information about you from
              loss, theft, misuse and unauthorized access, disclosure,
              alteration and destruction. These security safeguards include, but
              are not limited to, network and host security controls (e.g.,
              firewalls, intrusion detection systems, etc.), data encryption
              (both at rest and during transmission), and operating procedures
              that are designed to protect your information. You should protect
              your email username and password and NOT share it with anyone. If
              you believe your email username and password have been
              compromised, please contact your email service provider.
              Nevertheless, transmission via the internet is not completely
              secure and we cannot guarantee the security of information about
              you.
            </P>
            <Row marginY="10">
              <H4 bold>15. CAN-SPAM Compliance Notice (U.S. users only)</H4>
            </Row>
            <P>
              We may send periodic promotional or informational emails to you.
              You may opt-out of promotional communications by following the
              unsubscribe or opt-out instructions contained in the email. Please
              note that it may take up to 10 business days for us to process
              opt-out requests. If you opt-out of receiving promotional emails
              about recommendations or other information we think may interest
              you, we may still send you emails about your account or any
              Service you have requested or received from us.
            </P>
            <Row marginY="10">
              <H4 bold>16. International Transfers</H4>
            </Row>
            <P>
              Your information may be transferred to, and processed in,
              countries other than the country in which you are resident. These
              countries may have data protection laws that are different to the
              laws of your country.
            </P>
            <P>
              Specifically, our servers are located in the United States and
              EEA, and our group companies and third party service providers and
              partners operate around the world. This means that when we collect
              your information we may process it in any of these countries.
            </P>
            <P>
              However, we have taken appropriate safeguards to ensure that your
              information will remain protected in accordance with this Privacy
              Policy. These include our use of European Commission-approved
              Standard Contractual Clauses or using or disclosing personal
              information transferred from the European Union and our continued
              commitment to honoring principles set forth in the EU – U.S.
              Privacy Shield Framework, additional details of which are set out
              below.
            </P>
            <Row marginY="10">
              <H4 bold>17. Transfers Outside of the EEA and Switzerland </H4>
            </Row>
            <P>
              Medical.watch adheres to the principles of the EU-U.S. Privacy
              Shield Framework and the Swiss-U.S. Privacy Shield Framework
              ("Privacy Shield Principles") as set forth by the U.S. Department
              of Commerce regarding the collection, use, and retention of
              personal data from the European Economic Area and Switzerland
              transferred to the United States.
            </P>
            <P>
              While Medical.watch does not rely on the Privacy Shield Principles
              as the legal basis for the transfer of personal information from
              the EEA, United Kingdom, or Switzerland, Medical.watch is
              committed to protecting personal data from the EEA, United
              Kingdom, and Switzerland in compliance with those principles. If
              there is any conflict between the policies in this Privacy Policy
              and data subject rights under the Privacy Shield Principles, the
              Privacy Shield Principles shall govern. To learn more about the
              Privacy Shield program, please visit{" "}
              <Anchor
                inline
                bold
                line
                label="https://www.privacyshield.gov"
                blank
                to="https://www.privacyshield.gov"
              />
              .
            </P>
            <P>
              With respect to personal data received or transferred pursuant to
              the Privacy Shield Frameworks, Medical.watch is subject to the
              investigatory and enforcement authority of the U.S. Federal Trade
              Commission.
            </P>
            <Row marginY="10">
              <H4 bold>18. Contacting Us, Complaints and Dispute Resolution</H4>
            </Row>
            <P>
              In compliance with the Privacy Shield Principles, Medical.watch,
              Inc. commits to resolve complaints about your privacy and our
              collection or use of your information transferred to the United
              States pursuant to Privacy Shield. European Union and Swiss
              individuals with privacy inquiries or complaints should first
              contact us by email at{" "}
              <Anchor
                inline
                bold
                line
                label="legal@medical.watch"
                blank
                to="mailto:legal@medical.watch"
              />
              . We will work to resolve your issue and will respond within 45
              days of receipt. If, however, you believe that we have not been
              able to assist with your complaint or concern, and you are located
              in the EEA, the United Kingdom, or Switzerland, you have the right
              to lodge a complaint with the competent supervisory authority.
            </P>
            <P>
              Medical.watch has further committed to refer unresolved privacy
              complaints under the Privacy Shield Principles to an independent
              dispute resolution mechanism, the BBB EU Privacy Shield, operated
              by BBB National Programs. If you do not receive timely
              acknowledgment of your complaint, or if your complaint is not
              satisfactorily addressed, please visit{" "}
              <Anchor
                inline
                bold
                line
                label="https://bbbprograms.org/..."
                blank
                to="https://bbbprograms.org/privacy-shield-complaints/"
              />{" "}
              for more information and to file a complaint. This service is
              provided free of charge to you.
            </P>
            <P>
              If your Privacy Shield complaint cannot be resolved through the
              above channels, under certain conditions, you may invoke binding
              arbitration for some residual claims not resolved by other redress
              mechanisms. See Privacy Shield Annex 1 at{" "}
              <Anchor
                inline
                bold
                line
                label="https://www.privacyshield.gov/..."
                blank
                to="https://www.privacyshield.gov/article?id=ANNEX-I-introduction"
              />
              .
            </P>
            <P>
              Medical.watch commits to cooperate with EU data protection
              authorities and comply with advice given by such authorities with
              respect to human resources data transferred from the European
              Union in the context of any employment relationship with a
              European Union individual.
            </P>
            <Row marginY="10">
              <H4 bold>19. Privacy Complaints in Brazil</H4>
            </Row>
            <P>
              In circumstances in which Brazil law is controlling, we commit to
              resolve complaints about your privacy and our collection or use of
              your information. We have further committed to refer unresolved
              privacy complaints to an independent dispute resolution mechanism.
            </P>
            <Row marginY="10">
              <H4 bold>20. Children</H4>
            </Row>
            <P>
              This Service is intended for a general audience and is not
              directed at children under thirteen (13) years of age.
            </P>
            <P>
              Consistent with the federal Children’s Online Privacy Protection
              Act of 1998 (COPPA), we do not knowingly request personal
              information from anyone under the age of 13 without requiring
              parental consent. Any person who provides their personal
              information to us through our Site or Service represents that they
              are at least 13 years of age. If you are a parent or guardian and
              you believe we have collected information from your child in a
              manner not permitted by law, contact us at{" "}
              <Anchor
                inline
                bold
                line
                label="legal@medical.watch"
                blank
                to="mailto:legal@medical.watch"
              />
              . We will remove the data to the extent required by applicable
              laws.
            </P>
            <P>
              We do not knowingly "sell" or "share," as those terms are defined
              under the California Consumer Privacy Act, as amended, the
              personal information of minors under 16 years old who are
              California residents.
            </P>
            <P>
              If you are a California resident under 18 years old and registered
              to use the Service, you can ask us to remove any content or
              information you have posted on the Service. To make a request,
              email us at the email address set out in the "How to Contact Us"
              section with "California Under 18 Content Removal Request" in the
              subject line, and tell us what you want removed. We will make
              reasonable good faith efforts to remove the post from prospective
              public view, although we cannot ensure the complete or
              comprehensive removal of the content and may retain the content as
              necessary to comply with our legal obligations, resolve disputes,
              and enforce our agreements.
            </P>
            <Row marginY="10">
              <H4 bold>21. Your Nevada Privacy Rights</H4>
            </Row>
            <P>
              Nevada law (SB 220), permits customers in Nevada to opt-out of the
              sale of certain kinds of personal information. A sale under Nevada
              law is the transfer of this personal information to third parties
              for monetary consideration. Medical.watch does not sell your
              personal information to third parties as defined in Nevada law. If
              you are a Nevada resident and wish to obtain information about our
              compliance with Nevada law, please contact us as at{" "}
              <Anchor
                inline
                bold
                line
                label="legal@medical.watch"
                blank
                to="mailto:legal@medical.watch"
              />
              .
            </P>
            <Row marginY="10">
              <H4 bold>22. Updates to this Privacy Policy</H4>
            </Row>{" "}
            <P>
              We may update this Privacy Policy from time to time in response to
              changing legal, technical or business developments. When we update
              our Privacy Policy, we will take appropriate measures to inform
              you, consistent with the significance of the changes we make. We
              will obtain your consent to any material Privacy Policy changes if
              and where this is required by applicable data protection laws.
            </P>
            <P>
              You can see when this Privacy Policy was last updated by checking
              the "last updated" date displayed at the top of this Privacy
              Policy.
            </P>
            <Row marginY="10">
              <H4 bold>23. How to Contact Us</H4>
            </Row>
            <P>
              If you have any questions about this Privacy Policy or our privacy
              practices, please contact us at{" "}
              <Anchor
                inline
                bold
                line
                label="legal@medical.watch"
                blank
                to="mailto:legal@medical.watch"
              />
              .
            </P>
            <P>
              If you have a privacy concern or complaint, please contact our
              Data Protection Officer at{" "}
              <Anchor
                inline
                bold
                line
                label="legal@medical.watch"
                blank
                to="mailto:legal@medical.watch"
              />
              . When you contact us, please indicate in which country and/or
              state you reside.
            </P>
            <Row marginY="10">
              <H4 bold></H4>
            </Row>
            <Row marginY="10">
              <H4 bold>
                24. Additional Disclosures for California, Colorado, and
                Virginia Residents
              </H4>
            </Row>
            <P>
              Unless otherwise required by applicable law, the following terms
              shall have the following meaning:
            </P>
            <P>
              "Targeted advertising" or "Cross-context behavioral advertising"
              means displaying advertisements to a consumer where the
              advertisement is selected based on personal data obtained from
              that consumer’s activities over time and across nonaffiliated
              websites or online applications to predict such consumer’s
              preferences or interests.
            </P>
            <P>
              "Sale" means renting, releasing, disclosing, disseminating, making
              available, transferring, or otherwise communicating orally, in
              writing, or by electronic or other means, a consumer’s personal
              information by the business to a third party for monetary or other
              valuable consideration.
            </P>
            <P>
              "Profiling" means any form of automated processing of personal
              information to evaluate certain personal aspects relating to a
              natural person and in particular to analyze or predict aspects
              concerning that natural person’s performance at work, economic
              situation, health, personal preferences, interests, reliability,
              behavior, location, or movements.
            </P>
            <P>
              US data protection laws grant California, Colorado, and Virginia
              residents the following data rights:
            </P>
            <UL>
              <LI>
                Notice of Collection. In the 12 months prior to the last update
                of this Privacy Policy, we have collected the following
                categories of personal information:
              </LI>
              <LI>
                Identifiers, including name, email address, phone number account
                name, IP address, and an ID or number assigned to your account.
              </LI>
              <LI>
                Customer records, billing and shipping address, and credit or
                debit card information.
              </LI>
              <LI>
                Commercial information, including purchases and engagement with
                the Service.
              </LI>
              <LI>
                Internet activity, including your interactions with our Service.
              </LI>
              <LI>
                Employment and education data, including information you provide
                when you apply for a job with us.
              </LI>
              <LI>
                Inferences, including information about your interests,
                preferences and favorites.
              </LI>
              <LI>
                For more information on information we collect, including the
                sources we receive information from, review the Information
                Collected and Information Collected Automatically sections. We
                collect and use these categories of personal information for the
                business purposes described in the Use of Information section,
                including to provide and manage our Service.
              </LI>
            </UL>
            <P>
              Medical.watch does not generally sell information as the term
              "sell" is traditionally understood. However, to the extent "sale"
              or "sharing" under the CCPA is interpreted to include advertising
              technology activities such as those disclosed in the Cookies,
              similar tracking technology, and analytics section as a "sale" or
              "sharing," we will comply with applicable law as to such activity.
            </P>
            <P>
              We may share your personal information with government entities
              when required to do so by law. Please review the To Whom We Share
              Your Information section for more detail about the parties we have
              shared information with.
            </P>
            <P>
              We retain the personal information we collect for as long as it is
              necessary to complete the commercial purposes for which it was
              collected. Once the information is no longer necessary, we will
              delete it or de-identify/anonymize this data so that it no longer
              identifies a particular individual.
            </P>
            <P>
              Right to Know and Delete. Consumers who are California, Colorado,
              or Virginia residents have the right to request that we delete the
              personal information we have collected about them (subject to
              certain exemptions) and the right to know certain information
              about our data practices in the preceding 12 months. Specifically,
              you have the right to request the following from us:
            </P>
            <UL>
              <LI>
                The categories of personal information we have collected about
                you;
              </LI>
              <LI>
                The categories of sources from which the personal information
                was collected;
              </LI>
              <LI>
                The categories of personal information about you we disclosed
                for a business purpose;
              </LI>
              <LI>
                The business or commercial purpose for collecting the personal
                information; and
              </LI>
              <LI>
                The specific pieces of personal information we have collected
                about you.
              </LI>
            </UL>
            <P>
              To exercise any of these rights, please email us at{" "}
              <Anchor
                inline
                bold
                line
                label="legal@medical.watch"
                blank
                to="mailto:legal@medical.watch"
              />
              . In your request, please specify which right you are seeking to
              exercise and the scope of the request. We will confirm receipt of
              your request within 10 days. We may require additional information
              from you to help us verify your identity and process your request.
              The verification steps may vary depending on the sensitivity of
              the personal information and whether you have an account with us.
              If we are unable to verify your identity, we may deny your
              requests to know or delete.
            </P>
            <P>
              We may deny certain requests, or fulfill a request only in part,
              based on our legal rights and obligations. For example, we may
              retain personal information as permitted by law, such as for tax
              or other record keeping purposes, to maintain an active account,
              and to process transactions and facilitate your requests. Except
              as otherwise provided by applicable law, for purposes of these
              requests under this Section, personal information does not include
              information we have collected as a service provider to our
              customers.
            </P>
            <P>
              If personal information about you has been processed by us as a
              service provider on behalf of a customer and you wish to exercise
              any rights you have with such personal information, please inquire
              with our customer directly. If you wish to make your request
              directly to us, please provide the name of our customer on whose
              behalf we processed your personal information. We will refer your
              request to that customer.
            </P>
            <P>
              Right to Correct. Subject to certain exceptions, you have the
              right to request that we correct inaccurate personal information
              that we have collected about you. Once we receive your request and
              confirm your identity, we will review your request, taking into
              account the nature of the personal information and the purposes of
              the processing of the personal information to see if we can
              correct the data. We may also request additional information
              showing that the information you want to correct is inaccurate.
            </P>
            <P>
              Right to Opt-Out. You have the right to opt-out of processing of
              your Personal Information for the purpose of profiling to provide
              you with tailored content. You may submit a request to opt-out by
              clicking Do Not Sell or Share My Personal Information. You may
              also submit a request to opt-out by by emailing us at{" "}
              <Anchor
                inline
                bold
                line
                label="legal@medical.watch"
                blank
                to="mailto:legal@medical.watch"
              />
              .
            </P>
            <P>
              Authorized Agent. You can designate an authorized agent to submit
              requests on your behalf. However, we will require written proof of
              the agent’s permission to do so and verify your identity directly.
            </P>
            <P>
              Right to Non-Discrimination. You have the right not to receive
              discriminatory treatment by us for the exercise of any of your
              rights.
            </P>
            <Row marginY="10">
              <H4 bold>25. California Shine the Light.</H4>
            </Row>
            <P>
              Pursuant to Section 1798.83 of the California Civil Code,
              residents of California can obtain certain information about the
              types of personal information that companies with whom they have
              an established business relationship have shared with third
              parties for direct marketing purposes during the preceding
              calendar year. In particular, the law provides that companies must
              inform consumers about the categories of personal information that
              have been shared with third parties, the names and addresses of
              those third parties, and examples of the types of services or
              products marketed by those third parties. To request a copy of the
              information disclosure provided by Medical.watch pursuant to
              Section 1798.83 of the California Civil Code, please contact us
              via email at{" "}
              <Anchor
                inline
                bold
                line
                label="legal@medical.watch"
                blank
                to="mailto:legal@medical.watch"
              />
              .
            </P>
            <Row marginY="10">
              <H4 bold>26. Virginia and Colorado Residents</H4>
            </Row>
            <P>
              If you make a request to exercise any of the above data access
              rights and we are unable to comply with your request, you may
              request to appeal our decision. To appeal any data privacy request
              decision, please contact us by emailing{" "}
              <Anchor
                inline
                bold
                line
                label="legal@medical.watch"
                blank
                to="mailto:legal@medical.watch"
              />{" "}
              with the subject line "Data Access Request Appeal." If after you
              complete the appeal process with us, you are still not satisfied
              with our response, you may contact your Attorney General to file a
              complaint. Below are the contact information for the appropriate
              entity where you can inquire about filing an appeal:
            </P>
            <P></P>
            <P>
              Virginia residents:
              <br />
              Office of the Attorney General
              <br />
              202 North 9th Street
              <br />
              Richmond, Virginia 23219
              <br />
              Phone: (804) 786-2071
              <br />
              <Anchor
                inline
                bold
                line
                label="https://www.oag.state.va.us"
                blank
                to="https://www.oag.state.va.us"
              />{" "}
            </P>
            <P></P>
            <P></P>
            <P>
              Colorado residents:
              <br />
              Office of the Attorney General
              <br />
              Colorado Department of Law
              <br />
              Ralph L. Carr Judicial Building
              <br />
              1300 Broadway, 10th Floor
              <br />
              Denver, CO 80203
              <br />
              (720) 508-6000 <br />
              <Anchor
                inline
                bold
                line
                label="https://coag.gov"
                blank
                to="https://coag.gov"
              />
              .
            </P>
          </Column>
        </Column>
      </Content>
    </MainWrapper>
  );
};
