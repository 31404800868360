import React from "react";
import moment from "moment";
import Table from "rc-table";
import "../../../components/styles/rc-table.scss";

import {
  Row,
  Column,
  MainWrapper,
  Header,
  Content,
  Label,
  Anchor,
  Toolbar,
  Spinner,
  Filters,
  Dropdown,
  Grid,
  Tabs,
  Icon,
} from "../../../components";
import { Menu } from "../Menu";

export const view = ({
  activeTab,
  setActiveTab,
  tabs,

  plans,
  loading,

  basePlans,
  basePlansLoading,

  userPlans,
  userPlansLoading,
  infiniteScrollRef,
  hasNextPage,

  statusTypes,
  selectedStatus,
  handleStatusChange,

  allPlans,
  selectedPlan,
  handlePlanChange,

  role,
  handleAssignDefaultPlan,
}) => {
  const columns = [
    {
      title: "Name",
      key: "name",
      render: (cell) => (
        <>
          <Label small bold>
            {cell?.name}
          </Label>
          <br />
          <Label fade="0.4" bold color="gray" style={{ fontSize: "10px" }}>
            {cell?.id}
          </Label>
        </>
      ),
    },
    {
      title: "External Id",
      key: "externalId",
      render: (cell) => (
        <>
          <Label small>{cell?.externalId}</Label>
        </>
      ),
    },
    {
      title: "Price ($)",
      key: "price",
      render: (cell) => (
        <>
          <Label small>{cell?.price != -1 ? cell?.price / 100 : "-"}</Label>
        </>
      ),
    },
    {
      title: "External Price Id",
      key: "externalId",
      render: (cell) => (
        <>
          <Label small>{cell?.externalPriceId}</Label>
        </>
      ),
    },
    {
      title: "Period",
      key: "period",
      render: (cell) => (
        <>
          <Label small>{cell?.period}</Label>
        </>
      ),
    },
    {
      title: "Free",
      key: "free",
      render: (cell) => (
        <Row center expand>
          <Icon
            size={cell?.free ? 34 : 20}
            color={cell?.free ? "green" : "red"}
            name={cell?.free ? "check" : "close"}
          />
        </Row>
      ),
    },
    {
      title: "Default",
      key: "default",
      render: (cell) => (
        <Row center expand>
          <Icon
            size={cell?.default ? 34 : 20}
            color={cell?.default ? "green" : "red"}
            name={cell?.default ? "check" : "close"}
          />
        </Row>
      ),
    },
    {
      title: "Active",
      key: "active",
      render: (cell) => (
        <Row center expand>
          <Icon
            size={cell?.active ? 34 : 20}
            color={cell?.active ? "green" : "red"}
            name={cell?.active ? "check" : "close"}
          />
        </Row>
      ),
    },
    // {
    //   title: "Plan Id",
    //   key: "planId",
    //   render: (cell) => (
    //     <>
    //       <Label small>{cell?.id}</Label>
    //     </>
    //   ),
    // },
  ];
  const userPlanColumns = [
    {
      title: "Email",
      key: "email",
      render: (cell) => (
        <>
          <Label small>{cell?.email}</Label>
          <br />
          <Label fade="0.4" bold color="gray" style={{ fontSize: "10px" }}>
            {cell?.userId}
          </Label>
        </>
      ),
    },
    {
      title: "Customer Id",
      key: "customerId",
      render: (cell) => (
        <>
          <Label small>{cell?.customerId}</Label>
        </>
      ),
    },
    {
      title: "Plan",
      key: "planId",
      render: (cell) => (
        <>
          <Label small>{cell?.plan?.name}</Label>
          <br />
          <Label small>{" (" + cell?.plan?.period + ")"}</Label>
          <br />
          <Label fade="0.4" bold color="gray" style={{ fontSize: "10px" }}>
            {cell?.planId}
          </Label>
        </>
      ),
    },
    {
      title: "Subscription Status",
      key: "subscriptionStatus",
      render: (cell) => (
        <>
          <Label small>{cell?.subscriptionStatus}</Label>
          <br />
          <Label fade="0.4" bold color="gray" style={{ fontSize: "10px" }}>
            {cell?.subscriptionId}
          </Label>
        </>
      ),
    },
    {
      title: "Latest Invoice Status",
      key: "latestInvoiceStatus",
      render: (cell) => (
        <>
          <Label small>{cell?.latestInvoiceStatus}</Label>
          <br />
          <Label fade="0.4" bold color="gray" style={{ fontSize: "10px" }}>
            {cell?.latestInvoiceId}
          </Label>
        </>
      ),
    },
    {
      title: "Active",
      key: "active",
      render: (cell) => (
        <Row center expand>
          <Icon
            size={cell?.active ? 34 : 20}
            color={cell?.active ? "green" : "red"}
            name={cell?.active ? "check" : "close"}
          />
        </Row>
      ),
    },
    {
      title: "Expire After",
      key: "expireAfter",
      render: (cell) => (
        <>
          <Label small>
            {moment(cell?.expireAfter * 1000)
              .utc()
              .format("YYYY-MM-DD HH:mm:ss")}
          </Label>
        </>
      ),
    },
    // {
    //   title: "Subscription Id",
    //   key: "subscriptionId",
    //   render: (cell) => (
    //     <>
    //       <Label small>{cell?.subscriptionId}</Label>
    //     </>
    //   ),
    // },
    // {
    //   title: "Latest Invoice Id",
    //   key: "latestInvoiceId",
    //   render: (cell) => (
    //     <>
    //       <Label small>{cell?.latestInvoiceId}</Label>
    //     </>
    //   ),
    // },
    {
      title: "Created At",
      key: "createdAt",
      render: (cell) => (
        <>
          <Label small>
            {moment(cell?.createdAt).utc().format("YYYY-MM-DD HH:mm:ss")}
          </Label>
        </>
      ),
    },
    // {
    //   title: "Session Id",
    //   key: "sessionId",
    //   render: (cell) => (
    //     <>
    //       <Label small>{cell?.sessionId}</Label>
    //     </>
    //   ),
    // },
    // {
    //   title: "Session Status",
    //   key: "sessionStatus",
    //   render: (cell) => (
    //     <>
    //       <Label small>{cell?.sessionStatus}</Label>
    //     </>
    //   ),
    // },
    // {
    //   title: "Plan Id",
    //   key: "planId",
    //   render: (cell) => (
    //     <>
    //       <Label small>{cell?.planId}</Label>
    //     </>
    //   ),
    // },
  ];
  const basePlanColumns = [
    {
      title: "Name",
      key: "name",
      render: (cell) => (
        <>
          <Label small bold>
            {cell?.name}
          </Label>
          <br />
          <Label fade="0.4" bold color="gray" style={{ fontSize: "10px" }}>
            ({cell?.baseId})
          </Label>
        </>
      ),
    },
    {
      title: "External Id",
      key: "externalId",
      render: (cell) => (
        <>
          <Label small>{cell?.externalId}</Label>
        </>
      ),
    },
    {
      title: "Features",
      key: "features",
      render: (cell) => (
        <Column gap="2">
          <Row gap="5">
            <Label small>
              {cell?.planFeatures?.find((k) => k.name === "DISEASE")?.name}:{" "}
            </Label>
            {/* <Label small>{cell?.planFeatures?.find(k => k.name === "DISEASE")?.active + " "}</Label> */}
            <Label bold small>
              {cell?.planFeatures?.find((k) => k.name === "DISEASE")?.value}
            </Label>
          </Row>

          <Row gap="5">
            {" "}
            <Label small>
              {cell?.planFeatures?.find((k) => k.name === "PRODUCT")?.name}:{" "}
            </Label>
            {/* <Label small>{cell?.planFeatures?.find(k => k.name === "PRODUCT")?.active + " "}</Label> */}
            <Label bold small>
              {cell?.planFeatures?.find((k) => k.name === "PRODUCT")?.value}
            </Label>
          </Row>

          <Row gap="5">
            {" "}
            <Label small>
              {cell?.planFeatures?.find((k) => k.name === "CONFERENCE")?.name}:{" "}
            </Label>
            {/* <Label small>{cell?.planFeatures?.find(k => k.name === "CONFERENCE")?.active + " "}</Label> */}
            <Label bold small>
              {cell?.planFeatures?.find((k) => k.name === "CONFERENCE")?.value}
            </Label>
          </Row>

          <Row gap="5">
            {" "}
            <Label small>
              {cell?.planFeatures?.find((k) => k.name === "EXPORT")?.name}:{" "}
            </Label>
            <Label bold small>
              {cell?.planFeatures?.find((k) => k.name === "EXPORT")?.active +
                " "}
            </Label>
          </Row>

          <Row gap="5">
            {" "}
            <Label small>
              {cell?.planFeatures?.find((k) => k.name === "AICHAT")?.name}:{" "}
            </Label>
            <Label bold small>
              {cell?.planFeatures?.find((k) => k.name === "AICHAT")?.active +
                " "}
            </Label>
          </Row>
          <Row gap="5">
            <Label small>
              {
                cell?.planFeatures?.find((k) => k.name === "CUSTOM_MONITOR")
                  ?.name
              }
              :{" "}
            </Label>
            <Label bold small>
              {cell?.planFeatures?.find((k) => k.name === "CUSTOM_MONITOR")
                ?.active + " "}
            </Label>
          </Row>
        </Column>
      ),
    },
  ];

  return (
    <MainWrapper drawer>
      <Header
        drawer
        rightContent={
          <>
            <Row fit />
          </>
        }
      />
      <Menu active="plans" />

      <Content>
        <Column>
          {activeTab && (
            <Tabs
              active={activeTab}
              tabs={tabs}
              onChange={(e) => setActiveTab(e)}
            />
          )}
          <Column border="gray" fade="3" paddingAll>
            {activeTab === "allPlans" && (
              <>
                <Column>
                  <Label bold large>
                    Plans (with intervals)
                  </Label>
                </Column>
                <Column marginTop="20">
                  {plans?.length > 0 ? (
                    <Column>
                      <Table
                        rowKey="id"
                        data={plans}
                        {...{ columns }}
                        style={{ maxWidth: 980 }}
                        scroll={{ x: 978 }}
                      />
                      {loading && (
                        <Row expand paddingAll center>
                          <Spinner />
                        </Row>
                      )}
                    </Column>
                  ) : (
                    <>
                      {loading ? (
                        <Row expand paddingAll="80" center>
                          <Spinner />
                        </Row>
                      ) : (
                        <Row marginTop>
                          <Label>No plans found</Label>
                        </Row>
                      )}
                    </>
                  )}
                </Column>
              </>
            )}

            {activeTab === "planFeatures" && (
              <>
                <Column>
                  <Label bold large>
                    Base Plans with Features
                  </Label>
                </Column>
                <Column marginTop="20">
                  {basePlans?.length > 0 ? (
                    <Column>
                      <Table
                        rowKey="id"
                        data={basePlans}
                        {...{ columns: basePlanColumns }}
                        style={{ maxWidth: 980 }}
                        scroll={{ x: 978 }}
                      />
                      {basePlansLoading && (
                        <Row expand paddingAll center>
                          <Spinner />
                        </Row>
                      )}
                    </Column>
                  ) : (
                    <>
                      {basePlansLoading ? (
                        <Row expand paddingAll="80" center>
                          <Spinner />
                        </Row>
                      ) : (
                        <Row marginTop>
                          <Label>No base plans found</Label>
                        </Row>
                      )}
                    </>
                  )}
                </Column>
              </>
            )}

            {activeTab === "userPlans" && (
              <>
                <Column>
                  <Label bold large>
                    User Plan Associations
                  </Label>
                </Column>
                <Column marginTop="20">
                  <Filters>
                    <Grid columns={2}>
                      <Column gap="10">
                        <Label bold>Status</Label>
                        <Dropdown
                          expand
                          right
                          select
                          value={selectedStatus}
                          items={statusTypes}
                          onChange={(e) => handleStatusChange(e.id)}
                        />
                      </Column>
                      <Column gap="10">
                        <Label bold>Plan</Label>
                        <Dropdown
                          expand
                          right
                          select
                          value={selectedPlan}
                          items={allPlans}
                          onChange={(e) => handlePlanChange(e.id)}
                        />
                      </Column>
                    </Grid>
                  </Filters>
                  {(window.location.hostname.indexOf("alpha") > -1 ||
                    window.location.hostname.indexOf("beta") > -1 ||
                    window.location.hostname.indexOf("localhost") > -1) && (
                    <>
                      {role == "ADMIN" && (
                        <Row right paddingTop="10">
                          <Anchor
                            bold
                            label="Assign default plan"
                            onClick={() => handleAssignDefaultPlan()}
                          />
                        </Row>
                      )}
                    </>
                  )}
                </Column>
                <Column marginTop="20">
                  <Column>
                    <Table
                      rowKey="id"
                      data={userPlans}
                      {...{ columns: userPlanColumns }}
                      style={{ maxWidth: 980 }}
                      scroll={{ x: 978 }}
                    />
                    {userPlansLoading && (
                      <Row expand paddingAll center>
                        <Spinner />
                      </Row>
                    )}
                  </Column>
                  {userPlansLoading || hasNextPage ? (
                    <Row expand paddingAll="40" center ref={infiniteScrollRef}>
                      <Spinner />
                    </Row>
                  ) : (
                    <>
                      {userPlans?.length === 0 && (
                        <Row marginTop>
                          <Label>No user plans found</Label>
                        </Row>
                      )}
                    </>
                  )}
                </Column>
              </>
            )}
          </Column>
        </Column>
      </Content>
    </MainWrapper>
  );
};
