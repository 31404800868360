import { createAction, createActionTypes } from "../../utils";

export const CONFERENCE_ACTIONS = createActionTypes("CONFERENCE_ACTIONS", [
  "REQUEST",
  "SUCCESS",
  "FAILURE",
  "CLEAR_ALL",

  "ONE_REQUEST",
  "SELECTED_REQUEST",
  "ONE_SUCCESS",
  "ONE_FAILURE",
  "CREATE",
  "UPDATE",
  "CLEAR",

  "CLEAR_TAGS",

  "TWEETS_REQUEST",
  "TWEETS_SUCCESS",
  "TWEETS_FAILURE",

  "TWEETS_COUNT_REQUEST",
  "TWEETS_COUNT_SUCCESS",
  "TWEETS_COUNT_FAILURE",

  "DOL_REQUEST",
  "DOL_SUCCESS",
  "DOL_FAILURE",
]);

export const conferenceActions = {
  request: (data) =>
    createAction(CONFERENCE_ACTIONS.REQUEST, {
      ...data,
      fetching: true,
      success: false,
      error: null,
    }),
  success: ({ data, pageNum }) =>
    createAction(CONFERENCE_ACTIONS.SUCCESS, {
      data,
      pageNum,
      fetching: false,
      success: true,
      error: null,
    }),
  failure: (error) =>
    createAction(CONFERENCE_ACTIONS.FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),
  clearAll: () =>
    createAction(CONFERENCE_ACTIONS.CLEAR_ALL, {
      data: [],
      fetching: true,
      success: false,
      error: null,
    }),

  oneRequest: (id, nocache) =>
    createAction(CONFERENCE_ACTIONS.ONE_REQUEST, {
      id,
      nocache,
      fetching: true,
      success: false,
      error: null,
    }),
  selectedRequest: (data) =>
    createAction(CONFERENCE_ACTIONS.SELECTED_REQUEST, {
      ...data,
      fetching: true,
      success: false,
      error: null,
    }),
  oneSuccess: (data) =>
    createAction(CONFERENCE_ACTIONS.ONE_SUCCESS, {
      ...data,
      fetching: false,
      success: true,
      error: null,
    }),
  oneFailure: (error) =>
    createAction(CONFERENCE_ACTIONS.ONE_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  create: (data, redirect = "/admin/conferences") =>
    createAction(CONFERENCE_ACTIONS.CREATE, {
      data,
      redirect,
      fetching: true,
      success: false,
      error: null,
    }),
  update: (data) =>
    createAction(CONFERENCE_ACTIONS.UPDATE, {
      data,
      fetching: true,
      success: false,
      error: null,
    }),
  clear: () =>
    createAction(CONFERENCE_ACTIONS.CLEAR, {
      data: [],
      fetching: true,
      success: false,
      error: null,
    }),

  dolRequest: (data) =>
    createAction(CONFERENCE_ACTIONS.DOL_REQUEST, {
      ...data,
      fetching: true,
      success: false,
      error: null,
    }),
  dolSuccess: (data) =>
    createAction(CONFERENCE_ACTIONS.DOL_SUCCESS, {
      ...data,
      fetching: false,
      success: true,
      error: null,
    }),
  dolFailure: (error) =>
    createAction(CONFERENCE_ACTIONS.DOL_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  clearTags: () =>
    createAction(CONFERENCE_ACTIONS.CLEAR_TAGS, {
      data: [],
      fetching: false,
      success: false,
      error: null,
    }),

  tweetsRequest: (data) =>
    createAction(CONFERENCE_ACTIONS.TWEETS_REQUEST, {
      ...data,
      fetching: true,
      success: false,
      error: null,
    }),
  tweetsSuccess: ({ data, pageNum }) =>
    createAction(CONFERENCE_ACTIONS.TWEETS_SUCCESS, {
      data: data,
      pageNum: pageNum,
      fetching: false,
      success: true,
      error: null,
    }),
  tweetsFailure: (error) =>
    createAction(CONFERENCE_ACTIONS.TWEETS_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  tweetsCountRequest: (data) =>
    createAction(CONFERENCE_ACTIONS.TWEETS_COUNT_REQUEST, {
      ...data,
      fetching: true,
      success: false,
      error: null,
    }),
  tweetsCountSuccess: ({ data }) =>
    createAction(CONFERENCE_ACTIONS.TWEETS_COUNT_SUCCESS, {
      data: data,
      fetching: false,
      success: true,
      error: null,
    }),
  tweetsCountFailure: (error) =>
    createAction(CONFERENCE_ACTIONS.TWEETS_COUNT_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),
};
