import { isEmpty, uniqBy } from "lodash";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";

import styled from "styled-components";
import moment from "moment";
import amplitude from "amplitude-js";

import { Anchor, Html } from "../components";
import { Color, appVersion } from "./variables";

export const createActionTypes = (base, actions = []) => {
  return actions.reduce((acc, type) => {
    acc[type] = `${base}_${type}`;

    return acc;
  }, {});
};

export const createAction = (type, data = {}) => {
  return { type, payload: data };
};

export const abbreviateNumber = (number) => {
  const symbol = ["", "k", "M"];
  const tier = (Math.log10(Math.abs(number)) / 3) | 0;
  if (tier == 0) return number;
  const suffix = symbol[tier];
  const scale = Math.pow(10, tier * 3);
  const scaled = number / scale;
  return scaled.toFixed(1) + suffix;
};

export const isReady = (item) => {
  return !isEmpty(item.data) && !item.fetching;
};

export const utf8_to_b64 = (str) => {
  return window.btoa(unescape(encodeURIComponent(str)));
};

export const b64_to_utf8 = (str) => {
  return decodeURIComponent(escape(window.atob(str)));
};

export const isValidEmail = (email, workOnly) => {
  // RegExpr
  // https://emailregex.com
  const pattern = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  let validPattern = !!pattern.test(email);

  if (workOnly) {
    const personalDomains = [
      "@icloud.com",
      "@yahoo.com",
      "@gmail.com",
      "@hotmail.com",
      "@meddao.xyz",
      "@accelerationpoint.com",
      "@symplur.com",
      "@h1.co",
      "@h1insights.com",
      "@within3.com",
      "@larvol.com",
      "@veeva.com",
      "@iqvia.com",
      "@live.com",
      "@protonmail.com",
      "@yandex.com",
    ];
    if (personalDomains.some((text) => email.toLowerCase().includes(text))) {
      validPattern = false;
    }
  }

  return validPattern;
};

export const urlToLink = (text) => {
  if (text) {
    const pattern =
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    const parts = text.split(pattern);
    for (let i = 1; i < parts.length; i += 2) {
      parts[i] = (
        <Anchor
          key={`link${i}`}
          inline
          label={` ${parts[i]} `}
          to={parts[i].includes("http") ? parts[i] : `//${parts[i]}`}
          blank
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      );
    }

    return parts;
  }

  return undefined;
};

const HtmlContainer = styled.div`
  a {
    color: ${Color("green", "6")};
    &:hover {
      color: ${Color("green", "7")};
    }
  }
`;

export const findClosest = (arr, target) => {
  return arr.reduce((prev, curr) =>
    Math.abs(curr - target) < Math.abs(prev - target) ? curr : prev
  );
};

export const formatNumber = (num, digits) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits || 0).replace(rx, "$1") + item.symbol
    : "0";
};

export const formatNumberWithCommas = (x) => {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") || x;
};

export const AIHighlight = ({
  text,
  keywords,
  monitors,
  truncateLines = 0,
  handleTruncate,
}) => {
  if (text) {
    // let newText = text + "<p>";
    // newText = newText.replace(/(\n)/g, "</br>");

    let newText = ("<p>" + text + "</p>")
      .replace(/\n\n/g, "</p><p>")
      .replace(/\n/g, "</p><p>")
      .replace(/#/g, "");

    // newText = newText.replace(/######(.*?)######/g, "<h3>$1</h3>");
    // newText = newText.replace(/#####(.*?)#####/g, "<h3>$1</h3>");
    // newText = newText.replace(/####(.*?)####/g, "<h3>$1</h3>");
    // newText = newText.replace(/###(.*?)###/g, "<h3>$1</h3>");
    // newText = newText.replace(/##(.*?)##/g, "<h3>$1</h3>");
    // newText = newText.replace(/#(.*?)#/g, "<h3>$1</h3>");

    newText = newText.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");

    // converts [^1^] to [1]
    newText = newText.replace(/\[\^(.*?)\^\]/g, "[$1]");

    // converts [1](link) to anchot tag with value=link
    newText = newText.replace(
      // /\[(.*?)\]\((.*?)\)/g,
      /\[([^\[]+)\](\(([^)]*))\)/gim,
      '<a class="link" target="_blank" href="$3">[$1]</a>'
    );

    // remove links that contain twitter.com
    newText = newText.replace(
      /<a class="link" target="_blank" href="(.*?)">(.*?)<\/a>/g,
      (match, p1, p2) => {
        if (p1.includes("twitter.com")) {
          return p2;
        } else {
          return match;
        }
      }
    );

    if (keywords?.length > 0) {
      keywords.forEach((kw, i) => {
        const regexp = new RegExp("\\b" + kw + "\\b", "gi");
        // const regexp = new RegExp(kw, "gi");
        newText = newText.replace(
          regexp,
          ` <mark type='button' class="color-${i}">${kw}</mark>`
        );
      });
    }

    if (monitors?.length > 0) {
      monitors.forEach((kw, i) => {
        const regexp = new RegExp("\\b" + kw + "\\b", "gi");
        // const regexp = new RegExp(kw, "gi");
        newText = newText.replace(regexp, ` <strong>${kw}</strong>`);
      });
    }

    // newText = newText + '<span class="blinking-cursor">_</span>';

    if (truncateLines > 0) {
      return (
        <HTMLEllipsis
          unsafeHTML={newText}
          maxLine={truncateLines}
          ellipsis="..."
          onReflow={handleTruncate}
        />
      );
    } else {
      return <div dangerouslySetInnerHTML={{ __html: newText }} />;
    }
  }
};

export const insightHighlight = ({
  text,
  keywords,
  truncateLines = 0,
  handleTruncate,
  breakLines,
  mentions,
}) => {
  let newText = text;
  const mentionedTags = [];

  if (mentions?.length > 0) {
    mentions.forEach((mention) => {
      const matchingTag = text.substring(
        mention.startChar + 1,
        mention.endChar + 1
      );
      const newTag = { ...mention, name: matchingTag };
      mentionedTags.push(newTag);
    });
  }

  // converts [1](link) to anchot tag with value=link
  newText = newText.replace(
    // /\[(.*?)\]\((.*?)\)/g,
    /\[([^\[]+)\](\(([^)]*))\)/gim,
    '<a class="link" target="_blank" href="$3">[$1]</a>'
  );
  // remove brackets surrounding links
  newText = newText.replace(/\[(.*?)\]/g, "$1");
  // remove parentheses surrounding links
  newText = newText.replace(/\((.*?)\)/g, "$1");
  // replace dot, colon at the end of a link with a space
  newText = newText.replace(/\.(?=\s|$)/g, " .");
  newText = newText.replace(/\,(?=\s|$)/g, " ,");

  newText = newText
    .replace(/(>http(s)?:?\/\/(?:www\.|(?!www)))/g, ">")
    .replace(
      /#(([a-zA-Z0-9_ー\-]+))/g,
      "<a target='_blank'  href='https://twitter.com/hashtag/$1/'>#$1</a>"
    )
    .replace(
      /@(([a-zA-Z0-9_ー\-]+))/g,
      "<a target='_blank' href='https://twitter.com/$1/'>@$1</a>"
    )
    .replace(/\[(.*?)\]\((".*?")\)/g, '<a target="_blank" href=$2>$1</a>')
    .replace(/\[(.*?)\]\((.*?)\)/g, '<a target="_blank" href="$2">$1</a>')
    .replace(
      /(?<!["'])(http(s)?:?\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})(?!["'])/g,
      (match) => {
        const url =
          match.includes("http://") || match.includes("https://")
            ? match
            : "http://" + match;
        return `<a target='_blank' href='${url}'>${match}</a>`;
      }
    );

  // replace " ." with "."
  newText = newText.replace(/ \./g, ".");
  // replace " ," with ","
  newText = newText.replace(/ ,/g, ",");

  newText = ("<p>" + newText + "</p>")
    .replace(/\n\n/g, "</p><p>")
    .replace(/\n/g, "</p><p>");

  if (keywords?.length > 0) {
    keywords.forEach((kw, i) => {
      const regexp = new RegExp("\\b" + kw + "\\b", "gi");
      // const regexp = new RegExp(kw, "gi");
      newText = newText.replace(
        regexp,
        ` <mark type='button' class="color-${i}">${kw}</mark>`
      );
    });
  }

  if (mentionedTags?.length > 0) {
    mentionedTags.forEach((mention, i) => {
      const regexp = new RegExp("(?<!>)\\b" + mention.name + "\\b", "i");
      newText = newText.replace(
        regexp,
        ` <mark type='button'
        data-time="${mention.seconds}"
        class="color-1">${mention.name}</mark>`
      );
    });
  }

  if (breakLines) {
    newText = newText.replace(/\. /g, ". </br></br>");
  }

  newText = `<div class="highlights">${newText}</div>`;

  return (
    <>
      {truncateLines > 0 ? (
        <HTMLEllipsis
          unsafeHTML={newText}
          maxLine={truncateLines}
          ellipsis="..."
          onReflow={handleTruncate}
        />
      ) : (
        <div dangerouslySetInnerHTML={{ __html: newText }} />
      )}
    </>
  );
};

export const tweetHighlight = ({ text, urls, keywords }) => {
  // Removing urls and highlighting mentions and hastags
  if (text) {
    let newText = text.replace(/(\n)/g, " ");
    const textUrls = newText.match(/\bhttp(s)?:\/\/\S+/gi);
    const lastWord = newText.split(" ").pop();
    const isLastWordUrl = lastWord.startsWith("http");
    if (isLastWordUrl) {
      newText = text.split(lastWord)[0];
    }

    newText = newText
      .replace(/(\n\n)/g, " <p/> ")
      .replace(/(\n)/g, " </br> ")
      //pattern to change for better readability (backend-#128)
      .replace(/\b(pts)\b/gi, "patients")
      .replace(/\b(pembro)\b/gi, "pembrolizumab")
      .replace(/⬇️/gi, " decreased ")
      .replace(/⬆️/gi, " increased ")
      .replace(/➡️/gi, " ")
      .replace(/&lt;/gi, "<")
      .replace(/&gt;/gi, ">")
      //pattern to change for better readability (backend-#151)
      .replace(/&amp;/gi, " and ") // no word boundary
      .replace(/&/gi, " and ") // no word boundary
      .replace(/\b1L\b/gi, "1st line")
      .replace(/\b2L\b/gi, "2nd line")
      .replace(/\b3L\b/gi, "3rd line")
      .replace(/\basx\b/gi, "asymptomatic")
      .replace(/\bbx\b/gi, "biopsy")
      .replace(/\bd\/c/gi, " discontinue ") // no end word boundary
      .replace(/\bdx\b/gi, "diagnosis")
      .replace(/\bh\/o/gi, " history of ") // no end word boundary
      .replace(/\bincl\b/gi, "including")
      .replace(/\binh\b/gi, "inhibitors")
      .replace(/\bpretx\b/gi, "pretreatment")
      .replace(/\bpt\b/gi, "patient")
      .replace(/\bptx\b/gi, "patients")
      .replace(/\bresid\b/gi, "residual")
      .replace(/\brx\b/gi, "prescription")
      .replace(/\bSMI\b/gi, "serious mental illness")
      .replace(/\bsyst\b/gi, "systemic")
      .replace(/\btox\b/gi, "toxicity")
      .replace(/\bTreg\b/gi, "regulatory T cells")
      .replace(/\btx\b/gi, "treatment")
      .replace(/\bvs\b/gi, "vs.")
      .replace(/\bw\/out/gi, " without ") // no end word boundary
      .replace(/\bw\/o/gi, " without ") // no end word boundary
      .replace(/\bw\//gi, " with ") // no end word boundary
      .replace(/\bw\b/gi, "with");

    if (urls?.length > 0 && textUrls?.length === urls?.length) {
      urls.forEach((url, i) => {
        let realUrl = decodeURIComponent(url.url);
        realUrl = realUrl.replace(/ /g, "_");
        newText = newText.replace(textUrls[i], realUrl);
      });
    }

    newText = newText
      .replace(
        /(http(s)?:?\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g,
        "<a target='_blank' class='tweet-url' href='$1'>$1</a>"
      )
      .replace(/(>http(s)?:?\/\/(?:www\.|(?!www)))/g, ">")
      .replace(
        /#(([a-zA-Z0-9_ー\-]+))/g,
        "<a target='_blank'  href='https://twitter.com/hashtag/$1/'>#$1</a>"
      )
      .replace(
        /@(([a-zA-Z0-9_ー\-]+))/g,
        "<a target='_blank' href='https://twitter.com/$1/'>@$1</a>"
      );

    if (keywords?.length > 0) {
      keywords.forEach((kw) => {
        const regexp = new RegExp("\\b" + kw + "\\b", "gi");
        // const regexp = new RegExp("\\b" + kw + "\\b"); //Case sensitive
        newText = newText.replace(regexp, ` <mark>${kw}</mark>`);
      });
    }

    return (
      <HtmlContainer
        dangerouslySetInnerHTML={{
          __html: newText,
        }}
      />
    );
  }
};

export const tweetUrls = (text) => {
  const urls = [];
  if (text) {
    const pattern =
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    const parts = text.split(pattern);
    for (let i = 1; i < parts.length; i += 2) {
      urls.push(parts[i]);
    }
  }
  return urls;
};

export const timeFormat = (initialDate, endDate) => {
  const start1 = moment(initialDate).format("YYYY-MM-DD");
  const start2 = "2000-01-01";
  const end1 = moment(endDate).format("YYYY-MM-DD");
  const end2 = moment().format("YYYY-MM-DD");

  return start1 === start2 && end1 === end2;
};

// NOTE : Date.toISOString() does the timezone conversion
// We do not need timezone, but need same format for endpoint calls
export const getISOFormattedDateTimeString = (timestamp) => {
  return moment(timestamp).utcOffset(0, true).format("YYYY-MM-DDTHH:mm:ss");
};

export const getISOFormattedDate = (timestamp) => {
  return moment(timestamp).utcOffset(0, true).format("YYYY-MM-DD");
};

export const getUrlParams = (search) => {
  const hashes = search.slice(search.indexOf("?") + 1).split("&");
  const params = {};
  hashes.map((hash) => {
    const [key, val] = hash.split("=");
    params[key] = decodeURIComponent(val);
  });
  return params;
};

export const daysUntil = (dateToCheckAgainst) => {
  return new moment().to(moment(dateToCheckAgainst));
};

export const formatShortDate = (date) => {
  const formatedDate = moment(date).format("MMM D, YYYY");
  return formatedDate;
};

export const formatShortDates = (start, end, utc) => {
  let startDate = moment(start);
  let endDate = moment(end);
  if (utc) {
    startDate = moment(start).utc();
    endDate = moment(end).utc();
  }
  let dates = startDate.format("MMM D, YYYY");
  // Different start and end dates
  if (startDate.format("MMM D, YYYY") !== endDate.format("MMM D, YYYY")) {
    // Same year
    if (startDate.format("YYYY") === endDate.format("YYYY")) {
      // Same month
      dates = `${startDate.format("MMM D")} - ${endDate.format("MMM D, YYYY")}`;
      if (startDate.format("MMM") === endDate.format("MMM")) {
        dates = `${startDate.format("MMM D")} - ${endDate.format("D, YYYY")}`;
      }
    } else {
      dates = `${startDate.format("MMM D, YYYY")} - ${endDate.format(
        "MMM D, YYYY"
      )}`;
    }
  }

  return dates;
};
export const formatMonthDayDates = ({ start, end }) => {
  return end
    ? `${moment(start).format("MMM D")} - ${moment(end).format("MMM D")}`
    : moment(start).format("MMM D");
};

export const logAnalyticEvent = (
  event,
  eventProperties,
  userId,
  userName,
  userEmail,
  createdAt,
  userType,
  plan
) => {
  // Amplitude reference : https://developers.amplitude.com/docs/javascript
  if (event) {
    amplitude.getInstance().setUserId(userEmail);
    amplitude.getInstance().options.includeReferrer = true;
    amplitude.getInstance().options.includeUtm = true;
    // ref : https://developers.amplitude.com/docs/javascript#setting-multiple-user-properties
    var identify1 = new amplitude.Identify()
      .set("userId", userId || "Anonymous")
      .set("name", userName || "Anonymous")
      .set("email", userEmail || "Anonymous");
    if (appVersion !== "APP_VERSION") {
      identify1.set("Version", appVersion);
      // identify1.set("app_version", appVersion);
    }
    if (createdAt) {
      const createdDate = getISOFormattedDate(createdAt);
      identify1.set("createdDate", createdDate);
      const createdDateTime = getISOFormattedDateTimeString(createdAt);
      identify1.set("createdDateTime", createdDateTime);
    }
    if (userType) {
      identify1.set("userType", userType);
    }
    if (plan) {
      identify1.set("plan", plan);
    }
    // .set('City', 'San Francisco')
    amplitude.getInstance().identify(identify1);
    // var userProperties = {
    //   id: userId || "Anonymous",
    //   name: userName || "Anonymous",
    //   email: userEmail || "Anonymous",
    //   // City: "San Francisco",
    // };
    // amplitude.getInstance().setUserProperties(userProperties);
    amplitude.getInstance().logEvent(event, eventProperties);
  }
};

// to be called when the user logs out
// Ref : https://amplitude.github.io/Amplitude-JavaScript/
export const resetAnalyticSession = () => {
  // NOTE - With a null userId and a completely new deviceId, the current user would appear as a brand new user in dashboard
  // regenerate device id
  amplitude.getInstance().regenerateDeviceId();
  // nullify user id
  amplitude.getInstance().setUserId(null);
  // reset session id
  amplitude.getInstance().resetSessionId();
};

export const capitalize = (string) => {
  const lowerString = string?.toLowerCase();
  return string
    ? lowerString.charAt(0).toUpperCase() + lowerString.slice(1)
    : "";
};

export const sortedMonitors = (monitors) => {
  let newMonitors = [];
  if (monitors?.length > 0) {
    newMonitors = monitors?.map((m) => {
      let newMonitor = {
        id: m.monitorComponent.object1Id, // this is disease / product id
        monitorId: m.id,
        monitorComponentId: m.monitorComponentId,
        label: m.monitorComponent.object1Name,
        external: true,
        type: m.monitorComponent.object1Type,
        visible: m?.visible && !m.monitorComponent?.object1Ignore,
        monitorRules: m.monitorRules,
      };
      return newMonitor;
    });
    // monitors are nothing but pair of disease / products with
    // frequency. There can be a case where one disease
    // is associated with multiple frequencies.
    // And so, prepare a unique list for left menu
    newMonitors = uniqBy(newMonitors, "id");

    // monitors should be displayed in following order - diseases, products and conferences
    const confMonitors = newMonitors.filter((m) => m.type === "CONFERENCE");
    const disMonitors = newMonitors.filter((m) => m.type === "DISEASE");
    const prodMonitors = newMonitors.filter((m) => m.type === "PRODUCT");
    newMonitors = [
      ..._.orderBy(disMonitors, ["label"]),
      ..._.orderBy(prodMonitors, ["label"]),
      // Ref - (backend-169) conference monitors should not be rendered
      // ..._.orderBy(confMonitors, ["label"]),
    ];
  }
  return newMonitors;
};

export const idToText = (text) => {
  return text?.toLowerCase().replace(/\s+/g, "_");
};

export const textToId = ({ text, items }) => {
  const newText = text?.replace(/_/g, " ");
  const selectedItem = items.find(
    (item) => item.label?.toLowerCase() === newText
  );
  return selectedItem?.id;
};

export const formatTime = (seconds) => {
  // covert seconds to hh:mm:ss format
  // hide hours if zero
  seconds = seconds < 0 ? 0 : seconds;
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);
  return [hours, minutes, secs]
    .map((v) => (v < 10 ? "0" + v : v))
    .filter((v, i) => v !== "00" || i > 0)
    .join(":");
};

export const months = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];
