import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { accountActions, commonActions } from "../../../store/actions";
import { cancelHcpRequests } from "../../../store/sagas";
import { isReady } from "../../../utils";
import useDebounce from "../../../utils/useDebounce";

import { view } from "./EditAccountView";
import { getNPITaxonomies } from "../../../store/sagas/accountSagas";

export const EditAccount = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const errorsList = {
    name: "Required field",
    description: "Required field",
    location: "Required field",
  };

  // --> mapStateToProp
  const auth = useSelector((state) => state.auth.data);
  const initialCountries = useSelector((state) => state.common.countries);
  const initialHcp = useSelector((state) => state.account.one);
  const initialHcps = useSelector((state) => state.account.all);

  // --> STATE
  const [hcp, setHcp] = useState();
  const [npiError, setNpiError] = useState("");
  const [hcps, setHcps] = useState([]);
  const [deleteModal, toggleDeleteModal] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [npiTaxonomies, setNpiTaxonomies] = useState([]);
  const [selectedInstitution, setSelectedInstitution] = useState([]);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const debounceTerm = useDebounce(searchTerm, 700);
  const [countryTerm, setCountryTerm] = useState("");
  // const [errors, setErrors] = useState({
  //   name: "",
  //   description: "",
  //   location: "",
  // });

  // <-- STATE

  useEffect(() => {
    dispatch(accountActions.oneRequest(id));
    if (initialCountries?.length === 0)
      dispatch(commonActions.requestCountries());

    return () => {
      dispatch(accountActions.clear());
      cancelHcpRequests.abort();
    };
  }, [id]);

  useEffect(() => {
    if (
      !hcp?.npiId &&
      hcp?.accountType === "PERSON" &&
      hcp.accountSubtype === "HCP" &&
      hcp?.country === "USA"
    ) {
      setNpiError("Required field");
    } else if (npiError === "Required field") {
      setNpiError("");
    }
  }, [hcp]);

  useEffect(() => {
    (async () => {
      const temp = await getNPITaxonomies();
      if (temp.data.length > 0) {
        const allNpiTaxonomies = [];
        temp.data.forEach((row) => {
          allNpiTaxonomies.push({
            id: row.taxonomyCode,
            name: row.taxonomyName,
            label: row.taxonomyName,
          });
        });
        setNpiTaxonomies(allNpiTaxonomies);
      }
    })();
  }, [dispatch]);

  useEffect(() => {
    if (initialCountries?.length > 0) {
      const list = initialCountries.map((a) => ({
        id: a.countryCode,
        label: a.country,
      }));

      const uniqueList = list.filter(
        (v, i, a) => a.findIndex((t) => t.id === v.id) === i
      );

      setCountries(uniqueList);
    }
  }, [initialCountries]);

  useEffect(() => {
    if (isReady(initialHcp)) {
      if (initialHcp.data.accountType === "PERSON") {
        if (initialHcp.data.institution?.id) {
          setSelectedInstitution([
            {
              ...initialHcp.data.institution,
              label: initialHcp.data.institution.name,
            },
          ]);
          setSearchTerm(initialHcp.data.institution.name);
        }
      }
      setHcp(initialHcp.data);
      setCountryTerm(initialHcp.data.country);
    }
  }, [initialHcp.data]);

  useEffect(() => {
    if (hcp?.countryCode && initialCountries?.length > 0) {
      const list = initialCountries.filter(
        (a) => a.countryCode === hcp.countryCode
      );
      const statesList = list
        .filter((a) => a.stateProvince)
        .map((a) => ({
          id: a.stateProvinceCode,
          label: a.stateProvince,
        }));
      if (statesList.length > 0) {
        setStates(statesList);
      } else {
        setStates([]);
      }
    }
  }, [initialCountries, hcp?.countryCode]);

  useEffect(() => {
    if (initialHcps.success) {
      let institutions = [];
      if (initialHcps.data.length > 0) {
        initialHcps.data.forEach((a) => {
          institutions.push({
            ...a,
            label: a.name,
          });
        });
      }
      setHcps(institutions);
    }
  }, [initialHcps.data]);

  useEffect(() => {
    if (isReady(initialHcp) && initialHcp.data?.accountType === "PERSON") {
      requestInstitions(0);
      setPage(0);
    }
  }, [debounceTerm, initialHcp]);

  const handleUpdate = () => {
    // checkErrors();
    dispatch(accountActions.update(hcp));
  };

  // const clearError = (field) => {
  //   setErrors((oldData) => ({
  //     ...oldData,
  //     [field]: "",
  //   }));
  // };

  // const checkErrors = () => {
  //   if (hcp) {
  //     setErrors({
  //       name: hcp.name === "" ? errorsList.name : "",
  //       description: hcp.description === "" ? errorsList.description : "",
  //       location: hcp.location === "" ? errorsList.location : "",
  //     });
  //   }
  // };

  const handleChange = ({ value, field }) => {
    if (
      field === "npiId" &&
      hcp.accountType === "PERSON" &&
      hcp.accountSubtype === "HCP" &&
      hcp.country === "USA"
    ) {
      if (value.length === 0) {
        setNpiError("Required field");
        return;
      } else if (npiError === "Required field") {
        setNpiError("");
      }
    }

    setHcp((oldData) => ({
      ...oldData,
      [field]: value,
    }));

    // checkErrors();
    // clearError(field);
  };

  const handleChangeAndUpdate = ({ value, field }) => {
    if (
      field === "npiId" &&
      !value &&
      hcp.accountType === "PERSON" &&
      hcp.accountSubtype === "HCP" &&
      hcp.country === "USA"
    ) {
      setNpiError("Required field");
      return;
    }

    const newData = { ...hcp, [field]: value };
    setHcp(newData);
    dispatch(accountActions.update(newData));
  };

  const handleChangeAndUpdateNpiTaxonomy = ({ value, field }) => {
    const newData = {
      ...hcp,
      npiTaxonomies: [
        {
          taxonomyCode: value,
          primary: true,
          tAccountId: hcp.id,
        },
      ],
    };
    setHcp(newData);
    dispatch(accountActions.update(newData));
  };

  const requestInstitions = (pageNum) => {
    dispatch(
      accountActions.request({
        pageNum,
        ...(searchTerm !== "" && { term: searchTerm }),
        type: "INSTITUTION",
      })
    );
  };

  const handleMoreInstitions = () => {
    if (!hcps.fetching) {
      const newPage = page + 1;
      setPage(newPage);
      requestInstitions(newPage);
    }
  };

  const handleUpdateInstitution = (e) => {
    setSelectedInstitution([e]);
    if (e.id) {
      dispatch(
        accountActions.addRelationship({
          hcpId: hcp.id,
          institutionId: e.id,
        })
      );
    }
  };

  const handleClearInstitution = () => {
    if (selectedInstitution[0]?.id) {
      setSelectedInstitution([]);
      dispatch(
        accountActions.deleteRelationship({
          hcpId: hcp.id,
          institutionId: selectedInstitution[0].id,
        })
      );
    }
  };

  const handleUpdateState = (e) => {
    const newData = { ...hcp, stateProvince: e.label, stateProvinceCode: e.id };
    setHcp(newData);
    dispatch(accountActions.update(newData));
  };

  const handleUpdateCountry = (e) => {
    setCountryTerm(e.label);
    const newData = {
      ...hcp,
      country: e.label,
      countryCode: e.id,
      stateProvince: "",
      stateProvinceCode: "",
    };
    setHcp(newData);
    dispatch(accountActions.update(newData));
  };

  const handleDeleteAccount = () => {
    dispatch(accountActions.delete({ id, accountType: hcp.accountType }));
  };

  const handleClearCountry = () => {
    setCountryTerm([]);
    const newData = { ...hcp, country: "" };
    setHcp(newData);
    dispatch(accountActions.update(newData));
  };

  return view({
    // errors,
    hcp,
    handleUpdate,
    handleChange,
    handleChangeAndUpdate,
    isInstitution: hcp?.accountType === "INSTITUTION",
    isPerson: hcp?.accountType === "PERSON",
    searchTerm,
    setSearchTerm,
    hcps,
    infiniteScroll: {
      loading: initialHcps.fetching,
      hasNextPage: !initialHcps.isLastPage,
      onLoadMore: handleMoreInstitions,
    },
    selectedInstitution,
    handleUpdateInstitution,
    handleClearInstitution,

    countries,
    countryTerm,
    setCountryTerm,
    handleUpdateCountry,
    handleClearCountry,
    states,
    handleUpdateState,

    npiTaxonomies,
    handleChangeAndUpdateNpiTaxonomy,

    deleteModal,
    toggleDeleteModal,
    handleDeleteAccount,
    loadingInstitutions: initialHcps.fetching,
    npiError,
  });
};
