import { put, call, takeLatest, takeLeading, all } from "redux-saga/effects";
import axios from "axios";

import { getApiPath } from "../../utils/variables";
import { authHeader } from "../store";
import { objectToUrlEncoded } from "../../utils/constants";
import { TAG_ACTIONS, tagActions, productActions } from "../actions";

export let cancelTagRequests = new AbortController();

/**
 * get tag list
 */
function* handleRequest(action) {
  try {
    cancelTagRequests = new AbortController();
    const {
      diseaseId,
      productId,
      meaning,
      ignore,
      match,
      preferred,
      threshold,
      term,
      startDate,
      endDate,
      includeDiseases,
      includeProducts,
      includeConferences,
      maxResult,
      pageNum,
      nocache,
    } = action.payload;
    const params = objectToUrlEncoded({
      meaning,
      ignore,
      match,
      preferred,
      reviewed: null, // once the feature is implemented (true / false / null)
      threshold,
      term,
      startDate,
      endDate,
      includeDiseases,
      includeProducts,
      includeConferences,
      pageNum,
      maxResult: maxResult || -1,
      nocache: nocache || "true",
    });
    const temp = diseaseId ? `disease/${diseaseId}/tweets/` : productId ? `product/${productId}/tweets/` : ``;
    const { data } = yield call(
      axios.get,
      `${getApiPath(false)}/${temp}tags?${params}`,
      {
        signal: cancelTagRequests.signal,
      }
    );
    yield put(tagActions.success({ data, pageNum }));
  } catch (e) {
    if (e?.response?.status === 401) {
      yield put((window.location.href = "/logout"));
    }
    yield put(tagActions.failure({ error: { ...e } }));
  } finally {
  }
}

/**
 * get a tag
 */
function* handleRequestOne(action) {
  try {
    cancelTagRequests = new AbortController();
    const { id } = action.payload;
    const { data } = yield call(
      axios.get,
      `${getApiPath()}/tag/${id}?${Date.now()}`,
      {
        headers: authHeader(),
        signal: cancelTagRequests.signal,
      }
    );
    yield put(tagActions.oneSuccess({ data }));
  } catch (e) {
    yield put(tagActions.oneFailure({ error: { ...e } }));
  }
}

/**
 * create tag
 */
function* handleCreate(action) {
  const body = action.payload.data;
  const redirect = action.payload.redirect;

  try {
    cancelTagRequests = new AbortController();
    const { data } = yield call(axios.post, `${getApiPath()}/tag`, body, {
      headers: authHeader(),
      signal: cancelTagRequests.signal,
    });
    yield put(tagActions.oneSuccess({ data }));
    if (redirect) yield put((window.location.href = "/admin/tags"));
  } catch (e) {
    yield put(tagActions.oneFailure({ error: { ...e } }));
  }
}

/**
 * update tag
 */
function* handleUpdate(action) {
  const body = action.payload.data;

  try {
    cancelTagRequests = new AbortController();
    const { data } = yield call(axios.put, `${getApiPath()}/tag`, body, {
      headers: authHeader(),
      signal: cancelTagRequests.signal,
    });
    yield put(tagActions.oneSuccess({ data }));
  } catch (e) {
    yield put(tagActions.oneFailure({ error: { ...e } }));
  }
}

/**
 * delete tag
 */
function* handleDelete(action) {
  const { id, type } = action.payload.data;

  try {
    cancelTagRequests = new AbortController();
    const { data } = yield call(axios.delete, `${getApiPath()}/tag?id=${id}`, {
      headers: authHeader(),
      signal: cancelTagRequests.signal,
    });
    yield put(tagActions.oneSuccess({ data }));
    yield put((window.location.href = type ? `/admin/${type}` : "/admin/tags"));
  } catch (e) {
    yield put(tagActions.oneFailure({ error: { ...e } }));
  }
}

/**
 * Get substituted (whitelisted) tags for the tag specified
 */

//  curl -X GET "https://alpha.medical.watch/notauth/tags/substitutedTags?tagId=06367854-ddc7-4359-9990-7222d1ce12b2&tagId=e2690f04-9ee8-4c92-96f9-66d0d6999d21&term=s&pretty" --header "Content-Type: application/json" --header "Authentication-Info: c80a112f-c8df-48cd-864d-4bc377f3de81"

function* handleRequestWhitelist(action) {
  const { ids, nocache } = action.payload;
  const tagIds = ids.join("&tagId=");
  try {
    cancelTagRequests = new AbortController();
    const { data } = yield call(
      axios.get,
      `${getApiPath(false)}/tags/substitutedTags?tagId=${tagIds}${
        nocache ? "&nocache=true" : ""
      }`,
      {
        signal: cancelTagRequests.signal,
      }
    );
    yield put(tagActions.whitelistSuccess({ data }));
  } catch (e) {
    yield put(tagActions.whitelistFailure({ error: { ...e } }));
  }
}

/**
 * Set / reset preferred tag for the specified tag
 */
function* handleUpdateWhitelist(action) {
  const { id, preferredTagId } = action.payload.data;
  const body = { id, preferredTagId };

  try {
    cancelTagRequests = new AbortController();
    const { data } = yield call(
      axios.put,
      `${getApiPath()}/tag/preferred`,
      body,
      {
        headers: authHeader(),
        signal: cancelTagRequests.signal,
      }
    );
    yield put(tagActions.whitelistSuccess({ data }));
  } catch (e) {
    yield put(tagActions.whitelistFailure({ error: { ...e } }));
  }
}

/**
 * Get all tag associations for the specified tag
 * NOTE - it can be product or otherwise
 */
function* handleRequestTagAssociations(action) {
  const { id } = action.payload;
  try {
    cancelTagRequests = new AbortController();
    const { data } = yield call(
      axios.get,
      `${getApiPath()}/tag/${id}/tagsAssociated`,
      {
        headers: authHeader(),
        signal: cancelTagRequests.signal,
      }
    );
    yield put(tagActions.tagAssociationsSuccess({ data }));
  } catch (e) {
    yield put(tagActions.tagAssociationsFailure({ error: { ...e } }));
  }
}

/**
 * Create tag association
 */
function* handleCreateTagAssociation(action) {
  const { from, to, relationship } = action.payload.data;
  const params = objectToUrlEncoded({
    relationship,
  });
  try {
    cancelTagRequests = new AbortController();
    const { data } = yield call(
      axios.post,
      `${getApiPath()}/tag/${from}/associate/${to}?${params}`,
      null,
      {
        headers: authHeader(),
        signal: cancelTagRequests.signal,
      }
    );
    yield put(tagActions.tagAssociationSuccess({ data }));
  } catch (e) {
    yield put(tagActions.tagAssociationFailure({ error: { ...e } }));
  }
}

/**
 * Remove tag association
 */
function* handleDeleteTagAssociation(action) {
  const { from, to } = action.payload.data;
  try {
    cancelTagRequests = new AbortController();
    const { data } = yield call(
      axios.delete,
      `${getApiPath()}/tag/${from}/dissociate/${to}`,
      {
        headers: authHeader(),
        signal: cancelTagRequests.signal,
      }
    );
    yield put(tagActions.tagAssociationSuccess({ data }));
  } catch (e) {
    yield put(tagActions.tagAssociationFailure({ error: { ...e } }));
  }
}

/**
 * Get all tag monitor rules identified by the
 * tag id of the monitor (disease / product)
 */
function* handleRequestTagMonitorRules(action) {
  const { id } = action.payload;
  try {
    cancelTagRequests = new AbortController();
    const { data } = yield call(axios.get, `${getApiPath()}/tag/${id}/rules`, {
      headers: authHeader(),
      signal: cancelTagRequests.signal,
    });
    yield put(tagActions.tagMonitorRulesSuccess({ data }));
  } catch (e) {
    yield put(tagActions.tagMonitorRulesFailure({ error: { ...e } }));
  }
}

/**
 * Create tag monitor rule
 */
function* handleCreateTagMonitorRule(action) {
  const body = action.payload.data;
  try {
    cancelTagRequests = new AbortController();
    const { data } = yield call(axios.post, `${getApiPath()}/tag/rule`, body, {
      headers: authHeader(),
      signal: cancelTagRequests.signal,
    });
    yield put(tagActions.tagMonitorRuleSuccess({ data }));
  } catch (e) {
    yield put(tagActions.tagMonitorRuleFailure({ error: { ...e } }));
  }
}

/**
 * Remove tag monitor rule
 */
function* handleDeleteTagMonitorRule(action) {
  const { tagId, secondTagId, monitorObjectTagId, userId, region } =
    action.payload.data;
  const params = objectToUrlEncoded({
    tagId,
    secondTagId,
    monitorObjectTagId,
    userId,
    region,
  });
  try {
    cancelTagRequests = new AbortController();
    const { data } = yield call(
      axios.delete,
      `${getApiPath()}/tag/rule?${params}`,
      {
        headers: authHeader(),
        signal: cancelTagRequests.signal,
      }
    );
    yield put(tagActions.tagMonitorRuleSuccess({ data }));
  } catch (e) {
    yield put(tagActions.tagMonitorRuleFailure({ error: { ...e } }));
  }
}

/**
 * Get summary of tags (count, preferred, etc.) for admin users
 */
function* handleAdminSummaryRequest(action) {
  try {
    cancelTagRequests = new AbortController();
    const { meaning, ignore, preferred, term, withStats } = action.payload;

    const params = objectToUrlEncoded({
      meaning,
      ignore,
      preferred,
      reviewed: null, // once the feature is implemented (true / false / null)
      term,
      withStats,
    });

    // this is for admin users
    const { data } = yield call(
      axios.get,
      `${getApiPath()}/tags/adminSummary?${params}`,
      {
        headers: authHeader(),
        signal: cancelTagRequests.signal,
      }
    );
    yield put(tagActions.adminSummarySuccess(data));
  } catch (e) {
    yield put(tagActions.adminSummaryFailure({ error: { ...e } }));
  }
}

// function* handleRequestByUser(action) {
//   try {
//     const { id, userId } = action.payload;
//     const { data } = yield call(axios.get, `${getApiPath()}/tag/${id}/${userId}`);
//     yield put(tagActions.oneSuccess({ data }));
//   } catch (e) {
//     yield put(tagActions.oneFailure({ error: { ...e } }));
//   }
// }

/**
 * request tag owner
 */
function* handleRequestOwner(action) {
  try {
    cancelTagRequests = new AbortController();
    const { id } = action.payload;
    const { data } = yield call(axios.get, `${getApiPath()}/tag/${id}/OWNER`, {
      headers: authHeader(),
      signal: cancelTagRequests.signal,
    });
    yield put(tagActions.ownerSuccess({ data }));
  } catch (e) {
    yield put(tagActions.ownerFailure({ error: { ...e } }));
  }
}

/**
 * create tag owner
 */
function* handleCreateOwner(action) {
  const body = action.payload.data;

  try {
    cancelTagRequests = new AbortController();
    const { data } = yield call(
      axios.post,
      `${getApiPath()}/tag/relation`,
      body,
      {
        headers: authHeader(),
        signal: cancelTagRequests.signal,
      }
    );
    yield put(tagActions.ownerSuccess({ data }));
  } catch (e) {
    yield put(tagActions.ownerFailure({ error: { ...e } }));
  }
}

/**
 * update tag owner
 */
function* handleUpdateOwner(action) {
  const body = action.payload.data;
  try {
    cancelTagRequests = new AbortController();
    const { data } = yield call(
      axios.put,
      `${getApiPath()}/tag/relation`,
      body,
      {
        headers: authHeader(),
        signal: cancelTagRequests.signal,
      }
    );
    yield put(tagActions.ownerSuccess({ data }));
    // update products so we see the new owner products tab
    yield put(productActions.request({}));
  } catch (e) {
    yield put(tagActions.ownerFailure({ error: { ...e } }));
  }
}

/**
 * delete tag owner
 */
function* handleDeleteOwner(action) {
  const { tagId, tAccountId } = action.payload.data;
  const params = objectToUrlEncoded({
    tagId,
    tAccountId,
  });
  try {
    cancelTagRequests = new AbortController();
    const { data } = yield call(
      axios.delete,
      `${getApiPath()}/tag/relation?${params}`,
      {
        headers: authHeader(),
        signal: cancelTagRequests.signal,
      }
    );
    yield put(tagActions.ownerSuccess({ data }));
  } catch (e) {
    yield put(tagActions.ownerFailure({ error: { ...e } }));
  }
}

function* watchtagSagas() {
  yield all([
    takeLeading(TAG_ACTIONS.REQUEST, handleRequest),
    takeLatest(TAG_ACTIONS.CREATE, handleCreate),
    takeLatest(TAG_ACTIONS.UPDATE, handleUpdate),
    takeLatest(TAG_ACTIONS.DELETE, handleDelete),
    takeLatest(TAG_ACTIONS.ONE_REQUEST, handleRequestOne),
    takeLatest(TAG_ACTIONS.WHITELIST_REQUEST, handleRequestWhitelist),
    takeLatest(TAG_ACTIONS.WHITELIST_UPDATE, handleUpdateWhitelist),

    takeLatest(
      TAG_ACTIONS.TAG_ASSOCIATIONS_REQUEST,
      handleRequestTagAssociations
    ),
    takeLatest(TAG_ACTIONS.TAG_ASSOCIATION_CREATE, handleCreateTagAssociation),
    takeLatest(TAG_ACTIONS.TAG_ASSOCIATION_DELETE, handleDeleteTagAssociation),

    takeLatest(
      TAG_ACTIONS.TAG_MONITOR_RULES_REQUEST,
      handleRequestTagMonitorRules
    ),
    takeLatest(TAG_ACTIONS.TAG_MONITOR_RULE_CREATE, handleCreateTagMonitorRule),
    takeLatest(TAG_ACTIONS.TAG_MONITOR_RULE_DELETE, handleDeleteTagMonitorRule),

    takeLatest(TAG_ACTIONS.TAG_OWNER_REQUEST, handleRequestOwner),
    takeLatest(TAG_ACTIONS.TAG_OWNER_CREATE, handleCreateOwner),
    takeLatest(TAG_ACTIONS.TAG_OWNER_UPDATE, handleUpdateOwner),
    takeLatest(TAG_ACTIONS.TAG_OWNER_DELETE, handleDeleteOwner),

    takeLatest(TAG_ACTIONS.ADMIN_SUMMARY_REQUEST, handleAdminSummaryRequest),
  ]);
}

export default watchtagSagas;
