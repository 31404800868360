import React, { useEffect } from "react";
import { planActions } from "../../store/actions";
import { cancelPlanRequests } from "../../store/sagas";

import { MainWrapper, Content, Column, Spinner } from "../../components";
import { useSelector, useDispatch } from "react-redux";

export const CheckoutSuccess = ({}) => {
  const dispatch = useDispatch();
  const session = new URLSearchParams(window.location.search).get("session_id");
  const auth = useSelector((state) => state.auth.data);
  const userSession = useSelector((state) => state.plan.session);

  useEffect(() => {
    if (session) {
      dispatch(planActions.session(session));
    }

    return () => {
      cancelPlanRequests.abort();
    };
  }, []);

  useEffect(() => {
    if (userSession.data?.line_item_name) {
      const plan = userSession.data.line_item_name;
      const planCode = plan.includes("Basic")
        ? "basic"
        : plan.includes("Pro")
        ? "pro"
        : plan.includes("Enterprise")
        ? "enterprise"
        : plan.includes("Disease")
        ? "disease"
        : "free";

      if (auth?.active) {
        window.location.replace(`/preferences?newPlan=${planCode}`);
      } else {
        window.location.replace(
          "/signup?plan=" +
            planCode +
            "&email=" +
            encodeURIComponent(userSession.data.customer_details.email) +
            "&customerId=" +
            userSession.data.customer_id
        );
      }
    }
  }, [userSession]);

  return (
    <MainWrapper>
      <Content>
        <Column center middle>
          <Spinner />
        </Column>
      </Content>
    </MainWrapper>
  );
};
