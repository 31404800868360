import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useInfiniteScroll from "react-infinite-scroll-hook";

import moment from "moment";
import useDebounce from "../../../utils/useDebounce";
import { diseaseActions, settingsActions } from "../../../store/actions";
import { cancelDiseaseRequests } from "../../../store/sagas";
import { getDiseases } from "../../../store/sagas/diseaseSagas";
import { view } from "./DiseasesView";
import { MAX_RESULTS } from "../../../utils/variables";
import { getISOFormattedDateTimeString } from "../../../utils";

export const AdminDiseases = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentDate = moment().add(-1, "days").endOf("day");
  const lastMonth = moment().add(-6, "months").startOf("day");

  const statusTypes = [
    { id: 1, label: "All" },
    { id: 2, label: "Active" },
    { id: 3, label: "Inactive" },
  ];

  // --> mapStateToProp
  const diseases = useSelector((state) => state.disease.all);
  const diseaseSettings = useSelector((state) => state.settings?.disease);
  // <-- mapStateToProp

  // --> STATE
  const [dates, setDates] = useState({
    start:
      diseaseSettings?.dates?.start ||
      getISOFormattedDateTimeString(lastMonth),
    end:
      diseaseSettings?.dates?.end ||
      getISOFormattedDateTimeString(currentDate),
  });
  const [selectedStatus, setSelectedStatus] = useState(
    diseaseSettings?.status || 2
  );

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const debounceTerm = useDebounce(searchTerm, 700);
  // <-- STATE

  // --> EFFECT
  useEffect(() => {
    return () => {
      cancelDiseaseRequests.abort();
    };
  }, []);

  useEffect(() => {
    requestDiseases(0);
    setPage(0);
  }, [dispatch, debounceTerm, selectedStatus, dates]);

  useEffect(() => {
    setLoading(diseases.fetching);
  }, [diseases.fetching]);

  // <-- EFFECT

  const handleStatusChange = (id) => {
    setSelectedStatus(id);
    dispatch(
      settingsActions.set({
        disease: { ...diseaseSettings, status: id },
      })
    );
  };

  const handleDateChange = (e) => {
    const start = getISOFormattedDateTimeString(e.startDate);
    const end = getISOFormattedDateTimeString(e.endDate);
    setDates({
      start,
      end,
    });
    dispatch(
      settingsActions.set({
        disease: {
          ...diseaseSettings,
          dates: {
            start: getISOFormattedDateTimeString(e.startDate),
            end: getISOFormattedDateTimeString(e.endDate),
          },
        },
      })
    );
  };

  const requestDiseases = (pageNum) => {
    let ignore = null;
    switch (selectedStatus) {
      case 2: // active (non-blacklisted & non-whitelisted)
        ignore = false;
        break;
      case 3: // blacklisted
        ignore = true;
        break;
    }
    dispatch(
      diseaseActions.request({
        startDate: dates.start,
        endDate: dates.end,
        ignore,
        pageNum,
        maxResult: MAX_RESULTS,
        ...(searchTerm !== "" && { term: searchTerm }),
        details: true,
      })
    );
  };

  const exportCSV = async () => {
    let ignore = null;
    switch (selectedStatus) {
      case 2: // active (non-blacklisted & non-whitelisted)
        ignore = false;
        break;
      case 3: // blacklisted
        ignore = true;
        break;
    }
    const diseases = await getDiseases({
      startDate: dates.start,
      endDate: dates.end,
      pageNum: 0,
      maxResult: -1,
      ignore,
      ...(searchTerm !== "" && { term: searchTerm }),
      details: true,
    });
    if (diseases.data.length > 0) {
      let csvContent = "";
      // header
      csvContent += `Id, PrimaryName, Merged, Created, Last Modified, Tag Created, Match, Count\n`;
      _.forEach(diseases.data, (item) => {
        csvContent += `"${item.id}",`;
        csvContent += `"${item.primaryName}",`;
        csvContent += `"${item.substitutedTagNames || ''}",`;
        csvContent += `"${item.createdAt}",`;
        csvContent += `"${item.lastModified}",`;
        csvContent += `"${item.tag.createdAt}",`;
        csvContent += `"${item.tag.alwaysMatch ? 'Yes' : 'No'}",`;
        csvContent += `"${item.tag?.count !== -1 ? item.tag.count : 0}",`;
        csvContent += `\n`;
      });
      const url = window.URL.createObjectURL(new Blob([csvContent]));
      const link = document.createElement("a");
      link.href = url;
      link.id = "mw-download-link";
      link.setAttribute(
        "download",
        `Diseases_${moment().format("YYYYMMDDHHmmss")}.csv`
      );
      document.body.appendChild(link);
      link.click();
    }
  };

  const handleMoreItems = () => {
    if (!loading) {
      const newPage = page + 1;
      requestDiseases(newPage);
      setPage(newPage);
    }
  };

  const [infiniteScrollRef] = useInfiniteScroll({
    loading,
    hasNextPage: !diseases.isLastPage,
    onLoadMore: handleMoreItems,
  });

  const getActiveFilters = () => {
    let activeFilters = [];
    if (searchTerm) {
      activeFilters.push(`Search: ${searchTerm}`);
    }
    if (selectedStatus !== 1) {
      activeFilters.push(
        `Status: ${statusTypes.find((a) => selectedStatus === a.id)?.label}`
      );
    }
    return activeFilters;
  };

  return view({
    history,
    diseases: diseases.data || [],
    selectedStatus,
    handleStatusChange,
    statusTypes,
    activeFilters: getActiveFilters(),
    infiniteScrollRef,
    hasNextPage: !diseases.isLastPage,
    loading,
    searchTerm,
    setSearchTerm,

    exportCSV,
    dates,
    handleDateChange,
  });
};
