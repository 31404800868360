import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { cloneDeep } from "lodash";
import useDebounce from "../../utils/useDebounce";

import {
  tweetActions,
  tagActions,
  diseaseActions,
  productActions,
  monitorActions,
  authActions,
} from "../../store/actions";
import {
  cancelProductRequests,
  cancelDiseaseRequests,
  cancelTweetRequests,
  cancelTagRequests,
} from "../../store/sagas";

import { useGetVideo } from "../../store/queries/youtube";
import {
  useGetMediaSummary,
  useRegenerateMediaSummary,
  useGetMediaTakeaways,
} from "../../store/queries/ai";
import { useGetPodcast } from "../../store/queries/podcast";
import { useGetTweet } from "../../store/queries/x";
import { setWebEvent } from "../../store/queries/events";

import { view } from "./TweetView";
import {
  isReady,
  getUrlParams,
  logAnalyticEvent,
  findClosest,
} from "../../utils";
import { MAX_RESULTS } from "../../utils/variables";
import {
  getImpressionByCountry,
  getImpressionByCity,
  getImpressionByInstitution,
} from "../../store/sagas/tweetSagas";

export const Tweet = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation();
  const [queryParams] = useState(getUrlParams(location.search));
  const { data: video } = useGetVideo(id);

  const { data: podcast } = useGetPodcast(id);
  const { data: initialTweet, isSuccess: tweetsSuccess } = useGetTweet(id);
  const videoRef = useRef(null);
  const podcastRef = useRef(null);
  const contentRef = useRef(null);
  const initialTweetReplies = useSelector((state) => state.tweet.replies);
  const replyUrls = useSelector((state) => state.tweet.replyUrls.data);
  const allTags = useSelector((state) => state.tag.all);
  const sentimentTags = useSelector((state) => state.tweet.sentimentTags.data);
  const allDiseases = useSelector((state) => state.disease.all);
  const allProducts = useSelector((state) => state.product.all);
  const user = useSelector((state) => state.auth.data);
  const accountsReplied = useSelector(
    (state) => state.tweet.accountsReplied?.data || []
  );
  const accountsRetweeted = useSelector(
    (state) => state.tweet.accountsRetweeted?.data || []
  );
  const accountsQuoted = useSelector(
    (state) => state.tweet.accountsQuoted?.data || []
  );
  // const impressions = useSelector((state) => state.tweet.impressions?.data);
  const userPlans = useSelector((state) => state.plan.userPlans);
  const userType = user?.preferences?.filter(
    (p) => p.preferenceName === "UserType"
  )?.[0]?.preferenceStringValue;

  const [tweet, setTweet] = useState({});
  const [selectedTags, setSelectedTags] = useState([]);
  const [searchTag, setSearchTag] = useState("");
  const debounceTag = useDebounce(searchTag, 700);
  const [tags, setTags] = useState([]);
  const [pageTags, setPageTags] = useState(0);

  const [selectedDiseases, setSelectedDiseases] = useState([]);
  const [searchDisease, setSearchDisease] = useState("");
  const [diseases, setDiseases] = useState([]);

  const [aiSummary, setAiSummary] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [searchProduct, setSearchProduct] = useState("");
  const debounceProduct = useDebounce(searchProduct, 700);
  const [products, setProducts] = useState([]);
  const [pageProducts, setPageProducts] = useState(0);
  const [tweetFirstImage, setTweetFirstImage] = useState(undefined);
  const [tweetReplies, setTweetReplies] = useState(initialTweetReplies || []);
  const [tabs, setTabs] = useState([
    { id: "analysis", label: "Analysis", visible: false },
    { id: "transcript", label: "Transcript", visible: false },
    { id: "replies", label: "Replies", visible: false },
    {
      id: "tags",
      label: user?.mwRole === "ADMIN" ? "Manage tags" : "Tags",
      visible: true,
    },
  ]);
  const [activeTab, setActiveTab] = useState(undefined);
  const [impressionByCountry, setImpressionByCountry] = useState([]);
  const [impressionByCity, setImpressionByCity] = useState([]);
  const [impressionByInstitution, setImpressionByInstitution] = useState([]);
  const [mentionedProducts, setMentionedProducts] = useState([]);
  const [transcriptProducts, setTranscriptProducts] = useState([]);
  const [showMoreProducts, setShowMoreProducts] = useState(true);

  const [sortTableBy, setSortTableBy] = useState("name");
  const [sortDirection, setSortDirection] = useState("desc");
  const [hcps, setHcps] = useState([]);

  const maxProducts = 7;
  const {
    data: mediaSummary,
    isLoading: isLoadingMediaSummary,
    isSuccess: isMediaSummarySuccess,
  } = useGetMediaSummary(id);
  const {
    data: meidaTakeaways,
    refetch: refetchTakeaways,
    isLoading: isLoadingTakeaways,
    isSuccess: isTakeawaysySuccess,
  } = useGetMediaTakeaways({
    id,
    userType,
    enabled: mediaSummary?.data?.content || aiSummary,
  });
  const {
    mutateAsync: adminMediaSummary,
    isLoading: isAdminLoadingMediaSummary,
    isSuccess: isAdminMediaSummarySuccess,
  } = useRegenerateMediaSummary(id);

  const isAdmin = () => {
    return user?.mwRole === "ADMIN";
  };

  const cookies = useSelector((state) => state.settings.cookies);

  // ///////////////////////////////
  // to be only called in case of admin users
  const requestTags = (pageNum) => {
    if (!isAdmin()) return;
    dispatch(
      tagActions.request({
        meaning: null,
        ignore: false,
        preferred: true,
        maxResult: MAX_RESULTS,
        ...(searchTag !== "" && { term: searchTag }),
        pageNum,
      })
    );
  };

  const requestSentimentTags = () => {
    dispatch(tweetActions.tagSentimentRequest(id));
  };

  const requestDiseases = () => {
    if (!isAdmin()) return;
    dispatch(diseaseActions.request({ maxResult: -1 }));
  };

  const requestProducts = (pageNum) => {
    if (!isAdmin()) return;
    dispatch(
      productActions.request({
        maxResult: MAX_RESULTS,
        ...(searchProduct !== "" && { term: searchProduct }),
        pageNum,
      })
    );
  };
  // ////////////////////////////////

  const populateImpressionByCountry = () => {
    getImpressionByCountry({ id })
      .then((result) => {
        if (result.data.length > 0) {
          const temp = result.data.map((t) => {
            return {
              id: t.countryCode,
              value:
                tweet.numViews > 0 && tweet.numViews < t.count
                  ? tweet.numViews
                  : t.count,
            };
          });
          setImpressionByCountry(temp);
        }
      })
      .catch((error) => console.log(error));
  };

  const populateImpressionByCity = () => {
    getImpressionByCity({ id })
      .then((result) => {
        if (result.data.length > 0) {
          const temp = result.data.map((t) => {
            return {
              id: t.city,
              value:
                tweet.numViews > 0 && tweet.numViews < t.count
                  ? tweet.numViews
                  : t.count,
            };
          });
          setImpressionByCity(temp);
        }
      })
      .catch((error) => console.log(error));
  };

  const populateImpressionByInstitution = () => {
    getImpressionByInstitution({ id })
      .then((result) => {
        if (result.data.length > 0) {
          const temp = result.data.map((t) => {
            return {
              id: t.institution,
              value:
                tweet.numViews > 0 && tweet.numViews < t.count
                  ? tweet.numViews
                  : t.count,
            };
          });
          setImpressionByInstitution(temp);
        }
      })
      .catch((error) => console.log(error));
  };

  const regenerateMediaSummary = async (id) => {
    const resp = await adminMediaSummary(id);
    if (resp.data) {
      setAiSummary(resp.data.content);
    }
  };

  useEffect(() => {
    if (mediaSummary?.data?.content || aiSummary) {
      refetchTakeaways();
    }
  }, [mediaSummary?.data?.content, aiSummary]);

  useEffect(() => {
    requestDiseases();
    dispatch(tweetActions.tweetRepliesRequest(id));
    // get accounts
    dispatch(tweetActions.accountsRepliedRequest({ id }));
    dispatch(tweetActions.accountsRetweetedRequest({ id }));
    dispatch(tweetActions.accountsQuotedRequest({ id }));
    // // get impressions
    // dispatch(tweetActions.impressionsRequest({ id }));
    // get user's monitors
    if (user.active) {
      dispatch(monitorActions.monitorsRequest(user.id));
    }

    if (user?.active) {
      setWebEvent({ userId: user.id, eventType: "POST", objectId: id });
    }

    return () => {
      dispatch(tagActions.clear());
      dispatch(diseaseActions.clear());
      dispatch(productActions.clear());
      // dispatch(tweetActions.clear());

      cancelProductRequests.abort();
      cancelDiseaseRequests.abort();
      cancelTweetRequests.abort();
      cancelTagRequests.abort();
    };
  }, [dispatch]);

  useEffect(() => {
    if (initialTweet?.data) {
      setTweet(initialTweet.data);
    }
  }, [initialTweet?.data]);

  useEffect(() => {
    setActiveTab(tabs.find((tab) => tab.visible)?.id || undefined);
  }, [tabs]);

  useEffect(() => {
    if (initialTweetReplies.data?.length > 0) {
      const ids = initialTweetReplies.data.map((t) => t.externalId);
      dispatch(tweetActions.replyUrlsRequest(ids));

      setTabs((oldTabs) => {
        const newTabs = oldTabs.map((tab) => {
          if (tab.id === "replies") {
            return {
              ...tab,
              label:
                initialTweetReplies.data.length === 1
                  ? "1 Reply"
                  : `${initialTweetReplies.data.length} Replies`,
              visible: true,
            };
          }
          return tab;
        });
        return newTabs;
      });
    }
  }, [initialTweetReplies.data]);

  useEffect(() => {
    if (
      (video?.data?.transcript && video?.data?.transcript !== "\n") ||
      (podcast?.data?.transcript && podcast?.data?.transcript !== "\n")
    ) {
      setTabs((oldTabs) => {
        const newTabs = oldTabs.map((tab) => {
          if (tab.id === "transcript") {
            return {
              ...tab,
              visible: true,
            };
          }
          return tab;
        });
        return newTabs;
      });
    }
  }, [video?.data, podcast?.data]);

  useEffect(() => {
    // let's append urls to tweet replies in case we have new links
    if (replyUrls) {
      initialTweetReplies.data.forEach((tweet) => {
        tweet.urls = replyUrls.urls;
      });
      setTweetReplies(initialTweetReplies);
    }
  }, [replyUrls]);

  useEffect(() => {
    setPageTags(0);
    requestTags(0);
  }, [debounceTag]);

  useEffect(() => {
    setPageProducts(0);
    requestProducts(0);
  }, [debounceProduct]);

  useEffect(() => {
    if (isReady(allTags)) {
      let newTags = allTags.data.map((item) => ({
        ...item,
        label: item.name,
      }));

      if (tweet?.tags?.length > 0) {
        // remove from all tags list
        tweet.tags.forEach((item) => {
          newTags = newTags.filter((tg) => tg.id !== item.id);
        });
      }
      setTags(newTags);
    }
  }, [allTags.data]);

  useEffect(() => {
    if (isReady(allDiseases)) {
      let newDiseases = allDiseases.data.map((item) => ({
        id: item.tagId,
        label: item.primaryName,
      }));

      if (tweet?.diseaseTags?.length > 0) {
        // remove from all diseases list
        tweet.diseaseTags.forEach((item) => {
          newDiseases = newDiseases.filter((tg) => tg.tagId !== item.id);
        });
      }
      setDiseases(newDiseases);
    }
  }, [allDiseases]);

  useEffect(() => {
    if (isReady(allProducts)) {
      let newProducts = allProducts.data.map((item) => ({
        id: item.tagId,
        label: item.primaryName,
      }));

      if (tweet?.productTags?.length > 0) {
        // remove from all products list
        tweet.productTags.forEach((item) => {
          newProducts = newProducts.filter((tg) => tg.tagId !== item.id);
        });
      }
      setProducts(newProducts);
    }
  }, [allProducts.data]);

  useEffect(() => {
    let data = [];
    if (podcast?.data?.timeline?.length > 0) {
      data = podcast?.data;
    }
    if (video?.data?.timeline?.length > 0) {
      data = video?.data;
    }

    if (data?.timeline?.length > 0 && tweet?.productTags?.length > 0) {
      let tempMentions = [];
      let tempTranscript = [];

      tweet.productTags.forEach((tag) => {
        // For Product mentions we need the first one
        const tagTimeline = data.timeline.find((t) =>
          t.content.toLowerCase().includes(tag.name.toLowerCase())
        );
        if (tagTimeline) {
          tempMentions.push({
            name: tag.name,
            start: tagTimeline.start,
            duration: tagTimeline.duration,
          });
        }

        // For Transcript we need all of them
        const allTagTimeline = data.timeline
          .filter((t) =>
            t.content.toLowerCase().includes(tag.name.toLowerCase())
          )
          .map((t) => {
            return {
              name: tag.name,
              start: t.start,
              duration: t.duration,
            };
          });
        if (allTagTimeline) {
          tempTranscript = [...tempTranscript, ...allTagTimeline];
        }
      });
      setMentionedProducts(tempMentions.sort((a, b) => a.start - b.start));
      setTranscriptProducts(tempTranscript);
    }

    if (tweet?.tagAssociations && data?.timeline) {
      const productTags = tweet?.tagAssociations?.filter(
        (tag) => tag.tag.productId
      );
      let allMentions = [];
      let firstMention = [];
      productTags.forEach((tag) => {
        if (tag.mentions?.length > 0 && tag.mentions[0]?.mentionStart) {
          const closest = findClosest(
            data.timeline.map((t) => t.fragmentIdx),
            tag.mentions[0]?.mentionStart
          );
          firstMention.push({
            name: tag.tag.name,
            start: data.timeline.find((t) => t.fragmentIdx === closest)?.start,
          });
        }

        tag.mentions?.forEach((mention) => {
          const closest = findClosest(
            data.timeline.map((t) => t.fragmentIdx),
            mention.mentionStart
          );
          if (mention.mentionStart) {
            allMentions.push({
              name: tag.tag.name,
              startChar: mention.mentionStart,
              endChar: mention.mentionEnd,
              seconds: data.timeline.find((t) => t.fragmentIdx === closest)
                ?.start,
            });
          }
        });
      });

      setTranscriptProducts(allMentions);
      setMentionedProducts(firstMention.sort((a, b) => a.start - b.start));
    }
  }, [tweet, video?.data, podcast?.data]);

  useEffect(() => {
    const hasAnalysis = tabs.find((tab) => tab.id === "analysis").visible;
    if (selectedProducts.length > 0 || selectedDiseases.length > 0) {
      if (!hasAnalysis) {
        setTabs((oldTabs) => {
          const newTabs = oldTabs.map((tab) => {
            if (tab.id === "analysis") {
              return { ...tab, visible: true };
            }
            return tab;
          });
          return newTabs;
        });
      }
    } else {
      if (hasAnalysis) {
        setTabs((oldTabs) => {
          const newTabs = oldTabs.map((tab) => {
            if (tab.id === "analysis") {
              return { ...tab, visible: false };
            }
            return tab;
          });
          return newTabs;
        });
      }
    }
  }, [selectedProducts, selectedDiseases]);

  useEffect(() => {
    if (selectedProducts.length > 0 || selectedDiseases.length > 0) {
      const productMonitors = selectedProducts.map((p) => {
        return {
          monitorComponent: {
            object1Id: p.productId,
            object1Type: "PRODUCT",
            object1Name: p.label,
          },
          visible: true,
        };
      });
      const diseaseMonitors = selectedDiseases.map((d) => {
        return {
          monitorComponent: {
            object1Id: d.diseaseId,
            object1Type: "DISEASE",
            object1Name: d.label,
          },
          visible: true,
        };
      });
      const allMonitors = [...productMonitors, ...diseaseMonitors];
      dispatch(monitorActions.setMonitors(allMonitors));
    }
  }, [selectedProducts, selectedDiseases]);

  useEffect(() => {
    if (tweet?.tags?.length > 0) {
      const stags = tweet.tags.map((item) => ({
        id: item.id,
        label: item.name,
      }));
      setSelectedTags(stags);
    }

    if (tweet?.diseaseTags?.length > 0) {
      const stags = tweet.diseaseTags.map((item) => ({
        id: item.id,
        label: item.name,
        diseaseId: item.diseaseId,
      }));
      setSelectedDiseases(stags);
    }

    if (tweet?.productTags?.length > 0) {
      const stags = tweet.productTags.map((item) => ({
        id: item.id,
        label: item.name,
        productId: item.productId,
      }));
      setSelectedProducts(stags);
    }
    setTweetFirstImage(
      tweet?.medias?.find(
        (t) =>
          t.type === "animated_gif" ||
          t.type === "image" ||
          t.type === "IMAGE" ||
          t.type === "photo" ||
          t.type === "PHOTO"
      )
    );

    if (tweetsSuccess && tweet?.externalId) {
      requestSentimentTags();

      // get impressions by country
      populateImpressionByCountry();

      // get impressions by city
      populateImpressionByCity();

      // get impressions by institution
      populateImpressionByInstitution();

      logAnalyticEvent(
        "Tweet Details",
        {
          externalId: tweet?.externalId,
          postSource: tweet?.source,
          ...(queryParams?.source && { source: queryParams.source }),
        },
        user?.id || queryParams?.u || null,
        user?.displayName || queryParams?.n || null,
        user?.email || queryParams?.e || null,
        user?.createdAt || null,
        user?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
          ?.preferenceStringValue || queryParams?.t || "MEDICAL",
        userPlans?.data?.[0]?.plan?.name || null
      );
      // duplicate events for monitors
      if (queryParams?.source) {
        if (queryParams?.source === "disease") {
          logAnalyticEvent(
            "Disease Monitor - Tweet Details",
            {
              externalId: tweet?.externalId,
              postSource: tweet?.source,
              ...(queryParams?.source && { source: queryParams.source }),
            },
            user?.id || queryParams?.u || null,
            user?.displayName || queryParams?.n || null,
            user?.email || queryParams?.e || null,
            user?.createdAt || null,
            user?.preferences?.filter(
              (p) => p.preferenceName === "UserType"
            )?.[0]?.preferenceStringValue || queryParams?.t || "MEDICAL",
            userPlans?.data?.[0]?.plan?.name || null
          );
        }
        if (queryParams?.source === "product") {
          logAnalyticEvent(
            "Product Monitor - Tweet Details",
            {
              externalId: tweet?.externalId,
              postSource: tweet?.source,
              ...(queryParams?.source && { source: queryParams.source }),
            },
            user?.id || queryParams?.u || null,
            user?.displayName || queryParams?.n || null,
            user?.email || queryParams?.e || null,
            user?.createdAt || null,
            user?.preferences?.filter(
              (p) => p.preferenceName === "UserType"
            )?.[0]?.preferenceStringValue || queryParams?.t || "MEDICAL",
            userPlans?.data?.[0]?.plan?.name || null
          );
        }
        if (queryParams?.source === "conference") {
          logAnalyticEvent(
            "Conference Monitor - Tweet Details",
            {
              externalId: tweet?.externalId,
              postSource: tweet?.source,
              ...(queryParams?.source && { source: queryParams.source }),
            },
            user?.id || queryParams?.u || null,
            user?.displayName || queryParams?.n || null,
            user?.email || queryParams?.e || null,
            user?.createdAt || null,
            user?.preferences?.filter(
              (p) => p.preferenceName === "UserType"
            )?.[0]?.preferenceStringValue || queryParams?.t || "MEDICAL",
            userPlans?.data?.[0]?.plan?.name || null
          );
        }
      }
    }
  }, [tweet]);

  useEffect(() => {
    if (queryParams?.u && !user.id) {
      dispatch(
        authActions.setToken({
          id: queryParams?.u,
          ...(queryParams?.n && { displayName: queryParams.n }),
          ...(queryParams?.e && { email: queryParams.e }),
        })
      );
    }
  }, [queryParams]);

  const handleSearchTag = (e) => {
    setSearchTag(e);
  };

  // add tag association
  const addSelectedTags = (item) => {
    // check if the item already exists
    const exists = selectedTags.filter((t) => t.id === item.id);
    if (exists.length > 0) return;

    const clonedTags = cloneDeep(selectedTags);
    setSelectedTags([...clonedTags, item]);
    dispatch(
      tweetActions.tagCreate({
        tweetId: id,
        tagId: item.id,
      })
    );
  };

  // remove tag association
  const removeSelectedTags = (item) => {
    const clonedTags = cloneDeep(selectedTags);
    setSelectedTags(clonedTags.filter((i) => i.id !== item.id));
    dispatch(
      tweetActions.tagDelete({
        tweetId: id,
        tagId: item.id,
      })
    );
  };

  const handleSearchDisease = (e) => {
    setSearchDisease(e);
  };

  // add disease tag association
  const addSelectedDiseases = (item) => {
    const clonedDiseases = cloneDeep(selectedDiseases);
    setSelectedDiseases([
      ...clonedDiseases,
      { id: item.id, label: item.label },
    ]);
    dispatch(
      tweetActions.tagCreate({
        tweetId: id,
        tagId: item.id,
      })
    );
  };

  // remove disease tag association
  const removeSelectedDiseases = (item) => {
    const clonedDiseases = cloneDeep(selectedDiseases);
    setSelectedDiseases(clonedDiseases.filter((i) => i.id !== item.id));
    dispatch(
      tweetActions.tagDelete({
        tweetId: id,
        tagId: item.id,
      })
    );
  };

  const handleSearchProduct = (e) => {
    setSearchProduct(e);
  };

  // add product tag association
  const addSelectedProducts = (item) => {
    const clonedProducts = cloneDeep(selectedProducts);
    setSelectedProducts([
      ...clonedProducts,
      { id: item.id, label: item.label },
    ]);
    dispatch(
      tweetActions.tagCreate({
        tweetId: id,
        tagId: item.id,
      })
    );
  };

  // remove product tag association
  const removeSelectedProducts = (item) => {
    const clonedProducts = cloneDeep(selectedProducts);
    setSelectedProducts(clonedProducts.filter((i) => i.id !== item.id));
    dispatch(
      tweetActions.tagDelete({
        tweetId: id,
        tagId: item.id,
      })
    );
  };

  const handleSignUpClick = () => {
    // check for cookies first before publishing event
    if (!auth?.active && cookies?.seen && !cookies?.accepted) {
      return;
    }
    // call common helper function
    logAnalyticEvent(
      "Sign Up",
      {
        page: "Tweet Details",
        externalId: tweet?.externalId,
        ...(queryParams?.source && { source: queryParams.source }),
      },
      queryParams?.u || null,
      queryParams?.n || null,
      queryParams?.e || null,
      null,
      queryParams?.t || null,
    );
  };

  const handleMoreTags = () => {
    if (!allTags.fetching) {
      const tagsNewPage = pageTags + 1;
      setPageTags(tagsNewPage);
      requestTags(tagsNewPage);
    }
  };

  const handleMoreProducts = () => {
    if (!allProducts.fetching) {
      const productsNewPage = pageProducts + 1;
      setPageProducts(productsNewPage);
      requestProducts(productsNewPage);
    }
  };

  const playAt = (seconds, tagName) => {
    logAnalyticEvent(
      "Bookmark",
      {
        page: "Tweet Details",
        externalId: tweet?.externalId,
        postSource: tweet?.source,
        ...(queryParams?.source && { source: queryParams.source }),
        tagName: tagName,
        fromSeconds: seconds,
      },
      user?.id || queryParams?.u || null,
      user?.displayName || queryParams?.n || null,
      user?.email || queryParams?.e || null,
      user?.createdAt || null,
      user?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
        ?.preferenceStringValue || queryParams?.t || "MEDICAL",
      userPlans?.data?.[0]?.plan?.name || null
    );
    if (videoRef.current) {
      videoRef.current.internalPlayer.seekTo(seconds < 0 ? 0 : seconds);
    }
    if (podcastRef.current) {
      podcastRef.current.audio.current.currentTime = seconds < 0 ? 0 : seconds;
      podcastRef.current.audio.current.play();
    }
  };

  const handleRegenerateSummagy = () => {
    regenerateMediaSummary(id);
  };

  useEffect(() => {
    const allAccounts = [
      ...accountsReplied.map((a) => ({ ...a, type: "Reply" })),
      ...accountsRetweeted.map((a) => ({ ...a, type: "Repost" })),
      ...accountsQuoted.map((a) => ({ ...a, type: "Quoted" })),
    ];
    if (allAccounts.length === 0) return;

    const sortName = [
      (a) =>
        typeof a[sortTableBy] === "string"
          ? a[sortTableBy]?.toLowerCase()
          : a[sortTableBy],
    ];
    setHcps(
      sortDirection === "desc"
        ? _.sortBy(allAccounts, sortName)
        : _.sortBy(allAccounts, sortName).reverse()
    );
  }, [
    accountsReplied,
    accountsRetweeted,
    accountsQuoted,
    sortTableBy,
    sortDirection,
  ]);

  return view({
    isRegistered: user?.active,
    tweet,
    tweetReplies: tweetReplies?.data || [],
    isAdmin: isAdmin(),
    tweetFirstImage,
    activeTab,
    setActiveTab,
    tabs,

    ytData: video?.data,

    tags,
    addSelectedTags,
    removeSelectedTags,
    searchTag,
    handleSearchTag,
    selectedTags,
    tagsInfinteScroll: {
      loading: allTags.fetching,
      hasNextPage: !allTags.isLastPage,
      onLoadMore: handleMoreTags,
    },

    diseases,
    addSelectedDiseases,
    removeSelectedDiseases,
    searchDisease,
    handleSearchDisease,
    selectedDiseases,

    products,
    addSelectedProducts,
    removeSelectedProducts,
    searchProduct,
    handleSearchProduct,
    selectedProducts,
    productsInfinteScroll: {
      loading: allProducts.fetching,
      hasNextPage: !allProducts.isLastPage,
      onLoadMore: handleMoreProducts,
    },

    accountsReplied,
    accountsRetweeted,
    accountsQuoted,
    allAccounts: hcps,
    sortTableBy,
    setSortTableBy,
    sortDirection,
    setSortDirection,
    // impressions,
    // impressionsPercentage: impressions.analysis?.percentage,
    impressionByCountry,
    impressionByCity,
    impressionByInstitution,

    handleSignUpClick,
    sentimentTags: sentimentTags.filter((s) => s.productId) || [],
    mentionedProducts,
    videoRef,
    podcastRef,
    playAt,
    showMoreProducts,
    setShowMoreProducts,
    maxProducts,
    transcriptProducts,
    contentRef,
    podcast: podcast?.data,
    aiSummary: mediaSummary?.data?.content || aiSummary || "",
    isLoadingSumary: isLoadingMediaSummary || isAdminLoadingMediaSummary,
    isAiSumarySuccess: isMediaSummarySuccess || isAdminMediaSummarySuccess,
    hasAiSummary: isAdmin() || mediaSummary,
    regenerateSummary: handleRegenerateSummagy,
    meidaTakeaways: meidaTakeaways?.data?.takeaways || "",
    isLoadingTakeaways,
    isTakeawaysySuccess,
  });
};
