import React from "react";
import styled from "styled-components";
import { CopyToClipboard } from "react-copy-to-clipboard";

import {
  MainWrapper,
  Content,
  Column,
  Header,
  Row,
  H3,
  H4,
  Menu,
  Label,
  Icon,
  Input,
  IconButton,
  Metadata,
  Button,
  List,
  ListItem,
  Avatar,
  P,
  BlinkResponse,
  Html,
  HR,
  Anchor,
  Toast,
} from "../../components";
import { Color } from "../../utils/variables";

const VContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 70px);
  flex-wrap: nowrap;
  width: 100%;
`;

const HContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  padding-right: 10px;
  padding-bottom: 20px;
  width: 100%;
`;

const Footer = styled.div`
  max-width: 700px;
  width: 100%;
  flex-shrink: 0;
`;

const SubmitWrapper = styled.div`
  position: relative;

  button {
    position: absolute;
    right: 4px;
    top: 4px;
    height: 36px;
    width: 36px;
    background: ${Color("gray", "2")};
    svg {
      fill: ${Color("green", "7")};
    }

    :hover {
      background: ${Color("green", "4")};
    }
  }
`;

const ExampleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  gap: 10px;
  border-radius: 10px;
  background: ${Color("gray", "0.5")};
  cursor: pointer;

  &:hover {
    background: ${Color("gray", "2")};
  }
`;

const Example = ({ item, handleExampleClick }) => (
  <ExampleWrapper onClick={() => handleExampleClick(item.id)}>
    <Label>"{item.text}"</Label>
    <Row noShrink>
      <Icon name="send" color="green" fade="7" />
    </Row>
  </ExampleWrapper>
);

const PreviousChats = ({ item, handleSessionClick }) => (
  <ListItem onClick={() => handleSessionClick(item.sessionId)} hover>
    <Row gap="10" middle truncate>
      <Row noShrink>
        <Icon name="chat" color="gray" fade="7" />
      </Row>
      <Label truncate>{item.question}</Label>
    </Row>
  </ListItem>
);

const Wrapper = styled.div`
  display: flex;
  padding: 20px;
  gap: 20px;
  border-radius: 10px;
  background: ${(props) => (props.bkg ? Color("gray", "0.5") : "none")};
`;

const Question = ({
  item,
  username,
  scrollRef,
  isLast,
  regenerate,
  handleRegenerate,
  handleCopyToClipboard,
  isFetching,
}) => (
  <Column ref={scrollRef}>
    <Row marginTop="20" />
    <Wrapper bkg>
      <Avatar name={username || ""} bkg="purple" fade="5" color="white" />
      <Row marginTop="8">
        <P>{item.question}</P>
      </Row>
    </Wrapper>
    <Wrapper>
      <Avatar name="m" lowercase bkg="green" fade="5" color="white" />
      <Row marginTop="8">
        {regenerate && isLast ? (
          <BlinkResponse />
        ) : (
          <>
            {item.response ? (
              <Html string={item.response} replace />
            ) : (
              <>
                {isFetching ? (
                  <BlinkResponse />
                ) : (
                  <P>
                    Sorry, we could not find an answer for that, please try
                    again later.
                  </P>
                )}
              </>
            )}
          </>
        )}
      </Row>
    </Wrapper>

    <Row marginY="10" marginLeft="75">
      {!regenerate && isLast && (
        <Anchor
          bold
          label="Regenerate response"
          icon="reload"
          onClick={() => handleRegenerate(item)}
        />
      )}
      {item.response && (
        <Row fit right marginRight="10">
          <CopyToClipboard
            text={item.response}
            onCopy={() => handleCopyToClipboard()}
          >
            <Anchor bold label="Copy to clipboard" icon="copy" />
          </CopyToClipboard>
        </Row>
      )}
    </Row>
  </Column>
);

const RightMenu = ({
  body,
  previousChats,
  handleSessionClick,
  handleNewChatClick,
  showAll,
  setShowAll,
}) => {
  // const yesterday = moment().subtract("5", "days").format("DD/MM/YYYY");
  const previousChatsFiltered = showAll
    ? previousChats
    : previousChats
        // ?.filter((a) => moment(a.lastModified).format("DD/MM/YYYY") > yesterday)
        ?.slice(0, 5);
  const displayShowAll = previousChats?.length > 5;

  return (
    <Column
      show={!body ? "laptopL" : ""}
      hide={body ? "laptopL" : ""}
      stretch
      maxWidth={body ? "700" : "300"}
      paddingTop
    >
      <Column gap="20" marginBottom>
        {body && (
          <Column marginTop marginBottom>
            <HR />
          </Column>
        )}
        <Row>
          <Button
            small
            onClick={() => handleNewChatClick()}
            label="New chat"
            icon="add"
            minWidth={180}
          />
        </Row>
        {previousChatsFiltered?.length > 0 && (
          <Column left>
            <H4 bold>My previous chats</H4>
            {displayShowAll && (
              <Row marginTop>
                <Anchor
                  bold
                  label={showAll ? "Show recent" : "Show all"}
                  href="/aiChat"
                  onClick={() => setShowAll(!showAll)}
                />
              </Row>
            )}
            <List marginTop="15" marginLeft="-10">
              {previousChatsFiltered?.map((item) => (
                <PreviousChats
                  key={item.id}
                  {...{ item, handleSessionClick }}
                />
              ))}
            </List>
          </Column>
        )}
      </Column>
    </Column>
  );
};

export const view = ({
  examples,
  handleExampleClick,
  question,
  setQuestion,
  handleSubmitNewChat,
  previousChats,
  handleSessionClick,
  waitForQuestion,
  username,
  data,
  scrollRef,
  showAll,
  setShowAll,
  regenerate,
  handleRegenerate,
  copyText,
  setCopyText,
  showToast,
  setShowToast,
  handleCopyToClipboard,
  handleNewChatClick,
  isFetching,
}) => (
  <MainWrapper drawer className="dashboard">
    <Metadata />

    <Header
      drawer
      login
      user
      // signUpClick={handleSignUpClick}
    />
    <Menu activeTab="aiChat" />
    <Content noScroll>
      <HContainer>
        <VContainer>
          <Body>
            <Column stretch>
              {!data?.[0] && (
                <Column marginTop gap="20">
                  <H3 bold>Examples</H3>
                  <Column stretch gap="20">
                    {examples?.map((item) => (
                      <Example
                        key={item.id}
                        {...{ item, handleExampleClick }}
                      />
                    ))}
                  </Column>
                </Column>
              )}
              {data.length > 0 && (
                <>
                  {data?.map((item, index) => (
                    <Question
                      isLast={
                        !waitForQuestion ? index === data.length - 1 : false
                      }
                      key={item.id}
                      {...{
                        username,
                        item,
                        isFetching,
                        scrollRef,
                        regenerate,
                        handleRegenerate,
                        setShowToast,
                        handleCopyToClipboard,
                      }}
                    />
                  ))}
                </>
              )}
              {waitForQuestion && (
                <Question
                  item={{ question: waitForQuestion }}
                  {...{ username, scrollRef, isFetching }}
                />
              )}
            </Column>
            <RightMenu
              body
              {...{
                previousChats,
                handleSessionClick,
                handleNewChatClick,
                showAll,
                setShowAll,
              }}
            />
          </Body>
          <Footer>
            <Column gap="10" marginBottom="-10" stretch>
              <form onSubmit={handleSubmitNewChat}>
                <SubmitWrapper>
                  <Input
                    small
                    disabled={waitForQuestion}
                    placeholder="Ask away or give me a task..."
                    expand
                    icon="send"
                    reverse
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                  />
                  <IconButton
                    icon="send"
                    type="submit"
                    id="button-send"
                    onClick={handleSubmitNewChat}
                  />
                </SubmitWrapper>
              </form>
              <Row marginLeft>
                <Label>This chat is in experimental phase.</Label>
              </Row>
            </Column>
            <Column stretch maxWidth="300" />
          </Footer>
        </VContainer>
        <RightMenu
          {...{
            previousChats,
            handleSessionClick,
            handleNewChatClick,
            showAll,
            setShowAll,
          }}
        />
      </HContainer>
    </Content>
    <Toast
      visible={showToast}
      toggleVisibility={setShowToast}
      expiration={2000}
    >
      <Row gap="5" middle>
        <Icon name="check" />
        <Label>Text copied!</Label>
      </Row>
    </Toast>
  </MainWrapper>
);
