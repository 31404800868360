import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  Column,
  Row,
  Label,
  H3,
  H4,
  P,
  Icon,
  Footer,
  MainWrapper,
  Metadata,
  Spinner,
  RowToColumn,
  TweetCard,
  ListItem,
  Avatar,
  Anchor,
} from "../../components";
import { formatShortDates } from "../../utils";
import { HeaderAndDrawer, Counter, Share } from "./components";

export const view = ({
  signUpClick,
  isSuccess,
  influencers,
  isLoading,
  tweet,
  isLoadingTweets,
  conference,
}) => {
  return (
    <MainWrapper className="dashboard">
      <Metadata />
      <HeaderAndDrawer {...{ signUpClick }} />
      <Column marginTop="100">
        <Column paddingBottom="100">
          <Column center>
            <Column maxWidth="1200" paddingX>
              <Column>
                <Anchor
                  bold
                  icon="arrowLeft"
                  to="/congress_influencers"
                  label="Back to top congresses"
                />
                <Column gap="10">
                  <H3 bold>
                    Top influencers{" "}
                    {conference?.primaryName && `in ${conference.primaryName}`}
                  </H3>
                  <P color="gray" fade="7">
                    Click an influencer to see more details
                  </P>
                  <Share
                    title={`Top influencers ${
                      conference?.primaryName && `in ${conference.primaryName}`
                    }`}
                  />

                  {conference?.description && (
                    <Column marginTop="10" gap="10">
                      <Label color="gray" fade="7">
                        {conference?.description}
                      </Label>
                      <Row gap="10" middle>
                        <Label bold color="gray" fade="7">
                          {formatShortDates(
                            conference?.startDate,
                            conference?.endDate
                          )}
                        </Label>
                        {conference?.url && (
                          <Anchor
                            bold
                            blank
                            to={conference.url}
                            label={conference.url
                              .replace("http://", "")
                              .replace("https://", "")}
                          />
                        )}
                      </Row>
                    </Column>
                  )}
                </Column>
              </Column>

              <RowToColumn gap="40" columnGap="10">
                <Column fit maxWidth="600">
                  {isLoading && (
                    <Row marginY="40" expand center>
                      <Spinner />
                    </Row>
                  )}

                  {isSuccess && influencers?.length === 0 && (
                    <Row marginY="40" expand center>
                      <Label color="gray" fade="7">
                        No influencers found!
                      </Label>
                    </Row>
                  )}

                  {influencers?.length > 0 && (
                    <Column gap="10" marginTop="40">
                      {influencers?.map((dol, index) => (
                        <Column marginX="-10" key={dol.account.id}>
                          <Link to={`/account/${dol.account.id}`}>
                            <ListItem expand middle hover>
                              <RowToColumn gap="10" middle>
                                <Row fit middle gap="10">
                                  <Counter number={index + 1} />
                                  <Avatar
                                    imageUrl={dol.account.imageURL}
                                    name={dol.account.name}
                                    large
                                  />
                                  <Column gap="5">
                                    <Row gap="10" middle>
                                      <H4 bold>{dol.account.name}</H4>
                                      <Label>@{dol.account.username}</Label>
                                    </Row>
                                    <Label color="gray" fade="7">
                                      {dol.account.institutionName}
                                      {dol.account.institutionName &&
                                        dol.account.country &&
                                        ", "}
                                      {dol.account.country}
                                    </Label>
                                  </Column>
                                </Row>
                                <Label color="gray" fade="7" bold noShrink>
                                  {dol.mentions === 1
                                    ? `${dol.mentions} post`
                                    : `${dol.mentions} posts`}
                                </Label>
                              </RowToColumn>
                            </ListItem>
                          </Link>
                        </Column>
                      ))}
                    </Column>
                  )}
                </Column>
                <Column marginTop="30" maxWidth="560">
                  {isLoadingTweets && (
                    <Row marginY="40" expand center>
                      <Spinner />
                    </Row>
                  )}
                  {tweet && (
                    <Link to={`/post/${tweet.externalId}`}>
                      <TweetCard
                        data={tweet}
                        title={`Top post from ${conference?.primaryName}`}
                        subtitle={
                          tweet?.tAccount?.lastName &&
                          `Congratulations Dr. ${tweet.tAccount.lastName}. Keep up the good work!`
                        }
                      />
                    </Link>
                  )}
                </Column>
              </RowToColumn>
            </Column>
          </Column>
        </Column>
        <Footer />
      </Column>
    </MainWrapper>
  );
};
