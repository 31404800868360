import React from "react";
import { Link } from "react-router-dom";
import { formatMonthDayDates } from "../../../utils";
import { Footer, Subscribe } from "../components";

import {
  Row,
  MainWrapper,
  Header,
  Content,
  Label,
  Html,
  Column,
  Spinner,
  Card,
  TweetCard,
} from "../../../components";

export const view = ({
  nId,
  name,
  highlights,
  loading,
  dates,
  monitor,
  diseaseName,
  tweets,
}) => {
  return (
    <MainWrapper>
      <Header logo login user></Header>
      {nId ? (
        <Content noScroll paddingAll="0">
          <iframe
            style={{
              border: "0",
              height: "1000px",
              width: "100%",
              borderRadius: "10px",
              background: "transparent",
            }}
            src={`${window.location.origin}/notauth/mail/${nId}`}
          />
        </Content>
      ) : (
        <Content>
          <Column center>
            <Column maxWidth="600">
              <Column style={{ verticalAlign: "top" }} marginTop="10">
                <Subscribe />
                <Column marginBottom="30" marginTop="10" stretch gap="20">
                  <Label>Hi{name ? ` ${decodeURIComponent(name)}` : ""},</Label>
                  {tweets?.length > 0 && (
                    <>
                      {diseaseName ? (
                        <Label>
                          Here is your social media report for{" "}
                          {decodeURIComponent(diseaseName.replace(/\+/g, " "))}{" "}
                          from
                          {` ${monitor.primaryName}`},{" "}
                          {formatMonthDayDates(dates)}
                          {`:`}
                        </Label>
                      ) : (
                        <Label>
                          Here are the top posts from physicians in
                          {` ${monitor.primaryName}`},{" "}
                          {formatMonthDayDates(dates)}
                          {`:`}
                        </Label>
                      )}
                    </>
                  )}
                  {/* {dates.end && (
                      <Label bold large>
                        What's new this week
                      </Label>
                    )} */}
                </Column>

                {highlights.map((item) => (
                  <Column marginBottom key={item.id}>
                    {/* <Link to={`/editHighlight/${item.id}`}> */}
                    <Html string={item.content} />
                    {/* </Link> */}
                  </Column>
                ))}

                {tweets.map((t) => {
                  const hasQuote = t?.quoted?.length > 0;

                  return (
                    <Column
                      stretch
                      id={`tweet-${t.externalId}`}
                      key={t.externalId}
                      marginBottom
                    >
                      <Column>
                        <Link to={`/post/${t.externalId}?source=email`}>
                          <Card
                            border={hasQuote}
                            paddingAll={!hasQuote ? "0" : "20"}
                          >
                            <TweetCard data={t} {...{ hasQuote }} />
                            {hasQuote && (
                              <Column marginTop="30">
                                <TweetCard
                                  key={t.quoted[0].externalId}
                                  data={t.quoted[0]}
                                />
                              </Column>
                            )}
                          </Card>
                          {/* {t?.medias?.length > 0 && (
                              <Row marginTop="10">
                                <TweetMedia medias={t.medias} grid />
                              </Row>
                            )}
                            {t?.quoted?.length > 0 && (
                              <Column>
                                <Row flexWrap expand>
                                  {t?.quoted.map((i) => (
                                    <TweetCard
                                      id={`quoted-tweet-${t.externalId}`}
                                      key={i.externalId}
                                      data={i}
                                    />
                                  ))}
                                </Row>
                              </Column>
                          </TweetCard>
                            )} */}
                        </Link>
                      </Column>
                    </Column>
                  );
                })}
                {loading && (
                  <Row expand paddingTop marginBottom="60" center>
                    <Spinner />
                  </Row>
                )}
                {highlights.length === 0 && tweets.length === 0 && !loading && (
                  <Row expand paddingTop paddingBottom="50" center>
                    <Label large color="gray" fade="6">
                      No Highlights found
                    </Label>
                  </Row>
                )}
              </Column>

              <Footer
                first={tweets?.length > 0 ? "Share it with a colleague." : null}
                seeMore={`See ${diseaseName ? "more" : "trending"} insights ${
                  diseaseName ? "from" : "in"
                } ${monitor?.primaryName || ""}.`}
              />
            </Column>
          </Column>
        </Content>
      )}
    </MainWrapper>
  );
};
