import { createAction, createActionTypes } from "../../utils";

export const NOTIFICATION_ACTIONS = createActionTypes("NOTIFICATION_ACTIONS", [
  "REQUEST",
  "SUCCESS",
  "FAILURE",

  "SEND_REQUEST",
  "SEND_SUCCESS",
  "SEND_FAILURE",

  "CLEAR",
]);

export const notificationActions = {
  request: (data) =>
    createAction(NOTIFICATION_ACTIONS.REQUEST, {
      ...data,
      fetching: true,
      success: false,
      error: null,
    }),
  success: ({ data, pageNum }) =>
    createAction(NOTIFICATION_ACTIONS.SUCCESS, {
      data,
      pageNum,
      fetching: false,
      success: true,
      error: null,
    }),
  failure: (error) =>
    createAction(NOTIFICATION_ACTIONS.FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  ////////////////////////////////////////////////////
  clear: () =>
    createAction(NOTIFICATION_ACTIONS.CLEAR, {
      data: [],
      fetching: true,
      success: false,
      error: null,
    }),

  send: (data) =>
    createAction(NOTIFICATION_ACTIONS.SEND_REQUEST, {
      data,
      fetching: true,
      success: false,
      error: null,
    }),
  sendSuccess: ( data) =>
    createAction(NOTIFICATION_ACTIONS.SEND_SUCCESS, {
      data: data,
      fetching: false,
      success: true,
      error: null,
    }),
  sendFailure: (error) =>
    createAction(NOTIFICATION_ACTIONS.SEND_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

};
