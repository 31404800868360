import { createAction, createActionTypes } from "../../utils";

export const AI_ACTIONS = createActionTypes("AI_ACTIONS", [
  "ONE_REQUEST",
  "ONE_SUCCESS",
  "ONE_FAILURE",

  "PRODUCT_REQUEST",
  "PRODUCT_SUCCESS",
  "PRODUCT_FAILURE",

  "DISEASE_REQUEST",
  "DISEASE_SUCCESS",
  "DISEASE_FAILURE",

  "UPDATE",
  "UPDATE_SUCCESS",
  "UPDATE_FAILURE",

  "CHAT",
]);

export const aiActions = {
  oneRequest: (id) =>
    createAction(AI_ACTIONS.ONE_REQUEST, {
      id,
      fetching: true,
      success: false,
      error: null,
    }),
  oneSuccess: (data) =>
    createAction(AI_ACTIONS.ONE_SUCCESS, {
      ...data,
      fetching: false,
      success: true,
      error: null,
    }),
  oneFailure: (error) =>
    createAction(AI_ACTIONS.ONE_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  productRequest: (data) =>
    createAction(AI_ACTIONS.PRODUCT_REQUEST, {
      ...data,
      fetching: true,
      success: false,
      error: null,
    }),
  productSuccess: (data) =>
    createAction(AI_ACTIONS.PRODUCT_SUCCESS, {
      ...data,
      fetching: false,
      success: true,
      error: null,
    }),
  productFailure: (error) =>
    createAction(AI_ACTIONS.PRODUCT_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  diseaseRequest: (data) =>
    createAction(AI_ACTIONS.DISEASE_REQUEST, {
      ...data,
      fetching: true,
      success: false,
      error: null,
    }),
  diseaseSuccess: (data) =>
    createAction(AI_ACTIONS.DISEASE_SUCCESS, {
      ...data,
      fetching: false,
      success: true,
      error: null,
    }),
  diseaseFailure: (error) =>
    createAction(AI_ACTIONS.DISEASE_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  update: (data) =>
    createAction(AI_ACTIONS.UPDATE, {
      ...data,
      fetching: true,
      success: false,
      error: null,
    }),
  updateSuccess: (data) =>
    createAction(AI_ACTIONS.UPDATE_SUCCESS, {
      ...data,
      fetching: false,
      success: true,
      error: null,
    }),
  updateFailure: (error) =>
    createAction(AI_ACTIONS.UPDATE_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  chat: (data) =>
    createAction(AI_ACTIONS.CHAT, {
      data,
    }),
};
