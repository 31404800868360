import React from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Column,
  H3,
  H4,
  MainWrapper,
  Content,
  Button,
  Input,
  Anchor,
  Label,
  Card,
  CardBody,
  Icon,
  P,
} from "../../components";

export const Maintenance = ({}) => (
  <MainWrapper noHeader>
    <Content bkg="gray" fade="1">
      <Column center>
        <Row paddingBottom>
          <H4 bold color="green">
            <Link to="/">Medical.watch</Link>
          </H4>
        </Row>
        <Card medium radius="30" center>
          <Column paddingAll maxWidth="400">
            <H3 center bold>
              Will be back soon
            </H3>
            <Column paddingTop="40" marginBottom left>
              <P>
                We are busying upgrading Medical.watch with even more ways to
                analyze HCP insights from social media.
              </P>
              <P>
                We apologize for the inconvenience and appreciate your patience.
              </P>
              <P>Thank you for using Medical.watch!</P>
            </Column>
          </Column>
        </Card>
      </Column>
    </Content>
  </MainWrapper>
);
