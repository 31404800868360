import React, { useEffect, useRef, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { isEqual, cloneDeep } from "lodash";

import { Color } from "../utils/variables";

export const StackedColumnChart = ({ id, data, handleNameClick }) => {
  const chartRef = useRef(null);
  const legendRef = useRef(null);
  const CHART_ID = id || "StackedColumnChart";
  const LEGEND_ID = CHART_ID + "LEGEND";
  const [previousData, setPreviousData] = useState([]);

  function cornerRadius(radius, item) {
    let dataItem = item.dataItem;

    // Find the last series in this stack
    let lastSeries;
    chartRef.current.series.each(function (series) {
      if (
        dataItem.dataContext[series.dataFields.valueY] &&
        !series.isHidden &&
        !series.isHiding
      ) {
        lastSeries = series;
      }
    });

    // If current series is the one, use rounded corner
    return dataItem.component == lastSeries ? 10 : radius;
  }

  // Add series
  function makeSeries(valueY, categoryX) {
    var series = chartRef.current.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = valueY;
    series.dataFields.categoryX = categoryX;
    series.name = valueY;
    series.tooltipText = "{name}: [bold]{valueY}[/]";
    series.stacked = true;

    // Add label
    var labelBullet = series.bullets.push(new am4charts.LabelBullet());
    labelBullet.label.text = "{valueY}";
    labelBullet.locationY = 0.5;
    labelBullet.label.hideOversized = true;

    // attach an event with a column
    // TODO
    // - should be governed by a flag
    // - should work for one series at a time individually
    series.columns.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;
    series.columns.template.events.on(
      "hit",
      function (ev) {
        handleNameClick?.(ev.target.dataItem.component.name);
      },
      this
    );
  }

  const createChart = () => {
    am4core.useTheme(am4themes_animated);
    am4core.options.commercialLicense = true;

    chartRef.current = am4core.create(CHART_ID, am4charts.XYChart);

    chartRef.current.data = data;

    chartRef.current.colors.list = [
      am4core.color(Color("pink")),
      am4core.color(Color("purple")),
      am4core.color(Color("red")),
      am4core.color(Color("yellow")),
      am4core.color(Color("blue")),
      am4core.color(Color("green")),
    ];

    // Create axes
    var categoryAxis = chartRef.current.xAxes.push(
      new am4charts.CategoryAxis()
    );
    categoryAxis.dataFields.category = "time";
    // categoryAxis.title.text = "Time";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 20;

    var valueAxis = chartRef.current.yAxes.push(new am4charts.ValueAxis());
    // valueAxis.title.text = "Tags";

    // Create series
    const seriesLabel = [];
    data.forEach((item, index) => {
      Object.keys(item).forEach((k) => {
        if (k !== "time" && !seriesLabel.includes(k)) {
          seriesLabel.push(k);
        }
      });
    });

    seriesLabel.forEach((item) => {
      makeSeries(item, "time");
    });

    // Rotating labels
    let label = categoryAxis.renderer.labels.template;
    label.rotation = -45;
    label.fontSize = 11;
    label.horizontalCenter = "right";
    label.verticalCenter = "middle";

    // Add cursor
    chartRef.current.cursor = new am4charts.XYCursor();

    // Disable axis lines
    chartRef.current.cursor.lineX.disabled = true;
    chartRef.current.cursor.lineY.disabled = true;

    // Disable axis tooltips
    categoryAxis.cursorTooltipEnabled = false;
    valueAxis.cursorTooltipEnabled = false;

    // Disable zoom
    chartRef.current.cursor.behavior = "none";

    // Legend
    legendRef.current = am4core.create(LEGEND_ID, am4core.Container);
    legendRef.current.width = am4core.percent(100);
    legendRef.current.height = am4core.percent(100);
    // Legend
    var legend = new am4charts.Legend();
    chartRef.current.legend = legend;
    legend.parent = legendRef.current; // chartRef.current.chartContainer;
    // legend.itemContainers.template.togglable = false;
    legend.marginTop = 20;
    legend.marginBottom = -20;
    legend.scrollable = true;
  };

  useEffect(() => {
    return () => {
      chartRef.current && chartRef.current.dispose();
      legendRef.current && legendRef.current.dispose();
    };
  }, []);

  useEffect(() => {
    // deep compare
    if (!isEqual(data, previousData)) {
      // change state only when there is a change
      chartRef.current && chartRef.current.dispose();
      legendRef.current && legendRef.current.dispose();
      createChart();
      setPreviousData(cloneDeep(data)); // save for next compare
    }
  }, [data]);

  return (
    <div>
      <div
        id={CHART_ID}
        style={{
          width: "100%",
          height: "300px",
          margin: "20px 0 0 0",
        }}
      />
      <div
        id={LEGEND_ID}
        style={{
          width: "100%",
          height: "80px",
          // margin: "20px 0",
        }}
      />
    </div>
  );
};
