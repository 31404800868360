import React, { useState } from "react";
import Table from "rc-table";
import "../../components/styles/rc-table.scss";
import { Link } from "react-router-dom";

import {
  Row,
  Column,
  Toolbar,
  List,
  ListItem,
  Anchor,
  Avatar,
  Circle,
  ExportModal,
} from "../../components";
import { formatNumberWithCommas } from "../../utils";

export const TopHCPs = ({
  source,
  hasSentiment,
  hcps,
  showExport,
  exportCSVForHCPs,
  sortTableBy,
  setSortTableBy,
  sortDirection,
  setSortDirection,
  userType,
}) => {
  const [expotVisibility, toggleExportVisibility] = useState(false);

  const getClassNames = (title) => {
    if (sortTableBy === title) {
      return sortDirection === "asc" ? "sort-asc" : "sort-desc";
    }
    return "";
  };

  const headerActions = (key) => ({
    onClick() {
      if (sortTableBy === key) {
        setSortDirection(sortDirection === "asc" ? "desc" : "asc");
      } else {
        setSortTableBy(key);
        if (key === "score" || key === "mentions") {
          setSortDirection("asc");
        } else {
          setSortDirection("desc");
        }
      }
    },
  });

  const columns = [
    {
      title: "Name",
      key: "name",
      render: (cell) => (
        <Link to={`/account/${cell.id}${source ? "?source=" + source : ""}`}>
          <Row middle gap="10">
            <Avatar small imageUrl={cell.imageURL} name={cell.name} />
            <Anchor label={cell.name} color="black" />
          </Row>
        </Link>
      ),
      className: getClassNames("name"),
      onHeaderCell: (header) => headerActions(header.key),
    },
    {
      title: "Sentiment",
      dataIndex: "sentiment",
      key: "sentiment",
      render: (cell) => (
        <Row middle gap="10">
          {cell === 0 && (
            <>
              <Circle radius="8" bkg="gray" />
              <span>Undecided</span>
            </>
          )}
          {cell > 0 && (
            <>
              <Circle radius="8" bkg="green" />
              <span>Advocate</span>
            </>
          )}
          {cell < 0 && (
            <>
              <Circle radius="8" bkg="red" />
              <span>Detractor</span>
            </>
          )}
        </Row>
      ),
      className: getClassNames("sentiment"),
      onHeaderCell: () => headerActions("sentiment"),
    },
    {
      title: "Influence",
      dataIndex: "influence",
      key: "influence",
      className: getClassNames("score"),
      onHeaderCell: () => headerActions("score"),
    },
    {
      title: "Mentions",
      dataIndex: "mentions",
      key: "mentions",
      className: getClassNames("mentions"),
      onHeaderCell: (header) => headerActions(header.key),
    },
    {
      title: "Audience",
      dataIndex: "audience",
      key: "audience",
      render: (cell) => formatNumberWithCommas(cell),
      className: getClassNames("audience"),
      onHeaderCell: (header) => headerActions(header.key),
    },
    {
      title: "Location",
      // dataIndex: "location",
      key: "location",
      render: (cell) => <>{cell.displayLocation || " - "}</>,
      className: getClassNames("location"),
      onHeaderCell: (header) => headerActions(header.key),
    },
  ];

  if (userType === "OTHER" || userType === "HCP") {
    columns.splice(2, 1);
    columns.splice(3, 1);
  }
  // Diseases should not include sentiment
  if (!hasSentiment) {
    columns.splice(1, 1);
  }

  return (
    <Column>
      {hcps.length > 0 && (
        <>
          <ExportModal
            visible={expotVisibility}
            toggleVisibility={() => toggleExportVisibility(!expotVisibility)}
          />
          {exportCSVForHCPs && (
            <Toolbar>
              <Anchor
                label="Export"
                icon="download"
                onClick={() =>
                  showExport ? exportCSVForHCPs() : toggleExportVisibility(true)
                }
              />
            </Toolbar>
          )}
          <Table
            rowKey="id"
            className="sortable"
            data={hcps}
            {...{ columns }}
            style={{ maxWidth: 980 }}
            scroll={{ x: 978 }}
          />
        </>
      )}
    </Column>
  );
};
