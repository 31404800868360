import React, { useState } from "react";
import styled, { css } from "styled-components";
import useInfiniteScroll from "react-infinite-scroll-hook";

import PropTypes from "prop-types";

import { Row, Column, Circle, HR } from "./Layout";
import { Color } from "../utils/variables";
import { Button } from "./Button";
import { Input } from "./Input";
import { Spinner } from "./Spinner";
import { Label } from "./Typography";

export const List = styled(Column)`
  display: flex;
  width: 100%;

  ${(props) =>
    props.sticky &&
    css`
      & > div > a {
        display: flex;
        padding: 0 5px;
        margin: 0 -10px;

        &.active,
        &:hover {
          background-color: ${Color("gray", "1")};
          border-radius: 5px;
        }
        &.active {
          font-family: "Oxigen-Bold";
        }
      }
    `}

  & > a {
    display: flex;
    padding: 0 10px;
    margin: 0 -10px;

    &.active,
    &:hover {
      background-color: ${Color("gray", "1")};
    }
  }
`;

export const BorderBox = styled(Row)`
  border-top: 1px solid ${Color("gray", "3")};
`;

export const ListItem = styled(Row)`
  width: 100%;
  align-items: center;
  padding: 4px 10px;
  min-height: 42px;

  ${({ select, small }) =>
    select &&
    css`
      background: white;
      ${small
        ? `padding: 10px 20px`
        : `
          padding: 30px 40px;
          border-bottom: 1px solid ${Color("gray", "3")};
      `};
      cursor: pointer;

      &:hover {
        background-color: ${Color("green", "1")};
      }
    `}

  &:hover {
    ${(props) =>
      props.hover &&
      css`
        background-color: ${Color("gray", "1")};
        cursor: pointer;
      `}
  }
`;

export const SelectList = ({
  items,
  onChange,
  excludeItems,
  small,
  searchTerm,
  setSearchTerm,
  filter,
  radio,
  infiniteScroll,
}) => {
  const [filteredResults, setFilteredResults] = useState([]);

  let results = items;
  if (excludeItems?.length > 0) {
    let resultSet = items;
    excludeItems.forEach((item) => {
      resultSet = resultSet.filter((r) => r.label !== item.label);
    });
    results = resultSet;
  }

  const handleChange = (item) => {
    if (radio) {
      let newResults = [];
      results.forEach((r) => {
        r.selected = r.id === item.id;
        newResults.push(r);
      });
      results = newResults;
    }
    onChange(item);
  };

  let infiniteScrollRef;
  if (infiniteScroll) {
    [infiniteScrollRef] = useInfiniteScroll({
      loading: infiniteScroll.loading,
      hasNextPage: infiniteScroll.hasNextPage,
      onLoadMore: infiniteScroll.onLoadMore,
    });
  }

  return (
    <Column fit noScroll>
      {(searchTerm || searchTerm === "") && (
        <Row expand paddingX="15" paddingTop="10" paddingBottom bkg="white">
          <Input
            icon="search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            small
            expand
            placeholder="Search"
          />
        </Row>
      )}
      {filter && (
        <Row expand paddingX="15" paddingTop="10" paddingBottom bkg="white">
          <Input
            icon="search"
            small
            onChange={(e) => setFilteredResults(e.target.value.toLowerCase())}
            expand
            placeholder="Search"
          />
        </Row>
      )}
      <List scroll>
        <Column>
          {results
            ?.filter((r) => r.label.toLowerCase().includes(filteredResults))
            .map((item) => (
              <BorderBox key={item.id}>
                <ListItem
                  small={small}
                  select
                  onClick={() => handleChange(item)}
                >
                  <Row gap="10" expand middle paddingX="10" paddingY="10">
                    {radio && (
                      <Circle radius="9" bkg="gray" fade="7">
                        <Circle radius="8" bkg="white">
                          {item.selected && (
                            <Circle radius="4" bkg="green" fade="6" />
                          )}
                        </Circle>
                      </Circle>
                    )}
                    <Label bold>{item.label}</Label>
                    {!radio && (
                      <Row fit right>
                        <Button small label="Select" />
                      </Row>
                    )}
                  </Row>
                </ListItem>
              </BorderBox>
            ))}
          {(infiniteScroll?.loading || infiniteScroll?.hasNextPage) && (
            <Row expand paddingAll="20" center ref={infiniteScrollRef || null}>
              <Spinner small />
            </Row>
          )}
        </Column>
      </List>
    </Column>
  );
};

SelectList.propTypes = {
  filter: PropTypes.bool,
};
