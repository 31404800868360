import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { TabButton } from "./Button";
import { Row } from "./Layout";
import { Color } from "../utils/variables";

const TabsWrapper = styled(Row)`
  gap: 10px;
  margin: 10px 0 -1px;
  border-bottom: 1px solid ${Color("gray", "3")};
  transition: all 0.3s ease;

  ${(props) =>
    props.card &&
    css`
      margin: -20px -20px 0;
      padding: 0;
    `};
`;

export const Tabs = ({ tabs, onChange, active, card }) => (
  <TabsWrapper left {...{ card }}>
    {tabs
      ?.filter((a) => a.visible !== false)
      ?.map((t) => (
        <TabButton
          key={`tab-${t.id}`}
          label={t.label}
          active={t.id === active}
          onClick={() => onChange?.(t.id)}
        />
      ))}
  </TabsWrapper>
);

Tabs.propTypes = {
  tabs: PropTypes.array,
  onChange: PropTypes.func,
  active: PropTypes.string,
  card: PropTypes.bool,
};
