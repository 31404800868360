import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { view } from "./CongressDetailsView";
import { useParams } from "react-router-dom";
import {
  logAnalyticEvent,
  getUrlParams,
  getISOFormattedDateTimeString,
} from "../../utils";
import { useGetConferenceDols } from "../../store/queries/conference";
import { useConferenceInfluencers } from "../../store/queries/influencers";
import { useGetTweets } from "../../store/queries/tweets";
import moment from "moment";

export const CongressDetails = ({ signUpClick }) => {
  const { id, index } = useParams();
  const auth = useSelector((state) => state.auth.data);

  const { data: conferences, isLoading: loadingConferences } =
    useConferenceInfluencers({});

  const {
    data: influencers,
    isLoading,
    isSuccess,
  } = useGetConferenceDols({
    id,
    startDate: getISOFormattedDateTimeString(moment().startOf("year")),
    endDate: getISOFormattedDateTimeString(
      moment().subtract(1, "day").endOf("day")._d
    ),
    originalOnly: false,
    // includeReplies: false,
    maxResult: 10,
  });

  const { data: tweets, isLoading: isLoadingTweets } = useGetTweets(
    {
      ...(conferences?.data?.[index]?.tagId && {
        addTagIds: [
          conferences.data[index].tagId,
          conferences.data[index].parentTagId,
        ],
      }),
      // externalId: influencers?.data?.[0]?.account?.externalId,
      ...(auth?.id && { userId: auth.id }),
      sortBy: "score",
      sortOrder: "desc",
      maxResult: 1,
      pageNum: 0,
      sortBy: "score",
      sortOrder: "desc",
      includeDiseasesForConference: "true",
      originalOnly: "false",
      includeReplies: "true",
      startDate: getISOFormattedDateTimeString(
        conferences?.data?.[index]?.monitorStartTime
      ),
      endDate: getISOFormattedDateTimeString(
        moment(conferences?.data?.[index]?.monitorEndTime)?.add(7, "day")?._d
      ),
    },
    {
      enabled: !!conferences,
      //&& !!influencers
    }
  );

  useEffect(() => {
    scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Uncomment and update this section when ready to implement analytics
    // const queryParams = getUrlParams();
    // logAnalyticEvent(
    //   "Landing",
    //   {
    //     page: "Home",
    //     section: queryParams.section ? queryParams.section : "marketing",
    //   },
    //   auth?.id || queryParams?.u || null,
    //   auth?.displayName || queryParams?.n || null,
    //   auth?.email || queryParams?.e || null,
    //   auth?.createdAt || null,
    //   auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
    //     ?.preferenceStringValue ||
    //     queryParams?.t ||
    //     "MEDICAL",
    //   null
    // );
  }, []);

  const topInfluencers = influencers?.data?.slice(0, 15);

  return view({
    signUpClick,
    influencers: topInfluencers,
    isLoading,
    isSuccess,
    tweet: tweets?.data?.[0],
    isLoadingTweets,
    conference: conferences?.data?.[index],
  });
};
