import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Circle, Row } from "../components/Layout";
import { H3 } from "../components/Typography";
import { Spinner } from "./Spinner";

const AvatarGroupWrapper = styled(Row)`
  cursor: pointer;

  &:hover {
    .avatarCircle {
      border-color: black;
    }
    .text {
      text-decoration: underline;
    }
  }
`;

export const AvatarGroup = ({ children }) => (
  <AvatarGroupWrapper gap="10" middle>
    {children}
  </AvatarGroupWrapper>
);

export const Avatar = ({
  imageUrl,
  name,
  large,
  lowercase,
  medium,
  bkg,
  fade,
  color,
  border,
  isFetching,
}) => {
  const [image, setImage] = useState(null);

  const missingImage = () => {
    setImage(null);
  };

  useEffect(() => {
    setImage(imageUrl);
  }, [imageUrl]);

  return (
    <Circle
      className="avatarCircle"
      radius={large ? 30 : medium ? 25 : 20}
      bkg="gray"
      fade={fade ? fade : bkg ? "1" : "0.1"}
      {...{ bkg, border }}
    >
      {isFetching ? (
        <Spinner small />
      ) : (
        <>
          {image ? (
            <img
              height={large ? "60px" : medium ? "50px" : "40px"}
              onError={missingImage}
              src={image}
              alt={name || ""}
            />
          ) : (
            <>
              {name && (
                <Row marginTop={lowercase ? "-5" : 0}>
                  <H3 color={color || "gray"} fade={color ? "1" : "5"} bold>
                    {lowercase
                      ? name.split("")[0]
                      : name.split("")[0].toUpperCase()}
                  </H3>
                </Row>
              )}
            </>
          )}
        </>
      )}
    </Circle>
  );
};

Avatar.propTypes = {
  imageUrl: PropTypes.string,
  name: PropTypes.string,
  large: PropTypes.bool,
  bkg: PropTypes.string,
  color: PropTypes.string,
};
