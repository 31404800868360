import { put, call, takeLatest, all } from "redux-saga/effects";
import axios from "axios";

import { getApiPath } from "../../utils/variables";
import { authHeader } from "../store";
import { CHAT_ACTIONS, chatActions } from "../actions";

export let cancelChatRequests = new AbortController();

function* handleRequest() {
  try {
    cancelChatRequests = new AbortController();
    const { data } = yield call(axios.get, `${getApiPath()}/chats`, {
      headers: authHeader(),
      signal: cancelChatRequests.signal,
    });
    yield put(chatActions.success({ data }));
  } catch (e) {
    if (e?.response?.status === 401) {
      yield put((window.location.href = "/logout"));
    }
    yield put(chatActions.failure({ error: { ...e } }));
  }
}

function* handleRequestOne(action) {
  try {
    cancelChatRequests = new AbortController();
    const { id } = action.payload;
    const { data } = yield call(
      axios.get,
      `${getApiPath()}/chat/${id}?${Date.now()}`,
      {
        headers: authHeader(),
        signal: cancelChatRequests.signal,
      }
    );
    yield put(chatActions.oneSuccess({ data }));
  } catch (e) {
    if (e?.response?.status === 401) {
      yield put((window.location.href = "/logout"));
    }
    yield put(chatActions.oneFailure({ error: { ...e } }));
  }
}

/**
 * create a chat
 */
function* handleCreate(action) {
  const body = action.payload.data;
  try {
    cancelChatRequests = new AbortController();
    const { data } = yield call(axios.post, `${getApiPath()}/chat`, body, {
      headers: authHeader(),
      signal: cancelChatRequests.signal,
    });
    yield put(chatActions.oneSuccess({ data }));
  } catch (e) {
    yield put(chatActions.oneFailure({ error: { ...e } }));
  }
}

/**
 * update a chat
 */
function* handleCreateAdditional(action) {
  const body = action.payload.data;
  try {
    cancelChatRequests = new AbortController();
    const { data } = yield call(axios.post, `${getApiPath()}/chat`, body, {
      headers: authHeader(),
      signal: cancelChatRequests.signal,
    });
    yield put(chatActions.additionalSuccess({ data }));
  } catch (e) {
    yield put(chatActions.additionalFailure({ error: { ...e } }));
  }
}

/**
 * update a chat
 */
function* handleUpdate(action) {
  const body = action.payload.data;
  try {
    cancelChatRequests = new AbortController();
    const { data } = yield call(axios.put, `${getApiPath()}/chat`, body, {
      headers: authHeader(),
      signal: cancelChatRequests.signal,
    });
    yield put(chatActions.additionalSuccess({ data }));
  } catch (e) {
    yield put(chatActions.additionalFailure({ error: { ...e } }));
  }
}

function* watchChatSagas() {
  yield all([
    takeLatest(CHAT_ACTIONS.REQUEST, handleRequest),
    takeLatest(CHAT_ACTIONS.ONE_REQUEST, handleRequestOne),
    takeLatest(CHAT_ACTIONS.UPDATE, handleUpdate),
    takeLatest(CHAT_ACTIONS.CREATE, handleCreate),
    takeLatest(CHAT_ACTIONS.CREATE_ADDITIONAL, handleCreateAdditional),
  ]);
}

export default watchChatSagas;
