import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { getApiPath } from "../../utils/variables";
import { authHeader } from "../store";

export function getWebEvents(data) {
  const { userId, eventType, pageNum, maxResult } = data;
  return axios.get(`${getApiPath()}/webEvents`, {
    headers: authHeader(),
    params: {
      userId,
      eventType,
      pageNum,
      maxResult,
    },
  });
}
export function useGetWebEvents(data) {
  return useQuery(["webEvents"], () => getWebEvents(data), {
    cacheTime: 0,
  });
}

export function setWebEvent(data) {
  const { userId, eventType, objectId } = data;
  return axios.post(
    `${getApiPath()}/webEvent`,
    {
      userId,
      eventType,
      objectId,
    },
    { headers: authHeader() }
  );
}
export function useSetWebEvent() {
  return useMutation((data) => setWebEvent(data), {});
}
