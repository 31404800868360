import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { isEmpty, cloneDeep } from "lodash";
import moment from "moment";

import useDebounce from "../../../utils/useDebounce";
import {
  diseaseActions,
  tagActions,
  userActions,
} from "../../../store/actions";
import { getDiseaseMetrics } from "../../../store/sagas/diseaseSagas";
import { Tag_Tag_Relationship } from "../../../utils/constants";
import { view } from "./EditDiseaseView";
import { MAX_RESULTS } from "../../../utils/variables";
import { getISOFormattedDateTimeString } from "../../../utils";

const errorsMessage = {
  name: "Required field",
};

export const EditDisease = () => {
  const dispatch = useDispatch();
  const { id, params } = useParams();
  const isEditMode = params === "editMode";
  const currentDate = moment().add(-1, "days").endOf("day");
  const lastMonth = moment().add(-6, "months").startOf("day");

  const disease = useSelector((state) => state.disease.one.data);
  const usersSubscribed = useSelector((state) => state.user.all.data);
  const initialTags = useSelector((state) => state.tag.all); // all tags in system
  // initial tags which are whitelisted
  const whitelist = useSelector((state) => state.tag.whitelist.data);
  // // initial all tags which are associated
  // const initialAllTagAssociations = useSelector(
  //   (state) => state.tag.tagsAssociated.data
  // );

  const [isSavePressed, setIsSavePressed] = useState(false);
  const [errors, setErrors] = useState({});
  const [deleteModal, toggleDeleteModal] = useState(false);

  // tailored list of mergable tags
  const [mergableTags, setMergableTags] = useState([]);
  // merged tags
  const [mergedTags, setMergedTags] = useState([]);
  const [searchMergableTag, setSearchMergableTag] = useState("");
  const debounceMergableTag = useDebounce(searchMergableTag, 700);
  // associated products
  const [selectedProducts, setSelectedProducts] = useState([]);

  const [pageTags, setPageTags] = useState(0);
  const [metrics, setMetrics] = useState({});
  const [dates, setDates] = useState({
    start: getISOFormattedDateTimeString(lastMonth),
    end: getISOFormattedDateTimeString(currentDate),
  });

  const [data, setData] = useState({
    primaryName: "",
    tag: {
      alwaysMatch: true,
      alwaysIgnore: false,
    },
    meaning: "DISEASE",
    // tagsAssociatedWith: [],
  });

  const requestTags = (pageNum) => {
    if (isEditMode) return; // no need to go further
    dispatch(
      tagActions.request({
        meaning: null,
        ignore: false,
        match: null,
        pageNum: pageNum || 0,
        preferred: true,
        maxResult: MAX_RESULTS,
        includeProducts: true,
        includeDiseases: true,
        ...(searchMergableTag !== "" && { term: searchMergableTag }),
      })
    );
  };

  const requestUsersSubscribed = (id) => {
    if (id) {
      dispatch(
        userActions.request({
          monitorObjectId: id,
          maxResult: -1,
        })
      );
    }
  };

  useEffect(() => {
    if (id) {
      // get disease metrics
      (async () => {
        const temp = await getDiseaseMetrics({
          id: id,
          startDate: dates.start,
          endDate: dates.end,
        });
        setMetrics(temp?.data || {});
      })();
    }
  }, [dates]);

  useEffect(() => {
    if (disease && id) {
      setData({
        id: disease?.id,
        primaryName: disease?.primaryName,
        tag: disease?.tag,
        // tagsAssociatedWith: disease?.tagsAssociatedWith || [],
      });
    }
  }, [disease]);

  useEffect(() => {
    if (id) {
      // get the disease
      dispatch(diseaseActions.oneRequest(id, true));
      // fetch users subscribed to disease
      requestUsersSubscribed(id);
    }

    return () => {
      dispatch(diseaseActions.clear());
      dispatch(tagActions.clearTags());
      dispatch(userActions.clear());
    };
  }, [dispatch]);

  useEffect(() => {
    checkErrors();
  }, [data]);

  useEffect(() => {
    if (data.tag?.id) {
      updateTags();
    }
  }, [data.tag?.id]);

  useEffect(() => {
    if (initialTags?.data?.length > 0) {
      let newTags = initialTags.data.map((item) => ({
        ...item,
        label: item.name,
      }));
      // exclude self, because now we also fetch disease tags
      newTags = newTags.filter((k) => k.id !== data.tag?.id);

      // list for mergable drop down
      let finalTags = cloneDeep(newTags);
      // remove blacklisted
      // finalTags = finalTags.filter((k) => !k.alwaysIgnore);
      // exclude tags which are substitutes to others
      finalTags = finalTags.filter((k) => !k.substitutedIds);
      // exclude tags which are whitelisted
      finalTags = finalTags.filter((k) => !k.preferredTagId);
      // exclude tags which are products
      finalTags = finalTags.filter((k) => !k.productId);
      // exclude tags which are diseases
      finalTags = finalTags.filter((k) => !k.diseaseId);
      // console.log("after => ", finalTags)
      setMergableTags(finalTags);
    }
  }, [initialTags]);

  useEffect(() => {
    if (whitelist?.length > 0) {
      const whitelistedTags = whitelist.map((tag) => ({
        ...tag,
        label: tag.name,
      }));
      setMergedTags(whitelistedTags);
    }
  }, [whitelist]);

  // useEffect(() => {
  //   const initialSelectedProducts =
  //     initialAllTagAssociations?.[Tag_Tag_Relationship.MEDICATION];

  //   // if there is initial selection
  //   if (initialSelectedProducts?.length > 0) {
  //     const sproducts = initialSelectedProducts.map((assoc) => ({
  //       id: assoc.toTagId,
  //       label: assoc.toTag.name,
  //     }));
  //     setSelectedProducts(sproducts);
  //   }
  // }, [initialAllTagAssociations]);

  useEffect(() => {
    setPageTags(0);
    requestTags(0);
  }, [debounceMergableTag]);

  const checkErrors = () => {
    let newErrors = {};
    for (const item in errorsMessage) {
      if (data[item] === "") {
        newErrors[item] = errorsMessage[item];
      } else {
        delete newErrors[item];
      }
    }
    setErrors(newErrors);
  };

  // add merged (aka substituted) tags
  const addMergedTags = (item) => {
    const clonedTags = cloneDeep(mergedTags);
    setMergedTags([...clonedTags, item]);
    dispatch(
      tagActions.whitelistUpdate({ id: item.id, preferredTagId: data.tag.id })
    );

    setData((oldData) => ({
      ...oldData,
      alwaysMatch: true,
    }));
  };

  // remove merged (aka substituted) tags
  const removeMergedTags = (item) => {
    const clonedTags = cloneDeep(mergedTags);
    setMergedTags(clonedTags.filter((i) => i.id !== item.id));

    dispatch(tagActions.whitelistUpdate({ id: item.id, preferredTagId: null }));
  };

  const handleSearchMergableTag = (e) => {
    setSearchMergableTag(e);
  };

  const updateTags = () => {
    // get all tags which are merged or substituted
    dispatch(tagActions.whitelistRequest({
      ids: [data.tag.id],
      nocache: true,
    }));
    // // get all tags associated
    // dispatch(tagActions.tagAssociationsRequest({ id: data.tag.id }));
  };

  const handleChange = ({ value, field }) => {
    setData((oldData) => ({
      ...oldData,
      [field]: value,
    }));

    setErrors((oldError) => ({
      ...oldError,
      [field]: value === "",
    }));
  };

  // save new disease
  const handleSave = () => {
    setIsSavePressed(true);
    if (isEmpty(errors)) {
      const newData = {
        ...data,
        alwaysMatch: true, // always exact match
      };
      dispatch(diseaseActions.create(newData));
    }
  };

  // delete disease
  const handleDelete = () => {
    dispatch(tagActions.delete({ id: data?.tag.id, type: "diseases" }));
  };

  // update disease
  const handleNameOnBlur = () => {
    if (id && isEmpty(errors)) {
      dispatch(diseaseActions.update(data));
    }
  };

  // inactive / active
  const handleSwitchChange = ({ value, field }) => {
    const newData = {
      ...data,
      tag: {
        ...data.tag,
        [field]: value,
      },
    };
    setData(newData);
    id && dispatch(diseaseActions.update(newData));
  };

  const handleMoreTags = () => {
    if (!initialTags.fetching) {
      const tagsNewPage = pageTags + 1;
      setPageTags(tagsNewPage);
      requestTags(tagsNewPage);
    }
  };

  const handleDateChange = (e) => {
    const start = getISOFormattedDateTimeString(e.startDate);
    const end = getISOFormattedDateTimeString(e.endDate);
    setDates({
      start,
      end,
    });
  };

  return view({
    isEditMode,
    errors,
    handleSave,
    data,
    handleChange,
    handleSwitchChange,
    isSavePressed,
    isNew: !id,
    deleteModal,
    toggleDeleteModal,
    handleDelete,
    handleNameOnBlur,

    selectedProducts,
    dates,
    handleDateChange,
    metrics,
    usersSubscribed,

    mergableTags, // tags which can be merged
    mergedTags, // tags which are already merged
    addMergedTags,
    removeMergedTags,
    searchMergableTag,
    handleSearchMergableTag,

    tagsInfinteScroll: {
      loading: initialTags.fetching,
      hasNextPage: !initialTags.isLastPage,
    },
    handleMoreTags,
  });
};
