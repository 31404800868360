import React from "react";
import moment from "moment";
import "../../../components/styles/rc-table.scss";

import {
  Row,
  Column,
  MainWrapper,
  Header,
  Content,
  Card,
  Label,
  Toolbar,
  Filters,
  Dropdown,
  DateRange,
  Spinner,
  Switch,
  Grid,
  Anchor,
} from "../../../components";
import { LineChart } from "../../../components/LineChart";
import { StackedColumnChart } from "../../../components/StackedColumnChart";
import { Menu } from "../Menu";

export const view = ({
  activeFilters,

  allLoading,
  allSummary,

  chartTopTweetsLoading,
  topTweetsSummary,

  chartTopTweetsConfLoading,
  topTweetsConfSummary,

  chartTopInfluencersLoading,
  topInfluencersSummary,

  chartTopBrandsLoading,
  topBrandsSummary,

  chartTopArticlesLoading,
  topArticlesSummary,

  chartProductDigestLoading,
  productDigestSummary,

  eventTypes,
  selectedNotificationEventType,
  handleNotificationEventTypeChange,

  excludeEmployees,
  setExcludeEmployees,

  exportEventCSV,

  totalsInChart,
  setTotalsInChart,

  uniqueUsers,
  setUniqueUsers,

  rate,
  setRate,

  chartTypes,
  chartType,
  handleChartTypeChange,

  dates,
  handleDateChange,
}) => {
  return (
    <MainWrapper drawer>
      <Header
        drawer
        rightContent={
          <>
            <Row fit />
            {/* <ResponsiveButton label="Add Tag" to="/newTag" /> */}
          </>
        }
      />
      <Menu active="notificationEvents" />

      <Content>
        <Column>
          <Column marginBottom>
            <Filters
              {...{ activeFilters }}
              datePicker={
                <Row gap="10" middle>
                  <Label>From </Label>
                  <DateRange
                    noBackground
                    period
                    initialDate={dates.start}
                    endDate={dates.end}
                    onChange={handleDateChange}
                    noFutureDates
                  />
                </Row>
              }
            >
              <Grid columns={3}>
                <Column gap="10">
                  <Label bold>Type</Label>
                  <Dropdown
                    expand
                    right
                    select
                    value={selectedNotificationEventType}
                    onChange={handleNotificationEventTypeChange}
                    items={eventTypes}
                  />
                </Column>
                <Column marginTop="10">
                  <Label bold>Exclude Employees</Label>
                  <Switch
                    checked={excludeEmployees}
                    onChange={setExcludeEmployees}
                  />
                </Column>
              </Grid>
            </Filters>
          </Column>

          <Toolbar>
            {dates.start && (
              <Label small color="gray" fade="5">
                {moment(dates.start).utcOffset(0, true).format("M/D/YYYY")}
                {" - "}
                {moment(dates.end).utcOffset(0, true).format("M/D/YYYY")}
              </Label>
            )}
          </Toolbar>

          {(topTweetsSummary.length > 0 ||
            topTweetsConfSummary.length > 0 ||
            topInfluencersSummary.length > 0 ||
            productDigestSummary.length > 0) && (
            <Row paddingBottom gap="70" right>
              <Switch
                label="Show Totals"
                right={true}
                checked={totalsInChart}
                onChange={setTotalsInChart}
              />
              <Switch
                label="Show Uniques"
                right={true}
                checked={uniqueUsers}
                onChange={setUniqueUsers}
              />
              <Switch
                label="Show Rates"
                right={true}
                checked={rate}
                onChange={setRate}
              />
              {/* <Dropdown
                id="dropdown-chart-types"
                right
                select
                value={chartType}
                label="Chart"
                items={chartTypes}
                onChange={(e) => handleChartTypeChange(e)}
              /> */}
              <Anchor
                bold
                label="Export Events"
                icon="download"
                onClick={() => exportEventCSV()}
              />
            </Row>
          )}

          <Column>
            {allSummary.length > 0 ? (
              <>
                <Label bold>All</Label>
                <Card border marginTop="10">
                  {chartType === 1 && (
                    <LineChart
                      id={`lineChart_0`}
                      data={allSummary}
                      handleNameClick={() => {}}
                    />
                  )}
                  {chartType === 2 && (
                    <StackedColumnChart
                      id={`stackedChart_0`}
                      data={allSummary}
                      handleNameClick={() => {}}
                    />
                  )}
                </Card>
              </>
            ) : (
              <>
                {allLoading && (
                  <Row expand paddingAll="80" center>
                    <Spinner />
                  </Row>
                )}
              </>
            )}
          </Column>

          <Column marginTop="30">
            {topTweetsSummary.length > 0 ? (
              <>
                <Label bold>Top Posts</Label>
                <Card border marginTop="10">
                  {chartType === 1 && (
                    <LineChart
                      id={`lineChart_1`}
                      data={topTweetsSummary}
                      handleNameClick={() => {}}
                    />
                  )}
                  {chartType === 2 && (
                    <StackedColumnChart
                      id={`stackedChart_1`}
                      data={topTweetsSummary}
                      handleNameClick={() => {}}
                    />
                  )}
                </Card>
              </>
            ) : (
              <>
                {chartTopTweetsLoading && (
                  <Row expand paddingAll="80" center>
                    <Spinner />
                  </Row>
                )}
              </>
            )}
          </Column>

          <Column marginTop="30">
            {topTweetsConfSummary.length > 0 ? (
              <>
                <Label bold>Top Posts Congress</Label>
                <Card border marginTop="10">
                  {chartType === 1 && (
                    <LineChart
                      id={`lineChart_4`}
                      data={topTweetsConfSummary}
                      handleNameClick={() => {}}
                    />
                  )}
                  {chartType === 2 && (
                    <StackedColumnChart
                      id={`stackedChart_4`}
                      data={topTweetsConfSummary}
                      handleNameClick={() => {}}
                    />
                  )}
                </Card>
              </>
            ) : (
              <>
                {chartTopTweetsConfLoading && (
                  <Row expand paddingAll="80" center>
                    <Spinner />
                  </Row>
                )}
              </>
            )}
          </Column>

          <Column marginTop="30">
            {topInfluencersSummary.length > 0 ? (
              <>
                <Label bold>Top Influencers</Label>
                <Card border marginTop="10">
                  {chartType === 1 && (
                    <LineChart
                      id={`lineChart_2`}
                      data={topInfluencersSummary}
                      handleNameClick={() => {}}
                    />
                  )}
                  {chartType === 2 && (
                    <StackedColumnChart
                      id={`stackedChart_2`}
                      data={topInfluencersSummary}
                      handleNameClick={() => {}}
                    />
                  )}
                </Card>
              </>
            ) : (
              <>
                {chartTopInfluencersLoading && (
                  <Row expand paddingAll="80" center>
                    <Spinner />
                  </Row>
                )}
              </>
            )}
          </Column>

          <Column marginTop="30">
            {topBrandsSummary.length > 0 ? (
              <>
                <Label bold>Top Brands</Label>
                <Card border marginTop="10">
                  {chartType === 1 && (
                    <LineChart
                      id={`lineChart_3`}
                      data={topBrandsSummary}
                      handleNameClick={() => {}}
                    />
                  )}
                  {chartType === 2 && (
                    <StackedColumnChart
                      id={`stackedChart_3`}
                      data={topBrandsSummary}
                      handleNameClick={() => {}}
                    />
                  )}
                </Card>
              </>
            ) : (
              <>
                {chartTopBrandsLoading && (
                  <Row expand paddingAll="80" center>
                    <Spinner />
                  </Row>
                )}
              </>
            )}
          </Column>

          <Column marginTop="30">
            {topArticlesSummary.length > 0 ? (
              <>
                <Label bold>Top Articles</Label>
                <Card border marginTop="10">
                  {chartType === 1 && (
                    <LineChart
                      id={`lineChart_6`}
                      data={topArticlesSummary}
                      handleNameClick={() => {}}
                    />
                  )}
                  {chartType === 2 && (
                    <StackedColumnChart
                      id={`stackedChart_6`}
                      data={topArticlesSummary}
                      handleNameClick={() => {}}
                    />
                  )}
                </Card>
              </>
            ) : (
              <>
                {chartTopArticlesLoading && (
                  <Row expand paddingAll="80" center>
                    <Spinner />
                  </Row>
                )}
              </>
            )}
          </Column>

          <Column marginTop="30">
            {productDigestSummary.length > 0 ? (
              <>
                <Label bold>Product Alert Digest</Label>
                <Card border marginTop="10">
                  {chartType === 1 && (
                    <LineChart
                      id={`lineChart_5`}
                      data={productDigestSummary}
                      handleNameClick={() => {}}
                    />
                  )}
                  {chartType === 2 && (
                    <StackedColumnChart
                      id={`stackedChart_5`}
                      data={productDigestSummary}
                      handleNameClick={() => {}}
                    />
                  )}
                </Card>
              </>
            ) : (
              <>
                {chartProductDigestLoading && (
                  <Row expand paddingAll="80" center>
                    <Spinner />
                  </Row>
                )}
              </>
            )}
          </Column>
        </Column>
      </Content>
    </MainWrapper>
  );
};
