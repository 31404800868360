import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useInfiniteScroll from "react-infinite-scroll-hook";

import moment from "moment";

import useDebounce from "../../../utils/useDebounce";
import { highlightActions, settingsActions } from "../../../store/actions";
import { cancelHighlightRequests } from "../../../store/sagas";
import { view } from "./HighlightsView";
import { getISOFormattedDateTimeString } from "../../../utils";

export const AdminHighlights = () => {
  const dispatch = useDispatch();
  const currentDate = moment().endOf("day");
  const lastMonth = moment().add(-2, "months").add(1, "days").startOf("day");
  const objectTypes = [
    { id: "ALL", label: "All" },
    { id: "DISEASE", label: "Disease" },
    { id: "CONFERENCE", label: "Congress" },
  ];

  // --> mapStateToProp
  const user = useSelector((state) => state.auth.data);
  const highlights = useSelector((state) => state.highlight.all);
  const highlightsSettings = useSelector((state) => state.settings?.highlights);
  const isAdmin = user?.mwRole === "ADMIN";
  // <-- mapStateToProp

  // --> STATE
  const [dates, setDates] = useState({
    start:
      highlightsSettings?.dates?.start ||
      getISOFormattedDateTimeString(lastMonth),
    end:
      highlightsSettings?.dates?.end ||
      getISOFormattedDateTimeString(currentDate),
  });
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const debounceTerm = useDebounce(searchTerm, 700);
  const [objectNameTerm, setObjectNameTerm] = useState(
    highlightsSettings?.objectNameTerm || ""
  );
  const debounceObjectNameTerm = useDebounce(objectNameTerm, 700);
  const [selectedObjectType, setSelectedObjectType] = useState(
    highlightsSettings?.objectType || "ALL"
  );
  // <-- STATE

  // --> EFFECT
  useEffect(() => {
    return () => {
      cancelHighlightRequests.abort();
    };
  }, []);

  useEffect(() => {
    requestHighlights(0);
    setPage(0);
  }, [
    dispatch,
    debounceTerm,
    debounceObjectNameTerm,
    selectedObjectType,
    dates,
  ]);

  useEffect(() => {
    setLoading(highlights.fetching);
  }, [highlights.fetching]);
  // <-- EFFECT

  const requestHighlights = (pageNum) => {
    dispatch(
      highlightActions.request({
        pageNum,
        startDate: dates.start,
        endDate: dates.end,
        ...(selectedObjectType !== "ALL" && { objectType: selectedObjectType }),
        ...(searchTerm !== "" && { term: searchTerm }),
        ...(objectNameTerm !== "" && { objectNameTerm: objectNameTerm }),
      })
    );
  };

  const handleMoreItems = () => {
    if (!loading) {
      const newPage = page + 1;
      requestHighlights(newPage);
      setPage(newPage);
    }
  };

  const [infiniteScrollRef] = useInfiniteScroll({
    loading,
    hasNextPage: !highlights.isLastPage,
    onLoadMore: handleMoreItems,
  });

  const handleObjectTypeChange = (e) => {
    setSelectedObjectType(e.id);
    dispatch(
      settingsActions.set({
        highlights: { ...highlightsSettings, objectType: e.id },
      })
    );
  };

  const handleDateChange = (e) => {
    const start = getISOFormattedDateTimeString(e.startDate);
    const end = getISOFormattedDateTimeString(e.endDate);
    setDates({
      start,
      end,
    });
    dispatch(
      settingsActions.set({
        highlights: {
          ...highlightsSettings,
          dates: {
            start: getISOFormattedDateTimeString(e.startDate),
            end: getISOFormattedDateTimeString(e.endDate),
          },
        },
      })
    );
  };

  const handleSearchObjectNameTermChange = (e) => {
    if (!_.isEmpty(e)) {
      setObjectNameTerm(_.trim(e));
    } else {
      setObjectNameTerm(null);
    }
    dispatch(
      settingsActions.set({
        highlights: { ...highlightsSettings, objectNameTerm: _.trim(e) },
      })
    );
  };

  const getActiveFilters = () => {
    let activeFilters = [];
    if (objectNameTerm) {
      activeFilters.push(`Monitor: ${objectNameTerm}`);
    }
    if (selectedObjectType !== "ALL") {
      activeFilters.push(
        `Type: ${objectTypes.find((a) => selectedObjectType === a.id)?.label}`
      );
    }
    return activeFilters;
  };

  return view({
    highlights: highlights.data || [],
    infiniteScrollRef,
    hasNextPage: !highlights.isLastPage,
    loading,
    isAdmin,
    // searchTerm,
    // setSearchTerm,
    activeFilters: getActiveFilters(),

    objectTypes,
    selectedObjectType,
    handleObjectTypeChange,

    dates,
    handleDateChange,

    objectNameTerm,
    handleSearchObjectNameTermChange,
  });
};
