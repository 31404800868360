import React from "react";
import moment from "moment";
import Table from "rc-table";
import "../../../components/styles/rc-table.scss";
import { Link } from "react-router-dom";

import {
  Row,
  Column,
  MainWrapper,
  Header,
  Content,
  Card,
  Label,
  Anchor,
  Toolbar,
  Filters,
  Dropdown,
  DateRange,
  AutoComplete,
  Spinner,
  Switch,
  Grid,
  Html,
  ResponsiveButton,
} from "../../../components";
import { Menu } from "../Menu";

export const view = ({
  history,
  loading,
  notificationMessages,
  activeFilters,

  userTypes,
  userType,
  handleUserTypeChange,

  notificationTypes,
  notificationType,
  handleNotificationTypeChange,

  notificationContentTypes,
  notificationContentType,
  handleNotificationContentTypeChange,

  dates,
  handleDateChange,
}) => {
  const columns = [
    {
      title: " ",
      key: "action",
      render: (cell) => (
        <>
          <Link key={cell.id} to={`/editNotificationMessage/${cell.id}`}>
            <Anchor
              bold
              // label="Edit"
              icon="edit"
              onClick={() => {} }
              />
          </Link>
        </>
      ),
      align: "center",
    },
    {
      title: "Email Type",
      key: "notificationContentType",
      render: (cell) => (
        <Column>
          <Row middle>
            <Label style={{ whiteSpace: "normal" }} color={cell.active?"black":"red"}>
              {notificationContentTypes.find(m => m.id === cell.notificationContentType)?.label || " - "}
            </Label>
          </Row>
          {/* <Row middle>
            <Label style={{ whiteSpace: "normal" }}>
            <Label>{notificationTypes.find(k => k.id === cell.notificationType)?.label}</Label>
            </Label>
          </Row> */}
        </Column>
      ),
      align: "center",
    },
    // {
    //   title: "Notification Type",
    //   key: "notificationType",
    //   render: (cell) => (
    //     <Label>{notificationTypes.find(k => k.id === cell.notificationType)?.label}</Label>
    //   ),
    // },
    {
      title: "User Type",
      key: "userType",
      render: (cell) => (
        <Label style={{ whiteSpace: "normal" }} color={cell.active?"black":"red"}>
          {userTypes.find(k => k.id === cell.userType)?.label || " - "}
        </Label>
      ),
      align: "center",
    },
    {
      title: "Content",
      key: "content",
      render: (cell) => (
        <>
          <div style={{width:"450px", whiteSpace: "normal" }}>
          <Html string={cell.content} />
          </div>
          {/* <Label style={{ whiteSpace: "normal" }} color={cell.active?"black":"red"}>
            {cell?.content || "-"}
          </Label> */}
        </>
      ),
      width: 450,
      // ellipsis: true,
    },
    {
      title: "Start",
      key: "startDate",
      render: (cell) => (
        <Label color={cell.active?"black":"red"}>
          {new Date(cell.startDate).toLocaleDateString("en-US")}
        </Label>
      ),
    },
    {
      title: "End",
      key: "endDate",
      render: (cell) => (
        <Label color={cell.active?"black":"red"}>
          {new Date(cell.endDate).toLocaleDateString("en-US")}
        </Label>
      ),
    },
    // {
    //   title: "Created",
    //   key: "created",
    //   render: (cell) => (
    //     <Label style={{ whiteSpace: "normal" }}>
    //       {new Date(cell.createdAt).toLocaleString("en-US")}
    //     </Label>
    //   ),
    // },
    {
      title: "Last Modified",
      key: "lastmodified",
      render: (cell) => (
        <Label style={{ whiteSpace: "normal" }} color={cell.active?"black":"red"}>
          {new Date(cell.lastModified).toLocaleString("en-US")}
        </Label>
      ),
    },
  ];

  return (
    <MainWrapper drawer>
      <Header
        drawer
        rightContent={
          <>
            <Row fit />
            <ResponsiveButton label="Add" to="/newNotificationMessage" />
          </>
        }
      />
      <Menu active="notificationMessages" />

      <Content>
        <Column>
          <Column marginBottom>
            <Filters
              {...{ activeFilters }}
              datePicker={
                <Row gap="10" middle>
                  <Label>From </Label>
                  <DateRange
                    noBackground
                    period
                    initialDate={dates.start}
                    endDate={dates.end}
                    onChange={handleDateChange}
                    noFutureDates
                  />
                </Row>
              }
            >
              <Grid columns={3}>
                <Column gap="10">
                  <Label bold>Email Type</Label>
                  <Dropdown
                    expand
                    right
                    select
                    value={notificationContentType}
                    onChange={handleNotificationContentTypeChange}
                    items={notificationContentTypes}
                  />
                </Column>
                {/* <Column gap="10">
                  <Label bold>Occurrence</Label>
                  <Dropdown
                    expand
                    right
                    select
                    value={notificationType}
                    onChange={handleNotificationTypeChange}
                    items={notificationTypes}
                  />
                </Column> */}
                <Column gap="10">
                  <Label bold>User Type</Label>
                  <Dropdown
                    expand
                    right
                    select
                    value={userType}
                    onChange={handleUserTypeChange}
                    items={userTypes}
                  />
                </Column>
              </Grid>
            </Filters>
          </Column>

          {/* <Toolbar>
            {dates.start && (
              <Label small color="gray" fade="5">
                {moment(dates.start).utcOffset(0, true).format("M/D/YYYY")}
                {" - "}
                {moment(dates.end).utcOffset(0, true).format("M/D/YYYY")}
              </Label>
            )}
          </Toolbar> */}

          <Column marginTop="20">
            <Table
              rowKey="id"
              data={notificationMessages}
              {...{ columns }}
              style={{ maxWidth: 980 }}
              scroll={{ x: 978 }}
            />
            {loading ? (
              <Row expand paddingAll="40" center>
                <Spinner />
              </Row>
            ) : (
              <>
                {/* {notificationMessages?.length === 0 && (
                  <Row marginTop>
                    <Label>No email highlights found</Label>
                  </Row>
                )} */}
              </>
            )}
          </Column>
        </Column>
      </Content>
    </MainWrapper>
  );
};
