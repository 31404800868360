import { useState, useEffect } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";

import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import _ from "lodash";
import zipcelx from "zipcelx";

import { getRandomColor, MAX_RESULTS } from "../../utils/variables";
import { setWebEvent } from "../../store/queries/events";
import { productActions, settingsActions } from "../../store/actions";
import {
  getProductTweets,
  getProductPowerPoint,
} from "../../store/sagas/productSagas";
import {
  logAnalyticEvent,
  getISOFormattedDateTimeString,
  formatNumberWithCommas,
} from "../../utils";
import { view } from "./ProductView";

export const Product = ({ props }) => {
  const {
    homeSettings,
    auth,
    isRegistered,
    handleSignUpClick,
    noFetching,
    contentRef,
    downloadRef,
    handleScroll,
    monitors,
    selectedMonitor,
    setSelectedMonitor,
    dates,
    handleDateChange,
    highlights,
    highlightsLoading,
    tags,
    searchTag,
    selectedTags,
    handleSearchTag,
    addSelectedTag,
    removeSelectedTag,
    clearTag,
    tagsInfinteScroll,

    regions,
    selectedRegion,
    setSelectedRegion,
    handleRegionChange,
    regionName,

    clearFilters,
    handleInfluencersChartClick,
    allKeywords,
    mainKeywords,
    sortBy,
    handleSortByChange,
    filterTip,
    closeFilterTip,
    urlTab,
    activeTab,
    handleActiveTab,
    clearSection,
    showCalendar,
    setShowCalendar,
    whitelist,
    showExport,
    showFilter,
    filterVisibility,
    toggleFilterVisibility,
    showToast,
    toggleToast,
  } = props;

  const tabs = [
    { id: "tweets", label: "Posts" },
    { id: "influencers", label: "Top Influencers" },
  ];

  const influencerTypes = [
    { id: "all", label: "All" },
    { id: "high", label: "High" },
    { id: "medium", label: "Medium" },
    { id: "low", label: "Low" },
  ];

  const sentimentTypes = [
    { id: "all", label: "All" },
    { id: "advocate", label: "Advocate" },
    // { id: "undecided", label: "Undecided" },
    { id: "detractor", label: "Detractor" },
  ];

  // const influencerTabs = [
  //   { id: "advocates", label: "Top Advocates" },
  //   // { id: "tweeters", label: "Top Tweeters" },
  // ];

  const dispatch = useDispatch();

  // --> mapStateToProp
  const product = useSelector((state) => state.product.one);
  const productTags = useSelector((state) => state.product.tags);
  const AIResponse = useSelector((state) => state.product.gpt);
  const aiSettings = useSelector((state) => state.settings?.ai);
  const productTweets = useSelector((state) => state.product.tweets);
  const initialHcps = useSelector((state) => state.product.dol.data);
  const loadingHcps = useSelector((state) => state.product.dol.fetching);
  const hcpsReady = useSelector((state) => state.product.dol.success);

  const isTweetsCountLoading = useSelector(
    (state) => state.product.tweetsCount.fetching
  );
  const isTweetsCountReady = useSelector(
    (state) => state.product.tweetsCount.success
  );
  const productTweetsCount = useSelector(
    (state) => state.product.tweetsCount.data
  );
  const userPlans = useSelector((state) => state.plan.userPlans);
  // <-- mapStateToProp

  const loading = productTweets.fetching;
  const isReady = productTweets.success;

  // --> STATE
  // const [influencerTab, setInfluencerTab] = useState(
  //   homeSettings.influencersTab?.[selectedMonitor.id] || "advocates"
  // );
  const [isNewAI, setIsNewAI] = useState(false);
  const [refreshAI, setRefreshAI] = useState(false);
  const [pageProductTweets, setPageProductTweets] = useState(0);
  const [tweetsCounter, setTweetsCounter] = useState(0);
  const [hcps, setHcps] = useState([]);
  const [hcpsChartData, setHcpsChartData] = useState([]);
  // const [selectedHcp, setSelectedHcp] = useState(
  //   homeSettings.hcpName?.[selectedMonitor.id] || "all"
  // );
  const [sortTableBy, setSortTableBy] = useState("sentiment");
  const [sortDirection, setSortDirection] = useState("asc");
  const [selectedInfluence, setSelectedInfluence] = useState(
    homeSettings.influence?.[selectedMonitor.id] || "all"
  );
  const [selectedSentiment, setSelectedSentiment] = useState(
    homeSettings.sentiment?.[selectedMonitor.id] || "all"
  );

  const [errorMessage, setErrorMessage] = useState("");
  const [visible, toggleVisibility] = useState(false);
  const [error, setError] = useState(false);
  // <-- STATE

  const cookies = useSelector((state) => state.settings.cookies);

  const getUserType = () => {
    return auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]?.preferenceStringValue || "MEDICAL";
  };

  useEffect(() => {
    handleActiveTab(
      urlTab || homeSettings.activeTabs?.[selectedMonitor.id] || "tweets"
    );
  }, []);

  useEffect(() => {
    if (AIResponse.data?.response) {
      let responseTags = [];

      for (const key in AIResponse.data.tweetTagMap) {
        const kws = AIResponse.data.tweetTagMap[key]?.map((t) => ({
          id: t.id,
          name: t.name,
        }));
        responseTags.push(kws);
      }

      setIsNewAI(true);
      setRefreshAI(false);
      dispatch(
        settingsActions.set({
          ai: {
            ...aiSettings,
            [selectedMonitor.id]: {
              response: AIResponse.data.response,
              keywords: responseTags.flat(),
              timestamp: getISOFormattedDateTimeString(moment()),
              startDate: dates.start,
              endDate: dates.end,
              tags: selectedTags?.map((t) => t.id),
              sortBy,
              regionName,
            },
          },
        })
      );
    }
  }, [AIResponse.data]);

  const hasAISettingsChanged = () => {
    let hasChanged = false;
    const aiMonitor = aiSettings?.[selectedMonitor.id];
    if (aiMonitor) {
      const hasTagsChanged =
        aiMonitor.tags?.sort()?.join(",") !==
        selectedTags
          ?.map((t) => t.id)
          ?.sort()
          ?.join(",");

      const hasDatesChanged =
        moment(aiMonitor.startDate).format("YYYY-MM-DD") !==
          moment(dates.start).format("YYYY-MM-DD") ||
        moment(aiMonitor.endDate).format("YYYY-MM-DD") !==
          moment(dates.end).format("YYYY-MM-DD");
      const hasSortByChanged = aiMonitor.sortBy !== sortBy;
      const tempStoredRegionName = aiMonitor.regionName || [];
      const tempRegionName = regionName || [];
      const hasRegionChanged =
        tempStoredRegionName.toString() !== tempRegionName.toString();

      hasChanged =
        hasDatesChanged ||
        hasTagsChanged ||
        hasSortByChanged ||
        hasRegionChanged;
    }
    return hasChanged;
  };

  useEffect(() => {
    setRefreshAI(hasAISettingsChanged());
  }, [dates, selectedTags, sortBy, regionName]);

  useEffect(() => {
    if (isRegistered) {
      let hasChanged = true;
      const aiMonitor = aiSettings?.[selectedMonitor.id];
      if (aiMonitor) {
        const hasResponse = aiMonitor.response;
        let hasExpired = false;
        if (aiMonitor.timestamp) {
          hasExpired =
            getISOFormattedDateTimeString(moment()) >
            getISOFormattedDateTimeString(
              moment(aiMonitor.timestamp).add(12, "hours")
            );
        }
        hasChanged = !hasResponse || hasExpired;
      }

      if (hasChanged) {
        requestGptQuery();
      }
    }
  }, [aiSettings]);

  useEffect(() => {
    const isDownload = window.location.href.includes("section=download");
    if (hcps.length > 0 && isDownload && downloadRef.current) {
      contentRef.current.scrollTo(0, downloadRef.current.clientHeight - 80);
      exportCSVForHCPs();
      clearSection();
    }
  }, [hcps, downloadRef.current]);

  useEffect(() => {
    if (selectedMonitor?.name && !selectedMonitor?.id) {
      dispatch(
        productActions.tagsRequest({
          ignore: false,
          term: selectedMonitor.name,
        })
      );
    }
    if (selectedMonitor?.id && !selectedMonitor?.name) {
      // fetch details: We allways need to fetch that one as it's cleared
      // after pressing back in a tweet
      dispatch(productActions.oneRequest(selectedMonitor.id));
      // setSelectedHcp(homeSettings.hcpName?.[selectedMonitor.id] || "all")
      setSelectedInfluence(
        homeSettings.influence?.[selectedMonitor.id] || "all"
      );
      setSelectedSentiment(
        homeSettings.sentiment?.[selectedMonitor.id] || "all"
      );
    }
  }, [selectedMonitor]);

  useEffect(() => {
    if (auth?.active && selectedMonitor?.id) {
      setWebEvent({
        userId: auth.id,
        eventType:
          activeTab === "tweets" ? "PRODUCT_POSTS" : "PRODUCT_INFLUENCERS",
        objectId: selectedMonitor.id,
      });
    }
  }, [activeTab]);

  useEffect(() => {
    if (productTags?.data.length > 0) {
      const tempId = productTags?.data?.find(
        (k) => k.name?.toLowerCase() === selectedMonitor.name?.toLowerCase()
      )?.productId;
      if (tempId) {
        dispatch(productActions.oneRequest(tempId));
        setSelectedInfluence(homeSettings.influence?.[tempId] || "all");
        setSelectedSentiment(homeSettings.sentiment?.[tempId] || "all");
        setSelectedMonitor({ ...selectedMonitor, id: tempId });
      }
    }
  }, [productTags]);

  useEffect(() => {
    if (
      !product.fetching &&
      !_.isEmpty(product.data) &&
      selectedMonitor?.id === product.data.id
    ) {
      let event = "Dashboard - " + selectedMonitor.type;
      if (activeTab) {
        const t = tabs.filter((t) => t.id === activeTab);
        if (t.length > 0) event = "Product Monitor - " + t[0].label;
      }
      logEvent(event);
    }
  }, [product.data]);

  useEffect(() => {
    if (noFetching.current && activeTab === "influencers") {
      return;
    }
    if (
      noFetching.current &&
      activeTab === "tweets" &&
      productTweets.data.length > 0
    ) {
      return;
    }
    if (selectedMonitor?.id && selectedTags && regionName !== null) {
      const startDate = dates.start;
      const endDate = dates.end;
      setPageProductTweets(0);
      switch (activeTab) {
        case "influencers":
          requestProductDol(startDate, endDate);
          break;
        case "tweets":
          requestProductTweets(0);
          requestProductTweetsCount(0);
          break;
      }
    }
  }, [selectedMonitor?.id, dates, selectedTags, activeTab, regionName]);

  useEffect(() => {
    setTweetsCounter(productTweetsCount);
  }, [productTweetsCount]);

  useEffect(() => {
    let people = initialHcps.filter(
      (hcp) => (hcp.account.accountType = "PERSON")
    );

    switch (selectedInfluence) {
      case "high":
        people = people.filter((p) => p.dolScore > 666);
        break;

      case "medium":
        people = people.filter((p) => p.dolScore <= 666 && p.dolScore >= 333);
        break;

      case "low":
        people = people.filter((p) => p.dolScore < 333);
        break;
    }

    switch (selectedSentiment) {
      case "advocate":
        people = people.filter((p) => p.sentiment > 0);
        break;
      case "undecided":
        people = people.filter((p) => p.sentiment === 0);
        break;
      case "detractor":
        people = people.filter((p) => p.sentiment < 0);
        break;
    }

    const tempHcps = people.map((hcp) => {
      return {
        ...hcp.account,
        label: hcp.account.name,
        mentions: hcp.mentions,
        score: hcp.dolScore,
        sentiment: hcp.sentiment,
        audience: hcp.account.numFollowers,
        influence:
          hcp.dolScore > 666 ? "High" : hcp.dolScore > 333 ? "Medium" : "Low",
      };
    });
    let tempHcpsChart = people.map((hcp) => {
      return {
        ...hcp.account,
        x: hcp.sentiment,
        y: hcp.dolScore,
        // y: influencerTab === "advocates" ? hcp.dolScore : hcp.mentions,
        bullet: hcp.account.imageURL,
        value: 0,
        color: getRandomColor(),
      };
    });

    const sortName = [
      (a) =>
        typeof a[sortTableBy] === "string"
          ? a[sortTableBy]?.toLowerCase()
          : a[sortTableBy],
    ];
    setHcps(
      sortDirection === "desc"
        ? _.sortBy(tempHcps, sortName)
        : _.sortBy(tempHcps, sortName).reverse()
    );

    setHcpsChartData(tempHcpsChart);
  }, [
    initialHcps,
    sortTableBy,
    sortDirection,
    selectedInfluence,
    selectedSentiment,
    // influencerTab,
  ]);

  const requestProductDol = (startDate, endDate) => {
    if (loadingHcps) return;
    const selected = monitors.filter((m) => m.id === selectedMonitor.id)?.[0];
    dispatch(
      productActions.dolRequest({
        id: selectedMonitor.id,
        addTagIds: selectedTags?.map((t) => t.id) || [],
        ...(auth?.id &&
          selected?.monitorRules?.length > 0 && { userId: auth.id }),
        startDate,
        endDate,
        ...(regionName && regionName.length > 0 && { regionName }),
      })
    );
  };

  const requestProductTweets = (pageNum, sort) => {
    if (loading || !selectedMonitor.id) return;
    const selected = monitors.filter((m) => m.id === selectedMonitor.id)?.[0];
    dispatch(
      productActions.tweetsRequest({
        id: selectedMonitor.id,
        addTagIds: selectedTags?.map((t) => t.id) || [],
        ...(auth?.id &&
          selected?.monitorRules?.length > 0 && { userId: auth.id }),
        startDate: dates.start,
        endDate: dates.end,
        sortBy: sort || sortBy,
        pageNum,
        maxResult: MAX_RESULTS,
        ...(regionName && regionName.length > 0 && { regionName }),
      })
    );
  };

  const requestProductTweetsCount = (pageNum) => {
    if (isTweetsCountLoading || !selectedMonitor.id) return;
    // get count only for page 1
    if (pageNum === 0) {
      const selected = monitors.filter((m) => m.id === selectedMonitor.id)?.[0];
      dispatch(
        productActions.tweetsCountRequest({
          id: selectedMonitor.id,
          addTagIds: selectedTags?.map((t) => t.id) || [],
          ...(auth?.id &&
            selected?.monitorRules?.length > 0 && { userId: auth.id }),
          startDate: dates.start,
          endDate: dates.end,
          ...(regionName && regionName.length > 0 && { regionName }),
        })
      );
    }
  };

  const tabOnChange = (id) => {
    handleActiveTab(id);
    contentRef.current.scrollTo(0, 0);
    dispatch(
      settingsActions.set({
        home: {
          ...homeSettings,
          activeTabs: {
            ...homeSettings.activeTabs,
            [selectedMonitor.id]: id,
          },
        },
      })
    );
    // log event
    const event = id === "tweets" ? "Posts" : "Top Influencers";
    logEvent("Product Monitor - " + event, [
      {
        name: "activeTab",
        value: id,
      },
    ]);
  };

  const handleMoreProductTweets = () => {
    if (!loading) {
      const newPage = pageProductTweets + 1;
      requestProductTweets(newPage);
      requestProductTweetsCount(newPage);
      setPageProductTweets(newPage);
    }
  };

  const [productTweetsRef] = useInfiniteScroll({
    loading,
    hasNextPage: !productTweets.isLastPage,
    onLoadMore: handleMoreProductTweets,
  });

  const handleClearFilters = () => {
    if (activeTab === "influencers") {
      // setSelectedHcp("all");
      setSelectedInfluence("all");
      setSelectedSentiment("all");
      setSelectedRegion([]);
      setRegionName([]);
      dispatch(
        settingsActions.set({
          home: {
            ...homeSettings,
            hcpName: {
              ...homeSettings.hcpName,
              [selectedMonitor.id]: "all",
            },
            influence: {
              ...homeSettings.influence,
              [selectedMonitor.id]: "all",
            },
            sentiment: {
              ...homeSettings.sentiment,
              [selectedMonitor.id]: "all",
            },
            regions: {
              ...homeSettings.regions,
              [selectedMonitor.id]: [],
            },
          },
        })
      );
    } else {
      clearFilters();
    }

    // log event
    logEvent("Clear Filters");
  };

  // const handleSelectedHcp = (e) => {
  //   setSelectedHcp(e.id);
  //   dispatch(
  //     settingsActions.set({
  //       home: {
  //         ...homeSettings,
  //         hcpName: {
  //           ...homeSettings.hcpName,
  //           [selectedMonitor.id]: e.id,
  //         },
  //       },
  //     })
  //   );
  // };

  const handleSelectedInfluence = (e) => {
    setSelectedInfluence(e.id);
    dispatch(
      settingsActions.set({
        home: {
          ...homeSettings,
          influence: {
            ...homeSettings.influence,
            [selectedMonitor.id]: e.id,
          },
        },
      })
    );

    // log event
    logEvent("Influence Change", [
      {
        name: "influence",
        value: e.id,
      },
    ]);
  };

  const handleSelectedSentiment = (e) => {
    setSelectedSentiment(e.id);
    dispatch(
      settingsActions.set({
        home: {
          ...homeSettings,
          sentiment: {
            ...homeSettings.sentiment,
            [selectedMonitor.id]: e.id,
          },
        },
      })
    );

    // log event
    logEvent("Sentiment Change", [
      {
        name: "sentiment",
        value: e.id,
      },
    ]);
  };

  // const handleInfluencersTab = (e) => {
  //   setInfluencerTab(e);
  //   dispatch(
  //     settingsActions.set({
  //       home: {
  //         ...homeSettings,
  //         influencersTab: {
  //           ...homeSettings.influencersTab,
  //           [selectedMonitor.id]: e,
  //         },
  //       },
  //     })
  //   );
  // };

  const handleDateChangeInt = (e) => {
    handleDateChange(e);
    // log event
    const period =
      e.selectedPeriod === "1"
        ? "Last 7 Days"
        : e.selectedPeriod === "2"
        ? "Last 30 Days"
        : "Custom";
    // log event
    logEvent(`Date Change - ${period}`, [
      {
        name: "startDate",
        value: getISOFormattedDateTimeString(e.startDate),
      },
      {
        name: "endDate",
        value: getISOFormattedDateTimeString(e.endDate),
      },
    ]);
  };

  const exportCSVForTweets = async (isXlsx) => {
    if (selectedMonitor.id) {
      // check for time period, should not be more than 30 days
      const start = moment(dates.start);
      const end = moment(dates.end);
      const diff = end.diff(start, "days");
      if (diff > 31) {
        setErrorMessage(
          "Only 30 days of data can be exported at a time. Please adjust your date range or contact support@medical.watch for assistance."
        );
        toggleVisibility(true);
        setError(true);
        return;
      }

      // get all tweets for the period
      const selected = monitors.filter((m) => m.id === selectedMonitor.id)?.[0];
      const tweets = await getProductTweets({
        id: selectedMonitor.id,
        addTagIds: selectedTags?.map((t) => t.id) || [],
        ...(auth?.id &&
          selected?.monitorRules?.length > 0 && { userId: auth.id }),
        startDate: dates.start,
        endDate: dates.end,
        sortBy: sortBy,
        pageNum: 0,
        maxResult: -1,
        ...(regionName && regionName.length > 0 && { regionName }),
      });
      if (tweets.data?.length > 0) {
        if (isXlsx) {
          let xlsxContent = [];
          const s = "string";
          const n = "number";
          xlsxContent.push([
            { value: "Date", type: s },
            { value: "Content", type: s },
            { value: "Source", type: s },
            { value: "Sentiment", type: s },
            { value: "Name", type: s },
            { value: "Handle", type: s },
            { value: "Location", type: s },
            { value: "Country", type: s },
            { value: "Link", type: s },
            { value: "Tags", type: s },
            { value: "Replies", type: s },
            { value: "Reposts", type: s },
            { value: "Likes", type: s },
            { value: "Views", type: s },
          ]);
          _.forEach(tweets.data, (post) => {
            xlsxContent.push([
              {
                value: `${moment(post.externalCreatedAt)
                  .utc()
                  .format("MMM D, YYYY")}`,
                type: s,
              },
              {
                value: `${post.content
                  ?.replace(/(\n)/g, " ")
                  ?.replace(/(\")/g, "'")}`,
                type: s,
              },
              { value: `${post.source || "-"}`, type: s },
              { value: `${post.sentiment || "-"}`, type: n },
              { value: `${post.tAccount.name}`, type: s },
              { value: `${post.tAccount.username}`, type: s },
              { value: `${post.tAccount.displayLocation || ""}`, type: s },
              { value: `${post.tAccount.country || ""}`, type: s },
              {
                value: `${window.location.origin}/post/${post.externalId}`,
                type: s,
              },
              { value: `${post.tagNames || ""}`, type: s },
              { value: `${post.numReplies}`, type: n },
              { value: `${post.numRetweets}`, type: n },
              { value: `${post.numLikes}`, type: n },
              { value: `${post.numViews}`, type: n },
            ]);
          });
          const config = {
            filename: `Posts_${moment().format("YYYYMMDDHHmmss")}`,
            sheet: {
              data: xlsxContent,
            },
          };

          zipcelx(config);
          return;
        }

        let csvContent = "";
        // header
        csvContent += `Date, Content, Source, Sentiment, Name, Handle, Location, Link, Tags, Replies, Reposts, Likes, Views\n`;
        _.forEach(tweets.data, (post) => {
          csvContent += `"${moment(post.externalCreatedAt)
            .utc()
            .format("MMM D, YYYY")}",`;
          csvContent += `"${post.content
            ?.replace(/(\n)/g, " ")
            ?.replace(/(\")/g, "'")}",`;
          csvContent += `"${post.source || "-"}",`;
          csvContent += `"${post.sentiment || "-"}",`;
          csvContent += `"${post.tAccount.name}",`;
          csvContent += `"${post.tAccount.username}",`;
          csvContent += `"${post.tAccount.displayLocation || ""}",`;
          csvContent += `"${window.location.origin}/post/${post.externalId}",`;
          csvContent += `"${post.tagNames || ""}",`;
          csvContent += `"${post.numReplies}",`;
          csvContent += `"${post.numRetweets}",`;
          csvContent += `"${post.numLikes}",`;
          csvContent += `"${post.numViews}",`;
          csvContent += `\n`;
        });
        const url = window.URL.createObjectURL(new Blob([csvContent]));
        const link = document.createElement("a");
        link.href = url;
        link.id = "mw-download-link";
        link.setAttribute(
          "download",
          `Posts_${moment().format("YYYYMMDDHHmmss")}.csv`
        );
        document.body.appendChild(link);
        link.click();
      }

      // log event
      logEvent("Export Posts");
    }
  };

  const exportCSVForHCPs = () => {
    let csvContent = "";
    // header
    csvContent += `Name, Influence, Mentions, Audience, Location, Country\n`;
    _.forEach(hcps, (hcp) => {
      csvContent += `"${hcp.name}",`;
      csvContent += `${hcp.influence}, ${hcp.mentions}, ${hcp.audience},`;
      csvContent += `"${hcp.displayLocation || ""}",`;
      csvContent += `"${hcp.country || ""}"`;
      csvContent += `\n`;
    });
    const url = window.URL.createObjectURL(new Blob([csvContent]));
    const link = document.createElement("a");
    link.href = url;
    link.id = "mw-download-link";
    link.setAttribute(
      "download",
      `HCPs_${moment().format("YYYYMMDDHHmmss")}.csv`
    );
    document.body.appendChild(link);
    link.click();

    // log event
    logEvent("Export");
  };

  const [pptInProgress, setPptInProgress] = useState(false);
  const exportPptReport = async (params) => {
    const { maskAllHCPNames, maskNonUSHCPNames } = params;
    if (pptInProgress) return;
    setPptInProgress(true);
    getProductPowerPoint({
      id: selectedMonitor.id,
      addTagIds: selectedTags?.map((t) => t.id) || [],
      source: "X",
      userType: getUserType(),
      startDate: dates.start,
      endDate: dates.end,
      ...(regionName && regionName.length > 0 && { regionName }),
      ...(maskAllHCPNames && { maskAllHCPNames: true }),
      ...(maskNonUSHCPNames && { maskNonUSHCPNames: true }),
      sortBy: sortBy,
      pageNum: 0,
      maxResult: MAX_RESULTS,
    })
      .then((response) => {
        setPptInProgress(false);
        console.log(
          "response from report service ==> ",
          response.status
          // response.config,
          // response.headers,
        );
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.id = "mw-download-link";
        link.setAttribute("download", `mw_${selectedMonitor.id}_.pptx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        setPptInProgress(false);
        console.log(error);
      });
  };

  const sortByOnChange = (item) => {
    handleSortByChange(item);
    setPageProductTweets(0);
    requestProductTweets(0, item.id);
    requestProductTweetsCount(0);

    // log event
    const event =
      item.id === "score" ? "Sort by most influential" : "Sort by newest";
    logEvent(event);
  };

  const getCounter = () => {
    let counter;
    if (activeTab === "tweets" && isTweetsCountReady) {
      counter = `${formatNumberWithCommas(tweetsCounter)} posts`;
    }
    if (activeTab === "influencers" && hcpsReady) {
      counter = `${formatNumberWithCommas(hcps.length)} physicians`;
    }
    return counter;
  };

  const getActiveFilters = () => {
    let activeFilters = [];
    if (selectedTags?.length > 0) {
      activeFilters = selectedTags.map((a) => a.label);
    }
    if (regionName?.length > 0) {
      activeFilters = regions
        .filter((r) => regionName.includes(r.id))
        .map((a) => a.label);
    }
    if (selectedInfluence !== "all") {
      activeFilters.push(
        `Influence: ${
          influencerTypes.find((a) => selectedInfluence === a.id)?.label
        }`
      );
    }
    if (selectedSentiment !== "all") {
      activeFilters.push(
        `Sentiment: ${
          sentimentTypes.find((a) => selectedSentiment === a.id)?.label
        }`
      );
    }
    return activeFilters;
  };

  const handleAiRefresh = () => {
    dispatch(
      settingsActions.set({
        ai: {
          ...aiSettings,
          [selectedMonitor.id]: "",
        },
      })
    );

    // log event
    logEvent("Refresh");
  };

  const requestGptQuery = () => {
    if (selectedMonitor.id) {
      dispatch(
        productActions.gtpRequest({
          id: selectedMonitor.id,
          startDate: dates.start,
          endDate: dates.end,
          pageNum: pageProductTweets,
          sortBy: sortBy,
          addTagIds: selectedTags?.map((a) => a.id),
          // maxResult: MAX_RESULTS,
          maxResult: 20,
          // q,
          ...(regionName && regionName.length > 0 && { regionName }),
          source: "X",
          userType: getUserType(),
        })
      );
    }
  };

  const logEvent = (eventName, evProperties) => {
    // default event properties
    const eventProperties = {
      id: selectedMonitor.id,
      tagId: product.data?.tagId,
      type: selectedMonitor.type,
      ...(selectedMonitor.source && { source: selectedMonitor.source }),
      name: product.data?.primaryName,
      activeTab: activeTab,
      influence: selectedInfluence,
      sentiment: selectedSentiment,
      startDate: dates.start,
      endDate: dates.end,
    };
    // any override
    if (!_.isEmpty(evProperties)) {
      evProperties.forEach((evProp) => {
        eventProperties[evProp.name] = evProp.value;
      });
    }

    // check for cookies first before publishing event
    if (!auth?.active && cookies?.seen && !cookies?.accepted) {
      return;
    }
    // call common helper function
    logAnalyticEvent(
      eventName,
      eventProperties,
      auth?.id || null,
      auth?.displayName || null,
      auth?.email || null,
      auth?.createdAt || null,
      auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
        ?.preferenceStringValue || "MEDICAL",
      userPlans?.data?.[0]?.plan?.name || null
    );
  };

  return view({
    userId: auth.id,
    isRegistered,
    isAdmin: auth.mwRole === "ADMIN",
    userType: auth?.preferences?.find(k => k.preferenceName === "UserType")?.preferenceStringValue,
    handleSignUpClick,
    contentRef,
    downloadRef,
    handleScroll,
    monitors,
    selectedMonitor,

    loading,
    isReady,
    dates,
    handleDateChange: handleDateChangeInt,
    activeFilters: getActiveFilters(),

    activeTab,
    tabOnChange,

    productName: product.data?.primaryName,
    productTweets: productTweets.data,
    productTweetsRef,
    productNextPage: !productTweets.isLastPage,
    exportCSVForTweets,

    highlights,
    highlightsLoading,
    tweetsCounter,
    tabOnChange,
    showExport,
    showFilter,
    filterVisibility,
    toggleFilterVisibility,
    exportCSVForHCPs,
    tags,
    searchTag,
    selectedTags: selectedTags || [],
    handleSearchTag,
    addSelectedTag,
    removeSelectedTag,
    clearTag,
    tagsInfinteScroll,

    counter: getCounter(),
    hcps,
    hcpsChartData,
    handleClearFilters,
    // selectedHcp,
    // handleSelectedHcp,
    sortTableBy,
    setSortTableBy,
    sortDirection,
    setSortDirection,
    handleInfluencersChartClick,
    influencerTypes,
    selectedInfluence,
    handleSelectedInfluence,
    sentimentTypes,
    selectedSentiment,
    handleSelectedSentiment,

    // influencerTabs,
    // influencerTab,
    // handleInfluencersTab,
    loadingHcps,
    hcpsReady,
    allKeywords,
    mainKeywords,
    sortBy,
    handleSortByChange: sortByOnChange,
    filterTip,
    closeFilterTip,
    showCalendar,
    setShowCalendar,

    handleAiRefresh,
    isAISuccess: AIResponse.success,
    isFetchingAI: AIResponse.fetching,
    aiResponse: aiSettings ? aiSettings[selectedMonitor.id]?.response : null,
    hideAI: AIResponse?.data?.requestTokens === 0 || false,
    isNewAI,
    AIKeywords: aiSettings ? aiSettings[selectedMonitor.id]?.keywords : [],
    refreshAI,

    regions,
    selectedRegion,
    handleRegionChange,

    visible,
    toggleVisibility,
    errorMessage,
    error,

    pptInProgress,
    exportPptReport,
    showToast,
    toggleToast,
  });
};
