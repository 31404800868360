import { all, fork } from "redux-saga/effects";
import watchAuthSagas from "./authSagas";
import watchHcpSagas from "./hcpSagas";
import watchCompanySagas from "./companySagas";
import watchProductSagas from "./productSagas";
import watchDiseaseSagas from "./diseaseSagas";
import watchConferenceSagas from "./conferenceSagas";
import watchTweetSagas from "./tweetSagas";
import watchtagSagas from "./tagSagas";
import watchCommonSagas from "./commonSagas";
import watchUserSagas from "./userSagas";
import watchAccountSagas from "./accountSagas";
import watchHighlightSagas from "./highlightSagas";
import watchNotificationSagas from "./notificationSagas";
import watchMonitorSagas from "./monitorSagas";
import watchAISagas from "./aiSagas";
import watchChatSagas from "./chatSagas";
import watchPlanSagas from "./planSagas";

export { cancelAuthRequests } from "./authSagas";
export { cancelHcpRequests } from "./hcpSagas";
export { cancelCompanyRequests } from "./companySagas";
export { cancelProductRequests } from "./productSagas";
export { cancelDiseaseRequests } from "./diseaseSagas";
export { cancelConferenceRequests } from "./conferenceSagas";
export { cancelTweetRequests } from "./tweetSagas";
export { cancelTagRequests } from "./tagSagas";
export { cancelCommonRequests } from "./commonSagas";
export { cancelUserRequests } from "./userSagas";
export { cancelAccountRequests } from "./accountSagas";
export { cancelHighlightRequests } from "./highlightSagas";
export { cancelNotificationRequests } from "./notificationSagas";
export { cancelMonitorRequests } from "./monitorSagas";
export { cancelAiRequests } from "./aiSagas";
export { cancelChatRequests } from "./chatSagas";
export { cancelPlanRequests } from "./planSagas";

export default function* rootSaga() {
  yield all([
    fork(watchAuthSagas),
    fork(watchHcpSagas),
    fork(watchCompanySagas),
    fork(watchProductSagas),
    fork(watchDiseaseSagas),
    fork(watchConferenceSagas),
    fork(watchTweetSagas),
    fork(watchtagSagas),
    fork(watchCommonSagas),
    fork(watchUserSagas),
    fork(watchAccountSagas),
    fork(watchHighlightSagas),
    fork(watchNotificationSagas),
    fork(watchMonitorSagas),
    fork(watchAISagas),
    fork(watchChatSagas),
    fork(watchPlanSagas),
  ]);
}
