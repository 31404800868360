import { put, call, takeLatest, all } from "redux-saga/effects";
import axios from "axios";

import { API_URL_TEMP } from "../../utils/variables";
import { COMPANY_ACTIONS, companyActions } from "../actions";

// We should delete this when using final endpoints
// import { useMock } from "../mocks/mocks";
// const getApiPath = "";
// useMock("companies");

export let cancelCompanyRequests = new AbortController();

function* handleRequest() {
  try {
    cancelCompanyRequests = new AbortController();
    const { data } = yield call(axios.get, `${API_URL_TEMP}/companies`, {
      signal: cancelCompanyRequests.signal,
    });
    yield put(companyActions.success({ data }));
  } catch (e) {
    if (e?.response?.status === 401) {
      yield put((window.location.href = "/logout"));
    }
    yield put(companyActions.failure({ error: { ...e } }));
  }
}

function* handleRequestOne(action) {
  try {
    cancelCompanyRequests = new AbortController();
    const { id } = action.payload;
    const { data } = yield call(axios.get, `${getApiPath()}/companies/${id}`, {
      signal: cancelCompanyRequests.signal,
    });
    yield put(companyActions.oneSuccess({ data }));
  } catch (e) {
    if (e?.response?.status === 401) {
      yield put((window.location.href = "/logout"));
    }
    yield put(companyActions.oneFailure({ error: { ...e } }));
  }
}

function* watchProductSagas() {
  yield all([
    takeLatest(COMPANY_ACTIONS.REQUEST, handleRequest),
    takeLatest(COMPANY_ACTIONS.ONE_REQUEST, handleRequestOne),
  ]);
}

export default watchProductSagas;
