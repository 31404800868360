import { put, call, takeLatest, all } from "redux-saga/effects";
import axios from "axios";

import { getApiPath } from "../../utils/variables";
import { authHeader } from "../store";
import { COMMON_ACTIONS, commonActions } from "../actions";

export let cancelCommonRequests = new AbortController();

function* handleRequestMeaning() {
  try {
    cancelCommonRequests = new AbortController();
    const { data } = yield call(
      axios.get,
      `${getApiPath()}/common/enumValues?table=Tag&enum=Meaning`,
      {
        headers: authHeader(),
        signal: cancelCommonRequests.signal,
      }
    );
    yield put(commonActions.successMeanings({ data }));
  } catch (e) {
    yield put(commonActions.failureMeanings({ error: { ...e } }));
  }
}

function* handleRequestAccountSubtype() {
  try {
    cancelCommonRequests = new AbortController();
    const { data } = yield call(
      axios.get,
      `${getApiPath()}/common/enumValues?table=TAccount&enum=AccountSubtype`,
      {
        headers: authHeader(),
        signal: cancelCommonRequests.signal,
      }
    );
    yield put(commonActions.successAccountSubtypes({ data }));
  } catch (e) {
    yield put(commonActions.failureAccountSubtypes({ error: { ...e } }));
  }
}

function* handleRequestUserRole() {
  try {
    cancelCommonRequests = new AbortController();
    const { data } = yield call(
      axios.get,
      `${getApiPath()}/common/enumValues?table=MWUser&enum=MWRole`,
      {
        headers: authHeader(),
        signal: cancelCommonRequests.signal,
      }
    );
    yield put(commonActions.successUserRoles({ data }));
  } catch (e) {
    yield put(commonActions.failureUserRoles({ error: { ...e } }));
  }
}

function* handleRequestCountries() {
  try {
    cancelCommonRequests = new AbortController();
    const { data } = yield call(
      axios.get,
      `${getApiPath()}/countriesandprovinces`,
      {
        headers: authHeader(),
        signal: cancelCommonRequests.signal,
      }
    );
    let transformedResult = [];
    Object.keys(data).forEach((key) => {
      const temp = key.split(":");
      data[key].forEach((item) => {
        transformedResult.push({
          country: temp[0],
          countryCode: temp[1],
          stateProvince: item.name,
          stateProvinceCode: item.code,
        })
      });
    });
    transformedResult = _.orderBy(transformedResult, "country");
    yield put(commonActions.successCountries({ data: transformedResult }));
  } catch (e) {
    yield put(commonActions.failureCountries({ error: { ...e } }));
  }
}

export function getEnumCreatedBy() {
  return axios.get(
    `${getApiPath()}/common/enumValues?table=MWUser&enum=CreatedBy`,
    { headers: authHeader() }
  );
}

export function getEnumUserType() {
  return axios.get(
    `${getApiPath()}/common/enumValues?table=MWUser_Preference&enum=UserType`,
    { headers: authHeader() }
  );
}

function* watchCommonSagas() {
  yield all([
    takeLatest(COMMON_ACTIONS.REQUEST_MEANINGS, handleRequestMeaning),
    takeLatest(
      COMMON_ACTIONS.REQUEST_ACCOUNT_SUBTYPE,
      handleRequestAccountSubtype
    ),
    takeLatest(COMMON_ACTIONS.REQUEST_USER_ROLE, handleRequestUserRole),
    takeLatest(COMMON_ACTIONS.REQUEST_COUNTRIES, handleRequestCountries),
  ]);
}

export default watchCommonSagas;
