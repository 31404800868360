import { useQueryClient, useMutation, useQuery } from "react-query";
import axios from "axios";
import { getApiPath } from "../../utils/variables";
import { authHeader } from "../store";
import { objectToUrlEncoded } from "../../utils/constants";

export function getModels() {
  return axios.get(
    `${getApiPath()}/gptParameters?category1=all&name=AiModels`,
    {
      headers: authHeader(),
    }
  );
}

export function useGetModels() {
  return useQuery(["aiModels"], () => getModels(), {
    cacheTime: 0,
  });
}

export function getPrompt({ name, objectId, objectType, category1, category2, category3 }) {
  const params = objectToUrlEncoded({ name, objectId, objectType, category1, category2, category3 });
  return axios.get(`${getApiPath()}/gptParameter?${params}`, {
    headers: authHeader(),
  });
}

export function useGetPrompt({ name, category1, category2, category3, enabled }) {
  return useQuery(
    ["gptPrompt"],
    () => getPrompt({ name, objectId: null, objectType: null, category1, category2, category3 }),
    {
      cacheTime: 0,
      enabled,
    }
  );
}

export function useGetDiseasePrompt({ name, objectId, category1, category2, category3, enabled }) {
  return useQuery(
    ["diseasePrompt"],
    () => getPrompt({ name, objectId, objectType: "DISEASE", category1, category2, category3 }),
    {
      cacheTime: 0,
      enabled,
    }
  );
}

export function useGetProductPrompt({ name, objectId, category1, category2, category3, enabled }) {
  return useQuery(
    ["productPrompt"],
    () => getPrompt({ name, objectId, objectType: "PRODUCT", category1, category2, category3 }),
    {
      cacheTime: 0,
      enabled,
    }
  );
}

export function useGetAccountPrompt({ name, objectId, category1, category2, category3, enabled }) {
  return useQuery(
    ["accountPrompt"],
    () => getPrompt({ name, objectId, objectType: "ACCOUNT", category1, category2, category3 }),
    {
      cacheTime: 0,
      enabled,
    }
  );
}

export function regenerateMediaSummary(id) {
  return axios.post(
    `${getApiPath()}/postSummary/generate?externalId=${id}`,
    {},
    { headers: authHeader() }
  );
}
export function useRegenerateMediaSummary() {
  const queryClient = useQueryClient();
  return useMutation((id) => regenerateMediaSummary(id), {
    onSuccess: () => {
      queryClient.invalidateQueries("mediaSummary");
    },
  });
}

export function getMediaSummary(id) {
  if (id.includes("YT_") || id.includes("PC_")) {
    return axios.get(`${getApiPath(false)}/postSummary?externalId=${id}`, {});
  } else {
    return Promise.resolve({ data: { data: {} } });
  }
}
export function useGetMediaSummary(id) {
  return useQuery(["mediaSummary"], () => getMediaSummary(id), {
    cacheTime: 0,
  });
}

export function getMediaTakeaways({ id, userType, enabled }) {
  if ((id.includes("YT_") || id.includes("PC_")) && enabled) {
    let url = `/postSummaryTakeaways?externalId=${id}`;
    if (userType) {
      url += `&userType=${userType}`;
    }
    return axios.get(`${getApiPath(false)}${url}`, {});
  } else {
    return Promise.resolve({ data: { data: {} } });
  }
}
export function useGetMediaTakeaways({ id, userType, enabled }) {
  return useQuery(
    ["mediaTakeaways"],
    () => getMediaTakeaways({ id, userType, enabled }),
    {
      cacheTime: 0,
    }
  );
}
