import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { view } from "./AiSummaryView";

import {
  getObjectsWithSummary,
  updateSummaryTitle,
} from "../../store/sagas/summary";
import { idToText } from "../../utils";
import {
  getInsights,
  useGetInsight,
  useRegenerate,
} from "../../store/queries/insights";
import moment from "moment";

export const AiSummary = () => {
  const { objectId, id } = useParams();
  const {
    mutateAsync: regenerateInsight,
    isLoading: isRegenerating,
    error: regenerateError,
  } = useRegenerate();

  const user = useSelector((state) => state.auth.data);

  // --> STATE
  const [objectsWithSummary, setObjectsWithSummary] = useState([]);
  const [objectSummary, setObjectSummary] = useState([]);
  const [previousSummaries, setPreviousSummaries] = useState([]);
  const [inProgress, setInProgress] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [title, setTitle] = useState("");
  const [error, setError] = useState("");

  const { data: inisght, refetch: refetchInsight } = useGetInsight({
    data: { objectId, summaryId: id },
    enabled: false, // no call on page load; we will use effect to call with any chnage in summary id
  });
  // <-- STATE

  // --> EFFECT
  useEffect(() => {
    (async () => {
      setInProgress(true);
      const tempSummary = await getObjectsWithSummary();
      if (tempSummary.data) {
        setObjectsWithSummary(tempSummary.data);
      }

      setInProgress(false);
    })();
  }, []);

  // on nny change in id, refetch insight
  useEffect(() => {
    refetchInsight();
  }, [
    id,
    // , objectsWithSummary
  ]);

  useEffect(() => {
    if (inisght?.data?.length > 0 && inisght?.data[0]?.id === id) {
      setObjectSummary(inisght.data[0]);
      // get previous two weeks summary
      (async () => {
        // NOTE - date should be in YYYY-MM-DD format and within last week date range
        let previousSummaries = await getInsights({
          objectId: objectId,
          objectType: "DISEASE",
          startDateLessThan: moment(inisght.data[0].startDate).format(
            "YYYY-MM-DD"
          ),
          pageNum: 0,
          maxResult: 2,
        });
        setPreviousSummaries(previousSummaries?.data || []);
      })();
      window.scrollTo(0, 0);
    }
  }, [inisght]);

  // useEffect(() => {
  //   if (objectsWithSummary?.length > 0) {
  //     if (id) {
  //       setObjectId(
  //         textToId({
  //           text: id,
  //           items: objectsWithSummary?.map((item) => {
  //             return { id: item.id, label: item.primaryName };
  //           }),
  //         })
  //       );
  //     } else {
  //       setObjectId(objectsWithSummary[0].id);
  //     }
  //   }
  // }, [objectsWithSummary, id]);

  useEffect(() => {
    if (regenerateError?.message) {
      setError(regenerateError.message);
    }
  }, [regenerateError]);

  // <-- EFFECT

  const handleSignUpClick = () => {
    return;
  };

  const handleRegenerate = async (id) => {
    if (id) {
      const resp = await regenerateInsight(id);
      if (resp.data) {
        debugger;
        setObjectSummary(resp.data);
      }
    }
  };

  const handleUpdateTitle = (id) => {
    (async () => {
      const temp = await updateSummaryTitle({
        id,
        title,
      });
      if (temp.data) {
        setObjectSummary(temp.data);
      }
    })();
    setEditMode(false);
  };
  const handleCancelEditing = () => {
    setEditMode(false);
  };

  return view({
    user,
    isAdmin: user?.mwRole === "ADMIN",
    objectSummary,
    previousSummaries,
    inProgress,
    items:
      objectsWithSummary?.map((item) => {
        return {
          id: item.id,
          label: item.primaryName,
          to: `/insights/${idToText(item.primaryName)}`,
        };
      }) || [],
    handleSignUpClick,
    editMode,
    setEditMode,
    title,
    setTitle,
    handleUpdateTitle,
    handleCancelEditing,
    handleRegenerate,
    isRegenerating,
    error,
  });
};
