import React, { useEffect, useState, useCallback, useRef } from "react";
import { useParams } from "react-router-dom";
import { view } from "./InsightDetailsView";
import { logAnalyticEvent, getUrlParams } from "../../utils";
import { useInfluencersByDisease } from "../../store/queries/influencers";
import useInfiniteScroll from "react-infinite-scroll-hook";

export const InsightDetails = ({ signUpClick }) => {
  const maxResult = 5;
  const { id } = useParams();

  const {
    data: summaries,
    isSuccess,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useInfluencersByDisease(
    {
      objectId: id,
      maxResult,
    },
    {
      getNextPageParam: (lastPage, pages) => {
        return lastPage.data.length === maxResult ? pages.length : undefined;
      },
    }
  );

  useEffect(() => {
    scrollTo(0, 0);
  }, []);

  const handleMoreSummaries = () => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage();
    }
  };

  const [summariesRef] = useInfiniteScroll({
    loading: isFetchingNextPage,
    hasNextPage,
    onLoadMore: handleMoreSummaries,
  });

  useEffect(() => {
    // Uncomment and update this section when ready to implement analytics
    // const queryParams = getUrlParams();
    // logAnalyticEvent(
    //   "Landing",
    //   {
    //     page: "Home",
    //     section: queryParams.section ? queryParams.section : "marketing",
    //   },
    //   auth?.id || queryParams?.u || null,
    //   auth?.displayName || queryParams?.n || null,
    //   auth?.email || queryParams?.e || null,
    //   auth?.createdAt || null,
    //   auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
    //     ?.preferenceStringValue ||
    //     queryParams?.t ||
    //     "MEDICAL",
    //   null
    // );
  }, []);

  return view({
    signUpClick,
    summaries: summaries?.pages,
    loadingSummaries: isFetchingNextPage,
    isSuccess,
    hasNextPage,
    summariesRef,
  });
};
