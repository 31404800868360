import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import {
  Row,
  MainWrapper,
  Header,
  Content,
  Label,
  Input,
  ResponsiveButton,
  List,
  ListItem,
  Filters,
  Grid,
  Dropdown,
  DateRange,
  Html,
  Column,
  ContentGrid,
  Spinner,
} from "../../../components";

import { Menu } from "../Menu";

export const view = ({
  highlights,
  infiniteScrollRef,
  hasNextPage,
  loading,
  isAdmin,
  activeFilters,
  // searchTerm,
  // setSearchTerm,

  objectTypes,
  selectedObjectType,
  handleObjectTypeChange,

  dates,
  handleDateChange,

  objectNameTerm,
  handleSearchObjectNameTermChange,
}) => {
  return (
    <MainWrapper drawer={isAdmin}>
      <Header
        back={!isAdmin}
        drawer={isAdmin}
        rightContent={
          <>
            <Row fit />
            <ResponsiveButton label="Add Highlight" to="/newhighlight" />
          </>
        }
      />
      <Menu active="highlights" />

      <Content>
        <Column>
          <Filters
            {...{ activeFilters }}
            datePicker={
              <Row gap="10" middle>
                <Label>From </Label>
                <DateRange
                  noBackground
                  period
                  initialDate={dates.start}
                  endDate={dates.end}
                  onChange={handleDateChange}
                />
              </Row>
            }
          >
            <Grid columns={3}>
              <Column gap="10">
                <Label bold>Type</Label>
                <Dropdown
                  expand
                  select
                  value={selectedObjectType}
                  onChange={handleObjectTypeChange}
                  items={objectTypes}
                />
              </Column>
              <Column gap="10">
                <Label bold>Monitor</Label>
                <Input
                  expand
                  icon="search"
                  small
                  placeholder="search"
                  value={objectNameTerm}
                  onChange={(e) => {
                    handleSearchObjectNameTermChange(e.target?.value || null);
                  }}
                />
              </Column>
            </Grid>
          </Filters>

          <Column style={{ verticalAlign: "top" }} marginTop="10">
            <List>
              {highlights.map((item) => (
                <Link key={item.id} to={`/editHighlight/${item.id}`}>
                  <ListItem>
                    <Column marginY="10">
                      <ContentGrid reponsiveMargin="-40px 0 10px 0">
                        <Html string={item.content} />
                        <Column paddingTop="5">
                          <Label bold>
                            {item?.objectName || item.objectId}
                          </Label>
                          <Row marginTop="5">
                            <Label small color="gray">
                              {moment(item.startDate).utc().format("M/D/YYYY")}
                              {" - "}
                              {moment(item.endDate).utc().format("M/D/YYYY")}
                            </Label>
                          </Row>
                          {item.creator && (
                            <Label small color="gray">
                              Creator: {item.creator?.displayName}
                            </Label>
                          )}
                          <Label small color="gray">
                            Last Modified:{" "}
                            {new Date(item.lastModified).toLocaleString(
                              "en-US"
                            )}
                          </Label>
                        </Column>
                      </ContentGrid>
                    </Column>
                  </ListItem>
                </Link>
              ))}
            </List>
            {loading || hasNextPage ? (
              <Row expand paddingAll="40" center ref={infiniteScrollRef}>
                <Spinner />
              </Row>
            ) : (
              <>
                {highlights?.length === 0 && (
                  <List center>
                    <ListItem>No Highlights found</ListItem>
                  </List>
                )}
              </>
            )}
          </Column>
        </Column>
      </Content>
    </MainWrapper>
  );
};
