import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import { isEmpty } from "lodash";

import {
  highlightActions,
  diseaseActions,
  conferenceActions,
} from "../../../store/actions";
import { view } from "./EditHighlightView";
import { getISOFormattedDateTimeString } from "../../../utils";

const errorsMessage = {
  disease: "Required field",
  text: "Required field",
};

export const EditHighlight = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const auth = useSelector((state) => state.auth.data);
  const initialDiseases = useSelector((state) => state.disease.all.data);
  const initialConferences = useSelector((state) => state.conference.all.data);
  const highlight = useSelector((state) => state.highlight.one.data);
  const [deleteModal, toggleDeleteModal] = useState(false);
  const [isSavePressed, setIsSavePressed] = useState(false);
  const [errors, setErrors] = useState({});

  const [objectTypes] = useState([
    { id: "DISEASE", label: "Disease" },
    { id: "CONFERENCE", label: "Congress" },
  ]);
  const [selectedObjectType, setSelectedObjectType] = useState("DISEASE");

  const [diseases, setDiseases] = useState(null);
  const [selectedDisease, setSelectedDisease] = useState([]);
  const [conferences, setConferences] = useState([]);
  const [selectedConference, setSelectedConference] = useState([]);

  const [text, setText] = useState("");
  const [date, setDate] = useState({
    start: getISOFormattedDateTimeString(moment().startOf("day")),
    end: getISOFormattedDateTimeString(moment().endOf("day")),
  });

  useEffect(() => {
    dispatch(diseaseActions.request({ ignore: false, maxResult: -1 }));
    dispatch(
      conferenceActions.request({
        ignore: false,
        onlyParents: true,
        maxResult: -1,
      })
    );
    if (id) {
      dispatch(highlightActions.oneRequest(id));
    }
    return () => {
      dispatch(highlightActions.clear());
    };
  }, [dispatch]);

  useEffect(() => {
    if (initialDiseases.length > 0) {
      const newItems = initialDiseases.map((item) => ({
        id: item.id,
        label: item.primaryName,
      }));
      setDiseases(newItems);
    }
  }, [initialDiseases]);

  useEffect(() => {
    if (selectedObjectType === "DISEASE") {
      setDate({
        start: getISOFormattedDateTimeString(
          moment().isoWeekday(1).startOf("day")
        ),
        end: getISOFormattedDateTimeString(
          moment().isoWeekday(1).add(6, "days").endOf("day")
        ),
      });
    } else {
      setDate({
        start: getISOFormattedDateTimeString(moment().startOf("day")),
        end: getISOFormattedDateTimeString(moment().endOf("day")),
      });
    }
  }, [selectedObjectType]);

  useEffect(() => {
    if (initialConferences.length > 0) {
      const newItems = initialConferences
        .filter((c) => !c.parentId)
        .map((item) => ({
          id: item.id,
          label: item.primaryName,
        }));
      setConferences(newItems);
    }
  }, [initialConferences]);

  useEffect(() => {
    if (highlight && id) {
      setSelectedObjectType(highlight.objectType);
      if (highlight.objectType === "DISEASE")
        setSelectedDisease(highlight.objectId);
      else setSelectedConference(highlight.objectId);
      setText(highlight.content);
      setDate({
        start: moment(highlight.startDate).utc().format("YYYY-MM-DDTHH:mm:ss"),
        end: moment(highlight.endDate).utc().format("YYYY-MM-DDTHH:mm:ss"),
      });
    }
  }, [highlight]);

  useEffect(() => {
    checkErrors();
  }, [selectedDisease, selectedConference, text]);

  const checkErrors = () => {
    let newErrors = {};
    if (selectedDisease?.length === 0 && selectedConference?.length === 0) {
      newErrors.disease = errorsMessage.disease;
    }
    if (text === "") {
      newErrors.text = errorsMessage.text;
    }
    setErrors(newErrors);
  };

  const handleObjectTypeChange = (e) => {
    setSelectedObjectType(e.id);
  };

  const handleDiseaseChange = (e) => {
    setSelectedDisease(e.id);

    if (id && isEmpty(errors)) {
      const data = {
        ...highlight,
        objectId: e.id,
        objectName: e.label,
        objectType: selectedObjectType,
      };
      dispatch(highlightActions.update(data));
    }
  };

  const handleConferenceChange = (e) => {
    setSelectedConference(e.id);

    if (id && isEmpty(errors)) {
      const data = {
        ...highlight,
        objectId: e.id,
        objectName: e.label,
        objectType: selectedObjectType,
      };

      dispatch(highlightActions.update(data));
    }
  };

  const handleTextChange = (html) => {
    setText(html);
  };

  const handleEditorBlur = () => {
    if (id) {
      const wrappedText = `<div class="fromEditor">${text}</div>`;
      const data = {
        ...highlight,
        content: wrappedText.toString("html"),
      };
      dispatch(highlightActions.update(data));
    }
  };

  const handleSave = () => {
    setIsSavePressed(true);
    if (isEmpty(errors)) {
      const wrappedText = `<div class="fromEditor">${text}</div>`;
      const data = {
        ...highlight,
        creatorId: auth.id,
        objectId:
          selectedObjectType === "DISEASE"
            ? selectedDisease
            : selectedConference,
        objectType: selectedObjectType,
        content: wrappedText.toString("html"),
        startDate: date.start,
        endDate: date.end,
      };

      dispatch(highlightActions.create(data));
    }
  };

  const handleDelete = () => {
    dispatch(highlightActions.delete(id));
  };

  const handleDateChange = (e) => {
    setDate({
      start: getISOFormattedDateTimeString(e.startDate),
      end: getISOFormattedDateTimeString(e.endDate),
    });

    if (id && isEmpty(errors)) {
      const data = {
        ...highlight,
        startDate: getISOFormattedDateTimeString(e.startDate),
        endDate: getISOFormattedDateTimeString(e.endDate),
      };

      dispatch(highlightActions.update(data));
    }
  };

  return view({
    handleSave,
    errors,
    isSavePressed,
    isNew: !id,

    objectTypes,
    selectedObjectType,
    handleObjectTypeChange,

    diseases,
    selectedDisease,
    handleDiseaseChange,

    conferences,
    selectedConference,
    handleConferenceChange,

    text,
    handleTextChange,
    handleEditorBlur,
    deleteModal,
    toggleDeleteModal,
    handleDelete,
    date,
    handleDateChange,
  });
};
