import React from "react";

import {
  Row,
  Column,
  Content,
  H3,
  Label,
  MainWrapper,
  Header,
  Menu,
  Grid,
  Button,
  Dropdown,
  Modal,
  AutoCompleteWithResponses,
  Pill,
  Anchor,
  Metadata,
} from "../../components";

const diseaseIntervals = [
  { id: 168, label: "Weekly" },
  { id: -1, label: "Off" },
];

const productIntervals = [
  { id: 168, label: "Weekly" },
  { id: 24, label: "Daily" },
  { id: 0, label: "Instant" },
  { id: -1, label: "Off" },
];

const conferenceIntervals = [
  { id: 24, label: "Daily" },
  { id: -1, label: "Off" },
];

const topInfluencersIntervals = [
  { id: 1, label: "Biweekly" },
  { id: 0, label: "Off" },
];

export const Group = ({ children, label }) => (
  <Column marginTop="10">
    <Row marginBottom="10">
      <Label bold>{label}</Label>
    </Row>
    <Column marginTop="5">{children}</Column>
  </Column>
);

export const view = ({
  isTopInfluencers,
  preferences,
  monitor,
  handleChangeInterval,
  deleteModal,
  toggleDeleteModal,
  handleDeleteMonitor,

  regions,
  selectedRegions,
  addSelectedRegions,
  removeSelectedRegions,

  diseases,
  searchDisease,
  setSearchDisease,
  selectedDiseases,
  addSelectedDiseases,
  removeSelectedDiseases,
  clearFilters,
}) => {
  return (
    <MainWrapper drawer className="dashboard">
      <Header drawer login user back />
      {/* <Header drawer login user backTo="/watchlist" /> */}
      <Menu />
      <Content>
        <Metadata />
        {(isTopInfluencers || monitor?.id) && (
          <Column>
            <Row marginBottom="5">
              <H3 bold>
                {isTopInfluencers
                  ? "Top Influencers"
                  : monitor.monitorComponent.object1Name}
              </H3>
            </Row>

            {isTopInfluencers && (
              <Row marginTop>
                <Label>
                  Every two weeks, you'll get an email with Top Influencers for
                  the diseases on your watchlist.
                </Label>
              </Row>
            )}

            <Column marginTop>
              <Grid columns={3}>
                <Group label="Email frequency">
                  <Dropdown
                    expand
                    items={
                      isTopInfluencers
                        ? topInfluencersIntervals
                        : monitor.monitorComponent.object1Type === "DISEASE"
                        ? diseaseIntervals
                        : monitor.monitorComponent.object1Type === "PRODUCT"
                        ? productIntervals
                        : conferenceIntervals
                    }
                    value={
                      isTopInfluencers
                        ? preferences?.preferenceIntValue
                        : monitor.notificationInterval
                    }
                    onChange={handleChangeInterval}
                  />
                </Group>
                {!isTopInfluencers && (
                  <Group label="Regions">
                    <AutoCompleteWithResponses
                      showOnEmptyTerm
                      searchResults={regions}
                      placeholder="Filter Regions"
                      setSearchTerm={() => {}}
                      displayTextFromSelected
                      selectedItems={selectedRegions}
                      addItem={(item) => addSelectedRegions(item)}
                      clearSearchResults={() => {}}
                      searchLoading={false}
                      hidePills
                    />
                  </Group>
                )}
                {!isTopInfluencers &&
                  monitor.monitorComponent.object1Type === "PRODUCT" && (
                    <Group label="Indications">
                      <AutoCompleteWithResponses
                        showOnEmptyTerm
                        searchResults={diseases}
                        placeholder="Filter keywords"
                        setSearchTerm={(e) => setSearchDisease(e)}
                        searchTerm={searchDisease}
                        selectedItems={selectedDiseases}
                        addItem={addSelectedDiseases}
                        removeItem={removeSelectedDiseases}
                        clearSearchResults={() => {}}
                        searchLoading={false}
                        hidePills
                      />
                    </Group>
                  )}
              </Grid>
            </Column>

            {!isTopInfluencers &&
            (selectedRegions.length > 0 || selectedDiseases.length > 0) ? (
              <Row marginTop="30" expand gap="15" top>
                <Row noShrink marginTop="3">
                  <Anchor label="Clear filters" bold onClick={clearFilters} />
                </Row>
                <Row flexWrap="wrap">
                  {selectedRegions?.map((item) => (
                    <Pill
                      key={item.id}
                      label={item.label}
                      close
                      bkg="green"
                      fade="2"
                      onClose={() => removeSelectedRegions(item.id)}
                    />
                  ))}
                  {selectedDiseases?.map((item) => (
                    <Pill
                      key={item.id}
                      label={item.label}
                      close
                      bkg="green"
                      fade="2"
                      onClose={() => removeSelectedDiseases(item.id)}
                    />
                  ))}
                </Row>
              </Row>
            ) : (
              <Row marginTop="30">
                {!isTopInfluencers && (
                  <Label>
                    No filters added. Results include all regions and keywords.
                  </Label>
                )}
              </Row>
            )}
            {!isTopInfluencers && (
              <Row marginTop="40" gap="20">
                <Button
                  small
                  danger
                  secondary
                  label={
                    monitor.monitorComponent.object1Type === "PRODUCT"
                      ? "Remove product"
                      : monitor.monitorComponent.object1Type === "DISEASE"
                      ? "Remove disease"
                      : "Remove congress"
                  }
                  onClick={() => {
                    toggleDeleteModal(true);
                  }}
                />
                {/* <Button small label="Save Changes" onClick={() => {}} /> */}
              </Row>
            )}
          </Column>
        )}
      </Content>

      <Modal
        visible={deleteModal}
        toggleVisibility={toggleDeleteModal}
        maxWidth="600"
      >
        <Column gap="20" marginTop="-20" center>
          <Label large center>
            {`You're removing ${monitor?.monitorComponent?.object1Name} from your preferences`}
          </Label>
          <Row marginTop="40">
            <Grid columns={2}>
              <Button
                secondary
                label="Cancel"
                minWidth={200}
                onClick={() => toggleDeleteModal(false)}
              />
              <Button
                danger
                minWidth={200}
                label="Confirm"
                onClick={() => handleDeleteMonitor(monitor.id)}
              />
            </Grid>
          </Row>
        </Column>
      </Modal>
    </MainWrapper>
  );
};
