import React, { useEffect, useState } from "react";
import { view } from "./InsightsView";
import {
  logAnalyticEvent,
  getUrlParams,
  months,
  getISOFormattedDateTimeString,
} from "../../utils";
import { useDiseaseSummaries } from "../../store/queries/influencers";

export const DiseaseInsights = ({ signUpClick }) => {
  const {
    data: summaries,
    isLoading: loadingSummaries,
    isSuccess,
  } = useDiseaseSummaries();

  useEffect(() => {
    // Uncomment and update this section when ready to implement analytics
    // const queryParams = getUrlParams();
    // logAnalyticEvent(
    //   "Landing",
    //   {
    //     page: "Home",
    //     section: queryParams.section ? queryParams.section : "marketing",
    //   },
    //   auth?.id || queryParams?.u || null,
    //   auth?.displayName || queryParams?.n || null,
    //   auth?.email || queryParams?.e || null,
    //   auth?.createdAt || null,
    //   auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
    //     ?.preferenceStringValue ||
    //     queryParams?.t ||
    //     "MEDICAL",
    //   null
    // );
  }, []);

  return view({
    signUpClick,

    summaries: summaries?.data,
    loadingSummaries,
    isSuccess,
  });
};
