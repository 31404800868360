import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { Color } from "../utils/variables";
import { IconButton } from "./Button";

export const Toast = (props) => {
  const {
    children,
    close,
    warning,
    visible,
    toggleVisibility,
    expiration,
    bkg,
    modal,
  } = props;

  React.useEffect(() => {
    if (visible && expiration) {
      const timer = setTimeout(() => {
        toggleVisibility();
      }, expiration);
      return () => clearTimeout(timer);
    }
  });

  return (
    <>
      <ToastContainer {...props} className={visible ? "visible" : ""}>
        <ToastBox close={close} bkg={bkg} warning={warning}>
          {children}
          {close && (
            <ToastClose>
              <IconButton
                small
                icon="closeLarge"
                onClick={() => toggleVisibility()}
                color="white"
              />
            </ToastClose>
          )}
        </ToastBox>
      </ToastContainer>
      {modal && visible && (
        <CloseWrapper onClick={() => toggleVisibility(false)} />
      )}
    </>
  );
};

const CloseWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  cursor: pointer;
  z-index: 10;
`;

const ToastContainer = styled.div`
  pointer-events: none;
  position: fixed;
  display: flex;
  justify-content: center;
  ${(props) => (props.bottom ? "bottom: 0" : "top: 0")};
  left: 0;
  right: 0;
  padding: 14px;
  transform: translateY(${(props) => (props.bottom ? "100%" : "-100%")});
  transition: all 0.2s;
  opacity: 0;
  z-index: 110;

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
`;

const ToastBox = styled.div`
  pointer-events: all;
  max-width: 800px;
  display: flex;
  position: relative;
  border-radius: 8px;
  padding: 15px 20px;
  color: white;
  ${(props) => props.close && "padding-right: 50px"};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  background-color: ${(props) =>
    props.bkg
      ? Color(props.bkg)
      : props.warning
      ? Color("red")
      : Color("green")};
`;

const ToastClose = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
`;

Toast.propTypes = {
  children: PropTypes.any,
  close: PropTypes.bool,
  warning: PropTypes.bool,
  visible: PropTypes.bool,
  bkg: PropTypes.string,
  toggleVisibility: PropTypes.func,
  expiration: PropTypes.number,
};
