import React from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Column,
  H3,
  MainWrapper,
  Content,
  Button,
  Input,
  Anchor,
  Label,
  Card,
  CardBody,
  Icon,
  TextLogo,
  P,
} from "../../components";

export const view = ({
  userName,
  handleUserName,
  submit,
  error,
  notFound,
  loading,
  emailSent,
  handleSignUpClick,
  pressedResend,
  setPressedResend,
}) => (
  <MainWrapper noHeader>
    <Content bkg="gray" fade="1">
      <Column center>
        <Row paddingBottom>
          <TextLogo />
        </Row>
        <Card medium radius="30">
          <CardBody center>
            {emailSent?.id ? (
              <Column center maxWidth="400" stretch gap="20">
                <H3 bold>Check your email</H3>
                <Column marginTop="20" center stretch>
                  {emailSent?.email && (
                    <Column gap="5" center>
                      <Label>We sent an email to</Label>
                      <Label bold>{emailSent.email}</Label>
                      <Label>with a link you can use to sign in.</Label>
                    </Column>
                  )}
                  {pressedResend ? (
                    <Row marginTop="60" gap="5" middle>
                      <Label large>Email sent</Label>
                      <Icon name="check" color="green" size="30" />
                    </Row>
                  ) : (
                    <Column marginTop="60" gap="20" center>
                      <Label>Didn't get an email from us?</Label>
                      <Row>
                        <Button
                          type="submit"
                          id="button-login-retry"
                          minWidth="240"
                          label="Resend"
                          onClick={(e) => {
                            submit(e);
                            setPressedResend(true);
                          }}
                          loading={loading}
                        />
                      </Row>
                    </Column>
                  )}
                </Column>
              </Column>
            ) : (
              <form onSubmit={(e) => submit(e)}>
                <Column center maxWidth="400" stretch>
                  <H3 bold>Sign in with email</H3>
                  <Column marginTop="40" center stretch>
                    {notFound ? (
                      <Column gap="10">
                        <P center color="red">
                          Sorry, we couldn't find an account for{" "}
                          <Label bold>{userName}</Label>.
                        </P>
                        <Label center>
                          Please check your email or{" "}
                          <Anchor
                            bold
                            inline
                            label="Sign Up"
                            onClick={() => handleSignUpClick()}
                            to="/"
                          />
                          .
                        </Label>
                      </Column>
                    ) : (
                      <Label center>
                        Enter the email address associated with your account,
                        and we'll send a magic link to your inbox.
                      </Label>
                    )}
                  </Column>

                  <Column stretch>
                    <Row marginTop="20">
                      <Input
                        id="input-username"
                        small
                        placeholder="Email"
                        value={userName}
                        onChange={(e) => handleUserName(e.target.value)}
                      />
                    </Row>

                    {error && (
                      <Row marginTop="10">
                        <Label color="red">{error}</Label>
                      </Row>
                    )}
                  </Column>
                  <Column marginTop="60" center>
                    <Button
                      type="submit"
                      id="button-login"
                      label="Log In"
                      minWidth="240"
                      onClick={(e) => submit(e)}
                      loading={loading}
                    />

                    <Row marginTop="60">
                      <Label>
                        Don't have an account?{" "}
                        <Anchor
                          inline
                          bold
                          label="Sign Up"
                          onClick={() => handleSignUpClick()}
                          to="/"
                        />
                      </Label>
                    </Row>
                  </Column>
                </Column>
              </form>
            )}
          </CardBody>
        </Card>
      </Column>
    </Content>
  </MainWrapper>
);
