import { useQuery } from "react-query";
import axios from "axios";
import { getApiPath } from "../../utils/variables";

export function getTweet(id) {
  return axios.get(`${getApiPath(false)}/tweet/${id}`);
}
export function useGetTweet(id) {
  return useQuery(["tweet"], () => getTweet(id), {
    cacheTime: 0,
  });
}
