import React from "react";
import Table from "rc-table";
import "../../components/styles/rc-table.scss";
import { Link } from "react-router-dom";
import moment from "moment";
import styled from "styled-components";

import {
  Row,
  Column,
  Content,
  H3,
  H4,
  MainWrapper,
  Header,
  Icon,
  Drawer,
  List,
  ListItem,
  Avatar,
  Anchor,
  Input,
  HR,
  Spinner,
  Circle,
  Label,
  Metadata,
  TweetCard,
} from "../../components";
import { idToText, capitalize, months } from "../../utils";
import { Color } from "../../utils/variables";

const LinkWrapper = styled(Row)`
  &:hover {
    background: ${Color("green", "1")};
  }
`;

const LinkItem = ({ children, href }) => (
  <a href={href}>
    <LinkWrapper
      expand
      middle
      bkg="gray"
      fade="1"
      paddingAll="20"
      gap="20"
      radius="10"
      className="pointer"
    >
      <Row fit>
        <Column gap="10">
          <H4>{children}</H4>
        </Column>
      </Row>
      <Row noShrink marginLeft="10">
        <Icon color="green" fade="6" name="chevronRight" />
      </Row>
    </LinkWrapper>
  </a>
);

const Links = ({ selectedItem }) => {
  if (selectedItem?.name) {
    const previousDate = moment(
      `${selectedItem.year}-${months.indexOf(selectedItem.month) + 1}-01`
    )
      .subtract(1, "month")
      .format("MMMM YYYY");
    const nextDate = moment(
      `${selectedItem.year}-${months.indexOf(selectedItem.month) + 1}-01`
    )
      .add(1, "month")
      .format("MMMM YYYY");

    return (
      <Column marginTop="30" maxWidth="800" gap="20">
        {moment().diff(previousDate, "months") < 6 && (
          <LinkItem
            href={`/top_influencers/disease/${idToText(
              selectedItem.name
            )}/${previousDate.toLowerCase().replace(" ", "/")}`}
          >
            Top Influencers in {selectedItem.name} - {previousDate}
          </LinkItem>
        )}

        {moment().diff(nextDate, "months") > 0 && (
          <LinkItem
            href={`/top_influencers/disease/${idToText(
              selectedItem.name
            )}/${nextDate.toLowerCase().replace(" ", "/")}`}
          >
            Top Influencers in {selectedItem.name} - {nextDate}
          </LinkItem>
        )}
      </Column>
    );
  } else {
    return null;
  }
};

export const view = ({
  handleSignUpClick,
  tags,
  selectedItem,
  sortTableBy,
  setSortTableBy,
  sortDirection,
  setSortDirection,
  hcps,
  filteredItem,
  handleFilterItems,
  month,
  year,
  tweet,
  conferences,
  isLoading,
}) => {
  const getClassNames = (title) => {
    if (sortTableBy === title) {
      return sortDirection === "asc" ? "sort-asc" : "sort-desc";
    }
    return "";
  };

  const headerActions = (key) => ({
    onClick() {
      if (sortTableBy === key) {
        setSortDirection(sortDirection === "asc" ? "desc" : "asc");
      } else {
        setSortTableBy(key);
        if (key === "score" || key === "mentions") {
          setSortDirection("asc");
        } else {
          setSortDirection("desc");
        }
      }
    },
  });

  const columns = [
    {
      title: "",
      key: "index",
      render: (cell) => (
        <Row paddingX="5" marginTop="-2">
          <Circle bkg="gray" fade="1" radius="19">
            <Label>{cell.index}</Label>
          </Circle>
        </Row>
      ),
    },
    {
      title: "Name",
      key: "name",
      render: (cell) => (
        <Link to={`/account/${cell.id}?source=influencers`}>
          <Row middle gap="10">
            <Avatar small imageUrl={cell.imageURL} name={cell.name} />
            <Anchor label={cell.name} color="black" />
          </Row>
        </Link>
      ),
      className: getClassNames("name"),
      onHeaderCell: (header) => headerActions(header.key),
    },
    {
      title: "Posts",
      dataIndex: "mentions",
      key: "mentions",
      className: getClassNames("mentions"),
      onHeaderCell: (header) => headerActions(header.key),
    },
    {
      title: "Institution",
      dataIndex: "institution",
      key: "institution",
      className: getClassNames("institution"),
      onHeaderCell: (header) => headerActions(header.key),
    },
    {
      title: "Location",
      key: "location",
      render: (cell) => <>{cell.displayLocation}</>,
      className: getClassNames("location"),
      onHeaderCell: (header) => headerActions(header.key),
    },
  ];

  return (
    <MainWrapper drawer className="dashboard">
      <Header search drawer login user signUpClick={handleSignUpClick} />
      <Drawer login user scroll>
        <List marginTop="20">
          <Row marginBottom="20" marginTop="-10">
            <Input
              small
              placeholder="Diseases and conferences"
              icon="search"
              value={filteredItem}
              onChange={handleFilterItems}
            />
          </Row>
          {conferences?.filter((c) =>
            c.primaryName.toLowerCase().includes(filteredItem.toLowerCase())
          )?.length > 0 && (
            <>
              <Row marginBottom="15">
                <HR />
              </Row>
              {conferences
                ?.filter((c) =>
                  c.primaryName
                    ?.toLowerCase()
                    .includes(filteredItem.toLowerCase())
                )
                ?.map((c) => (
                  <div
                    className={
                      c?.primaryName === selectedItem?.name ? "active" : "hover"
                    }
                    key={c.id}
                  >
                    <ListItem>
                      <a
                        href={`/top_influencers/conference/${c.primaryName.replace(
                          / /g,
                          "_"
                        )}`}
                      >
                        <Row gap="10">{c.primaryName}</Row>
                      </a>
                    </ListItem>
                  </div>
                ))}
            </>
          )}
          {tags?.filter((tag) =>
            tag.name.toLowerCase().includes(filteredItem.toLowerCase())
          )?.length > 0 && (
            <>
              <Row marginBottom="15" marginTop="10">
                <HR />
              </Row>
              {tags
                ?.filter((tag) =>
                  tag.name.toLowerCase().includes(filteredItem.toLowerCase())
                )
                ?.map((tag) => (
                  <div
                    className={
                      idToText(tag.name) === selectedItem.label
                        ? "active"
                        : "hover"
                    }
                    key={tag.id}
                  >
                    <ListItem>
                      <a
                        href={`/top_influencers/disease/${idToText(
                          tag.name
                        )}/${month}/${year}`}
                      >
                        <Row gap="10">{tag.label}</Row>
                      </a>
                    </ListItem>
                  </div>
                ))}

              <Row marginTop="10" marginBottom="10">
                <HR />
              </Row>
            </>
          )}
        </List>
      </Drawer>

      <Content>
        <Column>
          {selectedItem.name && (
            <Row marginBottom="30">
              <H3 bold>
                {selectedItem.type === "disease"
                  ? `Top Influencers in ${selectedItem.name} - ${capitalize(
                      selectedItem.month
                    )} ${selectedItem.year}`
                  : `Top Influencers at ${selectedItem.name}`}
              </H3>
            </Row>
          )}
          {isLoading && (
            <Row expand paddingAll center>
              <Spinner />
            </Row>
          )}
          {hcps.length > 0 && (
            <>
              <Metadata
                title={
                  selectedItem.type === "disease"
                    ? `Top Influencers in ${selectedItem.name} - ${capitalize(
                        selectedItem.month
                      )} ${selectedItem.year}`
                    : `Top Influencers at ${selectedItem.name}`
                }
                description={
                  selectedItem.type === "disease"
                    ? `Medical.watch - Find top influencers in ${selectedItem.name} and other diseases`
                    : `Medical.watch - Find top influencers at ${selectedItem.name} and other medical congresses`
                }
                image={hcps[0]?.imageURL?.replace("_bigger", "")}
              />
              <Table
                rowKey="id"
                className="sortable"
                data={hcps}
                {...{ columns }}
                style={{ maxWidth: 980 }}
                scroll={{ x: 878 }}
              />
            </>
          )}
          {tweet && (
            <Link to={`/post/${tweet.externalId}?source=top_influencers`}>
              <Column maxWidth="800" marginTop="30">
                <TweetCard data={tweet} title="Top post" />
              </Column>
            </Link>
          )}
          {selectedItem.type === "disease" && <Links {...{ selectedItem }} />}
        </Column>
      </Content>
    </MainWrapper>
  );
};
