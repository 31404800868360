import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { productActions, tweetActions } from "../../../store/actions";
import {
  cancelProductRequests,
  cancelTweetRequests,
} from "../../../store/sagas";
import { view } from "./TopInfluencersView";
import { getISOFormattedDateTimeString, getUrlParams } from "../../../utils";

export const EmailTopInfluencers = () => {
  const dispatch = useDispatch();

  const { name, productId, startDate, endDate, tweets } = getUrlParams(
    location.search
  );

  const dates = {
    start: getISOFormattedDateTimeString(startDate),
    end: endDate ? getISOFormattedDateTimeString(endDate) : null,
  };

  // --> mapStateToProp
  const isLoggedIn = useSelector((state) => state.auth.data.id);
  const product = useSelector((state) => state.product.one.data);
  const allTweets = useSelector((state) => state.tweet.all);
  // <-- mapStateToProp

  // --> EFFECT
  useEffect(() => {
    if (productId && startDate) {
      productId && requestProduct();
    }

    if (tweets) {
      const tweetIds = tweets.split(",");
      requestTweets(tweetIds);
    }

    return () => {
      tweetActions.clear();
      productActions.clear();
      cancelProductRequests.abort();
      cancelTweetRequests.abort();
    };
  }, []);

  // <-- EFFECT

  const requestProduct = () => {
    dispatch(productActions.oneRequest(productId));
  };

  const requestTweets = (ids) => {
    dispatch(tweetActions.multipleRequest({ ids }));
  };

  return view({
    isLoggedIn,
    name,
    loading: allTweets.fetching,
    dates,
    monitor: product,
    tweets: allTweets.data,
  });
};
