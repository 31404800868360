import React from "react";
import styled from "styled-components";

import {
  Column,
  Row,
  MainWrapper,
  Header,
  Content,
  Label,
  Avatar,
  Anchor,
  Spinner,
  Menu,
  Input,
  Filters,
  Grid,
  Dropdown,
  Modal,
  P,
  Button,
  H4,
  Metadata,
} from "../../components";
import { Color } from "../../utils/variables";

const SearchResults = styled(Column)`
  max-width: 700px;

  .row {
    cursor: pointer;
    margin: 0 -10px;
    padding: 10px;

    &:hover {
      background: ${Color("gray", "0.5")};
    }
  }

  &.blured {
    .row + .row + .row + .row {
      pointer-events: none;
      cursor: default;
      filter: blur(5px);
    }
  }
`;

const AlsoSearched = ({ data }) => {
  return (
    <SearchResults stretch>
      {data?.length > 0 && (
        <Column stretch>
          <Row paddingTop paddingBottom="10">
            <Label bold color="gray" fade="7">
              People also searched for
            </Label>
          </Row>
          {data?.map((item, i) => (
            <Row
              key={item.id}
              expand
              className="row"
              onClick={() =>
                (window.location.href = `/account/${item.id}?source=search`)
              }
            >
              <Column>
                <Row gap="10" middle>
                  <Avatar
                    border="gray"
                    fade="2"
                    medium
                    imageUrl={item.imageurl || item.imageURL}
                    name={item.name}
                  />
                  <Column gap="5">
                    <Label bold>{item.name}</Label>
                    <Row>
                      <Label>{item.institname}</Label>
                      {item.institname && item.country && (
                        <Row paddingX="10">
                          <Label>·</Label>
                        </Row>
                      )}
                      <Label>
                        {item.country === "United States of America"
                          ? "United States"
                          : item.country === "Undetermined"
                          ? ""
                          : item.country}
                      </Label>
                    </Row>
                  </Column>
                </Row>
              </Column>
            </Row>
          ))}
        </Column>
      )}
    </SearchResults>
  );
};

export const view = ({
  auth,
  handleSignUpClick,
  data,
  counter,
  isLoading,
  hasNextPage,
  loadingRef,
  searchValue,
  updateSearch,
  relatedData,

  showFilter,
  filterVisibility,
  toggleFilterVisibility,
  regions,
  selectedRegion,
  handleRegionChange,
  accountTypes,
  selectedAccountType,
  handleChangeAccountType,
  activeFilters,
  handleClearFilters,
  hideFilters,
}) => (
  <MainWrapper drawer>
    <Metadata />
    <Header
      drawer
      login
      user
      signUpClick={handleSignUpClick}
      centerContent={
        <Input
          maxWidth="700px"
          small
          value={searchValue}
          onChange={updateSearch}
          icon="search"
          placeholder="Search physicians and Institutions"
        />
      }
    />
    <Menu />
    <Content>
      <Column maxWidth="900">
        <Column marginBottom>
          <Filters
            counter={
              !counter
                ? "No results"
                : counter === 1
                ? "1 result"
                : counter + " results"
            }
            activeFilters={hideFilters ? [] : activeFilters}
            onClearFilters={handleClearFilters}
            noToggle={hideFilters}
          >
            <Grid columns="3">
              <Column gap="10">
                <Label bold>Account Type</Label>
                <div
                  onClick={(e) => {
                    if (!showFilter) {
                      toggleFilterVisibility(true);
                      e.stopPropagation();
                    }
                  }}
                  className="pointer"
                >
                  <Dropdown
                    expand
                    select
                    value={selectedAccountType}
                    items={accountTypes}
                    onChange={(e) => handleChangeAccountType(e.id)}
                    disabled={!showFilter}
                  />
                </div>
              </Column>
              <Column gap="10">
                <Label bold>Region</Label>
                <div
                  onClick={(e) => {
                    if (!showFilter) {
                      toggleFilterVisibility(true);
                      e.stopPropagation();
                    }
                  }}
                  className="pointer"
                >
                  <Dropdown
                    expand
                    multiple
                    multipleLabel="Regions"
                    value={selectedRegion}
                    items={regions}
                    disabled={!showFilter}
                    onChange={(e) => handleRegionChange(e)}
                  />
                </div>
              </Column>
            </Grid>
          </Filters>
        </Column>
        {!auth?.active && (
          <Column
            maxWidth="400"
            bkg="green"
            fade="1"
            radius="10"
            paddingAll
            gap="5"
            marginBottom
          >
            <Label bold>Not all results visible</Label>
            <Label>Create a free account or log in to see all results</Label>
            <Row gap="20" marginTop="10">
              <Anchor to="/" bold color="green" label="Sign up" />
              <Anchor to="/login" bold color="green" label="Sign in" />
            </Row>
          </Column>
        )}

        <SearchResults stretch className={auth.active ? "" : "blured"}>
          {searchValue === "" && data.length > 0 && (
            <Row paddingBottom="10">
              <Label bold color="gray" fade="7">
                Recently viewed
              </Label>
            </Row>
          )}
          {data?.map((item, i) => (
            <Row
              key={item.id}
              expand
              className="row"
              onClick={() =>
                (window.location.href = `/account/${item.id}?source=search`)
              }
            >
              <Column>
                <Row gap="10" middle>
                  <Avatar
                    border="gray"
                    fade="2"
                    medium
                    imageUrl={item.imageurl}
                    name={item.name}
                  />
                  <Column gap="5">
                    <Label bold>{item.name}</Label>
                    <Row>
                      <Label>{item.institname}</Label>
                      {item.institname && item.country && (
                        <Row paddingX="10">
                          <Label>·</Label>
                        </Row>
                      )}
                      <Label>
                        {item.country === "United States of America"
                          ? "United States"
                          : item.country === "Undetermined"
                          ? ""
                          : item.country}
                      </Label>
                    </Row>
                  </Column>
                </Row>
              </Column>
            </Row>
          ))}
        </SearchResults>
        {isLoading ||
          (hasNextPage && (
            <Row expand paddingAll="20" center ref={loadingRef}>
              <Spinner small />
            </Row>
          ))}
        {auth?.active && <AlsoSearched data={relatedData} />}
      </Column>
    </Content>

    <Modal
      visible={filterVisibility}
      toggleVisibility={toggleFilterVisibility}
      close
      noMargin
      maxWidth="600"
    >
      {!auth.active ? (
        <Column gap="20" center>
          <H4 bold>Create a free account to view more results</H4>
          <Button label="Sign up" to="/" />
          <Row gap="5" middle>
            <Label>Already have an account?</Label>{" "}
            <Anchor to="/login" label="Sign in" bold />
          </Row>
        </Column>
      ) : (
        <Column gap="20" center>
          <H4 bold>Upgrade your plan to use filters</H4>
          <P center>
            Track more brands and diseases, get brand alerts, and much more.
          </P>
          <Button label="See plans" to="/pricing" />
        </Column>
      )}
    </Modal>
  </MainWrapper>
);
