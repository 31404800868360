import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Column, Card, Row } from "../components/Layout";
import { Anchor } from "../components/Button";
import { H3, Label, P } from "../components/Typography";
import { BlinkCursor } from "../components/Spinner";
import { Keyword } from "../components/Keyword";
import { AIHighlight } from "../utils";

const Text = styled(Keyword)`
  line-height: 25px;

  .blinking-cursor {
    font-family: bold;
    font-size: 20px;
    line-height: 20px;
  }

  &.hasFinished .blinking-cursor {
    display: none;
  }

  mark {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  strong {
    mark {
      color: #000 !important;
    }
  }
`;

export const Summary = ({
  keywords,
  // monitors,
  addSelectedTag,
  handleAiRefresh,
  isFetching,
  response,
  // isNewAI,
  // setIsNewAI,
  refreshAI,
  // isAdminUI,
  isSuccess,
  showRegenerate,
  hideMore,
  title,
}) => {
  // const [fullText, setFullText] = useState("");
  // const [text, setText] = useState("");
  // const [index, setIndex] = useState(0);
  const [text, setText] = useState(response);
  const [hasFinished, setHasFinished] = useState(false);
  const [showSeeMore, setShowSeeMore] = useState(false);
  const [showMore, setShowMore] = useState(!hideMore);
  // const [stopAnimation, setStopAnimation] = useState(false);

  useEffect(() => {
    if (response) {
      setText(response);
    } else {
      setText("");
    }
  }, [response]);

  const handleTruncate = (e) => {
    if (e.clamped && !showSeeMore) {
      setShowSeeMore(true);
    }
  };

  const htmlResponse = useMemo(() =>
    AIHighlight({
      text,
      // keywords: keywords?.map((k) => k.name),
      truncateLines: showMore ? 5 : 0,
      handleTruncate,
      // monitors,
    })
  );

  const handleKeywordPressed = (e) => {
    const selectedKeyword = keywords.find((a) => a.name === e);
    if (selectedKeyword) {
      addSelectedTag({
        id: selectedKeyword.id,
        label: selectedKeyword.name,
      });
    }
  };

  // useEffect(() => {
  //   if (!stopAnimation && isNewAI && index < fullText.length) {
  //     setHasFinished(false);
  //     setTimeout(() => {
  //       setText(text + fullText[index]);
  //       setIndex(index + 1);
  //     }, 15);
  //   } else {
  //     setIsNewAI?.(false);
  //     setHasFinished(true);
  //     setText(response);
  //   }
  // }, [fullText, index]);

  // useEffect(() => {
  //   if (!!response) {
  //     setText(isAdminUI ? response : "");
  //     setFullText(response);
  //     setIndex(0);
  //   } else {
  //     setText("");
  //     setFullText("");
  //     setIndex(0);
  //   }
  // }, [response]);

  // useEffect(() => {
  //   if (!showMore && !hasFinished && !stopAnimation) {
  //     setText(fullText);
  //     setStopAnimation(true);
  //   }
  // }, [showMore]);

  return (
    <>
      <Column maxWidth="700">
        {showRegenerate && isSuccess && !response ? (
          <Card border gap="10" marginTop="10" paddingRight="30">
            <Row
              gap="5"
              bkg="green"
              fade="1"
              paddingX="20"
              paddingY="8"
              radius="10"
            >
              <Anchor
                bold
                color="black"
                line
                label="Refresh"
                onClick={() => {
                  handleAiRefresh();
                  setHasFinished(false);
                  setShowMore(hideMore ? false : true);
                  setShowSeeMore(false);
                }}
              />
            </Row>
          </Card>
        ) : (
          <Column>
            {showRegenerate && (
              <Card border gap="10" marginTop="10" paddingRight="30">
                <Row
                  gap="5"
                  bkg="green"
                  fade="1"
                  paddingX="20"
                  paddingY="8"
                  radius="10"
                >
                  <Anchor
                    bold
                    color="black"
                    line
                    label="Refresh"
                    onClick={() => {
                      handleAiRefresh();
                      setHasFinished(false);
                      setShowMore(hideMore ? false : true);
                      setShowSeeMore(false);
                    }}
                  />
                </Row>
              </Card>
            )}
            {isFetching ? (
              <Row expand>
                <BlinkCursor size="20" />
              </Row>
            ) : (
              <>
                {refreshAI && (
                  <Row
                    gap="5"
                    bkg="green"
                    fade="1"
                    paddingX="20"
                    paddingY="8"
                    radius="10"
                    marginBottom="10"
                  >
                    <Label>Your filters have changed.</Label>
                    <Anchor
                      bold
                      color="black"
                      line
                      label="Refresh"
                      onClick={() => {
                        handleAiRefresh();
                      }}
                    />
                  </Row>
                )}

                {response && (
                  <Card border gap="10" marginTop="10" paddingRight="30">
                    <Column
                      paddingRight="10"
                      marginBottom="-10"
                      className="highlights"
                    >
                      {title && (
                        <Row marginBottom="10">
                          <H3 bold>{title}</H3>{" "}
                        </Row>
                      )}
                      <Text className={hasFinished ? "hasFinished" : ""}>
                        {htmlResponse && (
                          <form
                            onClick={(e) =>
                              handleKeywordPressed(e.target.textContent)
                            }
                          >
                            {htmlResponse}
                          </form>
                        )}
                      </Text>
                      {showSeeMore && (
                        <Row marginTop="5" marginBottom="10">
                          <Anchor
                            reverse
                            icon={showMore ? "chevronDown" : "chevronUp"}
                            label={showMore ? "See more" : "See less"}
                            onClick={() =>
                              setShowMore(hideMore ? false : !showMore)
                            }
                          />
                        </Row>
                      )}
                    </Column>
                  </Card>
                )}
              </>
            )}
          </Column>
        )}
      </Column>
    </>
  );
};

Summary.propTypes = {
  handleAiRefresh: PropTypes.func,
  isFetching: PropTypes.bool,
};
