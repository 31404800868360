import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";

import { Anchor, Button, IconButton } from "./Button";
import { Row, Column } from "./Layout";
import { H3, Label, P } from "./Typography";

import { Color, device } from "../utils/variables";
import { height } from "@amcharts/amcharts4/.internal/core/utils/Utils";

export const Modal = (props) => {
  const {
    children,
    close,
    visible,
    toggleVisibility,
    title,
    subtitle,
    filter,
    height,
    goBack,
    onClose,
    noOverlay,
    header,
    noMargin,
    autoHeight,
    noOverflow,
    maxHeight,
  } = props;

  const handleClose = () => {
    toggleVisibility();
    onClose?.();
  };

  return (
    <ModalContainer
      {...props}
      className={visible ? "visible" : ""}
      onClick={() => (noOverlay ? {} : handleClose())}
    >
      <ModalBox
        {...{
          filter,
          close,
          height,
          header,
          noOverlay,
          autoHeight,
          noOverflow,
          maxHeight,
        }}
        maxWidth={props.maxWidth}
        onClick={(e) => e.stopPropagation()}
      >
        {filter ? (
          <ModalHeader paddingAll gap="10" left>
            {goBack && (
              <Anchor label="Back" icon="arrowLeft" onClick={() => goBack()} />
            )}
            {title && <H3 bold>{title}</H3>}
            {subtitle && <Label>{subtitle}</Label>}
          </ModalHeader>
        ) : (
          <Row marginBottom={noMargin ? "0" : "40"} marginTop expand center>
            {title && <H3>{title}</H3>}
          </Row>
        )}
        {visible && children}
        {close && (
          <ModalClose>
            <IconButton
              icon="closeLarge"
              small
              onClick={() => (noOverlay && onClose ? onClose() : handleClose())}
            />
          </ModalClose>
        )}
      </ModalBox>
    </ModalContainer>
  );
};

export const ModalImage = (props) => {
  const { visible, toggleVisibility, url } = props;

  return (
    <ModalImageContainer
      {...props}
      className={visible ? "visible" : ""}
      onClick={() => toggleVisibility(false)}
    >
      <ModalImageBox {...{ url }} />
      <ModalClose>
        <IconButton
          small
          icon="closeLarge"
          onClick={() => toggleVisibility()}
        />
      </ModalClose>
    </ModalImageContainer>
  );
};

const ModalBox = styled.div`
  min-width: 372px;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : "800px")};
  height: ${({ height, noOverlay }) =>
    height ? `${height}px` : noOverlay ? "calc(100% - 60px)" : "auto"};
  ${({ maxHeight }) =>
    maxHeight &&
    css`
      height: 100%;
      max-height: ${maxHeight}px;
    `};

  width: 100%;
  position: relative;
  border-radius: 12px;
  ${({ header }) => header && `margin-top: 60px;`};
  ${({ noOverlay }) => noOverlay && `max-height: 700px`};
  ${({ filter }) =>
    filter
      ? css`
          background: ${Color("gray", "1")};
          padding: 0;
        `
      : css`
          background: white;
          padding: 30px 20px 50px;
          @media ${device.tablet} {
            padding-left: 100px;
            padding-right: 100px;
          }
        `};
  ${({ autoHeight }) =>
    autoHeight &&
    `
    height: calc(100% - 100px);
    margin-top: 50px;
  `};
  ${({ noOverflow }) =>
    noOverflow &&
    `
    overflow: hidden;
  `};
  box-shadow: 0px 16px 16px rgba(0, 0, 0, 0.12);
  transform: translateY(-100%);
  transition: all 0.2s;
`;

const ModalHeader = styled(Column)`
  background: white;
  // border-bottom: 1px solid ${Color("gray", "3")};
  border-radius: 12px 12px 0 0;
`;
export const ModalFooter = styled.div`
  display: flex;
  gap: ${({ gap }) => (gap ? `${gap}px` : "10px")};
  border-radius: 0 0 12px 12px;

  ${({ right }) =>
    right ? "justify-content: flex-end" : "justify-content: center"};

  border-top: 1px solid ${Color("gray", 0.3)};
  padding: 10px 20px;

  ${({ filter }) =>
    filter
      ? css`
          background: white;
          box-shadow: 0px -5px 10px rgba(25, 119, 91, 0.1),
            0px -3px 5px rgba(0, 0, 0, 0.15);
        `
      : css`
          margin: 20px -20px -50px;
          @media ${device.tablet} {
            margin-left: -100px;
            margin-right: -100px;
            padding-left: 100px;
            padding-right: 100px;
          }
        `};

  ${({ noSahdow }) =>
    noSahdow &&
    css`
      box-shadow: unset;
      background: ${Color("gray", "1")};
      border-top-conlor: #bbbcbf;
    `};
`;

const ModalContainer = styled.div`
  pointer-events: none;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  transition: all 0.2s;
  opacity: 0;
  z-index: 101;
  background: rgba(0, 0, 0, 0.4);

  &.visible {
    opacity: 1;
    transition: all 1s;
    pointer-events: all;

    ${ModalBox} {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const ModalClose = styled.div`
  position: absolute;
  top: 18px;
  right: 18px;
`;

const ModalImageContainer = styled(ModalContainer)`
  align-items: center;

  ${ModalClose} {
    top: 90px;
    right: 8px;
  }
`;

const ModalImageBox = styled(ModalBox)`
  padding: 0;
  height: calc(100% - 100px);
  margin: 120px 0 0;
  transform: scale(0.1) translateY(0);
  ${(props) =>
    props.url &&
    `background: transparent url(${props.url}) no-repeat top center`};
  background-size: contain;
  box-shadow: unset;
  border-radius: 0 !important;

  @media ${device.mobileXL} {
    background-position: center;
  }
`;

export const ExportModal = ({ visible, toggleVisibility }) => (
  <Modal visible={visible} toggleVisibility={toggleVisibility} close noMargin>
    <P>
      Our Medical.watch Pro and Enterprise subscriptions come with the ability
      to export physician social media activity and lists of Top Influencers
    </P>
    <ModalFooter>
      <Button label={"View pricing plans"} to="/pricing" />
    </ModalFooter>
  </Modal>
);

export const FilterModal = ({ visible, toggleVisibility }) => (
  <Modal visible={visible} toggleVisibility={toggleVisibility} close noMargin>
    <P>
      With Medical.watch Pro and Enterprise subscriptions, you can tailor physician
      social media posts by location and medical indication.
    </P>
    <ModalFooter>
      <Button label={"View pricing plans"} to="/pricing" />
    </ModalFooter>
  </Modal>
);

Modal.propTypes = {
  filter: PropTypes.bool,
  children: PropTypes.any,
  close: PropTypes.bool,
  title: PropTypes.string,
  visible: PropTypes.bool,
  toggleVisibility: PropTypes.func,
  goBack: PropTypes.func,
};

ModalBox.propTypes = {
  filter: PropTypes.bool,
};

ModalFooter.propTypes = {
  filter: PropTypes.bool,
  right: PropTypes.bool,
};
