import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import moment from "moment";
import { getISOFormattedDateTimeString } from "../../../utils";
import { MAX_RESULTS } from "../../../utils/variables";
import { view } from "./NotificationMessagesView";
import { getNotificationMessages } from "../../../store/sagas/notificationMessageSagas.js";

export const AdminNotificationMessages = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const notificationTypes = [
    { id: "ALL", label: "All" },
    { id: "INSTANT", label: "Instant" },
    { id: "DAILY", label: "Daily" },
    { id: "WEEKLY", label: "Weekly" },
    { id: "MONTHLY", label: "Monthly" },
    // { id: "USERREQUEST", label: "User Request" },
    // { id: "TRIAL", label: "Trial" },
  ];
  const notificationContentTypes = [
    { id: "ALL", label: "All" },
    { id: "DISEASE", label: "Disease" },
    { id: "CONFERENCE", label: "Congress" },
    { id: "PRODUCT", label: "Product" },
    { id: "TOPINFLUENCER", label: "Top Influencers" },
    { id: "TOP_BRAND_MENTIONS", label: "Top Brand Mentions" },
    { id: "TOP_ARTICLES", label: "Top Articles" },
  ];
  const [userTypes] = useState([
    { id: "ALL", label: "All" },
    { id: "HCP", label: "Physician" },
    { id: "MARKETING", label: "Marketing" },
    { id: "MEDICAL", label: "Medical Affairs" },
    { id: "SALES", label: "Sales" },
    { id: "OTHER", label: "Other" },
  ]);

  // --> mapStateToProp
  // useSelector
  // <-- mapStateToProp

  // --> STATE
  const [notificationMessages, setNotificationMessages] = useState([]);
  const [dates, setDates] = useState({
    start: getISOFormattedDateTimeString(moment().add(-2, "months").startOf("day")),
    end: getISOFormattedDateTimeString(moment().add(3, "months").endOf("day")),
  });

  const [userType, setUserType] = useState("ALL");
  const [notificationType, setNotificationType] = useState("ALL");
  const [notificationContentType, setNotificationContentType] = useState("ALL");

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  // <-- STATE

  const requestNotificationMessages = async (pageNum) => {
    setLoading(true);
    const response = await getNotificationMessages({
      objectId: null,
      objectType: null,
      ...(userType !== "ALL" && {userType: userType}),
      ...(notificationType !== "ALL" && { notificationType: notificationType}),
      ...(notificationContentType !== "ALL" && {notificationContentType: notificationContentType}),
      startDate: dates.start,
      endDate: dates.end,
      pageNum,
      maxResult: MAX_RESULTS,
    });
    setNotificationMessages(response.data || []);
    setLoading(false);
    return response;
  };

  // --> EFFECT
  useEffect(() => {
    // (async () => {
    //   await requestNotificationMessages(0);
    // })();
    return () => {
    };
  }, [dispatch]);

  useEffect(() => {
    setPage(0);
    (async () => {
      await requestNotificationMessages(0);
    })();
  }, [
    dates,
    userType,
    notificationType,
    notificationContentType,
  ]);
  // <-- EFFECT

  const handleDateChange = (e) => {
    const start = getISOFormattedDateTimeString(e.startDate);
    const end = getISOFormattedDateTimeString(e.endDate);
    setDates({
      start,
      end,
    });
  };

  const handleNotificationTypeChange = (item) => {
    setNotificationType(item.id);
  };

  const handleNotificationContentTypeChange = (item) => {
    setNotificationContentType(item.id);
  };

  const handleUserTypeChange = (item) => {
    setUserType(item.id);
  };

  const getActiveFilters = () => {
    let activeFilters = [];
    if (userType !== "ALL") {
      activeFilters.push(
        `User Type: ${
          userTypes.find(
            (a) => userType === a.id
          )?.label
        }`
      );
    }
    if (notificationType !== "ALL") {
      activeFilters.push(
        `Type: ${
          notificationTypes.find((a) => notificationType === a.id)
            ?.label
        }`
      );
    }
    if (notificationContentType !== "ALL") {
      activeFilters.push(
        `Email Type: ${
          notificationContentTypes.find(
            (a) => notificationContentType === a.id
          )?.label
        }`
      );
    }

    return activeFilters;
  };

  return view({
    history,
    loading,
    notificationMessages,
    activeFilters: getActiveFilters(),

    userTypes,
    userType,
    handleUserTypeChange,

    notificationTypes,
    notificationType,
    handleNotificationTypeChange,

    notificationContentTypes,
    notificationContentType,
    handleNotificationContentTypeChange,

    dates,
    handleDateChange,
  });
};
