import { MONITOR_ACTIONS } from "../actions";
import { MAX_RESULTS } from "../../utils/variables";

const initialState = {
  unauth: [],
  auth: { data: [], fetching: false, success: false, error: null },
  all: { data: [], fetching: false, success: false, error: null },
  one: { data: [], fetching: false, success: false, error: null },
  date: null,
};

export default function monitorReducer(state = initialState, action) {
  switch (action.type) {
    case MONITOR_ACTIONS.DATE:
      return { ...state, date: action.payload.data };

    case MONITOR_ACTIONS.REQUEST:
    case MONITOR_ACTIONS.FAILURE:
    case MONITOR_ACTIONS.FAILURE:
      return { ...state, all: { ...state.all, ...action.payload } };
    case MONITOR_ACTIONS.SUCCESS:
      // If we have pagination, we need to merge state data & payload data
      const newState = {
        ...state,
        all: {
          ...state.all,
          ...action.payload,
          isLastPage: action.payload.data.length < MAX_RESULTS,
        },
      };
      if (action.payload.pageNum && action.payload.pageNum !== 0) {
        newState.all.data = [...state.all.data, ...action.payload.data];
      }
      return newState;
    case MONITOR_ACTIONS.CLEAR_ALL:
      return {
        ...state,
        all: action.payload,
      };

    case MONITOR_ACTIONS.SET_MONITORS:
      return { ...state, unauth: action.payload.data };
    case MONITOR_ACTIONS.CLEAR_MONITORS:
      return { ...state, unauth: [] };

    case MONITOR_ACTIONS.ONE_REQUEST:
    case MONITOR_ACTIONS.ONE_SUCCESS:
    case MONITOR_ACTIONS.ONE_FAILURE:
    case MONITOR_ACTIONS.ONE_CLEAR:
      return { ...state, one: { ...action.payload } };

    case MONITOR_ACTIONS.MONITORS_REQUEST:
    case MONITOR_ACTIONS.MONITORS_SUCCESS:
    case MONITOR_ACTIONS.MONITORS_FAILURE:
    case MONITOR_ACTIONS.MONITORS_CLEAR:
      return { ...state, auth: { ...state.auth, ...action.payload } };
    default:
      return state;
  }
}
