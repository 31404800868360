import { createAction, createActionTypes } from "../../utils";

export const USER_ACTIONS = createActionTypes("USER_ACTIONS", [
  "REQUEST",
  "SUCCESS",
  "FAILURE",
  "CLEAR_ALL",

  "ONE_REQUEST",
  "ONE_SUCCESS",
  "ONE_FAILURE",
  "CREATE",
  "SIGNUP",
  "RESEND_SIGNUP",
  "UPDATE",
  "UPDATE_STATUS",
  "DELETE",
  "CLEAR",

  "COUNT_REQUEST",
  "COUNT_SUCCESS",
  "COUNT_FAILURE",

  "SEND_LOGIN_EMAIL",
  "SEND_EMAIL_SUCCESS",
  "SEND_EMAIL_FAILURE",
  "SEND_EMAIL_CLEAR",

  "REQUEST_PREFERENCES",
  "UPDATE_PREFERENCES",
  "DELETE_PREFERENCES",
  "SUCCESS_PREFERENCES",
  "FAILURE_PREFERENCES",
  "CLEAR_PREFENCES",
]);

export const userActions = {
  request: (data) =>
    createAction(USER_ACTIONS.REQUEST, {
      ...data,
      fetching: true,
      success: false,
      error: null,
    }),
  success: ({ data, count, pageNum }) =>
    createAction(USER_ACTIONS.SUCCESS, {
      data,
      ...(count && { count }), // do not include count, if not present, i.e., allow overriding of count
      pageNum,
      fetching: false,
      success: true,
      error: null,
    }),
  failure: (error) =>
    createAction(USER_ACTIONS.FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),
  clearAll: () =>
    createAction(USER_ACTIONS.CLEAR_ALL, {
      data: [],
      fetching: true,
      success: false,
      error: null,
    }),

  oneRequest: (id) =>
    createAction(USER_ACTIONS.ONE_REQUEST, {
      id,
      fetching: true,
      success: false,
      error: null,
    }),
  oneSuccess: (data) =>
    createAction(USER_ACTIONS.ONE_SUCCESS, {
      ...data,
      fetching: false,
      success: true,
      error: null,
    }),
  oneFailure: (error) =>
    createAction(USER_ACTIONS.ONE_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),
  create: (data) =>
    createAction(USER_ACTIONS.CREATE, {
      data,
      fetching: true,
      success: false,
      error: null,
    }),
  signup: (data) =>
    createAction(USER_ACTIONS.SIGNUP, {
      data,
      fetching: true,
      success: false,
      error: null,
    }),
  resendSignup: (data) =>
    createAction(USER_ACTIONS.RESEND_SIGNUP, {
      data,
      fetching: true,
      success: false,
      error: null,
    }),
  update: (data) =>
    createAction(USER_ACTIONS.UPDATE, {
      data,
      fetching: true,
      success: false,
      error: null,
    }),
  updateStatus: (data) =>
    createAction(USER_ACTIONS.UPDATE_STATUS, {
      data,
      fetching: true,
      success: false,
      error: null,
    }),
  delete: (id) =>
    createAction(USER_ACTIONS.DELETE, {
      id,
      fetching: true,
      success: false,
      error: null,
    }),
  clear: () =>
    createAction(USER_ACTIONS.CLEAR, {
      data: [],
      fetching: true,
      success: false,
      error: null,
    }),

  countRequest: (data) =>
    createAction(USER_ACTIONS.COUNT_REQUEST, {
      ...data,
      fetching: true,
      success: false,
      error: null,
    }),
  countSuccess: ({ data }) =>
    createAction(USER_ACTIONS.COUNT_SUCCESS, {
      data: data,
      fetching: false,
      success: true,
      error: null,
    }),
  countFailure: (error) =>
    createAction(USER_ACTIONS.COUNT_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),

  sendLoginEmail: (data) =>
    createAction(USER_ACTIONS.SEND_LOGIN_EMAIL, {
      data,
      fetching: true,
      success: false,
      error: null,
    }),
  sendEmailSuccess: (data) =>
    createAction(USER_ACTIONS.SEND_EMAIL_SUCCESS, {
      ...data,
      fetching: false,
      success: true,
      error: null,
    }),
  sendEmailFailure: (error) =>
    createAction(USER_ACTIONS.SEND_EMAIL_FAILURE, {
      ...error,
      fetching: false,
      success: false,
    }),
  sendEmailClear: () =>
    createAction(USER_ACTIONS.SEND_EMAIL_CLEAR, {
      data: [],
      fetching: false,
      success: false,
      error: null,
    }),

  requestPreferences: (data) =>
    createAction(USER_ACTIONS.REQUEST_PREFERENCES, {
      data,
      fetching: true,
      success: false,
      error: null,
    }),
  updatePreferences: (data) =>
    createAction(USER_ACTIONS.UPDATE_PREFERENCES, {
      data,
      fetching: true,
      success: false,
      error: null,
    }),
  deletePreferences: (data) =>
    createAction(USER_ACTIONS.DELETE_PREFERENCES, {
      data,
      fetching: true,
      success: false,
      error: null,
    }),
  successPreferences: (data) =>
    createAction(USER_ACTIONS.SUCCESS_PREFERENCES, {
      ...data,
      fetching: false,
      success: true,
      error: null,
    }),
  failurePreferences: (error) =>
    createAction(USER_ACTIONS.FAILURE_PREFERENCES, {
      ...error,
      fetching: false,
      success: false,
    }),
  clearPreferences: () =>
    createAction(USER_ACTIONS.CLEAR_PREFENCES, {
      data: [],
      fetching: true,
      success: false,
      error: null,
    }),
};
