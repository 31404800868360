import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  diseaseActions,
  conferenceActions,
  productActions,
  tweetActions,
} from "../../../store/actions";
import {
  cancelDiseaseRequests,
  cancelProductRequests,
  cancelConferenceRequests,
  cancelTweetRequests,
} from "../../../store/sagas";
import { view } from "./TopTweetsView";
import { getISOFormattedDateTimeString, getUrlParams } from "../../../utils";

export const EmailTopTweets = () => {
  const dispatch = useDispatch();

  const {
    name,
    diseaseId,
    productId,
    conferenceId,
    startDate,
    endDate,
    tweets,
  } = getUrlParams(location.search);

  const dates = {
    start: getISOFormattedDateTimeString(startDate),
    end: getISOFormattedDateTimeString(endDate),
  };

  // --> mapStateToProp
  const isLoggedIn = useSelector((state) => state.auth.data.id);
  const disease = useSelector((state) => state.disease.one.data);
  const product = useSelector((state) => state.product.one.data);
  const conference = useSelector((state) => state.conference.one.data);
  const allTweets = useSelector((state) => state.tweet.all);
  // <-- mapStateToProp

  // --> EFFECT
  useEffect(() => {
    if ((diseaseId || productId || conferenceId) && startDate && endDate) {
      diseaseId && requestDisease();
      productId && requestProduct();
      conferenceId && requestConference();

      if (tweets) {
        const tweetIds = tweets.split(",");
        requestTweets(tweetIds);
      }
    }

    return () => {
      tweetActions.clear();
      diseaseActions.clear();
      productActions.clear();
      conferenceActions.clear();
      cancelDiseaseRequests.abort();
      cancelProductRequests.abort();
      cancelConferenceRequests.abort();
      cancelTweetRequests.abort();
    };
  }, []);

  // <-- EFFECT

  const requestDisease = () => {
    dispatch(diseaseActions.oneRequest(diseaseId));
  };

  const requestProduct = () => {
    dispatch(productActions.oneRequest(productId));
  };

  const requestConference = () => {
    dispatch(conferenceActions.oneRequest(conferenceId));
  };

  const requestTweets = (ids) => {
    dispatch(tweetActions.multipleRequest({ ids, sortBy: "score" }));
  };

  return view({
    isLoggedIn,
    name,
    loading: allTweets.fetching,
    dates,
    monitor: { ...disease, ...conference, ...product },
    tweets: allTweets.data,
  });
};
