import { put, call, takeLatest, all } from "redux-saga/effects";
import axios from "axios";

import { getApiPath } from "../../utils/variables";
import { authHeader } from "../store";
import { objectToUrlEncoded } from "../../utils/constants";

export function getNotificationMessages({
  objectId,
  objectType,
  userType,
  notificationType,
  notificationContentType,
  startDate,
  endDate,
  maxResult,
  pageNum,
}) {
  const params = objectToUrlEncoded({
    objectId,
    objectType,
    userType,
    notificationType,
    notificationContentType,
    startDate,
    endDate,
    maxResult,
    pageNum,
  });
  return axios.get(`${getApiPath()}/notificationMessages?${params}`, {headers: authHeader()});
}

export function getNotificationMessage(id) {
  try {
    return axios.get(`${getApiPath()}/notificationMessage/${id}`, {headers: authHeader()});
  } catch (error) {
    console.error("getNotificationMessage", error);
  }
};

export function createNotificationMessage(body) {
  try {
    return axios.post(`${getApiPath()}/notificationMessage`, body, {headers: authHeader()});
  } catch (error) {
    console.error("createNotificationMessage", error);
  }
};

export function updateNotificationMessage(body) {
  try {
    return axios.put(`${getApiPath()}/notificationMessage`, body, {headers: authHeader()});
  } catch (error) {
    console.error("updateNotificationMessage", error);
  }
};

export function deleteNotificationMessage(id) {
  try {
    return axios.delete(`${getApiPath()}/notificationMessage?id=${id}`, {headers: authHeader()});
  } catch (error) {
    console.error("deleteNotificationMessage", error);
  }
};
