import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { useHistory } from "react-router-dom";
import { debounce } from "lodash";

import {
  userActions,
  productActions,
  diseaseActions,
  planActions,
} from "../../store/actions";
import { view } from "./SignupView";
import { isValidEmail, logAnalyticEvent } from "../../utils";
import useDebounce from "../../utils/useDebounce";
import { MAX_RESULTS } from "../../utils/variables";

const errorsMessage = {
  firstName: "Required field",
  lastName: "Required field",
  email: "Invalid email, please retry",
  userType: "Please select a role",
};
export const Signup = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const emailSent = useSelector((state) => state.user.email);
  const initialProducts = useSelector((state) => state.product.tags);
  const initialDiseases = useSelector((state) => state.disease.tags);
  const plans = useSelector((state) => state.plan.all);
  const basePlans = useSelector((state) => state.plan.basePlans);

  const searchParams = new URLSearchParams(window.location.search);
  let checkoutEmail = searchParams.get("email");

  // let plan = searchParams.get("plan");
  // if (!plan) {
  //   plan = "free";
  // }
  const customerId = searchParams.get("customerId");

  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: checkoutEmail || "",
    userType: "",
    customerId,
    plan: null,
  });
  const [errors, setErrors] = useState({});
  const [showEmail, setShowEmail] = useState(false);
  const [isSubmitPressed, setIsSubmitPressed] = useState(false);
  const [step, setStep] = useState(1);

  const [products, setProducts] = useState([]);
  const [pageProducts, setPageProducts] = useState(0);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [searchProduct, setSearchProduct] = useState("");
  const debounceSearchProduct = useDebounce(searchProduct, 700);

  const [diseases, setDiseases] = useState([]);
  const [pageDiseases, setPageDiseases] = useState(0);
  const [selectedDiseases, setSelectedDiseases] = useState([]);
  const [searchDisease, setSearchDisease] = useState("");
  const debounceSearchDisease = useDebounce(searchDisease, 700);

  const [nextDisabled, setNextDisabled] = useState(true);
  const [backendError, setBackendError] = useState("");
  const [pressedResend, setPressedResend] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState([]);

  const auth = useSelector((state) => state.auth.data);
  const cookies = useSelector((state) => state.settings.cookies);

  useEffect(() => {
    dispatch(planActions.request());
    dispatch(planActions.basePlansRequest());

    if (checkoutEmail) {
      checkoutEmail = decodeURIComponent(checkoutEmail);
    } else {
      setShowEmail(true);
    }

    return () => {
      dispatch(userActions.sendEmailClear());
    };
  }, []);

  useEffect(() => {
    setPageProducts(0);
    requestProducts(0);
  }, [debounceSearchProduct]);

  useEffect(() => {
    let newProducts = initialProducts.data.map((tag) => {
      return {
        ...tag,
        label: tag.preferredTagName || tag.name,
        id: tag.productId || tag.preferredTagId || tag.id,
        suggestion: !!tag.preferredTagId,
      };
    });

    newProducts = newProducts.filter((t) => t.productId || t.suggestion);
    setProducts(newProducts);
  }, [initialProducts.data]);

  useEffect(() => {
    setPageDiseases(0);
    requestDiseases(0);
  }, [debounceSearchDisease]);

  useEffect(() => {
    let newDiseases = initialDiseases.data.map((tag) => {
      return {
        ...tag,
        label: tag.preferredTagName || tag.name,
        id: tag.diseaseId || tag.preferredTagId || tag.id,
        suggestion: !!tag.preferredTagId,
      };
    });
    newDiseases = newDiseases.filter((t) => t.diseaseId || t.suggestion);
    setDiseases(newDiseases);
  }, [initialDiseases.data]);

  useEffect(() => {
    if (emailSent.error?.response?.data?.message) {
      setBackendError(emailSent.error.response.data.message);
    }
  }, [emailSent.error]);

  useEffect(() => {
    if (emailSent.success && emailSent.data?.id) {
      setStep(3);
      // // check for cookies first before publishing event
      // if (!auth?.active && cookies?.seen && !cookies?.accepted) {
      //   return;
      // }
      // log event
      logAnalyticEvent(
        "Sign up - Confirm email",
        {},
        emailSent.data?.id,
        emailSent.data?.displayName,
        emailSent.data?.email,
        emailSent.data?.createdAt,
        emailSent.data?.preferences?.filter(
          (p) => p.preferenceName === "UserType"
        )?.[0]?.preferenceStringValue || "MEDICAL",
        data.plan?.name || null
      );
    }
  }, [emailSent.success]);

  const requestProducts = (pageNum) => {
    dispatch(
      productActions.tagsRequest({
        ignore: false,
        maxResult: MAX_RESULTS,
        // term: searchProduct || "a",
        ...(searchProduct !== "" && { term: searchProduct }),
        pageNum,
      })
    );
  };

  const handleMoreProducts = () => {
    if (!initialProducts.fetching) {
      const productsNewPage = pageProducts + 1;
      setPageProducts(productsNewPage);
      requestProducts(productsNewPage);
    }
  };

  const addSelectedProduct = (item) => {
    setSelectedProducts([
      {
        id: item.productId,
        label: item.label,
      },
    ]);
  };

  const addSelectedProducts = (item) => {
    if (item) {
      setSelectedProducts([
        ...selectedProducts,
        {
          id: item.productId,
          label: item.label,
        },
      ]);
    }
  };

  const removeSelectedProducts = (item) => {
    const slectedItems = selectedProducts.filter((i) => i.id !== item.id);
    setSelectedProducts(slectedItems);
  };

  const clearSelectedProducts = () => {
    setSelectedProducts([]);
    setSearchProduct("");
  };

  const requestDiseases = (pageNum) => {
    dispatch(
      diseaseActions.tagsRequest({
        ignore: false,
        maxResult: MAX_RESULTS,
        ...(searchDisease !== "" && { term: searchDisease }),
        pageNum,
      })
    );
  };

  const handleMoreDiseases = () => {
    if (!initialDiseases.fetching) {
      const diseasesNewPage = pageDiseases + 1;
      setPageDiseases(diseasesNewPage);
      requestDiseases(diseasesNewPage);
    }
  };

  const addSelectedDisease = (item) => {
    setSelectedDiseases([
      {
        id: item.diseaseId,
        label: item.label,
      },
    ]);
  };

  const addSelectedDiseases = (item) => {
    if (item) {
      setSelectedDiseases([
        ...selectedDiseases,
        {
          id: item.diseaseId,
          label: item.label,
        },
      ]);
    }
  };

  const removeSelectedDiseases = (item) => {
    const slectedItems = selectedDiseases.filter((i) => i.id !== item.id);
    setSelectedDiseases(slectedItems);
  };

  const clearSelectedDiseases = () => {
    setSelectedDiseases([]);
    setSearchDisease("");
  };

  // useEffect(() => {
  //   checkErrors();
  //   // if (backendError) {
  //   //   setBackendError("");
  //   // }
  // }, [data]);

  useEffect(() => {
    isNextDisabled();
  }, [selectedProducts, selectedDiseases, data, step]);

  useEffect(() => {
    if (emailSent.error?.data?.message) {
      setErrors(emailSent.error?.data?.message);
    }
  }, [emailSent.error]);

  const checkErrors = () => {
    let newErrors = {};
    for (const item in errorsMessage) {
      if (item === "email") {
        if (checkoutEmail || isValidEmail(data.email)) {
          delete newErrors.email;
        } else {
          newErrors.email = errorsMessage.email;
        }
      } else {
        if (data[item] === "") {
          newErrors[item] = errorsMessage[item];
        } else {
          delete newErrors[item];
        }
      }
    }
    setErrors(newErrors);
  };

  const handleUpdateData = ({ field, value }) => {
    if (field === "userType") {
      let assignedPlan = plans?.data?.find((item) =>
        item.name.toLowerCase().includes("free")
      );
      if (value === "HCP") {
        assignedPlan = plans?.data?.find((item) => item.name.includes("HCP"));
      }
      if (value === "OTHER") {
        assignedPlan = plans?.data?.find((item) => item.name.includes("Other"));
      }
      if (basePlans?.data?.length > 0) {
        const basePlan = basePlans?.data?.find(
          (item) => item.name === assignedPlan.name
        );
        if (basePlan) {
          setSelectedPlan({
            diseases: basePlan?.planFeatures?.filter(
              (item) => item.name === "DISEASE"
            )?.[0]?.value,
            products: basePlan?.planFeatures?.filter(
              (item) => item.name === "PRODUCT"
            )?.[0]?.value,
          });
        }
      }
      setData((oldData) => ({
        ...oldData,
        userType: value,
        plan: assignedPlan,
      }));
    } else {
      setData((oldData) => ({
        ...oldData,
        [field]: value,
      }));
    }
    if (field === "email") {
      if (isValidEmail(value)) {
        setErrors((oldErrors) => {
          const newErrors = { ...oldErrors };
          delete newErrors.email;
          return newErrors;
        });
      } else {
        setErrors((oldErrors) => ({
          ...oldErrors,
          email: errorsMessage.email,
        }));
      }
    }
  };

  const debounceSubmit = debounce(() => {
    var aiChatValue = 0;
    // enable AIChat for all users except sales and eisai users
    if (
      data.userType != "SALES" &&
      data.email.indexOf("@eisai") < 0 &&
      (data.plan?.name.toLowerCase().indexOf("pro") > -1 ||
        data.plan?.name.toLowerCase().indexOf("enterprise") > -1)
    ) {
      aiChatValue = 1;
    }

    const newMonitors = [];

    selectedProducts.forEach((p) => {
      newMonitors.push({
        monitorComponent: {
          object1Type: "PRODUCT",
          object1Id: p.id,
        },
        notificationIntervalId: "2",
        notificationInterval: "24",
        notificationType: "EMAIL",
        visible: true,
      });
    });

    selectedDiseases.forEach((d) => {
      newMonitors.push({
        monitorComponent: {
          object1Type: "DISEASE",
          object1Id: d.id,
        },
        notificationIntervalId: "1",
        notificationInterval: "168",
        notificationType: "EMAIL",
        visible: true,
      });
    });

    dispatch(
      userActions.signup({
        firstName: data.firstName,
        lastName: data.lastName,
        displayName: `${data.firstName} ${data.lastName}`,
        email: data.email,
        customerId: data.customerId,
        preferences: [
          {
            preferenceName: "UserType",
            preferenceStringValue: data.userType,
            primary: true,
          },
          {
            preferenceName: "AIChat",
            preferenceIntValue: aiChatValue,
            primary: true,
          },
        ],
        active: true,
        password: "Test1234",
        mwRole: "USER",
        from: "signup",
        monitors: newMonitors,
        ...(data.plan?.id && {
          userPlans: [
            {
              planId: data.plan.id,
            },
          ],
        }),
      })
    );

    // check for cookies first before publishing event
    if (!auth?.active && cookies?.seen && !cookies?.accepted) {
      return;
    }
    // log event
    logAnalyticEvent(
      "Sign up - Monitors",
      {
        email: data.email,
        diseases: selectedDiseases.map((a) => a.label).join(","),
        products: selectedProducts.map((b) => b.label).join(","),
      },
      null,
      `${data.firstName} ${data.lastName}`,
      data.email,
      null,
      data.userType,
      data.plan?.name || null
    );
  }, 1000);

  const submit = () => {
    setIsSubmitPressed(true);
    if (isEmpty(errors)) {
      debounceSubmit();
    }
  };

  const resendEmail = () => {
    if (data.email) {
      dispatch(
        userActions.resendSignup({
          email: data.email,
        })
      );
      // log event
      logAnalyticEvent(
        "Resend",
        {
          page: "ConfirmEmail",
          lastName: data.lastName,
          email: data.email,
          type: data.userType,
        },
        null,
        `${data.firstName} ${data.lastName}`,
        data.email,
        null,
        data.userType,
        data.plan?.name || null
      );

      setPressedResend(true);
    }
  };

  const handleModalBack = () => {
    if (step === 2) {
      clearSelectedDiseases();
      clearSelectedProducts();
    }
    setStep(step - 1);
  };

  const handleNext = () => {
    if (step === 1) {
      setStep(2);
      // log event
      logAnalyticEvent(
        "Sign up - User info",
        {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          type: data.userType,
        },
        null,
        `${data.firstName} ${data.lastName}`,
        data.email,
        null,
        data.userType,
        data.plan?.name || null
      );
    }

    if (step === 2) {
      // check for cookies first before publishing event
      if (!auth?.active && cookies?.seen && !cookies?.accepted) {
        return;
      }
      submit();
    }
  };

  const isNextDisabled = () => {
    if (step === 2) {
      if (data?.userType === "HCP" || data?.userType === "OTHER") {
        if (selectedDiseases.length > 0) {
          setNextDisabled(false);
          return;
        }
      } else {
        if (
          selectedDiseases.length > 0 &&
          selectedProducts.length > 0 &&
          isValidEmail(data.email)
        ) {
          setNextDisabled(false);
          return;
        }
      }
    }
    if (
      step === 1 &&
      data.firstName.length > 1 &&
      data.lastName.length > 1 &&
      isValidEmail(data.email) &&
      data.userType !== ""
    ) {
      setNextDisabled(false);
      return;
    }
    setNextDisabled(true);
  };

  return view({
    checkoutEmail,
    showEmail,

    data,
    errors,
    handleUpdateData,
    submit,
    isSubmitPressed,
    loading: emailSent.fetching,
    emailSent: emailSent.data,
    history,
    step,
    handleNext,
    handleModalBack,

    diseases,
    setSearchDisease,
    searchDisease,
    selectedDiseases,
    addSelectedDisease,
    addSelectedDiseases,
    removeSelectedDiseases,
    clearSelectedDiseases,
    diseasesInfiniteScroll: {
      loading: initialDiseases.fetching,
      hasNextPage: !initialDiseases.isLastPage,
      onLoadMore: handleMoreDiseases,
    },

    products,
    setSearchProduct,
    searchProduct,
    selectedProducts,
    addSelectedProduct,
    addSelectedProducts,
    removeSelectedProducts,
    clearSelectedProducts,
    productsInfiniteScroll: {
      loading: initialProducts.fetching,
      hasNextPage: !initialProducts.isLastPage,
      onLoadMore: handleMoreProducts,
    },

    nextDisabled,
    backendError,
    resendEmail,
    pressedResend,
    selectedPlan,
  });
};
