import { COMMON_ACTIONS } from "../actions";

const initialState = {
  meanings: {},
  accountSubtypes: {},
  userRoles: {},
  countries: [],
  fetching: false,
  success: false,
  error: null,
};

export default function commonReducer(state = initialState, action) {
  switch (action.type) {
    case COMMON_ACTIONS.REQUEST_MEANINGS:
    case COMMON_ACTIONS.SUCCESS_MEANINGS:
      return {
        ...state,
        meanings: action.payload.data,
      };
    case COMMON_ACTIONS.FAILURE_MEANINGS:
      return {
        ...state,
        error: action.payload.error,
      };

    case COMMON_ACTIONS.REQUEST_ACCOUNT_SUBTYPE:
    case COMMON_ACTIONS.SUCCESS_ACCOUNT_SUBTYPE:
      return {
        ...state,
        accountSubtypes: action.payload.data,
      };
    case COMMON_ACTIONS.FAILURE_ACCOUNT_SUBTYPE:
      return {
        ...state,
        error: action.payload.error,
      };

    case COMMON_ACTIONS.REQUEST_USER_ROLE:
    case COMMON_ACTIONS.SUCCESS_USER_ROLE:
      return {
        ...state,
        userRoles: action.payload.data,
      };
    case COMMON_ACTIONS.FAILURE_USER_ROLE:
      return {
        ...state,
        error: action.payload.error,
      };

    case COMMON_ACTIONS.REQUEST_COUNTRIES:
    case COMMON_ACTIONS.SUCCESS_COUNTRIES:
      return {
        ...state,
        countries: action.payload.data,
      };
    case COMMON_ACTIONS.FAILURE_COUNTRIES:
      return {
        ...state,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
