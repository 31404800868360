import { useQuery } from "react-query";
import axios from "axios";
import { getApiPath } from "../../utils/variables";

export function getPodcast(id) {
  if (id.includes("PC_")) {
    return axios.get(`${getApiPath(false)}/episode/${id}`, {});
  } else {
    return Promise.resolve({ data: { data: {} } });
  }
}
export function useGetPodcast(id) {
  return useQuery(["podcast"], () => getPodcast(id), {
    cacheTime: 0,
  });
}
