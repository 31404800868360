import { useQuery, useInfiniteQuery } from "react-query";
import axios from "axios";
import { getApiPath } from "../../utils/variables";

export function getDiseaseInfluencers(data) {
  const url = `${getApiPath(false)}/tags/topDiseases`;
  return axios.get(url, { params: data });
}

export function useDiseaseInfluencers(data) {
  return useQuery(
    [`diseaseInfluencers${data?.startDate && `-${data.startDate}`}`],
    () => getDiseaseInfluencers(data),
    {
      refetchOnMount: false,
      cacheTime: 300000,
    }
  );
}

export function getConferenceInfluencers(data) {
  const url = `${getApiPath(false)}/conferences/selected`;
  return axios.get(url, { params: data });
}

export function useConferenceInfluencers(data) {
  return useQuery(
    ["conferenceInfluencers"],
    () => getConferenceInfluencers(data),
    {
      refetchOnMount: false,
      cacheTime: 300000,
    }
  );
}

export function getDiseaseTweets(data) {
  const url = `${getApiPath(false)}/influencers/tweets`;
  return axios.get(url, { params: data });
}

export function useDiseaseTweets(data) {
  return useQuery(
    [`influencersDiseaseTweets${data?.startDate && `-${data.startDate}`}`],
    () => getDiseaseTweets(data),
    {
      refetchOnMount: false,
      cacheTime: 300000,
    }
  );
}

export function getDiseaseSummaries() {
  const url = `${getApiPath(false)}/aiSummaries/latestSummaryPerObject`;
  return axios.get(url, {});
}

export function useDiseaseSummaries() {
  return useQuery(["diseaseSummaries"], () => getDiseaseSummaries(), {
    refetchOnMount: false,
    cacheTime: 300000,
  });
}

export function getInfluencersByDisease(data, { pageParam }) {
  const { objectId, maxResult } = data;
  const url = `${getApiPath(false)}/aiSummaries/objectSummary`;
  return axios.get(url, {
    params: { objectId, maxResult, pageNum: pageParam },
  });
}

export function useInfluencersByDisease(data, { getNextPageParam }) {
  return useInfiniteQuery(
    [`influencersByDisease-${data.objectId}`],
    ({ pageParam = 0 }) => getInfluencersByDisease(data, { pageParam }),
    {
      getNextPageParam,
      refetchOnMount: false,
      cacheTime: 300000,
    }
  );
}
