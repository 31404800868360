import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Toast, Label, Icon, Row } from "../components";

export const UseToast = () => {
  const [endpointMessage, setEndpointMessage] = useState("");
  const [visible, toggleVisibility] = useState(false);
  const [error, setError] = useState(false);

  // error
  const authError = useSelector((state) => state.auth.error);
  const userError = useSelector((state) => state.user.one.error);
  const diseaseError = useSelector((state) => state.disease.one.error);
  const productError = useSelector((state) => state.product.one.error);
  const tagError = useSelector((state) => state.tag.one.error);
  const tagRuleError = useSelector((state) => state.tag.tagMonitorRule.error);
  const conferenceError = useSelector((state) => state.conference.one.error);
  const accountError = useSelector((state) => state.account.one.error);
  const notificationError = useSelector(
    (state) => state.notification.send.error
  );

  useEffect(() => {
    if (typeof authError?.response?.data === "string") {
      // setEndpointMessage(authError?.response?.data);
      console.log(window.location.pathname);
      if (window.location.pathname === "/loginByPassword") {
        setEndpointMessage(
          "Login Error, please try after some time or contact administrator."
        );
      } else {
        setEndpointMessage("Sign in link expired, please request a new one.");
      }
      setError(true);
    }
  }, [authError]);
  useEffect(() => {
    setEndpointMessage(userError?.response?.data?.message);
    setError(true);
  }, [userError]);
  useEffect(() => {
    setEndpointMessage(diseaseError?.response?.data?.message);
    setError(true);
  }, [diseaseError]);
  useEffect(() => {
    setEndpointMessage(productError?.response?.data?.message);
    setError(true);
  }, [productError]);
  useEffect(() => {
    setEndpointMessage(tagError?.response?.data?.message);
    setError(true);
  }, [tagError]);
  useEffect(() => {
    setEndpointMessage(tagRuleError?.response?.data?.message);
    setError(true);
  }, [tagRuleError]);
  useEffect(() => {
    setEndpointMessage(conferenceError?.response?.data?.message);
    setError(true);
  }, [conferenceError]);
  useEffect(() => {
    setEndpointMessage(accountError?.response?.data?.message);
    setError(true);
  }, [accountError]);
  useEffect(() => {
    setEndpointMessage(notificationError?.response?.data?.message);
    setError(true);
  }, [notificationError]);

  // successs
  const notificationSuccess = useSelector(
    (state) => state.notification.send.success
  );
  const notificationResponse = useSelector(
    (state) => state.notification.send.data?.data
  );

  useEffect(() => {
    setEndpointMessage(
      notificationSuccess
        ? notificationResponse.length > 0
          ? "Notifications sent successfully"
          : "No notifications sent"
        : ""
    );
    setError(false);
  }, [notificationSuccess]);

  useEffect(() => {
    if (!!endpointMessage) {
      toggleVisibility(true);
    }
  }, [endpointMessage]);

  return (
    <Toast {...{ visible, toggleVisibility }} warning={error} expiration={4000}>
      <Row gap={error ? "10" : "5"} middle>
        <Icon name={error ? "warning" : "check"} />
        <Label>{endpointMessage}</Label>
      </Row>
    </Toast>
  );
};
